// @mui/icons-material
import w_home from "assets/icons/white/home.svg";
import w_company from "assets/icons/white/company.svg";
import w_farm from "assets/icons/white/farm.svg";
import w_user from "assets/icons/white/user.svg";
import w_role from "assets/icons/white/role.svg";
// import w_modules from "assets/icons/white/module.svg";
import w_products from "assets/icons/white/products.svg";
import w_rawMaterial from "assets/icons/white/raw-material.svg";
import w_customer from "assets/icons/white/customer.svg";
import w_supplier from "assets/icons/white/supplier.svg";
import w_vehicle from "assets/icons/white/vehicle.svg";
import w_device from "assets/icons/white/device.svg";
import w_deviceTagging from "assets/icons/white/device-tagging.svg";
import w_cage from "assets/icons/white/cage.svg";
import w_warehouse from "assets/icons/white/warehouse.svg";
import w_capacity from "assets/icons/white/capacity.svg";
import w_equipment from "assets/icons/white/equipment.svg";
import w_hr from "assets/icons/white/human-resource.svg";
import w_idManagement from "assets/icons/white/id-management.svg";
import w_recipe from "assets/icons/white/recipe.svg";
import w_rfid from "assets/icons/white/rfid.svg";
import w_report from "assets/icons/white/report.svg";
import w_adminLog from "assets/icons/white/admin-log.svg";
import g_home from "assets/icons/default/home.svg";
import g_company from "assets/icons/default/company.svg";
import g_farm from "assets/icons/default/farm.svg";
import g_user from "assets/icons/default/user.svg";
import g_role from "assets/icons/default/role.svg";
// import g_modules from "assets/icons/default/module.svg";
import g_device from "assets/icons/default/device.svg";
import g_deviceTagging from "assets/icons/default/device-tagging.svg";
import g_cage from "assets/icons/default/cage.svg";
import g_products from "assets/icons/default/products.svg";
import g_rawMaterial from "assets/icons/default/raw-material.svg";
import g_customer from "assets/icons/default/customer.svg";
import g_supplier from "assets/icons/default/supplier.svg";
import g_vehicle from "assets/icons/default/vehicle.svg";
import g_warehouse from "assets/icons/default/warehouse.svg";
import g_capacity from "assets/icons/default/capacity.svg";
import g_equipment from "assets/icons/default/equipment.svg";
import g_hr from "assets/icons/default/human-resource.svg";
import g_idManagement from "assets/icons/default/id-management.svg";
import g_recipe from "assets/icons/default/recipe.svg";
import g_rfid from "assets/icons/default/rfid.svg";
import g_report from "assets/icons/default/report.svg";
import g_adminLog from "assets/icons/default/admin-log.svg";
// core components/views for Admin layout
import HomePage from "components/AdminPortal/Home/Home";
import Company from "components/AdminPortal/Company/Company";
import Farm from "components/AdminPortal/Farm/Farm";
import User from "components/AdminPortal/User/User";
import Role from "components/AdminPortal/Role/Role";
// import Module from "components/AdminPortal/Module/Module";
import Device from "components/AdminPortal/Device/Device";
import DeviceTagging from "components/AdminPortal/DeviceTagging/DeviceTagging";
import DeviceSettings from "components/AdminPortal/DeviceSettings/DeviceSettings";
import Cages from "components/AdminPortal/Cages/Cages";
import RawMaterial from "components/AdminPortal/RawMaterial/RawMaterial";
import Product from "components/AdminPortal/Product/Product";
import Customer from "components/AdminPortal/Customer/Customer";
import Supplier from "components/AdminPortal/Supplier/Supplier";
import Vehicle from "components/AdminPortal/Vehicle/Vehicle";
import Warehouse from "components/AdminPortal/Warehouse/Warehouse";
import FarmCapacity from "components/AdminPortal/Capacity/FarmCapacity";
import TrayConfiguration from "components/AdminPortal/Capacity/TrayConfiguration";
import BatchCapacity from "components/AdminPortal/Capacity/BatchCapacity";
import Equipment from "components/AdminPortal/Equipment/Equipment";
import HumanResource from "components/AdminPortal/HumanResource/UserConfiguration/HumanResource";
import TaskSettings from "components/AdminPortal/HumanResource/TaskSettings/TaskSettings";
import IDManagement from "components/AdminPortal/IDManagement/IDManagement";
import Recipe from "components/AdminPortal/Recipe/Recipe";
import RFID from "components/AdminPortal/RFID/RFID";
import Report from "components/AdminPortal/Report/Report";
import AdminLog from "components/AdminPortal/AdminLog/AdminLog";
// core components/views for create/edit
import AddEditCompany from "components/AdminPortal/Company/AddEditCompany";
import SearchCompanyResult from "components/AdminPortal/Company/SearchCompanyResult";
import AddEditFarm from "components/AdminPortal/Farm/AddEditFarm";
import SearchFarmResult from "components/AdminPortal/Farm/SearchFarmResult";
import AddUser from "components/AdminPortal/User/AddUser";
import EditUser from "components/AdminPortal/User/EditUser";
import BatchUser from "components/AdminPortal/User/BatchUpdate";
import SearchUserResult from "components/AdminPortal/User/SearchUserResult";
import AddEditRole from "components/AdminPortal/Role/AddEditRole";
import SearchRoleResult from "components/AdminPortal/Role/SearchRoleResult";
// import AddEditModule from "components/AdminPortal/Module/AddEditModule";
import AddEditDevice from "components/AdminPortal/Device/AddEditDevice";
import AddEditDeviceTypes from "components/AdminPortal/Device/AddEditDeviceTypes";
import DeviceTypes from "components/AdminPortal/Device/DeviceTypes";
import AddEditReadingUnits from "components/AdminPortal/Device/AddEditReadingUnits";
import DeviceFunctions from "components/AdminPortal/Device/DeviceFunctions";
import BatchDevice from "components/AdminPortal/Device/BatchUpdate";
import SearchDeviceResult from "components/AdminPortal/Device/SearchDeviceResult";
import AddEditDeviceTagging from "components/AdminPortal/DeviceTagging/AddEditDeviceTagging";
import AddCages from "components/AdminPortal/Cages/AddCages";
import SearchCagesResult from "components/AdminPortal/Cages/SearchCagesResult";
import AddEditRawMaterial from "components/AdminPortal/RawMaterial/AddEditRawMaterial";
import RawMaterialTypeSetting from "components/AdminPortal/RawMaterial/RawMaterialTypeSetting";
import AddEditRawMaterialType from "components/AdminPortal/RawMaterial/AddEditRawMaterialType";
import VariantTypeSetting from "components/AdminPortal/RawMaterial/VariantTypeSetting";
import AddEditVariant from "components/AdminPortal/RawMaterial/AddEditVariant"
import SearchRawMaterialResult from "components/AdminPortal/RawMaterial/SearchRawMaterialResult";
import AddEditProduct from "components/AdminPortal/Product/AddEditProduct";
import ProductCategorySetting from "components/AdminPortal/Product/ProductCategorySetting";
import ProductCategoryTypeSetting from "components/AdminPortal/Product/ProductCategoryTypeSetting";
import AddEditProductCategoryTypeSetting from "components/AdminPortal/Product/AddEditProductCategoryTypeSetting";
import SearchProductResult from "components/AdminPortal/Product/SearchProductResult";
import Process from "components/AdminPortal/Product/Process";
// import AddEditCustomer from "components/AdminPortal/Customer/AddEditCustomer";
import SearchCustomerResult from "components/AdminPortal/Customer/SearchCustomerResult";
// import AddEditSupplier from "components/AdminPortal/Supplier/AddEditSupplier";
import SearchSupplierResult from "components/AdminPortal/Supplier/SearchSupplierResult";
import AddEditVehicle from "components/AdminPortal/Vehicle/AddEditVehicle";
import VehicleType from "components/AdminPortal/Vehicle/VehicleType";
import SearchVehicleResult from "components/AdminPortal/Vehicle/SearchVehicleResult";
import AddEditManufacturer from "components/AdminPortal/DeviceSettings/AddEditManufacturer";
// import AddEditWarehouse from "components/AdminPortal/Warehouse/AddEditWarehouse";
import SearchWarehouseResult from "components/AdminPortal/Warehouse/SearchWarehouseResult";
import CageCategory from "components/AdminPortal/Capacity/CageCategory"
import SearchCapacityResult from "components/AdminPortal/Capacity/SearchCapacityResult";
import ConfigureEquipment from "components/AdminPortal/Equipment/ConfigureEquipment";
import ConfigureMachine from "components/AdminPortal/Equipment/ConfigureMachine";
import SearchEquipmentResult from "components/AdminPortal/Equipment/SearchEquipmentResult";
import EditHumanResource from "components/AdminPortal/HumanResource/UserConfiguration/EditHumanResource";
import SearchHumanResourceResult from "components/AdminPortal/HumanResource/SearchHumanResourceResult";
import BatchRFID from "components/AdminPortal/RFID/BatchUpdate";
import SearchRFIDResult from "components/AdminPortal/RFID/SearchRFIDResult";
import SearchAdminLogResult from "components/AdminPortal/AdminLog/SearchAdminLogResult";

export const companyRoutes = [
  {
    path: "/company/create",
    component: AddEditCompany,
    permissionName: "Admin.Company",
    layout: "/admin",
  },
  {
    path: "/company/search",
    component: SearchCompanyResult,
    permissionName: "Admin.Company",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/company/:id",
    component: AddEditCompany,
    permissionName: "Admin.Company",
    layout: "/admin",
  },
];

export const farmRoutes = [
  {
    path: "/farm/create",
    component: AddEditFarm,
    permissionName: "Admin.Farm",
    layout: "/admin",
  },
  {
    path: "/farm/search",
    component: SearchFarmResult,
    permissionName: "Admin.Farm",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/farm/:id",
    component: AddEditFarm,
    permissionName: "Admin.Farm",
    layout: "/admin",
  },
];

export const userRoutes = [
  {
    path: "/user/create",
    component: AddUser,
    permissionName: "Admin.User",
    layout: "/admin",
  },
  {
    path: "/user/search",
    component: SearchUserResult,
    permissionName: "Admin.User",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/user/batch",
    component: BatchUser,
    permissionName: "Admin.User",
    layout: "/admin",
  },
  {
    path: "/user/:id",
    component: EditUser,
    permissionName: "Admin.User",
    layout: "/admin",
  },
];

export const roleRoutes = [
  {
    path: "/role/create",
    component: AddEditRole,
    permissionName: "Admin.Role",
    layout: "/admin",
  },
  {
    path: "/role/search",
    component: SearchRoleResult,
    permissionName: "Admin.Role",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/role/:id",
    component: AddEditRole,
    permissionName: "Admin.Role",
    layout: "/admin",
  },
];

// export const moduleRoutes = [
//   {
//     path: "/module/create",
//     component: AddEditModule,
//     permissionName: "Admin.Module",
//     layout: "/admin",
//   },
//   {
//     path: "/module/:id",
//     component: AddEditModule,
//     permissionName: "Admin.Module",
//     layout: "/admin",
//   },
// ];

export const cagesRoutes = [
  {
    path: "/cages/search",
    component: SearchCagesResult,
    // permissionName: "Admin.Equipment",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/cages/create",
    component: AddCages,
    // permissionName: "Admin.Module",
    layout: "/admin",
  },
  {
    path: "/cages/:id",
    component: AddCages,
    // permissionName: "Admin.Module",
    layout: "/admin",
  },
];

export const rawMaterialRoutes = [
  {
    path: "/raw-material/create",
    component: AddEditRawMaterial,
    permissionName: "Admin.Raw Material",
    layout: "/admin",
  },
  {
    path: "/raw-material/type/:id",
    component: AddEditRawMaterialType,
    permissionName: "Admin.Raw Material",
    layout: "/admin",
  },
  {
    path: "/raw-material/type",
    component: RawMaterialTypeSetting,
    permissionName: "Admin.Raw Material",
    layout: "/admin",
  },
  {
    path: "/raw-material/variant/:id",
    component: AddEditVariant,
    permissionName: "Admin.Raw Material",
    layout: "/admin",
  },
  {
    path: "/raw-material/variant",
    component: VariantTypeSetting,
    permissionName: "Admin.Raw Material",
    layout: "/admin",
  },
  {
    path: "/raw-material/search",
    component: SearchRawMaterialResult,
    permissionName: "Admin.Raw Material",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/raw-material/:id",
    component: AddEditRawMaterial,
    permissionName: "Admin.Raw Material",
    layout: "/admin",
  },
];

export const productRoutes = [
  {
    path: "/products/create",
    component: AddEditProduct,
    permissionName: "Admin.Products",
    layout: "/admin",
  },
  {
    path: "/products/category",
    component: ProductCategorySetting,
    permissionName: "Admin.Products",
    layout: "/admin",
  },
  {
    path: "/products/type/:id",
    component: AddEditProductCategoryTypeSetting,
    permissionName: "Admin.Products",
    layout: "/admin",
  },
  {
    path: "/products/type",
    component: ProductCategoryTypeSetting,
    permissionName: "Admin.Products",
    layout: "/admin",
  },
  {
    path: "/products/search",
    component: SearchProductResult,
    permissionName: "Admin.Products",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/products/process/:id",
    permissionName: "Admin.Products",
    readOnly: true,
    component: Process,
    layout: "/admin",
  },
  {
    path: "/products/:id",
    component: AddEditProduct,
    permissionName: "Admin.Products",
    layout: "/admin",
  },
]

export const customerRoutes = [
  // {
  //   path: "/customer/create",
  //   component: AddEditCustomer,
  //   permissionName: "Admin.Customer",
  //   layout: "/admin",
  // },
  {
    path: "/customer/search",
    component: SearchCustomerResult,
    permissionName: "Admin.Customer",
    readOnly: true,
    layout: "/admin",
  },
  // {
  //   path: "/customer/:id",
  //   component: AddEditCustomer,
  //   permissionName: "Admin.Customer",
  //   layout: "/admin",
  // },
];

export const supplierRoutes = [
  // {
  //   path: "/supplier/create",
  //   component: AddEditSupplier,
  //   permissionName: "Admin.Supplier",
  //   layout: "/admin",
  // },
  {
    path: "/supplier/search",
    component: SearchSupplierResult,
    permissionName: "Admin.Supplier",
    readOnly: true,
    layout: "/admin",
  },
  // {
  //   path: "/supplier/:id",
  //   component: AddEditSupplier,
  //   permissionName: "Admin.Supplier",
  //   layout: "/admin",
  // },
];

export const vehicleRoutes = [
  {
    path: "/vehicle/create",
    component: AddEditVehicle,
    permissionName: "Admin.Vehicle",
    layout: "/admin",
  },
  {
    path: "/vehicle/search",
    component: SearchVehicleResult,
    permissionName: "Admin.Vehicle",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/vehicle/type",
    component: VehicleType,
    permissionName: "Admin.Vehicle",
    layout: "/admin",
  },
  {
    path: "/vehicle/:id",
    component: AddEditVehicle,
    permissionName: "Admin.Vehicle",
    layout: "/admin",
  },
];

export const deviceRoutes = [
  {
    path: "/device/create",
    component: AddEditDevice,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
  {
    path: "/device/batch",
    component: BatchDevice,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
  {
    path: "/device/search",
    component: SearchDeviceResult,
    permissionName: "Admin.Device",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/device/type/:id",
    component: AddEditDeviceTypes,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
  {
    path: "/device/type",
    component: DeviceTypes,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
  {
    path: "/device/function/:id",
    component: AddEditReadingUnits,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
  {
    path: "/device/function",
    component: DeviceFunctions,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
  {
    path: "/device/:id",
    component: AddEditDevice,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
];

export const deviceTaggingRoutes = [
  {
    path: "/device-tagging/edit",
    component: AddEditDeviceTagging,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
  {
    path: "/device-tagging/search",
    component: SearchDeviceResult,
    permissionName: "Admin.Device",
    readOnly: true,
    layout: "/admin",
  },
];

export const deviceSettingsRoutes= [
  {
    path: "/device-settings/create",
    component: AddEditManufacturer,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
  {
    path: "/device-settings/search",
    component: SearchDeviceResult,
    permissionName: "Admin.Device",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/device-settings/:id",
    component: AddEditManufacturer,
    permissionName: "Admin.Device",
    layout: "/admin",
  },
]

export const warehouseRoutes = [
  // {
  //   path: "/warehouse/create",
  //   component: AddEditWarehouse,
  //   permissionName: "Admin.Warehouse",
  //   layout: "/admin",
  // },
  {
    path: "/warehouse/search",
    component: SearchWarehouseResult,
    permissionName: "Admin.Warehouse",
    readOnly: true,
    layout: "/admin",
  },
  // {
  //   path: "/warehouse/:id",
  //   component: AddEditWarehouse,
  //   permissionName: "Admin.Warehouse",
  //   layout: "/admin",
  // },
];

export const capacityRoutes = [
  {
    path: "/capacity/search",
    component: SearchCapacityResult,
    permissionName: "Admin.Capacity",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/capacity/farm-capacity/edit",
    component: CageCategory,
    permissionName: "Admin.Capacity",
    layout: "/admin",
  },
];

export const capacitySubRoutes = [
  {
    path: "/capacity/farm-capacity",
    name: "Farm Capacity",
    component: FarmCapacity,
    permissionName: "Admin.Capacity",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/capacity/trays-per-configuration",
    name: "Trays Per Configuration",
    component: TrayConfiguration,
    permissionName: "Admin.Capacity",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/capacity/farm-capacity-per-batch",
    name: "Farm Capacity Per Batch",
    component: BatchCapacity,
    permissionName: "Admin.Capacity",
    readOnly: true,
    layout: "/admin",
  }
];

export const equipmentRoutes= [
  {
    path: "/equipment/configure/:id",
    component: ConfigureMachine,
    permissionName: "Admin.Equipment",
    layout: "/admin",
  },
  {
    path: "/equipment/configure",
    component: ConfigureEquipment,
    permissionName: "Admin.Equipment",
    layout: "/admin",
  },
  {
    path: "/equipment/search",
    component: SearchEquipmentResult,
    permissionName: "Admin.Equipment",
    readOnly: true,
    layout: "/admin",
  },
]

export const humanResourceRoutes= [
  {
    path: "/human-resource/search",
    component: SearchHumanResourceResult,
    permissionName: "Admin.Human Resource",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/human-resource/user-configuration/:id",
    component: EditHumanResource,
    permissionName: "Admin.Human Resource",
    layout: "/admin",
  },
]

export const humanResourceSubRoutes = [
  {
    path: "/human-resource/user-configuration",
    name: "User Configuration",
    component: HumanResource,
    permissionName: "Admin.Human Resource",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/human-resource/task-settings",
    name: "Task Settings",
    component: TaskSettings,
    permissionName: "Admin.Human Resource",
    readOnly: true,
    layout: "/admin",
  },
];

export const rfidRoutes= [
  {
    path: "/rfid/search",
    component: SearchRFIDResult,
    permissionName: "Admin.RFID",
    readOnly: true,
    layout: "/admin",
  },
  {
    path: "/rfid/batch",
    component: BatchRFID,
    permissionName: "Admin.RFID",
    layout: "/admin",
  },
]

export const adminLogRoutes= [
  {
    path: "/admin-log/search",
    component: SearchAdminLogResult,
    permissionName: "Admin.Admin Log",
    readOnly: true,
    layout: "/admin",
  },
]

export const dashboardRoutes = [
  {
    path: "/home",
    name: "Home Admin",
    header: "FOMS Administration Portal",
    icon: {white: w_home, grey: g_home},
    component: HomePage,
    layout: "/admin",
  },
  {
    path: "/company",
    name: "Company",
    permissionName: "Admin.Company",
    header: "Company Creation & Management",
    canCreate: true,
    searchPlaceholder: "Search within Company Module",
    icon: {white: w_company, grey: g_company},
    component: Company,
    layout: "/admin",
  },
  {
    path: "/farm",
    name: "Farm",
    permissionName: "Admin.Farm",
    header: "Farm Creation & Management",
    canCreate: true,
    searchPlaceholder: "Search within Farm Module",
    icon: {white: w_farm, grey: g_farm},
    component: Farm,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User",
    permissionName: "Admin.User",
    header: "User Creation & Management",
    canCreate: true,
    searchPlaceholder: "Search within User Module",
    icon: {white: w_user, grey: g_user},
    component: User,
    layout: "/admin",
  },
  {
    path: "/role",
    name: "Role",
    permissionName: "Admin.Role",
    header: "Role Creation & Management",
    canCreate: true,
    searchPlaceholder: "Search within Role Module",
    icon: {white: w_role, grey: g_role},
    component: Role,
    layout: "/admin",
  },
  // {
  //   path: "/module",
  //   name: "Module",
  //   permissionName: "Admin.Module",
  //   header: "Module Creation & Management",
  //   canCreate: true,
  //   searchPlaceholder: "Search within Module",
  //   icon: {white: w_modules, grey: g_modules},
  //   component: Module,
  //   layout: "/admin",
  // },
  {
    path: "/device",
    name: "Device",
    permissionName: "Admin.Device",
    header: "Device Creation & Management",
    canCreate: true,
    searchPlaceholder: "Search within Device Module",
    icon: {white: w_device, grey: g_device},
    component: Device,
    layout: "/admin",
  },
  {
    path: "/device-tagging",
    name: "Device Tagging",
    permissionName: "Admin.Device",
    header: "Device Tagging",
    canCreate: true,
    searchPlaceholder: "Search within Device Module",
    icon: {white: w_deviceTagging, grey: g_deviceTagging},
    component: DeviceTagging,
    layout: "/admin",
  },
  {
    path: "/device-settings",
    name: "Device Settings",
    permissionName: "Admin.Device",
    header: "Device Settings",
    canCreate: true,
    searchPlaceholder: "Search within Device Module",
    icon: {white: w_adminLog, grey: g_adminLog},
    component: DeviceSettings,
    layout: "/admin",
  },
  {
    path: "/cages",
    name: "Cages",
    //permissionName: "Quality Control",
    header: "Cages",
    canCreate: true,
    searchPlaceholder: "Search within Cages Module",
    icon: {white: w_cage, grey: g_cage},
    component: Cages,
    layout: "/admin",
  },
  {
    path: "/raw-material",
    name: "Raw Materials",
    permissionName: "Admin.Raw Material",
    header: "Raw Material Creation & Management",
    canCreate: true,
    searchPlaceholder: "Search within Raw Material Module",
    icon: {white: w_rawMaterial, grey: g_rawMaterial},
    component: RawMaterial,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    permissionName: "Admin.Products",
    header: "Product Creation & Management",
    canCreate: true,
    searchPlaceholder: "Search within Products Module",
    icon: {white: w_products, grey: g_products},
    component: Product,
    layout: "/admin",
  },
  {
    path: "/customer",
    name: "Customer",
    permissionName: "Admin.Customer",
    header: "Customer Creation & Management",
    // canCreate: true,
    searchPlaceholder: "Search within Customer Module",
    icon: {white: w_customer, grey: g_customer},
    component: Customer,
    layout: "/admin",
  },
  {
    path: "/supplier",
    name: "Supplier",
    permissionName: "Admin.Supplier",
    header: "Supplier Creation & Management",
    // canCreate: true,
    searchPlaceholder: "Search within Supplier Module",
    icon: {white: w_supplier, grey: g_supplier},
    component: Supplier,
    layout: "/admin",
  },
  {
    path: "/vehicle",
    name: "Vehicle",
    permissionName: "Admin.Vehicle",
    header: "Vehicle Creation & Management",
    canCreate: true,
    searchPlaceholder: "Search within Vehicle Module",
    icon: {white: w_vehicle, grey: g_vehicle},
    component: Vehicle,
    layout: "/admin",
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    permissionName: "Admin.Warehouse",
    header: "Warehouse Creation & Management",
    // canCreate: true,
    searchPlaceholder: "Search within Warehouse Module",
    icon: {white: w_warehouse, grey: g_warehouse},
    component: Warehouse,
    layout: "/admin",
  },
  {
    path: "/capacity",
    name: "Capacity",
    permissionName: "Admin.Capacity",
    header: "Capacity",
    searchPlaceholder: "Search within Capacity Module",
    icon: {white: w_capacity, grey: g_capacity},
    layout: "/admin",
    subMenu: capacitySubRoutes
  },
  {
    path: "/equipment",
    name: "Equipment",
    permissionName: "Admin.Equipment",
    header: "Equipment Capacity",
    canCreate: true,
    searchPlaceholder: "Search within Equipment Capacity Module",
    icon: {white: w_equipment, grey: g_equipment},
    component: Equipment,
    layout: "/admin",
  },
  {
    path: "/human-resource",
    name: "Human Resource",
    permissionName: "Admin.Human Resource",
    header: "Human Resource Management",
    searchPlaceholder: "Search within Human Resource Module",
    icon: {white: w_hr, grey: g_hr},
    layout: "/admin",
    subMenu: humanResourceSubRoutes
  },
  {
    path: "/id-management",
    name: "ID Management",
    header: "ID Management",
    icon: {white: w_idManagement, grey: g_idManagement},
    component: IDManagement,
    layout: "/admin",
  },
  {
    path: "/recipe",
    name: "Recipe",
    permissionName: "Admin.Recipe",
    header: "Recipe Management",
    icon: {white: w_recipe, grey: g_recipe},
    component: Recipe,
    layout: "/admin",
  },
  {
    path: "/rfid",
    name: "RFID",
    permissionName: "Admin.RFID",
    header: "RFID Management",
    searchPlaceholder: "Search within RFID Module",
    icon: {white: w_rfid, grey: g_rfid},
    component: RFID,
    layout: "/admin",
  },
  {
    path: "/report",
    name: "Report",
    permissionName: "Admin.Report",
    header: "Report",
    icon: {white: w_report, grey: g_report},
    component: Report,
    layout: "/admin",
  },
  {
    path: "/admin-log",
    name: "Admin Log",
    permissionName: "Admin.Admin Log",
    header: "Admin Logs",
    searchPlaceholder: "Search within Admin Log Module",
    icon: {white: w_adminLog, grey: g_adminLog},
    component: AdminLog,
    layout: "/admin",
  },
];
