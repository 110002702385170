import { createSlice } from "@reduxjs/toolkit";
import { GetActiveUsersReport, GetActivationRatePercentReport, GetActiveRatePercentReport, GetModuleStatistics } from "services/AdminPortal/ReportService";

// initial state
const initialState = {
  activeUsers: [],
  activeRatePercent: [],
  activationRatePercent: [],
  moduleStatistics: []
};

const report = createSlice({
  name: "report",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetActiveUsersReport.fulfilled, (state, action) => {
        state.activeUsers = action.payload.result;
      })
      .addCase(GetActiveRatePercentReport.fulfilled, (state, action) => {
        state.activeRatePercent = action.payload.result;
      })
      .addCase(GetActivationRatePercentReport.fulfilled, (state, action) => {
        state.activationRatePercent = action.payload.result;
      })
      .addCase(GetModuleStatistics.fulfilled, (state, action) => {
        state.moduleStatistics = action.payload.result;
      })
  },
});

// export actions
export const { reset } = report.actions;

// export the reducer
export default report.reducer;