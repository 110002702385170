import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from 'qrcode.react';
import _ from "lodash";
// @mui/material
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";

import { setValues as setGeneralValues } from "store/common";
import { setSearchText } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues, reset } from ".";
import { GetDeviceList } from "services/UserPortal/CommonLookupService";
import { TracingCodeGeneratorModuleForStatisticsReport } from "services/UserPortal/CodeGeneratorService";

import styles from "assets/jss/components/UserPortal/codeGenerator.module.scss";


export default function GenerateQR() {
  const dispatch = useDispatch();
  const deviceList = useSelector(store => store.common.deviceList);
  const qr = useSelector(store => store.user.codeGenerator.qr);
  const [page, setPage] = React.useState(0); // for device list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for device list
  const [searchValue, setSearchValue] = React.useState(""); // for device list
  const [generate, setGenerate] = React.useState(""); // for device list

  const handleOnChange_autocomplete = (value) => {
    setGenerate(null);
    if (value) {
      dispatch(setValues({qr: {device: value, deviceRefNo: typeof value === "object" ? value.deviceRefNo : value}}));
    }
  };

  const handleButtonClick_generate = () => {
    setGenerate(qr.deviceRefNo);
  };
  
  const fetchMoreData = (search, pageNo) => {
    if ((page !== -1 || pageNo !== undefined)) {
      setIsLoadingMore(true);
      const param = {
        page: pageNo !== undefined ? pageNo : page,
        DeviceRefNo: search ? search : "",
      }
      dispatch(GetDeviceList(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result&&response.payload.result.items.length) {
            setPage(pageNo !== undefined ? pageNo+1 : page+1);
            if (search || page === 0 || pageNo === 0) {
              dispatch(setGeneralValues({deviceList: response.payload.result.items}));
            } else {
              dispatch(setGeneralValues({deviceList: _.unionBy(deviceList, response.payload.result.items, "id")}));
            }
          } else {
            setPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const triggerDownload = (imgURI) => {
    var evt = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true
    });
  
    var a = document.createElement('a');
    a.setAttribute('download', 'QR_'+qr.deviceRefNo+'.png');
    a.setAttribute('href', imgURI);
    a.setAttribute('target', '_blank');
  
    a.dispatchEvent(evt);
  }

  const handleButtonClick_download = () => {
    const qrCodeURL = document.getElementById('qrCodeEl');
    var canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 200;
    var ctx = canvas.getContext('2d');
    var data = (new XMLSerializer()).serializeToString(qrCodeURL);
    var DOMURL = window.URL || window.webkitURL || window;

    var img = new Image();
    var svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
    var url = DOMURL.createObjectURL(svgBlob);

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);

      var imgURI = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');

      triggerDownload(imgURI);
    };

    img.src = url;
  }

  const handleButtonClick_print = useReactToPrint({
    content: () => document.getElementById('qrCodeContainer'),
    documentTitle: "QR_"+qr.deviceRefNo,
    removeAfterPrint: true
  })

  // componentDidMount
  React.useEffect(() => {
    dispatch(TracingCodeGeneratorModuleForStatisticsReport());
    dispatch(GetDeviceList({page: 0}))
    .then(({payload}) => {
      if (payload.result && payload.result.items.length) {
        dispatch(setGeneralValues({deviceList: payload.result.items}));
      }
    });
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Generate QR Code"
        subheader="Input an ID to generate QR code"
      >
        <div className={styles.inputContainer}>
          <Autocomplete
            freeSolo
            id="deviceId"
            dataCount={page*10}
            options={deviceList}
            fetchData={()=>fetchMoreData()}
            renderOption={(option) => option.deviceRefNo}
            isLoadingMore={isLoadingMore}
            placeholder="Input an ID"
            onInputChange={(e, newInputValue) => {
              // if (e && e._reactName == "onBlur") {
              //   setPage(0);
              //   setSearchValue(qr.device?qr.device.deviceRefNo:"");
              //   fetchMoreData(newInputValue, 0);
              // } 
              if (e && e._reactName == "onClick") {
                setPage(0);
                setSearchValue(newInputValue);
                fetchMoreData(undefined, 0);
              }
              if (e && e._reactName == "onChange") {
                setPage(0);
                setSearchValue(newInputValue);
                fetchMoreData(newInputValue, 0);
                handleOnChange_autocomplete(newInputValue);
              }
            }}
            onChange={(_, newValue) => {
              handleOnChange_autocomplete(newValue);
            }}
            inputValue={searchValue}
            value={searchValue!==(qr.device&&qr.device.deviceRefNo) ? null : qr.device}
            renderValue={"deviceRefNo"}
          />
          <Button className={styles.button} onClick={() => handleButtonClick_generate()} disabled={!qr.deviceRefNo}>Generate</Button>
        </div>
        {generate &&
          <div className={styles.qrContainer}>
            <div className={styles.qrContent}>
              <div id="qrCodeContainer" className="m-10">
                <QRCodeSVG 
                  id="qrCodeEl"
                  value={generate}
                  width={200}
                  height={200}
                />
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.buttonSecondary}
                  onClick={handleButtonClick_print}
                >
                  Print
                </Button>
                <Button
                  className={styles.button}
                  onClick={handleButtonClick_download}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        }
      </Card>
    </React.Fragment>
  );
}
