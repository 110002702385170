import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import { GetAllFloorPlan, CreateFloorPlans } from "services/AdminPortal/DeviceTaggingService";
import { UploadFloorPlanDiagram } from "services/AdminPortal/UploadService";

// initial state
const initialState = {
  floors: [{}],
  floorPlan: {}, //step 2
};

const floorPlan = createSlice({
  name: "floorPlan",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.floors = action.payload;
    },
    addRequest: (state, action) => {
      state.floors = action.payload;
    },
    deleteRequest: (state, action) => {
      if (state.floors.length > 1) {
        state.floors.splice(action.payload, 1);
      } else {
        state.floors = [{}];
      }
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.floors[action.payload.index][key] = action.payload[key];
      })
    },
    setFloorPlanValues: (state, action) => {
      state.floorPlan = action.payload;
    },
    updateFloorPlanRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.floorPlan[key] = action.payload[key];
      })
    },
    updateImg: (state, action) => {
      state.floorPlan.floorPlanDiagramUrl = action.payload;
    },
    resetFloorPlan: (state) => {
      state.floorPlan = {};
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllFloorPlan.fulfilled, (state, action) => {
        if (action.payload.result) {
          let payload = _.orderBy(action.payload.result.items, ["floorNumber"], ["asc"]);
          payload = _.forEach(payload, (floor) => {
            floor.sectors = _.orderBy(floor.sectors, ["sectorNumber"], ["asc"]);
          });
          state.floors = payload;
        } else {
          state.floors = [{}];
        }
      })
      .addCase(CreateFloorPlans.fulfilled, (state, action) => {
        state.floors = action.payload.result;
      })
      .addCase(UploadFloorPlanDiagram.fulfilled, (state, action) => {
        state.floorPlan.floorPlanDiagramUrl = action.payload.result;
      })
  },
});

// export actions
export const { setValues, addRequest, deleteRequest, updateRequest, 
  setFloorPlanValues, updateFloorPlanRequest, updateImg, resetFloorPlan,
  reset } = floorPlan.actions;

// export the reducer
export default floorPlan.reducer;