import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
// @mui/material
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import ActiveAlarm from "./ActiveAlarm";
import AlarmTable from "./AlarmTable";
import { setSearchText } from "store/general";
import { reset } from ".";

import styles from "assets/jss/components/UserPortal/alarm.module.scss";

export default function AlarmTab(props) {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);
  const [filter, setFilter] = React.useState({timeStamp: null, resolvedTimeStamp: null});
  
  const items = [
    {title: "Operation Module", url: "/user/alarm/details/operations"},
    {title: "Equipment Module", url: "/user/alarm/details/equipment"},
    {title: "Raw Material Module", url: "/user/alarm/details/raw-material"},
    {title: "Climate & Control Module", url: "/user/alarm/details/climate"},
  ]

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date" && moment(value).isAfter('2000-01-01', 'year')) {
      setFilter({...filter, [field]: moment(value).format("YYYY-MM-DD")});
    } 
  };

  React.useEffect(() => {
    setTab(props.location.state ? props.location.state.tab : 0);
  },[props.location.state]);

  React.useEffect(() => {
    setFilter({timeStamp: null, resolvedTimeStamp: null});
  },[tab]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  const renderComponent = () => {
    if (tab===0) {
      return <ActiveAlarm {...props} filter={filter} />
    } else {
      return <AlarmTable {...props} filter={filter} index={tab} />
    }
  }

  const alarmPage = items.find(({url})=>url===props.location.pathname);
  return (
    <React.Fragment>
      <Card 
        title = {alarmPage && alarmPage.title}
        subheader={
          <Tabs 
            tabs={["Active Alarms", "Closed Alarms", "Notifications"]}
            value={tab} 
            onChange={(e,value) => {setTab(value); setFilter({timeStamp: null, resolvedTimeStamp: null});}}
          />
        }
        action={
          <React.Fragment>
            <DateTimePicker
              closeOnSelect
              clearable
              placeholder="Alarm date"
              className={styles.cardActionDropdown}
              value={filter.timeStamp}
              onChange={(e) => handleOnChange_date(e, "timeStamp")}
              onClear={()=>setFilter({...filter, timeStamp: null})}
            />
            { tab===1 &&
              <DateTimePicker
                closeOnSelect
                clearable
                placeholder="Resolved Date"
                className={styles.cardActionDropdown}
                value={filter.resolvedTimeStamp}
                onChange={(e) => handleOnChange_date(e, "resolvedTimeStamp")}
                onClear={()=>setFilter({...filter, resolvedTimeStamp: null})}
              />
            }
          </React.Fragment>
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
