import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import OldVegetableReport from "./OldVegetableReport";
import { setSearchText } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues } from "./store/vegetable";
import { GetRandDReportDetail, GetProductInfoById } from "services/UserPortal/QualityControlService";
import VegetableReport from "./VegetableReport";

export default function Report(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const qualityControl = useSelector(store => store.user.rnd.vegetable.qualityControl);
  
  React.useEffect(() => {
    if (qualityControl.productId) {
      dispatch(GetProductInfoById(qualityControl.productId));
    }
  },[qualityControl.productId]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    const prop = props.location.state;
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "report");
    dispatch(GetRandDReportDetail({id: path[index+1], isPastRecord: prop && prop.isPastRecord}))
    .then(({error}) => {
      if (error) {
        history.goBack();
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(setValues({qualityControl: {healthConditions: []}, totalHorizontal: 0, totalVertical: 0}));
      dispatch(resetNav());
    }
  },[]);

  if(qualityControl.isOldRecord) {
    // old records
    return <OldVegetableReport />;
  } else {
    return <VegetableReport />
  }
}
