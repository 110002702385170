import React from "react";
// @mui/material components
import { styled } from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import palette from "assets/jss/general.module.scss";

const OrangeRadio = styled(Radio)({
  color: palette.secondary,
  '&.Mui-checked': {
    color: palette.secondary,
    '&.Mui-disabled': {
      color: palette.secondary,
      opacity: 0.7
    },
  },
});

export default function CustomRadio(props) {
  return (
    <OrangeRadio 
      {...props}
    />
  );
}