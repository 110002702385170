import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetUserProfile, UpdateProfile, GetUserFarmList, GetActiveFarm, SetActiveFarm } from "services/AdminPortal/UserService";
import { UploadUserProfile } from "services/AdminPortal/UploadService";

// initial state
const initialState = {
  profile: {},
  farms: [],
  farm: {}
};

// create reducer and action creators
const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateImg: (state, action) => {
      state.profile.profileUrl = action.payload;
    },
    updateProfileRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.profile[key] = action.payload[key];
      })
    },
    updateFarmRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.farm[key] = action.payload[key];
      })
    },
    resetPassword: (state) => {
      state.profile.oldPassword = null;
      state.profile.newPassword = null;
      state.profile.confirmPassword = null;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserProfile.fulfilled, (state, action) => {
        const result = action.payload.result;
        state.profile = {
          ..._.pick(result, ["id", "profileUrl", "userName", "emailAddress", "name", "phone", "timeZone"]),
          role: _.startCase(_.toLower(result.roleNames[0])),
        }
      })
      .addCase(UploadUserProfile.fulfilled, (state, action) => {
        state.profile.profileUrl = action.payload.result;
      })
      .addCase(UpdateProfile.fulfilled, (state, action) => {
        const result = action.payload.result;
        state.profile = {
          ..._.pick(result, ["id", "profileUrl", "userName", "emailAddress", "name", "phone", "timeZone"]),
          role: _.startCase(_.toLower(result.roleNames[0])),
        }
      })
      .addCase(GetUserFarmList.fulfilled, (state, action) => {
        state.farms = _.orderBy(action.payload.result, ["farmName"], ["asc"]);
      })
      .addCase(GetActiveFarm.fulfilled, (state, action) => {
        state.farm.currentFarm = action.payload.result;
      })
      .addCase(SetActiveFarm.fulfilled, (state, action) => {
        state.farm.currentFarm = action.payload.result;
        state.farm.newFarm = null;
      })
  },
});

// export actions
export const { updateImg, updateProfileRequest, updateFarmRequest, resetPassword, reset } = profile.actions;

// export the reducer
export default profile.reducer;