import { createAsyncThunk } from "@reduxjs/toolkit";
import { find, pick } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

// Lookup
export const GetWorkOrdersLookupForAdjustment = createAsyncThunk(
  "planning/getWorkOrdersLookupForAdjustment", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetWorkOrdersLookupForAdjustment", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllOtherDevicesByWorkOrderId = createAsyncThunk(
  "planning/getAllOtherDevicesByWorkOrderId", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetAllOtherDevicesByWorkOrderId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);


// Planning Outcome
export const GetWorkOrderPlanningOutcomeList = createAsyncThunk(
  "planning/getWorkOrderPlanningOutcomeList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetWorkOrderPlanningOutcomeList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Conversions
export const GetWorkOrderConversionToProduct = createAsyncThunk(
  "planning/getWorkOrderConversionToProduct", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetWorkOrderConversionToProduct", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Adjustment
export const GetSubSaleOrdersByWorkOrderIdForAdjustment = createAsyncThunk(
  "planning/getSubSaleOrdersByWorkOrderIdForAdjustment", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetSubSaleOrdersByWorkOrderIdForAdjustment", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSimilarSubSaleOrdersForAdjustment = createAsyncThunk(
  "planning/getSimilarSubSaleOrdersForAdjustment", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetSimilarSubSaleOrdersForAdjustMent", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreatePlanningAdjustment = createAsyncThunk(
  "planning/createPlanningAdjustment", 
  async (_, {getState, rejectWithValue}) => {
    const body = pick(getState().user.planning.adjustment.adjust, ["workOrderId", "oldSubSaleOrderNumber", "newSubSaleOrderNumber"]);
    try {
      const response = await FomsMS.post("/userportal/Planning/CreatePlanningAdjustment", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Unassigned Orders
export const GetUnassignedList = createAsyncThunk(
  "planning/getUnassignedList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetUnassignedList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetUnAssignedStatusLookUp = createAsyncThunk(
  "planning/getUnAssignedStatusLookUp", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Planning/GetUnAssignedStatusLookUp", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetPurchaseOrderLookUp = createAsyncThunk(
  "planning/getPurchaseOrderLookUp", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetPurchaseOrderLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSubSaleOrderLookUp = createAsyncThunk(
  "planning/getSubSaleOrderLookUp", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetSubSaleOrderLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Maximum Order per Day
export const GetProductMaximumOrderPerDay = createAsyncThunk(
  "planning/getProductMaximumOrderPerDay", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetProductMaximumOrderPerDay", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateProductMaximumOrderPerDay = createAsyncThunk(
  "planning/createOrUpdateProductMaximumOrderPerDay", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.planning.maximum.order;
    try {
      const response = await FomsMS.post("/userportal/Planning/CreateOrUpdateProductMaximumOrderPerDay", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductList  = createAsyncThunk(
  "planning/getProductList ", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetProductList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Report
export const GetPlanningReport = createAsyncThunk(
  "planning/getPlanningReport", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetPlanningReport", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetPlanningReportByDateList = createAsyncThunk(
  "planning/getPlanningReportByDateList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetPlanningReportByDateList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateRawMaterialRequest = createAsyncThunk(
  "planning/createRawMaterialRequest", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.planning.report.rawMaterial;
    try {
      const response = await FomsMS.post("/userportal/Planning/CreateRawMaterialRequest", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const RejectRawMaterialRequest = createAsyncThunk(
  "planning/rejectRawMaterialRequest", 
  async (id, {rejectWithValue}) => {
    const params = {id}
    try {
      const response = await FomsMS.get("/userportal/Planning/RejectRawMaterialRequest", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllRawMaterialReserved = createAsyncThunk(
  "planning/getAllRawMaterialReserved", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetAllRawMaterialReserved", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadRawMaterialReserved = createAsyncThunk(
  "planning/downloadRawMaterialReserved", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const config = {
      responseType: 'blob',
      params: {...param, farmId: find(userDetail.farms, "isActive").id},
      stopLoading: true
    }
    try {
      const response = await FomsMS.get("/userportal/Planning/DownloadRawMaterialReserved", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllEquipmentReserved = createAsyncThunk(
  "planning/getAllEquipmentReserved", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetAllEquipmentReserved", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadEquipmentReserved = createAsyncThunk(
  "planning/downloadEquipmentReserved", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const config = {
      responseType: 'blob',
      params: {...param, farmId: find(userDetail.farms, "isActive").id},
      stopLoading: true
    }
    try {
      const response = await FomsMS.get("/userportal/Planning/DownloadEquipmentReserved", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllOtherDevicesReserved = createAsyncThunk(
  "planning/getAllOtherDevicesReserved", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetAllOtherDevicesReserved", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadOtherDevicesReserved = createAsyncThunk(
  "planning/downloadOtherDevicesReserved", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const config = {
      responseType: 'blob',
      params: {...param, farmId: find(userDetail.farms, "isActive").id},
      stopLoading: true
    }
    try {
      const response = await FomsMS.get("/userportal/Planning/DownloadOtherDevicesReserved", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Settings
export const GetPlanningConfiguration = createAsyncThunk(
  "planning/getPlanningConfiguration", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetPlanningConfiguration", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTimeToRunList = createAsyncThunk(
  "planning/getTimeToRunList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Planning/GetTimeToRunList", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdatePlanningConfiguration = createAsyncThunk(
  "planning/createOrUpdatePlanningConfiguration", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.planning.settings.setting;
    try {
      const response = await FomsMS.post("/userportal/Planning/CreateOrUpdatePlanningConfiguration", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllExclusionDays = createAsyncThunk(
  "planning/getAllExclusionDays", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetAllExclusiondays", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateExclusionDays = createAsyncThunk(
  "planning/createOrUpdateExclusionDays", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...getState().user.planning.settings.exclusion,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/userportal/Planning/CreateOrUpdateExclusionDays", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetPlanningParametersByFarmId = createAsyncThunk(
  "planning/getPlanningParametersByFarmId", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetPlanningParametersByFarmId", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Capacity
export const GetProductListForTrays = createAsyncThunk(
  "planning/getProductListForTrays", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetProductListForTrays", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetBankInfoById = createAsyncThunk(
  "planning/getBankInfoById", 
  async (id, {rejectWithValue}) => {
    const params = {
      bankId: id,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetBankInfoById", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCageInfoById = createAsyncThunk(
  "planning/getCageInfoById", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/GetCageInfoById", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateTrayInfo = createAsyncThunk(
  "planning/updateTrayInfo", 
  async (_, {getState, rejectWithValue}) => {
    const body = {
      ...getState().user.capacity.capacity.tray,
    };
    try {
      const response = await FomsMS.post("/userportal/Planning/UpdateTrayInfo", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateBoardInfo = createAsyncThunk(
  "planning/updateBoardInfo", 
  async (_, {getState, rejectWithValue}) => {
    const body = {
      ...getState().user.capacity.capacity.board,
    };
    try {
      const response = await FomsMS.post("/userportal/Planning/UpdateBoardInfo", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateCageInfo = createAsyncThunk(
  "planning/updateCageInfo", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.capacity.capacity.trays;
    try {
      const response = await FomsMS.post("/userportal/Planning/UpdateCageInfo", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteCageInfo = createAsyncThunk(
  "planning/deleteCageInfo", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        capacityId: id
      };
      const response = await FomsMS.delete("/userportal/Planning/DeleteCageInfo", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteTrayInfo = createAsyncThunk(
  "planning/deleteTrayInfo", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        trayId: id
      };
      const response = await FomsMS.delete("/userportal/Planning/DeleteTrayInfo", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteBoardInfo = createAsyncThunk(
  "planning/deleteBoardInfo", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        boardId: id
      };
      const response = await FomsMS.delete("/userportal/Planning/DeleteBoardInfo", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateWorkOrderLocation  = createAsyncThunk(
  "planning/updateWorkOrderLocation ", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.capacity.capacity.trays;
    try {
      const response = await FomsMS.post("/userportal/Planning/UpdateWorkOrderLocation ", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchPlanningOutcomeList = createAsyncThunk(
  "planning/searchPlanningOutcomeList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/SearchPlanningOutcomeList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchUnassignedOrderList = createAsyncThunk(
  "planning/searchUnassignedOrderList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Planning/SearchUnassignedOrderList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);