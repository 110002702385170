import React, {useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";
import Checkbox from "shared-components/Checkbox/Checkbox";

import DragCard from "./DragCard";
import CustomerOrder from "./CustomerOrder";
import FinishedGoods from "./FinishedGoods";
import Delivery from "./Delivery";
import RawMaterial from "./RawMaterial";
import Planning from "./Planning";
import Operations from "./Operations";
import Cost from "./Cost";
import Warehouse from "./Warehouse";
import Equipment from "./Equipment";
import Alarms from "./Alarms";
import temperature from "assets/icons/others/temperature.svg";
import humidity from "assets/icons/others/humidity.svg";
import lighting from "assets/icons/orange/lighting.svg";
import co2 from "assets/icons/orange/carbon-dioxide.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/home.module.scss";

import { checkPermission } from "common/functions";
import { farmConfig } from "enums/Constants";
import { setValues, reset } from ".";
import { GetWidgetSetting, GetAllWidget, GetMonitoringInfoByBankId, DeleteWidget, CreateOrUpdateWidgetSetting } from "services/UserPortal/WidgetService";

const widgetList = [
  {title: "Sales Order", name: "Sales Order", key: "customerOrderWidget", permission: "Customer Order", component: <CustomerOrder />},
  {title: "Finished Goods", name: "Finished Goods", key: "finishedGoodsWidget", permission: "Finished Goods", component: <FinishedGoods />}, 
  {title: "Raw Material", name: "Raw Material", key: "rawMaterialWidget", permission: "Raw Materials", component: <RawMaterial />}, 
  {title: "Delivery", name: "Delivery", key: "deliveryWidget", permission: "Delivery", component: <Delivery />}, 
  {title: "Planning", name: "Planning", key: "planningWidget", permission: "Planning", component: <Planning />}, 
  // {title: "Production Monitoring"}, 
  {title: "Operations", name: "Operations", key: "operationWidget", permission: "Operations", component: <Operations />, noPage: true}, 
  {title: "Cost Report", name: "Cost Report", key: "costReportWidget", permission: "Cost Report", component: <Cost />}, 
  {title: "Warehouse", name: "Warehouse", key: "warehouseWidget", permission: "Warehouse", component: <Warehouse />}, 
  {title: "Equipment", name: "Equipment", key: "equipmentWidget", permission: "Equipment", component: <Equipment />}, 
  {title: "Alarms", name: "Alarms and Notifications", key: "alarmAndNotiWidget", component: <Alarms />}
]

export default function Home() {
  const dispatch = useDispatch();
  const bankList = useSelector(store => store.common.bankList);
  const bankId = useSelector(store => store.user.home.bankId);
  const widgets = useSelector(store => store.user.home.widgets);
  const monitoring = useSelector(store => store.user.home.monitoring);
  const [openModal, setOpenModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [deleteModule, setDeleteModule] = React.useState(null);
  const [checkedWidgets, setCheckedWidgets] = React.useState([]);
  const [selectedWidgets, setSelectedWidgets] = React.useState([]);

  //get data from localStorage
  const userDetail = JSON.parse(localStorage.getItem("userDetail"));

  const handleModal = () => {
    setOpenModal(!openModal);
    setCheckedWidgets(selectedWidgets);
  };

  const handleModal_warning = (widget) => {
    setDeleteModule(widget);
    setOpenWarningModal(!openWarningModal);
  };

  const handleButtonClick_delete = () => {
    dispatch(DeleteWidget(deleteModule.key[0].toUpperCase() + deleteModule.key.slice(1)))
    .then(() => {
      dispatch(GetAllWidget());
      dispatch(GetWidgetSetting());
      setDeleteModule(null);
      setOpenWarningModal(false);
    });
  };

  const handleOnChange_select = (e) => {
    let body = _.cloneDeep(widgets);
    dispatch(setValues({bankId: e.target.value}));
    dispatch(CreateOrUpdateWidgetSetting({...body, bankId: e.target.value}));
  };

  const handleButtonClick_save = () => {
    let body = _.cloneDeep(widgets);
    for (let i = 0; i < widgetList.length; i++) {
      if (checkedWidgets.find(({key}) => key === widgetList[i].key)) {
        body = {...body, [widgetList[i].key]: true};
      } else {
        body = {...body, [widgetList[i].key]: false};
      }
    }
    dispatch(CreateOrUpdateWidgetSetting({...body, bankId: bankId}))
    .then(() => {
      dispatch(GetAllWidget());
      dispatch(GetWidgetSetting());
      setOpenModal(!openModal);
    });
  };

  const handleButtonClick_checkbox = (item) => {
    let payload = _.cloneDeep(checkedWidgets);
    const index = _.findIndex(payload, ({key}) => key === item.key);
    if (index !== -1) {
      payload.splice(index, 1);
    } else {
      if (checkedWidgets.length < 9) {
        payload.push(item);
      }
    }
    setCheckedWidgets(payload);
  };

  React.useEffect(() => {
    if (bankId) {
      dispatch(GetMonitoringInfoByBankId(bankId));
    } else {
      if (bankList.length) {
        if (!bankId) {
          dispatch(setValues({bankId: bankList[0].id}));
        }
      }
    }
  },[bankId]);

  React.useEffect(() => {
    let payload = [];
    for (let i = 0; i < widgetList.length; i++) {
      if (widgets && widgets[widgetList[i].key]) {
        payload.push(widgetList[i]);
      }
    }
    setCheckedWidgets(payload);
    setSelectedWidgets(payload);
  },[widgets]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllWidget());
    dispatch(GetWidgetSetting());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  const onMoveItem = useCallback((dragIndex, hoverIndex) => {
    const result = _.cloneDeep(selectedWidgets);
    const [removed] = result.splice(dragIndex, 1);
    result.splice(hoverIndex, 0, removed);
    setSelectedWidgets(result);
  }, [selectedWidgets]);

  return (
    <React.Fragment>
      <Card 
        title={"Welcome "+userDetail.name}
      >
        <Card 
          className={styles.cardMargin}
          classes={{
            root: clsx(styles.cardPaper, styles.cardMargin)
          }}
          title="Environmental Conditions:"
          action={
            <React.Fragment>
              <Typography className={styles.label}>{farmConfig.bank}(s):</Typography>
              <Select
                className={styles.cardActionDropdown}
                onChange={(e)=>handleOnChange_select(e)}
                placeholder={"Select "+farmConfig.bank}
                value={bankId}
              >
                {bankList && bankList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.bankName}</MenuItem>;
                })} 
              </Select>
            </React.Fragment>
          }
        >
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Paper elevation={0} className={styles.environmentPaper}>
                <div className={styles.environmentText}>
                  Temperature:<br />
                  <span className={styles.environmentValue2}>{monitoring.temperature ? monitoring.temperature.split(" ")[0] : 0}</span>&nbsp;<span className={styles.environmentUnit2}>{monitoring.temperature ? monitoring.temperature.split(" ")[1] : "°C"}</span>
                </div>
                <img className={styles.environmentIcon} src={temperature} alt="icon" />
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper elevation={0} className={styles.environmentPaper}>
                <div className={styles.environmentText}>
                  Lighting:<br />
                  <span className={styles.environmentValue}>{monitoring.lighting ? monitoring.lighting.split(" ")[0] : 0}</span>&nbsp;<span className={styles.environmentUnit}>{monitoring.lighting ? monitoring.lighting.split(" ")[1] : "ppfd"}</span>
                </div>
                <img className={styles.environmentIcon} src={lighting} alt="icon" />
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper elevation={0} className={styles.environmentPaper}>
                <div className={styles.environmentText}>
                  Humidity:<br />
                  <span className={styles.environmentValue2}>{monitoring.humidity ? monitoring.humidity.split(" ")[0] : 0}</span>&nbsp;<span className={styles.environmentUnit2}>{monitoring.humidity ? monitoring.humidity.split(" ")[1] : "%RH"}</span>
                </div>
                <img className={styles.environmentIcon} src={humidity} alt="icon" />
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper elevation={0} className={styles.environmentPaper}>
                <div className={styles.environmentText}>
                  Carbon Dioxide:<br />
                  <span className={styles.environmentValue}>{monitoring.cO2 ? monitoring.cO2.split(" ")[0] : 0}</span>&nbsp;<span className={styles.environmentUnit}>{monitoring.cO2 ? monitoring.cO2.split(" ")[1] : "ppm"}</span>
                </div>
                <img className={styles.environmentIcon} src={co2} alt="icon" />
              </Paper>
            </Grid>
          </Grid>
        </Card>
        <Grid container spacing={4}>
          <DndProvider backend={HTML5Backend}>
            {selectedWidgets.map((item, index) => {
              if (widgets[item.key]) {
                return (
                  <DragCard key={index} index={index} id={item.key} item={item} onMoveItem={onMoveItem} handleModal_warning={handleModal_warning}>
                    {item.component}
                  </DragCard>
                );
              }
            })}
          </DndProvider>
          {selectedWidgets.length !== 9 &&
            <Grid item xs={4}>
              <Paper elevation={0} className={clsx(styles.addPaper, styles.widgetPaper)}>
                {selectedWidgets.length === 0 &&
                  <React.Fragment>
                    <Typography className={styles.widgetAddTitle}>Add in Your First Widget!</Typography>
                    <Typography>Let’s get started by adding your first widget on the User Portal Dashboard.</Typography>
                  </React.Fragment>
                }
                <Button 
                  className={clsx(styles.button, styles.addButton)}
                  startIcon={<AddIcon />}
                  onClick={handleModal}
                >
                  Add New Widget
                </Button>
              </Paper>
            </Grid>
          }
        </Grid>
      </Card>
      <Modal
        className={styles.widgetModal}
        open={openModal}
        onClose={() => handleModal()}
        title="Select Widgets"
        content={
          <React.Fragment>
            You can only select up to 9 widgets
            <Grid container spacing={2} className={styles.modalContent}>
              {widgetList.map((item, index) => {
                if (!checkPermission(item.permission, "none")) {
                  return (
                    <Grid item xs={6} key={index}>
                      <Checkbox checked={Boolean(_.find(checkedWidgets, ({key})=> key === item.key))} onClick={()=>handleButtonClick_checkbox(item)} />
                      {item.name}
                    </Grid>
                  )
                }
              })}
            </Grid>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal()}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_save()}>Save</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Remove Widget?"
        content={"Are you sure you want to remove the "+(deleteModule && deleteModule.title)+" Module widget?"}
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_warning(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
