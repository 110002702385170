import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetBankOverview, GetBankLookUp, GetBankDetailView, GetCameraList, GetCageLookUp, GetLevelLookUp, GetBayLookUp, GetCageDetailView } from "services/UserPortal/MonitoringService";

// initial state
const initialState = {
  filter: {},
  workOrderList: [],
  bankOverview: {},
  detailView: {}, //for bank and cage detail
  //lookup
  bankList: [],
  cameraList: [],
  bayList: [],
  levelList: [],
  cageList: [],
};

const productionMonitoring = createSlice({
  name: "productionMonitoring",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetBankOverview.fulfilled, (state, action) => {
        state.bankOverview = action.payload.result;
      })
      .addCase(GetBankLookUp.fulfilled, (state, action) => {
        state.bankList = _.orderBy(action.payload.result, ["bankName"], ["asc"]);
      })
      .addCase(GetBankDetailView.fulfilled, (state, action) => {
        state.detailView = {...state.detailView, ...action.payload.result};
      })
      .addCase(GetCameraList.fulfilled, (state, action) => {
        state.cameraList = action.payload.result;
      })
      .addCase(GetBayLookUp.fulfilled, (state, action) => {
        state.bayList = action.payload.result;
      })
      .addCase(GetLevelLookUp.fulfilled, (state, action) => {
        state.levelList = action.payload.result;
      })
      .addCase(GetCageLookUp.fulfilled, (state, action) => {
        state.cageList = action.payload.result;
      })
      .addCase(GetCageDetailView.fulfilled, (state, action) => {
        state.detailView = {...state.detailView, ...action.payload.result};
      })
  },
});

// export actions
export const { setValues, reset } = productionMonitoring.actions;

// export the reducer
export default productionMonitoring.reducer;