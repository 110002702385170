import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
// core components
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";

import { setSearchText, setDirty } from "store/general";
import { updateRequest, reset } from "../store/settings";
import { GetTimeToRunList, GetOperationConfiguration, CreateOrUpdateOperationConfiguration } from "services/UserPortal/OperationsService";

import confirm from "assets/icons/orange/confirm.svg";
import styles from "assets/jss/components/UserPortal/operations.module.scss";

import { useRouteCanWrite } from "hooks";

export default function Release() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const isDirty = useSelector(store => store.general.isDirty);
  const timeToRun = useSelector(store => store.user.operations.settings.timeToRun);
  const setting = useSelector(store => store.user.operations.settings.setting);
  const [openModal, setOpenModal] = React.useState(false);

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.name]: e.target.value}));
  };

  const handleButtonClick_save = () => {
    dispatch(CreateOrUpdateOperationConfiguration())
    .then(({error})=> {
      if (!error) {
        dispatch(setDirty(false));
        setOpenModal(true);
      }
    })
  }

  const handleButtonClick_cancel = () => {
    dispatch(setDirty(false));
    dispatch(GetOperationConfiguration());
  }

  const handleModal = () => {
    setOpenModal(!openModal);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetTimeToRunList());
    dispatch(GetOperationConfiguration());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Paper elevation={0} className={styles.settingsPaper}>
        <Grid container spacing={4}>
          <Grid item xs={4} className={styles.settingsLabel}>
            Time to Run:
          </Grid>
          <Grid item xs={8}>
            <Select
              name="timeToRun"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a time to run"
              value={setting.timeToRun}
              disabled={!canWrite}
            >
              {timeToRun && timeToRun.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })} 
            </Select>
          </Grid>
          {canWrite &&
            <Grid item xs={12} className={styles.settingsButton}>
              <Button className={styles.buttonSecondary} onClick={() => handleButtonClick_cancel()} disabled={!isDirty}>Cancel</Button>
              <Button 
                className={styles.button}
                onClick={()=>handleButtonClick_save()}
                disabled={!isDirty}
              >
                Save
              </Button>
            </Grid>
          }
        </Grid>
      </Paper>
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        icon={<img className={styles.icon_64} src={confirm} alt="confirm" />}
        title="Success"
        content="Your selection has been saved."
        actions={
          <React.Fragment>
            <Button className={styles.button} onClick={() => handleModal()}>Okay</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
