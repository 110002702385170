import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import clsx from 'clsx';
// @mui/material components
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// core components
import styles from "assets/jss/shared-components/textField.module.scss";

export default function CustomTextFieldWithLabel({className, startAdornment, endAdornment, error, errorMsg, ...props}) {

  return (
    <div className={clsx(className, styles.textFieldDiv)}>
      <div className={(error || (errorMsg && errorMsg.field==props.id)) ?  clsx(styles.root, styles.error) : styles.root}>
        <Grid container spacing={0} className={styles.container} alignItems="center">
          {startAdornment && 
            <Grid item className={styles.adornment}>
              {startAdornment}
            </Grid>
          }
          <Grid item className={styles.textfieldGrid}>
            <TextField 
              {...props} 
              className={styles.textfield}
              InputProps={{ className: styles.focused, disableUnderline: true}} 
              InputLabelProps={{ className: styles.label }} 
            />
          </Grid>
          {endAdornment && 
            <Grid item className={styles.adornment}>
              {endAdornment}
            </Grid>
          }
        </Grid>
      </div>
      {(errorMsg && errorMsg.field==props.id) && <Typography className={styles.message}>{errorMsg.msg}</Typography>}
    </div>
  );
}

CustomTextFieldWithLabel.propTypes = {
  className: PropTypes.node,
  startAdornment: PropTypes.object,
  endAdornment: PropTypes.object,
};