import React from "react";
// @mui/material
import Typography from "@mui/material/Typography";
// @mui/icons-material
import emptyImg from "assets/img/empty-img.svg";

import styles from "assets/jss/error-page/error.module.scss";

export default function Empty(props) {

  return (
    <div className={styles.container}>
      <div>
        <img className={styles.emptyImg} src={emptyImg} alt="greenphyto-empty" />
        <Typography className={styles.description}>{props.description}</Typography>
      </div>
    </div>
  );
}
