import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
// core components
import styles from "assets/jss/shared-components/checkbox.module.scss";

export default function Numbering({value, ...props}) {

  return (
    <div 
      {...props} 
      className={props.disabled ? styles.disabled : (value ? clsx(styles.numberBox, styles.checkedNumber) : styles.numberBox)}
      onClick={()=>!props.disabled && props.onClick()}
    >
      {value ? <span>{value}</span> : null}
    </div>
  );
}

Numbering.propTypes = {
  value: PropTypes.string,
};