import { createAsyncThunk } from "@reduxjs/toolkit";

import {FomsMS} from "../index";

// Report
export const TracingCodeGeneratorModuleForStatisticsReport = createAsyncThunk(
  "codeGeneratorModuleStatistics/tracingCodeGeneratorModuleForStatisticsReport", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/CodeGeneratorModuleStatistics/TracingCodeGeneratorModuleForStatisticsReport", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);