// Edit User
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button"
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateRequest, resetUser } from ".";
import { GetAllRoles, GetUserByFarmId, UpdateUser } from "services/AdminPortal/UserService";

import {genderLookup} from "enums/Lookups.js";
import styles from "assets/jss/components/AdminPortal/user.module.scss";

export default function EditUser(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const rolesLookup = useSelector(store => store.admin.user.roles);
  const countryLookup = useSelector(store => store.lookup.countryLookup);
  const workforceTypeLookup = useSelector(store => store.lookup.workforceTypeLookup);
  const user = useSelector(store => store.admin.user.user);
  const [errorMsg, setErrorMsg] = React.useState(false);
  
  const userDetail = JSON.parse(localStorage.getItem("userDetail"));

  const handleOnChange_text = (e) => {
    if (e.target.id === 'userName') {
      e.target.value = e.target.value.replace(/\s/g, '');
    }
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };
  
  const handleButtonClick_exit = () => {
    const prop = props.location && props.location.state;  // from create/search
    history.push((prop && prop.prevPath) || "/admin/user");
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(UpdateUser())
        .then((response) => {
          if (response.error) {
            dispatch(setDirty(true));
            if (response.payload.message.includes('User name')) {
              setErrorMsg({field: "userName", msg: "Username already exists"});
            } else if (response.payload.message.includes('Email')) {
              setErrorMsg({field: "emailAddress", msg: "Email is already taken"});
            }
          } else {
            history.push("/admin/user");
          }
        });
      })
    }
  };

  const validateFields = () => {
    if (_.isEmpty(user.name)) {
      setErrorMsg({field: "name", msg: "Please add in a name"});
      return false;
    }
    if (!user.roleId) {
      setErrorMsg({field: "roleId", msg: "Please select a user role"});
      return false;
    }
    if (_.isEmpty(user.userName)) {
      setErrorMsg({field: "userName", msg: "Please add in a username"});
      return false;
    }
    if (_.isEmpty(user.emailAddress)) {
      setErrorMsg({field: "emailAddress", msg: "Please add in user email"});
      return false;
    }
    if (_.isEmpty(user.workforceType)) {
      setErrorMsg({field: "workforceType", msg: "Please select a workforce type"});
      return false;
    }
    if (user.phone && !validate.isPhoneNumber(user.phone)) {
      setErrorMsg({field: "phone", msg: "Invalid phone number"});
      return false;
    }
    if (!validate.isEmail(user.emailAddress)) {
      setErrorMsg({field: "emailAddress", msg: "Please enter a valid email address"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    const prop = props.location.state;
    dispatch(setAddNewButton(true));
    dispatch(GetAllRoles({page: 0, maxResultCount: 99}));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "user");
    dispatch(GetUserByFarmId(path[index+1]))
    .then(({error}) => {
      if (error) {
        history.push((prop && prop.prevPath) || "/admin/user");
      } else {
        dispatch(setDirty(true));
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetUser());
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Edit User"
        subheader="Please edit details of user." 
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=> handleButtonClick_exit()}
            >
              Go Back
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
              disabled={!isDirty}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <div className={styles.editContent}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField 
                label={<React.Fragment>Name <span className={styles.orange}>*</span></React.Fragment>}
                id="name"
                variant="outlined" 
                inputProps={{ maxLength: 64 }}
                placeholder="Please enter your name"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(user.name)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label={<React.Fragment>Role <span className={styles.orange}>*</span></React.Fragment>}
                name="roleId"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(user.roleId)}
                placeholder="Please select your role"
                errorMsg={errorMsg}
                disabled={user.id === userDetail.id}
              >
                {rolesLookup && rolesLookup.map((item) => {
                  return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField 
                label={<React.Fragment>Username <span className={styles.orange}>*</span></React.Fragment>}
                id="userName"
                variant="outlined" 
                inputProps={{ maxLength: 255 }}
                placeholder="Please enter your username"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(user.userName)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                label={<React.Fragment>Email <span className={styles.orange}>*</span></React.Fragment>}
                id="emailAddress"
                variant="outlined" 
                inputProps={{ maxLength: 255 }}
                placeholder="eg. johndoe@gmail.com"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(user.emailAddress)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                label="Employee ID"
                id="employeeId"
                variant="outlined" 
                inputProps={{ maxLength: 10 }}
                placeholder="Please enter your ID"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(user.employeeId)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Gender"
                name="gender"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(user.gender)}
                placeholder="Please select your gender"
              >
                {genderLookup && genderLookup.map((item, index) => {
                  return <MenuItem key={index} value={item.displayName}>{item.displayName}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField 
                label="Phone Number"
                id="phone"
                type="number"
                variant="outlined" 
                placeholder="eg. 88775566"
                onInput={(e)=>{ 
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
                }}
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(user.phone)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label={<React.Fragment>Workforce Type <span className={styles.orange}>*</span></React.Fragment>}
                name="workforceType"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(user.workforceType)}
                placeholder="Select a type"
                errorMsg={errorMsg}
              >
                {workforceTypeLookup && workforceTypeLookup.map((item, index) => {
                  return <MenuItem key={index} value={item.displayName}>{item.displayName}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Country/Region"
                name="country"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(user.country)}
                placeholder="Select a country"
                errorMsg={errorMsg}
              >
                {countryLookup && countryLookup.map((item, index) => {
                  return <MenuItem key={index} value={item.countryName}>{item.countryName}</MenuItem>;
                })} 
              </Select>
            </Grid>
          </Grid>
        </div>
      </Card>
    </React.Fragment>
  )
}
