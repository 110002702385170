import React, {useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";

import { minDate } from "config";
import { setSearchText } from "store/general";
import { renderValue } from "common/functions";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues } from "../store/phenotype";
import { GetPlantPhenotypeReport, GetProductInfoById } from "services/UserPortal/QualityControlService";

import PrintFormat from "layouts/PrintFormat";
import styles from "assets/jss/components/UserPortal/qualityControl.module.scss";

export default function Report(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const totalHorizontal = useSelector(store => store.user.qualityControl.phenotype.totalHorizontal);
  const qualityControl = useSelector(store => store.user.qualityControl.phenotype.qualityControl);
  const [listIndex, setListIndex] = useState(false);
  const [zoomModal, setZoomModal] = useState(false);
  const [, setOnBeforeGetContentResolve] = useState(false);
  const [printing, setPrinting] = useState(false);
  
  const handleOnClick_zoom = (item, index) => {
    setListIndex(index);
    setZoomModal(item);
  }

  const onClickArrow = (index) => {
    if (qualityControl.qualityControlImages) {
      if (listIndex + index >= 0  && listIndex + index <= (qualityControl.qualityControlImages.length - 1)) {
        setListIndex(listIndex + index);
        if (qualityControl.qualityControlImages[listIndex + index]) {
          setZoomModal(qualityControl.qualityControlImages[listIndex + index].imageUrl);
        }
      }
    }
  }

  const handleButtonClick_print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Quality_Control_Report_"+qualityControl.workOrderNumber,
    removeAfterPrint: true,
    onAfterPrint: () => {
      setPrinting(false);
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setPrinting(true);
        setOnBeforeGetContentResolve(resolve);
      });
    },
  })

  const renderColumnImage = () => {
    let component = [];
    for (let i = 1; i <= totalHorizontal; i++) {
      const matrixValue = qualityControl.plantMatrices && qualityControl.plantMatrices.find((item) => item.horizontal == i);
      if (matrixValue.imageUrl) {
        component.push(<img src={matrixValue.imageUrl} className={clsx(styles.hcImageDiv, styles.hcImage, styles.textFieldMargin, matrixValue.imageUrl && styles.reportImg)} style={{backgroundColor: "#FFF"}} onClick={()=>handleOnClick_zoom(matrixValue.imageUrl)} />)
      } else {
        component.push(<div className={clsx(styles.hcImageDiv, styles.hcImage, styles.textFieldMargin, matrixValue.imageUrl && styles.reportImg)} style={{backgroundColor: "#FFF"}} />)
      }
    }
    return component;
  }

  const renderColumnFields = (key) => {
    let component = [];
    for (let i = 1; i <= totalHorizontal; i++) {
      const matrixValue = qualityControl.plantMatrices && qualityControl.plantMatrices.find((item) => item.horizontal == i);
      component.push(
        <TextField 
          key={i}
          className={clsx(i !== totalHorizontal && styles.textFieldMargin, styles.textFieldSmall4, (matrixValue && (matrixValue.isDisabled || matrixValue[key] === 0)) ? styles.coloredTextField : styles.printText)}
          id="plantMatrices"
          variant="outlined" 
          type="Number"
          placeholder=""
          value={matrixValue && renderValue(matrixValue[key])}
          disabled
        />
      )
    }
    return component;
  }

  const component = (isPrinting) => {
    return (
      <React.Fragment>
        <Grid container spacing={4}>
          {isPrinting && 
            <Grid item xs={12}>
              <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Quality Control Phenotype Report</Typography>
            </Grid>
          }
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Work Order:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.workOrderNumber}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Vegetable Name:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.productName}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Germination Serial No.:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.germinationSerialNo}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>General Information</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Serial No:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.vegetableSerialNo}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Vegetable Variety:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.vegetableVariety}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Batch No:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.batchNo}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Tray Position:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.trayPosition}</Typography>
          </Grid>
          <Grid item xs={8} className={styles.grid} />
          <Grid item xs={12}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Dates</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Germinate Date:</Typography>
            <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.germinateDate).isAfter(minDate) && moment(qualityControl.germinateDate).format("DD/MM/YYYY")}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Harvest Date:</Typography>
            <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.harvestDate).isAfter(minDate) && moment(qualityControl.harvestDate).format("DD/MM/YYYY")}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Nursery Duration:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.nurseryDuration}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Transplant Date:</Typography>
            <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.transplantDate).isAfter(minDate) && moment(qualityControl.transplantDate).format("DD/MM/YYYY")}</Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Growth Duration:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.growthDuration}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Plant Board</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Board Type:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.boardType}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Growing Media Type:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.growingMediaType}</Typography>
          </Grid>
          <Grid item xs={4} />
        </Grid>
        <div className="pagebreak">
          <Grid item xs={12} className={styles.grid}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Remarks</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.remark}</Typography>
          </Grid>
          <Grid item xs={5} />
          <Grid item xs={12} className={styles.grid}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Plant Matrix</Typography>
          </Grid>
          <Grid item xs={7} className={styles.grid}>
            {qualityControl.boardType
            ? <Grid container spacing={2}>
                <Grid item xs={2} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Photos:</Typography>
                </Grid>
                <Grid item xs={10} className={styles.grid}>
                  <div className={styles.padding}>
                    {renderColumnImage()}
                  </div>
                </Grid>
                <Grid item xs={2} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Plant Weight (g):</Typography>
                </Grid>
                <Grid item xs={10} className={styles.grid}>
                  <div className={styles.padding}>
                    {renderColumnFields("plantWeight")}
                  </div>
                </Grid>
                <Grid item xs={2} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Plant Height (cm):</Typography>
                </Grid>
                <Grid item xs={10} className={styles.grid}>
                  <div className={styles.padding}>
                    {renderColumnFields("plantHeight")}
                  </div>
                </Grid>
                <Grid item xs={2} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Plant Width (cm):</Typography>
                </Grid>
                <Grid item xs={10} className={styles.grid}>
                  <div className={styles.padding}>
                    {renderColumnFields("plantWidth")}
                  </div>
                </Grid>
              </Grid>
            : <Typography className={styles.formLabel4}>No Board Type</Typography>
            }
          </Grid>
        </div>
      </React.Fragment>
    );
  }
  
  React.useEffect(() => {
    if (qualityControl.productId) {
      dispatch(GetProductInfoById(qualityControl.productId));
    }
  },[qualityControl.productId]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    const prop = props.location.state;
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "report");
    dispatch(GetPlantPhenotypeReport({id: path[index+1], isPastRecord: prop && prop.isPastRecord}))
    .then(({error}) => {
      if (error) {
        history.goBack();
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(setValues({qualityControl: {healthConditions: []}, totalHorizontal: 0, totalVertical: 0}));
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = "Quality Control Report"
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.goBack()}
            >
              Go Back
            </Button>
          </React.Fragment>
        }
        action={!printing && 
          <Button
            className={styles.buttonSecondary}  
            onClick={handleButtonClick_print}
          >
            Export
          </Button>
        }
      >
        {component()}
      </Card>
      <Modal
        classes={{
          content: styles.zoomModalContent
        }}
        open={Boolean(zoomModal)}
        className={styles.zoomModal}
        onClose={() => handleOnClick_zoom(false)}
        content={
          <React.Fragment>
            <img src={zoomModal} className={styles.zoomImage} />
            {listIndex !== undefined &&
              <React.Fragment>
                <a className={styles.carouselButton} onClick={()=>onClickArrow(-1)}>&#10094;</a>
                <a className={clsx(styles.carouselButton, styles.next)} onClick={()=>onClickArrow(1)}>&#10095;</a>
              </React.Fragment>
            }
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleOnClick_zoom(false)}>Close</Button>
          </React.Fragment>
        }
      />
      <div ref={componentRef}>
        <PrintFormat printing={printing}>
          {component(true)}
        </PrintFormat>
      </div>
    </React.Fragment>
  )
}
