// Batch Upload RFID
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// @mui/material
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
import Card from "shared-components/Card/Card";

import UploadedData from "./UploadedData";
import { DownloadBatchTemplate, UploadBatchRFID } from "services/AdminPortal/RFIDService";

import batchUpdate from "assets/img/batchUpdate-img.svg";

import styles from "assets/jss/components/AdminPortal/rfid.module.scss";

const FileDownload = require('js-file-download');
export default function BatchUpdate() {
  let history = useHistory();
  const dispatch = useDispatch();
  const inputFile = React.createRef();
  const [isFileUploaded, setIsFileUploaded] = React.useState(false);

  const handleButtonClick_download = () => {
    dispatch(DownloadBatchTemplate())
    .then((response) => {
      FileDownload(response.payload.data, 'rfid_batch_upload_template.xlsx');
    })
  }

  const onButtonClick_upload = () => {
    inputFile.current.click();
  }

  const handleOnUpload = (e) => {
    setIsFileUploaded(true);
    dispatch(UploadBatchRFID(e.target.files[0]));
  }

  if (isFileUploaded) {
    return <UploadedData />
  } else {
    return (
      <Card
        cardActions={
          <Button className={styles.buttonSecondary} onClick={()=>history.goBack()}>Back</Button>
        }
      >
        <div className={styles.batchPaper}>
          <img className={styles.img} src={batchUpdate} alt="batch-update" />
          <Typography className={styles.batchTitle}>RFID Batch Update</Typography>
          <Typography className={styles.batchDesc}>
            Use the batch update function to quickly upload multiple RFID records at one go.
          </Typography>
          <Button 
            className={styles.batchButton} 
            startIcon={<AddIcon />} 
            onClick={() => handleButtonClick_download()}
          >
            Download CSV Template
          </Button>
          <input 
            ref={inputFile} 
            style={{"display": "none"}} 
            type="file" 
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => handleOnUpload(e)}
          />
          <Button 
            className={styles.batchButton} 
            startIcon={<AddIcon />} 
            onClick={() => onButtonClick_upload()}
          >
            Upload CSV File
          </Button>
        </div>
      </Card>
    );
  }
}
