import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import { GetAllProducts, GenerateProductId, GetProductCategoryByID, GetProductById, GetProductProcessByProductId, GetProductPreHarvestMixProcess, GetProductVersionsByProductId } from "services/AdminPortal/ProductService";
import { UploadProductImage } from "services/AdminPortal/UploadService";

// initial state
const initialState = {
  productFinish: [],
  productUnfinish:[],

  selectedProduct: {
    isDraft: "true",
  },
  productPreHarvestGrowthProcess: [],
  productPostHarvestGrowthProcess: [],
  productImage: "",
  preHarvestMixProcess: {},
  preProcess: {},
  postProcess: {},
  process: {},
  subTable: {},
  task: {},
  versionList: [],
};

const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    updateImg: (state, action) => {
      state.productImage = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.selectedProduct[key] = action.payload[key];
      });
    },
    updatePostProcess: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.postProcess[key] = action.payload[key];
      });
    },
    updatePreProcess: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.preProcess[key] = action.payload[key];
      });
    },
    updateSubTable: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.subTable[key] = action.payload[key];
      });
    },
    setVersionList: (state, action) => {
      state.versionList = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllProducts.fulfilled, (state, action) => {
        state.productFinish = action.payload.result.productFinishedList.items;
        state.productUnfinish = action.payload.result.productUnfinishedList.items;
      })
      .addCase(GenerateProductId.fulfilled, (state, action) => {
        state.selectedProduct.productID = action.payload.result.productID;
      })
      .addCase(GetProductCategoryByID.fulfilled, (state, action) => {
        state.selectedProduct.combinationProduct = action.payload.result.combinationProduct;
      })
      .addCase(GetProductById.fulfilled, (state, action) => {
        const result = action.payload.result;
        state.selectedProduct = _.omit(result, ["versionList"]);
        state.productImage = action.payload.result.productImage;
        if(result.versionList.length) { // this is needed for product process version view
          state.selectedProduct.versionCreationTime = result.versionList[0].creationTime;
          state.selectedProduct.productPreHarvestGrowthProcess = result.versionList[0].preHarvestProcess;
          state.selectedProduct.productPostHarvestGrowthProcess = result.versionList[0].postHarvestProcess;
        }
      })
      .addCase(GetProductProcessByProductId.fulfilled, (state, action) => {
        const result = action.payload.result;
        state.productPreHarvestGrowthProcess = result.preHarvestProcess;
        state.productPostHarvestGrowthProcess = result.postHarvestProcess;
      })
      .addCase(GetProductPreHarvestMixProcess.fulfilled, (state, action) => {
        const result = action.payload.result
        state.preHarvestMixProcess = _.groupBy(result.preHarvestProcess, 'productID');
      })
      .addCase(GetProductVersionsByProductId.fulfilled, (state, action) => {
        const result = action.payload.result
        state.versionList = _.orderBy(result, ["versionName"], ["desc"]);
      })
      .addCase(UploadProductImage.fulfilled, (state, action) => {
        state.selectedProduct.productImage = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateImg, updateRequest, updateSubTable, updatePostProcess, updatePreProcess, setVersionList, reset } = product.actions;

// export the reducer
export default product.reducer;