import React from "react";
import clsx from "clsx";
// @mui/material components
import TableCell from "@mui/material/TableCell";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { bankFormat } from "common/functions";
import light from "assets/icons/others/light.svg";
import deviceIcon from "assets/icons/others/device.svg";
import cage from "assets/icons/others/cage.svg";
import b_light from "assets/icons/black/light.svg";
import b_deviceIcon from "assets/icons/black/device.svg";
import b_cage from "assets/icons/black/cage.svg";
import close from "assets/icons/white/close.svg";
import styles from "assets/jss/shared-components/bank.module.scss";

export default function ClimateBank(props) {

  const noOfBays = () => {
    let element = [];
    for (let i = 1; i <= parseInt(props.bays); i++) {
      if (props.isManual) {
        element.push(
          renderNotUsed(i)
        );
      } else {
        const bayDetails = props.data.find((item) => {
          if (item.bayRefNo && item.bayRefNo===bankFormat(i)) {
            return item;
          }
        })
        element.push(
          renderCell({level: props.row, bay: i}, bayDetails)
        );
      }
    }
    return element;
  }

  const renderNotUsed = (i) => {
    return (
      <TableCell 
        key={i}
        className={clsx(styles.cell2, styles.notUse)}
      />
    )
  }

  const renderCell = (value, bayDetails) => {
    const devices = bayDetails && bayDetails.devices.filter((item) => {
      if (item.cageRefNo) {
        const location = item.cageRefNo.split("-");
        if (location[1] === bankFormat(value.bay) && location[2] === bankFormat(value.level)) {
          return item;
        }
      }
    })
    return (
      <TableCell
        key={value.bay} 
        className={ clsx(styles.cell3, styles.clickable, 
          // isSelected && classes.selected, 
          devices && devices.length === 0 ? styles.notAvailable : null
        )} 
      >
        {devices && devices.length
        ? <React.Fragment>
            { devices.map((device,index) => {
            const isSelected = props.selected.find(({deviceId}) => {
              return deviceId === device.id;
            })
              const isDeviceOn = device.value > 0 || device.value > 0;
              return (
                <React.Fragment key={index}>
                  {index > 0 && <Divider />}
                  <div 
                    className={ clsx(styles.clickable, isSelected && styles.selected, styles.cellItem2,
                    device ? isDeviceOn ? styles.on : styles.off : styles.notAvailable
                    )} 
                    onClick={()=>device && props.onCellClick(device)}
                  >
                    <div className={isDeviceOn ? styles.details : clsx(styles.details, styles.black)}>
                      <img className={styles.icon_24} src={isDeviceOn ? cage : b_cage} alt="cage" /> {device.cageRefNo}
                    </div>
                    <div className={isDeviceOn ? styles.details : clsx(styles.details, styles.black)}>
                      <img className={styles.icon_24} src={isDeviceOn ? deviceIcon : b_deviceIcon} alt="device" /> {device.deviceRefNo}
                    </div>
                    <div className={isDeviceOn ? styles.details : clsx(styles.details, styles.black)}>
                      <img className={styles.icon_24} src={isDeviceOn ? light : b_light} alt="light" /> 
                      <LinearProgress 
                        classes={{
                          root: isDeviceOn ? styles.progressBar : clsx(styles.progressBar, styles.progressBar2), 
                          barColorPrimary: styles.progress
                        }} 
                        variant="determinate" 
                        value={device.value} />
                      {device.value}%
                      {device.isTwoChannel &&
                        <Tooltip title="Two Channel Light" placement="top">
                          <InfoOutlinedIcon className={clsx(styles.icon_24, styles.infoIcon)} />
                        </Tooltip>
                      }
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
          </React.Fragment>
        : <div className={styles.cellItem}><img className={clsx(styles.notAvailableIcon, styles.notAvailable)} src={close} alt="not-available" /></div>
        }
      </TableCell>
    )
  }

  return (noOfBays());
}