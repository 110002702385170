import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { GetCustomerCapacityReport, GetCustomerOrderCapacityDetailReportByDateAndProduct, GetCustomerList } from "services/UserPortal/CustomerOrderService";

// initial state
const initialState = {
  filter: {},
  report: [],
  totalCount: 0,
  orders: [],
  customerList: []
};

const capacityCO = createSlice({
  name: "capacityCO",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCustomerCapacityReport.fulfilled, (state, action) => {
        state.report = action.payload.result.map((item)=>{
          item.date = moment(item.date).format("YYYY-MM-DD");
          return item;
        });
      })
      .addCase(GetCustomerOrderCapacityDetailReportByDateAndProduct.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.orders = action.payload.result.items;
      })
      .addCase(GetCustomerList.fulfilled, (state, action) => {
        state.customerList = action.payload.result;
      })
  },
});

// export actions
export const { setValues, reset } = capacityCO.actions;

// export the reducer
export default capacityCO.reducer;