// Product Process
// Product Details
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import CreateRMEquip from "./CreateRMEquip";
import CreateTask from "./CreateTask";
import { setValues, setVersionList } from "./store/product";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { growthProcessTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllProducts, GetProductById, GetProductPreHarvestMixProcess, GetProductVersionsByProductId, GetAllPackagingType } from "services/AdminPortal/ProductService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/AdminPortal/product.module.scss";

export default function Process(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const finishProduct = useSelector(store => store.admin.product.product.productFinish);
  const product = useSelector(store => store.admin.product.product.selectedProduct);
  const versionList = useSelector(store => store.admin.product.product.versionList);
  const packageTypeList = useSelector(store => store.admin.product.lookup.packageTypeList);
  const preHarvestMixProcess = useSelector(store => store.admin.product.product.preHarvestMixProcess);
  const [viewVersion, setViewVersion] = React.useState({});
  const [collapsed, setCollapsed] = React.useState({});
  const [view, setView] = React.useState(false);
  const [growthEditIndex, setGrowthEditIndex] = React.useState(null); //pre/postharvest edit index
  const [type, setType] = React.useState("");
  const [productID, setProductID] = React.useState("");

  const tableHead = (processType) => {
    if(processType === "productPostHarvestGrowthProcess") {
      return _.filter(growthProcessTableHead, (obj) => {return (obj.post !== false && obj.id !== "action")});
    } else {
      return _.filter(growthProcessTableHead, (obj) => {return obj.id !== "action" && obj.post !== true});
    }
  }

  const handleButtonClick_version = (version) => {
    setViewVersion(version);
  }

  const handleButtonClick_exit = () => {
    if (view) {
      setView(null);
    } else {
      const prop = props.location && props.location.state;
      history.push((prop && prop.prevPath) || "/admin/products");
    }
  }

  const handleButtonClick_detail = (viewType, index, processType, item, id) => {
    dispatch(setValues({process: item}));
    setView(viewType);
    setGrowthEditIndex(index);
    setType(processType);
    setProductID(id);
  }

  const displayTable = (process, processType, id) => {
    return (
      <div className={styles.table}>
        <Table
          header={tableHead(processType).map((head) => {
            if (head.id === "packageType") {
              head.label = "Packaging Type"
            } 
            return head;
          })}
        >
          { process && process.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{('0'+(index+1)).slice(-2)}</TableCell>
                <TableCell>{item.processName}</TableCell>
                <TableCell>{item.processDurationDays}</TableCell>
                {processType === "productPreHarvestGrowthProcess"
                ? <React.Fragment>
                    <TableCell>{item.plantPerTray}</TableCell>
                    <TableCell>{item.traysPerCage}</TableCell>
                    <TableCell>{item.weightPerPlant}</TableCell>
                  </React.Fragment>
                : <React.Fragment>
                    <TableCell>{item.packageSize}</TableCell>
                    <TableCell>{(item.packagingTypeSettingId && packageTypeList.length && packageTypeList.find((user) => user.id === item.packagingTypeSettingId)) ? packageTypeList.find((user) => user.id === item.packagingTypeSettingId).packagingType : null}</TableCell>
                  </React.Fragment>
                }
                <TableCell><span className={styles.link} onClick={()=>handleButtonClick_detail("rm", index, processType, item)}>Details</span></TableCell>
                <TableCell><span className={styles.link} onClick={()=>handleButtonClick_detail("task", index, processType, item, product.combinationProduct && id)}>Details</span></TableCell>
                <TableCell>{item.lossRate}</TableCell>
              </TableRow>
            )
          })}
        </Table>
      </div>
    )
  }
  
  React.useEffect(() => {
    const prop = props.location.state;
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "process");
    dispatch(GetProductById({id: path[index+1], versionID: viewVersion.id}))
    .then((response) => {
      if (response.error) {
        history.push((prop && prop.prevPath) || "/admin/products");
      } else {
        if(response.payload.result.combinationProduct) {
          dispatch(GetProductPreHarvestMixProcess(response.payload.result.productCategoryTypeID));
        }
      }
    });
  },[viewVersion]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    const prop = props.location.state;
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "process");
    dispatch(GetAllProducts());
    dispatch(GetAllPackagingType({page: 0, maxResultCount: 9999}));
    dispatch(GetProductVersionsByProductId(path[index+1]))
    .then((response) => {
      if (response.error || !response.payload.result.length) {
        history.push((prop && prop.prevPath) || "/admin/products");
      } else {
        setViewVersion(_.find(response.payload.result, ["isActive", true]));
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(setVersionList([]));
      dispatch(resetNav());
    }
  },[]);

  const toggleView = () => {
    switch (view) {
      case "rm":
        return <CreateRMEquip type={type} growthEditIndex={growthEditIndex} isFromVersion={true} />
      case "task":
        return <CreateTask type={type} growthEditIndex={growthEditIndex} isFromVersion={true} productID={productID} />
      default: 
        return (
          <React.Fragment>
            { product.combinationProduct 
            ? Object.keys(preHarvestMixProcess).map((productId, index) => {
                const foundProduct = _.find(finishProduct, {"id": Number(productId)});
                return (
                  <Card 
                    key={index}
                    classes={{root:styles.growthProcessTable}}
                    title = {
                      <React.Fragment>
                        Pre Harvesting ({foundProduct && foundProduct.productID}) Version {preHarvestMixProcess[productId][0].productVersionName}
                        <IconButton 
                          className={collapsed[index] ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                          onClick={() => setCollapsed({...collapsed, [index]: !collapsed[index]})}
                        >
                          <img className={styles.icon} src={arrow} alt="arrow" />
                        </IconButton>
                      </React.Fragment>
                    }
                  >
                    { !collapsed[index] &&
                      displayTable(preHarvestMixProcess[productId], "productPreHarvestGrowthProcess", foundProduct && foundProduct.productID)
                    }
                  </Card>
                )
              })
            : <Card 
                classes={{root:styles.growthProcessTable}}
                title = {
                  <React.Fragment>
                    Pre Harvesting ({product.productID}) 
                    <IconButton 
                      className={collapsed[0] ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                      onClick={() => setCollapsed({...collapsed, [0]: !collapsed[0]})}
                    >
                      <img className={styles.icon} src={arrow} alt="arrow" />
                    </IconButton>
                  </React.Fragment>
                }
              >
                { !collapsed[0] &&
                  displayTable(product.productPreHarvestGrowthProcess, "productPreHarvestGrowthProcess")
                }
              </Card>
            }
            <Card 
              classes={{root:styles.growthProcessTable}}
              title = {
                <React.Fragment>
                  Post Harvesting ({product.productID})
                  <IconButton 
                    className={collapsed[preHarvestMixProcess.length+1] ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                    onClick={() => setCollapsed({...collapsed, [preHarvestMixProcess.length+1]: !collapsed[preHarvestMixProcess.length+1]})}
                  >
                    <img className={styles.icon} src={arrow} alt="arrow" />
                  </IconButton>
                </React.Fragment>
              }
              action={product.combinationProduct &&
                <div className={clsx(styles.versionDiv, styles.mixAction)}>
                  <Typography className={styles.versionLabel}>Past Variations: </Typography>
                  <div className={styles.versionContainer}>
                    { versionList.map((item, index) => { // product.versions
                      if (index < 10) {
                        return (
                          <Typography 
                            key={index} 
                            className={viewVersion.versionName !== item.versionName && styles.version} 
                            onClick={()=>handleButtonClick_version(item)}
                          >
                            {item.versionName}
                          </Typography>
                        );
                      }
                    })}
                  </div>
                </div>
              }
            >
              { !collapsed[preHarvestMixProcess.length+1] &&
                displayTable(product.productPostHarvestGrowthProcess, "productPostHarvestGrowthProcess")
              }
            </Card>
          </React.Fragment>
        );
    }
  }

  return (
    <React.Fragment>
      <Card 
        classes={{
          root: styles.result,
        }}
        title={"Product Process Version " + viewVersion.versionName}
        subheader={
          <React.Fragment>
            Last Updated: <span className={styles.subheader}>{moment(product.versionCreationTime).format("DD/MM/yyyy H:mm:ss")}</span>
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>handleButtonClick_exit()}
            >
              Go Back
            </Button>
          </React.Fragment>
        }
      >
        <div className={styles.imageDiv}>
          <Typography className={styles.imageLabel}>Product Image:</Typography>
          <div className={styles.imgBorder}>
            { product.productImage && <img className={clsx(styles.cardRadius, styles.addImg)} src={product.productImage} alt="product-pic" /> }
          </div>
        </div>
        {(!product.combinationProduct || (product.combinationProduct && view === "rm" || view === "task")) &&
         <div className={styles.versionDiv}>
            <Typography className={styles.versionLabel}>Past Variations: </Typography>
            <div className={styles.versionContainer}>
              { versionList.map((item, index) => { // product.versions
                if (index < 10) {
                  return (
                    <Typography 
                      key={index} 
                      className={viewVersion.versionName !== item.versionName && styles.version} 
                      onClick={()=>handleButtonClick_version(item)}
                    >
                      {item.versionName}
                    </Typography>
                  );
                }
              })}
            </div>
          </div>
        }
        {toggleView()}
      </Card>
    </React.Fragment>
  );
}
