import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Pagination from "./Pagination";
import styles from "assets/jss/components/UserPortal/home.module.scss";

export default function CustomerOrder() {
  const results = useSelector(store => store.user.home.customerOrderWidget);
  const [page, setPage] = React.useState(0);

  return (
    <React.Fragment>
      {results.length
      ? <React.Fragment>
          <Typography className={styles.widgetTitle}>5 Latest Added Sales Orders</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper elevation={0} className={styles.paper}>
                <Typography className={styles.tableTitle}>Customer Name</Typography>
                <Typography className={styles.paperContent}>{results[page] && results[page].customerName}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} className={styles.paper}>
                <Typography className={styles.tableTitle}>Customer PO</Typography>
                <Typography className={styles.paperContent}>{results[page] && results[page].purchaseOrderNumber}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} className={styles.paper}>
                <Typography className={styles.tableTitle}>Products</Typography>
                <Typography className={styles.paperContent}>{results[page] && results[page].noOfProducts}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} className={styles.paper}>
                <Typography className={styles.tableTitle}>Total Weight</Typography>
                <Typography className={styles.paperContent}>{results[page] && results[page].totalWeight}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={0} className={styles.paper}>
                <Typography className={styles.tableTitle}>Sale Order</Typography>
                <Typography className={styles.paperContent}>
                  {_.map(results[page] && results[page].saleOrderListForCOWiget, (so, i) => {
                    return (
                      <Link to={{pathname: "/user/sales-order/management/"+so.id}} key={i} className={styles.link}>{so.saleOrderNumber}<br /></Link>
                    )
                  })}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      : <Typography className={styles.tableTitle}>Nothing to show</Typography>
      }
      <div className={styles.widgetAction}>
        { results.length > 1 &&
          <Pagination 
            count={results.length} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </div>
    </React.Fragment>
  );
}
