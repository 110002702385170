import { combineReducers } from "redux";
import overview from "./overview";
import costChart from "./costChart";
import parameters from "./parameters";
import dashboard from "./dashboard";
import flagship from "./flagship";

const SmartMeteringReducer = combineReducers({
  overview,
  costChart,
  parameters,
  dashboard,
  flagship,
});

export default SmartMeteringReducer;