import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetModuleList = createAsyncThunk(
  "alarm/getModuleList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/AlarmNotification/GetModuleList");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllAlarmNotification = createAsyncThunk(
  "alarm/getAllAlarmNotification", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/AlarmNotification/GetAllAlarmNotification", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAlarmNotificationCount = createAsyncThunk(
  "alarm/getAlarmNotificationCount", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      isShowOnlyForUnread: true,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/AlarmNotification/GetAlarmNotificationCount", {params, stopLoading: true, isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SetAlarmNotificationReadByUser = createAsyncThunk(
  "alarm/setAlarmNotificationReadByUser", 
  async (alarmNotificationId, {rejectWithValue}) => {
    const body = {alarmNotificationId};
    try {
      const response = await FomsMS.post("/userportal/AlarmNotification/SetAlarmNotificationReadByUser", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SetResolveAlarmNotification = createAsyncThunk(
  "alarm/setResolveAlarmNotification", 
  async (id, {rejectWithValue}) => {
    const body = {
      alarmNotificationIdList: id
    };
    try {
      const response = await FomsMS.post("/userportal/AlarmNotification/SetResolveAlarmNotification", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Alarm Settings
export const GetAllAlarmType  = createAsyncThunk(
  "alarm/getAllAlarmType ", 
  async (isBefore, {rejectWithValue}) => {
    const params = {isBefore}
    try {
      const response = await FomsMS.get("/userportal/AlarmNotification/GetAllAlarmType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllAlarmNotificationSetting  = createAsyncThunk(
  "alarm/getAllAlarmNotificationSetting ", 
  async (setting, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...setting,
      isActive: true,
      farmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.get("/userportal/AlarmNotification/GetAllAlarmNotificationSetting", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateAlarmNotificationSetting  = createAsyncThunk(
  "alarm/createOrUpdateAlarmNotificationSetting ", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.alarm.setting;
    try {
      const response = await FomsMS.post("/userportal/AlarmNotification/CreateOrUpdateAlarmNotificationSetting", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);