import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { 
  GetBatchIDList, 
  GetProductList,
  GetTrayPosition, 
  GetAllQualityControlEntries, 
  GetBoardType, 
  GetNutrientCategory, 
  GetPlantPhenotypeReport, 
  GetQCDetailsFromOperation,
  GetGerminationDetailByWorkOrderIdAndProductId,
  CalculateNurseryDuration, 
  CalculateGrowthDuration, 
  GetProductInfoById, 
  CalculateDatesByWorkOrderIdAndProductId,
  GeneratePlantPhenotypeMatrixFormat,
} from "services/UserPortal/QualityControlService";

// initial state
const initialState = {
  filter: {},
  //pheno
  totalCount: 0,
  result: [],
  qualityControl: {}, // for create/edit
  totalHorizontal: 0,
  totalVertical: 0,
  // lookup
  boardTypeList: [],
  trayPositionList: [],
  nutrientCatList: [],
  batchIDList: [],
  productList: []
};

const phenotype = createSlice({
  name: "phenotype",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.qualityControl[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    //select work order to work on
      .addCase(GetAllQualityControlEntries.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.result = action.payload.result.items;
      })
      .addCase(GetPlantPhenotypeReport.fulfilled, (state, action) => {
        state.qualityControl = {
          plantMatrices: action.payload.result.plantMatrices && action.payload.result.plantMatrices.plantMatrixList,
          ..._.omit(action.payload.result, ["plantMatrices"]),
        };
        state.totalHorizontal = action.payload.result.plantMatrices && action.payload.result.plantMatrices.totalHorizontal;
        state.totalVertical = action.payload.result.plantMatrices && action.payload.result.plantMatrices.totalVertical;
      })
    // lookup
      .addCase(GetBoardType.fulfilled, (state, action) => {
        state.boardTypeList = action.payload.result;
      })
      .addCase(GetTrayPosition.fulfilled, (state, action) => {
        state.trayPositionList = action.payload.result;
      })
      .addCase(GetNutrientCategory.fulfilled, (state, action) => {
        state.nutrientCatList = action.payload.result;
      })
      .addCase(GetBatchIDList.fulfilled, (state, action) => {
        state.batchIDList = action.payload.result;
      })
      .addCase(GetProductList.fulfilled, (state, action) => {
        state.productList = action.payload.result;
      })
      // calculation
      .addCase(GetQCDetailsFromOperation.fulfilled, (state, action) => {
        state.qualityControl = {...state.qualityControl, ...action.payload.result};
      })
      .addCase(GetGerminationDetailByWorkOrderIdAndProductId.fulfilled, (state, action) => {
        state.qualityControl = {...state.qualityControl, ..._.omit(action.payload.result, ['workOrderId', 'productId', 'germinateDate', 'transplantDate', 'nurseryDuration', 'id', 'initialEC', 'initialPHLevel', 'initialTDS', 'initialWaterLevel', 'finalEC', 'finalPHLevel', 'finalTDS', 'finalWaterLevel'])};
      })
      .addCase(CalculateNurseryDuration.fulfilled, (state, action) => {
        state.qualityControl.nurseryDuration = action.payload.result;
      })
      .addCase(CalculateGrowthDuration.fulfilled, (state, action) => {
        state.qualityControl.growthDuration = action.payload.result;
      })
      .addCase(GetProductInfoById.fulfilled, (state, action) => {
        state.qualityControl.vegetableVariety = action.payload.result.vegetableVariety;
      })
      .addCase(CalculateDatesByWorkOrderIdAndProductId.fulfilled, (state, action) => {
        state.qualityControl.germinateDate = action.payload.result.germinateDate;
        state.qualityControl.transplantDate = action.payload.result.transplantDate;
        state.qualityControl.harvestDate = action.payload.result.harvestDate;
      })
      .addCase(GeneratePlantPhenotypeMatrixFormat.fulfilled, (state, action) => {
        state.totalHorizontal = action.payload.result.totalHorizontal;
        state.totalVertical = action.payload.result.totalVertical;
        state.qualityControl.plantMatrices = action.payload.result.plantMatrixList;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = phenotype.actions;

// export the reducer
export default phenotype.reducer;