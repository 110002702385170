import { createSlice } from "@reduxjs/toolkit";
import { 
  GetProductListByWorkOrderId,
  GetBatchIDList, 
  GetTrayPosition, 
  GetProductList,
  GetQCDetailsFromOperation,
  CalculateNurseryDuration, 
  GetProductInfoById, 
  GetAllGerminationReports, 
  GetGerminationReportDetail,
  GetBatchStartDateByBatchID,
  CalculateGerminationRate,
  CalculateDatesByWorkOrderIdAndProductId,
  GetSeedSupplierNameByBatchID
} from "services/UserPortal/QualityControlService";

// initial state
const initialState = {
  filter: {},
  //vege
  totalCount: 0,
  result: [],
  qualityControl: {},
  // lookup
  productList: [],
  trayPositionList: [],
  batchIDList: [],
  //filter
  filterProductList: [],
};

const germination = createSlice({
  name: "germination",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.qualityControl[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      //select work order to work on
      .addCase(GetAllGerminationReports.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.result = action.payload.result.items;
      })
      .addCase(GetGerminationReportDetail.fulfilled, (state, action) => {
        state.qualityControl = action.payload.result;
      })
      // lookup
      .addCase(GetProductListByWorkOrderId.fulfilled, (state, action) => {
        state.productList = action.payload.result;
      })
      .addCase(GetTrayPosition.fulfilled, (state, action) => {
        state.trayPositionList = action.payload.result;
      })
      .addCase(GetBatchIDList.fulfilled, (state, action) => {
        state.batchIDList = action.payload.result;
      }) 
    // filter
      .addCase(GetProductList.fulfilled, (state, action) => {
        state.filterProductList = action.payload.result;
      })
      // calculation
      .addCase(GetQCDetailsFromOperation.fulfilled, (state, action) => {
        state.qualityControl = {...state.qualityControl, ...action.payload.result};
      })
      .addCase(CalculateNurseryDuration.fulfilled, (state, action) => {
        state.qualityControl.nurseryDuration = action.payload.result;
      })
      .addCase(CalculateGerminationRate.fulfilled, (state, action) => {
        state.qualityControl.germinationRate = action.payload.result;
      })
      .addCase(GetProductInfoById.fulfilled, (state, action) => {
        state.qualityControl.vegetableVariety = action.payload.result.vegetableVariety;
      })
      .addCase(GetBatchStartDateByBatchID.fulfilled, (state, action) => {
        state.qualityControl.batchStartDate = action.payload.result;
      })
      .addCase(CalculateDatesByWorkOrderIdAndProductId.fulfilled, (state, action) => {
        state.qualityControl.germinateDate = action.payload.result.germinateDate;
        state.qualityControl.transplantDate = action.payload.result.transplantDate;
        state.qualityControl.harvestDate = action.payload.result.harvestDate;
        state.qualityControl.batchOpenDate = action.payload.result.batchOpenDate;
      })
      .addCase(GetSeedSupplierNameByBatchID.fulfilled, (state, action) => {
        state.qualityControl.supplierName = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = germination.actions;

// export the reducer
export default germination.reducer;