// Create Edit Raw Material
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";

import RawMaterialCreationForm from "./RawMaterialCreationForm";
import SafetyLevelUOMForm from "./SafetyLevelUOMForm";
import BatchForm from "./BatchForm";
import { reset } from "./"
import { GetRawMaterial } from "services/AdminPortal/RawMaterialService";

export default function AddEditRawMaterial(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const [step, setStep] = React.useState(0);
  const [isEdit, setIsEdit] = React.useState(false);

  // componentDidMount
  React.useEffect(() => {
    const prop = props.location.state;
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "raw-material");
    if (path[index+1] != "create") {
      setIsEdit(true)
      dispatch(GetRawMaterial(path[index+1]))
      .then(({error}) => {
        if (error) {
          history.push((prop && prop.prevPath) || "/admin/raw-material");
        } else {
          dispatch(setDirty(true));
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    return () => {
      dispatch(setDirty(false));
      dispatch(resetNav());
      dispatch(reset());
    }
  },[]);

  const renderForm = () => {
    switch(step) {
      case 0:
        return <RawMaterialCreationForm step={step} setStep={setStep} isEdit={isEdit}/>
      case 1:
        return <SafetyLevelUOMForm step={step} setStep={setStep} isEdit={isEdit} />
      case 2:
        return <BatchForm step={step} setStep={setStep}/>
    }
  }
  return (
    <React.Fragment>
      {renderForm()}
    </React.Fragment>
  )
}
