import { createAsyncThunk } from "@reduxjs/toolkit";

import {FomsMS} from "../index";

export const GetAllSustainability = createAsyncThunk(
  "sustainability/getAllSustainability", 
  async ({stopLoading, ...params}, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Sustainability/GetAllSustainability", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateSustainability = createAsyncThunk(
  "sustainability/CreateOrUpdateSustainability", 
  async (_, {getState, rejectWithValue}) => {
    const body = {
      sustainabilityUpdateRequestList: getState().user.sustainability.dashboard.sustainability
    };
    try {
      const response = await FomsMS.post("/userportal/Sustainability/CreateOrUpdateSustainability", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllSellingPrice = createAsyncThunk(
  "sustainability/getAllSellingPrice", 
  async ({stopLoading, ...params}, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Sustainability/GetAllSellingPrice", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateSellingPrice = createAsyncThunk(
  "sustainability/CreateOrUpdateSellingPrice", 
  async (_, {getState, rejectWithValue}) => {
    const body = {
      sellingPriceUpdateList: getState().user.sustainability.dashboard.price
    };
    try {
      const response = await FomsMS.post("/userportal/Sustainability/CreateOrUpdateSellingPrice", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllGMF = createAsyncThunk(
  "sustainability/getAllGMF", 
  async ({stopLoading, ...params}, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Sustainability/GetAllGMF", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateGMF = createAsyncThunk(
  "sustainability/CreateOrUpdateGMF", 
  async (_, {getState, rejectWithValue}) => {
    const body = {
      gmfUpdateList: getState().user.sustainability.dashboard.gmf
    };
    try {
      const response = await FomsMS.post("/userportal/Sustainability/CreateOrUpdateGMF", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ConvertkWhToCO2 = createAsyncThunk(
  "sustainability/convertkWhToCO2", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const params = {
      ...param.date
    };
    try {
      const response = await FomsMS.get("/userportal/Sustainability/ConvertkWhToCO2", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);