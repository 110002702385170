import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllWarehouses = createAsyncThunk(
  "warehouse/getAllWarehouses", 
  async (param, {rejectWithValue}) => {
		const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Warehouse/GetAllWarehouses", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWarehouseById = createAsyncThunk(
	"warehouse/getWarehouseById", 
	async (id, {rejectWithValue}) => {
		try {
			const params = {id};
			const response = await FomsMS.get("/Warehouse/GetWarehouseById", {params});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const GetWarehouseAllUnitOfMeasurement = createAsyncThunk(
	"warehouse/getWarehouseAllUnitOfMeasurement", 
	async (_, {rejectWithValue}) => {
			try {
			const response = await FomsMS.get("/Warehouse/GetWarehouseAllUnitOfMeasurement");
			return response.data;
			} catch (error) {
			return rejectWithValue(error);
			}
	},
);

export const GetProposedWarehouseId = createAsyncThunk(
  "warehouse/generateProductId", 
  async (data, {rejectWithValue}) => {
		const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...data,
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.post("/Warehouse/GetProposedWarehouseId", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)

export const CreateOrUpdateWarehouse = createAsyncThunk(
  "warehouse/createOrUpdateWarehouse", 
  async (data, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...data,
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.post("/Warehouse/CreateOrUpdateWarehouse", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)

export const DeleteWarehouse = createAsyncThunk(
  "warehouse/deleteWarehouse", 
  async (id, {rejectWithValue}) => {
    const params = {id}
    try {
      const response = await FomsMS.delete("/Warehouse/DeleteWarehouse", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchWarehouse = createAsyncThunk(
  "warehouse/searchWarehouse", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Warehouse/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWarehouseRackByWarehouseId = createAsyncThunk(
	"warehouse/GetWarehouseRackByWarehouseId", 
	async (param, {rejectWithValue}) => {
		try {
			const params = {
      warehouseId: param.warehouseId,
      rackID: param.rackID,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    }
			const response = await FomsMS.get("/Warehouse/GetWarehouseRackByWarehouseId", {params, stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);