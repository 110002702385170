import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import Typography from "@mui/material/Typography";

import { formatNumbers } from "common/functions";
import { reset } from "../store/search";
import { setSearchText } from "store/general";

import styles from "assets/jss/components/UserPortal/planning.module.scss";
import SearchOutcomeResult from "./SearchOutcomeResult";
import SearchOutcomeUnassignedResult from "./SearchOutcomeUnassignedResult";
import SearchExcessResult from "./SearchExcessResult";
import SearchUnassignedResult from "./SearchUnassignedResult";

export default function SearchResult() {
  const dispatch = useDispatch();
  //count
  const outcomeTotalCount = useSelector(store => store.user.planning.search.outcomeTotalCount);
  const outcomeUnassignedTotalCount = useSelector(store => store.user.planning.search.outcomeUnassignedTotalCount);
  const excessTotalCount = useSelector(store => store.user.planning.search.excessTotalCount);
  const unassignedTotalCount = useSelector(store => store.user.planning.search.unassignedTotalCount);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={styles.result}>{formatNumbers(outcomeTotalCount + outcomeUnassignedTotalCount + excessTotalCount + unassignedTotalCount) + " Search Result(s) From 4 Tables:  ‘" + params.keyword + "’"}</Typography>
      <SearchOutcomeResult />
      <SearchOutcomeUnassignedResult />
      <SearchExcessResult />
      <SearchUnassignedResult />
    </React.Fragment>
  );
}
