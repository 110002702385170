import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import TextField from 'shared-components/TextField/TextField';
import Select from "shared-components/Select/Select";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Modal from 'shared-components/Modal/Modal';

import { renderValue, addToEndDate } from "common/functions";
import { isPositiveDecimal } from "common/validations";
import { setDirty, setSearchText, setSuccess } from "store/general";
import { resetValues } from "store/common";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues, updateRequest } from "../store/reserve";
import { GetEquipmentReserveById, CreateOrUpdateEquipmentReserve, GetEquipmentList } from "services/UserPortal/EquipmentService";
import { GetNotYetStartedWorkOrderList, GetProductList, GetProductGrowthProcessList, GetProductTaskList } from "services/UserPortal/CommonLookupService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/equipment.module.scss";

export default function AddEditReserve(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const error = useSelector(store => store.general.error);
  const reservation = useSelector(store => store.user.equipment.reserve.reservation);
  const equipmentList = useSelector(store => store.user.equipment.reserve.equipmentList);
  const workOrderList = useSelector(store => store.common.unstartedWorkOrderList);
  const productList = useSelector(store => store.common.productList);
  const processList = useSelector(store => store.common.processList);
  const taskList = useSelector(store => store.common.taskList);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id]: e.target.value}));
  };

  const handleOnChange_select = (e) => {
    const payload = {
      workOrderId: null, 
      productId: null, 
      productVersionId: null, 
      productGrowthProcessId: null, 
      productTaskId: null,
      equipmentConfigId: null
    }
    if (e.target.name === "workOrderId") {
      dispatch(updateRequest({...payload, [e.target.name]: e.target.value}));
    } else if (e.target.name === "productId") {
      const product = productList.find(({id}) => id == e.target.value);
      dispatch(updateRequest({..._.omit(payload, ["workOrderId"]), [e.target.name]: e.target.value, productVersionId: product.productVersionId}));
    } else if (e.target.name === "productGrowthProcessId") {
      dispatch(updateRequest({[e.target.name]: e.target.value, productTaskId: null, equipmentConfigId: null}));
    } else {
      dispatch(updateRequest({[e.target.name]: e.target.value}));
    }
  };

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      const newValue = moment(value).set({second: 0, millisecond: 0});
      let payload = {[field]: newValue.format()};
      if (field === "reserveStartDate") {
        const endDate = addToEndDate(newValue, reservation.reserveEndDate, "hours");
        payload = {...payload, reserveEndDate: endDate};
      }
      dispatch(updateRequest(payload));
    } else {
      dispatch(updateRequest({[field] : value}));
    }
  };

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateEquipmentReserve())
        .then((response) => {
          if (response.error) {
            dispatch(setDirty(true));
            setOpenWarningModal(true);
          } else {
            const selectedWorkOrder = _.find(workOrderList, ({id})=>id===reservation.workOrderId);
            dispatch(setSuccess("Reservation has been successfully added to the reservation list " + selectedWorkOrder.workOrderNo));
            handleButtonClick_exit();
          }
        });
      });
    }
  };

  const handleButtonClick_exit = () => {
    history.push("/user/equipment/reserve");
  }

  const validateFields = () => {
    if (!reservation.workOrderId) {
      setErrorMsg({field: "workOrderId", msg: "Please select a work order"});
      return false;
    }
    if (!reservation.productId) {
      setErrorMsg({field: "productId", msg: "Please select a product"});
      return false;
    }
    if (!reservation.productGrowthProcessId) {
      setErrorMsg({field: "productGrowthProcessId", msg: "Please select a process"});
      return false;
    }
    if (!reservation.productTaskId) {
      setErrorMsg({field: "productTaskId", msg: "Please select a task"});
      return false;
    }
    if (!reservation.reserveStartDate) {
      setErrorMsg({field: "reserveStartDate", msg: "Please enter a start date"});
      return false;
    }
    if (!moment(reservation.reserveStartDate).isValid()) {
      setErrorMsg({field: "reserveStartDate", msg: "Invalid Date"});
      return false;
    }
    if (!reservation.reserveEndDate) {
      setErrorMsg({field: "reserveEndDate", msg: "Please enter an end date"});
      return false;
    }
    if (!moment(reservation.reserveEndDate).isValid()) {
      setErrorMsg({field: "reserveEndDate", msg: "Invalid Date"});
      return false;
    }
    if (moment(reservation.reserveEndDate).isBefore(moment(reservation.reserveStartDate))) {
      setErrorMsg({field: "reserveEndDate", msg: "End date/time cannot be before start date/time"});
      return false;
    }
    if (!reservation.equipmentConfigId) {
      setErrorMsg({field: "equipmentConfigId", msg: "Please select an equipment"});
      return false;
    }
    if (!reservation.reserveQty || reservation.reserveQty === "0") {
      setErrorMsg({field: "reserveQty", msg: "Please enter reserve quantity"});
      return false;
    }
    if (!isPositiveDecimal(reservation.reserveQty)) {
      setErrorMsg({field: "reserveQty", msg: "Please enter a valid reserve quantity"});
      return false;
    }
    setErrorMsg(false);
    return true;
  };

  React.useEffect(() => {
    if (reservation.reserveStartDate != "Invalid Date" && reservation.reserveQty && selectedEquipment) {
      const dur = moment.duration(reservation.reserveQty, selectedEquipment.uom.charAt(0).toLowerCase());
      dispatch(updateRequest({reserveEndDate: moment(reservation.reserveStartDate).add(dur).format()}));
    }
  },[reservation.equipmentConfigId, reservation.reserveStartDate, reservation.reserveQty]);

  React.useEffect(() => {
    if (reservation.workOrderId) {
      dispatch(GetProductList(reservation.workOrderId));
    }
  },[reservation.workOrderId]);

  React.useEffect(() => {
    if (reservation.productGrowthProcessId) {
      dispatch(GetProductTaskList(reservation.productGrowthProcessId));
      dispatch(GetEquipmentList({productGrowthProcessId: reservation.productGrowthProcessId}));
    }
  },[reservation.productGrowthProcessId]);

  React.useEffect(() => {
    if (reservation.productVersionId) {
      dispatch(GetProductGrowthProcessList(reservation.productVersionId));
    }
  },[reservation.productVersionId]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetNotYetStartedWorkOrderList());
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "reserve");
    if (path[index+1] !== "create") {
      dispatch(GetEquipmentReserveById(path[index+1]))
      .then((response) => {
        if (response.error) {
          Promise.all([dispatch(setDirty(false))])
          .then(() => {
            handleButtonClick_exit();
          });
        }
      });
    } else {
      dispatch(updateRequest({reserveStartDate : moment().format(), reserveEndDate: moment().add(1,'hours').format()}));
    }
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(setValues({filter: {reservation: {}}}));
      dispatch(resetNav());
      dispatch(resetValues());
    }
  },[]);

  const selectedEquipment = _.find(equipmentList, ({id})=>reservation.equipmentConfigId===id);
  return (
    <React.Fragment>
      <Card 
        title = {props.isEdit ? "Edit Reservation" : "Add Reservation"}
        cardActions={ 
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={handleButtonClick_exit}
            >
              Cancel
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Typography className={styles.formTitle}>Equipment Reservation</Typography>
        <Grid container spacing={4}>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Work Order No.:</Typography>
            <Select
              name="workOrderId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a work order"
              value={renderValue(reservation.workOrderId)}
              errorMsg={errorMsg}
            >
              {workOrderList && workOrderList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Product:</Typography>
            <Select
              name="productId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a product"
              value={renderValue(reservation.productId)}
              errorMsg={errorMsg}
              disabled={!reservation.workOrderId}
            >
              {productList && productList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Process:</Typography>
            <Select
              name="productGrowthProcessId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a process"
              value={renderValue(reservation.productGrowthProcessId)}
              errorMsg={errorMsg}
              disabled={!reservation.productId}
            >
              {processList && processList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.processName}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Task:</Typography>
            <Select
              name="productTaskId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a task"
              value={renderValue(reservation.productTaskId)}
              errorMsg={errorMsg}
              disabled={!reservation.productGrowthProcessId}
            >
              {taskList && taskList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.taskName}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Start Date/Time:</Typography>
            <DateTimePicker
              type="datetime"
              name="reserveStartDate"
              value={reservation.reserveStartDate}
              onChange={(e) => handleOnChange_date(e, "reserveStartDate")}
            />
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>End Date/Time:</Typography>
            <DateTimePicker
              type="datetime"
              name="reserveEndDate"
              value={reservation.reserveEndDate}
              minDateTime={reservation.reserveStartDate ? moment(reservation.reserveStartDate).format() : moment().add(1,'hours').format()}
              onChange={(e) => handleOnChange_date(e, "reserveEndDate")}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Equipment:</Typography>
            <Select
              name="equipmentConfigId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select an equipment"
              value={renderValue(reservation.equipmentConfigId)}
              errorMsg={errorMsg}
              disabled={!reservation.productGrowthProcessId}
            >
              {equipmentList && equipmentList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.equipmentName}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Measurement Unit:</Typography>
            <TextField
              id="uom"
              variant="outlined" 
              value={selectedEquipment ? selectedEquipment.uom : ""}
              disabled
            />
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Duration Reserved:</Typography>
            <TextField
              id="reserveQty"
              type="Number"
              variant="outlined" 
              placeholder="Enter reserve quantity"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(reservation.reserveQty)}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Card>
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning()}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Oops!"
        content={error}
        actions={
          <Button className={styles.button} onClick={() => handleModal_warning()}>Okay</Button>
        }
      />
    </React.Fragment>
  );
}
