import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MenuItem from "@mui/material/MenuItem";
// core components
import Select from "shared-components/Select/Select";
import Accordion from "shared-components/Accordion/Accordion";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import { rowsPerTable } from "config";
import { setSearchText, setDirty } from "store/general";
import { formatNumbers, roundTo2Decimal, renderValue } from "common/functions";
import { fixedCostTableHead } from "enums/UserPortal/TableHeaders";
import { updateRequest, setValues } from "../store/costOverview";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/costProductivity.module.scss";

import { GetCostSettingLookUpByCostCategoryId, GetWorkOrderCostByWorkOrderId, GetCostSettingById, GetTotalHarvestedWeightbyWOIdAndProductId, CreateOrUpdateWorkOrderCost, DeleteWorkOrderCost } from "services/UserPortal/CostProductivityService";

export default function FixedCost() {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const filter = useSelector(store => store.user.costProductivity.costOverview.filter);
  const totalCount = useSelector(store => store.user.costProductivity.costOverview.totalCount);
  const workOrderCost = useSelector(store => store.user.costProductivity.costOverview.workOrderCost);
  const nameList = useSelector(store => store.user.costProductivity.costOverview.nameList);
  const detail = useSelector(store => store.user.costProductivity.costOverview.detail);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('ruleNo');
  const [page, setPage] = React.useState(0);
  const [expanded, setExpanded] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [totalHarvestedWeight, setTotalHarvestedWeight] = React.useState(0);
  const [costPerKg, setCostPerKg] = React.useState(0);
  const [isAddEdit, setIsAddEdit] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleButtonClick_accordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
    dispatch(GetCostSettingLookUpByCostCategoryId(panel));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateWorkOrderCost())
        .then((response) =>{
          if (response.error) {
            dispatch(setDirty(true));
          }
          dispatch(GetWorkOrderCostByWorkOrderId({...filter, page: 0, maxResultCount: 9999}));
          resetState();
        })
      })
    }
  }

  const handleButtonClick_cancel = () => {
    if (id === 0) {
      dispatch(GetWorkOrderCostByWorkOrderId({...filter, page: 0, maxResultCount: 9999}));
      setPage(0);
    }
    resetState();
  }

  const handleModal_delete = (value) => {
    setId(value);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteWorkOrderCost(id))
    .then(() =>{
      setOpenModal(false);
      dispatch(GetWorkOrderCostByWorkOrderId({...filter, page: 0, maxResultCount: 9999}));
    })
  }

  const validateFields = () => {
    if (!detail.costSettingId) {
      setErrorMsg({field: "costSettingId", msg: "Please select a name"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setIsAddEdit(false);
    setId(null);
    setErrorMsg(false);
    setOpenModal(false);
    dispatch(setDirty(false));
    dispatch(setValues({detail: {}}));
  }

  React.useEffect(() => {
    if (filter.productId && filter.workOrderId) {
      dispatch(GetTotalHarvestedWeightbyWOIdAndProductId())
      .then((response) => {
        setTotalHarvestedWeight(response.payload.result)
      });
    }
  },[filter]);

  React.useEffect(() => {
    if (detail.costSettingId) {
      dispatch(GetCostSettingById({id: detail.costSettingId, stopLoading: true}))
      .then((response) => {
        setCostPerKg(response.payload.result.value)
      });
    }
  },[detail.costSettingId]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
    }
  },[]);

  return (
    <React.Fragment>
      <div>
        <Typography className={styles.costTitle}>Overall Cost: ${workOrderCost.totalAmount ? formatNumbers(roundTo2Decimal(workOrderCost.totalAmount)) : 0}</Typography>
        <Typography className={styles.costTitle}>Variable Cost: ${workOrderCost.variableCostType && workOrderCost.variableCostType.subTotal ? formatNumbers(roundTo2Decimal(workOrderCost.variableCostType.subTotal)) : 0}</Typography>
        <Typography className={styles.costTitle}>Fixed Cost: ${workOrderCost.fixedCostType && workOrderCost.fixedCostType.subTotal ? formatNumbers(roundTo2Decimal(workOrderCost.fixedCostType.subTotal)) : 0}</Typography>
      </div>
      {workOrderCost && workOrderCost.fixedCostType && workOrderCost.fixedCostType.fixedCostCategories.map((item, index) => {
        return ( 
          <Accordion
            key={index}
            classes={{
              root: styles.accordion,
            }}
            expanded={expanded == item.id}
            onChange={handleButtonClick_accordion(item.id)}
            disabled={isDirty}
            header={
              <div className={styles.overviewHeader}>
                <span>{item.name}</span>
                <div><span className={styles.totalFont}>Subtotal:</span> ${item.subTotal ? formatNumbers(roundTo2Decimal(item.subTotal)) : 0}</div>
              </div>
            }
          >
            <Paper className={styles.content} elevation={0}>
              <Table
                className={styles.tableMargin}
                header={fixedCostTableHead}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { item.fixedCostDetails && item.fixedCostDetails.map((subItem,subIndex) => {
                  return (
                    <TableRow key={subIndex}>
                      <TableCell>
                        { isAddEdit && id === subItem.id
                          ? <Select
                              variant="outlined" 
                              name="costSettingId"
                              onChange={(e)=>handleOnChange_select(e)}
                              value={renderValue(detail.costSettingId)}
                              placeholder="Select a name"
                              errorMsg={errorMsg}
                            >
                              {nameList.map((option, i) => {
                                return <MenuItem key={i} value={option.id}>{option.description}</MenuItem>;
                              })} 
                            </Select>
                          : subItem.description
                        } 
                      </TableCell> 
                      <TableCell>
                        { isAddEdit && id === subItem.id
                        ? formatNumbers(roundTo2Decimal(costPerKg))
                        : formatNumbers(roundTo2Decimal(subItem.costPerKg))
                        } 
                      </TableCell>
                      <TableCell>
                        { isAddEdit && id === subItem.id
                        ? formatNumbers(roundTo2Decimal(totalHarvestedWeight))
                        : formatNumbers(roundTo2Decimal(subItem.weightOfVegetable))
                        }  
                      </TableCell>
                      <TableCell>
                        { isAddEdit && id === subItem.id
                        ? formatNumbers(roundTo2Decimal(totalHarvestedWeight * costPerKg))
                        : formatNumbers(roundTo2Decimal(subItem.subTotal))
                        }  
                      </TableCell>
                      <TableCell align="right">
                        { isAddEdit && id === subItem.id
                        ? <React.Fragment>
                            <IconButton 
                              type="confirm"
                              onClick={() => handleButtonClick_confirm()}
                            />
                            <IconButton 
                              type="close"
                              onClick={() => handleButtonClick_cancel()}
                            />
                          </React.Fragment>
                        : <React.Fragment>
                            <IconButton 
                              type="delete"
                              onClick={() => handleModal_delete(subItem.id)}
                              disabled={Boolean(id && id !== subItem.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
              { count > 1 &&
                <Pagination 
                  count={count} 
                  page={page+1}
                  onChange={(e, pageNo)=>setPage(pageNo-1)}
                />
              }
            </Paper>
          </Accordion>
        )
      })}
      <Modal
        open={openModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this record? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
