import React from "react";
// @mui/material
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';

import styles from "assets/jss/error-page/error.module.scss";

export default function Error() {

  return (
    <div className={styles.container}>
      <div>
        <CircularProgress
          variant="indeterminate"
          className={styles.progress}
          thickness={6}
        />
        <Typography className={styles.loadingDesc}>Loading, please wait...</Typography>
      </div>
    </div>
  );
}
