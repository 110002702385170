import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// core components
import Card from "shared-components/Card/Card";
import CustomTabs from "shared-components/Tabs/Tabs";

import Empty from "error-page/Empty";
import DeviceControlBank from "./DeviceControlBank";
import DeviceControlFloor from "./DeviceControlFloor";
import ConfigurationDetail from "./ConfigurationDetail";
import History from "./History";
import { farmConfig } from "enums/Constants";
import { setSearchText } from "store/general";
import { setValues, reset } from "../store/deviceControl";
import { GetAllFloorPlan } from "services/UserPortal/ClimateService";

import styles from "assets/jss/components/UserPortal/climate.module.scss";

export default function DeviceControl(props) {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const bankList = useSelector(store => store.common.bankList);
  const floorSectorList = useSelector(store => store.user.climate.deviceControl.floorSectorList);
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);
  const [bankFloorTab, setBankFloorTab] = React.useState(0);
  const [display, setDisplay] = React.useState(props.location.state ? props.location.state.display : "main");  // main, detail

  const toggleTab = () => {
    if (bankFloorTab === 0) {
      return bankList.map((item) => {return item.bankName});
    } else {
      return floorSectorList.map((item) => {
        const number = item.name.replace("Floor ", "").split(" Sector ");
        if (Number(number[1]) === 1) {
          return 'Chamber ' + ((Number(number[0]) * (Number(number[1]) + 1))-1)
        } else if (Number(number[1]) === 2) {
          return 'Chamber ' + (Number(number[0]) * Number(number[1]))
        }
      });
    }
  }

  const handleOnChange_tab = (e, value) => {
    setTab(value);
    dispatch(setValues({bays: []}));
  }

  const handleOnChange_bankFloorTab = (e, value) => {
    setBankFloorTab(value);
    setTab(0);
    if (display !== "detail" && display !== "history") {
      if (value === 0) {  // bank
        setDisplay("main");
      } else if (value === 1) {  // floor
        setDisplay("floor");
      }
    }
  };

  const handleOnClick_link = (link) => {
    setDisplay(link);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllFloorPlan());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  const renderComponent = () => {
    switch (display) {
      case "main":
        // get bank detail and pass in as props
        return <DeviceControlBank bank={bankList.length && bankList[tab]} />
      case "floor":
        return <DeviceControlFloor selectedFloorSector={floorSectorList.length && floorSectorList[tab]} />
      case "detail":
        return <ConfigurationDetail selectedFloorSector={floorSectorList.length && floorSectorList[tab]} bank={bankList.length && bankList[tab]} bankFloorTab={bankFloorTab} tab={tab} />
      case "history":
        return <History selectedFloorSector={floorSectorList.length && floorSectorList[tab]} bank={bankList.length && bankList[tab]} bankFloorTab={bankFloorTab} tab={tab} />
    }
  }

  return (
    <React.Fragment>
      {bankList.length 
      ? <React.Fragment>
          <Card 
            title = {
              <React.Fragment>
                Device Control
                <Tabs 
                  className={styles.bankFloorTab}
                  classes={{
                    indicator: styles.dateIndicator
                  }}
                  value={bankFloorTab} 
                  onChange={handleOnChange_bankFloorTab}
                >
                  <Tab className={bankFloorTab === 0 ? clsx(styles.bankFloorLabel, styles.selectedTab) : styles.bankFloorLabel} label={farmConfig.banks} disabled={isDirty} />
                  <Tab className={bankFloorTab === 1 ? clsx(styles.bankFloorLabel, styles.selectedTab) : styles.bankFloorLabel} label="Chamber" disabled={isDirty} />
                </Tabs>
              </React.Fragment>
              }
            subheader={
              <CustomTabs 
                tabs={toggleTab()}
                value={tab} 
                onChange={handleOnChange_tab}
                disabled={isDirty}
              />
            }
            action={
              <React.Fragment>
                {(display === "main" || display === "floor") &&
                  <Button
                    className={styles.buttonSecondary}
                    onClick={()=>handleOnClick_link("history")}
                    disabled={isDirty}
                  >
                    History
                  </Button>
                }
                {(display === "main" || display === "floor") &&
                  <Button
                    className={clsx(styles.buttonSecondary, styles.topRightTabSpacing)}
                    onClick={()=>handleOnClick_link("detail")}
                    disabled={isDirty}
                  >
                    Timing Configuration
                  </Button>
                }
              </React.Fragment>
            }
            cardActions={ (display === "detail" || display === "history") &&
              <Button
                className={styles.buttonSecondary}
                onClick={()=>setDisplay("main")}
              >
                Go Back
              </Button>
            }
          >
            {renderComponent()}
          </Card>
        </React.Fragment>
      : <Empty 
          description={"Your page is empty. There are no "+farmConfig.banks+" to display."}
        />
      }
    </React.Fragment>
  );
}
