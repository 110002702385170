import { combineReducers } from "redux";
import overview from "./overview";
import adhoc from "./adhoc";
import inventoryCount from "./inventoryCount";
import pallet from "./pallet";

const AMRReducer = combineReducers({
  overview,
  adhoc,
  inventoryCount,
  pallet,
});

export default AMRReducer;