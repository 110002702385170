import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

// Lookup
export const GetCustomerList = createAsyncThunk(
  "customerOrder/getCustomerList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetCustomerList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetPONumberFromERP  = createAsyncThunk(
  "customerOrder/getPONumberFromERP ", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetPONumberFromERP", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductList  = createAsyncThunk(
  "customerOrder/getProductList ", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetProductList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetPackageList  = createAsyncThunk(
  "customerOrder/getPackageList ", 
  async (id, {rejectWithValue}) => {
    const params = {
      ProductId: id,
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetPackageList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllUnitOfMeasurement  = createAsyncThunk(
  "customerOrder/getAllUnitOfMeasurement ", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetAllUnitOfMeasurement", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSaleOrderStatusLookUp  = createAsyncThunk(
  "customerOrder/getSaleOrderStatusLookUp ", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetSaleOrderStatusLookUp", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetPurchaseOrderLookUp  = createAsyncThunk(
  "customerOrder/getPurchaseOrderLookUp ", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetPurchaseOrderLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSaleOrderLookUp  = createAsyncThunk(
  "customerOrder/getSaleOrderLookUp ", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetSaleOrderLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// export const GetCustomerOrderType  = createAsyncThunk(
//   "customerOrder/getCustomerOrderType ", 
//   async (_, {rejectWithValue}) => {
//     try {
//       const response = await FomsMS.get("userportal/CustomerOrder/GetCustomerOrderType");
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   },
// );

// Management
export const GetAllSaleOrders = createAsyncThunk(
  "customerOrder/getAllSaleOrders", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      // DeliveryStartDate: param.deliveryDate,
      // DeliveryEndDate: param.deliveryDate,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetAllSaleOrders", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCustomerOrderBySaleOrderID = createAsyncThunk(
  "customerOrder/GetCustomerOrderBySaleOrderID", 
  async (id, {rejectWithValue}) => {
    const params = {SaleOrderId: id};
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetCustomerOrderBySaleOrderID", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSaleOrderById = createAsyncThunk(
  "customerOrder/getSaleOrderById", 
  async ({id, stopLoading}, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetSaleOrderById", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTotalWeight = createAsyncThunk(
  "customerOrder/getTotalWeight", 
  async (param, {rejectWithValue}) => {
    const params = param;
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetTotalWeight", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateCustomerOrder = createAsyncThunk(
  "customerOrder/createOrUpdateCustomerOrder", 
  async (id, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const order = getState().user.customerOrder.management.customerOrder;
    const subSaleOrders = getState().user.customerOrder.management.subSaleOrders;
    let body = {
      ...order,
      saleOrder: {
        ...order.saleOrder,
        farmId: find(userDetail.farms, "isActive").id,
        subSaleOrders,
        id,
      },
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/CustomerOrder/CreateOrUpdateCustomerOrder", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CancelSaleOrder = createAsyncThunk(
  "customerOrder/CancelSaleOrder", 
  async (param, {rejectWithValue}) => {
    const params = {...param}
    try {
      const response = await FomsMS.delete("/userportal/CustomerOrder/CancelSaleOrder", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Capacity
export const GetCustomerCapacityReport = createAsyncThunk(
  "customerOrder/getCustomerCapacityReport", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetCustomerCapacityReport", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCustomerOrderCapacityDetailReportByDateAndProduct = createAsyncThunk(
  "customerOrder/getCustomerOrderCapacityDetailReportByDateAndProduct", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/GetCustomerOrderCapacityDetailReportByDateAndProduct", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchCustomerOrder = createAsyncThunk(
  "customerOrder/searchCustomerOrder", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/SearchCustomerOrder", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchCustomerOrderCapacity = createAsyncThunk(
  "customerOrder/searchCustomerOrderCapacity", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/CustomerOrder/SearchCustomerOrderCapacityDetailReportByDateAndProduct", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);