import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { reset } from "../store/search";
import { setSearchText } from "store/general";
import { customerOrderTableHead, subCustomerOrderTableHead } from "enums/UserPortal/TableHeaders";
import { SearchCustomerOrder } from "services/UserPortal/CustomerOrderService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/salesOrder.module.scss";

export default function SearchSaleOrderResult() {
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.customerOrder.search.saleOrderTotalCount);
  const results = useSelector(store => store.user.customerOrder.search.saleOrderResults);
  const [page, setPage] = React.useState(0);
  const [subPage, setSubPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  React.useEffect(() => {
    dispatch(SearchCustomerOrder({keyword: params.keyword, page, stopLoading: Boolean(page)}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value) {
      return (
        <Highlighter
          highlightClassName={styles.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: styles.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Sale Order List"}
                <IconButton 
                  className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={styles.table}>
                  <Table
                    expandable
                    className={styles.mainTable}
                    header={customerOrderTableHead}
                  >
                    { results.map((item,index) => {
                      const subCount = Math.ceil(item.subSaleOrders.length / rowsPerTable);
                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            hover
                            sx={{ cursor: 'pointer' }}
                            onClick={() => open === index ? setOpen(false) : setOpen(index)}
                          >
                            <TableCell>
                              <IconButton 
                                className={open === index ? styles.rotate : null}
                                onClick={() => open === index? setOpen(false) : setOpen(index)}
                              >
                                <img className={styles.icon} src={arrow} alt="arrow" />
                              </IconButton>
                            </TableCell>
                            <TableCell>{highlightValue(item.saleOrderNumber)}</TableCell>
                            <TableCell>{highlightValue(item.purchaseOrderNo)}</TableCell>
                            <TableCell>{highlightValue(item.customerName)}</TableCell>
                            <TableCell>{highlightValue(moment(item.deliveryDate).format("DD/MM/yyyy"))}</TableCell>
                            <TableCell>{highlightValue(item.orderType)}</TableCell>
                            <TableCell>{highlightValue(item.saleOrderStaus)}</TableCell>
                            <TableCell><Link to={{pathname: "/user/sales-order/management/"+item.id}} className={styles.link}>Link</Link></TableCell>
                          </TableRow>
                          <TableRow>
                            { open===index && 
                              <TableCell className="pt-0 pb-0" colSpan={8}>
                                <Collapse in={open === index}>
                                  <Table
                                    className={styles.subTable}
                                    header={subCustomerOrderTableHead}
                                  >
                                    { item.subSaleOrders.slice(subPage * rowsPerTable, subPage * rowsPerTable + rowsPerTable)
                                    .map((subItem, subIndex) => {
                                      return (
                                        <React.Fragment key={subIndex}>
                                          <TableRow>
                                            <TableCell>{highlightValue(subItem.subSaleOrderNumber)}</TableCell>
                                            <TableCell>{subItem.workOrderId ? highlightValue(subItem.workOrderId) : "Not Issued"}</TableCell>
                                            <TableCell>{highlightValue(subItem.subSaleOrderStatus)}</TableCell>
                                            <TableCell>{highlightValue(subItem.productComponent.split("(").join(" ("))}</TableCell>
                                          </TableRow>
                                        </React.Fragment>
                                      )
                                    })}
                                  </Table>
                                  { subCount > 1 &&
                                    <Pagination 
                                      count={subCount} 
                                      page={subPage+1}
                                      onChange={(e, pageNo)=>setSubPage(pageNo-1)}
                                    />
                                  }
                                </Collapse>
                              </TableCell>
                            }
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
