import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { checkPermission, formatNumbers, roundTo2Decimal, renderValue, filterParam } from "common/functions";
import { setSearchText } from "store/general";
import { setValues } from "../store/cost";
import { equipmentCostTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllEquipmentCost } from "services/UserPortal/EquipmentService";

import styles from "assets/jss/components/UserPortal/equipment.module.scss";

export default function Cost() {
  const dispatch = useDispatch();
  const lotIdList = useSelector(store => store.common.lotIdList);
  const filter = useSelector(store => store.user.equipment.cost.filter);
  const totalCount = useSelector(store => store.user.equipment.cost.totalCount);
  const totalCost = useSelector(store => store.user.equipment.cost.totalCost);
  const costs = useSelector(store => store.user.equipment.cost.costs);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const [newFilter, setNewFilter] = React.useState({workOrderNo: null, lotId: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('productRefNo');

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    if (key === "workOrderNo") {
      dispatch(setValues({filter: {...filter, workOrderNo: null, rawMaterialId: null, lotId: null, page: 0}}));
    } else {
      dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
    }
  };

  const getAllEquipmentCost = (stopLoading) => {
    if (filter.workOrderNo) {
      dispatch(GetAllEquipmentCost({...filter, stopLoading}));
    } else {
      dispatch(setValues({ totalCount: 0, totalCost: 0, costs: []}));
    }
  }
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search, page: 0}}));
    } else {
      if (workOrderList.length) {
        dispatch(setValues({filter: {workOrderNo: workOrderList[0].workOrderNo, page: 0}}));
      } else {
        dispatch(setValues({filter: {workOrderNo: null, page: 0}}));
      }
    }
  },[workOrderList]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllEquipmentCost();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  useInterval(() => {
    getAllEquipmentCost(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        title={filter.workOrderNo ? "Equipment Cost for "+ filter.workOrderNo : "Equipment Cost"}
        subheader={filteredKeys.length > 0
        ? <React.Fragment>
            {filteredKeys.map((key) => (
              <Tag
                key={key}
                variant="outlined"
                tabIndex={-1}
                label={filter[key]}
                className={styles.tag}
                onDelete={() => handleButtonClick_delete(key)}
              />
            ))}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the cost list
                  <Select
                    className={styles.filterDropdown}
                    name="workOrderNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderNo)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.workOrderNo}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <Table
          className={styles.table}
          header={equipmentCostTableHead}
          // order={order}
          // orderBy={orderBy}
          // onRequestSort={handleRequestSort}
        >
          { costs.map((item,index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.productRefNo}</TableCell>
                  <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                  <TableCell>{item.processName}</TableCell>
                  <TableCell>{item.taskName}</TableCell>
                  <TableCell>{item.equipmentName}</TableCell>
                  <TableCell>{item.equipmentRefNo}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.actualDuration))}</TableCell>
                  <TableCell>{item.uom}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.actualCost))}</TableCell>
                </TableRow>
              </React.Fragment>
            )
          })}
          <TableRow>
            <TableCell colSpan={8} />
            <TableCell className={styles.bold}>Total Cost</TableCell>
            <TableCell className={styles.bold}>{formatNumbers(roundTo2Decimal(totalCost))}</TableCell>
          </TableRow>
        </Table>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
        {checkPermission("Planning", "readEdit") &&
          <Typography className={styles.link}>
            <Link to={{pathname: "/user/planning/planning-outcome"}}>Assigning of Equipment is done by the Planning Module</Link>
          </Typography>
        }
      </Card>
    </React.Fragment>
  );
}
