import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Button from "@mui/material/Button";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Modal from "shared-components/Modal/Modal";

import { useInterval } from 'common/utils';
import { setDirty } from "store/general";
import { rowsPerTable } from "config";
import { operationActiveAlarmTableHead, equipmentActiveAlarmTableHead, rawMaterialActiveAlarmTableHead, climateActiveAlarmTableHead } from "enums/UserPortal/TableHeaders";
import { setValues, resolveRequest, reset } from ".";
import { GetAllAlarmNotification, SetResolveAlarmNotification } from "services/UserPortal/AlarmService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/alarm.module.scss";

export default function ActiveAlarm(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.alarm.totalCount);
  const result = useSelector(store => store.user.alarm.alarms);
  const resolve = useSelector(store => store.user.alarm.resolve);
  const [openModal, setOpenModal] = React.useState(false);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('creationTime');
  const [page, setPage] = React.useState(0);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleButtonClick_checkbox = (e) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(resolve);
    const value = Number(e.target.id);
    if (payload.includes(value)) {
      payload.splice(payload.indexOf(value), 1);
    } else {
      payload.push(value);
    }
    dispatch(resolveRequest(payload));
  };

  const handleButtonClick_resolve = () => {
    dispatch(SetResolveAlarmNotification(resolve))
    .then((response)=> {
      if (!response.error) {
        dispatch(setDirty(false));
        setOpenModal(false)
        getAllAlarmNotification(0);
        setPage(0);
        dispatch(resolveRequest([]));
      }
    })
  };

  const items = [
    {title: "Operation Module", url: "/user/alarm/details/operations", tableHead: operationActiveAlarmTableHead, name: "UserPortal.Operation"},
    {title: "Equipment Module", url: "/user/alarm/details/equipment", tableHead: equipmentActiveAlarmTableHead, name: "UserPortal.Equipment"},
    {title: "Raw Material Module", url: "/user/alarm/details/raw-material", tableHead: rawMaterialActiveAlarmTableHead, name: "UserPortal.RawMaterial"},
    {title: "Climate & Environment Module", url: "/user/alarm/details/climate", tableHead: climateActiveAlarmTableHead, name: "UserPortal.ClimateEnvironment"},
  ]

  const getAllAlarmNotification = (pageNo, stopLoading) => {
    if (alarmPage) {
      dispatch(GetAllAlarmNotification({...props.filter, page: pageNo, moduleName: alarmPage.name, IsResolved: false, IsNotification: false, stopLoading}))
      .then((response) => {
        if (response.payload && response.payload.result) {
          dispatch(setValues({
            totalCount: response.payload.result.totalCount,
            alarms: response.payload.result.items,
          }));
        }
      });
    }
  }

  React.useEffect(() => {
    setPage(0);
    getAllAlarmNotification(0);
  },[props.filter]);

  React.useEffect(() => {
    getAllAlarmNotification(page);
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllAlarmNotification(page, true);
  });

  const alarmPage = items.find(({url})=>url===props.location.pathname);
  return (
    <React.Fragment >
      <Table
        className={styles.table}
        header={alarmPage && alarmPage.tableHead}
        // order={order}
        // orderBy={orderBy}
        // onRequestSort={handleRequestSort}
      >
        { result.map((item,index) => {
          return (
            <TableRow key={index}>
              {alarmPage && alarmPage.tableHead.map(({id}, i) => {
                if (id !== "action") {
                  let value = _.get(item, id);
                  if (id.includes("Time") && value) {
                    value = moment(value).format("DD MMM YYYY, HHmm");
                  }
                  return <TableCell key={i}>{value}</TableCell>
                }
              })}
              <TableCell><Checkbox id={item.id} onChange={(e) => handleButtonClick_checkbox(e)} checked={Boolean(resolve.find((id) => id===item.id))} /></TableCell>
            </TableRow>
          )
        })}
      </Table>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
        />
      }
      <div className={styles.buttonDiv}>
        <Button
          className={styles.buttonSecondary}
          onClick={()=>history.push("/user/alarm/details")}
        >
          Go Back
        </Button>
        <Button
          className={styles.button}
          onClick={()=>setOpenModal(true)}
          disabled={resolve.length === 0}
        >
          Resolve
        </Button>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you want to mark this/these alarm(s) as resolved? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => setOpenModal(false)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_resolve()}>Confirm</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
