import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { formatNumbers, roundTo2Decimal } from "common/functions";

import Pagination from "./Pagination";
import styles from "assets/jss/components/UserPortal/home.module.scss";

import palette from "assets/jss/general.module.scss";

export default function Warehouse() {
  let history = useHistory();
  const results = useSelector(store => store.user.home.warehouseWidget);
  const [page, setPage] = React.useState(0);
  
  const config = () => {
    return ({
      colors: ["#00CFB4", palette.secondary],
      title: {
        text: formatNumbers(roundTo2Decimal((((results[page] && results[page].available)/((results[page] && results[page].available) + (results[page] && results[page].used)))*100)))+"%",
        align: 'center',
        y: 70,
        style: {
          fontSize: "2.083vw",
          fontWeight: 700,
        }
      },
      subtitle: {
        text: 'Capacity Remaining',
        align: 'center',
        verticalAlign: 'middle',
        y: 30,
        style: {
          fontSize: "0.833vw"
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          center: [null, '-20%'],
          size: '300%'
        }
      },
      series: [{
        type: 'pie',
        innerSize: '75%',
        data: [['Used', results[page] && Number(results[page].used)],
        {
          name: 'Available',
          y: results[page] && Number(results[page].available),
        }],
      }],
    })
  };

  return (
    <React.Fragment>
      {results.length
      ? <Grid container spacing={2}>
          <Grid item xs={6}>
            <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: styles.pieChart }}/>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} style={{height: "unset"}} className={styles.paper}>
              <Typography className={styles.tableTitle}>Warehouse Name:</Typography>
              <Typography className={styles.paperContent}>{results[page] && results[page].warehouseName}</Typography>
            </Paper>
            <Paper elevation={0} style={{height: "unset"}} className={clsx(styles.paper, styles.widgetMargin)}>
              <Typography className={styles.tableTitle}>Warehouse ID:</Typography>
              <Typography className={styles.paperContent}>{results[page] && results[page].warehouseRefNo}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} className={styles.legend}>
            <div className={styles.legendColor} />&nbsp;Used:&nbsp;<span className={styles.paperContent}>{formatNumbers(roundTo2Decimal(results[page] && results[page].used))}{results[page] && results[page].uom}</span>
            <div className={styles.legendColor2} />&nbsp;Available:&nbsp;<span className={styles.paperContent}>{formatNumbers(roundTo2Decimal(results[page] && results[page].available))}{results[page] && results[page].uom}</span>
          </Grid>
        </Grid>
      : <Typography className={styles.tableTitle}>Nothing to show</Typography>
      }
      <div className={styles.widgetAction}>
        { results.length > 1 &&
          <Pagination 
            count={results.length} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        <Button className={clsx(styles.buttonSecondary, styles.viewAllButton)} onClick={()=>history.push("/user/warehouse/master-list")}>View All</Button>
      </div>
    </React.Fragment>
  );
}
