// Device Tagging
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
// core components
import Card from "shared-components/Card/Card";
import TableCell from "shared-components/Table/TableCell";

import Empty from "error-page/Empty";
import { farmConfig } from "enums/Constants";
import { reset } from "./store/deviceTagging";
import { GetAllDevicesTagging } from "services/AdminPortal/DeviceTaggingService";

import styles from "assets/jss/components/AdminPortal/deviceTagging.module.scss";
import { Typography } from "@mui/material";

import { useRouteCanWrite } from "hooks";

export default function DeviceTagging() {
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const devices = useSelector(store => store.admin.deviceTagging.deviceTagging.devicesTagging);

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllDevicesTagging());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);
  
  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  return (
    <React.Fragment>
      { devices && devices.floorPlanList.length > 0 
      ? <React.Fragment>
          <Card 
            title = {_.find(userDetail.farms, "isActive").farmName}
          >
            <div className={styles.displayScroll}>
              <Table className={styles.mainTable}>
                <TableBody>
                  <TableRow>
                    <TableCell />
                    { devices.floorPlanList.map((item, index) => {
                      return (
                        <TableCell key={index} className={styles.tagTableCell}>
                          <Typography className={styles.location}>
                            Floor {item.floorNumber} - Sector {item.sectorNumber}
                          </Typography>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography className={styles.label}>Uploaded Floorplans</Typography>
                    </TableCell>
                    {/* Jump to Step 2 */}
                    { devices.floorPlanList.map((item, index) => {
                      return ( 
                        <TableCell key={index}>
                          <Link to={canWrite ? {pathname: "/admin/device-tagging/edit", state: {step: 1, floorId: item.floorId, sectorId: item.sectorId}} : "#"}>
                            <div className={styles.thumbnail}>
                              { item.floorPlanDiagramUrl 
                              ? <img className={styles.img} src={item.floorPlanDiagramUrl} alt="thumbnail" />
                              : <Typography className={clsx(styles.imgText, styles.divText)}>No Floorplan</Typography>
                              }
                            </div>
                          </Link>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography className={styles.label}>Add {farmConfig.banks} to Floorplans</Typography>
                    </TableCell>
                    {/* Jump to Step 3 */}
                    { devices.floorPlanList.map((item, index) => {
                      return ( 
                        <TableCell key={index}>
                          <Link to={canWrite ? {pathname: "/admin/device-tagging/edit", state: {step: 2, floorId: item.floorId, sectorId: item.sectorId}} : "#"}>
                            <div className={styles.div}>
                              <Typography className={styles.divText}>{item.bankCount ? item.bankCount : "No"} {farmConfig.bank}{item.bankCount > 1 && "s"}</Typography>
                            </div>
                          </Link>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography className={styles.label}>{farmConfig.bank} Information</Typography>
                    </TableCell>
                    {/* Jump to Step 4 */}
                    { devices.floorPlanList.length > 0 && _.orderBy(devices.bankList, ["bankName"], ["asc"]).map((item, index) => {
                      return ( 
                        <TableCell key={index}>
                          <Link to={canWrite ? {pathname: "/admin/device-tagging/edit", state: {step: 3, bankId: item.bankId}} : "#"}>
                            <div className={styles.div}>
                              <Typography className={styles.divText}>{item.bankName}</Typography>
                            </div>
                          </Link>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Card>
        </React.Fragment>
      : <React.Fragment>
          <Empty 
            description={"Your page is empty. " + (canWrite ? "Tag a device to get started!" : "")}
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
}
