import { find } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

// Lookup
export const GetAllDevices = createAsyncThunk(
  "rfid/getAllDevices", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      isIncludedDraft: true
    }
    try {
      const response = await FomsMS.get("/RFID/GetAllDevices", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// RFID
export const GetAllRFIDs = createAsyncThunk(
  "rfid/getAllRFIDs", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      MaxResultCount: 99
    }
    try {
      const response = await FomsMS.get("/RFID/GetAllRFIDs", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRFID = createAsyncThunk(
  "rfid/getRFID", 
  async (id, {rejectWithValue}) => {
    const params = {id}
    try {
      const response = await FomsMS.get("/RFID/GetRFID", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteRFID = createAsyncThunk(
  "rfid/deleteRFID", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/RFID/DeleteRFID", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRFID = createAsyncThunk(
  "rfid/createOrUpdateRFID", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const RFID = getState().admin.rfid.RFID;
    const body = {
      ...RFID,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/RFID/CreateOrUpdateRFID", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Grouping
export const GetAllRFIDGroup = createAsyncThunk(
  "rfid/getAllRFIDGroup", 
  async (_, {rejectWithValue}) => {
    const params = {
      MaxResultCount: 99
    }
    try {
      const response = await FomsMS.get("/RFID/GetAllRFIDGroup", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRFIDGroup = createAsyncThunk(
  "rfid/getRFIDGroup", 
  async (id, {rejectWithValue}) => {
    const params = {id}
    try {
      const response = await FomsMS.get("/RFID/GetRFIDGroup", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteRFIDGroup = createAsyncThunk(
  "rfid/deleteRFIDGroup", 
  async (id, {rejectWithValue}) => {
    const params = {id}
    try {
      const response = await FomsMS.delete("/RFID/DeleteRFIDGroup", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRFIDGroup = createAsyncThunk(
  "rfid/createOrUpdateRFIDGroup", 
  async (_, {getState, rejectWithValue}) => {
    const grouping = getState().admin.rfid.grouping;
    let body = grouping;
    try {
      const response = await FomsMS.post("/RFID/CreateOrUpdateRFIDGroup", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Batch Update
export const DownloadBatchTemplate = createAsyncThunk(
  "rfid/downloadBatchTemplate", 
  async (_, {rejectWithValue}) => {
    const config = {
      responseType: 'blob',
      params: {
        stopLoading: true
      }, 
    }
    try {
      const response = await FomsMS.get("/RFID/DownloadBatchTemplate", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadBatchRFID = createAsyncThunk(
  "rfid/uploadBatchRFID", 
  async (file, {rejectWithValue}) => {
    try {
      let body = new FormData();
      body.append("File", file);
      const userDetail = JSON.parse(localStorage.getItem("userDetail"));
      let params = {
        FarmId: find(userDetail.farms, "isActive").id
      }
      const response = await FomsMS.post("/RFID/UploadBatchRFID", body, {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateBatchRFID = createAsyncThunk(
  "rfid/createBatchRFID", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().admin.rfid.batchRFIDs;
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
      let params = {
        FarmId: find(userDetail.farms, "isActive").id
      }
    try {
      const response = await FomsMS.post("/RFID/CreateBatchRFID", body, {params, isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchRFID = createAsyncThunk(
  "rfid/searchRFID", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/RFID/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);