import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllEmployees = createAsyncThunk(
  "humanResource/getAllEmployees", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/HumanResource/GetAllEmployees", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEmployee = createAsyncThunk(
  "humanResource/getEmployee", 
  async (userId, {rejectWithValue}) => {
    const params = {userId};
    try {
      const response = await FomsMS.get("/HumanResource/GetEmployeeByUserId", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteEmployee = createAsyncThunk(
  "humanResource/deleteEmployee", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/HumanResource/Delete", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateEmployee = createAsyncThunk(
  "humanResource/createOrUpdateEmployee", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const employee = getState().admin.humanResource.configuration.employee;
    let body = {
      ...employee,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/HumanResource/CreateOrUpdate", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllTimeInterval = createAsyncThunk(
  "humanResource/getAllTimeInterval", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/HumanResource/GetAllTimeInterval");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Task Settings
export const GetAllTaskSetting = createAsyncThunk(
  "humanResource/getAllTaskSetting", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount ? param.maxResultCount : rowsPerTable
    }
    try {
      const response = await FomsMS.get("/HumanResource/GetAllTaskSetting", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateTaskSetting = createAsyncThunk(
  "humanResource/createOrUpdateTaskSetting", 
  async (_, {getState, rejectWithValue}) => {
    const data = getState().admin.humanResource.settings.task;
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...data,
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.post("/HumanResource/CreateOrUpdateTaskSetting", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)

export const DeleteTaskSetting = createAsyncThunk(
  "humanResource/deleteTaskSetting", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/HumanResource/DeleteTaskSetting", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchEmployee = createAsyncThunk(
  "humanResource/searchCustomer", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/HumanResource/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);