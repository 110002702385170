import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import Switch from "@mui/material/Switch";
// core components
import styles from "assets/jss/shared-components/switch.module.scss";

export default function CustomSwitch({size, ...props}) {

  return (
    <Switch
      {...props}
      focusVisibleClassName={styles.focusVisible}
      classes={{
        root: size?styles.rootsm:styles.root,
        switchBase: size?styles.switchBasesm:styles.switchBase,
        thumb: size?styles.thumbsm:styles.thumb,
        track: styles.track,
      }}
      sx={{
        "& .MuiSwitch-switchBase": {
          "&.Mui-checked": {
            color: "white",
            transform: "translateX(1.250vw)",
            "+ .MuiSwitch-track": {
              background: "linear-gradient(136.67deg, #86D295 8.34%, #00CFB4 95.26%)",  // #customise
              border: "none",
            },
          }
        }
      }}
    />
  );
}

CustomSwitch.propTypes = {
  size: PropTypes.string,
};