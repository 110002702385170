import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { filterParam } from 'common/functions';
import { setSearchText } from "store/general";
import { setValues } from "../store/planningOutcome";

import Outcomes from "./Outcomes";
import Excess from "./Excess";
import Unassigned from "./Unassigned";

export default function PlanningOutcome() {
  const dispatch = useDispatch();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, deliveryStartDate: null, deliveryEndDate: null});

  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search, page: 0}}));
    } else {
      if (workOrderList.length) {
        dispatch(setValues({filter: {workOrderId: workOrderList[0].id}}));
      } else {
        dispatch(setValues({filter: {workOrderId: null}}));
      }
    }
  },[workOrderList]);

  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  // componentDidMount
  // React.useEffect(() => {
  //   dispatch(setSearchText(""));
  //   const search = filterParam(newFilter);
  //   if (!_.isEmpty(search)) { 
  //     dispatch(setValues({filter: {...search, page: 0}}));
  //   } else {
  //     dispatch(setValues({filter: {deliveryStartDate: moment().format("YYYY-MM-DD"), deliveryEndDate: moment().format("YYYY-MM-DD")}}));
  //   }
  // },[]);

  return (
    <React.Fragment>
      <Outcomes newFilter={newFilter} setNewFilter={setNewFilter} />
      <Excess />
      <Unassigned />
    </React.Fragment>
  );
}
