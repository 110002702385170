import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import { renderValue } from "common/functions";
import { CreateOrUpdateRawMaterialReconciliation } from "services/UserPortal/RawMaterialService";
import { GetRawMaterialBatchList } from "services/UserPortal/CommonLookupService";

import { setDirty, setSearchText } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues, updateRequest } from "../store/reconciliation";

import styles from "assets/jss/components/UserPortal/rawMaterial.module.scss";

export default function AddReconciliation() {
  let history = useHistory();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfo);
  const batchList = useSelector(store => store.common.batchList);
  const reconciliation = useSelector(store => store.user.rawMaterial.reconciliationRM.reconciliation);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id]: e.target.value}));
  };

  const handleOnChange_select = (e) => {
    if (e.target.name === "rawMaterialId") {
      dispatch(updateRequest({[e.target.name]: e.target.value, rawMaterialBatchId: null}));
    } else {
      dispatch(updateRequest({[e.target.name]: e.target.value}));
    }
  };

  const handleButtonClick_cancel = () => {
    dispatch(setValues({reconciliation: {}}));
    history.push("/user/raw-material/reconciliation");
  };


  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateRawMaterialReconciliation(reconciliation))
        .then((response) => {
          if (response.error) {
            dispatch(setDirty(true));
          } else {
            history.push("/user/raw-material/reconciliation");
          }
        });
      });
    }
  };

  const validateFields = () => {
    if (!reconciliation.rawMaterialId) {
      setErrorMsg({field: "rawMaterialId", msg: "Please select a raw material"});
      return false;
    }
    if (!reconciliation.rawMaterialBatchId) {
      setErrorMsg({field: "rawMaterialBatchId", msg: "Please select a batch ref no."});
      return false;
    }
    if (!reconciliation.adjustment) {
      setErrorMsg({field: "adjustment", msg: "Please enter quantity"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  React.useEffect(() => {
    if (reconciliation.rawMaterialId) {
      dispatch(GetRawMaterialBatchList(reconciliation.rawMaterialId));
    }
  },[reconciliation.rawMaterialId]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setAddNewButton(true));
    dispatch(setDirty(true));
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(setValues({reconciliation: {}}));
      dispatch(resetNav());
    }
  },[]);

  const selectedRawMaterial = _.find(rawMaterialList, ({id}) => id === reconciliation.rawMaterialId);
  return (
    <React.Fragment>
      <Card 
        title="Reconciliation Notes"
        subheader="Key in details below for the reconciliation notes"
      >
        <Paper className={styles.paper} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={styles.label}>
              <Typography>RM Name <span className={styles.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="rawMaterialId"
                onChange={(e)=>handleOnChange_select(e)}
                placeholder="Select a raw material"
                value={renderValue(reconciliation.rawMaterialId)}
                errorMsg={errorMsg}
              >
                {rawMaterialList && rawMaterialList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.rawMaterialName}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Batch Number <span className={styles.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="rawMaterialBatchId"
                onChange={(e)=>handleOnChange_select(e)}
                placeholder="Select a batch number"
                value={renderValue(reconciliation.rawMaterialBatchId)}
                errorMsg={errorMsg}
                disabled={!reconciliation.rawMaterialId}
              >
                {batchList && batchList.map((item, index) => {
                  return <MenuItem key={index} value={item.rawMaterialBatchId}>{item.batchRefNo}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Quantity Adjusted (+/-) <span className={styles.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField 
                id="adjustment"
                variant="outlined"
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Eg. 100"
                type="Number"
                value={renderValue(reconciliation.adjustment)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="uom"
                variant="outlined" 
                value={selectedRawMaterial ? selectedRawMaterial.uom : ""}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Reason:</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="reason"
                variant="outlined" 
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Enter a reason"
                value={renderValue(reconciliation.reason)}
                errorMsg={errorMsg}
              />
            </Grid>
          </Grid>
          <div className={styles.action}>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>handleButtonClick_cancel()}
            >
              Cancel
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
              disabled={!isDirty}
            >
              Save
            </Button>
          </div>
        </Paper>
      </Card>
    </React.Fragment>
  );
}
