import { createSlice } from "@reduxjs/toolkit";

import { GetWorkOrderRevenue } from "services/UserPortal/CostProductivityService";

// initial state
const initialState = {
  filter: {},
  subTotal: 0,
  totalCount: 0,
  harvests: [],
};

const harvest = createSlice({
  name: "harvest",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetWorkOrderRevenue.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.subTotal = action.payload.result.subTotal;
        state.harvests = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, updateRequest, updateExclusionRequest, reset } = harvest.actions;

// export the reducer
export default harvest.reducer;