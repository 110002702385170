import React from "react";
// @mui/material
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// core components
import Modal from "shared-components/Modal/Modal";

import styles from "assets/jss/shared-components/modal.module.scss";

export default function ViewMoreModal({list, title}) {
  const [openModal, setOpenModal] = React.useState(false);

  const checkViewMore = () => {
    const array = list.split(",");
    const result = list.replaceAll(",", " ");
    if (array.length > 3) {
      return (
        <React.Fragment>
          {result.split(" ").slice(0,3).join(", ")}
          <span className={styles.link} onClick={() => handleModal()}> (More)</span>
        </React.Fragment>
      )
    } else {
      return list;
    }
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  }

  return (
    <React.Fragment>
      {checkViewMore()}
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        title={title}
        content = {
          list &&
          <div className={styles.moreList}>
            <List>
              { list.split(",").map((result, index) => {
                return (
                  <ListItem key={index}>{result}</ListItem>
                )
              })}
            </List>
          </div>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() =>  handleModal()}>Close</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
