import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllVehicles = createAsyncThunk(
  "delivery/getAllVehicles", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetAllVehicles", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllDriver = createAsyncThunk(
  "delivery/getAllDriver", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetAllDriver", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeliveryTripLookUp = createAsyncThunk(
  "delivery/getDeliveryTripLookUp", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetDeliveryTripLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeliveryOrderLookUp = createAsyncThunk(
  "delivery/getDeliveryOrderLookUp", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetDeliveryOrderLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCustomerList = createAsyncThunk(
  "delivery/getCustomerList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetCustomerList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Assignment
export const GetAllDeliveryOrderForAssignment = createAsyncThunk(
  "delivery/getAllDeliveryOrderForAssignment", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetAllDeliveryOrderForAssignment", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateDeliveryTrip = createAsyncThunk(
  "delivery/createOrUpdateDeliveryTrip", 
  async (_, {getState, rejectWithValue}) => {
    const body = {
      ...getState().user.delivery.assignment.assignment,
      deliveryTripDetails: getState().user.delivery.assignment.deliveryTripDetails
    };
    try {
      const response = await FomsMS.post("/userportal/Delivery/CreateOrUpdateDeliveryTrip", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Master List
export const GetAllDeliveryTrip = createAsyncThunk(
  "delivery/getAllDeliveryTrip", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    params.append("skipCount", (param.page) * rowsPerTable);
    if (!param.maxResultCount) params.append("maxResultCount", rowsPerTable);
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetAllDeliveryTrip", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const PartialUpdateDeliveryTrip = createAsyncThunk(
  "delivery/partialUpdateDeliveryTrip", 
  async (_, {getState, rejectWithValue}) => {
    const body = {
      ...getState().user.delivery.master.trip
    };
    try {
      const response = await FomsMS.post("/userportal/Delivery/PartialUpdateDeliveryTrip", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteTrip = createAsyncThunk(
  "delivery/deleteTrip", 
  async (id, {rejectWithValue}) => {
    const params = { id };
    try {
      const response = await FomsMS.delete("/userportal/Delivery/DeleteTrip", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeliveryTripDetail = createAsyncThunk(
  "delivery/getDeliveryTripDetail", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetDeliveryTripDetail", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeliveryTripLocationByTripId = createAsyncThunk(
  "delivery/getDeliveryTripLocationByTripId", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetDeliveryTripLocationByTripId", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Delivery Order
export const GetAllDeliveryOrder = createAsyncThunk(
  "delivery/getAllDeliveryOrder", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetAllDeliveryOrder", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeliveryOrderById = createAsyncThunk(
  "delivery/getDeliveryOrderById", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetDeliveryOrderById", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateDeliveryOrder = createAsyncThunk(
  "delivery/updateDeliveryOrder", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/Delivery/UpdateDeliveryOrder", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadDeliveryOrderImageList = createAsyncThunk(
  "upload/UploadDeliveryOrderImageList", 
  async (files, {rejectWithValue}) => {
    let body = new FormData();
    files.map((item,index) => body.append("deliveryOrderImageList["+index+"].deliveryOrderImage", item));
    try {
      const response = await FomsMS.post("/Upload/UploadDeliveryOrderImageList", body, {isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Settings
export const GetIntervalTime = createAsyncThunk(
  "delivery/getIntervalTime", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetIntervalTime", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeliveryTripSetting = createAsyncThunk(
  "delivery/getDeliveryTripSetting", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/GetDeliveryTripSetting", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateTripSetting = createAsyncThunk(
  "delivery/createOrUpdateTripSetting", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.delivery.settings.settings;
    try {
      const response = await FomsMS.post("/userportal/Delivery/CreateOrUpdateTripSetting", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchDeliveryAssignmentList = createAsyncThunk(
  "delivery/searchDeliveryAssignmentList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/SearchDeliveryAssignmentList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchDeliveryMasterList = createAsyncThunk(
  "delivery/searchDeliveryMasterList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/SearchDeliveryMasterList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchDeliveryOrder = createAsyncThunk(
  "delivery/SearchDeliveryOrder", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Delivery/SearchDeliveryOrder", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);