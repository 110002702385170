import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import TextField from "shared-components/TextField/TextField";
import Autocomplete from "shared-components/Select/Autocomplete";
import InfiniteAutocomplete from "shared-components/Select/InfiniteAutocomplete";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";
// @mui/material
import Tooltip from "@mui/material/Tooltip";

import { rowsPerTable } from "config";
import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { SaveASRSOnboardingList, GetAllCages, GetAllASRSSoftzone } from "services/UserPortal/ASRSService";
import { uploadedOnboardingTableHead } from "enums/UserPortal/TableHeaders";
import { setValues, updateRequest, reset } from "../store/currentListings";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/asrs.module.scss";

export default function UploadedData() {
  const dispatch = useDispatch();
  const batchResult = useSelector(store => store.user.asrs.currentListings.batchResult);
  const batch = useSelector(store => store.user.asrs.currentListings.batch);
  const cageList = useSelector(store => store.user.asrs.currentListings.cageList);
  const softzoneList = useSelector(store => store.user.asrs.currentListings.softzoneList);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('serialNo');
  const [page, setPage] = React.useState(0);
  const [editIndex, setEditIndex] = React.useState(false);
  const [errorMode, setErrorMode] = React.useState(null);
  const [errorFields, setErrorFields] = React.useState([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [softzonePage, setSoftzonePage] = React.useState(0); // for softzone list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for softzone list
  const [searchValue, setSearchValue] = React.useState(""); // for softzone list

  const count = Math.ceil(batchResult.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_select = (target, newValue) => {
    const item = _.find(cageList, ({cageRefNo}) => cageRefNo === newValue);
    dispatch(updateRequest({[target] : newValue, cageId: item.id}));
  };

  const handleOnChange_autocomplete = (target, newValue) => {
    dispatch(updateRequest({[target] : newValue, softzoneId: newValue.softzoneID}));
  };

  const fetchMoreData = (search, pageNo) => {
    if (softzonePage !== -1 || pageNo !== undefined) {
      setIsLoadingMore(true);
      const param = {
        page: pageNo !== undefined ? pageNo : softzonePage,
        softzoneName: search ? search : ""
      }
      dispatch(GetAllASRSSoftzone(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result) {
            setSoftzonePage(pageNo !== undefined ? pageNo+1 : softzonePage+1);
            if (search || softzonePage === 0 || pageNo === 0) {
              dispatch(setValues({softzoneList: response.payload.result.items}));
            } else {
              dispatch(setValues({softzoneList: _.unionBy(softzoneList, response.payload.result.items, "softzoneID")}));
            }
          } else {
            setSoftzonePage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleButtonClick_edit = (item, index) => {
    setEditIndex(index);
    dispatch(setValues({batch: item}));
  }

  const handleModal_delete = (id) => {
    setEditIndex(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleButtonClick_delete = () => {
    let payload = _.cloneDeep(batchResult);
    let index = (page*rowsPerTable)+editIndex;
    payload.splice(index);
    dispatch(setValues({batchResult: payload}));
    setOpenDeleteModal(!openDeleteModal);
    resetState();
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      let payload = _.cloneDeep(batchResult);
      let index = (page*rowsPerTable)+editIndex;
      payload[index] = batch;
      dispatch(setValues({batchResult: payload}));
      resetState();
    }
  }

  const handleButtonClick_save = () => {
    if (validateMissingFields().length > 0) {
      handleModal_warning();
    } else if (validateInvalidFields().length > 0) {
      handleModal_warning();
    } else {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(SaveASRSOnboardingList())
        .then((response)=>{
          if (response.error && response.payload.message !== "An internal error occurred during your request!") {
            dispatch(setDirty(true));
            handleModal_warning();
            setErrorFields([response.payload.message]);
            setErrorMode("Invalid");
          }
        });
      })
    }
  }

  const validateMissingFields = () => {
    let errors = [];
    for (let i = 0; i < batchResult.length; i++) {
      if (!batchResult[i].cageRefNo) {
        errors = _.union(errors, ["Cage ID"]);
      }
      if (!batchResult[i].softzoneId) {
        errors = _.union(errors, ["Softzone ID"]);
      }
    }
    setErrorFields(errors);
    setErrorMode("Missing");
    return errors;
  }

  const validateInvalidFields = () => {
    let errors = [];
    setErrorFields(errors);
    setErrorMode("Invalid");
    return errors;
  }

  const validateFields = () => {
    if (!batch.cageRefNo) {
      setErrorMsg({field: "cageRefNo", msg: "Please select a cage"});
      return false;
    }
    if (batchResult.find(({cageId}) => cageId === batch.cageId)) {
      setErrorMsg({field: "cageRefNo", msg: "Cage already used in uploaded list"});
      return false;
    }
    if (!batch.softzoneId) {
      setErrorMsg({field: "softzoneId", msg: "Please enter a softzone id"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setEditIndex(false);
    setPage(0);
    dispatch(setValues({batch: {}}));
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetAllCages());
    dispatch(GetAllASRSSoftzone({page: 0}))
    .then((response) => {
      if (response.payload.result) {
        setSoftzonePage(softzonePage+1);
        dispatch(setValues({softzoneList: response.payload.result.items}))
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  }, []);

  const renderAlert = (item) => {
    if (!item.cageRefNo) {
      return (
        <Tooltip title="No such cage" placement="top">
          <img className={styles.tableIcon} src={alert} alt="alert" />
        </Tooltip>
      );
    } else if (item.isDuplicateCage) {
      return (
        <Tooltip title="Duplicate cage" placement="top">
          <img className={styles.tableIcon} src={alert} alt="alert" />
        </Tooltip>
      );
    } else if (item.isMaintenanceCage) {
      return (
        <Tooltip title="Maintenance cage" placement="top">
          <img className={styles.tableIcon} src={alert} alt="alert" />
        </Tooltip>
      );
    } else if (item.isWrongSoftzone) {
      return (
        <Tooltip title="Wrong softzone" placement="top">
          <img className={styles.tableIcon} src={alert} alt="alert" />
        </Tooltip>
      );
    }
    return null;
  }

  return (
    <React.Fragment>
      <Card 
        className={styles.cardRadius}
        title = "Uploaded Data"
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={() => location.reload()}
              disabled={editIndex != false}
            >
              Back
            </Button>
            <Button
              className={styles.button}
              onClick={() => handleButtonClick_save()}
              disabled={editIndex != false}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Table
          className={styles.batchTable}
          header={uploadedOnboardingTableHead}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        >
          { batchResult
          .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
          .map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell className={styles.snCell}>{String((page*rowsPerTable)+1+index).padStart(2,'0')}</TableCell>
                <TableCell>
                  { editIndex === index
                  ? <Autocomplete
                      id="cageRefNo"
                      inputProps={{ maxLength: 50 }}
                      value={batch.cageRefNo}
                      onChange={(e, newValue) => {
                        handleOnChange_select('cageRefNo', newValue);
                      }}
                      options={cageList.map((listItem) => listItem.cageRefNo)}
                      placeholder="Select a cage"
                      errorMsg={errorMsg}
                    />
                  : item.cageRefNo
                  }
                </TableCell>
                <TableCell>
                  { editIndex === index
                  ? <InfiniteAutocomplete
                      dataCount={softzonePage*10}
                      options={softzoneList}
                      fetchData={()=>fetchMoreData()}
                      renderOption={(option) => option.softzoneName}
                      isLoadingMore={isLoadingMore}
                      placeholder="Select a softzone"
                      onInputChange={(e, newInputValue) => {
                        // if (e && e._reactName == "onBlur") {
                        //   setPage(0);
                        //   setSearchValue(newInputValue);
                        //   fetchMoreData(newInputValue, 0);
                        // } 
                        if (e && e._reactName == "onClick") {
                          setSoftzonePage(0);
                          setSearchValue(newInputValue);
                          fetchMoreData(undefined, 0);
                        }
                        if (e && e._reactName == "onChange") {
                          setSoftzonePage(0);
                          setSearchValue(newInputValue);
                          fetchMoreData(newInputValue, 0);
                        }
                      }}
                      onChange={(_, newValue) => {
                        handleOnChange_autocomplete("softzoneName", newValue);
                      }}
                      inputValue={searchValue}
                      value={searchValue!==batch.softzoneName ? null : batch.softzoneName}
                      renderValue={"softzoneName"}
                    />
                  : item.softzoneId
                  }
                </TableCell>
                <TableCell>
                  { editIndex === index
                  ? <TextField 
                      id="amelechID"
                      variant="outlined" 
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={renderValue(batch.amelechID)}
                      errorMsg={errorMsg}
                    />
                  : item.amelechID
                  }
                </TableCell>
                <TableCell>
                  { editIndex === index
                  ? <TextField 
                      id="aranetID1"
                      variant="outlined" 
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={renderValue(batch.aranetID1)}
                      errorMsg={errorMsg}
                    />
                  : item.aranetID1
                  }
                </TableCell>
                <TableCell>
                  { editIndex === index
                  ? <TextField 
                      id="aranetID2"
                      variant="outlined" 
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={renderValue(batch.aranetID2)}
                      errorMsg={errorMsg}
                    />
                  : item.aranetID2
                  }
                </TableCell>
                <TableCell>
                  { editIndex === index
                  ? <TextField 
                      id="aranetID3"
                      variant="outlined" 
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={renderValue(batch.aranetID3)}
                      errorMsg={errorMsg}
                    />
                  : item.aranetID3
                  }
                </TableCell>
                <TableCell>
                  { editIndex === index
                  ? <TextField 
                      id="noOfTray"
                      variant="outlined" 
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={renderValue(batch.noOfTray)}
                      errorMsg={errorMsg}
                    />
                  : item.noOfTray
                  }
                </TableCell>
                <TableCell>
                  { editIndex === index
                  ? <TextField 
                      id="manufacturerSerialNumber"
                      variant="outlined" 
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={renderValue(batch.manufacturerSerialNumber)}
                      errorMsg={errorMsg}
                    />
                  : item.manufacturerSerialNumber
                  }
                </TableCell>
                <TableCell>
                  { editIndex === index
                  ? <TextField 
                      id="lightSerialNo"
                      variant="outlined" 
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={renderValue(batch.lightSerialNo)}
                      errorMsg={errorMsg}
                    />
                  : item.lightSerialNo
                  }
                </TableCell>
                <TableCell align="right">
                  {renderAlert(item)}
                </TableCell>
                <TableCell align="right">
                  { editIndex === index
                  ? <React.Fragment>
                      <IconButton
                        type="confirm" 
                        onClick={() => handleButtonClick_confirm()}
                      />
                      <IconButton 
                        type="close"
                        onClick={() => resetState()}
                      />
                    </React.Fragment>
                  : <React.Fragment>
                      <IconButton
                        type="edit" 
                        onClick={() => handleButtonClick_edit(item, index)}
                        disabled={(editIndex !== false && editIndex !== index)}
                      />
                      <IconButton
                        type="delete" 
                        onClick={() => handleModal_delete(item, index)}
                        disabled={(editIndex !== false && editIndex !== index)}
                      />
                    </React.Fragment>
                  }
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this line? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning()}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title={errorMode + " Fields"}
        content={
          <React.Fragment>
            <Typography>Please check these fields again:</Typography>
            <Typography className={styles.errorList}>{errorFields.join(", ")}</Typography>
          </React.Fragment>
        }
        actions={
          <Button className={styles.button} onClick={() => handleModal_warning()}>OK</Button>
        }
      />
    </React.Fragment>
  );
}
