// Product Category (Create Edit)
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import Checkbox from "shared-components/Checkbox/Checkbox";
import TextField from "shared-components/TextField/TextField";
import Pagination from "shared-components/Table/Pagination";
import IconButton from "shared-components/Button/IconButton";

import { rowsPerTable } from "config";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { checkPermission } from "common/functions";
import * as validate from "common/validations";
import { configureProductCategoryTableHead } from "enums/AdminPortal/TableHeaders";
import { setDirty, setLoading } from "store/general";
import { GetAllProductCategory, DeleteProductCategory, CreateOrUpdateProductCategory, GetProductCategoryByID } from "services/AdminPortal/ProductService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/product.module.scss";

export default function ProductCategorySetting(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const allProductCategory = useSelector(store => store.admin.product.lookup.allProductCategory);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productCategoryName');
  const [page, setPage] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [addEditMode, setAddEditMode] = React.useState(false);
  const [productCategoryName, setProductCategoryName] = React.useState("");
  const [productCategoryShortForm, setProductCategoryShortForm] = React.useState("");
  const [combinationProduct, setCombinationProduct] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(allProductCategory.length / rowsPerTable);

  const tableHead = () => {
    if (checkPermission("Admin.Products (Mix Product)", "none")) {
      return _.filter(configureProductCategoryTableHead, ({id}) => {
        return id !== "combinationProduct";
      });
    }
    return configureProductCategoryTableHead;
  }

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleButtonClick_edit = (name, shortform, isCombi, id) => {
    dispatch(setDirty(true));
    setProductCategoryName(name);
    setProductCategoryShortForm(shortform);
    setCombinationProduct(isCombi);
    setId(id);
    setAddEditMode("Edit");
  }

  const handleButtonClick_add = () => {
    setAddEditMode("Add");
    dispatch(setDirty(true));
  }

  const handleButtonClick_goBack = () => {
    resetState();
    const prop = props.location && props.location.state;
    if (props.setProductCategorySetting) {
      props.setProductCategorySetting(false);
    } else {
      history.push((prop && prop.prevPath) || "/admin/products");
    }
  }

  const handleButtonClick_cancel = () => {
    resetState();
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      let body = {
        productCategoryName: productCategoryName,
        combinationProduct: combinationProduct,
        productCategoryShortForm: productCategoryShortForm,
        id: id
      };
      dispatch(CreateOrUpdateProductCategory(body))
      .then((response)=>{
        if (response.error) {
          dispatch(setLoading(false));
          if (response.payload.message.includes('Short Form')) {
            setErrorMsg({field: "shortform", msg: response.payload.message});  //validation for existing product shortform
          } else {
            setErrorMsg({field: "category", msg: response.payload.message});  //validation for existing product shortform
          }
        } else {
          resetState();
        }
      })
    }
  }

  const handleModal = (id) => {
    setId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    Promise.all([
      dispatch(DeleteProductCategory(id))
    ])
    .then(() => {
      setOpenModal(!openModal);
      dispatch(GetAllProductCategory());
    })
  }

  const validateFields = () => {
    if (_.isEmpty(productCategoryName)) {
      setErrorMsg({field: "category", msg: "Please add in a Product Category"});
      return false;
    }
    if (_.isEmpty(productCategoryShortForm)) {
      setErrorMsg({field: "shortform", msg: "Please add in a Shortform"});
      return false;
    }
    if (!validate.isShortform(productCategoryShortForm)) {
      setErrorMsg({field: "shortform", msg: "Shortform should contain at most 3 upper case characters"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const resetState = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      const prop = props.location && props.location.state;  // from search
      if (prop && prop.prevPath.includes("search")) {
        history.push((prop && prop.prevPath) || "/admin/products");
      } else {
        setAddEditMode(false);
        setId(0);
      }
      setProductCategoryName("");
      setProductCategoryShortForm("");
      setCombinationProduct(false);
      setErrorMsg(false);
    });
  }

  React.useEffect(() => {
    dispatch(GetAllProductCategory());
  },[addEditMode]);

  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    const prop = props.location && props.location.state;
    if (prop && prop.prevPath.includes("search")) {  // from search
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "category");
      dispatch(GetProductCategoryByID(path[index+1]))
      .then((response) => {
        if (response.error) {
          history.push((prop && prop.prevPath) || "/admin/products");
        } else {
          const result = response.payload.result;
          handleButtonClick_edit(result.productCategoryName, result.productCategoryShortForm, result.combinationProduct, result.id);
        }
      });
    }
    // componentDidUnmount
    return () => {
      if (prop) {
        dispatch(resetNav());
      }
    }
  },[]);

  if (addEditMode) {
    return (
      <Card 
        title = "Add New Product Category"
        cardActions={
          <React.Fragment>
            <Button disabled/>
            <div>
              <Button
                className={clsx(styles.buttonSecondary, styles.buttonMargin)}
                onClick={() => handleButtonClick_cancel()}
              >
                Cancel
              </Button>
              <Button
                className={styles.button}
                onClick={() => handleButtonClick_save()}
              >
                Save
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              id="category" 
              label={<React.Fragment>{(addEditMode === "Add")? "New" : ""} Product Category <span className={styles.orange}>*</span></React.Fragment>}
              variant="outlined" 
              placeholder="Enter Product Category"
              onChange={(e) => setProductCategoryName(e.target.value)}
              value= {productCategoryName}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField 
              id="shortform"
              label={<React.Fragment>{(addEditMode === "Add")? "New" : ""} Shortform <span className={styles.orange}>*</span></React.Fragment>}
              variant="outlined" 
              placeholder="Enter Product ShortForm"
              onChange={(e) => setProductCategoryShortForm(e.target.value.toUpperCase())}
              inputProps={{ maxLength: 3 }}
              value= {productCategoryShortForm}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={styles.label}>Combination Product</Typography>
            <Checkbox className={styles.checkbox}
              id="importRawMaterials"
              checked={combinationProduct}
              disabled={addEditMode === "Edit"}
              onChange={() => setCombinationProduct(!combinationProduct)}
            />
          </Grid>
        </Grid>
      </Card>
    )
  } else {
    return (
      <React.Fragment>
        <Card 
          title = "Product Category"
          cardActions={
            <React.Fragment>
              <div>
                <div>
                  <Button
                    className={styles.buttonSecondary}
                    onClick={() => handleButtonClick_add()}
                  >
                    + Add New Product Category
                  </Button>
                </div>
                <Button
                  className={clsx(styles.buttonSecondary, styles.goBackButton)}
                  onClick={() => handleButtonClick_goBack()}
                >
                  Go Back
                </Button>
              </div>
            </React.Fragment>
          }
        >
          <Table
            className={styles.table}
            header={tableHead()}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(allProductCategory, [orderBy], [order])
            .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
            .map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.productCategoryName}</TableCell>
                  <TableCell>{item.productCategoryShortForm}</TableCell>
                  {!checkPermission("Admin.Products (Mix Product)", "none") &&
                    <TableCell align="center">
                      <Checkbox 
                        checked={item.combinationProduct}
                        disabled
                      />
                    </TableCell>
                  }
                  <TableCell align="right">
                    <React.Fragment>
                      <IconButton 
                        type="edit"
                        onClick={() => handleButtonClick_edit(item.productCategoryName, item.productCategoryShortForm, item.combinationProduct, item.id)}
                      />
                      <IconButton 
                        type="delete"
                        onClick={() => handleModal(item.id)}
                      />
                    </React.Fragment>
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
        </Card>
        <Modal
          open={openModal}
          onClose={() => handleModal(null)}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this product category? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal(0)}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
