import { combineReducers } from "redux";
import currentListings from "./currentListings";
import maintenance from "./maintenance";
import movement from "./movement";
import softzone from "./softzone";
import station from "./station";
import settings from "./settings";

const ASRSReducer = combineReducers({
  currentListings,
  maintenance,
  movement,
  softzone,
  station,
  settings,
});

export default ASRSReducer;