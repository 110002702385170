import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash"

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

// Lookup
export const GetProductList  = createAsyncThunk(
  "commonLookup/getProductList ", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetProductList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductListByWorkOrderId = createAsyncThunk(
	"QualityControl/GetProductListByWorkOrderId", 
	async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
		try {
			const response = await FomsMS.get("/userportal/QualityControl/GetProductListByWorkOrderId", {params, stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const GetTrayPosition = createAsyncThunk(
	"QualityControl/GetTrayPosition", 
	async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
		try {
			const response = await FomsMS.get("/userportal/QualityControl/GetTrayPosition", {params, stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const GetBoardType = createAsyncThunk(
  "QualityControl/GetBoardType", 
  async (_, {rejectWithValue}) => {
		try {
			const response = await FomsMS.get("/userportal/QualityControl/GetBoardType", {stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const GetNutrientCategory = createAsyncThunk(
  "QualityControl/GetNutrientCategory", 
	async (_, {rejectWithValue}) => {
		try {
			const response = await FomsMS.get("/userportal/QualityControl/GetNutrientCategory", {stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const GetBatchIDList = createAsyncThunk(
  "QualityControl/GetBatchIDList", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetBatchIDList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Form
export const CalculateNurseryDuration = createAsyncThunk(
  "QualityControl/CalculateNurseryDuration", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateNurseryDuration", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateGrowthDuration = createAsyncThunk(
  "QualityControl/CalculateGrowthDuration", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateGrowthDuration", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateTotalNetWeight = createAsyncThunk(
  "QualityControl/CalculateTotalNetWeight", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateTotalNetWeight", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateAverageNetWeightPerPlant = createAsyncThunk(
  "QualityControl/CalculateAverageNetWeightPerPlant", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateAverageNetWeightPerPlant", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateNutrientsVolPerPlant = createAsyncThunk(
  "QualityControl/CalculateNutrientsVolPerPlant", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateNutrientsVolPerPlant", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateActualNutrientsVolPerPlant = createAsyncThunk(
  "QualityControl/CalculateActualNutrientsVolPerPlant", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateActualNutrientsVolPerPlant", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateActualNutrientsVolPerTray = createAsyncThunk(
  "QualityControl/CalculateActualNutrientsVolPerTray", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateActualNutrientsVolPerTray", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateNetWeightUnderHealthCondition = createAsyncThunk(
  "QualityControl/CalculateNetWeightUnderHealthCondition", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateNetWeightUnderHealthCondition", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadQualityControlImageList = createAsyncThunk(
  "upload/UploadQualityControlImageList", 
  async (files, {rejectWithValue}) => {
    let body = new FormData();
    files.map((item,index) => {
      body.append("qualityControlImageList["+index+"].qualityControlImage", item.imageUrl ? item.imageUrl : item);
      body.append("qualityControlImageList["+index+"].imageLocation", item.imageLocation);
    });
    try {
      const response = await FomsMS.post("/Upload/UploadQualityControlImageList", body, {isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadQualityControlImage = createAsyncThunk(
  "upload/uploadQualityControlImage", 
  async (file, {rejectWithValue}) => {
    let body = new FormData();
    body.append("qualityControlImage", file);
    try {
      const response = await FomsMS.post("/Upload/UploadQualityControlImage", body, {isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductInfoById = createAsyncThunk(
  "QualityControl/GetProductInfoById", 
  async (id, {rejectWithValue}) => {
    const params = {productId: id};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetProductInfoById", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateQualityControl = createAsyncThunk(
  "QualityControl/CreateOrUpdateQualityControl", 
  async (qc, {rejectWithValue}) => {
    const body = qc;
    try {
      const response = await FomsMS.post("/userportal/QualityControl/CreateOrUpdateQualityControl", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetQCDetailsFromOperation = createAsyncThunk(
  "QualityControl/GetQCDetailsFromOperation", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetQCDetailsFromOperation", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Vegetable
export const GetGerminationDetailByWorkOrderIdAndProductId = createAsyncThunk(
	"QualityControl/GetGerminationDetailByWorkOrderIdAndProductId", 
	async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
		try {
			const response = await FomsMS.get("/userportal/QualityControl/GetGerminationDetailByWorkOrderIdAndProductId", {params, stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const GetAllQualityControlEntries = createAsyncThunk(
  "QualityControl/GetAllQualityControlEntries", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetAllQualityControlEntries", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetQualityControlDetail = createAsyncThunk(
  "QualityControl/GetQualityControlDetail", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetQualityControlDetail", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GeneratePlantMatrixFormat = createAsyncThunk(
  "QualityControl/GeneratePlantMatrixFormat", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GeneratePlantMatrixFormat", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteQualityControlEntries = createAsyncThunk(
  "QualityControl/DeleteQualityControlEntries", 
  async (QualityControlId, {rejectWithValue}) => {
    const params = {QualityControlId};
    try {
      const response = await FomsMS.delete("/userportal/QualityControl/DeleteQualityControlEntries", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Germination
export const GetAllGerminationReports = createAsyncThunk(
  "QualityControl/GetAllGerminationReports", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetAllGerminationReports", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetGerminationReportDetail = createAsyncThunk(
  "QualityControl/GetGerminationReportDetail", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetGerminationReportDetail", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteGerminationReport = createAsyncThunk(
  "QualityControl/DeleteGerminationReport", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/userportal/QualityControl/DeleteGerminationReport", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadQCGerminationGoodSeedlingImage = createAsyncThunk(
  "upload/UploadQCGerminationGoodSeedlingImage", 
  async (file, {rejectWithValue}) => {
    let body = new FormData();
    body.append("qualityControlImage", file)
    try {
      const response = await FomsMS.post("/Upload/UploadQCGerminationGoodSeedlingImage", body, {isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateGerminationReport = createAsyncThunk(
  "QualityControl/CreateOrUpdateGerminationReport", 
  async (qc, {rejectWithValue}) => {
    const body = qc;
    try {
      const response = await FomsMS.post("/userportal/QualityControl/CreateOrUpdateGerminationReport", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetBatchStartDateByBatchID = createAsyncThunk(
  "QualityControl/GetBatchStartDateByBatchID", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetBatchStartDateByBatchID", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateGerminationRate = createAsyncThunk(
  "QualityControl/CalculateGerminationRate", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateGerminationRate", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateDatesByWorkOrderIdAndProductId = createAsyncThunk(
  "QualityControl/CalculateDatesByWorkOrderIdAndProductId", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/CalculateDatesByWorkOrderIdAndProductId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSeedSupplierNameByBatchID = createAsyncThunk(
  "QualityControl/GetSeedSupplierNameByBatchID", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetSeedSupplierNameByBatchID", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Phenotype
export const GetPlantPhenotypeReport = createAsyncThunk(
  "QualityControl/GetPlantPhenotypeReport", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetPlantPhenotypeReport", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GeneratePlantPhenotypeMatrixFormat = createAsyncThunk(
  "QualityControl/GeneratePlantPhenotypeMatrixFormat", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GeneratePlantPhenotypeMatrixFormat", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdatePhenotypeQualityControl = createAsyncThunk(
  "QualityControl/CreateOrUpdatePhenotypeQualityControl", 
  async (qc, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      farmId: find(userDetail.farms, "isActive").id, 
      ...qc
    };
    try {
      const response = await FomsMS.post("/userportal/QualityControl/CreateOrUpdatePlantPhenotypeReport", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Search
export const SearchGerminationReport = createAsyncThunk(
  "QualityControl/searchGerminationReport", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/QualityControl/SearchGerminationReport", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchVegetableReport = createAsyncThunk(
  "QualityControl/searchVegetableReport", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/QualityControl/SearchGerminationReport", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Tracing
export const TracingQCGrowthReport = createAsyncThunk(
  "QualityControl/TracingQCGrowthReport", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/QualityControl/TracingQCGrowthReport", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// R&D
export const GetAllRandDReports = createAsyncThunk(
  "QualityControl/GetAllRandDReports", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetAllRandDReports", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRandDReportDetail = createAsyncThunk(
  "QualityControl/GetRandDReportDetail", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/QualityControl/GetRandDReportDetail", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRandDReport = createAsyncThunk(
  "QualityControl/CreateOrUpdateRandDReport", 
  async (qc, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      farmId: find(userDetail.farms, "isActive").id, 
      ...qc
    };
    try {
      const response = await FomsMS.post("/userportal/QualityControl/CreateOrUpdateRandDReport", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);