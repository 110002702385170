import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import CustomIconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";
import DateTimePicker from "shared-components/DatePicker/DateTimePicker";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { rowsPerTable, minDate } from "config";
import { useInterval } from 'common/utils';
import { renderValue, filterUrl } from 'common/functions';
import { setSearchText, setDirty } from "store/general";
import { setValues, updateRequest } from "../store/master";
import { deliveryTableHead, deliveryDetailsTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllDriver, GetAllDeliveryTrip, DeleteTrip, PartialUpdateDeliveryTrip } from "services/UserPortal/DeliveryService";

import { useRouteCanWrite } from "hooks";
import FilterModal from "./FilterModal";
import filterIcon from "assets/icons/orange/filter.svg";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/delivery.module.scss";

export default function MasterList() {
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const driverList = useSelector(store => store.user.delivery.master.driverList);
  const totalCount = useSelector(store => store.user.delivery.master.totalCount);
  const results = useSelector(store => store.user.delivery.master.results);
  const trip = useSelector(store => store.user.delivery.master.trip);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('deliveryTripRefNo');
  const [subPage, setSubPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const [filter, setFilter] = React.useState({driverId: [], vehicleId: [], tripStatus: [], deliveryTripId: [], deliveryOrderId: [], deliveryStartDate: null, deliveryEndDate: null, page: 0});
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if (value) {
      setFilter({...filter, deliveryStartDate: moment(value.startDate).format("YYYY-MM-DD"), deliveryEndDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0});
    }
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  }

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleOnChange_time = (value) => {
    let payload = _.cloneDeep(trip);
    if(value && value.isValid() && value != "Invalid Date") {
      payload.time = value;
    } else {
      payload.time = value;
    }
    payload.deliveryTime = moment(value).format("HH:mm");
    dispatch(setValues({trip: payload}));
  };
  
  const handleButtonClick_edit = (id) => {
    dispatch(setDirty(true));
    setEditId(id);
    const value = _.pick(_.find(results, (item) => item.id === id), ["farmId", "id", "driverId", "deliveryTime"]);
    const time = value.deliveryTime.split(":");
    value.time = moment().set({'hour': time[0], 'minute': time[1]});
    dispatch(setValues({trip: value}));
  }

  const handleModal_delete = (id) => {
    setOpenDeleteModal(id);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteTrip(openDeleteModal))
    .then(() =>{
      setFilter({...filter, page: 0});
      getAllDeliveryTrips();
      setOpenDeleteModal(false);
    })
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(PartialUpdateDeliveryTrip())
      .then(({error}) =>{
        if (!error) {
          getAllDeliveryTrips();
          dispatch(setValues({trip: {}}));
          setEditId(null);
        }
      })
    }
  }

  const handleButtonClick_cancel = () => {
    dispatch(setValues({trip: {}}));
    setEditId(null);
    dispatch(setDirty(false));
  }
  
  const getAllDeliveryTrips = (stopLoading) => {
    dispatch(GetAllDeliveryTrip({...filter, stopLoading}));
  }

  const validateFields = () => {
    if (!trip.deliveryTime) {
      setErrorMsg({field: "deliveryTime", msg: "Please select a delivery time"});
      return false;
    }
    if (!moment(trip.time).isValid()) {
      setErrorMsg({field: "deliveryTime", msg: "Invalid time"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  React.useEffect(() => {
    getAllDeliveryTrips();
    window.history.pushState({}, '', filterUrl({...filter}));
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllDriver());
  },[]);

  useInterval(() => {
    getAllDeliveryTrips(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="Master List"
        action={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={() => handleModal()}
              endIcon={<img className={styles.icon} src={filterIcon} alt="filter" />}
              disabled={Boolean(editId)}
            >
              Filter
            </Button>
            <DateRangePicker
              clearable
              placeholder="Delivery date"
              className={styles.cardActionDate}
              value={{startDate: filter.deliveryStartDate, endDate: filter.deliveryEndDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>setFilter({...filter, deliveryStartDate: null, deliveryEndDate: null, page: 0})}
              disabled={Boolean(editId)}
            />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button disabled />
            <Button
              className={styles.button}
              onClick={() => history.push(location.pathname+"/map")}
              disabled={Boolean(editId)}
            >
              Map View
            </Button>
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            expandable
            className={styles.detailTable}
            header={deliveryTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { results.map((item,index) => {
              const subCount = Math.ceil(item.deliveryTripDetails.length / rowsPerTable);
              return (
                <React.Fragment key={index}>
                  <TableRow
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => open === index ? setOpen(false) : setOpen(index)}
                  >
                    <TableCell>
                      <IconButton 
                        className={open === index ? styles.rotate : null}
                        onClick={() => open === index? setOpen(false) : setOpen(index)}
                      >
                        <img className={styles.icon} src={arrow} alt="arrow" />
                      </IconButton>
                    </TableCell>
                    <TableCell>{item.deliveryTripRefNo}</TableCell>
                    <TableCell>{moment(item.deliveryDate).format("DD/MM/YYYY")}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>
                      { canWrite && editId === item.id
                      ? <Select
                          name="driverId"
                          onChange={(e)=>handleOnChange_select(e)}
                          value={renderValue(trip.driverId)}
                          placeholder="Select a driver"
                        >
                          {driverList && driverList.map((item, index) => {
                            return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                          })} 
                        </Select>
                      : item.driverName
                      }
                    </TableCell>
                    <TableCell>
                      { canWrite && editId === item.id
                      ? <DateTimePicker
                          type="time"
                          name="time"
                          value={trip.time}
                          onChange={(e) => handleOnChange_time(e)}
                          errorMsg={errorMsg}
                        />
                      : item.deliveryTime
                      }
                    </TableCell>
                    <TableCell>{moment(item.tripStartTime).isAfter(minDate) && moment(item.tripStartTime).format("HH:mm")}</TableCell>
                    <TableCell>{item.estimatedDuration ? item.estimatedDuration + " mins" : ""}</TableCell>
                    <TableCell>{item.actualDuration ? item.actualDuration + " mins" : ""}</TableCell>
                    <TableCell>
                      {editId
                      ?"Link"
                      :<Link to={{pathname: location.pathname+"/"+item.id}} className={styles.link}>Link</Link>
                      }
                    </TableCell>
                    <TableCell>
                      {item.status === "Pending"
                      ? canWrite && editId === item.id
                        ? <React.Fragment>
                            <CustomIconButton 
                              type="confirm"
                              onClick={() => handleButtonClick_confirm()}
                            />
                            <CustomIconButton 
                              type="close"
                              onClick={() => handleButtonClick_cancel()}
                            />
                          </React.Fragment>
                        : <React.Fragment>
                            <CustomIconButton 
                              type="edit" 
                              disabled={Boolean(editId && editId !== item.id)} 
                              onClick={()=>handleButtonClick_edit(item.id)} 
                            />
                            <CustomIconButton 
                              type="delete"
                              onClick={() => handleModal_delete(item.id)}
                              disabled={Boolean(editId && editId !== item.id)}
                            />
                          </React.Fragment>
                      : null
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    { open===index && 
                      <TableCell className="pt-0 pb-0" colSpan={11}>
                        <Collapse in={open === index}>
                          <Table
                            className={styles.subTable}
                            header={deliveryDetailsTableHead}
                          >
                            { item.deliveryTripDetails.slice(subPage * rowsPerTable, subPage * rowsPerTable + rowsPerTable)
                            .map((subItem, subIndex) => {
                              if (subItem.deliveryOrder) {
                                const detail = subItem.deliveryOrder;
                                return (
                                  <React.Fragment key={subIndex}>
                                    <TableRow>
                                      <TableCell>{detail.deliveryOrderRefNo}</TableCell>
                                      <TableCell>{detail.workOrderNumber}</TableCell>
                                      <TableCell>{detail.saleOrderNumber}</TableCell>
                                      <TableCell>{detail.customerName}</TableCell>
                                      <TableCell>{detail.deliveryAddress}</TableCell>
                                      <TableCell>{detail.deliveryOrderStatus ? detail.deliveryOrderStatus.status : "Pending"}</TableCell>
                                      <TableCell>{moment(detail.signedDateTime).isAfter(minDate) && moment(detail.signedDateTime).format("DD/MM/YYYY")}</TableCell>
                                      <TableCell>{detail.remarks}</TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                )
                              }
                            })}
                          </Table>
                          { subCount > 1 &&
                            <Pagination 
                              count={subCount} 
                              page={subPage+1}
                              onChange={(e, pageNo)=>setSubPage(pageNo-1)}
                            />
                          }
                        </Collapse>
                      </TableCell>
                    }
                  </TableRow>
                </React.Fragment>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>setFilter({...filter, page: pageNo-1})}
          />
        }
      </Card>
      <FilterModal open={openModal} onClose={() => handleModal()} filter={filter} setFilter={setFilter} />
      <Modal
        open={Boolean(openDeleteModal)}
        onClose={() => handleModal_delete(false)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this trip? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(false)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
