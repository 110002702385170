// Edit Equipment
// Other Equipments
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @mui/material
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import { isPositive, isValidPrice } from "common/validations";
import { renderValue, roundTo2Decimal } from "common/functions";
import { setDirty } from "store/general";
import { updateRequest } from ".";
import { GetAllUOM, UpdateEquipmentConfig } from "services/AdminPortal/EquipmentService";

import styles from "assets/jss/components/AdminPortal/equipment.module.scss";

export default function ConfigureOthers() {
  let history = useHistory();
  const dispatch = useDispatch();
  const equipment = useSelector(store => store.admin.equipment.equipment);
  const uom = useSelector(store => store.admin.equipment.uom);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(UpdateEquipmentConfig())
        .then(({error}) => {
          if (error) {
            dispatch(setDirty(true));
          } else {
            history.push("/admin/equipment");
          }
        });
      })
    }
  };

  const handleButtonClick_exit = () => {
    history.push("/admin/equipment");
  };

  const validateFields = () => {
    if (!isPositive(equipment.lowestDenominator)) {
      setErrorMsg({field: "lowestDenominator", msg: "Enter a valid lowest denominator"});
      return false;
    } else if (!isPositive(equipment.availableUsagePerDay)) {
      setErrorMsg({field: "availableUsagePerDay", msg: "Enter a valid available usage per day"});
      return false;
    } else if (!isValidPrice(equipment.costPerUnit)) {
      setErrorMsg({field: "costPerUnit", msg: "Enter a valid cost per unit"});
      return false;
    } else if (!isPositive(equipment.accumulatedHoursForMaintenance)) {
      setErrorMsg({field: "accumulatedHoursForMaintenance", msg: "Enter a valid accumulated hours for maintenance"});
      return false;
    } else if (Number(equipment.lowestDenominator) > Number(equipment.availableUsagePerDay)) {
      setErrorMsg({field: "lowestDenominator", msg: "Lowest denominator cannot be more than available usage per day"});
      return false;
    } else {
      setErrorMsg(false);
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllUOM());
  },[]);

  return (
    <Card 
      title="Configure the Machine" 
      subheader={"You are currently configuring " + equipment.equipmentRefNo + "(" + equipment.equipmentName + ")"}
      cardActions={
        <React.Fragment>
          <Button 
            className={styles.buttonSecondary}
            onClick={()=>handleButtonClick_exit()}
          >
            Go Back
          </Button>
          <Button
            className={styles.button}
            onClick={()=>handleButtonClick_save()}
          >
            Save
          </Button>
        </React.Fragment>
      }
    >
      <Paper className={styles.paper} elevation={0}>
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <TextField 
              label="Lowest Denominator"
              id="lowestDenominator"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
              }}
              placeholder="Enter lowest denominator here"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(equipment.lowestDenominator)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="UOM"
              name="lowestUOM"
              value={renderValue(equipment.lowestUOM)}
              placeholder="Select unit of measurement"
              disabled
            >
              {uom.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField 
              label="Available Usage per Day"
              id="availableUsagePerDay"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
              }}
              placeholder="Enter available usage per day here"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(equipment.availableUsagePerDay)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField 
              label="Cost per Unit"
              id="costPerUnit"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                // decimal
                const reg = /^\d*(\.\d{0,2})?$/
                if (!reg.test(e.target.value)) {
                  e.target.value = roundTo2Decimal(e.target.value);
                }
              }}
              placeholder="Enter cost per unit here"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(equipment.costPerUnit)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField 
              label="Accumulated Ops Time for Maintenance"
              id="accumulatedHoursForMaintenance"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,5)
              }}
              placeholder="Enter accumulated hours for maintenance here"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(equipment.accumulatedHoursForMaintenance)}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Paper>
    </Card>
  )
}
