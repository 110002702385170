import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// @mui/material
import Typography from "@mui/material/Typography";
// shared component
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import { rowsPerTable } from "config";
import { homeOperationsTableHead } from "enums/UserPortal/TableHeaders";

import Pagination from "./Pagination";
import styles from "assets/jss/components/UserPortal/home.module.scss";

export default function Operations() {
  const results = useSelector(store => store.user.home.operationWidget);
  const [page, setPage] = React.useState(0);

  return (
    <React.Fragment>
      {results.length
      ? <Table
          className={styles.table}
          headerProps={styles.tableTitle}
          header={homeOperationsTableHead}
        >
          { results
          .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
          .map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.workOrderNumber}</TableCell>
                <TableCell>{item.productList.map(({productName})=>productName).join(", ")}</TableCell>
                <TableCell align="right"><Link to={{pathname: "/user/operations/overall-tasks", search: "?workOrderNo="+item.workOrderNumber}} className={styles.link}>Link</Link></TableCell>
              </TableRow>
            )
          })}
        </Table>
      : <Typography className={styles.tableTitle}>Nothing to show</Typography>
      }
      <div className={styles.widgetAction}>
        { results.length > 5 &&
          <Pagination 
            count={results.length} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </div>
    </React.Fragment>
  );
}
