// Create Edit Device Settings
// Step 1: Device Settings Details
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { updateRequest } from ".";
import { GetIntegrationTypes } from "services/AdminPortal/ManufacturerService";

import styles from "assets/jss/components/AdminPortal/manufacturer.module.scss";

export default function DetailForm(props) {
  const dispatch = useDispatch();
  const manufacturer = useSelector(store => store.admin.manufacturer.manufacturer);
  const integrationTypes = useSelector(store => store.admin.manufacturer.integrationTypes);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleButtonClick_next = () => {
    if (validateFields()) {
      props.setStep(1);
    }
  }

  const validateFields = () => {
    if (_.isEmpty(manufacturer.manufacturerName)) {
      setErrorMsg({field: "manufacturerName", msg: "Please enter manufacturer name"});
      return false;
    }
    if (_.isEmpty(manufacturer.manufacturerType)) {
      setErrorMsg({field: "manufacturerType", msg: "Please enter manufacturer type"});
      return false;
    }
    if (manufacturer.manufacturerPhone && !validate.isPhoneNumber(manufacturer.manufacturerPhone)) {
      setErrorMsg({field: "manufacturerPhone", msg: "Invalid phone number"});
      return false;
    }
    if (manufacturer.manufacturerEmail && !validate.isEmail(manufacturer.manufacturerEmail)) {
      setErrorMsg({field: "manufacturerEmail", msg: "Invalid email address"});
      return false;
    }
    if (!manufacturer.integrationType) {
      setErrorMsg({field: "integrationType", msg: "Please select an integration type"});
      return false;
    }
    if (_.isEmpty(manufacturer.baseUrl)) {
      setErrorMsg({field: "baseUrl", msg: "Please enter base Url"});
      return false;
    }
    else {
      setErrorMsg(false);
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetIntegrationTypes());
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Device Manufacturer Details"
        cardActions={
          <React.Fragment>
            <Button disabled />
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_next()}
            >
              Next Step
            </Button>
          </React.Fragment>
        }
      >
        <Typography className={styles.sectionHeader}>Manufacturer Information</Typography>
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <TextField 
              label={<React.Fragment>Manufacturer Name <span className={styles.orange}>*</span></React.Fragment>}
              id="manufacturerName"
              variant="outlined" 
              inputProps={{ maxLength: 50 }}
              placeholder="Enter manufacturer name"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(manufacturer.manufacturerName)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField 
              label={<React.Fragment>Manufacturer Type <span className={styles.orange}>*</span></React.Fragment>}
              id="manufacturerType"
              variant="outlined" 
              inputProps={{ maxLength: 50 }}
              placeholder="Enter manufacturer type"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(manufacturer.manufacturerType)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField 
              label={<React.Fragment>Manufacturer Phone <span className={styles.orange}>*</span></React.Fragment>}
              id="manufacturerPhone"
              variant="outlined" 
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
              }}
              placeholder="Enter manufacturer phone"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(manufacturer.manufacturerPhone)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField 
              label={<React.Fragment>Manufacturer Email <span className={styles.orange}>*</span></React.Fragment>}
              id="manufacturerEmail"
              variant="outlined" 
              inputProps={{ maxLength: 50 }}
              placeholder="Enter manufacturer email"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(manufacturer.manufacturerEmail)}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
        <Typography className={styles.sectionHeader}>Integration Information</Typography>
        <Grid container spacing={8}>
          <Grid item xs={4}>
            <Select
              label={<React.Fragment>Integration Type <span className={styles.orange}>*</span></React.Fragment>}
              name="integrationType"
              onChange={(e)=>handleOnChange_select(e)}
              value={renderValue(manufacturer.integrationType)}
              placeholder="Select an integration type"
              errorMsg={errorMsg}
            >
              {integrationTypes.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={8}>
            <TextField 
              label={<React.Fragment>Base Url <span className={styles.orange}>*</span></React.Fragment>}
              id="baseUrl"
              variant="outlined" 
              inputProps={{ maxLength: 200 }}
              placeholder="Enter base url"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(manufacturer.baseUrl)}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
        <Typography className={styles.sectionHeader}>Credentials</Typography>
        <Grid container spacing={8}>
          <Grid item xs={4}>
            <TextField 
              label="Username"
              id="credentialUserName"
              variant="outlined" 
              inputProps={{ maxLength: 100 }}
              placeholder="Enter an username"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(manufacturer.credentialUserName)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              label="Password"
              id="credentialPassword"
              variant="outlined" 
              inputProps={{ maxLength: 100 }}
              placeholder="Enter a password"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(manufacturer.credentialPassword)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              label="Token"
              id="credentialToken"
              variant="outlined" 
              inputProps={{ maxLength: 500 }}
              placeholder="Enter a token"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(manufacturer.credentialToken)}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  )
}
