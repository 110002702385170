// Create Edit Device Tagging
// Step 1: Device Tagging Details (set up number of floors + sectors)
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
// @mui/material
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import * as validate from "common/validations";
import { renderValue } from "common/functions";
import DeviceTaggingStepper from "./DeviceTaggingStepper";
import { setDirty } from "store/general";
import { isPositive } from "common/validations";
import { floorPlanTableHead } from "enums/AdminPortal/TableHeaders";
import { updateRequest, addRequest, deleteRequest } from "./store/floorPlan";
import { GetAllFloorPlan, CreateFloorPlans, UpdateFloorPlans, DeleteFloorPlan } from "services/AdminPortal/DeviceTaggingService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/deviceTagging.module.scss";

export default function DetailForm(props) {
  const dispatch = useDispatch();
  const floors = useSelector(store => store.admin.deviceTagging.floorPlan.floors);
  const [isChanged, setIsChanged] = React.useState(false);
  const [floorIndex, setFloorIndex] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e, index) => {
    setIsChanged(true);
    const key = e.target.id.replace(/\d/g, '');
    dispatch(updateRequest({index, [key] : e.target.value}));
  };

  const handleButtonClick_add = () => {
    setIsChanged(true);
    let payload = [...floors, {}];
    dispatch(addRequest(payload));
  }

  const handleButtonClick_delete = () => {
    setIsChanged(true);
    if (floors[floorIndex].id === undefined) {
      dispatch(deleteRequest(floorIndex));
    } else {
      dispatch(DeleteFloorPlan(floors[floorIndex].id))
      .then(() => {
        dispatch(GetAllFloorPlan());
      })
    }
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleModal_delete = (index) => {
    setFloorIndex(index);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_next = () => {
    if (!isChanged) {
      props.setStep(1);
    } else if (validateFields()) {
      Promise.all([
        dispatch(CreateFloorPlans()),
        dispatch(UpdateFloorPlans()),
      ])
      .then((response)=>{
        if (!response[0].error && !response[1].error){
          dispatch(GetAllFloorPlan()) // get updated floor plan
          .then(() => props.setStep(1))
        }
      })
    }
  }

  const validateFields = () => {
    for (let i = 0; i < floors.length; i++) {
      if (!isPositive(floors[i].floorNumber)) {
        setErrorMsg({field: "floorNumber"+i, msg: "Please add in a floor number"});
        return false;
      }
      if (!isPositive(floors[i].noOfSector)) {
        setErrorMsg({field: "noOfSector"+i, msg: "Please add in number of sectors"});
        return false;
      }
    }
    if (!validate.isUnique(floors, "floorNumber")) {
      setErrorMsg({field: "error", msg: "Floor number should be unique"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  React.useEffect(() => {
    dispatch(setDirty(isChanged));
  },[isChanged]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllFloorPlan());
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Step 1: Add in details of the farm"
        subheader={
          <React.Fragment>
            Please select in number of levels and sectors per level
            <DeviceTaggingStepper activeStep={props.step} />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button disabled />
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_next()}
            >
              Next Step
            </Button>
          </React.Fragment>
        }
      >
        <Paper className={styles.paper} elevation={0}>
          { errorMsg.field === "error" && <Typography className={styles.error}>{errorMsg.msg}</Typography> }
          <Table
            className={clsx(styles.table, styles.whiteTable)}
            header={floorPlanTableHead}
          >
            { floors.map((item, index) => {
              if (!item.isDeleted) {
                return (
                  <TableRow key={index}>
                    <TableCell className={styles.textfieldCell}>
                      <TextField 
                        id={"floorNumber"+index}
                        variant="outlined" 
                        type="Number"
                        onInput={(e)=>{ 
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,2)
                        }}
                        placeholder="Add in the Floor number"
                        onChange={(e) => handleOnChange_text(e, index)}
                        value={renderValue(item.floorNumber)}
                        errorMsg={errorMsg}
                      />
                    </TableCell>
                    <TableCell className={styles.textfieldCell}>
                      <TextField 
                        id={"noOfSector"+index}
                        variant="outlined" 
                        type="Number"
                        onInput={(e)=>{ 
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,2)
                        }}
                        placeholder="Add a number of sector per level"
                        onChange={(e) => handleOnChange_text(e, index)}
                        value={renderValue(item.noOfSector)}
                        errorMsg={errorMsg}
                      />
                    </TableCell>
                    <TableCell className={styles.textfieldCell}>
                      <IconButton 
                        type="delete"
                        className={styles.cellButton}
                        onClick={() => handleModal_delete(index)}
                      />
                    </TableCell>
                  </TableRow>
                )
              } else {
                return null;
              }
            })}
          </Table>
          <Button
            className={clsx(styles.buttonSecondary, styles.addButton)}
            startIcon={<AddIcon />}
            onClick={() => handleButtonClick_add()}
          >
            Add Floor
          </Button>
        </Paper>
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this floor? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
