import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllSaleOrder = createAsyncThunk(
  "finishedGoods/getAllSaleOrder", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetAllSaleOrder", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllSubSaleOrderList = createAsyncThunk(
  "finishedGoods/getAllSubSaleOrderList", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetAllSubSaleOrderList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWarehouseRackByWarehouseId = createAsyncThunk(
	"finishedGoods/GetWarehouseRackByWarehouseId", 
	async (param, {rejectWithValue}) => {
    const params = {
      warehouseId: param.warehouseId,
      rackID: param.rackID,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    }
		try {
			const response = await FomsMS.get("/userPortal/FinishedGoods/GetWarehouseRackByWarehouseId", {params, stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const GetStatusList = createAsyncThunk(
  "finishedGoods/getStatusList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetStatusList", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductList = createAsyncThunk(
  "finishedGoods/getProductList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetProductList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetPackageList = createAsyncThunk(
  "finishedGoods/getPackageList", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      productId: id
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetPackageList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// In
export const GetAllFinishedGoodsMasterSummary = createAsyncThunk(
  "finishedGoods/getAllFinishedGoodsMasterSummary", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetAllFinishedGoodsMasterSummary", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CheckInFinishedGoods = createAsyncThunk(
  "finishedGoods/checkInFinishedGoods", 
  async (_, {getState, rejectWithValue}) => {
    const body = {idList: getState().user.finishedGoods.finishedGoodsIn.checkInList};
    try {
      const response = await FomsMS.post("/userportal/FinishedGoods/CheckInFinishedGoods", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllFinishedGoodsInAndOut = createAsyncThunk(
  "finishedGoods/getAllFinishedGoodsInAndOut", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetAllFinishedGoodsInAndOut", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateNumberOfPackage = createAsyncThunk(
  "finishedGoods/calculateNumberOfPackage", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/CalculateNumberOfPackage", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateExpiredDate = createAsyncThunk(
  "finishedGoods/calculateExpiredDate", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/CalculateExpiredDate", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CheckInFinishedGoodsForm = createAsyncThunk(
  "finishedGoods/checkInFinishedGoodsForm", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/userportal/FinishedGoods/CheckInFinishedGoodsForm", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateFinishedGoods = createAsyncThunk(
  "finishedGoods/createFinishedGoods", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      farmId: find(userDetail.farms, "isActive").id,
      ...getState().user.finishedGoods.finishedGoodsIn.order
    };
    try {
      const response = await FomsMS.post("/userportal/FinishedGoods/CreateFinishedGoods", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSubSaleOrderWeight = createAsyncThunk(
  "finishedGoods/GetSubSaleOrderWeight", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetSubSaleOrderWeight", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductListBySubSaleOrderId = createAsyncThunk(
  "finishedGoods/getProductListBySubSaleOrderId", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetProductListBySubSaleOrderId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Out
export const CheckOutFinishedGoods = createAsyncThunk(
  "finishedGoods/checkOutFinishedGoods", 
  async (_, {getState, rejectWithValue}) => {
    const body = {idList: getState().user.finishedGoods.finishedGoodsOut.checkOutList};
    try {
      const response = await FomsMS.post("/userportal/FinishedGoods/CheckOutFinishedGoods", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Master
export const GetAllFinishedGoodsMasterList = createAsyncThunk(
  "finishedGoods/getAllFinishedGoodsMasterList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetAllFinishedGoodsMasterList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DisposeFinishedGoods = createAsyncThunk(
  "finishedGoods/disposeFinishedGoods", 
  async (param, {rejectWithValue}) => {
    const body = {
      finishedGoodsMasterInfoList: param
    }
    try {
      const response = await FomsMS.post("/userportal/FinishedGoods/DisposeFinishedGoods", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Dispose
export const GetAllDisposalList = createAsyncThunk(
  "finishedGoods/getAllDisposalList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetAllDisposalList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// DO Creation
export const GetAllDeliveryOrderCreationList = createAsyncThunk(
  "finishedGoods/getAllDeliveryOrderCreationList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetAllDeliveryOrderCreationList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeliveryOrderCreationDetailLink = createAsyncThunk(
  "finishedGoods/getDeliveryOrderCreationDetailLink", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const params = {
      ...param,
      skipCount: 0,
      maxResultCount: 9999
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/GetDeliveryOrderCreationDetailLink", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateDeliveryOrder = createAsyncThunk(
  "finishedGoods/createDeliveryOrder", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      farmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.post("/userportal/FinishedGoods/CreateDeliveryOrder", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchFinishedGoodsInList = createAsyncThunk(
  "finishedGoods/searchFinishedGoodsInList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/SearchFinishedGoodsInList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchFinishedGoodsOutList = createAsyncThunk(
  "finishedGoods/searchFinishedGoodsOutList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/SearchFinishedGoodsOutList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchFinishedGoodsMasterList = createAsyncThunk(
  "finishedGoods/searchFinishedGoodsMasterList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/SearchFinishedGoodsMasterList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchFinishedGoodsDisposalList = createAsyncThunk(
  "finishedGoods/searchFinishedGoodsDisposalList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/SearchFinishedGoodsDisposalList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchFinishedGoodsDOCreation = createAsyncThunk(
  "finishedGoods/searchFinishedGoodsDOCreation", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/FinishedGoods/SearchFinishedGoodsDOCreation", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);