// Equipment
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// @mui/material
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import Empty from "error-page/Empty";
import { reset } from "./";

import { formatNumbers, filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { equipmentTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllEquipment } from "services/AdminPortal/EquipmentService";

import settings from "assets/icons/orange/settings.svg";
import styles from "assets/jss/components/AdminPortal/equipment.module.scss";

import { useRouteCanWrite } from "hooks";

export default function Equipment() {
  let history = useHistory();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const totalCount = useSelector(store => store.admin.equipment.totalCount);
  const equipments = useSelector(store => store.admin.equipment.equipments);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('equipmentName');
  const [page, setPage] = React.useState(0);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  React.useEffect(() => {
    dispatch(GetAllEquipment({page}));
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      { totalCount > 0 
      ? <Card 
          title = {
            <React.Fragment>
              Current Equipment of Farm
              {canWrite && <IconButton 
                className={styles.iconButton}
                onClick={() => history.push("/admin/equipment/configure")}
              >
                <img className={styles.icon} src={settings} alt="settings" />
              </IconButton>}
            </React.Fragment>
          }
        >
          <div className={styles.table}>
            <Table
              header={filterActionTableHead(equipmentTableHead, canWrite)}
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
            >
              { equipments.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.equipmentName}</TableCell>
                    <TableCell>{item.equipmentRefNo}</TableCell>
                    <TableCell className={styles.numberCell}>{item.shortForm}</TableCell>
                    <TableCell className={styles.numberCell}>{formatNumbers(item.lowestDenominator)}</TableCell>
                    <TableCell className={styles.numberCell}>{item.lowestUOM}</TableCell>
                    <TableCell className={styles.numberCell}>{formatNumbers(item.costPerUnit)}</TableCell>
                    <TableCell className={styles.numberCell}>{formatNumbers(item.availableUsagePerDay)}</TableCell>
                    <TableCell className={styles.numberCell}>{formatNumbers(item.accumulatedHoursForMaintenance)}</TableCell>
                    {canWrite &&
                      <TableCell>
                        <Link className={styles.orange} to={{pathname: "/admin/equipment/configure/"+item.id}}>
                          Edit
                        </Link>
                      </TableCell>
                    }
                  </TableRow>
                )
              })}
            </Table>
          </div>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
          {canWrite &&
            <Typography className={styles.footerText}>
              Note:&nbsp;
              <span className={styles.footerTextContent}>
                If you want to create a new equipment, please go to <a href="/admin/device" className={styles.orange}>Device Creation</a> to create equipment.
              </span>
            </Typography>
          }
        </Card>
      : <Empty description={"Your page is empty. " + (canWrite ? "Configure equipment capacity to get started!" : "")}/>
      }
    </React.Fragment>
  );
}
