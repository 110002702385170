import { combineReducers } from "redux";
import deviceTagging from "./deviceTagging";
import floorPlan from "./floorPlan";
import bank from "./bank";
import devices from "./devices";

const deviceTaggingReducer = combineReducers({
  deviceTagging,
  floorPlan,
  bank,
  devices
});

export default deviceTaggingReducer;