import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import FilterButton from "shared-components/Button/FilterButton";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import Modal from 'shared-components/Modal/Modal';
import ViewMoreModal from 'shared-components/Modal/ViewMoreModal';

import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { setDirty, setSearchText } from "store/general";
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { finishedGoodsListTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/master";
import { GetAllFinishedGoodsMasterList, DisposeFinishedGoods, GetStatusList, GetProductList } from "services/UserPortal/FinishedGoodsService";

import confirm from "assets/icons/orange/confirm.svg";
import styles from "assets/jss/components/UserPortal/finishedGoods.module.scss";

import { useRouteCanWrite } from "hooks";

export default function MasterList() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const lotIdList = useSelector(store => store.common.lotIdList);
  const isDirty = useSelector(store => store.general.isDirty);
  const filter = useSelector(store => store.user.finishedGoods.finishedGoodsMaster.filter);
  const productList = useSelector(store => store.user.finishedGoods.finishedGoodsMaster.productList);
  const statusList = useSelector(store => store.user.finishedGoods.finishedGoodsMaster.statusList);
  const totalCount = useSelector(store => store.user.finishedGoods.finishedGoodsMaster.totalCount);
  const result = useSelector(store => store.user.finishedGoods.finishedGoodsMaster.finishedGoods);
  const disposeList = useSelector(store => store.user.finishedGoods.finishedGoodsMaster.disposeList);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, productId: null, status: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('dateIn');
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleButtonClick_checkout = () => {
    dispatch(DisposeFinishedGoods(disposeList))
    .then(() => {
      dispatch(setDirty(false));
      setOpenSuccessModal(!openSuccessModal);
      dispatch(setValues({filter: {...filter, page: 0}, disposeList : []}));
    });
  };

  const handleModal_success = () => {
    setOpenSuccessModal(!openSuccessModal);
  };

  const handleButtonClick_checkbox = (item) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(disposeList);
    const value = item.id;
    const index = _.findIndex(payload, ({id})=>id===value);
    if (index !== -1) {
      payload.splice(index, 1);
    } else {
      payload.push({id: value, isFromExcessTable: item.isFromExcessTable});
    }
    dispatch(setValues({disposeList : payload}));
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };
  
  const getFinishedGoodsList = (stopLoading) => {
    dispatch(GetAllFinishedGoodsMasterList({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getFinishedGoodsList();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetStatusList());
    dispatch(GetProductList());
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getFinishedGoodsList(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="Finished Goods Master List"
        subheader={
          <React.Fragment>
            <Typography>Below are the finished goods to be checked out for delivery module</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "page") {
                let label = filter[key];
                if (key === "workOrderId") {
                  const workOrder = workOrderList.find(({id}) => id == filter[key]);
                  label = workOrder && workOrder.workOrderNo;
                }
                if (key === "productId") {
                  const product = productList.find(({id}) => id == filter[key]);
                  label = product && product.productName+" ("+product.productRefNo+")";
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={styles.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              classes={{modal: styles.filterModal}}
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the finished goods list
                  <Select
                    className={styles.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="status"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a status"
                    value={renderValue(newFilter.status)}
                  >
                    {statusList && statusList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
        cardActions={ canWrite &&
          <React.Fragment>
            <Button disabled/>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_checkout()}
              disabled={!isDirty}
            >
              Dispose Off
            </Button>
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            className={styles.masterTable}
            header={finishedGoodsListTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{moment(item.dateIn).isAfter(minDate) ? moment(item.dateIn).format("DD/MM/yyyy") : ""}</TableCell>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.productRefNo}</TableCell>
                  <TableCell>{item.workOrderNo}</TableCell>
                  <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                  <TableCell>{item.subSaleOrderNo}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.qtyIn))}{item.uom}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.numberOfPacket))}</TableCell>
                  <TableCell>{item.uomForWeightPerPacket}</TableCell>
                  <TableCell>{item.palletId && <ViewMoreModal list={item.palletId} title="Pallet ID" />}</TableCell>
                  <TableCell>{item.warehouseName}({item.warehouseRefNo})</TableCell>
                  <TableCell>{item.rackRefNo && <ViewMoreModal list={item.rackRefNo} title="Rack Reference Number" />}</TableCell>
                  <TableCell>{moment(item.expectedDateOut).isAfter(minDate) ? moment(item.expectedDateOut).format("DD/MM/yyyy") : ""}</TableCell>
                  <TableCell>{moment(item.dateOut).isAfter(minDate) ? moment(item.dateOut).format("DD/MM/yyyy") : ""}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.qtyOut))}{item.uom}</TableCell>
                  <TableCell>{moment(item.expiredDate).isAfter(minDate) ? moment(item.expiredDate).format("DD/MM/yyyy") : ""}</TableCell>
                  <TableCell>
                    <Checkbox 
                      id="dispose"
                      value={item.id}
                      checked={_.findIndex(disposeList, ({id})=>id==item.id) != -1}
                      onClick={()=>handleButtonClick_checkbox(item)}
                      disabled={canWrite && Boolean(item.status==="Cancelled" || item.status==="Disposed" || item.status==="CheckOut" || item.status==="CheckIn")}
                    />
                  </TableCell>
                  <TableCell>{moment(item.disposedDate).isAfter(minDate)? moment(item.disposedDate).format("DD/MM/yyyy") : ""}</TableCell>
                  <TableCell>{item.status}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
      <Modal
        open={openSuccessModal}
        onClose={() => handleModal_success()}
        icon={<img className={styles.icon_64} src={confirm} alt="confirm" />}
        title="Success"
        content={
          <React.Fragment>
            The selected product(s) has been disposed off. Click <Link className={styles.link} to={{pathname: "/user/finished-goods/disposal-list"}}>here</Link> for the whole list of disposal list.
          </React.Fragment>
        }
        actions={
          <Button className={styles.button} onClick={() => handleModal_success()}>Okay</Button>
        }
      />
    </React.Fragment>
  );
}
