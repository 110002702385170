// Edit Equipment
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// core components
import Loading from "error-page/Loading";
import ConfigureOthers from "./ConfigureOthers";

import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { resetEquipment } from ".";
import { GetEquipment } from "services/AdminPortal/EquipmentService";

export default function ConfigureMachine() {
  const dispatch = useDispatch();
  let history = useHistory();
  const equipment = useSelector(store => store.admin.equipment.equipment);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "configure");
    if (path[index+1]) {
      dispatch(GetEquipment(path[index+1]))
      .then(({error}) => {
        if (error) {
          history.push("/admin/equipment");
        } else {
          dispatch(setDirty(true));
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetEquipment());
      dispatch(resetNav());
    }
  },[]);

  if (!_.isEmpty(equipment)) {
    return (
      <React.Fragment>
        <ConfigureOthers />
      </React.Fragment>
    )
  } else {
    return <Loading />
  }
}
