import { createSlice } from "@reduxjs/toolkit";

import { GetAllRawMaterialReserveOrIssue } from "services/UserPortal/RawMaterialService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  reserveList: [],
  //create
  reservation: {},
};

const reserveRM = createSlice({
  name: "reserveRM",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.reservation[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRawMaterialReserveOrIssue.fulfilled, (state, action) => {
        state.totalCount= action.payload.result.totalCount;
        state.reserveList = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = reserveRM.actions;

// export the reducer
export default reserveRM.reducer;