import { createAsyncThunk } from "@reduxjs/toolkit";

import {AIMS} from "./index";

export const ChatWithAIBotWithMemory = createAsyncThunk(
  "general/chat_with_foms_aibot_with_memory", 
  async (param, {rejectWithValue}) => {
    const body = {
      ...param,
    };
    try {
      const response = await AIMS.post("/chat_with_foms_aibot_with_memory", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ChatWithAIBot = createAsyncThunk(
  "general/chat_with_foms_aibot", 
  async (param, {rejectWithValue}) => {
    const body = {
      question: param,
    };
    try {
      const response = await AIMS.post("/chat_with_foms_aibot", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);