import { createAsyncThunk } from "@reduxjs/toolkit";
import { find, omitBy, isNull } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

// Lookup
export const GetProductProcessName = createAsyncThunk(
  "humanResource/getProductProcessName", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/HumanResource/GetProductProcessName", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTaskNumberList = createAsyncThunk(
  "humanResource/getTaskNumberList", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/HumanResource/GetTaskNumberList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetLotIdByWorkOrderIdAndProductId = createAsyncThunk(
  "humanResource/getLotIdByWorkOrderIdAndProductId", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/HumanResource/GetLotIdByWorkOrderIdAndProductId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Log Sheet
export const GetAllLogSheet = createAsyncThunk(
  "humanResource/getAllLogSheet", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/HumanResource/GetAllLogSheet", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateLogSheet = createAsyncThunk(
  "humanResource/createOrUpdateLogSheet", 
  async (log, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      ...omitBy(log, isNull),
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/HumanResource/CreateOrUpdateLogSheet", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SubmitLogSheetList = createAsyncThunk(
  "humanResource/submitLogSheetList", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.hr.logSheet.log;
    try {
      const response = await FomsMS.post("/userportal/HumanResource/SubmitLogSheetList", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteLogSheet = createAsyncThunk(
  "humanResource/deleteLogSheet", 
  async (logSheetId, {rejectWithValue}) => {
    try {
      const params = {logSheetId};
      const response = await FomsMS.delete("/userportal/HumanResource/DeleteLogSheet", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateLogSheetDurationDetail = createAsyncThunk(
  "humanResource/createOrUpdateLogSheetDurationDetail", 
  async (detail, {rejectWithValue}) => {
    let body = {
      ...detail,
    };
    try {
      const response = await FomsMS.post("/userportal/HumanResource/CreateOrUpdateLogSheetDurationDetail", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// HR Availability
export const GetAllHRAvailability = createAsyncThunk(
  "humanResource/getAllHRAvailability", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/HumanResource/GetAllHRAvailability", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Labour Cost
export const GetAllLabourCost = createAsyncThunk(
  "humanResource/getAllLabourCost", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/HumanResource/GetAllLabourCost", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDurationDetailByLogSheetId = createAsyncThunk(
  "humanResource/getDurationDetailByLogSheetId", 
  async (logSheetId, {rejectWithValue}) => {
    const params = {logSheetId};
    try {
      const response = await FomsMS.get("/userportal/HumanResource/GetDurationDetailByLogSheetId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Admin Log Sheet
export const GetAllOverallLogStatus = createAsyncThunk(
  "humanResource/getAllOverallLogStatus", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/HumanResource/GetAllOverallLogStatus", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SendReminderEmail = createAsyncThunk(
  "humanResource/sendReminderEmail", 
  async (id, {rejectWithValue}) => {
    const params = {overallLogId: id};
    try {
      const response = await FomsMS.get("/userportal/HumanResource/SendReminderEmail", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateOverallLogStatus = createAsyncThunk(
  "humanResource/updateOverallLogStatus", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const log = getState().user.hr.adminLogSheet.log;
    const body = {
      ...log,
      farmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.patch("/userportal/HumanResource/UpdateOverallLogStatus", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchPastLogSheet = createAsyncThunk(
  "humanResource/searchPastLogSheet", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/HumanResource/SearchPastLogSheet", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchLabourCost = createAsyncThunk(
  "humanResource/searchLabourCost", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/HumanResource/SearchLabourCost", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);