import { createAsyncThunk } from "@reduxjs/toolkit";

import {FomsMS} from "../index";

export const RequestResetPassword = createAsyncThunk(
  "reset/requestResetPassword", 
  async (_, {getState, rejectWithValue}) => {
    const email = getState().credentials.resetPassword.email;
    const params = {email};
    try {
      const response = await FomsMS.get("/ResetPassword/Request", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ResetPassword = createAsyncThunk(
  "reset/resetPassword", 
  async (_, {getState, rejectWithValue}) => {
    const resetPassword = getState().credentials.resetPassword;
    const body = {
      email: resetPassword.email,
      passwordResetCode: resetPassword.token,
      newPassword: Buffer.from(resetPassword.password).toString('base64'),
    };
    try {
      const response = await FomsMS.post("/ResetPassword/Reset", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const Verify = createAsyncThunk(
  "reset/verify", 
  async (search, {rejectWithValue}) => {
    const params = search;
    try {
      const response = await FomsMS.get("/ResetPassword/Verify", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);