import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import {FomsMS} from "../index";

export const GetAllUnitOfMeasurement = createAsyncThunk(
  "RawMaterial/getAllUnitOfMeasurement", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/RawMaterial/GetAllUnitOfMeasurement");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWarehouseRackByWarehouseId = createAsyncThunk(
	"RawMaterial/GetWarehouseRackByWarehouseId", 
	async (param, {rejectWithValue}) => {
		try {
			const params = {
      warehouseId: param.warehouseId,
      rackID: param.rackID,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    }
			const response = await FomsMS.get("/RawMaterial/GetWarehouseRackByWarehouseId", {params, stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const GetAllRawMaterial = createAsyncThunk(
  "RawMaterial/getAllRawMaterial", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      MaxResultCount: 9999
    }
    try {
      const response = await FomsMS.get("/RawMaterial/GetAllRawMaterial", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRawMaterial = createAsyncThunk(
  "RawMaterial/getRawMaterial", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.get("/RawMaterial/GetRawMaterial", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllRawMaterialType = createAsyncThunk(
  "RawMaterial/getAllRawMaterialType", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.get("/RawMaterial/GetAllRawMaterialType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRawMaterialType = createAsyncThunk(
  "RawMaterial/getRawMaterialType", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.get("/RawMaterial/GetRawMaterialType", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllRawMaterialVariantType = createAsyncThunk(
  "RawMaterial/getAllRawMaterialVariantType", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      rawMaterialTypeId : id,
      maxResultCount: 50, //default is 10 if never hardset this, to adjust when search func comes in the future
    }
    try {
      const response = await FomsMS.get("/RawMaterial/GetAllRawMaterialVariantType", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRawMaterialVariantType = createAsyncThunk(
  "RawMaterial/getRawMaterialVariantType", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.get("/RawMaterial/GetRawMaterialVariantType", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateUpdateVariantType = createAsyncThunk(
  "RawMaterial/createOrUpdateRawMaterialVariantType", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/RawMaterial/CreateOrUpdateRawMaterialVariantType", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteRawMaterialVariantType = createAsyncThunk(
  "RawMaterial/deleteRawMaterialVariantType", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/RawMaterial/DeleteRawMaterialVariantType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateUpdateRawMaterialType = createAsyncThunk(
  "RawMaterial/createOrUpdateRawMaterialType", 
  async (data, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...data,
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.post("/RawMaterial/CreateOrUpdateRawMaterialType", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteRawMaterialType = createAsyncThunk(
  "RawMaterial/deleteRawMaterialType", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/RawMaterial/DeleteRawMaterialType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProposedRawMaterialRefNo = createAsyncThunk(
  "RawMaterial/getProposedRawMaterialRefNo", 
  async (data, {rejectWithValue}) => {
    try {
      const params = {
        rawMaterialTypeId : data.rawMaterialTypeId,
        rawMaterialVariantTypeId: data.variantTypeId,
        id: data.id
      };
      const response = await FomsMS.get("/RawMaterial/GetProposedRawMaterialRefNo", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProposedRawMaterialBatchRefNo = createAsyncThunk(
  "RawMaterial/getProposedRawMaterialBatchRefNo", 
  async (data, {rejectWithValue}) => {
    try {
      const params = {
        rawMaterialTypeId : data.rawMaterialTypeId,
        rawMaterialVariantTypeId: data.variantTypeId
      };
      const response = await FomsMS.get("/RawMaterial/GetProposedRawMaterialBatchRefNo", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRawMaterial = createAsyncThunk(
  "RawMaterial/createOrUpdateRawMaterial", 
  async (data, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...data,
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.post("/RawMaterial/CreateOrUpdateRawMaterial", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)

export const DeleteRawMaterial = createAsyncThunk(
  "RawMaterial/deleteRawMaterial", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/RawMaterial/DeleteRawMaterial", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const IsRawMaterialNameExist = createAsyncThunk(
  "RawMaterial/isRawMaterialNameExist", 
  async (data, {rejectWithValue}) => {
    try {
      const params = {
        rawMaterialName : data.rawMaterialName,
        id: data.id
      };
      const response = await FomsMS.get("/RawMaterial/IsRawMaterialNameExist", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchRawMaterial = createAsyncThunk(
  "rawMaterial/searchRawMaterial", 
  async (Keyword, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword,
      MaxResultCount: 9999
    }
    try {
      const response = await FomsMS.get("/RawMaterial/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllSupplier = createAsyncThunk(
  "rawMaterial/GetAllSupplier", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/RawMaterial/GetAllSupplier", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllWarehouses = createAsyncThunk(
  "rawMaterial/getAllWarehouses", 
  async (param, {rejectWithValue}) => {
		const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.get("/RawMaterial/GetAllWarehouses", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);