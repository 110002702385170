import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
// @mui/material
import { graphColors as colors } from "assets/jss/palette";
import styles from "assets/jss/components/UserPortal/report.module.scss";

require("highcharts/modules/exporting")(Highcharts);

export default function ReportChart() {
  const graph = useSelector(store => store.user.report.report.graph);
  const [config, setConfig] = React.useState({});

  const results = graph.graphDataList ?? [];
  
  React.useEffect(() => {
    const categories = _.uniq(_.flattenDeep(results.map((item) => {
      return item.dataList.map(({xValue}) => xValue);
    }))).sort();
    // const yIsNumeric = _.isNumber(Number(results[0].dataList[0].yValue));
    if (graph.visualisationType && graph.visualisationType.includes("Bar")) {
      setConfig({
        chart: {
          type: 'column',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: categories,
          title: {
            text: results[0] && results[0].xaxisVariableName
          }
        },
        yAxis: {
          title: {
            text: results[0] && results[0].yaxisVariableName
          }
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: true
        },
        series: results.map((item, i) => {
          return {
            data: item.dataList.map(({xValue, yValue}) => [_.indexOf(categories, xValue), Number(yValue)]),
            name: item.productName,
            color: colors[i]
          };
        })
      })
    } else if (graph.visualisationType && graph.visualisationType.includes("Line")) {
      setConfig({
        chart: {
          type: 'line',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: categories,
          title: {
            text: results[0] && results[0].xaxisVariableName
          }
        },
        yAxis: {
          title: {
            text: results[0] && results[0].yaxisVariableName
          }
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: true
        },
        plotOptions: {
          line: {
            marker: {
              enabled: true
            }
          }
        },
        series: results.map((item, i) => {
          return {
            findNearestPointBy: 'xy',
            data:  item.dataList.map(({xValue, yValue}) => [_.indexOf(categories, xValue), Number(yValue)]),
            name: item.productName,
            color: colors[i]
          };
        })
      })
    } else {
      setConfig({
        chart: {
          type: 'scatter',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: categories,
          title: {
            text: results[0] && results[0].xaxisVariableName
          }
        },
        yAxis: {
          title: {
            text: results[0] && results[0].yaxisVariableName
          }
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: true
        },
        series: results.map((item, i) => {
          return {
            data: item.dataList.map(({xValue, yValue}) => [_.indexOf(categories, xValue), Number(yValue)]),
            name: item.productName,
            color: colors[i]
          };
        })
      })
    }
  }, [graph]);
  
  return (
    <React.Fragment>
      <HighchartsReact highcharts={Highcharts} options={config} containerProps={{ className: styles.highChart2 }} />
    </React.Fragment>
  );
}
