// Farm
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FarmList from "./FarmList";
import BankDetails from "./BankDetails";
import { reset } from ".";
import { setSearchText } from "store/general";

export default function Farm() {
  const dispatch = useDispatch();
  const farms = useSelector(store => store.admin.farm.farms);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const isFirstUpdate = React.useRef(true);
  const [bankDetails, setBankDetails] = React.useState(false);
  const [farmId, setFarmId] = React.useState(null);   // to know which farm detail is selected

  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  if (bankDetails) {
    return (
      <BankDetails 
        id={farmId} 
        farmName={farms.find((farm) => farm.id === farmId).farmName} 
        setBankDetails={setBankDetails}
        setFarmId={setFarmId}
      />
    )
  } else {
    return (
      <FarmList setBankDetails={setBankDetails} setFarmId={setFarmId}/>
    );
  }
}
