import React from "react";
import { useDispatch, useSelector } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import CustomTabs from "shared-components/Tabs/Tabs";

import Empty from "error-page/Empty";
import CapacityBank from "./CapacityBank";
import { farmConfig } from "enums/Constants";
import { setSearchText } from "store/general";
import { reset } from "./store/capacity";
import { TracingCapacityModuleForStatisticsReport } from "services/UserPortal/CapacityService";

export default function Capacity(props) {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const bankList = useSelector(store => store.common.bankList);
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);

  const handleOnChange_tab = (e, value) => {
    setTab(value);
  }
  // componentDidMount
  React.useEffect(() => {
    dispatch(TracingCapacityModuleForStatisticsReport());
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
    {bankList.length 
    ? <React.Fragment>
        <Card 
          title = "Cage Utilisation Status"
          subheader={
            <CustomTabs 
              tabs={bankList.map((item) => {return item.bankName})}
              value={tab} 
              onChange={handleOnChange_tab}
              disabled={isDirty}
            />
          }
        >
          <CapacityBank bank={bankList.length && bankList[tab]} />
        </Card>
      </React.Fragment>
    : <Empty 
        description={"There are no "+farmConfig.bank+" to display."}
      />
    }
    </React.Fragment>
  );
}
