import { createSlice } from "@reduxjs/toolkit";

import { GetAllUnitOfMeasurement, GetAllVehicle, GetVehicle, GetAllVehicleType, GetVehicleType, GetProposedVehicleRefNo, SearchVehicle } from "services/AdminPortal/VehicleService";

// initial state
const initialState = {
  totalCount: 0,
  vehicles: [],
  vehicle: {},
  vehicleTypeCount: 0,
  vehicleTypes: [],
  vehicleType: {},
  uomLookup: [],
  searchResultsCount: 0,
  searchResults: [],
};

const vehicle = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.vehicle[key] = action.payload[key];
      })
    },
    updateVehicleTypeRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.vehicleType[key] = action.payload[key];
      })
    },
    resetVehicle: (state) => {
      state.vehicle = {};
    },
    resetVehicleType: (state) => {
      state.vehicleType = {};
    },
    resetSearch: (state) => {
      state.searchResultsCount = 0;
      state.searchResults = [];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllVehicle.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.vehicles = action.payload.result.items;
      })
      .addCase(GetVehicle.fulfilled, (state, action) => {
        state.vehicle = action.payload.result;
      })
      .addCase(GetAllVehicleType.fulfilled, (state, action) => {
        state.vehicleTypeCount = action.payload.result.totalCount;
        state.vehicleTypes = action.payload.result.items;
      })
      .addCase(GetVehicleType.fulfilled, (state, action) => {
        state.vehicleType = action.payload.result;
      })
      .addCase(GetProposedVehicleRefNo.fulfilled, (state, action) => {
        state.vehicle.vehicleRefNo = action.payload.result;
      })
      .addCase(GetAllUnitOfMeasurement.fulfilled, (state, action) => {
        state.uomLookup = action.payload.result;
      })
      .addCase(SearchVehicle.fulfilled, (state, action) => {
        state.searchResultsCount = action.payload.result.totalCount;
        state.searchResults = action.payload.result.items;
      })
  },
});

// export actions
export const { updateRequest, updateVehicleTypeRequest, resetVehicle, resetVehicleType, resetSearch, reset } = vehicle.actions;

// export the reducer
export default vehicle.reducer;