import React from "react";
// @mui/lab
import Pagination from '@mui/material/Pagination';

import styles from "assets/jss/components/UserPortal/home.module.scss";

export default function CustomPagination({...props}) {

  return (
    <Pagination 
      {...props}
      className={styles.pagination}
      variant="outlined" 
      shape="rounded" 
      hidePrevButton 
      hideNextButton
    />
  );
}