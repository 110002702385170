// Create Edit Product
// Step 2: Packaging Type Management
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";

import { rowsPerTable } from "config";
import { packageSettingsTableHead } from "enums/AdminPortal/TableHeaders";
import { renderValue } from "common/functions";
import { setValues, updateRequest } from "./store/packageSettings";
import { GetAllPackagingType, CreateOrUpdatePackagingType, DeletePackagingType } from "services/AdminPortal/ProductService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/product.module.scss";

export default function ManagePackage() {
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.admin.product.packageSettings.totalCount);
  const results = useSelector(store => store.admin.product.packageSettings.packageTypes);
  const packageType = useSelector(store => store.admin.product.packageSettings.package);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('typeId');
  const [page, setPage] = React.useState(0);
  const [isAddEdit, setIsAddEdit] = React.useState(false);
  const [packageId, setPackageId] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };
  
  const handleButtonClick_edit = (id) => {
    setPackageId(id);
    setIsAddEdit(true);
    dispatch(setValues({package: _.find(results, (item) => item.id === id)}));
  }

  const handleModal_delete = (id) => {
    setPackageId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeletePackagingType(packageId))
    .then(() =>{
      dispatch(GetAllPackagingType({page: 0}));
      resetState();
    })
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdatePackagingType())
      .then(({error}) => {
        if (!error) {
          dispatch(GetAllPackagingType({page: 0}));
          resetState();
        }
      })
    }
  }

  const handleButtonClick_cancel = () => {
    if (packageId === 0) {
      dispatch(setValues({packageTypes: _.filter(results, (item) => item.id !== packageId)}));
      setPage(0);
    }
    resetState();
  }

  const handleButtonClick_add = () => {
    const payload = [...results, {id: 0}];
    setIsAddEdit(true);
    setPackageId(0);
    dispatch(setValues({packageTypes: payload}));
  }

  const validateFields = () => {
    if (!packageType.packagingType) {
      setErrorMsg({field: "packagingType", msg: "Please enter package type"});
      return false;
    } 
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setPage(0);
    setIsAddEdit(false);
    setPackageId(null);
    setOpenDeleteModal(false);
    setErrorMsg(false);
    dispatch(setValues({package: {}}));
  }

  React.useEffect(() => {
    dispatch(GetAllPackagingType({page}));
  },[page]);

  return (
    <div className="flex justify-center">
      <Paper elevation={0} className={styles.packagePaper}>
        <Table
          className={styles.table}
          header={packageSettingsTableHead}
          // order={order}
          // orderBy={orderBy}
          // onRequestSort={handleRequestSort}
        >
          { results.map((item) => {
            return (
              <TableRow key={item.id}>
                <TableCell>
                  { isAddEdit && packageId === item.id
                  ? <TextField 
                      id="packagingType"
                      variant="outlined" 
                      inputProps={{ maxLength: 255 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={renderValue(packageType.packagingType)}
                      errorMsg={errorMsg}
                    />
                  : item.packagingType
                  }
                </TableCell>
                <TableCell align="right" className={isAddEdit && packageId === item.id ? styles.tableCell : null}>
                  { isAddEdit && packageId === item.id
                  ? <React.Fragment>
                      <IconButton 
                        type="confirm"
                        onClick={() => handleButtonClick_confirm()}
                      />
                      <IconButton 
                        type="close"
                        onClick={() => handleButtonClick_cancel()}
                      />
                    </React.Fragment>
                  : <React.Fragment>
                      <IconButton
                        type="edit" 
                        onClick={() => handleButtonClick_edit(item.id)}
                        disabled={(packageId && packageId !== item.id)}
                      />
                      <IconButton
                        type="delete" 
                        onClick={() => handleModal_delete(item.id)}
                        disabled={(packageId && packageId !== item.id)}
                      />
                    </React.Fragment>
                  }
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
            disabled={Boolean(isAddEdit)}
          />
        }
        <Button
          className={clsx(styles.buttonSecondary, styles.addMoreProcess)}
          startIcon={<AddIcon />}
          onClick={() => handleButtonClick_add()}
          disabled={isAddEdit}
        >
          Add More Packaging Type
        </Button>
      </Paper>
      <Modal
          open={openDeleteModal}
          onClose={() => handleModal_delete(null)}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this package type? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
    </div>
  )
}
