import React from "react";
// @mui/material components
import Chip from "@mui/material/Chip";
// core components
import styles from "assets/jss/shared-components/chip.module.scss";

export default function CustomChip(props) {
  return (
    <Chip
      classes={{
        root: styles.chipRoot
      }}
      {...props}
    />
  );
}