import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @mui/material
import Paper from "@mui/material/Paper";
import Drawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// @mui/icons-material
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { minDateTime } from "config";
import { setValues } from "../store/flagship";
import { useInterval } from 'common/utils';
import { GetData, GetCurrentAddresses } from "services/UserPortal/SmartMeteringFlagshipService";

import EnergyConsumption from "./EnergyConsumption";
import GraphScale from "./GraphScale";
import ParamList from "./ParamList";
import ColumnChart from "./ColumnChart";
import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";

export default function Current() {
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.smartMetering.flagship.filter);
  const result = useSelector(store => store.user.smartMetering.flagship.result);
  const [selected, setSelected] = React.useState([]);
  const [selectedDB, setSelectedDB] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const controller = new AbortController();
  
  const getEnergyMonitoring = (stopLoading) => {
    if ((filter.address && filter.address.length) && (filter.dbName && filter.dbName.length)) {
      dispatch(GetData({...filter, stopLoading: true, signal: controller.signal}));
      setIsLoading(!stopLoading);
    } else {
      dispatch(setValues({result: []}));
    }
  }

  const getAddresses = (dbName) => {
    dispatch(GetCurrentAddresses({dbName: [dbName]}));
  }

  React.useEffect(() => {
    dispatch(setValues({filter: {...filter, dbName: selectedDB, address: selected}}));
  },[selected, selectedDB]);

  React.useEffect(() => {
    getEnergyMonitoring();
    // cleanup controller
    return () => {
      controller.abort();
    };
  },[filter]);

  React.useEffect(() => {
    dispatch(setValues({filter: {...filter, startDate: moment().subtract(1,'hours').format(), endDate: moment().format()}}));
  },[]);
  
  useInterval(() => {
    getEnergyMonitoring(true);
  });

  return (
    <React.Fragment>
      <div className={styles.graphContent}>
        <IconButton
          className={styles.formIconButton3}
          onClick={()=>setOpen(!open)}
        >
          {!open ? <KeyboardArrowRightIcon className={styles.icon_24} /> : <KeyboardArrowLeftIcon className={styles.icon_24} />}
        </IconButton>
        <Drawer
          sx={{
            // width: drawerWidth,
            // flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: "30%",
              height: "31.250vw",
              position: 'absolute',
              padding: "1.563vw",
              marginLeft: "-1.979vw",
              border: "0.052vw solid #D9D9D6",
              borderLeft: 0,
              borderBottomRightRadius: "0.833vw",
              overflow: "visible",
              // marginTop: "5.125vw",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Paper className={styles.paper2} elevation={0}>
            <ParamList selected={selected} setSelected={setSelected} selectedDB={selectedDB} setSelectedDB={setSelectedDB} getAddresses={getAddresses} />
          </Paper>
          <IconButton
            className={styles.formIconButton}
            onClick={()=>setOpen(!open)}
          >
            {!open ? <KeyboardArrowRightIcon className={styles.icon_24} /> : <KeyboardArrowLeftIcon className={styles.icon_24} />}
          </IconButton>
        </Drawer>
        <div className={styles.highChart}>
          <div className={styles.topComponent}>
            <div>
              <EnergyConsumption />
              <Typography>Last Updated: {moment(result.lastUpdated).isAfter(minDateTime) && moment(result.lastUpdated).format('DD/MM/YYYY HH:mm')}</Typography>
            </div>
            <GraphScale filter={filter} setValues={setValues} />
          </div>
          <ColumnChart results={result && result.response} isLoading={isLoading} setIsLoading={setIsLoading} yAxis="Current (A)" />
          {/* <div className={styles.legend}>
            {result.todayTotalEnergy && result.todayTotalEnergy.aggregationRequest.map((item, index) => {
              if (item) {
                return (
                  <React.Fragment key={index}>
                    <div className={clsx(styles.colorBox, styles.legendColor)} />{item.address}
                  </React.Fragment>
                )
              }
            })}
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
}
