import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";


export default function EnergyConsumption() {
  const result = useSelector(store => store.user.smartMetering.overview.result);

  return (
    <div>
      <Typography className={styles.label}>Energy Consumption</Typography>
      <Paper className={styles.paper} elevation={0}>
        <Typography>Today: <span className={styles.orange}>{result.todayTotalEnergy && result.todayTotalEnergy.totalDaykWh}kW</span></Typography>
        <Typography>Yesterday: <span className={styles.orange}>{result.yesterdayTotalEnergy && result.yesterdayTotalEnergy.totalDaykWh}kW</span></Typography>
      </Paper>
      <Typography className={styles.sublabel}>Last Updated: {result.todayTotalEnergy && moment(result.todayTotalEnergy.dateTime).format('DD/MM/YYYY HH:mm')}</Typography>
    </div>
  );
}
