import { createSlice } from "@reduxjs/toolkit";

import { SearchDeliveryAssignmentList, SearchDeliveryMasterList, SearchDeliveryOrder } from "services/UserPortal/DeliveryService";

// initial state
const initialState = {
  assignmentTotalCount: 0,
  masterTotalCount: 0,
  doTotalCount: 0,
  assignmentResults: [],
  masterResults: [],
  doResults: [],
};

const deliverySearch = createSlice({
  name: "deliverySearch",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SearchDeliveryAssignmentList.fulfilled, (state, action) => {
        state.assignmentTotalCount = action.payload.result.totalCount;
        state.assignmentResults = action.payload.result.items;
      })
      .addCase(SearchDeliveryMasterList.fulfilled, (state, action) => {
        state.masterTotalCount = action.payload.result.totalCount;
        state.masterResults = action.payload.result.items;
      })
      .addCase(SearchDeliveryOrder.fulfilled, (state, action) => {
        state.doTotalCount = action.payload.result.totalCount;
        state.doResults = action.payload.result.items;
      })
  },
});

// export actions
export const { reset } = deliverySearch.actions;

// export the reducer
export default deliverySearch.reducer;