// Create Edit Farm
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { addRequest, resetFarm, resetBanks } from ".";
import { GetFarm } from "services/AdminPortal/FarmService";

import FarmForm from "./FarmForm";
import BankForm from "./BankForm";

export default function AddEditFarm(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const [step, setStep] = React.useState(0);

  // componentDidMount
  React.useEffect(() => {
    const prop = props.location.state;
    dispatch(setAddNewButton(true));
    dispatch(setDirty(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "farm");
    if (path[index+1] != "create") {
      dispatch(GetFarm(path[index+1]))
      .then(({error}) => {
        if (error) {
          Promise.all([dispatch(setDirty(false))])
          .then(()=> {
            history.push((prop && prop.prevPath) || "/admin/farm");
          })
        }
        if (prop && prop.add) {
          dispatch(addRequest());
        }
      });
    }
    if (prop && prop.step) {
      setStep(prop.step);
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetFarm());
      dispatch(resetBanks());
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      { step === 0
      ? <FarmForm step={step} setStep={setStep} />
      : <BankForm id={props.id} step={step} setStep={setStep} add={props.add} />
      }
    </React.Fragment>
  )
}
