// Company
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// @mui/material
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import Empty from "error-page/Empty";
import { setSearchText } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { GetAllCompany, DeleteCompany } from "services/AdminPortal/CompanyService";
import { reset } from ".";

import image from "assets/icons/black/image.svg";
import industry from "assets/icons/black/industry.svg";
import country from "assets/icons/black/country.svg";
import currency from "assets/icons/black/currency.svg";
import size from "assets/icons/black/id-management.svg";
import timeunit from "assets/icons/black/time-unit.svg";
import timezone from "assets/icons/black/timezone.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/company.module.scss";

import { useRouteCanWrite } from "hooks";

export default function Company() {
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const companies = useSelector(store => store.admin.company.companies);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const [companyId, setCompanyId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleModal = (id) => {
    setCompanyId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteCompany(companyId))
    .then(() =>{
      dispatch(GetAllCompany());
      resetState();
    });
  }

  const resetState = () => {
    setCompanyId(null);
    setOpenModal(!openModal);
  }

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllCompany())
    .then(({payload}) => {
      if (payload && payload.result.totalCount > 0) {
        dispatch(setAddNewButton(true))
      }
    })
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      { companies && companies.length > 0 
      ? <React.Fragment>
          <Card 
            title="Your Company Details"
          >
            {companies.map((company) => {
              return (
                <Paper key={company.companyId} className={styles.paper} elevation={0}>
                  <Grid container>
                    <Grid item xs={12} className={styles.iconGroup}>
                      {canWrite &&
                        <React.Fragment>
                          <Link to={{pathname: "/admin/company/"+company.id}}>
                            <IconButton type="edit" />
                          </Link>
                          <IconButton
                            type="delete" 
                            onClick={() => handleModal(company.id)}
                          />
                        </React.Fragment>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <div className={styles.img}>
                        { company.imageUrl 
                        ? <img className={styles.cardRadius} src={company.imageUrl} alt="company-pic" />
                        : <img className={styles.newIcon} src={image} alt="company-pic" />
                        }
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={styles.name}>{company.companyName}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={styles.address}>{company.address}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Paper className={styles.gridPaper} elevation={0}>
                        <img className={styles.icon_48} src={industry} alt="industry-icon" />
                        <div className={styles.detail}>
                          <Typography className={styles.label}>Industry:</Typography>
                          <Typography className={styles.value}>{company.industry}</Typography>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Paper className={styles.gridPaper} elevation={0}>
                        <img className={styles.icon_48} src={country} alt="country-icon" />
                        <div className={styles.detail}>
                          <Typography className={styles.label}>Country:</Typography>
                          <Typography className={styles.value}>{company.country}</Typography>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Paper className={styles.gridPaper} elevation={0}>
                        <img className={styles.icon_48} src={currency} alt="currency-icon" />
                        <div className={styles.detail}>
                          <Typography className={styles.label}>Currency:</Typography>
                          <Typography className={styles.value}>{company.currency}</Typography>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Paper className={styles.gridPaper} elevation={0}>
                        <img className={styles.icon_48} src={size} alt="size-icon" />
                        <div className={styles.detail}>
                          <Typography className={styles.label}>Company Size:</Typography>
                          <Typography className={styles.value}>{company.companySize}</Typography>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Paper className={styles.gridPaper} elevation={0}>
                        <img className={styles.icon_48} src={timezone} alt="timezone-icon" />
                        <div className={styles.detail}>
                          <Typography className={styles.label}>Time Zone:</Typography>
                          <Typography className={styles.value}>{company.timeZone}</Typography>
                        </div>
                      </Paper>
                    </Grid>
                    { company.timeUnit &&
                      <Grid item xs={4} className={styles.grid}>
                        <Paper className={styles.gridPaper} elevation={0}>
                          <img className={styles.icon_48} src={timeunit} alt="timeunit-icon" />
                          <div className={styles.detail}>
                            <Typography className={styles.label}>Time Unit:</Typography>
                            <Typography className={styles.value}>{company.timeUnit}</Typography>
                          </div>
                        </Paper>
                      </Grid>
                    }
                  </Grid>
                </Paper>
              )
            })}
          </Card>
          <Modal
            open={openModal}
            onClose={() => handleModal(null)}
            icon={<img className={styles.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete one of your company profile? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
                <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
        </React.Fragment>
      : <Empty 
          description={"Your page is empty. " + (canWrite ? "Add a company to get started!" : "")}
        />
      }
    </React.Fragment>
  );
}
