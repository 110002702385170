// Batch Upload User
// Edit Batch Upload
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import Select from "shared-components/Select/Select";
import IconButton from "shared-components/Button/IconButton";

import * as validate from "common/validations";
import { rowsPerTable } from "config";
import { setDirty } from "store/general";
import { genderLookup } from "enums/Lookups.js";
import { GetAllRoles, CreateBatchUser } from "services/AdminPortal/UserService";
import { userTableHead } from "enums/AdminPortal/TableHeaders";
import { setBatchValues, updateBatchRequest, updateBatchesRequest, deleteBatchRequest, resetBatch, reset } from ".";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/user.module.scss";

export default function UploadedData() {
  let history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector(store => store.admin.user.batchUsers);
  const user = useSelector(store => store.admin.user.batchUser);
  const rolesLookup = useSelector(store => store.admin.role.roles);
  const countryLookup = useSelector(store => store.lookup.countryLookup);
  const workforceTypeLookup = useSelector(store => store.lookup.workforceTypeLookup);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [editUser, setEditUser] = React.useState(false);
  const [userIndex, setUserIndex] = React.useState(null);
  const [errorMode, setErrorMode] = React.useState(null);
  const [errorFields, setErrorFields] = React.useState([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);

  const count = Math.ceil(users.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_text = (e) => {
    dispatch(updateBatchRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateBatchRequest({[e.target.name] : e.target.value}));
  };

  const handleButtonClick_edit = (lineNo) => {
    setUserIndex(lineNo);
    setEditUser(true);
    dispatch(setBatchValues(users.find((item) => item.lineNo === lineNo)));
  }

  const handleModal_delete = (lineNo) => {
    setUserIndex(lineNo);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(deleteBatchRequest(userIndex));
    setOpenDeleteModal(!openDeleteModal);
    resetState();
  }

  const handleButtonClick_confirm = () => {
    dispatch(updateBatchesRequest(userIndex));
    resetState();
  }

  const handleButtonClick_save = () => {
    if (validateMissingFields().length > 0) {
      handleModal_warning();
    } else if (validateInvalidFields().length > 0) {
      handleModal_warning();
    } else {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateBatchUser())
        .then((response)=>{
          if (response.error && response.payload.message !== "An internal error occurred during your request!") {
            dispatch(setDirty(true));
            handleModal_warning();
            setErrorFields([response.payload.message]);
            setErrorMode("Invalid");
          } else {
            history.push("/admin/user");
          }
        });
      })
    }
  }

  const validateMissingFields = () => {
    let errors = [];
    for (let i = 0; i < users.length; i++) {
      if (_.isEmpty(users[i].name)) {
        errors = _.union(errors, ["Name"]);
      }
      if (_.isEmpty(users[i].roles)) {
        errors = _.union(errors, ["Roles"]);
      }
      if (_.isEmpty(users[i].userName)) {
        errors = _.union(errors, ["Username"]);
      }
      if (_.isEmpty(users[i].emailAddress)) {
        errors = _.union(errors, ["Email"]);
      }
      if (_.isEmpty(users[i].workforceType)) {
        errors = _.union(errors, ["Workforce Type"]);
      }
    }
    setErrorFields(errors);
    setErrorMode("Missing");
    return errors;
  }

  const validateInvalidFields = () => {
    let errors = [];
    for (let i = 0; i < users.length; i++) {
      if (users[i].phone && !validate.isPhoneNumber(users[i].phone)) {
        errors = _.union(errors, ["Phone"]);
      }
      if (!validate.isEmail(users[i].emailAddress)) {
        errors = _.union(errors, ["Email"]);
      }
      if (users[i].roles && _.findIndex(rolesLookup, ["name", users[i].roles]) === -1) {
        errors = _.union(errors, ["Roles"]);
      }
      if (users[i].gender && _.findIndex(genderLookup, ["displayName", users[i].gender]) === -1) {
        errors = _.union(errors, ["Gender"]);
      }
      if (users[i].workforceType && _.findIndex(workforceTypeLookup, ["displayName", users[i].workforceType]) === -1) {
        errors = _.union(errors, ["Workforce Type"]);
      }
      if (users[i].country && _.findIndex(countryLookup, ["countryName", users[i].country]) === -1) {
        errors = _.union(errors, ["Country/ Region"]);
      }
    }
    setErrorFields(errors);
    setErrorMode("Invalid");
    return errors;
  }

  const resetState = () => {
    setEditUser(false);
    setUserIndex(null);
    setPage(0);
    dispatch(resetBatch());
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetAllRoles({page: 0, maxResultCount: 99}));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  }, []);

  return (
    <React.Fragment>
      <Card 
        className={styles.cardRadius}
        title = "Uploaded Data"
        cardActions={
          <React.Fragment>
            <Button disabled/>
            <Button
              className={styles.button}
              onClick={() => handleButtonClick_save()}
              disabled={editUser}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Table
          className={styles.table}
          header={userTableHead.map((head) => {
            if (head.id === "action") {
              head.label = null;
            }
            if (head.id !== "phone" && head.id !== "gender" && head.id !== "employeeId" && head.id !== "country" && head.id !== "action") {
              return {...head, required: true}
            } else {
              return head
            }
          })}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        >
          { _.orderBy(users, [orderBy], [order])
          .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
          .map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  { editUser && userIndex === item.lineNo
                  ? <TextField 
                      id="name"
                      variant="outlined" 
                      inputProps={{ maxLength: 64 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={!user.name ? "" : user.name}
                    />
                  : item.name
                  }
                </TableCell>
                <TableCell>
                  { editUser && userIndex === item.lineNo
                  ? <TextField 
                      id="userName"
                      variant="outlined" 
                      inputProps={{ maxLength: 255 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={!user.userName ? "" : user.userName}
                    />
                  : item.userName
                  }
                </TableCell>
                <TableCell>
                  { editUser && userIndex === item.lineNo
                  ? <TextField 
                      id="phone"
                      type="number"
                      variant="outlined" 
                      onInput={(e)=>{ 
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
                      }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={!user.phone ? "" : user.phone}
                    />
                  : item.phone
                  }
                </TableCell>
                <TableCell>
                  { editUser && userIndex === item.lineNo
                  ? <TextField 
                      id="emailAddress"
                      variant="outlined" 
                      inputProps={{ maxLength: 255 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={!user.emailAddress ? "" : user.emailAddress}
                    />
                  : item.emailAddress
                  }
                </TableCell>
                <TableCell>
                  { editUser && userIndex === item.lineNo
                  ? <TextField 
                      id="employeeId"
                      variant="outlined" 
                      inputProps={{ maxLength: 10 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={!user.employeeId ? "" : user.employeeId}
                    />
                  : item.employeeId
                  }
                </TableCell>
                <TableCell>
                  { editUser && userIndex === item.lineNo
                  ? <Select
                      name="role"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={user.roles === null ? "" : user.roles}
                      placeholder="Please select your role"
                    >
                      {rolesLookup && rolesLookup.map((item) => {
                        return <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>;
                      })} 
                    </Select>
                  : item.roles
                  }
                </TableCell>
                <TableCell>
                  { editUser && userIndex === item.lineNo
                  ? <Select
                      name="gender"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={user.gender === null ? "" : user.gender}
                      placeholder="Please select your gender"
                    >
                      {genderLookup && genderLookup.map((item) => {
                        return <MenuItem key={item.key} value={item.displayName}>{item.displayName}</MenuItem>;
                      })} 
                    </Select>
                  : item.gender
                  }
                </TableCell>
                <TableCell>
                  { editUser && userIndex === item.lineNo
                  ? <Select
                      name="workforceType"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={user.workforceType === null ? "" : user.workforceType}
                      placeholder="Select a type"
                    >
                      {workforceTypeLookup && workforceTypeLookup.map((item) => {
                        return <MenuItem key={item.key} value={item.displayName}>{item.displayName}</MenuItem>;
                      })} 
                    </Select>
                  : item.workforceType
                  }
                </TableCell>
                <TableCell>
                  { editUser && userIndex === item.lineNo
                  ? <Select
                      name="country"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={user.country === null ? "" : user.country}
                      placeholder="Select a country"
                    >
                      {countryLookup && countryLookup.map((item) => {
                        return <MenuItem key={item.key} value={item.countryName}>{item.countryName}</MenuItem>;
                      })} 
                    </Select>
                  : item.country
                  }
                </TableCell>
                <TableCell align="right">
                  { editUser && userIndex === item.lineNo
                  ? <React.Fragment>
                      <IconButton 
                        type="confirm"
                        onClick={() => handleButtonClick_confirm()}
                      />
                      <IconButton 
                        type="close"
                        onClick={() => resetState()}
                      />
                    </React.Fragment>
                  : <React.Fragment>
                      <IconButton
                        type="edit" 
                        onClick={() => handleButtonClick_edit(item.lineNo)}
                        disabled={(userIndex && userIndex !== item.lineNo)}
                      />
                      <IconButton 
                        type="delete"
                        onClick={() => handleModal_delete(item.lineNo)}
                        disabled={(userIndex && userIndex !== item.lineNo)}
                      />
                    </React.Fragment>
                  }
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this user? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning()}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title={errorMode + " Fields"}
        content={
          <React.Fragment>
            <Typography>Please check these fields again:</Typography>
            <Typography className={styles.errorList}>{errorFields.join(", ")}</Typography>
          </React.Fragment>
        }
        actions={
          <Button className={styles.button} onClick={() => handleModal_warning()}>OK</Button>
        }
      />
    </React.Fragment>
  );
}
