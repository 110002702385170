import { createSlice } from "@reduxjs/toolkit";

import { GetPalletManagement } from "services/UserPortal/AMRService";

// initial state
const initialState = {
  filter: {},
  result: [],
};

const amrPallet = createSlice({
  name: "amrPallet",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetPalletManagement.fulfilled, (state, action) => {
        state.result = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, updateExcessOrderRequest, reset } = amrPallet.actions;

// export the reducer
export default amrPallet.reducer;