import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { 
  GetAllSaleOrders, GetCustomerOrderBySaleOrderID, GetSaleOrderById,
  GetCustomerList, GetPONumberFromERP, GetProductList, GetAllUnitOfMeasurement, // lookups
  GetSaleOrderStatusLookUp, GetPurchaseOrderLookUp, GetSaleOrderLookUp, // filter
} from "services/UserPortal/CustomerOrderService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  orders: [],
  order: {subSaleOrders:[]},
  customerOrder: {},
  subSaleOrders: [{isWeightOrder: false}],
  // lookups
  customerList: [],
  poNumberList: [],
  productList: [],
  packageList: [],
  uomList: [],
  // filter
  saleOrder: [],
  purchaseOrder: [],
  saleOrderStatus: []
};

const managementCO = createSlice({
  name: "managementCO",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.customerOrder[key] = action.payload[key];
      })
    },
    updateProductRequest: (state, action) => {
      state.subSaleOrders = action.payload;
    },
    updatePackageRequest: (state, action) => {
      state.packageList = action.payload;
    },
    resetOrder: (state) => {
      state.customerOrder = {};
      state.subSaleOrders = [{}];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllSaleOrders.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.orders = action.payload.result.items;
      })
      .addCase(GetCustomerOrderBySaleOrderID.fulfilled, (state, action) => {
        state.customerOrder = _.omit(action.payload.result, ["saleOrder"]);
        state.subSaleOrders = action.payload.result.saleOrder.subSaleOrders;
      })
      .addCase(GetSaleOrderById.fulfilled, (state, action) => {
        state.order = action.payload.result;
      })
      .addCase(GetCustomerList.fulfilled, (state, action) => {
        state.customerList = action.payload.result;
      })
      .addCase(GetPONumberFromERP.fulfilled, (state, action) => {
        state.poNumberList = action.payload.result;
      })
      .addCase(GetProductList.fulfilled, (state, action) => {
        state.productList = action.payload.result;
      })
      .addCase(GetAllUnitOfMeasurement.fulfilled, (state, action) => {
        state.uomList = action.payload.result;
      })
      .addCase(GetSaleOrderStatusLookUp.fulfilled, (state, action) => {
        state.saleOrderStatus = action.payload.result;
      })
      .addCase(GetPurchaseOrderLookUp.fulfilled, (state, action) => {
        state.purchaseOrder = action.payload.result.sort();
      })
      .addCase(GetSaleOrderLookUp.fulfilled, (state, action) => {
        state.saleOrder = action.payload.result.sort();
      })
  },
});

// export actions
export const { setValues, updateRequest, updateProductRequest, 
  updatePackageRequest,
  resetOrder, reset } = managementCO.actions;

// export the reducer
export default managementCO.reducer;