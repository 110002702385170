import { createSlice } from "@reduxjs/toolkit";
import { GetWorkOrderCostByWorkOrderId, GetAllCostCategory, GetCostSettingLookUpByCostCategoryId } from "services/UserPortal/CostProductivityService";

// initial state
const initialState = {
  filter: {},
  workOrderCost: {},
  result: {},
  detail: {},
  //lookup
  costCategoryList: [],
  nameList: [],
};

const costOverview = createSlice({
  name: "costOverview",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.detail[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetWorkOrderCostByWorkOrderId.fulfilled, (state, action) => {
        state.workOrderCost = action.payload.result;
      })
      .addCase(GetAllCostCategory.fulfilled, (state, action) => {
        state.costCategoryList = action.payload.result;
      })
      .addCase(GetCostSettingLookUpByCostCategoryId.fulfilled, (state, action) => {
        state.nameList = action.payload.result;
      })
  },
});

// export actions
export const { reset, updateRequest, setValues } = costOverview.actions;

// export the reducer
export default costOverview.reducer;