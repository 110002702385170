import { createSlice } from "@reduxjs/toolkit";

import { GetEquipmentOverview, GetEquipmentList, GetAllFloorPlan, GetSectorByFloorId, GetEquipmentByFloorandSectorIdOrBankId, GetEquipmentLocationFloorAndSectorView, GetEquipmentLocationBankView } from "services/UserPortal/MonitoringService";

// initial state
const initialState = {
  //overview
  equipmentOverview: {},
  //location
  allFloorPlan: [],
  sector: [],
  equipment: [],
  equipLocation: {},
  
  //lookup
  equipmentList: [],
};

const equipmentMonitoring = createSlice({
  name: "equipmentMonitoring",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetEquipmentOverview.fulfilled, (state, action) => {
        state.equipmentOverview = action.payload.result;
      })
      .addCase(GetEquipmentList.fulfilled, (state, action) => {
        state.equipmentList = action.payload.result;
      })
      .addCase(GetAllFloorPlan.fulfilled, (state, action) => {
        state.allFloorPlan = action.payload.result;
      })
      .addCase(GetSectorByFloorId.fulfilled, (state, action) => {
        state.sector = action.payload.result;
      })
      .addCase(GetEquipmentByFloorandSectorIdOrBankId.fulfilled, (state, action) => {
        state.equipment = action.payload.result;
      })
      .addCase(GetEquipmentLocationFloorAndSectorView.fulfilled, (state, action) => {
        state.equipLocation = action.payload.result;
      })
      .addCase(GetEquipmentLocationBankView.fulfilled, (state, action) => {
        state.equipLocation = action.payload.result;
      })
  },
});

// export actions
export const { setValues, reset } = equipmentMonitoring.actions;

// export the reducer
export default equipmentMonitoring.reducer;