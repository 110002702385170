import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import _ from "lodash";
import { minDate } from "config";

import { GetAllDeviceControl, GetAllDeviceControlRule, GetAllDeviceUsageHistory, GetDeviceControlRule, GetAllFloorPlan } from "services/UserPortal/ClimateService";

// initial state
const initialState = {
  bays: [],
  rulesCount: 0,
  rules: [],
  historyCount: 0,
  history: [],
  deviceControlRule: {
    ruleList: [{
      startDate: moment().format("YYYY-MM-DD"), 
      endDate: moment().format("YYYY-MM-DD"),
      turnOnTime: moment().format("HH-mm"),
      turnOffTime: moment().format("HH-mm"),
    }]
  },
  floorPlan: {},
  floorSectorList: []
};

const deviceControl = createSlice({
  name: "deviceControl",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.deviceControlRule[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllDeviceControl.fulfilled, (state, action) => {
        if (action.payload.result.bank) {
          state.bays = action.payload.result.bank.bays;
        } else if (action.payload.result.floor) {
          state.floorPlan = action.payload.result.floor.sectors[0];
        }
      })
      .addCase(GetAllDeviceControlRule.fulfilled, (state, action) => {
        state.rulesCount = action.payload.result.totalCount;
        state.rules = action.payload.result.items;
      })
      .addCase(GetAllDeviceUsageHistory.fulfilled, (state, action) => {
        state.historyCount = action.payload.result.totalCount;
        state.history = action.payload.result.items;
      })
      .addCase(GetDeviceControlRule.fulfilled, (state, action) => {
        let payload = _.omit(action.payload.result,["ruleList"]);
        payload.ruleList = action.payload.result.ruleList.map((rule) => {
          const onTime = rule.turnOnTime.split("-");
          const offTime = rule.turnOffTime.split("-");
          rule.endDate = moment(rule.endDate).isAfter(minDate) ? moment(rule.endDate).format() : null;
          rule.onTime = moment().set({'hour': onTime[0], 'minute': onTime[1]});
          rule.offTime = moment().set({'hour': offTime[0], 'minute': offTime[1]});
          return rule;
        });
        state.deviceControlRule = payload;
      })
      .addCase(GetAllFloorPlan.fulfilled, (state, action) => {
        let arr = [];
        if (action.payload.result) {
          for (let obj of action.payload.result.items) {
            for (let i of obj.sectors) {
              arr.push({name: "Floor "+obj.floorNumber+" Sector "+i.sectorNumber, floor: obj.id, sector: i.id});
            }
          }
        }
        state.floorSectorList = _.sortBy(arr, 'name');
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = deviceControl.actions;

// export the reducer
export default deviceControl.reducer;