import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @mui/material components
import Card from "@mui/material/Card";
// import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import {loginRoutes as routes} from "routes/loginRoutes.js";

import logo from "assets/logo/logo-green.svg";
import styles from "assets/jss/layouts/form.module.scss";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/account") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    {localStorage.getItem("accessToken") 
    ? <Redirect from="/" to="/user/home" />
    : <Redirect from="/" to="/account/request_reset_password" />}
  </Switch>
);

export default function Form() {
  
  return (
    <div className={styles.wrapper}>
      <Card className={styles.container}>
        <div>
          <img className={styles.logo} src={logo} alt="greenphyto-logo" />
        </div>
        <CardContent className={styles.content}>{switchRoutes}</CardContent>
      </Card>
    </div>
  );
}
