import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import { useInterval } from 'common/utils';
import { reset } from "../store/chart";
import { ConvertkWhToCO2, GetAllSellingPrice } from "services/UserPortal/SustainabilityService";
import MonthlyChart from "./MonthlyChart";
import EmissionChart from "./EmissionChart";
import ScopeChart from "./ScopeChart";
import RECChart from "./RECChart";
import FinancialChart from "./FinancialChart";

export default function Chart() {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState(0);
  const [date, setDate] = React.useState({startDate: moment().startOf('month').format('YYYY-MM-DD'), endDate: moment().startOf('month').add(1, 'y').format('YYYY-MM-DD')});

  const handleOnChange_tab = (e, value) => {
    setTab(value);
  }

  const getAllSustainability = (stopLoading) => {
    dispatch(ConvertkWhToCO2({date, stopLoading}));
    dispatch(GetAllSellingPrice({date, stopLoading}));
  }

  React.useEffect(() => {
    if (date.startDate && date.endDate) {
      getAllSustainability();
    }
  },[date]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllSustainability(true);
  });

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <MonthlyChart date={date} setDate={setDate} />
      case 1:
        return <EmissionChart date={date} setDate={setDate} />
      case 2:
        return <ScopeChart date={date} setDate={setDate} />
      case 3:
        return <RECChart date={date} setDate={setDate} />
      case 4:
        return <FinancialChart date={date} setDate={setDate} />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title = "Sustainability Chart"
        subheader={
          <React.Fragment>
            Definition of year starts from May to May
            <Tabs 
              // variant="scrollable"
              tabs={["Monthly Energy Consumption and Emission", "Emissions Breakdown", "Scope Emissions Overview", "Renewable Energy Certificates (RECs)"]} //, "Financial Savings"
              value={tab} 
              onChange={handleOnChange_tab}
            />
          </React.Fragment>
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
