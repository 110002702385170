import { createAsyncThunk } from "@reduxjs/toolkit";
import { find, omit, pickBy, identity } from "lodash";
import moment from "moment";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

// Device Control
export const GetAllDeviceControl = createAsyncThunk(
  "climate/getAllDeviceControl", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetAllDeviceControl", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetControlMode = createAsyncThunk(
  "climate/getControlMode", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetControlMode", {params, stopLoading: true, isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SetControlMode = createAsyncThunk(
  "climate/setControlMode", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/userportal/ClimateEnvironment/SetControlMode", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllDeviceControlRule = createAsyncThunk(
  "climate/getAllDeviceControlRule", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetAllDeviceControlRule", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeviceControlRule = createAsyncThunk(
  "climate/getDeviceControlRule", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetDeviceControlRule", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateDeviceControlRule = createAsyncThunk(
  "climate/createOrUpdateDeviceControlRule", 
  async (param, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const deviceControlRule = getState().user.climate.deviceControl.deviceControlRule;
    const body = {
      ...param,
      ...omit(deviceControlRule,["ruleList"]),
      ruleList: deviceControlRule.ruleList.map((rule) => pickBy(rule, identity)),
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/userportal/ClimateEnvironment/CreateOrUpdateDeviceControlRule", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteDeviceControlRule = createAsyncThunk(
  "climate/deleteDeviceControlRule", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/userportal/ClimateEnvironment/DeleteDeviceControlRule", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SendControlCommandToDevice = createAsyncThunk(
  "climate/sendControlCommandToDevice", 
  async (params, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/SendControlCommandToDevice", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SendControlCommandToDevices = createAsyncThunk(
  "climate/sendControlCommandToDevices", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/ClimateEnvironment/SendControlCommandToDevices", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllDeviceUsageHistory = createAsyncThunk(
  "climate/getAllDeviceUsageHistory", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetAllDeviceUsageHistory", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadDeviceUsageHistory = createAsyncThunk(
  "climate/downloadDeviceUsageHistory", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const config = {
      responseType: 'blob',
      params: {
        ...param,
        FarmId: find(userDetail.farms, "isActive").id,
        MaxResultCount: 9999
      }, 
      stopLoading: true
    }
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/DownloadDeviceUsageHistory", config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateDeviceCO2CanisterDate = createAsyncThunk(
  "climate/UpdateDeviceCO2CanisterDate", 
  async (data, {rejectWithValue}) => {
    const body = data;
    try {
      const response = await FomsMS.post("/userportal/ClimateEnvironment/UpdateDeviceCO2CanisterDate", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Climate Monitoring
export const GetClimateMonitoring = createAsyncThunk(
  "climate/getClimateMonitoring", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "deviceIds") {
        param[key].map((item) => params.append("deviceIds", item));
      } else {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    params.append("includeDetailRead", moment(param.endDate).diff(moment(param.startDate), 'days') <= 7);
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetClimateMonitoring", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetClimateMonitoringHeatMap = createAsyncThunk(
  "climate/getClimateMonitoringHeatMap", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "deviceIds") {
        param[key].map((item) => params.append("deviceIds", item));
      } else {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    params.append("includeDetailRead", moment(param.endDate).diff(moment(param.startDate), 'days') <= 7);
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetClimateMonitoringHeatMap", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadClimateMonitoring = createAsyncThunk(
  "climate/downloadClimateMonitoring", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "deviceIds") {
        param[key].map((item) => params.append("deviceIds", item));
      } else {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    params.append("includeDetailRead", moment(param.endDate).diff(moment(param.startDate), 'days') <= 7);
    params.append("stopLoading", true);
    const config = {
      responseType: 'blob',
      params
    }
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/DownloadClimateMonitoring", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeviceUnit = createAsyncThunk(
  "climate/getDeviceUnit", 
  async (deviceId, {rejectWithValue}) => {
    const params = {deviceId};
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetDeviceUnit", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeviceUnits = createAsyncThunk(
  "climate/getDeviceUnits", 
  async (id, {rejectWithValue}) => {
    const params = {
      deviceFunctionId: id
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetDeviceUnits", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Sensor Control
export const UpdateSensorControlConfiguration = createAsyncThunk(
  "climate/updateSensorControlConfiguration", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.climate.sensorControl.libraries;
    try {
      const response = await FomsMS.post("/userportal/ClimateEnvironment/UpdateSensorControlConfiguration", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllSensorControlMapping = createAsyncThunk(
  "climate/getAllSensorControlMapping", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      MaxResultCount: 9999,
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetAllSensorControlMapping", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateSensorControlMapping = createAsyncThunk(
  "climate/createOrUpdateSensorControlMapping", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const sensorControlMappingList = getState().user.climate.sensorControl.config;
    const body = {
      FarmId: find(userDetail.farms, "isActive").id,
      sensorControlMappingList
    };
    try {
      const response = await FomsMS.post("/userportal/ClimateEnvironment/CreateOrUpdateSensorControlMapping", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Sensor Setting
export const GetAllSensorSetting = createAsyncThunk(
  "climate/getAllSensorSetting", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetAllSensorSetting", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSensorSetting = createAsyncThunk(
  "climate/getSensorSetting", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetSensorSetting", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteSensorSetting = createAsyncThunk(
  "climate/deleteSensorSetting", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/userportal/ClimateEnvironment/DeleteSensorSetting", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateSensorSetting = createAsyncThunk(
  "climate/createOrUpdateSensorSetting", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const sensorSetting = getState().user.climate.sensorSettings.sensorSetting;
    const body = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...sensorSetting
    };
    try {
      const response = await FomsMS.post("/userportal/ClimateEnvironment/CreateOrUpdateSensorSetting", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllFloorPlan = createAsyncThunk(
  "climate/getAllFloorPlan", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetAllFloorPlan", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchSensorSetting = createAsyncThunk(
  "climate/searchSensorSetting", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/SearchSensorSetting", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchDeviceControl = createAsyncThunk(
  "climate/searchDeviceControl", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/SearchDeviceControl", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);