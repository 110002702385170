// Create Edit Device
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { reset } from "./store/device";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setDirty } from "store/general";
import { GetDevice } from "services/AdminPortal/DeviceService";

import DetailForm from "./DetailForm";
import MappingForm from "./MappingForm";
import TestingForm from "./TestingForm";
import HierarchyForm from "./HierarchyForm";

export default function AddEditDevice(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const [step, setStep] = React.useState(0);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isChanged, setIsChanged] = React.useState(false);

  // componentDidMount
  React.useEffect(() => {
    const prop = props.location.state;
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "device");
    if (path[index+1] != "create") {
      setIsEdit(true);
      dispatch(GetDevice(path[index+1]))
      .then(({error}) => {
        if (error) {
          history.push((prop && prop.prevPath) || "/admin/device");
        } else {
          dispatch(setDirty(true));
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(reset());
      dispatch(resetNav());
    }
  },[]);

  const renderForm = () => {
    switch(step) {
      case 0:
        return <DetailForm step={step} setStep={setStep} isChanged={isChanged} setIsChanged={setIsChanged} isEdit={isEdit} />
      case 1:
        return <MappingForm step={step} setStep={setStep} />
      case 2:
        return <TestingForm step={step} setStep={setStep} />
      case 3:
        return <HierarchyForm step={step} setStep={setStep} />
    }
  }
  
  return (
    <React.Fragment>
      {renderForm()}
    </React.Fragment>
  )
}
