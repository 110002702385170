import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllProductCategory, GetProductCategoryByID, GetAllProductGroup, GetProductGroupByCategoryID, 
  GetProductRawMaterial, GetProductEquipment, GetTaskTypeList, GetEquipmentList, GetEquipmentTaskTypeList,
  GetFinishedProductForMix, GetProcessMasterList, GetUserList, GetAllPackagingType } from "services/AdminPortal/ProductService";

// initial state
const initialState = {
  allProductCategory: [],
  allProductCategoryType: [],
  allProductGroup: [],
  productGroup: [],
  rawMaterialList: [],
  equipmentList: [],
  taskTypeList: [],
  taskEquipmentList: [],
  equipmentTaskTypeList: [],
  processMasterList: [],
  finishedCategoryTypeList: [],
  processNameArray: [],
  userList: [],
  packageTypeList: []
};

const productLookup = createSlice({
  name: "productLookup",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    setCombinedProcessName: (state, action) => {
      state.processNameArray = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllProductCategory.fulfilled, (state, action) => {
        state.allProductCategory = action.payload.result.productCategoryList.items;
      })
      .addCase(GetProductCategoryByID.fulfilled, (state, action) => {
        state.allProductCategoryType = action.payload.result.productCategoryType;
      })
      .addCase(GetAllProductGroup.fulfilled, (state, action) => {
        state.allProductGroup = action.payload.result.items;
      })
      .addCase(GetProductGroupByCategoryID.fulfilled, (state, action) => {
        state.productGroup = action.payload.result;
      })
      .addCase(GetProductRawMaterial.fulfilled, (state, action) => {
        state.rawMaterialList = action.payload.result;
      })
      .addCase(GetProductEquipment.fulfilled, (state, action) => {
        state.equipmentList = action.payload.result;
      })
      .addCase(GetTaskTypeList.fulfilled, (state, action) => {
        state.taskTypeList = action.payload.result;
      })
      .addCase(GetEquipmentList.fulfilled, (state, action) => {
        state.taskEquipmentList = action.payload.result;
      })
      .addCase(GetEquipmentTaskTypeList.fulfilled, (state, action) => {
        state.equipmentTaskTypeList = action.payload.result;
      })
      .addCase(GetFinishedProductForMix.fulfilled, (state, action) => {
        state.finishedCategoryTypeList = action.payload.result;
      })
      .addCase(GetProcessMasterList.fulfilled, (state, action) => {
        state.processMasterList = action.payload.result;
      })
      .addCase(GetUserList.fulfilled, (state, action) => {
        state.userList = _.sortBy(action.payload.result, ["userName"]);
      })
      .addCase(GetAllPackagingType.fulfilled, (state, action) => {
        state.packageTypeList = _.sortBy(action.payload.result.items, ["packageType"]);
      })
  },
});

// export actions
export const { setValues, setCombinedProcessName, reset } = productLookup.actions;

// export the reducer
export default productLookup.reducer;