import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
// @mui/material
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Modal from "shared-components/Modal/Modal";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import { reportMissingTableHead, reportMissingDetailTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/report";
import { GetPlanningReportByDateList, RejectRawMaterialRequest } from "services/UserPortal/PlanningService";

import alert from "assets/icons/orange/alert-line.svg";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/planning.module.scss";

export default function MissingTable(props) {
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.planning.report.missingCount);
  const result = useSelector(store => store.user.planning.report.missing);
  const rawMaterial = useSelector(store => store.user.planning.report.rawMaterial);
  const [rejectId, setRejectId] = React.useState('asc');
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('rawMaterialRefNo');
  const [page, setPage] = React.useState(0);
  const [subOrder, setSubOrder] = React.useState('asc');
  const [subOrderBy, setSubOrderBy] = React.useState('purchaseOrderNumber');
  const [subPage, setSubPage] = React.useState(0);
  const [openRejectModal, setOpenRejectModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  
  const count = Math.ceil(totalCount / rowsPerTable);
  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  
  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleSubRequestSort = (e, property) => {
    const isAsc = subOrderBy === property && subOrder === 'asc';
    setSubOrder(isAsc ? 'desc' : 'asc');
    setSubOrderBy(property);
  };

  const handleButtonClick_checkbox = (e, rm) => {
    let payload = _.cloneDeep(rawMaterial);
    const filteredRMIndex = payload.findIndex(({rawMaterialId}) => rawMaterialId==rm.rawmaterialId);
    if (filteredRMIndex != -1) { // RM alr selected
      const index = _.indexOf(payload[filteredRMIndex].idList, rm.id);
      if (index != -1) {  // sub RM alr selected
        if (payload[filteredRMIndex].idList.length === 1) {
          payload.splice(filteredRMIndex, 1);
        } else {
          payload[filteredRMIndex].idList.splice(index, 1);
          payload[filteredRMIndex].oldQuantity -= rm.quantityRequired;
        }
      } else {  // sub RM not selected
        payload[filteredRMIndex].idList.push(rm.id);
        payload[filteredRMIndex].oldQuantity += rm.quantityRequired;
      }
    } else {  // new RM
      payload = _.unionBy(payload, [{rawMaterialId: rm.rawmaterialId, rawMaterialRefNo: rm.rawMaterialRefNo, uom: rm.uom, farmId: _.find(userDetail.farms, "isActive").id, oldQuantity: rm.quantityRequired, idList: [rm.id]}], "rawMaterialId");
    }
    dispatch(setValues({rawMaterial: payload}));
  }

  const handleButtonClick_checkAll = (e, rawMaterialList) => {
    let payload = _.cloneDeep(rawMaterial);
    const filteredRM = payload.find(({rawMaterialId}) => rawMaterialId==e.target.value);
    if (filteredRM && filteredRM.idList.length === rawMaterialList.filter(({reject}) => reject === "Reject").length) {  // uncheck all
      payload = _.reject(payload, ({rawMaterialId}) => rawMaterialId == e.target.value);
    } else {  // check all
      const rm = rawMaterialList[0];
      let oldQuantity = 0;
      let idList = [];
      for (let i = 0; i < rawMaterialList.length; i++) {
        const item = rawMaterialList[i];
        if (item.reject === "Reject") {
          oldQuantity+=item.quantityRequired;
          idList = _.union(idList,[item.id]);
        }
      }
      payload = _.unionBy(payload, [{rawMaterialId: rm.rawmaterialId, rawMaterialRefNo: rm.rawMaterialRefNo, uom: rm.uom, farmId: _.find(userDetail.farms, "isActive").id, oldQuantity, idList}], "rawMaterialId");
    }
    dispatch(setValues({rawMaterial: payload}));
  }

  const handleModal_reject = (id) => {
    setRejectId(id);
    setOpenRejectModal(!openRejectModal);
  }

  const handleButtonClick_reject = () => {
    dispatch(RejectRawMaterialRequest(rejectId))
    .then(() => {
      setPage(0);
      getPlanningReportByDateList();
      setOpenRejectModal(false);
    });
  }

  const getPlanningReportByDateList = (stopLoading) => {
    if (props.state && props.state.date) {
      dispatch(GetPlanningReportByDateList({page, date: moment(props.state.date).format("YYYY-MM-DD"), stopLoading}))
      .then((response) => {
        const payload = response.payload.result.missingRawMaterialList;
        if (payload) {
          dispatch(setValues({missingCount: payload.totalCount, missing: payload.items}));
        }
      });
    }
  }

  React.useEffect(() => {
    setSubPage(0);
  },[open]);


  React.useEffect(() => {
    getPlanningReportByDateList();
    setOpen(false);
  },[page]);

  useInterval(() => {
    getPlanningReportByDateList(true);
  });

  return (
    <React.Fragment>
      <Table 
        className={styles.mainTable}
        header={reportMissingTableHead}
        // order={order}
        // orderBy={orderBy}
        // onRequestSort={handleRequestSort}
      >
        { result.map((item,index) => {
          const subCount = Math.ceil(item.rawMaterialListDtos.length / rowsPerTable);
          const rm = rawMaterial.find(({rawMaterialId}) => rawMaterialId==item.rawmaterialId);
          return (
            <React.Fragment key={index}>
              <TableRow
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => open === index? setOpen(false) : setOpen(index)}
              >
                <TableCell>
                  <IconButton 
                    className={open === index ? styles.rotate : null}
                    onClick={() => open === index? setOpen(false) : setOpen(index)}
                  >
                    <img className={styles.icon} src={arrow} alt="arrow" />
                  </IconButton>
                </TableCell>
                <TableCell>{item.rawMaterialListDtos.length ? item.rawMaterialListDtos[0].rawMaterialName : ""}</TableCell>
                <TableCell>{item.rawMaterialRefNo}</TableCell>
                <TableCell>{formatNumbers(roundTo2Decimal(item.quantityRequired))}{item.uom}</TableCell>
                <TableCell>
                  <Checkbox 
                    value={item.rawmaterialId}
                    checked={Boolean(rm && rm.idList && rm.idList.length === item.rawMaterialListDtos.filter(({reject})=>reject==="Reject").length)}
                    indeterminate={Boolean(rm && rm.idList && rm.idList.length && rm.idList.length !== item.rawMaterialListDtos.filter(({reject})=>reject==="Reject").length)}
                    onChange={(e) => handleButtonClick_checkAll(e, item.rawMaterialListDtos)}
                    disabled={item.rawMaterialListDtos.filter(({reject})=>reject!=="Reject").length === totalCount}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                { open===index && 
                  <TableCell className="pt-0 pb-0" colSpan={7}>
                    <Collapse in={open === index}>
                      <Table
                        className={styles.subTable}
                        header={reportMissingDetailTableHead}
                        order={subOrder}
                        orderBy={subOrderBy}
                        onRequestSort={handleSubRequestSort}
                      >
                        { _.orderBy(item.rawMaterialListDtos, [subOrderBy], [subOrder])
                        .slice(subPage * rowsPerTable, subPage * rowsPerTable + rowsPerTable)
                        .map((subItem, subIndex) => {
                          return (
                            <React.Fragment key={subIndex}>
                              <TableRow>
                                <TableCell>{subItem.subSaleOrderNumber}</TableCell>
                                <TableCell>{formatNumbers(roundTo2Decimal(subItem.quantityRequired))}{subItem.uom}</TableCell>
                                <TableCell>
                                  <Checkbox 
                                    id="id"
                                    value={subItem.id}
                                    checked={Boolean(rm && rm.idList && rm.idList.find((id) => id==subItem.id))}
                                    onChange={(e) => handleButtonClick_checkbox(e, subItem)}
                                    disabled={subItem.reject !== "Reject"}
                                  />
                                </TableCell>
                                <TableCell>
                                  <span className={subItem.reject==="Reject" ? styles.link : null} onClick={()=>subItem.reject==="Reject" && handleModal_reject(subItem.id)}>
                                    {subItem.reject}
                                  </span>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          )
                        })}
                      </Table>
                      { subCount > 1 &&
                        <Pagination 
                          count={subCount} 
                          page={subPage+1}
                          onChange={(e, pageNo)=>setSubPage(pageNo-1)}
                        />
                      }
                    </Collapse>
                  </TableCell>
                }
              </TableRow>
            </React.Fragment>
          )
        })}
      </Table>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
        />
      }
      <Modal
        open={openRejectModal}
        onClose={() => handleModal_reject(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Are you sure you want to reject this? This action cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_reject(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_reject()}>Reject</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
