import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// core components
import Card from "shared-components/Card/Card";
import CustomIconButton from "shared-components/Button/IconButton";

import { setDirty, setError } from "store/general";
import { updateRequest } from "./store/vegetable";
import { CreateOrUpdateRandDReport, UploadQualityControlImageList } from "services/UserPortal/QualityControlService";

import styles from "assets/jss/components/UserPortal/qualityControl.module.scss";

export default function UploadImage(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const inputFile = React.createRef();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const qualityControl = useSelector(store => store.user.rnd.vegetable.qualityControl);
  const [imageFile, setImageFile] = React.useState(qualityControl.qualityControlImages ? new Array(qualityControl.qualityControlImages.length).fill(null) : []);

  const workOrder = _.find(workOrderList, ({id}) => qualityControl.workOrderId == id)

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    maxSize: 2000000,
    onDrop: (files, rejectedFiles) => {
      setImageFile(files);
      const payload = _.map(files, (item) => {
        return {
          imageUrl: URL.createObjectURL(item),
          fileName: item.name,
          isNew: true
        };
      });
      dispatch(updateRequest({qualityControlImages : payload}));
      rejectedFiles.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            dispatch(setError(`Error: ${err.message}`));
          }

          if (err.code === "file-invalid-type") {
            dispatch(setError(`Error: ${err.message}`));
          }
        });
      });
    }
  });

  const onButtonClick_image = () => {
    inputFile.current.click();
  }
  
  const handleOnChange_image = (e) => {
    const payload = _.compact(_.map(e.target.files, (item) => {
      if (item.size > 2000000) {
        dispatch(setError('Error: File is larger than 2000000 bytes'));
        return null;
      } else {
        return {imageUrl: URL.createObjectURL(item), fileName: item.name, isNew: true};
      }
    }));
    let currentImages = qualityControl.qualityControlImages ? _.cloneDeep(qualityControl.qualityControlImages) : []
    dispatch(updateRequest({qualityControlImages : [...currentImages, ...payload]}));
    setImageFile([...imageFile, ...e.target.files]);
  }

  const handleButtonClick_deleteImage = (index) => {
    if (qualityControl.qualityControlImages[index].id){
      let deleted = _.cloneDeep(qualityControl.qualityControlImages);
      deleted[index].isDeleted = true;
      dispatch(updateRequest({qualityControlImages: deleted}));
      setImageFile(imageFile.filter((n, i) => i !== index ));
    }
    else {
      let deleted = _.cloneDeep(qualityControl.qualityControlImages);
      deleted.splice(index, 1);
      dispatch(updateRequest({qualityControlImages: deleted}));
      setImageFile(imageFile.filter((n, i) => i !== index ));
    }
  }

  const handleOnClick_save = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      const files = imageFile.filter((item) => item != null);
      Promise.all([
        files.length && dispatch(UploadQualityControlImageList(files)),
        (props.hcImages && props.hcImages.length) && dispatch(UploadQualityControlImageList(props.hcImages))
      ])
      .then((response) => {
        if (!response[0].error && !response[1].error) {
          let qc = _.cloneDeep(qualityControl);
          let qcImages = _.cloneDeep(qualityControl.qualityControlImages);
          let hc = _.cloneDeep(qualityControl.healthConditions);
          qcImages = _.union(qcImages ? _.filter(qcImages, ({isNew}) => !isNew) : [], response[0].payload ? response[0].payload.result : []);
          if (hc && response[1]) {
            hc = hc.map((item) => {
              const imageIndex = response[1].payload.result.findIndex(({imageLocation}) => imageLocation == item.healthConditionName);
              if (imageIndex !== -1) {
                item.imageUrl = response[1].payload.result[imageIndex].imageUrl;
              }
              return item;
            })
          }
          qc.qualityControlImages = qcImages;
          qc.healthConditions = hc;
          dispatch(CreateOrUpdateRandDReport(qc))
          .then(({error}) => {
            if (!error) {
              history.push("/user/r&d");
            } else {
              dispatch(updateRequest({qualityControlImages: qcImages, healthConditions: hc}));
            }
          });
        }
      })
    })
  }
  
  return (
    <React.Fragment>
      <Card 
        title = "Add New Photo - Quality Control Entries"
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>props.setStep(0)}
            >
              Go Back
            </Button>
            <Button 
              className={styles.button}
              onClick={() => handleOnClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Typography className={styles.formTitle}>Work Order: {workOrder && workOrder.workOrderNo}</Typography>
        <input 
          ref={inputFile} 
          style={{"display": "none"}} 
          type="file" 
          accept="image/*"
          onChange={(e) => handleOnChange_image(e)}
          multiple
        />
        <Button
          className={styles.buttonSecondary}
          startIcon={<AddIcon />}
          onClick={()=>onButtonClick_image()}
        >
          Upload Image File(s)
        </Button>
        {(qualityControl.qualityControlImages && _.filter(qualityControl.qualityControlImages, ({isDeleted}) => !isDeleted).length) 
        ? <React.Fragment>
            <Typography className={styles.formTitle}>Photos Attached:</Typography>
            <div className={styles.imageContainer}>
              <div className={clsx(styles.imageList, styles.leftContainer)}> 
                {qualityControl.qualityControlImages && qualityControl.qualityControlImages.map((item, index) => {
                  if (!item.isDeleted) {
                    return (
                      <div className={styles.imageNameList} key={index}>
                        <Typography className={styles.imageName}>{item.fileName}</Typography>
                        <div>
                          <CustomIconButton
                            type = "delete"
                            onClick={()=>handleButtonClick_deleteImage(index)}
                          />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
              <Grid container className={styles.imageList}>
                {qualityControl.qualityControlImages && qualityControl.qualityControlImages.map((item, index) => {
                  if (!item.isDeleted) {
                    return (
                      <Grid className={styles.image} item xs={4} key={index}>
                        <Button className={styles.deleteImage} onClick={()=>handleButtonClick_deleteImage(index)}><CloseIcon /></Button>
                        <img src={item.imageUrl} className={styles.imageBorder} />
                      </Grid>
                    )
                  }
                })}
              </Grid>
            </div>
          </React.Fragment>
        : <div {...getRootProps({className: isDragActive ? clsx(styles.dropZone, styles.activeDropZone) : styles.dropZone})}>
            <input {...getInputProps()} />
            <Typography className={styles.emptyLabel}>No Image Uploaded</Typography>
            <Typography className={styles.emptyLabel}>Drag & drop image file(s) here, or click to upload image file(s)</Typography>
          </div>
        }
      </Card>
    </React.Fragment>
  );
}
