import { createSlice } from "@reduxjs/toolkit";

import { ExecuteGetAllRobots, ExecuteGetAllMission, ExecuteGetMapByName, GetUpcomingMissions } from "services/UserPortal/AMRService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  result: [],
  upcoming: [],
  task: {},
  nodeList: [],
  //lookup
  amrList: [],
  cageList: [],
  actionList: []
};

const amrAdHoc = createSlice({
  name: "amrAdHoc",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.task[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(ExecuteGetAllRobots.fulfilled, (state, action) => {
        state.amrList = action.payload.result;
      })
      .addCase(ExecuteGetAllMission.fulfilled, (state, action) => {
        state.result = action.payload.result;
      })
      .addCase(GetUpcomingMissions.fulfilled, (state, action) => {
        state.upcoming = action.payload.result;
      })
      .addCase(ExecuteGetMapByName.fulfilled, (state, action) => {
        state.nodeList = action.payload.result.nodes;
        state.actionList = action.payload.result.actions;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = amrAdHoc.actions;

// export the reducer
export default amrAdHoc.reducer;