import { combineReducers } from "redux";
import finishedGoodsIn from "./in";
import finishedGoodsOut from "./out";
import finishedGoodsMaster from "./master";
import disposal from "./disposal";
import doCreation from "./doCreation";
import search from "./search";

const finishedGoodsReducer = combineReducers({
  finishedGoodsIn,
  finishedGoodsOut,
  finishedGoodsMaster,
  disposal,
  doCreation,
  search,
});

export default finishedGoodsReducer;