import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetProfitAndLoss, GetSubSaleOrderLookup, GetCustomerLookUp, 
  GetCostingBySaleOrder, GetCostingbyProduct, GetTotalCostByCustomer,
  GetTotalRevenuebySaleOrder, GetTotalRevenueByProduct, GetTotalRevenueByCustomer } from "services/UserPortal/CostProductivityService";

// initial state
const initialState = {
  totalCount: 0,
  reports: [],
  profitLoss: {},
  // lookup
  subSaleOrderList: [],
  customerList: []
};

const cpReport = createSlice({
  name: "cpReport",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetProfitAndLoss.fulfilled, (state, action) => {
        state.profitLoss = action.payload.result;
      })
      .addCase(GetSubSaleOrderLookup.fulfilled, (state, action) => {
        state.subSaleOrderList = _.orderBy(action.payload.result,["subSaleOrderNumber"], ['desc']);
      })
      .addCase(GetCustomerLookUp.fulfilled, (state, action) => {
        state.customerList = _.orderBy(action.payload.result, ["customerName"]);
      })
      .addCase(GetCostingBySaleOrder.fulfilled, (state, action) => {
        state.reports = action.payload.result;
      })
      .addCase(GetCostingbyProduct.fulfilled, (state, action) => {
        state.reports = action.payload.result;
      })
      .addCase(GetTotalCostByCustomer.fulfilled, (state, action) => {
        state.reports = action.payload.result;
      })
      .addCase(GetTotalRevenuebySaleOrder.fulfilled, (state, action) => {
        state.reports = action.payload.result;
      })
      .addCase(GetTotalRevenueByProduct.fulfilled, (state, action) => {
        state.reports = action.payload.result;
      })
      .addCase(GetTotalRevenueByCustomer.fulfilled, (state, action) => {
        state.reports = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, updateExclusionRequest, reset } = cpReport.actions;

// export the reducer
export default cpReport.reducer;