import { createSlice } from "@reduxjs/toolkit";

import { GetAllSensorControlDeviceFunction, GetDeviceList } from "services/UserPortal/CommonLookupService";

// initial state
const initialState = {
  deviceCount: 0,
  functionCount: 0,
};

const lookupClimate = createSlice({
  name: "lookupClimate",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllSensorControlDeviceFunction.fulfilled, (state, action) => {
      state.functionCount = action.payload.result.length;
    })
      .addCase(GetDeviceList.fulfilled, (state, action) => {
      state.deviceCount = action.payload.result.totalCount;
    })
  },
});

// export actions
export const { setValues, reset } = lookupClimate.actions;

// export the reducer
export default lookupClimate.reducer;