// Create Edit Device Tagging
// Stepper
import React from "react";
// core components
import Stepper from "shared-components/Stepper/Stepper";

import { farmConfig } from "enums/Constants";

export default function DeviceTaggingStepper(props) {

  const deviceTaggingSteps = ['Add in Details of the Farm', 'Upload Floor Plans for each floor', 'Add in '+farmConfig.bank+' Information', 'Tag Devices to '+farmConfig.bank+' / Floor Plan'];

  return (
    <Stepper 
      steps={deviceTaggingSteps}
      activeStep={props.activeStep}
    />
  )
}
