import { createSlice } from "@reduxjs/toolkit";

import { SearchRawMaterial, SearchRawMaterialBatch, SearchRawMaterialCost, SearchRawMaterialReturn, SearchRawMaterialReconciliation } from "services/UserPortal/RawMaterialService";

// initial state
const initialState = {
  rmTotalCount: 0,
  batchTotalCount: 0,
  reservedTotalCount: 0,
  issuedTotalCount: 0,
  requestTotalCount: 0,
  receivedTotalCount: 0,
  costTotalCount: 0,
  returnTotalCount: 0,
  reconciliationTotalCount: 0,
  rmResults: [],
  batchResults: [],
  reservedResults: [],
  issuedResults: [],
  requestResults: [],
  receivedResults: [],
  costResults: [],
  returnResults: [],
  reconciliationResults: [],
};

const searchRM = createSlice({
  name: "searchRM",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SearchRawMaterial.fulfilled, (state, action) => {
        state.rmTotalCount = action.payload.result.rawMaterialFinishList && action.payload.result.rawMaterialFinishList.totalCount;
        state.rmResults = action.payload.result.rawMaterialFinishList && action.payload.result.rawMaterialFinishList.items;
      })
      .addCase(SearchRawMaterialBatch.fulfilled, (state, action) => {
        state.batchTotalCount = action.payload.result.totalCount;
        state.batchResults = action.payload.result.items;
      })
      .addCase(SearchRawMaterialCost.fulfilled, (state, action) => {
        state.costTotalCount = action.payload.result.totalCount;
        state.costResults = action.payload.result.items;
      })
      .addCase(SearchRawMaterialReturn.fulfilled, (state, action) => {
        state.returnTotalCount = action.payload.result.totalCount;
        state.returnResults = action.payload.result.items;
      })
      .addCase(SearchRawMaterialReconciliation.fulfilled, (state, action) => {
        state.reconciliationTotalCount = action.payload.result.totalCount;
        state.reconciliationResults = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, reset } = searchRM.actions;

// export the reducer
export default searchRM.reducer;