import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import _ from "lodash";
// @mui/material components
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import { fixedProcessList } from "enums/Constants";

import styles from "assets/jss/shared-components/timeline.module.scss";

export default function RecipeTimeline({value, pathname, prevPath, ...props}) {
  const tableRef = React.useRef(null);
  const [noOfDays, setNoOfDays] = React.useState(0);
  const [maxPreDays, setMaxPreDays] = React.useState(0);

  const renderHeader = () => {
    let cells = [];
    
    for (let i = 0; i < noOfDays; i++) {
      if (i === noOfDays-1) {
        cells.push(<TableCell key={i} className={clsx(props.classes.header, styles.tableCell)} align="right">Day {i+1}</TableCell>);
      } else if (i % 3 === 0) {
        cells.push(<TableCell key={i} className={clsx(props.classes.header, styles.tableCell)} align="left">Day {i}</TableCell>);
      } else {
        cells.push(<TableCell key={i} className={clsx(props.classes.header, styles.tableCell)} />)
      }
    }
    return cells
  }

  const renderTable = (processList, startDay) => {
    let cells = [];
    if (startDay) {
      cells.push(<TableCell colSpan={startDay} />);
    }
    for (let i = 0; i < processList.length; i++) {
      const process = fixedProcessList.find(({processName}) => processName === processList[i].process);
      cells.push(
        <TableCell 
          className={styles.tableCell}
          key={i}
          colSpan={processList[i].days}
        >
          <Link to={props.disabled?"#":{pathname, state: {prevPath}}}>
            <div className={clsx(styles.chip, styles[process.code])} disabled={props.disabled}>
              <span className={styles.rowTitle}>{processList[i].process} | {processList[i].days} Days</span>
            </div>
          </Link>
        </TableCell>
      );
    }
    return cells;
  }
  
  React.useEffect(() => {
    tableRef.current.scrollTo(0, 0);
    let totalDays = _.max(_.map(value.preHarvestTimeLine, (process) => process.processList.reduce((a, {days}) => a + days, 0)));
    if (value.isMix) {
      setMaxPreDays(totalDays);
    }
    totalDays += _.map(value.postHarvestTimeLine, (process) => process.processList.reduce((a, {days}) => a + days, 0)).reduce((a, b) => a + b, 0);
    setNoOfDays(totalDays);
  },[value]);
  
  return (
    <div className={styles.recipeTable} ref={tableRef}>
      <Table>
        {!_.isEmpty(value) &&
          <React.Fragment>
            <TableRow className={props.classes.row}>
              {renderHeader()}
            </TableRow>
            {value.isMix 
            ? <React.Fragment>
                <TableRow className={props.classes.row}>
                  <TableCell colSpan={noOfDays} className={clsx(styles.mixProduct, styles.tableCell)}>
                    <span className={styles.rowTitle}>Pre Harvesting</span>
                  </TableCell>
                </TableRow>
                {_.map(value.preHarvestTimeLine, (process) => {
                  return (
                    <React.Fragment>
                      <TableRow className={props.classes.row}>
                        <TableCell colSpan={maxPreDays} className={clsx(styles.mixProduct, styles.tableCell)}>
                          <span className={styles.rowTitle}>{process.productName} ({process.productRefNo})</span>
                        </TableCell>
                      </TableRow>
                      <TableRow className={props.classes.row}>
                        {renderTable(process.processList)}
                      </TableRow>
                    </React.Fragment>
                  )
                })}
                {_.map(value.postHarvestTimeLine, (process) => {
                  return (
                    <React.Fragment>
                      <TableRow className={props.classes.row}>
                        <TableCell colSpan={maxPreDays} />
                        <TableCell colSpan={noOfDays-maxPreDays} className={clsx(styles.mixProduct, styles.tableCell)}>
                          <span className={styles.rowTitle}>Post Harvesting ({process.productRefNo})</span>
                        </TableCell>
                      </TableRow>
                      <TableRow className={props.classes.row}>
                        {renderTable(process.processList, maxPreDays)}
                      </TableRow>
                    </React.Fragment>
                  )
                })}
              </React.Fragment>
            : <TableRow className={props.classes.row}>
                {renderTable(_.flatten([..._.map(value.preHarvestTimeLine, (process)=> process.processList), ..._.map(value.postHarvestTimeLine, (process)=> process.processList)]))}
              </TableRow>
            }
          </React.Fragment>
        }
      </Table>
    </div>
  );
}

RecipeTimeline.propTypes = {
  header: PropTypes.number,
  value: PropTypes.object,
  pathname: PropTypes.string, 
  prevPath: PropTypes.string,
};