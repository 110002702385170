import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllCustomer = createAsyncThunk(
  "customer/getAllCustomer", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Customer/GetAllCustomer", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCustomer = createAsyncThunk(
  "customer/getCustomer", 
  async (Id, {rejectWithValue}) => {
    const params = {Id};
    try {
      const response = await FomsMS.get("/Customer/GetCustomer", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteCustomer = createAsyncThunk(
  "customer/deleteCustomer", 
  async (Id, {rejectWithValue}) => {
    const params = {Id};
    try {
      const response = await FomsMS.delete("/Customer/DeleteCustomer", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateCustomer = createAsyncThunk(
  "customer/createOrUpdateCustomer", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const customer = getState().admin.customer.customer;
    let body = {
      ...customer,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/Customer/CreateOrUpdateCustomer", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProposedCustomerRefNo = createAsyncThunk(
  "customer/getProposedCustomerRefNo", 
  async (data, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      CountryCode: data,
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Customer/GetProposedCustomerRefNo", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchCustomer = createAsyncThunk(
  "customer/searchCustomer", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Customer/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);