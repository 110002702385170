import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Button from '@mui/material/Button';
// @mui/icons-material
import CloseIcon from '@mui/icons-material/Close';

import { updateRequest } from "../store/phenotype";

import plus from "assets/icons/white/plus.svg"
import styles from "assets/jss/components/UserPortal/qualityControl.module.scss";

export default function PlantMatrixImage({matrixValue, ...props}) {
  const dispatch = useDispatch();
  const inputFile = React.createRef();
  const qualityControl = useSelector(store => store.user.qualityControl.phenotype.qualityControl);

  const onButtonClick_image = () => {
    inputFile.current.click();
  }
  
  const handleOnChange_image = (e) => {
    let tempMatrix = qualityControl.plantMatrices ? _.cloneDeep(qualityControl.plantMatrices) : [];
    const foundIndex = tempMatrix.findIndex((item) => item.horizontal == props.x && item.vertical == 1);
    if (foundIndex != -1) {
      tempMatrix[foundIndex].imageUrl = URL.createObjectURL(e.target.files[0]);
    } else {
      tempMatrix.push({
        imageUrl: URL.createObjectURL(e.target.files[0]),
        horizontal: props.x,
        vertical: 1,
        qualityControlEntryId: qualityControl.id
      });
    }
    let images = _.cloneDeep(props.matrixImages);
    const imageIndex = images.findIndex((item) => item.imageLocation == props.x);
    if (imageIndex != -1) {
      images.splice(imageIndex, 0, {
        imageLocation: props.x,
        imageUrl: e.target.files[0]
      });
    } else {
      images.push({
        imageLocation: props.x,
        imageUrl: e.target.files[0]
      });
    }
    props.setMatrixImages(images);
    dispatch(updateRequest({plantMatrices: tempMatrix}));
  }

  const handleButtonClick_deleteImage = () => {
    let tempMatrix = qualityControl.plantMatrices ? _.cloneDeep(qualityControl.plantMatrices) : [];
    const foundIndex = tempMatrix.findIndex((item) => item.horizontal == props.x && item.vertical == 1);
    if (foundIndex != -1) {
      tempMatrix[foundIndex].imageUrl = "";
    } 
    let images = _.cloneDeep(props.matrixImages);
    const imageIndex = images.findIndex((item) => item.imageLocation == props.x);
    if (imageIndex != -1) {
      images.splice(imageIndex, 1);
    }
    props.setMatrixImages(images);
    dispatch(updateRequest({plantMatrices: tempMatrix}));
  }
  
  return (
    <div className={clsx(styles.hcImageDiv, styles.textFieldMargin)}>
      <input 
        ref={inputFile} 
        style={{"display": "none"}} 
        type="file" 
        accept="image/*"
        onChange={(e) => handleOnChange_image(e)}
        onClick={event => event.target.value = null}
      />
      <div className={styles.hcImageContainer}>
        {(matrixValue && matrixValue.imageUrl) && <Button className={styles.deleteImage} onClick={()=>handleButtonClick_deleteImage()}><CloseIcon /></Button>}
        <img src={(matrixValue && matrixValue.imageUrl) ? matrixValue.imageUrl : plus} className={styles.hcImage} onClick={()=>onButtonClick_image()} />
      </div>
    </div>
  );
}
