import { createSlice } from "@reduxjs/toolkit";

import { GetTimeToRunList, GetOperationConfiguration } from "services/UserPortal/OperationsService";

// initial state
const initialState = {
  timeToRun: [],
  setting: {},
};

const operationsSettings = createSlice({
  name: "operationsSettings",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.setting[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetTimeToRunList.fulfilled, (state, action) => {
        state.timeToRun = action.payload.result;
      })
      .addCase(GetOperationConfiguration.fulfilled, (state, action) => {
        state.setting = action.payload.result ? action.payload.result : {};
      })
  },
});

// export actions
export const { setValues, updateRequest, updateExclusionRequest, reset } = operationsSettings.actions;

// export the reducer
export default operationsSettings.reducer;