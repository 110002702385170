import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";
import { rowsPerTable } from "config";

import {FomsMS} from "../index";

// Lookup
export const GetDeviceLookUp = createAsyncThunk(
  "monitoring/getDeviceLookUp", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetDeviceLookUp", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Production & AllOverview
export const GetBankOverview = createAsyncThunk(
  "monitoring/getBankOverView", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetBankOverView", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetBankLookUp = createAsyncThunk(
  "monitoring/getBankLookUp", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetBankLookUp", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//BankOverview
export const GetBankDetailView  = createAsyncThunk(
  "monitoring/getBankDetailView ", 
  async (id, {rejectWithValue}) => {
    const params = {
      BankId: id,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetBankDetailView", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCameraList = createAsyncThunk(
  "monitoring/getCameraList", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetCameraList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Bank Cage View
export const GetBayLookUp = createAsyncThunk(
  "monitoring/getBayLookUp", 
  async (id, {rejectWithValue}) => {
    const params = {
      BankId: id
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetBayLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetLevelLookUp = createAsyncThunk(
  "monitoring/getLevelLookUp", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetLevelLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCageLookUp = createAsyncThunk(
  "monitoring/getCageLookUp", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetCageLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCageDetailView = createAsyncThunk(
  "monitoring/GetCageDetailView", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetCageDetailView", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Work Order
export const GetWorkOrderTimeLine = createAsyncThunk(
  "monitoring/getWorkOrderTimeLine", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetWorkOrderTimeLine", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Equipment - overview
export const GetEquipmentOverview = createAsyncThunk(
  "monitoring/getEquipmentOverview", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetEquipmentOverview", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipmentList = createAsyncThunk(
  "monitoring/getEquipmentList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetEquipmentList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);


//Equipment - location
export const GetAllFloorPlan = createAsyncThunk(
  "monitoring/getAllFloorPlan", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetAllFloorPlan", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSectorByFloorId = createAsyncThunk(
  "monitoring/getSectorByFloorId", 
  async (id, {rejectWithValue}) => {
    const params = {
      FloorId: id
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetSectorByFloorId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipmentByFloorandSectorIdOrBankId = createAsyncThunk(
  "monitoring/GetEquipmentByFloorandSectorIdOrBankId", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetEquipmentByFloorandSectorIdOrBankId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipmentLocationFloorAndSectorView = createAsyncThunk(
  "monitoring/GetEquipmentLocationFloorAndSectorView", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetEquipmentLocationFloorAndSectorView", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipmentLocationBankView = createAsyncThunk(
  "monitoring/GetEquipmentLocationBankView", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetEquipmentLocationBankView", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Settings
export const GetAllMonitoringProductionSetting = createAsyncThunk(
  "monitoring/getAllMonitoringProductionSetting", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetAllMonitoringProductionSetting", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetMonitoringProductionSettingById = createAsyncThunk(
  "monitoring/getMonitoringProductionSettingById", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetMonitoringProductionSettingById", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateMonitoringProductionSetting = createAsyncThunk(
  "monitoring/createOrUpdateMonitoringProductionSetting", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const value = getState().user.monitoring.settings.setting;
    let body = {
      ...value,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/Monitoring/CreateOrUpdateMonitoringProductionSetting", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteMonitoringProductionSetting = createAsyncThunk(
  "monitoring/DeleteMonitoringProductionSetting", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/userportal/Monitoring/DeleteMonitoringProductionSetting", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllMonitoringStorageSetting = createAsyncThunk(
  "monitoring/getAllMonitoringStorageSetting", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetAllMonitoringStorageSetting", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetMonitoringStorageSettingById = createAsyncThunk(
  "monitoring/getMonitoringStorageSettingById", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetMonitoringStorageSettingById", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateMonitoringStorageSetting = createAsyncThunk(
  "monitoring/createOrUpdateMonitoringStorageSetting", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const value = getState().user.monitoring.settings.setting;
    let body = {
      ...value,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/Monitoring/CreateOrUpdateMonitoringStorageSetting", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteMonitoringStorageSetting = createAsyncThunk(
  "monitoring/DeleteMonitoringStorageSetting", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/userportal/Monitoring/DeleteMonitoringStorageSetting", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//storage
export const GetAllWarehouses = createAsyncThunk(
  "monitoring/getAllWarehouses", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetAllWarehouses", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWarehouseDetailByWarehouseId = createAsyncThunk(
  "monitoring/getWarehouseDetailByWarehouseId", 
  async (id, {rejectWithValue}) => {
    const params = {
      id: id,
    };
    try {
      const response = await FomsMS.get("/userportal/Monitoring/GetWarehouseDetailByWarehouseId", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);