import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  userName: null,
  emailAddress: null,
  password: null,
  confirmPassword: null,
  error: null,
};

// create reducer and action creators
const signup = createSlice({
  name: "signup",
  initialState,
  reducers: {
    signupRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
});

// export actions
export const { signupRequest, reset } = signup.actions;

// export the reducer
export default signup.reducer;