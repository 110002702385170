/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import _ from "lodash";

// @mui/material
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";

import { renderValue, filterParam } from "common/functions";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { setValues } from "../store/overview";
import { GetProductList } from "services/UserPortal/CommonLookupService";
import { SubmitEndOrder } from "services/UserPortal/OperationsService";

import errorImg from "assets/img/error-img.svg";
import styles from "assets/jss/components/UserPortal/operations.module.scss";
import { setNoSnackbar } from "store/general";
import { setError } from "store/general";

export default function ProgressDetails(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const processOverview = useSelector(store => store.user.operations.overview.processOverview);

  const handleButtonClick_cageIDList = (item) => {
    props.setView("cage");
    props.setOperationTaskName(item);
  };

  const handleButtonClick_endOrder = () => {
    const body = {
      processName: props.processName.replace("In Progress", ""),
      id: props.productId
    }
    dispatch(SubmitEndOrder(body))
    .then(() => {
      dispatch(setError(props.processName.replace("In Progress", "") + "Process of " + props.workOrder + " has ended"))
    })
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  return (
    <React.Fragment>
      <Card
        classes={{
          root: styles.cardPaper,
        }}
        title={props.processName}
        action={
          <React.Fragment>
            {props.processName === "Transplanting In Progress"
            ?
              <Button
                className={styles.button}
                onClick={() => props.setView("transplant")}
              >
                Transplanting Details
              </Button>
            : null
            }
            {props.processName === "Harvesting In Progress"
            ?
              <Button
                className={styles.button}
                onClick={() => props.setView("harvest")}
              >
                Harvesting Dashboard
              </Button>
            : null
            }
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <Button
              className={styles.button}
              // onClick={() => history.push("/user/operations/process-overview")}
              onClick={() => handleButtonClick_endOrder()}
            >
              End Order
            </Button>
          </React.Fragment>
        }
      >
        <div>
          <Typography className={styles.checkFont}>Checks:</Typography>
          <Typography>Seed Type: {processOverview.seedType}</Typography>
          <Typography>Board Type: {processOverview.boardType}</Typography>
        </div>
        <img className={styles.image} src={processOverview.productImage} alt="greenphyto-error" />
        <Divider className={styles.hrDivider} />
        <Grid container spacing={1} className={styles.hrDivider}>
          <Grid item xs={2}>
            <Typography>Status:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{processOverview.seedType}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>No. of Boards Processed:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{processOverview.noOfBoardsProcessed}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Start Time:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{processOverview.startTime}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>No. of Trays Processed:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{processOverview.noOfTraysProcessed}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Expected End Time:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{processOverview.endTime}</Typography>
          </Grid>
          <Grid item xs={6}>
            {processOverview.operationTaskName && processOverview.operationTaskName.map((item, index) => {
              return (
                <Typography key={index}><span className={styles.link} onClick={()=>handleButtonClick_cageIDList(item)}>Cage ID List ({item})</span></Typography>
              )
            })}
            {/* <Link to={{pathname: "/user/operations/cage-details/" + props.productId, state: {operationTaskName: props.process}}} className={styles.orange}>
              Cage ID List
            </Link> */}
          </Grid>
          {props.process === "Transplant In Progress"
          ?
            <React.Fragment>
              <Grid item xs={6}/>
              <Grid item xs={6}>
                <span className={styles.link} onClick={()=>handleButtonClick_cageIDList()}>Edit</span>
              </Grid>
            </React.Fragment>
          : null
          }
        </Grid>
      </Card>
    </React.Fragment>
  );
}
