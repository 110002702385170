import { combineReducers } from "redux";
import germination from "./germination";
import phenotype from "./phenotype";
import vegetable from "./vegetable";
import search from "./search";

const qcReducer = combineReducers({
  germination,
  phenotype,
  vegetable,
  search,
});

export default qcReducer;