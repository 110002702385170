// Recipe
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Checkbox from "shared-components/Checkbox/Checkbox";
import RecipeTimeline from "shared-components/Timeline/RecipeTimeline";
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";

import { fixedProcessList } from "enums/Constants";
import { setDirty } from "store/general";
import { setValues, updateRequest, resetControl, reset } from ".";
import { GetProductList, GetRecipeByProductId, CreateOrUpdateRecipe } from "services/AdminPortal/RecipeService";

import { useRouteCanWrite } from "hooks";
import { checkPermission } from "common/functions";

import styles from "assets/jss/components/AdminPortal/recipe.module.scss";

export default function Recipe() {
  const dispatch = useDispatch();
  const tableRef = React.useRef(null);
  const canWrite = useRouteCanWrite();
  const products = useSelector(store => store.admin.recipe.products);
  const recipe = useSelector(store => store.admin.recipe.recipe);
  const control = useSelector(store => store.admin.recipe.control);
  const [selectedProduct, setSelectedProduct] = React.useState(products[0]);
  const [isEdit, setIsEdit] = React.useState(false);

  const handleOnChange_select = (e) => {
    setSelectedProduct(products.find((item) => item.id === e.target.value));
  };

  const handleButtonClick_checkbox = () => {
    dispatch(updateRequest({isAIControl : !control.isAIControl}))
  };

  const handleOnChange_text = (e, days) => {
    let payload = _.cloneDeep(control[e.target.id]);
    if (e.target.id === "recipeControlForStorage") {
      payload = {...payload, recipeControlValue: Number(e.target.value)};
    } else {
      payload = payload.map((item) => {
        if (item.days === days) {
          return {...item, recipeControlValue: Number(e.target.value)};
        }
        return item;
      })
    }
    dispatch(updateRequest({[e.target.id] : payload}));
  };

  const handleButtonClick_edit = () => {
    setIsEdit(true);
    dispatch(setDirty(true));
    dispatch(setValues(_.omit(recipe, ["recipeTimeLine", "productRefNo"])));
  }

  const handleButtonClick_cancel = () => {
    resetState();
  }

  const handleButtonClick_save = () => {
    dispatch(CreateOrUpdateRecipe())
    .then(() => {
      dispatch(GetRecipeByProductId(selectedProduct.id));
    });
    resetState();
  }

  const resetState = () => {
    dispatch(setDirty(false));
    setIsEdit(false);
    dispatch(resetControl());
  }

  const renderTable = (listName) => {
    const list = (recipe && recipe[listName]) ?? [];
    const components = Array.from({length: list.length});
    for (let i = 0; i <= list.length; i++) {
      if (list[i]) {
        const index = Number(list[i].days.replace('Day', '')) - 1;
        if (isEdit) {
          components[index] = 
          <TableCell key={index} align="center">
            <TextField 
              id={listName}
              type="Number"
              variant="outlined" 
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              onInput={(e)=>{ 
                e.target.value = e.target.value > 100 ? 100 : Math.max(0, parseInt(e.target.value)).toString().slice(0,3)
              }}
              onChange={(e) => handleOnChange_text(e, list[i].days)}
              value={control && control[listName].find((con) => con.days === list[i].days) ? String(control[listName].find((con) => con.days === list[i].days).recipeControlValue) : ""}
            />
          </TableCell>;
        } else {
          components[index] = 
          <TableCell key={index} align="center">
            {list[i].recipeControlValue}
          </TableCell>;
        }
      }
    }
    return components;
  }

  React.useEffect(() => {
    tableRef.current.scrollTo(0, 0);
    if (selectedProduct) {
      dispatch(GetRecipeByProductId(selectedProduct.id));
    }
  },[selectedProduct]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetProductList())
    .then(({payload}) => {
      if (payload && payload.result) {
        setSelectedProduct(payload.result[0]);
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Recipe"
        subheader={selectedProduct && <React.Fragment>Product Selected: <span className={styles.productCode}>{selectedProduct.productId}</span></React.Fragment>}
        action={
          <Select
            className={styles.productDropdown}
            name="selectedProduct"
            onChange={(e)=>handleOnChange_select(e)}
            placeholder="Select Product"
            value={selectedProduct && selectedProduct.id}
            disabled={isEdit}
          >
            {products && products.map((item, index) => {
              return <MenuItem key={index} value={item.id}>{item.productName}</MenuItem>;
            })} 
          </Select>
        }
      >
        <Card 
          classes={{
            root: clsx(styles.cardPaper, styles.marginBottom),
          }}
          title="Timeline"
        >
          <RecipeTimeline 
            classes={{
              row: styles.timelineRow,
              header: styles.timelineCell
            }}
            value={!_.isEmpty(recipe) ? recipe.recipeTimeLine : {}}
            pathname={selectedProduct && "/admin/products/process/" + selectedProduct.id}
            prevPath={location.pathname}
            disabled={checkPermission("Admin.Products", "none")}
          />
        </Card>
        <Card 
          classes={{
            root: styles.cardPaper,
          }}
          title= {
            <React.Fragment>
              Control on per day basis
              <div className={styles.checkboxPadding} >
                <Checkbox
                  id="isAIControl"
                  checked={isEdit ? control.isAIControl : recipe.isAIControl}
                  onChange={(e) => handleButtonClick_checkbox(e)} 
                  disabled={!isEdit}
                />
                <Typography>AI Controlled</Typography>
              </div>
              <div className={styles.checkboxPadding} >
                <Checkbox
                  id="supportUV"
                  checked={isEdit ? control.supportUV : recipe.supportUV}
                  onChange={(e) => handleButtonClick_checkbox(e)} 
                  disabled={!isEdit}
                />
                <Typography>UV</Typography>
              </div>
            </React.Fragment>
          }
          action={canWrite &&
            (!isEdit
            ? <IconButton 
                type="edit"
                onClick={() => handleButtonClick_edit()}
                disabled={!(!_.isEmpty(recipe) && recipe.recipeControlForLightingList.length)}
              />
            : <React.Fragment>
                <Button 
                  className={clsx(styles.buttonSecondary, styles.cardButton)}
                  onClick={() => handleButtonClick_cancel()}
                >
                  Cancel
                </Button>
                <Button 
                  className={styles.button}
                  onClick={() => handleButtonClick_save()}
                >
                  Save
                </Button>
              </React.Fragment>
            )
          }
        >
          <div className={styles.table} ref={tableRef}>
            <Table>
              <TableRow />
              {!_.isEmpty(recipe) && !recipe.recipeTimeLine.isMix &&
                <React.Fragment>
                  <TableRow>
                    <TableCell sticky className={styles.tableHead}>Process</TableCell>
                    {recipe.recipeTimeLine
                    ? _.flatten([..._.map(recipe.recipeTimeLine.preHarvestTimeLine, (process)=> process.processList), ..._.map(recipe.recipeTimeLine.postHarvestTimeLine, (process)=> process.processList)]).map((item, index) => {
                      const process = fixedProcessList.find(({processName}) => processName === item.process);
                      if (item.days) {
                        return <TableCell key={index} colSpan={item.days} className={clsx(styles.tableHead, styles.dayCell, styles[process.code])} align="center">{item.process}</TableCell>;
                      }
                    })
                    : null
                    }
                  </TableRow>
                  <TableRow>
                    <TableCell sticky className={styles.tableHead}>Days</TableCell>
                    {recipe.recipeControlForLightingList.length
                    ? recipe.recipeControlForLightingList.map((item, index) => {
                      return <TableCell key={index} className={styles.dayCell} align="center">Day {index+1}</TableCell>;
                    })
                    : null
                    }
                  </TableRow>
                  <TableRow>
                    <TableCell sticky className={styles.tableHead}>Light Control (%)</TableCell>
                    {renderTable('recipeControlForLightingList')}
                  </TableRow>
                  <TableRow>
                    <TableCell sticky className={styles.tableHead}>Light Control 2 (%)</TableCell>
                    {renderTable('recipeControlForLightingTwoList')}
                    
                  </TableRow>
                  {((isEdit && control.isAIControl) || (!isEdit && recipe.isAIControl)) &&
                    <TableRow>
                      <TableCell sticky className={styles.tableHead}>Light Control (AI)</TableCell>
                      {renderTable('aiLightControlList')}
                    </TableRow>
                  }
                  <TableRow>
                    <TableCell sticky className={styles.tableHead}>Duration (hours)</TableCell>
                    {renderTable('recipeControlForLightDurationList')}
                  </TableRow>
                  <TableRow>
                    <TableCell sticky className={styles.tableHead}>Temperature (°C)</TableCell>
                    {renderTable('recipeControlForTemperatureList')}
                  </TableRow>
                </React.Fragment>
              }
              <TableRow>
                <TableCell sticky className={styles.tableHead}>Storage Duration</TableCell>
                {!_.isEmpty(recipe) && recipe.recipeControlForLightingList.length 
                ? <React.Fragment>
                    <TableCell align="center">
                      { isEdit 
                      ? <TextField 
                          id="recipeControlForStorage"
                          type="Number"
                          variant="outlined" 
                          onInput={(e)=>{ 
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                          }}
                          onChange={(e) => handleOnChange_text(e)}
                          value={control && control.recipeControlForStorage ? String(control.recipeControlForStorage.recipeControlValue) : ""}
                        />
                      : recipe.recipeControlForStorage.recipeControlValue
                      }
                    </TableCell>
                    <TableCell colSpan={recipe.recipeControlForLightingList.length-1}>Days</TableCell>
                  </React.Fragment>
                : null
                }
              </TableRow>
            </Table>
          </div>
        </Card>
      </Card>
    </React.Fragment>
  );
}
