import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Bank from "shared-components/Bank/Bank";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";

import { checkPermission, renderValue } from "common/functions";
import { setValues } from "./store/capacity";
import { GetWorkOrderList } from "services/UserPortal/CapacityService";
import { GetBankInfoById, GetCageInfoById, GetProductListForTrays, UpdateCageInfo } from "services/UserPortal/PlanningService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/capacity.module.scss";

export default function CapacityBank(props) {
  const dispatch = useDispatch();
  const trays = useSelector(store => store.user.capacity.capacity.trays);
  const cage = useSelector(store => store.user.capacity.capacity.cage);
  const cageList = useSelector(store => store.user.capacity.capacity.cageList);
  const productList = useSelector(store => store.user.capacity.capacity.productList);
  const workOrderList = useSelector(store => store.user.capacity.capacity.workOrderList);
  const [collapsed, setCollapsed] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleButtonClick_delete = (i) => {
    let payload = _.cloneDeep(trays);
    payload[i].productId = 0;
    payload[i].boardUpdateDtos = _.map(payload[i].boardUpdateDtos, (item) => {
      item.productId = 0;
      return item;
    });
    dispatch(setValues({trays: payload}));
  };

  const handleOnChange_select = (e, index, subIndex) => {
    let payload = _.cloneDeep(trays);
    if (subIndex !== undefined) {
      payload[index].boardUpdateDtos[subIndex][e.target.name] = e.target.value;
    } else {
      payload[index][e.target.name] = e.target.value;
    }
    if (e.target.value === -1) {
      payload[index].isBoardLevel = true;
    } else {
      payload[index].isBoardLevel = false;
      payload[index].boardUpdateDtos = payload[index].boardUpdateDtos.map((item) => {return {...item, [e.target.name]: e.target.value}});
    }
    dispatch(setValues({trays: payload}));
  };

  const handleButtonClick_adjust = () => {
    dispatch(UpdateCageInfo())
    .then(() => {
      handleOnClick_cell(null);
      getBankInfo();
    });
  }

  const handleButtonClick_reset = () => {
    let payload = _.cloneDeep(trays);
    payload = _.map(payload, (item) => {
      item.productId = 0;
      item.boardsList = _.map(item.boardUpdateDtos, (board) => {
        board.productId = 0;
        return board
      });
      return item;
    });
    dispatch(setValues({trays: payload}));
  }

  const handleOnClick_cell = (cageDetails) => {
    setIsLoading(true);
    setOpenModal(!openModal);
    if (cageDetails) {
      dispatch(GetCageInfoById({capacityId: cageDetails.capacityId, locationNo: cageDetails.cageRefNo}))
      .then((response) => {
        const result = response.payload.result;
        const traysDetail = _.map(result.cagesList[0].traysList, (item) => {
          return {
            trayId: item.trayId,
            productId: item.isBoardLevel ? -1 : item.productId,
            isBoardLevel: item.isBoardLevel,
            boardUpdateDtos: _.map(item.boardsList, (board) => _.pick(board, ["boardId", "productId"]))
          }
        })
        setIsLoading(false);
        dispatch(setValues({trays: traysDetail}));
      });
      dispatch(setValues({cage: cageDetails}));
      setCollapsed(new Array(cageDetails.totalQty).fill(false));
    } else {
      dispatch(setValues({cage: {}, trays: []}));
      setCollapsed([]);
    }
  }

  const onButtonClick_collapse = (index) => {
    let payload = _.cloneDeep(collapsed);
    payload[index] = !collapsed[index];
    setCollapsed(payload)
  }

  const getBankInfo = () => {
    if (props.bank.id) {
      dispatch(GetBankInfoById(props.bank.id));
    }
  }

  const renderTrays = () => {
    let component = [];
    if (!_.isEmpty(cage)) {
      for (let i = 0; i < cage.totalQty; i++) {
        const trayItem = trays[i];
        component.push(
          <React.Fragment key={i}>
            <Grid item xs={1} className={styles.modalGridItem}>
              <Typography className={styles.modalLabel}>Tray {i+1}:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Select
                name="productId"
                onChange={(e)=>handleOnChange_select(e, i)}
                value={renderValue(trayItem && trayItem.productId)}
                placeholder="Select an option"
              >
                <MenuItem value={-1}>Refer to boards</MenuItem>
                {productList && productList.map((item) => {
                  return <MenuItem key={item.id} value={item.id}>{item.productName + " ("+item.productId+")"}</MenuItem>;
                })} 
              </Select>
              <Typography className={styles.modalLink} onClick={()=>onButtonClick_collapse(i)}><img className={collapsed[i] ? styles.icon : clsx(styles.icon, styles.rotate)} src={arrow} alt="arrow" />Board Details</Typography>
              {(trayItem && collapsed[i] && trayItem.boardUpdateDtos)
              ? _.map(trayItem.boardUpdateDtos, (item, index) => {
                  return (
                    <div key={index} className={styles.boardDiv}>
                      <Typography className={styles.boardLabel}>Board {index+1}:</Typography>
                      <Select
                        name="productId"
                        onChange={(e)=>handleOnChange_select(e, i, index)}
                        value={renderValue(item && item.productId)}
                        placeholder="Select an option"
                      >
                        {productList && productList.map((option) => {
                          return <MenuItem key={option.id} value={option.id}>{option.productName + " ("+option.productId+")"}</MenuItem>;
                        })} 
                      </Select>
                    </div>
                  )
                })
              : null
              }
            </Grid>
            <Grid item xs={1} className={styles.modalGridItem}>
              <CustomIconButton
                type="delete" 
                onClick={() => handleButtonClick_delete(i)}
              />
            </Grid>
          </React.Fragment>
        )
      }
    }
    return component;
  }
  
  React.useEffect(() => {
    getBankInfo();
  },[props.bank.id]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetProductListForTrays());
    dispatch(GetWorkOrderList());
    // componentDidUnmount
    return () => {
      // dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title={props.bank.bankName}
        classes={{
          root: styles.cardPaper,
        }}
        // cardActions={
        //   <React.Fragment>
        //     <Button disabled />
        //     <div>
        //       <Button 
        //         className={styles.buttonSecondary}
        //         onClick={()=>handleButtonClick_exit()}
        //         disabled={!isDirty}
        //       >
        //         Cancel
        //       </Button>
        //       <Button 
        //         className={clsx(styles.button, styles.buttonMargin)}
        //         onClick={()=>handleButtonClick_unassign()}
        //         disabled={!isDirty}
        //       >
        //         Unassign
        //       </Button>
        //     </div>
        //   </React.Fragment>
        // }
      >
        <div className={styles.bankView}>
          <Bank 
            type="capacity"
            levels={props.bank.numberOfLevels}
            bays={props.bank.numberOfBays}
            onCellClick={(e) => handleOnClick_cell(e)}
            data={cageList}
          />
        </div>
        <div className={styles.legend}>
          <div className={clsx(styles.colorBox, styles.unassigned)} /> Unassigned Cages
          <div className={clsx(styles.colorBox, styles.fully)} /> Fully Assigned Cages
          <div className={clsx(styles.colorBox, styles.partially)} /> Partially Assigned Cages
          {/* <div className={clsx(styles.colorBox, styles.reserved)} /> Reserved Cages */}
        </div>
        {checkPermission("Monitoring", "readEdit") &&
          <Typography className={styles.link}>
            <Link to={{pathname: "/user/monitoring/production"}}>Go to Production Module to review the summary of the cage utilisation</Link>
          </Typography>
        }
      </Card>
      <Modal
        className={styles.modal}
        classes={{
          content: styles.modalContent
        }}
        open={openModal}
        onClose={() => handleOnClick_cell()}
        title={"Tray Assignment Status for "+(cage.cageRefNo)}
        content={
          isLoading
          ? <CircularProgress
              variant="indeterminate"
              className={styles.progress}
              thickness={6}
            />
          : <React.Fragment>
              Choose the product to assign to each tray. If you want to unassign the vegetable from the tray, click on the &quot;bin&quot; icon to remove the vegetable assigned. Click save to save the changes.
              <Grid container spacing={2} className={styles.modalGrid}>
                <Grid item xs={9} className="flex">
                  <Typography className={styles.label}>Work Order Number:</Typography>
                  <Select
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={cage.workOrderId}
                    placeholder="Select Work Order"
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                </Grid>
                <Grid item xs={3} className={styles.modalGridButton}>
                  <Button className={styles.buttonSecondary} onClick={() => handleButtonClick_reset()}>Reset All Tray</Button>
                </Grid>
                {renderTrays()}
              </Grid>
            </React.Fragment>
        }
        actions={ !isLoading &&
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleOnClick_cell()}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_adjust()}>Save</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
