import { createSlice } from "@reduxjs/toolkit";

import { GetAllRawMaterialCost } from "services/UserPortal/RawMaterialService";

// initial state
const initialState = {
  filter: {},
  totalCost: 0,
  totalCount: 0,
  costs: [],
  subSaleOrders: [],
  purchaseOrders: [],
  status: [],
};

const costRM = createSlice({
  name: "costRM",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRawMaterialCost.fulfilled, (state, action) => {
        state.totalCost = action.payload.result.totalCost;
        state.totalCount = action.payload.result.totalCount;
        state.costs = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, reset } = costRM.actions;

// export the reducer
export default costRM.reducer;