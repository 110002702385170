// Role
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// @mui/material
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import Pagination from "shared-components/Table/Pagination";
import CustomIconButton from "shared-components/Button/IconButton";

import Empty from "error-page/Empty";
import { GetAllRoles, DeleteRole } from "services/AdminPortal/RoleService";
import { reset } from "./";

import { filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { roleTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/role.module.scss";

import { useRouteCanWrite } from "hooks";

export default function Role() {
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.admin.role.totalCount);
  const roles = useSelector(store => store.admin.role.roles);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('displayName');
  const [page, setPage] = React.useState(0);
  const [roleId, setRoleId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleModal = (id) => {
    setRoleId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = (id) => {
    dispatch(DeleteRole(id))
    .then(() =>{
      dispatch(GetAllRoles({page: 0}));
      resetState();
    });
  }
  
  const resetState = () => {
    setRoleId(null);
    setPage(0);
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  React.useEffect(() => {
    dispatch(GetAllRoles({page}));
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      { totalCount > 0 
      ? <React.Fragment>
          <Card 
            title = "Role List"
          >
            <div className={styles.table}>
              <Table
                header={filterActionTableHead(roleTableHead, canWrite)}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { roles.map((role) => {
                  return (
                    <TableRow key={role.id}>
                      <TableCell className={styles.roleNameCell}>{role.name}</TableCell>
                      <TableCell className={styles.roleDescCell}>{role.description}</TableCell>
                      <TableCell className={styles.roleModuleCell}>{role.readOnlyModule.replaceAll(",", ", ")}</TableCell>
                      <TableCell className={styles.roleModuleCell}>{role.readEditModule.replaceAll(",", ", ")}</TableCell>
                      <TableCell align="right">
                        {role.name != "Admin" && canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/role/"+role.id}}>
                              <CustomIconButton type="edit" />
                            </Link>
                            <CustomIconButton 
                              type="delete"
                              onClick={() => handleModal(role.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={page+1}
                onChange={(e, pageNo)=>setPage(pageNo-1)}
              />
            }
          </Card>
          <Modal
            open={openModal}
            onClose={() => handleModal(null)}
            icon={<img className={styles.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete this role? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
                <Button className={styles.button} onClick={() => handleButtonClick_delete(roleId)}>Delete</Button>
              </React.Fragment>
            }
          />
        </React.Fragment>
      : <Empty description={"Your page is empty. " + (canWrite ? "Add a role to get started!" : "")}/>
      }
    </React.Fragment>
  );
}
