import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { minDate, rowsPerTable } from "config";
import { reset } from "../store/search";
import { setSearchText } from "store/general";
import { deliveryOrderCreationTableHead } from "enums/UserPortal/TableHeaders";
import { SearchFinishedGoodsDOCreation } from "services/UserPortal/FinishedGoodsService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/finishedGoods.module.scss";

export default function SearchDOResult() {
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.finishedGoods.search.doTotalCount);
  const results = useSelector(store => store.user.finishedGoods.search.doResults);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  React.useEffect(() => {
    dispatch(SearchFinishedGoodsDOCreation({keyword: params.keyword, page, stopLoading: Boolean(page)}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={styles.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: styles.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From DO List"}
                <IconButton 
                  className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={styles.table}>
                  <Table
                    header={deliveryOrderCreationTableHead}
                  >
                    { _.map(results, (item,index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{highlightValue(item.saleOrderNumber)}</TableCell>
                            <TableCell>{highlightValue(item.customerName)}</TableCell>
                            <TableCell>{highlightValue(moment(item.deliveryDate).isAfter(minDate) ? moment(item.deliveryDate).format("DD/MM/yyyy") : "")}</TableCell>
                            <TableCell>{highlightValue(item.noOfSubSaleOrderCheckOut)}</TableCell>
                            <TableCell>{highlightValue(item.status)}</TableCell>
                            <TableCell><Link className={styles.link} to={{pathname: "/user/finished-goods/delivery-order-creation/"+item.id, state: {good: item}}}>Link</Link></TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
