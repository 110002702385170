import React from "react";
import { useDispatch } from "react-redux";
// core components
import Button from "@mui/material/Button";

import Card from "shared-components/Card/Card";

import AllOverview from "./AllOverview";
import WarehouseOverview from "./WarehouseOverview";
import { setSearchText } from "store/general";
import { reset } from "../store/production";

import { GetAllWarehouses, GetWarehouseDetailByWarehouseId } from "services/UserPortal/MonitoringService";

import styles from "assets/jss/components/UserPortal/monitoring.module.scss";

export default function Storage() {
  const dispatch = useDispatch();
  const [id, setId] = React.useState(null);

  const handleButtonClick_back = () => {
    setId(null);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllWarehouses());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  React.useEffect(() => {
    id && dispatch(GetWarehouseDetailByWarehouseId(id));
  },[id]);

  return (
    <React.Fragment>
      <Card 
        title="Storage"
        cardActions={ id &&
          <Button
            className={styles.buttonSecondary}
            onClick={()=>handleButtonClick_back()}
          >
            Back
          </Button>
        }
      >
        {id ? <WarehouseOverview id={id} setId={setId}/> : <AllOverview setId={setId}/>}
      </Card>
    </React.Fragment>
  );
}
