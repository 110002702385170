import { createSlice } from "@reduxjs/toolkit";

import { GetAllWaterFlowControl } from "services/UserPortal/FlowService";

// initial state
const initialState = {
  totalCount: 0,
  filter: {},
  results: [],
  flowControl: {},
  //lookup
  deviceList: []
};

const overviewFC = createSlice({
  name: "overviewFC",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.flowControl[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllWaterFlowControl.fulfilled, (state, action) => {
        state.results = action.payload.result.items;
        state.totalCount = action.payload.result.totalCount;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = overviewFC.actions;

// export the reducer
export default overviewFC.reducer;