// Supplier Search
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
// import CustomIconButton from "shared-components/Button/IconButton";

import { formatNumbers, filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { resetSearch } from ".";
import { setSearchText } from "store/general";
import { supplierTableHead } from "enums/AdminPortal/TableHeaders";
import { DeleteSupplier, SearchSupplier } from "services/AdminPortal/SupplierService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/supplier.module.scss";

import { useRouteCanWrite } from "hooks";

export default function SearchSupplierResult() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const searchResultsCount = useSelector(store => store.admin.supplier.searchResultsCount);
  const searchResults = useSelector(store => store.admin.supplier.searchResults);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('displayName');
  const [page, setPage] = React.useState(0);
	const [supplierId, setSupplierId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);

  const count = Math.ceil(searchResultsCount / rowsPerTable);
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleModal = (id) => {
    setSupplierId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteSupplier(supplierId))
    .then(() =>{
      dispatch(SearchSupplier(params.keyword));
      resetState();
    })
  }

  const resetState = () => {
    setSupplierId(null);
    setPage(0);
    setOpenModal(!openModal);
  }

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={styles.highlight}
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  React.useEffect(() => {
    dispatch(resetSearch());
    dispatch(SearchSupplier({keyword: params.keyword, page}));
  },[params.keyword]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(resetSearch());
      dispatch(setSearchText(""));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={styles.result}>{formatNumbers(searchResultsCount) + " Search Result(s) From 1 Table:  ‘" + params.keyword + "’"}</Typography>
      { searchResultsCount > 0 &&
        <React.Fragment>
          <Card 
            title = {
              <React.Fragment>
                {searchResultsCount + " Result(s) From Supplier List"}
                <IconButton 
                  className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={styles.table}>
									<Table
										header={filterActionTableHead(supplierTableHead, canWrite)}
										order={order}
										orderBy={orderBy}
										onRequestSort={handleRequestSort}
									>
										{ _.orderBy(searchResults, [orderBy], [order])
										.slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
										.map((item,index) => {
											return (
												<TableRow key={index}>
													<TableCell>{highlightValue(item.supplierRefNo)}</TableCell>
													<TableCell>{highlightValue(item.supplierName)}</TableCell>
													<TableCell>{highlightValue(item.address)}</TableCell>
													<TableCell>{highlightValue(item.email)}</TableCell>
													<TableCell>{highlightValue(item.contactPerson)}</TableCell>
													<TableCell>{highlightValue(item.creditLimit)}</TableCell>
													<TableCell>{highlightValue(item.creditTermID)}</TableCell>
													<TableCell>{highlightValue(item.supplierTypeID)}</TableCell>
													<TableCell align="right">
                            {/* {canWrite &&
                              <React.Fragment>
                                <Link to={{pathname: "/admin/supplier/"+item.id}}>
                                  <CustomIconButton type="edit" />
                                </Link>
                                <CustomIconButton 
                                  type="delete"
                                  onClick={() => handleModal(item.id)}
                                />
                              </React.Fragment>
                            } */}
													</TableCell>
												</TableRow>
											)
										})}
									</Table>
								</div>
                { count > 1 &&
									<Pagination 
										count={count} 
										page={page+1}
										onChange={(e, pageNo)=>setPage(pageNo-1)}
									/>
								}
              </React.Fragment>
            }
          </Card>
          <Modal
            open={openModal}
            onClose={() => handleModal(null)}
            icon={<img className={styles.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete this supplier? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
                <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
}
