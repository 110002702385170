import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
// @mui/material components
import Typography from "@mui/material/Typography";
// @mui/lab
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// core components
import TextField from "shared-components/TextField/TextField";

import styles from "assets/jss/shared-components/dateTimePicker.module.scss";

export default function CustomDatePicker({type, errorMsg, label, value, clearable, onClear, name, ...props}) {

  console.log(styles)
  const renderField = () => {
    switch (type) {
      case "datetime":
        return (
          <DateTimePicker 
            {...props}
            maxDateTime={props.maxDateTime && moment(props.maxDateTime)}
            minDateTime={props.minDateTime && moment(props.minDateTime)}
            value={value ? moment(value) : null}
            format="DD/MM/YYYY HH:mm"
            ampm={false}
            slots={{ textField: TextField }}
            slotProps={{ 
              textField: { 
                id: name,
                sx: {svg: {color: styles.color}, input: {color: styles.color}, label: {color: styles.color}},
                errorMsg: errorMsg,
                helperText: (clearable && value)
                  ? <Typography className={styles.helperLink} onClick={onClear}>Clear</Typography>
                  : errorMsg && errorMsg.field === name ? errorMsg.msg : ""
              }
            }}
          />
        );
      case "time":
        return (
          <TimePicker 
            {...props}
            maxTime={props.maxTime && moment(props.maxTime)}
            minTime={props.minTime && moment(props.minTime)}
            value={value ? moment(value) : null}
            format="HH:mm"
            ampm={false}
            slots={{ textField: TextField }}
            slotProps={{ 
              textField: { 
                id: name,
                sx: {svg: {color: styles.color}, input: {color: styles.color}, label: {color: styles.color}},
                errorMsg: errorMsg,
                helperText: (clearable && value)
                  ? <Typography className={styles.helperLink} onClick={onClear}>Clear</Typography>
                  : errorMsg && errorMsg.field === name ? errorMsg.msg : ""
              } 
            }}
          />
        );
      default: 
        return (
          <DatePicker 
            {...props}
            maxDate={props.maxDate && moment(props.maxDate)}
            minDate={props.minDate && moment(props.minDate)}
            value={value ? moment(value) : null}
            format="DD/MM/YYYY"
            slots={{ textField: TextField }}
            slotProps={{ 
              textField: { 
                id: name,
                sx: {svg: {color: styles.color}, input: {color: styles.color}, label: {color: styles.color}},
                errorMsg: errorMsg,
                helperText: (clearable && value)
                  ? <Typography className={styles.helperLink} onClick={onClear}>Clear</Typography>
                  : errorMsg && errorMsg.field === name ? errorMsg.msg : ""
              }
            }}
          />
        );
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {label && <Typography className={styles.label}>{label}</Typography>}
      {renderField()}
    </LocalizationProvider>
  )
}

DatePicker.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  clearable: PropTypes.bool,
  onClear: PropTypes.func,
  errorMsg: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};