import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DetailForm from "./DetailForm";
import { setDirty, setSearchText } from "store/general";
import { GetBoardType, GetNutrientCategory } from "services/UserPortal/QualityControlService"
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues } from "../store/phenotype";
import { GetPlantPhenotypeReport } from "services/UserPortal/QualityControlService";

export default function AddEditQualityControl() {
  const dispatch = useDispatch();
  let history = useHistory();
  const [matrixImages, setMatrixImages] = React.useState([]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "quality-control");
    dispatch(GetBoardType());
    dispatch(GetNutrientCategory());
    if (path[index+2] !== "create") {
      dispatch(GetPlantPhenotypeReport({id: path[index+2]}))
      .then(({error}) => {
        if (error) {
        history.push("/user/quality-control/phenotype");
        } else {
          dispatch(setDirty(true))
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    // componentDidUnmount
    return () => {
      dispatch(setValues({qualityControl: {healthConditions: []}, totalHorizontal: 0, totalVertical: 0}));
      dispatch(resetNav());
    }
  },[]);

  return <DetailForm matrixImages={matrixImages} setMatrixImages={setMatrixImages} />;
}
