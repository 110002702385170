/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Select from "shared-components/Select/Select";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

// import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { amrMissionStatus } from "enums/Constants"; 
import { amrPalletTableHead } from "enums/UserPortal/TableHeaders";
import { filterParam, filterUrl } from 'common/functions';
import { setValues, reset } from "../store/pallet";
import { GetInventoryCount } from "services/UserPortal/AMRService";

import styles from "assets/jss/components/UserPortal/amr.module.scss";

export default function LevelDetails(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.amr.pallet.filter);
  const result = useSelector(store => store.user.amr.pallet.result);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('palletPositionId');

  const rowsPerTable = 10;
  const count = Math.ceil(result.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    window.history.pushState({}, '', filterUrl(filter));
    // if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
    //   getAllStation();
    // }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setValues({filter: {page: 0}}));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <div className={styles.table}>
          <Table
            header={amrPalletTableHead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(result, [orderBy], [order])
              .slice(filter.page * rowsPerTable, filter.page * rowsPerTable + rowsPerTable)
              .map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.palletPositionId}</TableCell>
                    <TableCell>{item.palletType}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.datetime}</TableCell>
                  </TableRow>
                )
              }
            )}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
    </React.Fragment>
  );
}
