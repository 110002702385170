// Farm Capacity per Batch
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import Alert from "shared-components/Modal/Alert";
import Tabs from "shared-components/Tabs/Tabs";

import CageConfiguration from "./CageConfiguration";
import CapacityTable from "./CapacityTable";
import { setSearchText } from "store/general";
import { reset } from "./store/batchCapacity";

export default function BatchCapacity() {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const [tab, setTab] = React.useState(0);
  const [newTab, setNewTab] = React.useState(0);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);

  const handleOnChange_tab = (e, value) => {
    if (isDirty) {
      setOpenAlertModal(!openAlertModal);
      setNewTab(value);
    } else {
      setTab(value);
    }
  }

  const handleButtonClick_exit = () => {
    setOpenAlertModal(false);
    setTab(newTab);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = "Whole Farm Capacity per Batch"
        subheader={
          <Tabs 
            tabs={["Cages & Plant", "Capacity Table"]}
            value={tab} 
            onChange={handleOnChange_tab}
          />
        }
      >
        { tab === 0
        ? <CageConfiguration />
        : <CapacityTable />
        }
      </Card>
      <Alert open={openAlertModal} onConfirm={()=>handleButtonClick_exit()} onCancel={()=>handleOnChange_tab()} />
    </React.Fragment>
  );
}
