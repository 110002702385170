import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';

import VariableCost from "./VariableCost";
import FixedCost from "./FixedCost";
import { setSearchText } from "store/general";
import { renderValue, filterParam } from "common/functions";
import { setValues } from "../store/costOverview";

import { GetWorkOrderCostByWorkOrderId } from "services/UserPortal/CostProductivityService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import styles from "assets/jss/components/UserPortal/costProductivity.module.scss";

export default function CostOverview() {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState(0);
  const isDirty = useSelector(store => store.general.isDirty);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const lotIdList = useSelector(store => store.common.lotIdList);
  const filter = useSelector(store => store.user.costProductivity.costOverview.filter);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, productId: null});

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}, workOrderCost: {}}));
  };

  const getWorkOrderCost = () => {
    if (filter.workOrderId && filter.productId) {
      dispatch(GetWorkOrderCostByWorkOrderId({...filter, page: 0, maxResultCount: 9999}));
    }
  }

  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) {
      dispatch(setValues({filter: {...search}}));
      dispatch(GetProductList(search.workOrderId));
    } else if (workOrderList.length) {
      dispatch(GetProductList(workOrderList[0].id))
      .then((response) => {
        if (response.payload.result && response.payload.result.length) {
          dispatch(setValues({filter: {workOrderId: workOrderList[0].id, productId: response.payload.result[0].id}}));
        }
      });
    } else {
      dispatch(setValues({filter: {workOrderId: null, productId: null}}));
    }
  },[workOrderList]);

  React.useEffect(() => {
    if (newFilter.workOrderId) {
      dispatch(GetProductList(newFilter.workOrderId))
      .then((response) => {
        if (response.payload.result) {
          setNewFilter({...newFilter, productId: response.payload.result[0].id});
        }
      });
    }
  },[newFilter.workOrderId]);

  React.useEffect(() => {
    getWorkOrderCost();
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <VariableCost />
      case 1:
        return <FixedCost />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title = "Cost & Efficiency"
        subheader={
          <React.Fragment>
            { Object.keys(filter).map((key) => {
              if (filter[key]) {
                let label = filter[key];
                if (key === "workOrderId") {
                  const workOrder = workOrderList.find(({id}) => id == filter.workOrderId);
                  label = workOrder && workOrder.workOrderNo;
                }
                if (key === "productId") {
                  const product = productList && productList.find(({id}) => id == filter[key]);
                  label = product && product.productName+" ("+product.productRefNo+")";
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={styles.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
            <Tabs 
              tabs={["Variable Cost", "Fixed Cost"]}
              value={tab} 
              onChange={(e,value) => setTab(value)}
              disabled={isDirty}
            />
          </React.Fragment>
        }
        action={
          <div className={styles.cardHeader}>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: newFilter}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the cost & efficiency
                  <Select
                    className={styles.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                    disabled={!newFilter.workOrderId}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
              disabled={isDirty}
            />
          </div>
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
