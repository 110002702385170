import { isAuthenticated } from 'auth/Authenticate';
import { useEffect, useRef } from 'react';

export const useInterval = (callback, delay) => {
  if (isAuthenticated) {
    const savedCallback = useRef();
    if (!delay) {
      delay = 1000*60;
    }

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);


    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
}