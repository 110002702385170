// Create Edit Device Settings
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { resetManufacturer } from ".";
import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { GetManufacturer } from "services/AdminPortal/ManufacturerService";

import DetailForm from "./DetailForm";
import DeviceForm from "./DeviceForm";

export default function AddEditManufacturer() {
  const dispatch = useDispatch();
  let history = useHistory();
  const [step, setStep] = React.useState(1);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "device-settings");
    if (path[index+1] != "create") {
      dispatch(GetManufacturer(path[index+1]))
      .then(({error}) => {
        if (error) {
          history.push("/admin/device-settings");
        } else {
          dispatch(setDirty(true));
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetManufacturer());
      dispatch(resetNav());
    }
  },[]);

  const renderForm = () => {
    switch(step) {
      case 0:
        return <DetailForm step={step} setStep={setStep} />
      case 1:
        return <DeviceForm step={step} setStep={setStep} />
    }
  }
  
  return (
    <React.Fragment>
      {renderForm()}
    </React.Fragment>
  )
}
