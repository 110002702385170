/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { useRouteSearchPlaceholder } from "hooks";
import _ from "lodash";
// @mui/material components
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// core components
import Navbar from "shared-components/Navbars/Navbar";
import Sidebar from "shared-components/Sidebar/Sidebar";
import TextField from "shared-components/TextField/TextField";
import Prompt from "shared-components/Modal/Prompt";
import AuthAlert from "shared-components/Modal/AuthAlert";
import Chatbot from "shared-components/Chatbot/Chatbot";

import * as Auth from "auth/Authenticate";
import Loading from "error-page/Loading";
import Error from "error-page/Error";
import * as allAdminRoutes from "routes/adminRoutes";
import * as allUserRoutes from "routes/userRoutes";
import {settingsRoutes} from "routes/settingsRoutes";
import { snackbarTime } from "config";
import { setError, setSuccess, setSearchText, setAdminPortal } from "store/general";
import { reset as resetLookup } from "store/lookup";
import { reset as resetCommon } from "store/common";
import { GetUserProfile } from "services/AdminPortal/UserService";
import { GetAllCountries, GetAllCompanySizes, GetAllCurrencies, GetAllIndustries, GetAllTimeZones, GetAllUOMs, GetAllWorkForceTypes } from "services/AdminPortal/GeneralService";
import { GetAllBanks, GetWorkOrderList, GetUserList, GetAllUserUOMs, GetAllRawMaterialInfo, GetAllSupplierInfo, GetAllWarehouseInfo, GetEquipmentList, GetAllLotIdLookup } from "services/UserPortal/CommonLookupService";

import search from "assets/icons/black/search.svg";
import close from "assets/icons/black/close.svg";
import search_disabled from "assets/icons/grey/search.svg";
import styles from "assets/jss/layouts/portal.module.scss";

import { checkPermission } from "common/functions";

export default function Admin({ ...rest }) {
  let history = useHistory();
  const dispatch = useDispatch();
  const contentRef = React.useRef(null);
  const isFirstUpdate = React.useRef(true);
  const isAdminPortal = useSelector(store => store.general.isAdminPortal);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const isDirty = useSelector(store => store.general.isDirty);
  const isLoading = useSelector(store => store.general.isLoading);
  const isNoSnackbar = useSelector(store => store.general.isNoSnackbar);
  const error = useSelector(store => store.general.error);
  const success = useSelector(store => store.general.success);
  const searchText = useSelector(store => store.general.searchText);
  const searchPlaceholder = useRouteSearchPlaceholder();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [chatbotOpen, setChatbotOpen] = React.useState(JSON.parse(localStorage.getItem("chatbotOpen")));

  const hasAdmin = JSON.parse(localStorage.getItem("hasAdmin"));

  let subRoutes = [];
  let mainRoutes = location.pathname.includes("/admin") && hasAdmin ? [...allAdminRoutes.dashboardRoutes, ...allUserRoutes.dashboardRoutes] : allUserRoutes.dashboardRoutes;
  const routesObj = location.pathname.includes("/admin") && hasAdmin ? allAdminRoutes : allUserRoutes;
  Object.keys(routesObj).forEach((key) => {
    if (key !== "dashboardRoutes") {
      if (key.includes("SubRoutes")) {
        mainRoutes=[...routesObj[key], ...mainRoutes];
      } else {
        subRoutes=[...subRoutes, ...routesObj[key]];
      }
    }
  })
  const switchRoutes = (
    <Switch>
      {subRoutes.map((prop, key) => {
        if (prop.layout === location.pathname.includes("/admin") ? "/admin" : "/user") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component && (prop.permissionName ? (checkPermission(prop.permissionName,"readEdit") || (checkPermission(prop.permissionName,"read") && prop.readOnly)) : true) ? prop.component : Error}
              key={key}
            />
          );
        }
      })}
      {mainRoutes.map((prop, key) => {
        if (prop.layout === location.pathname.includes("/admin") ? "/admin" : "/user") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component && (prop.permissionName ? !checkPermission(prop.permissionName, "none") : true) ? prop.component : Error}
              key={key}
            />
          );
        }
      })}
      {settingsRoutes.map((prop, key) => {
        if (prop.layout === "/settings") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component ? prop.component : Error}
              key={key}
            />
          );
        }
      })}
      { location.pathname.includes("/admin") && hasAdmin
      ? <Redirect to="/admin/home" />
      : <Redirect to="/user/home" />
      }
    </Switch>
  );

  const handleOnChange_text = (e) => {
    dispatch(setSearchText(e.target.value));
  };

  const handleOnSearch = (e) => {
    e.preventDefault();
    let pathName = location.pathname;
    if (pathName.includes("/user/")) {
      const arr = pathName.split("/");
      const index = arr.indexOf("user");
      if (isNaN(arr[arr.length-1])) {
        pathName = arr.slice(0, index+2).join("/");
      }
    } else if (pathName.includes("/admin/")) {
      const arr = pathName.split("/");
      const index = arr.indexOf("admin");
      pathName = arr.slice(0, index+2).join("/");
    }
    if (pathName.includes("/search")) {
      history.push(pathName+"?keyword="+searchText);
    } else {
      history.push(pathName+"/search?keyword="+searchText);
    }
  }

  const handleOnClear = () => {
    dispatch(setSearchText(""));
    let pathName = location.pathname;
    const arr = pathName.split("/search");
    history.push(arr[0]);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    } else {
      setMobileOpen(true);
    }
  };

  // common
  React.useEffect(() => {
    const checkUserData = () => {
      if(localStorage.getItem("accessToken")) {
        if (!isAdminPortal) {
          dispatch(resetLookup()),
          dispatch(GetAllBanks()),
          dispatch(GetWorkOrderList()),
          dispatch(GetUserList()),
          dispatch(GetAllUserUOMs()),
          dispatch(GetAllRawMaterialInfo()),
          dispatch(GetAllSupplierInfo()),
          dispatch(GetAllWarehouseInfo()),
          dispatch(GetEquipmentList())
          dispatch(GetAllLotIdLookup());
        }
      }
    }
    checkUserData();
    window.addEventListener("storage", checkUserData);
    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  },[isAdminPortal])

  // lookup
  React.useEffect(() => {
    if(localStorage.getItem("accessToken")) {
      dispatch(GetAllTimeZones()); 
      if (isAdminPortal) {
        dispatch(resetCommon());
        dispatch(GetUserProfile());
        dispatch(GetAllCountries()); 
        dispatch(GetAllCompanySizes()); 
        dispatch(GetAllCurrencies()); 
        dispatch(GetAllIndustries()); 
        dispatch(GetAllUOMs()); 
        dispatch(GetAllWorkForceTypes());
      }
    }
  },[isAdminPortal])

  // React.useEffect(() => {
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // },[])

  React.useEffect(() => {
    contentRef.current.scrollTo(0, 0);
  },[location.pathname])

  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isLoading]);

  // refresh prompt
  React.useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  },[isDirty])

  const content = () => {
    if (error === 401) { // Unauthorised
      return <Error />;
    } else {
      return ( 
        <div className={isLoading ? "hidden" : styles.content}>
          {searchPlaceholder &&
            <form className={styles.search} onSubmit={(e) => handleOnSearch(e)}>
              <TextField 
                id="search"
                variant="outlined" 
                className={styles.textfield}
                value={searchText}
                placeholder={searchPlaceholder}
                InputProps={{
                  startAdornment: 
                    <InputAdornment position="start">
                      {(isDisabled && !location.pathname.includes("company")) || location.pathname.includes("edit") 
                      ? <img src={search_disabled} alt="search_disabled" className={styles.icon_24}/>
                      : <img src={search} alt="search" className={styles.icon_24}/>
                      }
                    </InputAdornment>,
                  endAdornment: searchText &&
                    <InputAdornment position="end">
                      <img src={close} alt="clear" className={styles.clearIcon} onClick={() => handleOnClear()}/>
                    </InputAdornment>,
                }}
                noBorder
                onChange={(e) => handleOnChange_text(e)}
                disabled={(isDisabled && !location.pathname.includes("company")) || location.pathname.includes("edit")} // NOTE: 1 company for now, Add New will be disabled
              />
            </form>
          }
          <div className={styles.container}>
            <div style={{width: "83vw"}}>
              {switchRoutes}
            </div>
          </div>
          <Snackbar 
            open={error != false && !isNoSnackbar} 
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={snackbarTime} 
            onClose={()=>dispatch(setError(false))}
          >
            <Alert severity="error" elevation={6} variant="filled">
              {error}
            </Alert>
          </Snackbar>
          <Snackbar 
            open={success != false} 
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={snackbarTime} 
            onClose={()=>dispatch(setSuccess(false))}
          >
            <Alert severity="success" elevation={6} variant="filled">
              {success}
            </Alert>
          </Snackbar>
        </div>
      )
    }
  }

  return (
    <div className={styles.wrapper}>
      {isLoading && <Loading />}
      { !isLoading && 
        <Sidebar
          routes={isAdminPortal ? allAdminRoutes.dashboardRoutes : allUserRoutes.dashboardRoutes}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          {...rest}
        />
      }
      <div className={styles.mainPanel} ref={contentRef}>
        { !isLoading && 
          <Navbar
            // routes={adminRoutes}
            chatbotOpen={chatbotOpen}
            setChatbotOpen={setChatbotOpen}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
        }
        {content()}
        { !isLoading && chatbotOpen &&
          <Chatbot 
            chatbotOpen={chatbotOpen}
            setChatbotOpen={setChatbotOpen}
          />
        }
      </div>
      <Prompt 
        when={isDirty}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {return isDirty}}
      />
      <AuthAlert open={!Auth.isAuthenticated()} />
    </div>
  );
}
