// Create Edit Reading Unit
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";

import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues, updateRequest, resetLibrary } from "./store/reading";
import { GetFunctionLibrary, CreateOrUpdateFunctionLibrary } from "services/AdminPortal/DeviceService";

import styles from "assets/jss/components/AdminPortal/device.module.scss";

export default function AddEditReadingUnits(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const functionLibrary = useSelector(store => store.admin.device.reading.functionLibrary);
  const library = useSelector(store => store.admin.device.reading.library);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const libraries = functionLibrary.deviceFunctions;

  const handleOnChange_text = (e) => {
    let value = e.target.value;
    if (e.target.id === "shortDeviceFunctionName") {
      value = value.toUpperCase();
    }
    dispatch(updateRequest({[e.target.id] : value}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      const deviceFunctions = _.filter(libraries, (item) => item.id !== library.id);
      const body = {
        ...functionLibrary,
        deviceFunctions: [...deviceFunctions, library],
      };
      dispatch(CreateOrUpdateFunctionLibrary(body))
      .then((response)=>{
        if (response.error) {
          if (response.payload.message.includes("short")) {
            setErrorMsg({field: "shortDeviceFunctionName", msg: "Shortform already exists"});
          } else {
            setErrorMsg({field: "deviceFunctionName", msg: "Reading name already exists"});
          }
        } else {
          resetState();
        }
      })
    }
  }

  const validateFields = () => {
    if (_.isEmpty(library.deviceFunctionName)) {
      setErrorMsg({field: "deviceFunctionName", msg: "You must provide a reading name"});
      return false;
    }
    if (!library.isCombine) {
      if (_.isEmpty(library.shortDeviceFunctionName)) {
        setErrorMsg({field: "shortDeviceFunctionName", msg: "You must provide a shortform"});
        return false;
      } 
      if (!validate.isShortform(library.shortDeviceFunctionName)) {
        setErrorMsg({field: "shortDeviceFunctionName", msg: "Shortform should contain at most 3 upper case characters"});
        return false;
      } 
    }
    setErrorMsg({field: "", msg: ""});
    return true;
  }

  const resetState = () => {
    if (props.setNewOrUpdate) {
      props.setNewOrUpdate(false);
      props.setLibraryId(null);
    } else {
      const prop = props.location && props.location.state;  // from search
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        history.push((prop && prop.prevPath) || "/admin/device");
      });
    }
    dispatch(resetLibrary());
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setDirty(true));
    const prop = props.location && props.location.state;  // from search
    if (prop) {
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "function");
      dispatch(GetFunctionLibrary(prop.libraryId))
      .then((response) => {
        if (response.error) {
          Promise.all([dispatch(setDirty(false))])
          .then(() => {
            history.push((prop.prevPath) || "/admin/device");
          })
        } else {
          const payload = response.payload.result.deviceFunctions.find(({id}) => id === Number(path[index+1]))
          if (payload) {
            dispatch(setValues(payload));
          } else {
            Promise.all([dispatch(setDirty(false))])
            .then(() => {
              history.push((prop.prevPath) || "/admin/device");
            })
          }
        }
      });
    }
    if(props.id) {
      dispatch(setValues(libraries.find(({id}) => id === props.id)));
    }
    // componentDidUnmount
    return () => {
      dispatch(resetLibrary());
      const propLocation = props.location && props.location.state;
      if (propLocation) {
        dispatch(resetNav());
      }
    }
  },[]);

  return (
    <React.Fragment>
      { !library.isCombine
      ? <Card 
          title = {props.id || (props.location && props.location.state) ? "Edit Function" : "Add New Function"}
          cardActions={
            <React.Fragment>
              <Button disabled/>
              <div>
                <Button
                  className={clsx(styles.buttonSecondary, styles.buttonMargin)}
                  onClick={()=>resetState()}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.button}
                  onClick={()=>handleButtonClick_save()}
                >
                  Save
                </Button>
              </div>
            </React.Fragment>
          }
        >
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <TextField 
                label={<React.Fragment>{!props.id && "New "}Reading Name <span className={styles.orange}>*</span></React.Fragment>}
                id="deviceFunctionName"
                variant="outlined" 
                inputProps={{ maxLength: 50 }}
                placeholder="Add Reading Name Here"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(library.deviceFunctionName)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField 
                label={<React.Fragment>{!props.id && "New "}Unit</React.Fragment>}
                id="deviceFunctionUnit"
                variant="outlined" 
                placeholder="Eg. Temperature’s Unit is °C"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(library.deviceFunctionUnit)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField 
                label={<React.Fragment>{!props.id && "New "}Shortform <span className={styles.orange}>*</span></React.Fragment>}
                id="shortDeviceFunctionName"
                variant="outlined" 
                inputProps={{ maxLength: 3 }}
                placeholder="Eg. Temperature’s shortform is T"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(library.shortDeviceFunctionName)}
                errorMsg={errorMsg}
              />
            </Grid>
          </Grid>
        </Card>
      : <Card 
          title = "Edit Combined Functions"
          cardActions={
            <React.Fragment>
              <Button disabled/>
              <div>
                <Button
                  className={clsx(styles.buttonSecondary, styles.buttonMargin)}
                  onClick={()=>resetState()}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.button}
                  onClick={()=>handleButtonClick_save()}
                >
                  Save
                </Button>
              </div>
            </React.Fragment>
          }
        >
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <TextField 
                label={<React.Fragment>{!props.id && "New "}Combined Function Name <span className={styles.orange}>*</span></React.Fragment>}
                id="deviceFunctionName"
                variant="outlined" 
                inputProps={{ maxLength: 50 }}
                placeholder="Add Reading Name Here"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(library.deviceFunctionName)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField 
                label={<React.Fragment>{!props.id && "New "}Shortform <span className={styles.orange}>*</span></React.Fragment>}
                id="shortDeviceFunctionName"
                variant="outlined" 
                inputProps={{ maxLength: 3 }}
                value={renderValue(library.shortDeviceFunctionName)}
                disabled
              />
            </Grid>
          </Grid>
        </Card>
      }
    </React.Fragment>
  );
}
