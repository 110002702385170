import { createSlice } from "@reduxjs/toolkit";

import { GetAllRawMaterialReturn} from "services/UserPortal/RawMaterialService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  returnList: [],
  return: {},
};

const returnRM = createSlice({
  name: "returnRM",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    resetReturn: (state) => {
      state.return = {};
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRawMaterialReturn.fulfilled, (state, action) => {
        state.totalCount= action.payload.result.totalCount;
        state.returnList = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, reset, resetRequest } = returnRM.actions;

// export the reducer
export default returnRM.reducer;