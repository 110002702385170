import { useSelector } from "react-redux";
import _ from "lodash";

import { dashboardRoutes as adminRoutes } from "routes/adminRoutes";
import { dashboardRoutes as userRoutes } from "routes/userRoutes";

export const useRouteSearchPlaceholder = () => {
  const strapiSidebar = useSelector(store => store.strapi.sidebar);
  let routes = adminRoutes;
  if (!location.pathname.includes("/admin")) {
    routes = userRoutes;
  }
  let placeholder = "";
  routes.forEach((route) => {
    const attributes = _.find(strapiSidebar, ({attributes}) => attributes.title==route.name);

    if (window.location.href.indexOf(route.layout + route.path) !== -1) {
      placeholder = attributes ? attributes.attributes.search : route.searchPlaceholder;
    }
  });
  return placeholder;
};