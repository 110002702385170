import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Pagination from "./Pagination";
import styles from "assets/jss/components/UserPortal/home.module.scss";

export default function Alarms() {
  let history = useHistory()
  const results = useSelector(store => store.user.home.alarmAndNotiWidget);
  const [page, setPage] = React.useState(0);

  return (
    <React.Fragment>
      {results.length
      ? <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={0} className={styles.paper}>
                <Typography className={styles.tableTitle}>Message</Typography>
                <Typography className={styles.paperContent}>{results[page] && results[page].message}</Typography>
              </Paper>
            </Grid>
            {results[page] && results[page].operation &&
              <React.Fragment>
                <Grid item xs={12}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Work Order</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].operation.workOrderNo}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Product</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].operation.productRefNo}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Task</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].operation.taskName}</Typography>
                  </Paper>
                </Grid>
              </React.Fragment>
            }
            {results[page] && results[page].planning &&
              <React.Fragment>
                <Grid item xs={12}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Work Order</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].planning.workOrderNo}</Typography>
                  </Paper>
                </Grid>
              </React.Fragment>
            }
            {results[page] && results[page].equipment &&
              <React.Fragment>
                <Grid item xs={12}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Equipment ID</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].equipment.equipmentRefNo}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Location</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].equipment.locationDescription}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>User</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].equipment.userName}</Typography>
                  </Paper>
                </Grid>
              </React.Fragment>
            }
            {results[page] && results[page].rawMaterial &&
              <React.Fragment>
                <Grid item xs={12}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Raw Material ID</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].rawMaterial.rawMaterialName}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Batch</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].rawMaterial.rawMaterialBatchRefNo}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Weight</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].rawMaterial.weight}</Typography>
                  </Paper>
                </Grid>
              </React.Fragment>
            }
            {results[page] && results[page].delivery &&
              <React.Fragment>
                <Grid item xs={12}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Trip ID</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].delivery.tripName}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Driver</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].delivery.driverName}</Typography>
                  </Paper>
                </Grid>
              </React.Fragment>
            }
            {results[page] && results[page].finishedGoods &&
              <React.Fragment>
                <Grid item xs={12}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Work Order</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].finishedGoods.workOrderNo}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Product</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].finishedGoods.productRefNo}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0} className={styles.paper}>
                    <Typography className={styles.tableTitle}>Lot ID</Typography>
                    <Typography className={styles.paperContent}>{results[page] && results[page].finishedGoods.lotId}</Typography>
                  </Paper>
                </Grid>
              </React.Fragment>
            }
          </Grid>
        </React.Fragment>
      : <Typography className={styles.tableTitle}>Nothing to show</Typography>
      }
      <div className={styles.widgetAction}>
        { results.length > 1 &&
          <Pagination 
            count={results.length} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        <Button className={clsx(styles.buttonSecondary, styles.viewAllButton)} onClick={()=>history.push("/user/alarm/details")}>View All</Button>
      </div>
    </React.Fragment>
  );
}
