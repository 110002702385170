import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";
import _ from "lodash";
// import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// core components
import Card from "shared-components/Card/Card";
import Radio from 'shared-components/Radio/Radio';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";

// import AddEditOrder from "./AddEditOrder";
import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from "common/functions";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { orderDetailTableHead } from "enums/UserPortal/TableHeaders";
import { GetSaleOrderById, CancelSaleOrder } from "services/UserPortal/CustomerOrderService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/salesOrder.module.scss";

// import { useRouteCanWrite } from "hooks";

export default function Details() {
  let history = useHistory();
  const dispatch = useDispatch();
  // const canWrite = useRouteCanWrite();
  const saleOrder = useSelector(store => store.user.customerOrder.management.order);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('purchaseOrderNumber');
  const [page, setPage] = React.useState(0);
  // const [isEdit, setIsEdit] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [isOnlyThisOne, setIsOnlyThisOne] = React.useState(true);
  const [orderStarted, setOrderStarted] = React.useState(false);
  const [openInfoModal, setOpenInfoModal] = React.useState(false);

  const count = Math.ceil(saleOrder.subSaleOrders.length / rowsPerTable);
  // const orderType = ["One-off", "Recurring", "Forecast"];

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleModal_delete = () => {
    setOpenWarningModal(false);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleModal_info = () => {
    history.push("/user/sales-order/management");
  }

  const handleButtonClick_delete = () => {
    dispatch(CancelSaleOrder({saleOrderId: saleOrder.id, isOnlyThisOne: isOnlyThisOne}))
    .then((response) => {
      if (!response.error) {
        setOrderStarted(Boolean(response.payload.result));
        setOpenInfoModal(!openInfoModal);
      }
    });
  }

  // const handleButtonClick_edit = () => {
  //   setIsEdit(true);
  // };

  const getSaleOrder = (stopLoading) => {
    // if (!isEdit) {
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "management");
      dispatch(GetSaleOrderById({id: path[index+1], stopLoading}))
      .then(({error}) => {
        if (error) {
          history.push("/user/sales-order/management");
        }
      });
    // }
  }

  // React.useEffect(() => {
  //   getSaleOrder()
  // },[isEdit]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    getSaleOrder();
    // componentDidUnmount
    return () => {
      dispatch(resetNav());
    }
  },[]);

  useInterval(() => {
    getSaleOrder(true);
  });

  // if (isEdit) {
  //   return <AddEditOrder isEdit={isEdit} setIsEdit={setIsEdit} tab={orderType.indexOf(saleOrder.orderType)} />
  // } else {
    return (
      <React.Fragment>
        <Card 
          classes = {{
            title: styles.cardTitle
          }}
          title={
            <React.Fragment>
              Sales Order Details
              <div>
                <Button
                  className={styles.buttonSecondary}
                  onClick={()=>history.goBack()}
                >
                  Management Summary
                </Button>
                {/* {canWrite &&
                  <Button
                    className={clsx(styles.buttonSecondary, styles.actions)}
                    onClick={()=>handleButtonClick_edit()}
                    disabled={saleOrder.saleOrderStaus==="Cancel"}
                  >
                    Edit All
                  </Button>
                } */}
                {/* {canWrite &&
                  <Button
                    className={clsx(styles.buttonSecondary, styles.actions)}
                    onClick={()=>handleModal_delete()}
                    disabled={saleOrder.saleOrderStaus==="Cancel"}
                  >
                    Delete All
                  </Button>
                } */}
              </div>
            </React.Fragment>
          }
          subheader={
            <Grid container spacing={2} className={styles.subheader}>
              <Grid item xs={4}>
                <Typography><span className={styles.subheaderLabel}>Customer:</span> {saleOrder.customerName ? saleOrder.customerName+" ("+saleOrder.customerRefNo+")" : "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography><span className={styles.subheaderLabel}>Delivery Date:</span> {moment(saleOrder.deliveryDate).format("DD/MM/yyyy")}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography><span className={styles.subheaderLabel}>Order Type:</span> {saleOrder.orderType}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography><span className={styles.subheaderLabel}>Customer PO:</span> {saleOrder.purchaseOrderNo}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography><span className={styles.subheaderLabel}>Our Reference:</span> {saleOrder.saleOrderNumber}</Typography>
              </Grid>
              { saleOrder.creatorUserName &&
                <Grid item xs={2}>
                  <Typography><span className={styles.subheaderLabel}>Created By:</span> {saleOrder.creatorUserName}</Typography>
                  <Typography><span className={styles.subheaderLabel}>Timestamp:</span> {moment(saleOrder.creationTime).format('DD/MM/YYYY HH:mm')}</Typography>
                </Grid>
              }
              { saleOrder.modifierUserName &&
                <Grid item xs={2}>
                  <Typography><span className={styles.subheaderLabel}>Edited By:</span> {saleOrder.modifierUserName}</Typography>
                  <Typography><span className={styles.subheaderLabel}>Timestamp:</span> {moment(saleOrder.modificationTime).format('DD/MM/YYYY HH:mm')}</Typography>
                </Grid>
              }
            </Grid>
          }
        >
          <div className={styles.table}>
            <Table
              className={styles.detailTable}
              header={orderDetailTableHead}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            >
              { _.orderBy(saleOrder.subSaleOrders, [orderBy], [order])
              .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
              .map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.productID}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.isRootInclude ? "Y" : "N"}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.isWeightOrder ? "Weight" : "Package"}</TableCell>
                    <TableCell>{item.packageName}</TableCell>
                    <TableCell>{formatNumbers(item.totalNetWeight)}{item.uom}</TableCell>
                    <TableCell>{item.unitPrice && roundTo2Decimal(item.unitPrice)}</TableCell>
                    <TableCell>{(item.unitPrice && item.quantity) && formatNumbers(roundTo2Decimal(item.unitPrice * item.quantity))}</TableCell>
                    <TableCell>{item.subSaleOrderStatus}</TableCell>
                    <TableCell>{item.subSaleOrderNumber}</TableCell>
                    <TableCell>{item.productComponent && item.productComponent.split("(").join(" (")}</TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </div>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
        </Card>
        <Modal
          open={openWarningModal}
          onClose={() => handleModal_warning()}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content={
            <React.Fragment>
              Before you cancel, make sure you read the <b>Cancellation Policy of Sales Order</b>.
              <List>
                <ListItem>
                  For orders which have not been added into the work order, orders can be cancelled.
                </ListItem>
                <ListItem>
                  For orders which have been added into the work order, orders will be treated as excess capacity.
                </ListItem>
              </List>
            </React.Fragment>
          }
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal_warning()}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
        <Modal
          open={openDeleteModal}
          onClose={() => handleModal_delete()}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title={saleOrder.orderType === "One-off" ? "Are you sure?" : "Delete "+saleOrder.orderType+" Event?"}
          content={ saleOrder.orderType === "One-off"
            ? "Do you really want to delete this order? This process cannot be undone."
            : <React.Fragment>
                <div className={styles.radio}>
                  <Radio 
                    id="isOnlyThisOne"
                    checked={isOnlyThisOne}
                    onChange={()=>setIsOnlyThisOne(true)}
                  />
                  <Typography className={styles.radioLabel}>This Order</Typography>
                </div>
                <div className={styles.radio}>
                  <Radio 
                    id="isOnlyThisOne"
                    checked={!isOnlyThisOne}
                    onChange={()=>setIsOnlyThisOne(false)}
                  />
                  <Typography className={styles.radioLabel}>This and following orders under same PO</Typography>
                </div>
              </React.Fragment>
          }
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal_delete()}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleModal_warning()}>Delete</Button>
            </React.Fragment>
          }
        />
        <Modal
          open={openInfoModal}
          onClose={() => handleModal_info()}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title={orderStarted ? "Order Moved" : "Order Cancelled"}
          content={orderStarted
            ? "Order cancelled successfully. However as work order has started for this order, this will be treated as excess capacity." 
            : "Your order has been cancelled successfully"
          }
          actions={
            <Button className={styles.button} onClick={() => handleModal_info()}>OK</Button>
          }
        />
      </React.Fragment>
    );
  // }
}
