import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from "common/functions";
import { setSearchText } from "store/general";
import { setValues } from "../store/conversion";
import { farmConfig } from "enums/Constants";
import { conversionTableHead } from "enums/UserPortal/TableHeaders";
import { GetWorkOrderConversionToProduct } from "services/UserPortal/PlanningService";
import { checkPermission } from "common/functions";

import styles from "assets/jss/components/UserPortal/planning.module.scss";

export default function Conversion() {
  const dispatch = useDispatch();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const filter = useSelector(store => store.user.planning.conversion.filter);
  const conversions = useSelector(store => store.user.planning.conversion.conversions);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null});
  const [date, setDate] = React.useState(moment());
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productID');
  const [page, setPage] = React.useState(0);

  const count = Math.ceil(conversions && conversions.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };

  const getWorkOrderConversion = (stopLoading) => {
    if (filter.workOrderId) {
      dispatch(GetWorkOrderConversionToProduct({...filter, stopLoading}));
    } else {
      dispatch(setValues({conversions: []}))
    }
  }
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search}}));
    } else {
      if (workOrderList.length) {
        dispatch(setValues({filter: {workOrderId: workOrderList[0].id}}));
      } else {
        dispatch(setValues({filter: {workOrderNo: null}}));
      }
    }
  },[workOrderList]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getWorkOrderConversion();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  useInterval(() => {
    getWorkOrderConversion(true);
    setDate(moment());
  });

  return (
    <React.Fragment>
      <Card 
        title={"Conversion to Individual Product"}
        subheader={
          <React.Fragment>
            <Typography>{"Details on " + moment(date).format("DD MMMM YYYY")}</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "workOrderDate" && key !== "page") {
                let label = filter[key];
                if (key === "workOrderId") {
                  const workOrder = workOrderList.find(({id}) => id == filter[key]);
                  label = workOrder && workOrder.workOrderNo;
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={styles.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the conversion list
                  <Select
                    className={styles.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <Table
          className={clsx(styles.table, styles.conversionTable)}
          header={conversionTableHead}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        >
          { _.orderBy(conversions, [orderBy], [order])
          .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
          .map((item,index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.productName}</TableCell>
                <TableCell>{item.productID}</TableCell>
                <TableCell>{formatNumbers(roundTo2Decimal(item.weight))}kg</TableCell>
                <TableCell>{formatNumbers(roundTo2Decimal(item.weightAfterLossRate))}kg</TableCell>
                <TableCell>{formatNumbers(roundTo2Decimal(item.actualProductionWeight))}kg</TableCell>
                <TableCell>{formatNumbers(item.noOfTraysSeeding)}</TableCell>
                <TableCell>{formatNumbers(item.noOfCageSeeding)}</TableCell>
                <TableCell>{formatNumbers(item.noOfTraysGermination)}</TableCell>
                <TableCell>{formatNumbers(item.noOfCageGermination)}</TableCell>
              </TableRow>
            )
          })}
        </Table>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        <div className={styles.linkDiv}>
          {checkPermission("Admin.Capacity", "readEdit")?<Link className={styles.link} to={{pathname: "/admin/capacity/trays-per-configuration"}}>Trays and {farmConfig.cage} Configuration</Link>:""}
          {checkPermission("Admin.Recipe", "readEdit")?<Link className={styles.link} to={{pathname: "/admin/recipe"}}>Recipe Management for Per Tray Requirement</Link>:""}
        </div>
      </Card>
    </React.Fragment>
  );
}
