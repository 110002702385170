import { createSlice } from "@reduxjs/toolkit";
import { GetAllSustainability, GetAllSellingPrice, GetAllGMF, ConvertkWhToCO2 } from "services/UserPortal/SustainabilityService";

// initial state
const initialState = {
  sustainability: [],
  price: [],
  gmf: [],
  conversion: {},
};

const sustainabilityDashboard = createSlice({
  name: "sustainabilityDashboard",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllSustainability.fulfilled, (state, action) => {
        state.sustainability = action.payload.result;
      })
      .addCase(GetAllSellingPrice.fulfilled, (state, action) => {
        state.price = action.payload.result;
      })
      .addCase(GetAllGMF.fulfilled, (state, action) => {
        state.gmf = action.payload.result.items;
      })
      .addCase(ConvertkWhToCO2.fulfilled, (state, action) => {
        state.conversion = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = sustainabilityDashboard.actions;

// export the reducer
export default sustainabilityDashboard.reducer;