
// core components/views for Auth layout
import LoginPage from "components/Authentication/Login";
// import SignupPage from "components/Authentication/Signup";
import RequestResetPasswordPage from "components/Authentication/RequestResetPasswordForm";
import ResetPasswordPage from "components/Authentication/ResetPasswordForm";
// import NotificationPage from "components/Authentication/Notification";

export const loginRoutes = [
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    layout: "",
  },
  // {
  //   path: "/signup",
  //   name: "Signup",
  //   component: SignupPage,
  //   layout: "",
  // },
  {
    path: "/request_reset_password",
    name: "Request Reset Password",
    component: RequestResetPasswordPage,
    layout: "/account",
  },
  {
    path: "/reset_password",
    name: "Reset Password",
    component: ResetPasswordPage,
    layout: "/account",
  },
  // {
  //   path: "/created",
  //   name: "Notification",
  //   component: NotificationPage,
  //   layout: "/account",
  // },
];
