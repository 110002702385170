import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import Autocomplete from "shared-components/Select/Autocomplete";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";
import TextField from "shared-components/TextField/TextField";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { renderValue, filterParam } from 'common/functions';
import { setValues, updateRequest, reset } from ".";
import { cagesTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllCageList, UpdateCage, DeleteCage, GetCageList, GetBarcodeIDList, GetLocationIDList, GetStatusList } from "services/AdminPortal/CagesService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/cage.module.scss";

export default function Cages() {
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const filter = useSelector(store => store.admin.cage.filter);
  const totalCount = useSelector(store => store.admin.cage.totalCount);
  const results = useSelector(store => store.admin.cage.cages);
  const cage = useSelector(store => store.admin.cage.cage);
  const cageList = useSelector(store => store.admin.cage.cageList);
  const barcodeList = useSelector(store => store.admin.cage.barcodeList);
  const locationList = useSelector(store => store.admin.cage.locationList);
  const statusList = useSelector(store => store.admin.cage.statusList);
  const [newFilter, setNewFilter] = React.useState({cageRefNo: null, barcodeNumber: null, cageLocationID: null, status: null, page: 0});
  const [isEdit, setIsEdit] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleOnChange_autocomplete = (target, newValue) => {
    setNewFilter({...newFilter, [target]: newValue});
  };

  const handleButtonClick_deleteTag = (key) => {
    if (!isEdit) {
      dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
    }
  };

  const handleOnEdit = (item) => {
    dispatch(setValues({cage: item}));
    setId(item.id);
    setIsEdit(true);
  }

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnConfirm = () => {
    if (validateFields()) {
      dispatch(UpdateCage())
      .then(() => {
        dispatch(GetAllCageList({...filter}));
        dispatch(setValues({cage: {}}));
        setId(null);
        setIsEdit(false);
      });
    }
  };

  const handleOnCancel = () => {
    dispatch(setValues({cage: {}}));
    setId(null);
    setIsEdit(false);
  };

  const validateFields = () => {
    if (!cage.barcodeNumber) {
      setErrorMsg({field: "barcodeNumber", msg: "Please enter barcode number"});
      return false;
    }
    else {
      setErrorMsg(false);
      return true;
    }
  }

  const handleModal = (id) => {
    setId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteCage(id))
    .then(() =>{
      setId(null);
      dispatch(setValues({filter: {...filter, page: 0}}));
      setOpenModal(!openModal);
    })
  }

  const getAllCages = (stopLoading) => {
    dispatch(GetAllCageList({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllCages();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
    dispatch(GetCageList());
    dispatch(GetBarcodeIDList());
    dispatch(GetLocationIDList());
    dispatch(GetStatusList());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllCages(true);
  });

  return (
    <React.Fragment>
      <Card 
        title = "Cages - Current Listing of Cage ID with Barcode ID & Cage Location ID"
        subheader={
          <React.Fragment>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "page") {
                let label = filter[key];
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={styles.tag}
                    onDelete={() => handleButtonClick_deleteTag(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <div className={styles.cardHeader}>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              disabled={isEdit}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the cage list
                  <Autocomplete
                    className={styles.filterDropdown}
                    id="cageRefNo"
                    inputProps={{ maxLength: 50 }}
                    value={newFilter.cageRefNo}
                    onChange={(e, newValue) => {
                      handleOnChange_autocomplete('cageRefNo', newValue);
                    }}
                    options={cageList.map((listItem) => listItem.cageRefNo)}
                    placeholder="Select a cage"
                    errorMsg={errorMsg}
                  />
                  <Autocomplete
                    className={styles.filterDropdown}
                    id="barcodeNumber"
                    inputProps={{ maxLength: 50 }}
                    value={newFilter.barcodeNumber}
                    onChange={(e, newValue) => {
                      handleOnChange_autocomplete('barcodeNumber', newValue);
                    }}
                    options={barcodeList.map((listItem) => listItem)}
                    placeholder="Select a barcode"
                    errorMsg={errorMsg}
                  />
                  <Autocomplete
                    className={styles.filterDropdown}
                    id="cageLocationID"
                    inputProps={{ maxLength: 50 }}
                    value={newFilter.cageLocationID}
                    onChange={(e, newValue) => {
                      handleOnChange_autocomplete('cageLocationID', newValue);
                    }}
                    options={locationList.map((listItem) => listItem)}
                    placeholder="Select a location"
                    errorMsg={errorMsg}
                  />
                  <Select
                    className={styles.filterDropdown}
                    name="status"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Choose status"
                    value={renderValue(newFilter.status)}
                  >
                    {statusList && statusList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </div>
        }
      >
        <div className={styles.table}>
          <Table
            header={cagesTableHead}
          >
            { results.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{('0'+((filter.page*rowsPerTable)+1+index)).slice(-2)}</TableCell>
                  <TableCell width="20%">{item.cageRefNo}</TableCell>
                  <TableCell width="20%">
                    {isEdit && id === item.id
                    ? <TextField 
                        id="barcodeNumber"
                        variant="outlined" 
                        placeholder="Enter a value"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(cage.barcodeNumber)}
                        errorMsg={errorMsg}
                      />
                    : item.barcodeNumber
                    }
                  </TableCell>
                  <TableCell width="20%">{item.cageLocationId}</TableCell>
                  <TableCell width="20%">{item.status}</TableCell>
                  <TableCell align="right">
                    {isEdit && id === item.id
                    ? <React.Fragment>
                        <IconButton 
                          type="confirm" 
                          onClick={handleOnConfirm}
                        />
                        <IconButton 
                          type="close"
                          onClick={handleOnCancel}
                        />
                      </React.Fragment>
                    : <React.Fragment>
                        <IconButton 
                          type="edit" 
                          onClick={() => handleOnEdit(item)}
                          disabled={isEdit && id !== item.id}
                        />
                        <IconButton 
                          type="delete"
                          onClick={() => handleModal(item.id)}
                          disabled={isEdit && id !== item.id}
                        />
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
            disabled={isEdit}
          />
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this cage? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
