import React from "react";
import clsx from "clsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import Stepper from "@mui/material/Stepper";
import StepConnector from '@mui/material/StepConnector';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// core components
import styles from "assets/jss/shared-components/stepper.module.scss";

export default function CustomStepper({steps, className, ...props}) {

  return (
    <Stepper activeStep={props.activeStep} className={clsx(className, styles.stepper)}>
      {steps.map((label) => {
        return (
          <Step 
            key={label}
            connector={
              <StepConnector
                classes={{
                  line: styles.line
                }}
              />
            }
          >
            <StepLabel
              classes={{
                label: styles.label
              }}
              StepIconProps={{
                classes: {
                  active: styles.step,
                  completed: styles.step,
                }
              }}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

CustomStepper.propTypes = {
  steps: PropTypes.array,
};