import _ from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import { GetCurrentFarmCapacity, GetCapacityCategoryByFarmId, GetAllBankAndCage, GetAllSoftzone, GetAllLocationBySoftzone, GetAllProduct, GetAllProductProcess } from "services/AdminPortal/CapacityService";

// initial state
const initialState = {
  farmCapacity: {},
  categories: [],
  category: {
    productId: []
  },
  bankList: [],
  softzoneList: [],
  locationList: [],
  product: [],
  process: []
};

const farmCapacity = createSlice({
  name: "farmCapacity",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.category = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateCategoryRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.category[key] = action.payload[key];
      })
    },
    resetCategory: (state) => {
      state.category = {
        productId: []
      };
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCurrentFarmCapacity.fulfilled, (state, action) => {
        state.farmCapacity = action.payload.result;
      })
      .addCase(GetCapacityCategoryByFarmId.fulfilled, (state, action) => {
        state.categories = action.payload.result.map((category) => {
          return {
            ...category, 
            productId: category.productList.map((product) => {return product.id})
          }
        });
      })
      .addCase(GetAllBankAndCage.fulfilled, (state, action) => {
        state.bankList = _.orderBy(action.payload.result, ["bankName"], ["asc"]);
      })
      .addCase(GetAllSoftzone.fulfilled, (state, action) => {
        state.softzoneList = action.payload.result.items;
      })
      .addCase(GetAllLocationBySoftzone.fulfilled, (state, action) => {
        state.locationList = action.payload.result[0].locations;
      })
      .addCase(GetAllProduct.fulfilled, (state, action) => {
        state.product = _.orderBy(action.payload.result, ["productName"], ["asc"]);
      })
      .addCase(GetAllProductProcess.fulfilled, (state, action) => {
        state.process = action.payload.result.sort();
      })
  },
});

// export actions
export const { setValues, updateRequest, updateCategoryRequest, addDeleteRequest, resetCategory, reset } = farmCapacity.actions;

// export the reducer
export default farmCapacity.reducer;