import { combineReducers } from "redux";
import reserve from "./reserve";
import issue from "./issue";
import availability from "./availability";
import cost from "./cost";
import maintenance from "./maintenance";
import search from "./search";

const equipmentReducer = combineReducers({
  reserve,
  issue,
  availability,
  cost,
  maintenance,
  search,
});

export default equipmentReducer;