import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import TextField from 'shared-components/TextField/TextField';
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import { minDate } from "config";
import { renderValue, addToEndDate } from "common/functions";
import { setDirty, setSearchText, setSuccess } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateRequest, reset } from "../store/maintenance";
import { GetEquipmentMaintenance, CreateOrUpdateEquipmentMaintenance } from "services/UserPortal/EquipmentService";

import styles from "assets/jss/components/UserPortal/equipment.module.scss";

export default function EditMaintenance(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const maintenance = useSelector(store => store.user.equipment.maintenance.maintenance);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [equipment, setEquipment] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id]: e.target.value}));
  };

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      const newValue = moment(value);
      let payload = {[field]: newValue.format("YYYY-MM-DD")};
      if (field === "maintenanceStartDate") {
        const endDate = addToEndDate(newValue, maintenance.maintenanceEndDate, "days");
        payload = {...payload, maintenanceEndDate: endDate};
      }
      dispatch(updateRequest(payload));
    } else {
      dispatch(updateRequest({[field] : value}));
    }
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateEquipmentMaintenance())
        .then((response) => {
          if (response.error) {
            dispatch(setDirty(true));
          } else {
            setSuccess("Maintenance schedule successfully modified.");
            handleExit();
          }
        });
      })
    }
  };

  const handleExit = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      history.push("/user/equipment/maintenance-status");
    })
  };

  const validateFields = () => {
    if (!moment(maintenance.maintenanceStartDate).isValid()) {
      setErrorMsg({field: "maintenanceStartDate", msg: "Invalid Date"});
      return false;
    }
    if (!moment(maintenance.maintenanceEndDate).isValid()) {
      setErrorMsg({field: "maintenanceEndDate", msg: "Invalid Date"});
      return false;
    }
    if (moment(maintenance.maintenanceEndDate).isBefore(moment(maintenance.maintenanceStartDate))) {
      setErrorMsg({field: "maintenanceEndDate", msg: "End date cannot be before start date"});
      return false;
    }
    setErrorMsg(false);
    return true;
  };

  // componentDidMount
  React.useEffect(() => {
    if (!props.location.state || !props.location.state.equipment) {
      handleExit();
    } else {
      dispatch(setDirty(true));
      setEquipment(props.location.state.equipment);
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "maintenance-status");
      if (path[index+1] !== "create") {
        dispatch(GetEquipmentMaintenance(path[index+1]))
        .then((response) => {
          if (response.error) {
            handleExit();
          } else {
            const result = response.payload.result;
            dispatch(updateRequest({
              ...result, 
              maintenanceStartDate: props.location.state.isEdit 
                ? moment(result.maintenanceStartDate).isAfter(minDate) ? moment(result.maintenanceStartDate).format() : moment().format()
                : moment().format(),
              maintenanceEndDate: props.location.state.isEdit 
                ? moment(result.maintenanceEndDate).isAfter(minDate) ? moment(result.maintenanceEndDate).format() : moment().format()
                : moment().format(),
              isUpdateMaintenanceSchedule: Boolean(props.location.state.isEdit)
            }));  
          }
        });
      } else {
        dispatch(updateRequest({
          id: props.location.state.id, 
          equipmentConfigId: props.location.state.equipment.id, 
          maintenanceStartDate: moment().format(), 
          maintenanceEndDate: moment().format(),
          isUpdateMaintenanceSchedule: Boolean(props.location.state.isEdit)
        }));  
      }
      if (props.location.state.isDisabled) {
        setIsDisabled(props.location.state.isDisabled);
      }
    }
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = "Edit Maintenance Schedule"
        cardActions={ 
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.push("/user/equipment/maintenance-status")}
            >
              Go Back
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Typography className={styles.formTitle}>Equipment Details</Typography>
        <Grid container spacing={4}>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Equipment:</Typography>
            <TextField
              id="equipment"
              variant="outlined"
              value={renderValue(equipment && equipment.equipmentName)}
              disabled
            />
          </Grid>
          {/* <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Equipment ID:</Typography>
            <TextField
              id="equipmentID"
              variant="outlined"
              value={renderValue(props.equipment && props.equipment.equipmentRefNo)}
              disabled
            />
          </Grid> */}
        </Grid>
        <Typography className={styles.formTitle}>Maintenance Period</Typography>
        <Grid container spacing={4}>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>Start Date:</Typography>
            <DateTimePicker
              name="maintenanceStartDate"
              value={maintenance.maintenanceStartDate}
              onChange={(e) => handleOnChange_date(e, "maintenanceStartDate")}
              errorMsg={errorMsg}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={styles.formLabel}>End Date:</Typography>
            <DateTimePicker
              name="maintenanceEndDate"
              value={maintenance.maintenanceEndDate}
              minDate={maintenance.maintenanceStartDate ? moment(maintenance.maintenanceStartDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
              onChange={(e) => handleOnChange_date(e, "maintenanceEndDate")}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={8} className="flex">
            <Typography className={styles.formLabel}>Remarks:</Typography>
            <TextField 
              id="remark"
              variant="outlined" 
              inputProps={{ maxLength: 200 }}
              placeholder="Enter remarks"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(maintenance.remark)}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
