import { createSlice } from "@reduxjs/toolkit";

import { GetAllRawMaterialBatchByWarehouseId, GetAllRawMaterialBatchIdLookUp } from "services/UserPortal/WarehouseService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  rm: [],
  // Lookup
  allRackNumber: [],
  rmBatchList: []
};

const rmWarehouse = createSlice({
  name: "rmWarehouse",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRawMaterialBatchByWarehouseId.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.rm = action.payload.result.items;
      })
      .addCase(GetAllRawMaterialBatchIdLookUp.fulfilled, (state, action) => {
        state.rmBatchList = action.payload.result;
      })
  },
});

// export actions
export const { setValues, reset } = rmWarehouse.actions;

// export the reducer
export default rmWarehouse.reducer;