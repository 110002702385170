import { createSlice } from "@reduxjs/toolkit";

import { GetAllWarehouses, GetWarehouseDetailByWarehouseId } from "services/UserPortal/MonitoringService";

// initial state
const initialState = {
  detail: {},
  //lookup
  warehouseList: [],
};

const storageMonitoring = createSlice({
  name: "storageMonitoring",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllWarehouses.fulfilled, (state, action) => {
        state.warehouseList = action.payload.result && action.payload.result.items;
      })
      .addCase(GetWarehouseDetailByWarehouseId.fulfilled, (state, action) => {
        state.detail = action.payload.result;
      })
  },
});

// export actions
export const { setValues, reset } = storageMonitoring.actions;

// export the reducer
export default storageMonitoring.reducer;