import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import {FomsMS} from "../index";

export const GetMonitoringInfoByBankId = createAsyncThunk(
  "widget/getMonitoringInfoByBankId", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      bankId: id,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Widget/GetMonitoringInfoByBankId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWidgetSetting = createAsyncThunk(
  "widget/getWidgetSetting", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Widget/GetWidgetSetting", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllWidget = createAsyncThunk(
  "widget/getAllWidget", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Widget/GetAllWidget", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteWidget = createAsyncThunk(
  "widget/deleteWidget", 
  async (widgetName, {rejectWithValue}) => {
    try {
      const userDetail = JSON.parse(localStorage.getItem("userDetail"));
      const params = {
        widgetName,
        farmId: find(userDetail.farms, "isActive").id,
      };
      const response = await FomsMS.delete("/userportal/Widget/DeleteWidget", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateWidgetSetting = createAsyncThunk(
  "widget/createOrUpdateWidgetSetting", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...param,
      userId: userDetail.id,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/userportal/Widget/CreateOrUpdateWidgetSetting", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);