import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllUnitOfMeasurement = createAsyncThunk(
	"vehicle/getAllUnitOfMeasurement", 
	async (_, {rejectWithValue}) => {
			try {
			const response = await FomsMS.get("/Vehicle/GetAllUnitOfMeasurement");
			return response.data;
			} catch (error) {
			return rejectWithValue(error);
			}
	},
);

export const GetAllVehicle = createAsyncThunk(
  "vehicle/getAllVehicle", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Vehicle/GetAllVehicle", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetVehicle = createAsyncThunk(
  "vehicle/getVehicle", 
  async (Id, {rejectWithValue}) => {
    const params = {Id};
    try {
      const response = await FomsMS.get("/Vehicle/GetVehicle", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteVehicle = createAsyncThunk(
  "vehicle/deleteVehicle", 
  async (Id, {rejectWithValue}) => {
    const params = {Id};
    try {
      const response = await FomsMS.delete("/Vehicle/DeleteVehicle", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateVehicle = createAsyncThunk(
  "vehicle/createOrUpdateVehicle", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const vehicle = getState().admin.vehicle.vehicle;
    let body = {
      ...vehicle,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/Vehicle/CreateOrUpdateVehicle", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProposedVehicleRefNo = createAsyncThunk(
  "vehicle/getProposedVehicleRefNo", 
  async (data, {rejectWithValue}) => {
    const params = data;
    try {
      const response = await FomsMS.get("/Vehicle/GetProposedVehicleRefNo", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllVehicleType = createAsyncThunk(
  "vehicle/getAllVehicleType", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      MaxResultCount: 9999
    }
    try {
      const response = await FomsMS.get("/Vehicle/GetAllVehicleType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetVehicleType = createAsyncThunk(
  "vehicle/getVehicleType", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      id,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/Vehicle/GetVehicleType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteVehicleType = createAsyncThunk(
  "vehicle/deleteVehicleType", 
  async (Id, {rejectWithValue}) => {
    const params = {Id};
    try {
      const response = await FomsMS.delete("/Vehicle/DeleteVehicleType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateVehicleType = createAsyncThunk(
  "vehicle/createOrUpdateVehicleType", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const vehicleType = getState().admin.vehicle.vehicleType;
    let body = {
      ...vehicleType,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/Vehicle/CreateOrUpdateVehicleType", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchVehicle = createAsyncThunk(
  "vehicle/searchVehicle", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Vehicle/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);