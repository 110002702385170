import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Bank from "shared-components/Bank/Bank";
import Modal from "shared-components/Modal/Modal";
import Switch from "shared-components/Switch/Switch";
import Tabs from "shared-components/Tabs/Tabs";

import { useInterval } from 'common/utils';
import { farmConfig } from "enums/Constants";
import { controlModeLookup } from "enums/Lookups";
import { setDirty, setSuccess } from "store/general";
import { GetAllDeviceControl, SendControlCommandToDevice, SendControlCommandToDevices, GetControlMode, SetControlMode } from "services/UserPortal/ClimateService";
import { GetAllSensorControlDeviceFunction } from "services/UserPortal/CommonLookupService";
import { UpdateLightingDeviceSetting } from "services/AdminPortal/DeviceService";

import DeviceControlDetails from "./DeviceControlDetails";
import DetailModal from "./DetailModal";
import DeviceDetail from "./DeviceDetail";
import light from "assets/icons/grey/light.svg";
import device from "assets/icons/grey/device.svg";
import cage from "assets/icons/grey/cage.svg";
import styles from "assets/jss/components/UserPortal/climate.module.scss";

export default function DeviceControlBank(props) {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const bays = useSelector(store => store.user.climate.deviceControl.bays);
  const functionList = useSelector(store => store.common.functionList);
  const [selectedFunction, setSelectedFunction] = React.useState(null);
  const [selectedControl, setSelectedControl] = React.useState(controlModeLookup[0].key);
  const [selectedDevice, setSelectedDevice] = React.useState({});
  const [selectedDevices, setSelectedDevices] = React.useState([]);
  const [openDetailModal, setOpenDetailModal] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [isTurnOn, setIsTurnOn] = React.useState(false);
  const [isTurnOn2, setIsTurnOn2] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const handleOnChange_text = (e) => {
    setSelectedDevice({...selectedDevice, [e.target.id]: e.target.value});
  };

  const handleButtonClick_switch = () => {
    setSelectedDevice({...selectedDevice, controlValue: !isTurnOn ? 1 : 0});
    setIsTurnOn(!isTurnOn);
  };

  const handleButtonClick_switch2 = () => {
    setSelectedDevice({...selectedDevice, controlValue2: !isTurnOn2 ? 1 : 0});
    setIsTurnOn2(!isTurnOn2);
  };

  const handleOnChange_select = (e) => {
    if (e.target.name !== "mode") {
      dispatch(setDirty(true));
    }
    setSelectedControl(e.target.value);
  };

  const handleButtonClick_adjust = () => {
    if (tab === 0) {
      dispatch(SendControlCommandToDevice({deviceId: selectedDevice.id, deviceRefNo: device.deviceRefNo, controlType: "Auto", controlValue: selectedDevice.controlValue, controlValue2: selectedDevice.controlValue2}))
      .then(({error}) => {
        if (!error) {
          dispatch(setDirty(false));
          setOpenModal(false);
          handleButtonClick_exit();
          dispatch(setSuccess("Success! Changes will be reflected in a few minutes."));
        }
      });
    } else {
      dispatch(UpdateLightingDeviceSetting({id: selectedDevice.id, isFollowNormalLight: selectedDevice.isFollowNormalLight, isFollowReceipt: selectedDevice.isFollowReceipt}))
      .then(({error}) => {
        if (!error) {
          dispatch(setDirty(false));
          dispatch(setSuccess("Success! Changes will be reflected in a few minutes."));
        }
      });
    }
  }

  const handleButtonClick_view = () => {
    dispatch(setDirty(openDetailModal));
    setOpenDetailModal(!openDetailModal);
  }

  const handleModal = () => {
    setOpenModal(!openModal);
  }

  const handleButtonClick_save = () => {
    if (selectedDevices.length) {
      dispatch(SendControlCommandToDevices(selectedDevices))
      .then((response) => {
        if (!response.error) {
          dispatch(setSuccess("Success! Changes will be reflected in a few minutes."));
          handleButtonClick_exit();
        }
      });
    } else {
      dispatch(SetControlMode({controlMode: selectedControl, bankId: props.bank.id, devcieFunctionId: selectedFunction}))
      .then(() => {
        handleButtonClick_exit();
      });
    }
  }

  const handleOnClick_cell = (device) => {
    if (device.controlValue || device.controlValue2) {
      handleModal();
      setSelectedDevice({...device, deviceId: device.id});
      setIsTurnOn(Boolean(device.controlValue));
      setIsTurnOn2(Boolean(device.controlValue2));
    } else {
      dispatch(setDirty(true));
      let payload = _.cloneDeep(selectedDevices);
      const index = selectedDevices.findIndex(({deviceId}) => deviceId === device.id);
      if (index !== -1) {
        payload.splice(index,1);
      } else {
        payload = [...payload, {...device, deviceId: device.id, controlType: "Auto", controlValue: 1, controlValue2: device.isTwoChannel ? 1 : 0}];
      }
      if (payload.length === 0) {
        dispatch(setDirty(false));
      }
      setSelectedDevices(payload);
    }
  }

  const handleButtonClick_exit = () => {
    dispatch(setDirty(false));
    setSelectedDevices([]);
    setSelectedDevice({});
    dispatch(GetAllSensorControlDeviceFunction({isControl: true}))
    .then((response)=>{
      if (!response.error) {
        const result = response.payload.result;
        setSelectedFunction(result.length && result[0].id);
      }
    });
  }

  const getDeviceControl = (stopLoading) => {
    if (!isDirty && selectedFunction) {
      dispatch(GetControlMode({bankId: props.bank.id, devcieFunctionId: selectedFunction}))
      .then((response) => {
        setSelectedControl(response.payload.result);
      });
      dispatch(GetAllDeviceControl({bankId: props.bank.id, deviceFunctionId: selectedFunction, stopLoading}));
    }
  }
  
  React.useEffect(() => {
    getDeviceControl();
  },[props.bank.id, selectedFunction]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllSensorControlDeviceFunction({isControl: true}))
    .then((response)=>{
      if (!response.error) {
        const result = response.payload.result;
        setSelectedFunction(result.length && result[0].id);
      }
    });
    // componentDidUnmount
    return () => {
      // dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getDeviceControl(true);
  });

  return (
    <React.Fragment>
      <Card 
        title={props.bank.bankName}
        classes={{
          root: styles.cardPaper,
        }}
        action={
          <React.Fragment>
            <Typography className={styles.label}>Device Function:</Typography>
            <Select
              className={styles.cardActionDropdown}
              name="deviceFunctionId"
              onChange={(e)=>setSelectedFunction(e.target.value)}
              placeholder="Select a device function"
              value={selectedFunction}
              disabled={isDirty}
            >
              {functionList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.deviceFunctionName}</MenuItem>;
              })} 
            </Select>
            <Typography className={styles.label}>Control Mode:</Typography>
            <Select
              className={styles.cardActionDropdown}
              name="mode"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a mode"
              value={selectedControl}
              disabled={isDirty}
            >
              {controlModeLookup.map((item, index) => {
                return <MenuItem key={index} value={item.key}>{item.displayName}</MenuItem>;
              })} 
            </Select>
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <div />
            <div>
              <Button 
                className={styles.buttonSecondary}
                onClick={()=>handleButtonClick_exit()}
                disabled={!isDirty}
              >
                Cancel
              </Button>
              <Button 
                className={clsx(styles.button, styles.buttonMargin)}
                onClick={()=>handleButtonClick_view()}
                disabled={!isDirty}
              >
                View Details
              </Button>
              <Button 
                className={clsx(styles.button, styles.buttonMargin)}
                onClick={()=>handleButtonClick_save()}
                disabled={!isDirty}
              >
                {selectedControl === "Manual" ? "Save" : "Turn On"}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <div className={styles.bankView}>
          <Bank 
            type="climate"
            isManual={selectedControl === "Manual"}
            levels={props.bank.numberOfLevels}
            bays={props.bank.numberOfBays}
            onCellClick={(e) => selectedControl==="Auto" && handleOnClick_cell(e)}
            data={bays?bays:[]}
            selected={selectedDevices}
          />
        </div>
        <div className={styles.legend}>
          <div className={clsx(styles.colorBox, styles.off)} /> Device Off
          <div className={clsx(styles.colorBox, styles.notUse)} /> Device Not in Use
          <div className={clsx(styles.colorBox, styles.on)} /> Device On
          <div className={clsx(styles.colorBox, styles.selected)} /> Device Selected
          <div className={clsx(styles.colorBox, styles.notAvailable)} /> Not Available
        </div>
        <div className={styles.legend}>
          <img className={clsx(styles.icon, styles.legendIcon)} src={light} alt="light" /> Light
          <img className={clsx(styles.icon, styles.legendIcon)} src={device} alt="device" /> Device
          <img className={clsx(styles.icon, styles.legendIcon)} src={cage} alt="cage" /> {farmConfig.cage}
        </div>
      </Card>
      {selectedDevices && <DeviceControlDetails selectedDevices={selectedDevices} selectedFunction={selectedFunction} />}
      <Modal
        className={styles.detailModal}
        classes={{
          content: styles.detailModalContent
        }}
        open={openModal}
        onClose={() => handleModal()}
        title={"Light Adjustment for "+farmConfig.cage+" "+selectedDevice.cageRefNo+" ("+selectedDevice.deviceRefNo+")"}
        content={
          <React.Fragment>
            <Tabs 
              className={styles.modalTab}
              tabs={["Light Adjustment", "Device Details"]}
              value={tab} 
              onChange={(e, value) => setTab(value)}
            />
            {tab === 0
            ? <Grid container spacing={4} className={styles.modalGrid}>
                <Grid item xs={4} className={styles.modalGridItem}>
                  <Typography className={styles.label}>Light Switch {selectedDevice.isTwoChannel && "(Channel 1)"}:</Typography>
                </Grid>
                <Grid item xs={8} className={styles.modalGridItem}>
                  <Switch 
                    checked={isTurnOn}
                    onChange={() => handleButtonClick_switch()}
                  />
                </Grid>
                <Grid item xs={4} className={styles.modalGridItem}>
                  <Typography className={styles.label}>Light Intensity {selectedDevice.isTwoChannel && "(Channel 1)"}:</Typography>
                </Grid>
                <Grid item xs={7} className={styles.modalGridItem}>
                  <TextField
                    id="controlValue"
                    type="Number"
                    variant="outlined" 
                    onInput={(e)=>{ 
                      e.target.value = e.target.value > 100 ? 100 : Math.max(1, parseInt(e.target.value)).toString().slice(0,3)
                    }}
                    onChange={(e)=>handleOnChange_text(e)}
                    placeholder="Enter value"
                    value={!isTurnOn ? "" : selectedDevice.controlValue}
                    disabled={!isTurnOn}
                  />
                </Grid>
                {selectedDevice.isTwoChannel &&
                  <React.Fragment>
                    <Grid item xs={4} className={styles.modalGridItem}>
                      <Typography className={styles.label}>Light Switch (Channel 2):</Typography>
                    </Grid>
                    <Grid item xs={8} className={styles.modalGridItem}>
                      <Switch 
                        checked={isTurnOn2}
                        onChange={() => handleButtonClick_switch2()}
                      />
                    </Grid>
                    <Grid item xs={4} className={styles.modalGridItem}>
                      <Typography className={styles.label}>Light Intensity {selectedDevice.isTwoChannel && "(Channel 2)"}:</Typography>
                    </Grid>
                    <Grid item xs={7} className={styles.modalGridItem}>
                      <TextField
                        id="controlValue2"
                        type="Number"
                        variant="outlined" 
                        onInput={(e)=>{ 
                          e.target.value = e.target.value > 100 ? 100 : Math.max(1, parseInt(e.target.value)).toString().slice(0,3)
                        }}
                        onChange={(e)=>handleOnChange_text(e)}
                        placeholder="Enter value"
                        value={!isTurnOn2 ? "" : selectedDevice.controlValue2}
                        disabled={!isTurnOn2}
                      />
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
            : <DeviceDetail selectedFunction={selectedFunction} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} />
            }
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal()}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_adjust()}>Confirm</Button>
          </React.Fragment>
        }
      />
      <DetailModal openDetailModal={openDetailModal} selectedDevices={selectedDevices} selectedFunction={selectedFunction} handleButtonClick_view={handleButtonClick_view} />
    </React.Fragment>
  );
}
