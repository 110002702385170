import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";
import { rowsPerTable } from "config";

import {FomsMS} from "../index";

export const GetAllWorkOrderLookUp = createAsyncThunk(
  "costAndProductivity/getAllWorkOrderLookUp", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetAllWorkOrderLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSubSaleOrderLookup = createAsyncThunk(
  "costAndProductivity/getSubSaleOrderLookup", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetSubSaleOrderLookup", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCustomerLookUp = createAsyncThunk(
  "costAndProductivity/getCustomerLookUp", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetCustomerLookUp", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllCostType = createAsyncThunk(
  "costAndProductivity/getAllCostType", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetAllCostType", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllCostCategory = createAsyncThunk(
  "costAndProductivity/getAllCostCategory", 
  async (value, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      costType: value
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetAllCostCategory", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductListByWorkOrderId = createAsyncThunk(
  "costAndProductivity/getProductListByWorkOrderId", 
  async (id, {rejectWithValue}) => {
    const params = {
      workOrderId: id,
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetProductListByWorkOrderId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWorkOrderCostByWorkOrderId = createAsyncThunk(
  "costAndProductivity/getWorkOrderCostByWorkOrderId", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetWorkOrderCostByWorkOrderId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllUnitOfMeasurement = createAsyncThunk(
  "costAndProductivity/GetAllUnitOfMeasurement", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetAllUnitOfMeasurement", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Cost Overview
export const GetCostSettingLookUpByCostCategoryId = createAsyncThunk(
  "costAndProductivity/getCostSettingLookUpByCostCategoryId", 
  async (id, {rejectWithValue}) => {
    const params = {
      costCategoryId: id,
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetCostSettingLookUpByCostCategoryId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTotalHarvestedWeightbyWOIdAndProductId = createAsyncThunk(
  "costAndProductivity/getTotalHarvestedWeightbyWOIdAndProductId", 
  async (param, {rejectWithValue}) => {
    const params = {param};
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetTotalHarvestedWeightbyWOIdAndProductId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateWorkOrderCost = createAsyncThunk(
  "costAndProductivity/createOrUpdateWorkOrderCost", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const detail = getState().user.costProductivity.costOverview.detail;
    let body = {
      ...detail,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/CostAndProductivity/CreateOrUpdateWorkOrderCost", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteWorkOrderCost = createAsyncThunk(
  "costAndProductivity/deleteWorkOrderCost", 
  async (id, {rejectWithValue}) => {
    const params = {
      id: id
    }
    try {
      const response = await FomsMS.delete("/userportal/CostAndProductivity/DeleteWorkOrderCost", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Harvest
export const GetWorkOrderRevenue = createAsyncThunk(
  "costAndProductivity/getWorkOrderRevenue", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetWorkOrderRevenue", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Report
export const GetProfitAndLoss = createAsyncThunk(
  "costAndProductivity/getProfitAndLoss", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetProfitAndLoss", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCostingBySaleOrder = createAsyncThunk(
  "costAndProductivity/getCostingbySaleOrder", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetCostingbySaleOrder", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTotalRevenuebySaleOrder = createAsyncThunk(
  "costAndProductivity/getTotalRevenuebySaleOrder", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetTotalRevenuebySaleOrder", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCostingbyProduct = createAsyncThunk(
  "costAndProductivity/getCostingbyProduct", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetCostingbyProduct", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTotalRevenueByProduct = createAsyncThunk(
  "costAndProductivity/getTotalRevenueByProduct", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetTotalRevenueByProduct", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTotalCostByCustomer = createAsyncThunk(
  "costAndProductivity/getTotalCostByCustomer", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetTotalCostByCustomer", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTotalRevenueByCustomer = createAsyncThunk(
  "costAndProductivity/getTotalRevenueByCustomer", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetTotalRevenueByCustomer", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadProfitAndLoss = createAsyncThunk(
  "costAndProductivity/downloadProfitAndLoss", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    const config = {
      responseType: 'blob',
      params,
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/DownloadProfitAndLoss", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadTotalCostBySaleOrder = createAsyncThunk(
  "costAndProductivity/downloadTotalCostBySaleOrder", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    const config = {
      responseType: 'blob',
      params,
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/DownloadTotalCostBySaleOrder", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadTotalRevenueBySaleOrder = createAsyncThunk(
  "costAndProductivity/downloadTotalRevenueBySaleOrder", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    const config = {
      responseType: 'blob',
      params,
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/DownloadTotalRevenueBySaleOrder", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadTotalCostByProduct = createAsyncThunk(
  "costAndProductivity/downloadTotalCostByProduct", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    const config = {
      responseType: 'blob',
      params,
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/DownloadTotalCostByProduct", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadTotalRevenueByProduct = createAsyncThunk(
  "costAndProductivity/downloadTotalRevenueByProduct", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    const config = {
      responseType: 'blob',
      params,
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/DownloadTotalRevenueByProduct", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadTotalCostByCustomer = createAsyncThunk(
  "costAndProductivity/downloadTotalCostByCustomer", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    const config = {
      responseType: 'blob',
      params,
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/DownloadTotalCostByCustomer", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadTotalRevenueByCustomer = createAsyncThunk(
  "costAndProductivity/downloadTotalRevenueByCustomer", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    params.append("farmId", find(userDetail.farms, "isActive").id);
    const config = {
      responseType: 'blob',
      params,
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/DownloadTotalRevenueByCustomer", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Settings
export const GetAllCostSetting = createAsyncThunk(
  "costAndProductivity/getAllCostSetting", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetAllCostSetting", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCostSettingById = createAsyncThunk(
  "costAndProductivity/getCostSettingById", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/CostAndProductivity/GetCostSettingById", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateCostSetting = createAsyncThunk(
  "costAndProductivity/createOrUpdateCostSetting", 
  async (_, {getState, rejectWithValue}) => {
    const setting = getState().user.costProductivity.settings.setting;
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      ...setting,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/CostAndProductivity/CreateOrUpdateCostSetting", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteCostSetting = createAsyncThunk(
  "costAndProductivity/deleteCostSetting", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/userportal/CostAndProductivity/DeleteCostSetting", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);