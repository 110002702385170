// Create Edit Product
// Step 3: Create Raw Material/Equipment
import React from "react";

import CreateRawMaterial from "./CreateRawMaterial";
import CreateEquipment from "./CreateEquipment";

export default function CreateRMEquip(props) {
  return (
    <React.Fragment>
      <CreateRawMaterial {...props} />
      <CreateEquipment {...props} />
    </React.Fragment>
  )
}
