import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import InputAdornment from "@mui/material/InputAdornment";
// core components
import TextField from "shared-components/TextField/TextField";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Switch from "shared-components/Switch/Switch";

import { GetAllEnergyDevices } from "services/UserPortal/SmartMeteringService";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import search from "assets/icons/grey/search.svg";
import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";

export default function ParamList(props) {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const deviceList = useSelector(store => store.user.smartMetering.parameters.deviceList);
  const [detailed, setDetailed] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState(""); // for device list
  const [collapsed, setCollapsed] = React.useState([]);

  const handleOnChange_switch = () => {
    setDetailed(!detailed);
  };

  const handleOnCollapse = (value, index) => {
    let payload = _.cloneDeep(collapsed);
    payload[index] = value;
    setCollapsed(payload);
  }

  const handleOnChange_text = (e) => {
    setSearchValue(e.target.value);
  }

  const handleOnChange_checkbox = (e) => {
    if (detailed) {
      let payload = _.cloneDeep(props.selected);
      if (payload.includes(Number(e.target.value))) {
        payload.splice(payload.indexOf(Number(e.target.value)), 1);
      } else {
        payload.push(Number(e.target.value));
      }
      props.setSelected(payload);
    }
  };
  
  const handleOnChange_checkAll = (e) => {
    if (detailed) {
      const device = deviceList.find(({mainDeviceName}) => mainDeviceName === e.target.value);
      let payload = _.cloneDeep(props.selected);
      if (device.detailList.every(({address}) => payload.includes(Number(address)))) {
        const addressList = device.detailList.map(({address}) => address);
        payload = payload.filter((element) => !addressList.filter((add) => add === element).length);
      } else {
        payload = _.uniq([...payload, ...device.detailList.map(({address}) => address)]);
      }
      props.setSelected(payload);
    } else {
      let payload = _.cloneDeep(props.selected);
      if (payload.includes(e.target.value)) {
        payload.splice(payload.indexOf(e.target.value), 1);
      } else {
        payload.push(e.target.value);
      }
      props.setSelected(payload);
    }
  };

  const handleOnClick_clear = () => {
    props.setSelected([]);
  }

  React.useEffect(() => {
    dispatch(GetAllEnergyDevices(searchValue))
    .then((response) => {
      if (response.payload.result) {
        const list = _.flatten(response.payload.result.map(({detailList}) => detailList));
        props.setSelected(list.map(({address}) => address));
      }
    });
  },[searchValue]);
  
  React.useEffect(() => {
    let list = [];
    if (detailed) {
      list = _.flatten(deviceList.map(({detailList}) => detailList)).map(({address}) => address);
    } else {
      list = deviceList.map(({mainDeviceName}) => mainDeviceName);
    }
    props.setSelected(list);
  },[detailed]);
  
  React.useEffect(() => {
    const array = deviceList && deviceList.length > 1 ? new Array(deviceList.length - 1).fill(false) : [];
    setCollapsed(array);
  },[deviceList]);

  return (
    <React.Fragment>
      <TextField 
        id="search"
        variant="outlined" 
        placeholder="Search" 
        value={searchValue}
        InputProps={{
          startAdornment: 
            <InputAdornment position="start">
              <img src={search} alt="search" className={styles.icon_24}/>
            </InputAdornment>,
        }}
        onChange={(e) => handleOnChange_text(e)}
      />
      <div className={styles.listTopComponent}>
        <Typography className={styles.link} onClick={handleOnClick_clear} disabled={!props.selected.length}>Clear Selection</Typography>
        <div className={styles.switchAction}>
          <Typography className={styles.switchLabel}>Device Address:</Typography>
          <Switch 
            checked={Boolean(detailed)}
            onChange={() => handleOnChange_switch()}
          />
        </div>
      </div>
      <List className={styles.list}>
        {deviceList && deviceList.map((item, index)=>{
          return (
            <React.Fragment key={index}>
              <ListItem classes={{gutters: styles.listPadding}}>
                {detailed 
                ? <React.Fragment>
                    <Checkbox 
                      value={item.mainDeviceName} 
                      indeterminate={item.detailList.some(({address}) => props.selected.includes(Number(address))) && !item.detailList.every(({address}) => props.selected.includes(Number(address)))} 
                      checked={item.detailList.every(({address}) => props.selected.includes(Number(address)))} 
                      onChange={(e)=>handleOnChange_checkAll(e)}
                      disabled={isDirty}
                    /> {item.mainDeviceName}
                    <IconButton 
                      className={collapsed[index] ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                      onClick={() => handleOnCollapse(!collapsed[index], index)}
                    >
                      <img className={styles.icon} src={arrow} alt="arrow" />
                    </IconButton>
                  </React.Fragment>
                : <React.Fragment>
                    <Checkbox 
                      value={item.mainDeviceName} 
                      checked={props.selected.includes(item.mainDeviceName)} 
                      onChange={(e)=>handleOnChange_checkAll(e)}
                      disabled={isDirty}
                    /> {item.mainDeviceName}
                  </React.Fragment>
                }
              </ListItem>
              {detailed && collapsed[index] && 
                item.detailList.map((add, i)=>{
                  return (
                    <ListItem key={i} classes={{gutters: styles.subListPadding}}>
                      <Checkbox 
                        value={add.address} 
                        checked={props.selected.includes(Number(add.address))} 
                        onChange={(e)=>handleOnChange_checkbox(e)}
                        disabled={isDirty}
                      /> {add.deviceName}
                    </ListItem>
                  )
                })
              }
            </React.Fragment>
          )
        })}
      </List>
    </React.Fragment>
  );
}
