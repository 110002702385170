import React, {useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import _ from "lodash";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from '@mui/material/CircularProgress';
// core components
import Select from "shared-components/Select/Select";
import Checkbox from "shared-components/Checkbox/Checkbox";
import DateTimePicker from "shared-components/DatePicker/DateTimePicker";

import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { updateRequest, setValues } from "../store/assignment";
import { GetAllVehicles, GetAllDriver, CreateOrUpdateDeliveryTrip } from "services/UserPortal/DeliveryService";

import Maps from "./Maps";
import DragList from "./DragList";
import styles from "assets/jss/components/UserPortal/delivery.module.scss";

export default function AssignModal({stops, setStops, handleModal_details, ...props}) {
  const dispatch = useDispatch();
  const vehicleList = useSelector(store => store.user.delivery.assignment.vehicleList);
  const driverList = useSelector(store => store.user.delivery.assignment.driverList);
  const assignment = useSelector(store => store.user.delivery.assignment.assignment);
  const deliveryTripDetails = useSelector(store => store.user.delivery.assignment.deliveryTripDetails);
  const [isFirstOpen, setIsFirstOpen] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [dragList, setDragList] = React.useState(stops);

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name]: e.target.value}))
  };

  const handleButtonClick_checkbox = (e) => {
    dispatch(updateRequest({[e.target.id] : !assignment[e.target.id]}));
  };

  const handleOnChange_date = (value) => {
    let payload = _.cloneDeep(assignment);
    if(value && value.isValid() && value != "Invalid Date") {
      payload.deliveryDate = moment(value).format("YYYY-MM-DD");
      payload.time = moment(payload.time).set({"year": moment(value).get("year"), "month": moment(value).get("month"), "date": moment(value).get("date")});
    } else {
      payload.deliveryDate = value;
    }
    dispatch(setValues({assignment: payload}));
  };

  const handleOnChange_time = (value) => {
    let payload = _.cloneDeep(assignment);
    if(value && value.isValid() && value != "Invalid Date") {
      payload.time = value;
      payload.deliveryTime = moment(value).format("HH:mm");
    } else {
      payload.time = value;
      payload.deliveryTime = value;
    }
    dispatch(setValues({assignment: payload}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateDeliveryTrip())
        .then(({error})=> {
          if (!error) {
            props.onClose();
            dispatch(setValues({assignment: {}, deliveryTripDetails: []}));
            setStops([]);
            location.reload();
          } else {
            dispatch(setDirty(true));
          }
        });
      })
    }
  }

  const handleButtonClick_cancel = () => {
    dispatch(setDirty(false));
    setErrorMsg(false);
    setIsFirstOpen(true);
    props.onClose();
  }

  const validateFields = () => {
    if (!stops.length) {
      setErrorMsg({field: "stops", msg: "Please select delivery orders"});
      return false;
    }
    if (!assignment.deliveryDate) {
      setErrorMsg({field: "deliveryDate", msg: "Please select a delivery date"});
      return false;
    }
    if (!moment(assignment.deliveryDate).isValid()) {
      setErrorMsg({field: "deliveryDate", msg: "Invalid date"});
      return false;
    }
    if (!assignment.deliveryTime) {
      setErrorMsg({field: "deliveryTime", msg: "Please select a delivery time"});
      return false;
    }
    if (!moment(assignment.time).isValid()) {
      setErrorMsg({field: "deliveryTime", msg: "Invalid time"});
      return false;
    }
    if (!assignment.driverId) {
      setErrorMsg({field: "driverId", msg: "Please select a driver"});
      return false;
    }
    if (!assignment.vehicleId) {
      setErrorMsg({field: "vehicleId", msg: "Please select a vehicle"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const onMoveItem = useCallback((dragIndex, hoverIndex) => {
    const result = _.cloneDeep(dragList);
    const [removed] = result.splice(dragIndex, 1);
    result.splice(hoverIndex, 0, removed);
    result.map((item, index) => {
      item.routeOrder = index+1;
      return item;
    });
    setDragList(result);
  }, [dragList]);

  const onDropItem = useCallback(() => {
    setStops(dragList);
  }, [dragList]);

  React.useEffect(() => {
    setDragList(stops);
  },[stops]);

  React.useEffect(() => {
    dispatch(GetAllVehicles());
    dispatch(GetAllDriver());
  },[]);

  return (
    <React.Fragment>
      <Modal {...props} onClose={handleButtonClick_cancel} className={styles.assignModal}>
        <Paper className={styles.modalPaper}>
          {isFirstOpen &&
          <div className={styles.loadingContainer}>
            <CircularProgress
              variant="indeterminate"
              className={styles.loading}
              thickness={2}
            />
          </div>
          }
          <div className={clsx(styles.leftModal, isFirstOpen && "hidden")}>
            <Typography className={styles.modalTitle}>Assigning Vehicle and Driver</Typography>
            <Typography className={styles.modalSubtitle}>You are currently configuring the delivery details for the following orders:</Typography>
            <Paper className={styles.orderPaper} elevation={0}>
              <List className={styles.doList}>
                <DndProvider backend={HTML5Backend}>
                  {_.map(dragList, (item, index)=>{
                    return (
                      <DragList key={index} stops={stops} setStops={setStops} index={index} id={item.id} item={item} onMoveItem={onMoveItem} onDropItem={onDropItem} />
                    )
                  })}
                </DndProvider>
              </List>
            </Paper>
            {errorMsg.field === "stops" && <Typography className={styles.error}>{errorMsg.msg}</Typography>}
            <Grid container spacing={4}>
              <Grid item xs={4} className={styles.label}>
                <Typography>Select date for delivery:</Typography>
              </Grid>
              <Grid item xs={8}>
                <DateTimePicker
                  name="deliveryDate"
                  placeholder="Select a date"
                  value={assignment.deliveryDate}
                  minDate={moment()}
                  onChange={(e) => handleOnChange_date(e)}
                  errorMsg={errorMsg}
                />
              </Grid>
              <Grid item xs={4} className={styles.label}>
                <Typography>Select time for delivery:</Typography>
              </Grid>
              <Grid item xs={8}>
                <DateTimePicker
                  type="time"
                  name="deliveryTime"
                  placeholder="Select a time"
                  value={assignment.time}
                  onChange={(e) => handleOnChange_time(e)}
                  errorMsg={errorMsg}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={styles.estDetail}>
                  <Typography>Est time to complete {deliveryTripDetails.length} stops: {deliveryTripDetails.map(item => item.estimateTimeInMinute + item.processingTimeInMinute).reduce((prev, curr) => prev + curr, 0)} minutes</Typography>
                  <Typography className={styles.assignLink} onClick={()=>handleModal_details()}>Details</Typography>
                </div>
                <div className={styles.leftContent}>
                  <Checkbox  
                    id="returnToStartPoint"
                    checked={Boolean(assignment.returnToStartPoint)}
                    onChange={(e) => handleButtonClick_checkbox(e)}
                  />
                  <Typography>Return to start point</Typography>
                </div>
              </Grid>
              <Grid item xs={4} className={styles.label}>
                <Typography>Driver:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  name="driverId"
                  onChange={(e)=>handleOnChange_select(e)}
                  placeholder="Select a driver"
                  value={renderValue(assignment.driverId)}
                  errorMsg={errorMsg}
                >
                  {driverList && driverList.map((item, index) => {
                    return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                  })} 
                </Select>
              </Grid>
              <Grid item xs={4} className={styles.label}>
                <Typography>Vehicle:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  name="vehicleId"
                  onChange={(e)=>handleOnChange_select(e)}
                  placeholder="Select a vehicle"
                  value={renderValue(assignment.vehicleId)}
                  errorMsg={errorMsg}
                >
                  {vehicleList && vehicleList.map((item, index) => {
                    return <MenuItem key={index} value={item.id}>{item.vehicleRefNo}</MenuItem>;
                  })} 
                </Select>
              </Grid>
            </Grid>
            <div className={styles.settingsButton}>
              <Button className={styles.buttonSecondary} onClick={() => handleButtonClick_cancel()}>Cancel</Button>
              <Button 
                className={styles.button}
                onClick={() => handleButtonClick_save()}
              >
                Save
              </Button>
            </div>
          </div>
          <div className={clsx(styles.rightModal, isFirstOpen && "hidden")}>
            <Maps 
              stops={stops} 
              setStops={setStops} 
              returnToStartPoint={assignment.returnToStartPoint}
              deliveryTime={assignment.time}
              isFirstOpen={isFirstOpen} 
              setIsFirstOpen={setIsFirstOpen} 
            />
          </div>
        </Paper>
      </Modal>
    </React.Fragment>
  );
}
