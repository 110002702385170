import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clsx from "clsx";
// @mui/material components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// core components
import TextField from "shared-components/TextField/TextFieldWithLabel";

import * as validate from "common/validations";
import { snackbarTime } from "config";
import { setError } from "store/general";
import { updateRequest, reset } from "./store/resetPassword";
import { RequestResetPassword } from "services/AdminPortal/ResetPasswordService";

import user from "assets/icons/black/username.svg";
import styles from "assets/jss/components/Authentication/form.module.scss";

export default function RequestResetPasswordForm() {
  let history = useHistory();
  const dispatch = useDispatch();
  const credentials = useSelector(store => store.credentials.resetPassword);
  const error = useSelector(store => store.general.error);
  const [sentLink, setSentLink] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  
  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (validate.isEmail(credentials.email)) {
      dispatch(RequestResetPassword())
      .then(({error}) => {
        if (!error) {
          setSentLink(true);
          setErrorMsg(false);
        }
      });
    } else {
      setErrorMsg({field: "email", msg: "Please enter a valid email"});
    }
  };

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(setError(false));
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      { !sentLink &&
        <form onSubmit={(e) => handleButtonClick(e)}>
          <Typography className={styles.title}>Reset your password</Typography>
          <Typography className={styles.subtitle}>Don&apos;t worry, we got your back. Type in your email address and we will send you a link</Typography>
          <TextField 
            className={styles.textfield}
            id="email"
            startAdornment={<img src={user} alt="user-line" />}
            label="Email Address"
            variant="filled"
            onChange={(e) => handleOnChange_text(e)}
            errorMsg={errorMsg}
          />
          <Button 
            type="submit" 
            className={clsx(styles.button, styles.buttonStyle)}
            onClick={(e) => handleButtonClick(e)}
          >
            Send Email
          </Button>
          <Button 
            className={clsx(styles.buttonSecondary, styles.buttonStyle)}
            onClick={() => history.push("/login")}
          >
            Back to Log In
          </Button>
        </form>
      }
      { sentLink &&
        <React.Fragment>
          <Typography className={styles.title}>Check Your Inbox</Typography>
          <Typography className={styles.subtitle}>We have sent you a verification email. Open it and tap the change password link to continue.</Typography>
          <Button 
            className={clsx(styles.button, styles.buttonStyle)}
            onClick={(e) => handleButtonClick(e)}
          >
            Resend Email
          </Button>
          <Button 
            className={clsx(styles.buttonSecondary, styles.buttonStyle)}
            onClick={() => history.push("/login")}
          >
            Back to Log In
          </Button>
        </React.Fragment>
      }
      <Snackbar 
        open={error != false} 
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={snackbarTime} 
        onClose={()=>dispatch(setError(false))}
      >
        <Alert severity="error" elevation={6} variant="filled">
          {error}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
