import { createAsyncThunk } from "@reduxjs/toolkit";

import {FomsMS} from "../index";

export const GetAllCountries = createAsyncThunk(
  "general/getAllCountries", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/General/GetAllCountries");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllCompanySizes = createAsyncThunk(
  "general/getAllCompanySizes", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/General/GetAllCompanySizes");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllCurrencies = createAsyncThunk(
  "general/getAllCurrencies", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/General/GetAllCurrencies");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllIndustries = createAsyncThunk(
  "general/getAllIndustries", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/General/GetAllIndustries");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllTimeZones = createAsyncThunk(
  "general/getAllTimeZones", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/General/GetAllTimeZones");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllUOMs = createAsyncThunk(
  "general/getAllUOMs", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/General/GetAllUOMs");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllWorkForceTypes = createAsyncThunk(
  "general/getAllWorkForceTypes", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/General/GetAllWorkForceTypes");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCreditTermList = createAsyncThunk(
  "general/getCreditTermList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/General/GetCreditTermList");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);