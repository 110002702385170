import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import _ from "lodash";

import {
  GetAllRawMaterial, 
  GetRawMaterialType, 
  GetAllRawMaterialType, 
  GetAllRawMaterialVariantType, 
  GetRawMaterial, 
  GetRawMaterialVariantType, 
  GetAllUnitOfMeasurement,
  GetProposedRawMaterialBatchRefNo,
  SearchRawMaterial,
  GetAllSupplier,
  GetAllWarehouses
} from "services/AdminPortal/RawMaterialService";

// initial state
const initialState = {
  rawMaterialFinish: [],
  rawMaterialUnfinish:[],
  allRawMaterialType: [],
  allRawMaterialVariantType: [],
  selectedRawMaterial: {
    isDraft: true,
    isSeed: false,
    isNutrient: false,
    rawMaterialMapping : {},
    maximumLevel: 0,
    minimumOrderQuantity: 0,
    batches: [
      {
        dateOfCreation: moment().format("YYYY-MM-DD"),
        expiryDate: moment().add(1,"y").format("YYYY-MM-DD"),
      }
    ],
  },
  allUnitOfMeasurement: [],
  rawMaterialBatchRefNo: "",
  allRackNumber: [],
  allSupplier: [],
  warehouses: [],
  searchResultsCount: {},
  searchResults: {}
};

// create reducer and action creators
const rawMaterial = createSlice({
  name: "rawMaterial",
  initialState,
  reducers: {
    reset: () => initialState,
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        if (key === "childRawMaterialId" || key === "childValue" || key === "parentValue" || key === "childRawMaterialName") { //cond to update into the rawMaterialMapping nest
          state.selectedRawMaterial.rawMaterialMapping = {[key]: action.payload[key], ...state.selectedRawMaterial.rawMaterialMapping};
        } else {
          state.selectedRawMaterial[key] = action.payload[key];
        }
      });
      if (action.payload.unitOfMeasurement) {
        state.selectedRawMaterial.batches.forEach((array) => {
          array["quantityUOM"] = action.payload.unitOfMeasurement;
        })
      }
    },
    clearRawMaterialMapping: (state) => {
      state.selectedRawMaterial.rawMaterialMapping = {};
    },
    updateBatchForm: (state, action) => {
      const lastBatch = _.last(state.selectedRawMaterial.batches);
      Object.keys(action.payload).forEach((key) => {
        lastBatch[key] = action.payload[key];
      })
    },
    addNewBatch: (state) => {
      state.selectedRawMaterial.batches = [...state.selectedRawMaterial.batches, {dateOfCreation: moment().format("YYYY-MM-DD"),expiryDate: moment().format("YYYY-MM-DD"), quantityUOM: state.selectedRawMaterial.unitOfMeasurement}]
    },
    resetSearch: (state) => {
      state.searchResultsCount = {};
      state.searchResults = {};
    },
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRawMaterial.fulfilled, (state, action) => {
        state.rawMaterialFinish = action.payload.result.rawMaterialFinishList.items;
        state.rawMaterialUnfinish = action.payload.result.rawMaterialUnfinishList.items;
      })
      .addCase(GetRawMaterial.fulfilled, (state, action) => {
        state.selectedRawMaterial = action.payload.result;
      })
      .addCase(GetAllRawMaterialType.fulfilled, (state, action) => {
        state.allRawMaterialType = action.payload.result.items;
      })
      .addCase(GetRawMaterialType.fulfilled, (state, action) => {
        state.selectedRawMaterial.rawMaterialType = action.payload.result;
      })
      .addCase(GetAllRawMaterialVariantType.fulfilled, (state, action) => {
        if(action.payload.result != null) {
          state.allRawMaterialVariantType = action.payload.result.items;
        } else {
          state.allRawMaterialVariantType = [];
        }
      })
      .addCase(GetRawMaterialVariantType.fulfilled, (state, action) => {
        state.selectedRawMaterial.rawMaterialVariantType = action.payload.result;
      })
      .addCase(GetAllUnitOfMeasurement.fulfilled, (state, action) => {
        state.allUnitOfMeasurement = action.payload.result;
      })
      .addCase(GetProposedRawMaterialBatchRefNo.fulfilled, (state, action) => {
        state.rawMaterialBatchRefNo = action.payload.result;
      })
      .addCase(GetAllSupplier.fulfilled, (state, action) => {
        state.allSupplier = action.payload.result.items;
      })
      .addCase(GetAllWarehouses.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.warehouses = action.payload.result.items;
        }
      })
      .addCase(SearchRawMaterial.fulfilled, (state, action) => {
      let rawMaterialList = [];
      let rawMaterialListCount = 0;
      Object.keys(action.payload.result).forEach((key) => {
          if (key === "rawMaterialFinishList" || key === "rawMaterialUnfinishList") {
            rawMaterialListCount += action.payload.result[key] ? action.payload.result[key].totalCount : 0;
            rawMaterialList = action.payload.result[key] ? [...rawMaterialList, ...action.payload.result[key].items] : rawMaterialList;
            if (rawMaterialListCount) {
              state.searchResultsCount.rawMaterialList = rawMaterialListCount;
              state.searchResults.rawMaterialList = rawMaterialList;
            }
          } else {
            if (action.payload.result[key]) {
              state.searchResultsCount[key] = action.payload.result[key].totalCount;
              state.searchResults[key] = action.payload.result[key].items;
            }
          }
        })
      })
  }
});

// export actions
export const { reset, updateRequest, updateBatchForm, addNewBatch, resetBatchForm, resetSearch, setValues, clearRawMaterialMapping } = rawMaterial.actions;

// export the reducer
export default rawMaterial.reducer;