import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

//Lookup
export const GetChamberList = createAsyncThunk(
  "cages/getChamberList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/integration/Cage/GetChamberList");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCageList = createAsyncThunk(
  "cages/getCageList", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      ...param
    }
    try {
      const response = await FomsMS.get("/integration/Cage/GetCageList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetBarcodeIDList = createAsyncThunk(
  "cages/getBarcodeIDList", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      ...param
    }
    try {
      const response = await FomsMS.get("/integration/Cage/GetBarcodeIDList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetLocationIDList = createAsyncThunk(
  "cages/getLocationIDList", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      ...param
    }
    try {
      const response = await FomsMS.get("/integration/Cage/GetLocationIDList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetStatusList = createAsyncThunk(
  "cages/getStatusList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/integration/Cage/GetStatusList");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Main
export const GetAllCageList = createAsyncThunk(
  "cages/getAllCageList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: param.page * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/integration/Cage/GetAllCageList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteCage = createAsyncThunk(
  "cages/deleteCage", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/integration/Cage/DeleteCage", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCagePreview = createAsyncThunk(
  "cages/getCagePreview", 
  async (_, {getState, rejectWithValue}) => {
    const cage = getState().admin.cage.cage;
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let params = {
      ...cage,
      farmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.get("/integration/Cage/GetCagePreview", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateCage = createAsyncThunk(
  "cages/createCage", 
  async (_, {getState, rejectWithValue}) => {
    const cagePreview = getState().admin.cage.cagePreview;
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      cageList: cagePreview,
      farmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.post("/integration/Cage/CreateCage", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateCage = createAsyncThunk(
  "cages/updateCage", 
  async (_, {getState, rejectWithValue}) => {
    const cage = getState().admin.cage.cage;
    let body = {
      ...cage,
    }
    try {
      const response = await FomsMS.patch("/integration/Cage/UpdateCage", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchCages = createAsyncThunk(
  "administrationLog/searchCages", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/integration/Cage/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);