import { createSlice } from "@reduxjs/toolkit";

import { GetAllRawMaterialReserveOrIssue, GetRawMaterialStatusList } from "services/UserPortal/RawMaterialService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  issues: [],
  issuedId: [],
  //filter
  statusList: {},
};

const issueRM = createSlice({
  name: "issueRM",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      state.issuedId = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRawMaterialReserveOrIssue.fulfilled, (state, action) => {
        state.totalCount= action.payload.result.totalCount;
        state.issues = action.payload.result.items;
      })
      .addCase(GetRawMaterialStatusList.fulfilled, (state, action) => {
        state.statusList = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = issueRM.actions;

// export the reducer
export default issueRM.reducer;