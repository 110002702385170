import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import Checkbox from "shared-components/Checkbox/Checkbox";
import IconButton from "shared-components/Button/IconButton";

import Empty from "error-page/Empty";
import { useRouteCanWrite } from "hooks";
import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { filterParam, filterUrl, filterActionTableHead } from 'common/functions';
import { sensorSettingsTableHead } from "enums/UserPortal/TableHeaders";
import { setValues, reset } from "../store/sensorSettings";
import { GetAllSensorSetting, DeleteSensorSetting } from "services/UserPortal/ClimateService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/climate.module.scss";

export default function SensorSettings() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const filter = useSelector(store => store.user.climate.sensorSettings.filter);
  const totalCount = useSelector(store => store.user.climate.sensorSettings.totalCount);
  const sensorSettings = useSelector(store => store.user.climate.sensorSettings.sensorSettings);
  const [newFilter, ] = React.useState({page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('ruleNo');
  const [settingId, setSettingId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleModal_delete = (id) => {
    setSettingId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteSensorSetting(settingId))
    .then(() =>{
      resetState();
    })
  }

  const resetState = () => {
    setSettingId(null);
    dispatch(setValues({ filter: {page: 0} }));
    setOpenModal(!openModal);
  }

  const getAllSensorSetting = (stopLoading) => {
    dispatch(GetAllSensorSetting({...filter, stopLoading}));
  }

  React.useEffect(() => {
    window.history.pushState({}, '', filterUrl(filter));
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllSensorSetting();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);
  
  useInterval(() => {
    getAllSensorSetting(true);
  });

  return (
    <React.Fragment>
      { totalCount > 0 
      ? <React.Fragment>
          <Card 
            title="Sensor Settings List"
          >
            <Table
              className={styles.table}
              header={filterActionTableHead(sensorSettingsTableHead, canWrite)}
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
            >
              { sensorSettings.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{('0'+((filter.page*rowsPerTable)+1+index)).slice(-2)}</TableCell>
                    <TableCell>{item.ruleName}</TableCell>
                    <TableCell>{item.deviceFunctionName}</TableCell>
                    <TableCell>{item.deviceRefNo}</TableCell>
                    <TableCell>
                      <Checkbox 
                        checked={Boolean(item.isActive)}
                        disabled
                      />
                    </TableCell>
                    <TableCell>{moment(item.creationTime).isAfter(minDate) && moment(item.creationTime).format("DD/MM/YYYY")}</TableCell>
                    <TableCell align="right">
                      {canWrite &&
                        <React.Fragment>
                          <Link to={{pathname: "/user/climate/sensor-settings/"+item.id}}>
                            <IconButton type="edit" />
                          </Link>
                          <IconButton 
                            type="delete"
                            onClick={() => handleModal_delete(item.id)}
                          />
                        </React.Fragment>
                      }
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={filter.page+1}
                onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
              />
            }
          </Card>
          <Modal
            open={openModal}
            onClose={() => handleModal_delete(null)}
            icon={<img className={styles.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete this sensor setting? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
                <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
        </React.Fragment>
      : <Empty 
          description="Your page is empty. Add a sensor settings to get started!"
        />
      }
    </React.Fragment>
  );
}
