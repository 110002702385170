// File with all commonly used functions
import _ from "lodash";
import moment from "moment";

import { minDate } from "config";

export const bankFormat = (value) => {
  return _.padStart(value.toString(), 2, '0');
}

export const renderValue = (value) => {
  return value ? String(value) : "";
}

export const roundTo2Decimal = (value) => {
  return parseFloat(value).toFixed(2).replace(/[.,]0+$/, '');
}

export const formatNumbers = (value) => {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } return 0;
}

export const getCurrentTime = (date) => {
  if (date) {
    return moment(date).set({
      hour:   moment().get('hour'),
      minute: moment().get('minute'),
      second: moment().get('second')
    })
  }
  return minDate.set({
    hour:   moment().get('hour'),
    minute: moment().get('minute'),
    second: moment().get('second')
  })
}

export const setStartTime = (start, end) => {
  if (moment(end).isBefore(moment(start))) {
    return moment(end);
  }
  return moment(start);
}

export const setEndTime = (start, end) => {
  if (moment(end).isBefore(moment(start))) {
    return moment(start);
  }
  return moment(end);
}

export const setEndDate = (start, end) => {
  if (moment(end).isBefore(moment(start))) {
    return start;
  }
  return end;
}

export const addToEndDate = (start, end, add) => {
  if (moment(end).isBefore(moment(start))) {
    return moment(start).add(1, add).format();
  }
  return end;
}

//check role permission of the module
export const checkPermission = (name, status) => {
  const permissionList = JSON.parse(localStorage.getItem("permission"));
  const permission = _.find(permissionList, {"name": name}) && _.find(permissionList, {"name": name}).value;
  if (status === "none") {
    return permission === status || permission === undefined;
  }
  return permission === status;
}

//remove the action column in the table header
export const filterActionTableHead = (tableHead, canWrite) => {
  if(!canWrite) {
    return _.filter(tableHead, function(obj) {return !obj.id.includes("action")});
  } else {
    return tableHead;
  }
}

// filter function
export const filterUrl = (filter) => {
  let filterBy = _.omitBy(filter, _.isNil);
  filterBy = _.omitBy(filterBy, (item) => _.isArray(item) && _.isEmpty);
  if (!_.isEmpty(filterBy)) {
    let path = location.pathname+"?";
    Object.keys(filterBy).forEach((key,index) => {
      if (index>0) path+="&";
      path += (key === "page" ? key+"="+(filter[key] + 1) : key+"="+filter[key]);
    })
    return path;
  } 
  // else {
  //   return location.pathname;
  // }
}

export const filterParam = (filterParam) => {
  const keys = new Set(Object.keys(filterParam));
  var params = new URLSearchParams(window.location.search);
  let result = {};
  for(const [key, value] of params) {
    if (keys.has(key) && !_.isNil(value)) {
      if (key === "page") {
        result[key] = value - 1;
      } else {
        result[key] = value;
      }
    }
  }
  return result;
}