import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Checkbox from "shared-components/Checkbox/Checkbox";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";

import { CreateOrUpdateRawMaterialBatch, GetWarehouseRackByWarehouseId } from "services/UserPortal/RawMaterialService";

import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { setValues, updateBatchForm } from "../store/management";

import styles from "assets/jss/components/UserPortal/rawMaterial.module.scss";

export default function AddEditBatch(props) {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const allRackNumber = useSelector(store => store.user.rawMaterial.managementRM.allRackNumber);
  const batch = useSelector(store => store.user.rawMaterial.managementRM.batch);
  const warehouseList = useSelector(store => store.common.warehouseList);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [page, setPage] = React.useState(0); // for rack list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for rack list
  const [searchValue, setSearchValue] = React.useState(""); // for rack list

  const handleOnChange_text = (e) => {
    dispatch(setDirty(true));
    dispatch(updateBatchForm({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    dispatch(updateBatchForm({[e.target.name] : e.target.value}));
    if (e.target.name === "warehouseId") {
      dispatch(updateBatchForm({["rackNumbers"] : []}));
    }
  };

  const handleExpiryDateChange = (date) => {
    if(date && date != "Invalid Date") {
      dispatch(setDirty(true));
      dispatch(updateBatchForm({["expiryDate"] :moment(date).format("YYYY-MM-DD")}));
    } else {
      dispatch(updateBatchForm({["expiryDate"] : date}));
    }
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(setDirty(true));
    dispatch(updateBatchForm({rackNumbers : value}));
  };

  const fetchMoreData = (search, pageNo) => {
    if (page !== -1 || pageNo !== undefined) {
      setIsLoadingMore(true);
      const param = {
        warehouseId: batch.warehouseId,
        page: pageNo !== undefined ? pageNo : page,
        rackID: search ? search : ""
      }
      dispatch(GetWarehouseRackByWarehouseId(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result) {
            setPage(pageNo !== undefined ? pageNo+1 : page+1);
            if (search || page === 0 || pageNo === 0) {
              dispatch(setValues({allRackNumber: response.payload.result.items}));
            } else {
              dispatch(setValues({allRackNumber: _.unionBy(allRackNumber, response.payload.result.items, "rackID")}));
            }
          } else {
            setPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const handleButtonClick_cancel = () => {
    props.setAddEditBatch(false);
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        let body = _.cloneDeep(batch);
        if (body.rackNumbers) {
          body.rackNumbers = body.rackNumbers.map((item) => item.rackID)
        }
        body.quantity = body.qtyAdd;
        dispatch(CreateOrUpdateRawMaterialBatch(body))
        .then(({error}) => {
          if (error) {
            dispatch(setDirty(true));
          } else {
            props.setAddEditBatch(false);
          }
        })
      })
    }
  }

  const validateFields = () => {
    if (batch.expiryDate === null) {
      setErrorMsg({field: "expiryDate", msg: "Please enter an expiry date"});
      return false;
    }
    if (!moment(batch.expiryDate).isValid()) {
      setErrorMsg({field: "expiryDate", msg: "Invalid date"});
      return false;
    }
    if (batch.expiryDate < batch.dateOfCreation) {
      setErrorMsg({field: "expiryDate", msg: "Expiry date should be later than created date"});
      return false;
    }
    if (batch.isSeed && !batch.lossRatePercent) {
      setErrorMsg({field: "lossRatePercent", msg: "Please enter loss rate"});
      return false;
    }
    if (batch.isSeed && batch.lossRatePercent > 100) {
      setErrorMsg({field: "lossRatePercent", msg: "Loss rate cannot be more than 100%"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  React.useEffect(() => {
    setPage(0);
    const param = {
      warehouseId: batch.warehouseId,
      page: 0
    }
    dispatch(GetWarehouseRackByWarehouseId(param))
    .then((response) => {
      if (response.payload.result) {
        setPage(page+1);
        dispatch(setValues({allRackNumber: response.payload.result.items}))
      }
    });
  },[batch.warehouseId]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(setValues({batch: {}}))
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = "Edit Raw Materials Batch"
      >
        <Paper className={styles.paper} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={styles.label}>
              <Typography>Proposed Batch ID:</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                value={batch.batchRefNo}
                variant="outlined" 
                disabled
              />
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Date of Creation:</Typography>
            </Grid>
            <Grid item xs={8}>
              <DateTimePicker
                className={styles.datepicker}
                name="startDate"
                value={batch.dateOfCreation}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Quantity Added:</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField 
                value={batch.qtyAdd}
                variant="outlined" 
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField 
                value={batch.quantityUOM}
                variant="outlined" 
                disabled
              />
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Total Cost:</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                value={batch.totalCost}
                variant="outlined" 
                disabled
              />
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Expiry Date:</Typography>
            </Grid>
            <Grid item xs={8}>
              <DateTimePicker
                name="expiryDate"
                value={batch.expiryDate ? batch.expiryDate : moment().format("YYYY-MM-DD")}
                minDate={batch.dateOfCreation}
                onChange={handleExpiryDateChange}
                errorMsg={errorMsg}
              />
            </Grid>
            {batch.isSeed &&
            <React.Fragment>
              <Grid item xs={4} className={styles.label}>
                <Typography>Loss Rate (%):</Typography>
                <Typography className={styles.lossRateSubheader}>Over the Product Loss Rate</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField 
                  id="lossRatePercent"
                  variant="outlined"
                  onChange={(e) => handleOnChange_text(e)}
                  placeholder="Eg. 10%"
                  type="Number"
                  onInput={(e)=>{ 
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                  }}
                  value={renderValue(batch.lossRatePercent)}
                  errorMsg={errorMsg}
                />
              </Grid>
            </React.Fragment>
            }
            <Grid item xs={4} className={styles.label}>
              <Typography>Warehouse:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="warehouseId"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(batch.warehouseId)}
                placeholder="Input Warehouse Name"
              >
                {warehouseList && warehouseList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.warehouseName} ({item.warehouseId})</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={4} className={styles.label2}>
              <Typography>Rack Number:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                dataCount={page*10}
                options={allRackNumber}
                fetchData={()=>fetchMoreData()}
                renderOption={(option) => (
                  <React.Fragment>
                    <Checkbox checked={_.findIndex(batch.rackNumbers, ({rackID}) => rackID === option.rackID) > -1} />
                    {option.rackID}
                  </React.Fragment>
                )}
                isLoadingMore={isLoadingMore}
                placeholder="Eg. R01-01"
                onInputChange={(_, newInputValue) => {
                  setPage(0);
                  setSearchValue(newInputValue);
                  fetchMoreData(newInputValue, 0);
                }}
                onChange={(_, newValue) => {
                  handleOnChange_autocomplete(newValue);
                }}
                inputValue={searchValue}
                value={batch.rackNumbers ? batch.rackNumbers : []}
                renderValue={"rackID"}
                multiple
                disabled={!batch.warehouseId}
              />
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Supplier Name:</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                value={batch.supplierName}
                variant="outlined" 
                disabled
              />
            </Grid>
          </Grid>
          <div className={styles.action}>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>handleButtonClick_cancel()}
            >
              Cancel
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
              disabled={!isDirty}
            >
              Save
            </Button>
          </div>
        </Paper>
      </Card>
    </React.Fragment>
  );
}
