// Create Edit Farm
// Stepper
import React from "react";
// core components
import Stepper from "shared-components/Stepper/Stepper";

import { farmConfig } from "enums/Constants";

import styles from "assets/jss/components/AdminPortal/farm.module.scss";

export default function FarmStepper(props) {
  const farmSteps = ['Create a Farm', 'Add a '+farmConfig.bank];

  return (
    <Stepper 
      className={styles.stepper}
      steps={farmSteps}
      activeStep={props.activeStep}
    />
  )
}
