import { combineReducers } from "redux";
import configuration from "./configuration";
import settings from "./settings";
import search from "./search";

const humanResourceReducer = combineReducers({
  configuration,
  settings,
  search,
});

export default humanResourceReducer;