// Vehicle Type
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";

import AddEditVehicleType from "./AddEditVehicleType";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { rowsPerTable } from "config";
import { vehicleTypeTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllVehicleType, DeleteVehicleType } from "services/AdminPortal/VehicleService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/vehicle.module.scss";

export default function VehicleType(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const vehicleTypeCount = useSelector(store => store.admin.vehicle.vehicleTypeCount);
  const vehicleTypes = useSelector(store => store.admin.vehicle.vehicleTypes);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('vehicleCode');
  const [page, setPage] = React.useState(0);
  const [vehicleTypeId, setVehicleTypeId] = React.useState(null);
  const [newOrUpdate, setNewOrUpdate] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(vehicleTypeCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleModal = (id) => {
    setVehicleTypeId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_edit = (id) => {
    setVehicleTypeId(id);
    setNewOrUpdate(true);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteVehicleType(vehicleTypeId))
    .then(() =>{
      dispatch(GetAllVehicleType());
      resetState();
    })
  }

  const handleButtonClick_exit = () => {
    if (props.setVehicleTypeSetting) {
      props.setVehicleTypeSetting(false);
    } else {
      const prop = props.location && props.location.state;
      history.push((prop && prop.prevPath) || "/admin/vehicle");
    }
  }

  const resetState = () => {
    setVehicleTypeId(null);
    setPage(0);
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    dispatch(GetAllVehicleType());
  },[newOrUpdate]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    // componentDidUnmount
    return () => {
      const prop = props.location && props.location.state;
      if (prop) {
        dispatch(resetNav());
      }
    }
  },[]);

  if (newOrUpdate) {
    return <AddEditVehicleType id={vehicleTypeId} setTypeId={setVehicleTypeId} setNewOrUpdate={setNewOrUpdate} />;
  } else {
    return (
      <React.Fragment>
        <Card 
          title = "Current Vehicle Type Available"
          cardActions={
            <Button
              className={styles.buttonSecondary}
              onClick={() => handleButtonClick_exit()}
            >
              Go Back
            </Button>
          }
        >
          <div className={styles.table}>
            <Table
              header={vehicleTypeTableHead}
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
            >
              { vehicleTypes.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.vehicleTypes}</TableCell>
                    <TableCell>{item.shortCuts}</TableCell>
                    <TableCell align="right">
                      <CustomIconButton
                        type="edit"
                        onClick={() => handleButtonClick_edit(item.id)}
                      />
                      <CustomIconButton
                        type="delete" 
                        onClick={() => handleModal(item.id)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </div>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
          <Button
            className={clsx(styles.buttonSecondary, styles.addButton)}
            startIcon={<AddIcon />}
            onClick={()=>setNewOrUpdate(true)}
          >
            Add New Vehicle Type
          </Button>
        </Card>
        <Modal
          open={openModal}
          onClose={() => handleModal(null)}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this vehicle type? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
