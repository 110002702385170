import React from "react";
import PropTypes from "prop-types";
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from "clsx";
// @mui/material
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from "@mui/material/InputAdornment";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// @mui/lab
import useAutocomplete from "@mui/lab/useAutocomplete";
// @mui/icons-material
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// core components
import { TextFieldCss } from 'shared-components/TextField/TextField';
import Select from "shared-components/Select/Select";
import Chip from "shared-components/Chip/Chip";

import styles from "assets/jss/shared-components/select.module.scss";

export default function InfiniteAutocomplete({label, dataCount, placeholder, renderOption, isLoadingMore, fetchData, disabled, errorMsg, ...props}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClickAway  = () => {
    setOpen(false);
  };

  const {
    getInputProps,
    getListboxProps,
    getOptionProps,
    getTagProps,
    groupedOptions
  } = useAutocomplete({
    ...props,
    id: "use-autocomplete",
    clearOnBlur: true,
    disableCloseOnSelect: props.multiple,
    getOptionLabel: option => props.renderValue ? option[props.renderValue].toString() : option,
  });

  if (disabled) {
    return (
      <React.Fragment>
        {label && <Typography className={styles.label}>{label}</Typography>}
        <Select className={props.className} placeholder={placeholder} disabled/>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        {label && <Typography className={styles.label}>{label}</Typography>}
        <ClickAwayListener onClickAway={handleClickAway}> 
          <div className={clsx(styles.autocomplete, props.className)}>
            <TextFieldCss 
              {...getInputProps()}
              className={clsx(styles.textfield)}
              InputProps={{
                classes: {
                  input: styles.infiniteInput
                },
                startAdornment: (props.multiple && props.value && props.value.length > 0) && props.value.map((item, index) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    tabIndex={-1}
                    label={props.renderValue ? item[props.renderValue] : item}
                    className={styles.chip}
                    {...getTagProps({index})}
                  />
                )),
                endAdornment: 
                  <InputAdornment position="end" className={styles.endAdornment}>
                    <KeyboardArrowDownIcon />
                  </InputAdornment>,
              }}
              onClick={(e)=>handleClick(e)}
              value={(!props.multiple && props.value) ? (props.renderValue ? props.value[props.renderValue] : props.value) : props.inputValue}
              variant="outlined" 
              placeholder={placeholder}
              error={errorMsg && errorMsg.field === props.id}
              helperText={errorMsg && errorMsg.field === props.id ? errorMsg.msg : ""}
            />
            {groupedOptions.length > 0 &&
              <Popper
                {...getListboxProps()}
                className={styles.infinitePaper} 
                anchorEl={anchorEl}
                open={open}
                placement="bottom-start"
                id="scrollableDiv"
              >
                <Paper className={styles.paper} square>
                  <InfiniteScroll
                    dataLength={dataCount}
                    next={()=>fetchData ? fetchData() : null}
                    hasMore={true}
                    loader={ isLoadingMore && 
                      <div className={"w-full text-center"}>
                        <CircularProgress className={styles.loading} size={"1.250vw"} />
                      </div>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    <ul>
                      {groupedOptions.map((option, index) => (
                        <li key={index} {...getOptionProps({ option, index })} className={styles.menuItem}>{renderOption(option)}</li>
                      ))}
                    </ul>
                  </InfiniteScroll>
                </Paper>
              </Popper>
            }
          </div>
        </ClickAwayListener>
      </React.Fragment>
    );
  }
}

InfiniteAutocomplete.propTypes = {
  label: PropTypes.string,
  dataCount: PropTypes.number,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  renderOption: PropTypes.func,
  isLoadingMore: PropTypes.bool,
  fetchData: PropTypes.func,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};
