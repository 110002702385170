import React from "react";
import { useDispatch, useSelector } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import Alert from "shared-components/Modal/Alert";

import BeforeAfter from "./BeforeAfter";
import { setDirty } from "store/general";
import { setValues, reset } from ".";
import { GetAllAlarmType, GetAllAlarmNotificationSetting } from "services/UserPortal/AlarmService";

export default function Settings() {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const [tab, setTab] = React.useState(0);
  const [newTab, setNewTab] = React.useState(0);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);

  const handleOnChange_tab = (e, value) => {
    if (isDirty) {
      setOpenAlertModal(!openAlertModal);
      setNewTab(value);
    } else {
      setTab(value);
    }
  }

  const handleButtonClick_exit = () => {
    setOpenAlertModal(false);
    setTab(newTab);
    dispatch(setDirty(false));
  }

  React.useEffect(() => {
    dispatch(GetAllAlarmType(Boolean(!tab)));
    dispatch(GetAllAlarmNotificationSetting({isIndividual: true, isBefore: Boolean(!tab)}))
    .then((response) => {
      if (response.payload.result&&response.payload.result.totalCount) {
        dispatch(setValues({setting: response.payload.result.items[0]}));
      } else {
        dispatch(setValues({setting: {isBefore: Boolean(!tab), isAppNotification: true, isIndividual: true}}));
      }
    })
  },[tab]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = "Individual Alarms Triggered"
        subheader={
          <React.Fragment>
            Choose the options to configure individual alarm before event’s deadline is reached:
            <Tabs 
              tabs={["Before", "After"]}
              value={tab} 
              onChange={handleOnChange_tab}
            />
          </React.Fragment>
        }
      >
        <BeforeAfter tab={tab} />
      </Card>
      <Alert open={openAlertModal} onConfirm={()=>handleButtonClick_exit()} onCancel={()=>handleOnChange_tab()} />
    </React.Fragment>
  );
}
