import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/icons-material
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// @mui/material
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import CustomIconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";

import { rowsPerTable } from "config";
import { checkPermission, renderValue, formatNumbers, roundTo2Decimal, filterActionTableHead } from "common/functions";
import { setDirty } from "store/general";
import { hrOverallStatusTableHead } from "enums/UserPortal/TableHeaders";
import { updateRequest, setValues } from "../store/adminLogSheet";
import { GetAllOverallLogStatus, SendReminderEmail, UpdateOverallLogStatus } from "services/UserPortal/HumanResourceService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/hr.module.scss";

import { useRouteCanWrite } from "hooks";

export default function OverallStatus() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.hr.adminLogSheet.overallTotalCount);
  const result = useSelector(store => store.user.hr.adminLogSheet.overallLog);
  const log = useSelector(store => store.user.hr.adminLogSheet.log);
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [currentDate] = React.useState(moment());
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('employeeRefNo');
  const [page, setPage] = React.useState(0);
  const [employeeId, setEmployeeId] = React.useState(false);
  const [employeeDetails, setEmployeeDetails] = React.useState(false);
  const [isAddEdit, setIsAddEdit] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if (value && value.isValid() && value != "Invalid Date" && moment(value).isAfter('2000-01-01', 'year')) {
      setDate(moment(value).format("YYYY-MM-DD"));
    } 
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };
  
  const handleButtonClick_edit = (id) => {
    dispatch(setDirty(true));
    setIsAddEdit(true);
    setEmployeeId(id);
    dispatch(setValues({log: {id}}));
  }

  const handleModal_email = (value) => {
    setEmployeeDetails(value);
    setOpenModal(!openModal);
  }

  const handleButtonClick_email = (id) => {
    dispatch(SendReminderEmail(id));
    handleModal_email(null);
  }

  const handleButtonClick_confirm = () => {
    dispatch(UpdateOverallLogStatus())
    .then(() =>{
      getLogStatus(page);
      resetState();
    })
  }

  const handleButtonClick_cancel = () => {
    if (employeeId === 0) {
      dispatch(setValues({log: {}}));
      setPage(0);
    }
    resetState();
  }

  const resetState = () => {
    setIsAddEdit(false);
    setEmployeeId(null);
    setErrorMsg(false);
    setOpenModal(false);
    dispatch(setDirty(false));
    dispatch(setValues({log: {}}));
  }

  const getLogStatus = (pageNo, stopLoading) => {
    dispatch(GetAllOverallLogStatus({date, page: pageNo, stopLoading}));
  }

  React.useEffect(() => {
    setPage(0);
    getLogStatus(0);
  },[date]);

  React.useEffect(() => {
    getLogStatus(page);
  },[page]);

  return (
    <React.Fragment>
      <div className={clsx(styles.tabHeader, styles.tabMargin)}>
        <Typography className={styles.tabTitle}>Information Correct as at {moment(currentDate).format("DD/MM/YYYY, HHmm")}</Typography>
        <DateTimePicker
          closeOnSelect
          placeholder="Select a date"
          className={styles.cardActionDropdown}
          value={date}
          onChange={(e) => handleOnChange_date(e)}
          disabled={isAddEdit}
        />
      </div>
      <Table
        className={styles.table}
        header={filterActionTableHead(hrOverallStatusTableHead)}
        // order={order}
        // orderBy={orderBy}
        // onRequestSort={handleRequestSort}
      >
        { result.map((item,index) => {
          return (
            <TableRow key={index}>
              <TableCell>{item.employeeRefNo}</TableCell>
              <TableCell>{item.userName}</TableCell>
              <TableCell>{formatNumbers(roundTo2Decimal(item.noOfHoursLogged))}</TableCell>
              <TableCell>{formatNumbers(roundTo2Decimal(item.totalWorkingHour))}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.logStatus ? "Yes" : "No"}</TableCell>
              <TableCell>
                { isAddEdit && employeeId === item.id
                ? <TextField 
                    id="remark"
                    variant="outlined" 
                    inputProps={{ maxLength: 100 }}
                    placeholder="Enter remarks"
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(log.remark)}
                    errorMsg={errorMsg}
                  />
                : item.remark
                }
              </TableCell>
              <TableCell align="right">
                { canWrite && 
                  (isAddEdit && employeeId === item.id
                  ? <React.Fragment>
                      <CustomIconButton 
                        type="confirm"
                        onClick={() => handleButtonClick_confirm()}
                      />
                      <CustomIconButton 
                        type="close"
                        onClick={() => handleButtonClick_cancel()}
                      />
                    </React.Fragment>
                  : <React.Fragment>
                      <IconButton onClick={()=>handleModal_email(item)} disabled={Boolean(employeeId && employeeId !== item.id)}>
                        <EmailOutlinedIcon className={clsx(styles.icon, (employeeId && employeeId !== item.id) ? styles.iconDisabled : styles.emailIcon)} />
                      </IconButton>
                      <CustomIconButton 
                        type="edit"
                        onClick={() => handleButtonClick_edit(item.id)}
                        disabled={Boolean(employeeId && employeeId !== item.id)}
                      />
                    </React.Fragment>
                  )
                }
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
          disabled={Boolean(isAddEdit)}
        />
      }
      {checkPermission("Admin.Human Resource", "readEdit") &&
        <Typography className={styles.link}>
          <Link to={{pathname: "/admin/human-resource/user-configuration"}}>Go to “Human Resource Management” Module in the admin portal to configure hourly rates of employees</Link>
        </Typography>
      }
      <Modal
        open={openModal}
        onClose={() => handleModal_email(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content={"A reminder will be sent to "+(employeeDetails && employeeDetails.userName)+" via the email address to remind user to update his log sheet."}
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_email(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_email(employeeDetails.id)}>Send</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
