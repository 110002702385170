import { createSlice } from "@reduxjs/toolkit";
import { locale } from 'config';

// initial state
const initialState = {
  isAdminPortal: location.pathname.includes("/admin"),
  language: locale[0],
  isDirty: false,
  isLoading: false,
  isNoSnackbar: false,
  error: false,
  success: false,
  searchText: "",
  // chatbot
  sessionId: '',
  chatHistory: ['How may I assist you? Please type your query in down below...'],
};

// create reducer and action creators
const general = createSlice({
  name: "general",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    setAdminPortal: (state, action) => {
      state.isAdminPortal = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setDirty: (state, action) => {
      state.isDirty = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setNoSnackbar: (state, action) => {
      state.isNoSnackbar = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    reset: (state) => {
      state.isAdminPortal = location.pathname.includes("/admin");
      state.isDirty = false;
      state.isLoading = false;
      state.isNoSnackbar = false;
      state.error = false;
      state.success = false;
      state.searchText = "";
    },
  },
});

// export actions
export const { setValues, setAdminPortal, setLanguage, setDirty, setLoading, setNoSnackbar, setError, setSuccess, setSearchText,
  reset } = general.actions;

// export the reducer
export default general.reducer;