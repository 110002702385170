import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import Popper from "@mui/material/Popper";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import styles from "assets/jss/shared-components/popper.module.scss";

import close from "assets/icons/white/close.svg";
export default function CustomPopper({children, title, content, closePopper, buttonClick, noButton, ...props}) {
  const [arrowRef, setArrowRef] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <React.Fragment>
      {React.cloneElement(children, { ...children.props, ref: setAnchorEl })}
      {anchorEl &&
        <Popper
          {...props}
          className={clsx(props.className, styles.popper)}
          anchorEl={anchorEl} 
          transition
          modifiers={[
            {
              name: "preventOverflow",
              options: {
                enabled: true,
                boundariesElement: 'window',
              },
            },
            {
              name: "arrow",
              options: {
                enabled: true,
                element: arrowRef,
              },
            }
          ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper className={styles.container}>
                <ClickAwayListener onClickAway={closePopper}>
                  <div>
                    <span className={styles.arrow} ref={setArrowRef} />
                    {!noButton && <img className={styles.icon} src={close} alt="close-line" onClick={closePopper}/>}
                    {title && <Typography className={styles.title}>{title}</Typography>}
                    <Typography className={styles.content}>{content}</Typography>
                    { !noButton &&
                      <span className="flex justify-end">
                        <Button className={styles.button} onClick={buttonClick}>Okay</Button>
                      </span>
                    }
                  </div>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      }
    </React.Fragment>
  );
}

CustomPopper.propTypes = {
  children: PropTypes.node,
  closePopper: PropTypes.func,
  buttonClick: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  noButton: PropTypes.bool,
};