import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import {FomsMS} from "../index";

export const GetActiveUsersReport = createAsyncThunk(
  "report/getActiveUsersReport", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      companyId: userDetail.companyId,
      StartDate: param.startDate,
      EndDate: param.endDate,
    }
    try {
      const response = await FomsMS.get("/Report/GetActiveUsersReport", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetActiveRatePercentReport = createAsyncThunk(
  "report/getActiveRatePercentReport", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      companyId: userDetail.companyId,
      StartDate: param.startDate,
      EndDate: param.endDate,
    }
    try {
      const response = await FomsMS.get("/Report/GetActiveRatePercentReport", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetActivationRatePercentReport = createAsyncThunk(
  "report/getActivationRatePercentReport", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      companyId: userDetail.companyId,
      StartDate: param.startDate,
      EndDate: param.endDate,
    }
    try {
      const response = await FomsMS.get("/Report/GetActivationRatePercentReport", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExportReport = createAsyncThunk(
  "report/Export", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const config = {
      responseType: 'blob',
      params: {
        farmId: find(userDetail.farms, "isActive").id,
        companyId: userDetail.companyId,
        ...param
      }, 
      stopLoading: true
    }
    try {
      const response = await FomsMS.get("/Report/Export", config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetModuleStatistics = createAsyncThunk(
  "report/GetModuleStatistics", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      companyId: userDetail.companyId,
      StartDate: param.startDate,
      EndDate: param.endDate,
    }
    try {
      const response = await FomsMS.get("/Report/GetModuleStatistics", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExportModuleStatistics = createAsyncThunk(
  "report/ExportModuleStatistics", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const config = {
      responseType: 'blob',
      params: {
        farmId: find(userDetail.farms, "isActive").id,
        companyId: userDetail.companyId,
        ...param
      },
      stopLoading: true
    }
    try {
      const response = await FomsMS.get("/Report/ExportModuleStatistics", config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);