// Create Edit Product
// Step 2-5: Post Harvest Table
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import CustomIconButton from "shared-components/Button/IconButton";

import { growthProcessTableHead } from "enums/AdminPortal/TableHeaders";
import { isUnique, isPositive, isPositiveDecimal } from "common/validations";
import { setValues, updatePostProcess } from "./store/product";
import { GetAllPackagingType } from "services/AdminPortal/ProductService";

import settings from "assets/icons/orange/settings.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/product.module.scss";
import arrow from "assets/icons/orange/droplist-arrow.svg";


export default function PostHarvest(props) {
  const dispatch = useDispatch();
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct);
  const selectedPreHarvestProcess = useSelector(store => store.admin.product.product.productPreHarvestGrowthProcess);
  const selectedPostHarvestProcess = useSelector(store => store.admin.product.product.productPostHarvestGrowthProcess);
  const postProcess = useSelector(store => store.admin.product.product.postProcess);
  const processMasterList = useSelector(store => store.admin.product.lookup.processMasterList);
  const packageTypeList = useSelector(store => store.admin.product.lookup.packageTypeList);
  const [openModal, setOpenModal] = React.useState(false);
  const [lossRate, setLossRate] = React.useState("");
  const [growthEditIndex, setGrowthEditIndex] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [preHarvestTotalLoss, setPreHarvestTotalLoss] = React.useState([false]);
  const [postHarvestTotalLoss, setPostHarvestTotalLoss] = React.useState([false]);
  const [isAdd, setIsAdd] = React.useState(false);

  const calTotalLoss = (array) => {
    if (array) {
      return array.reduce((a, b) => {
        return a + (b.lossRate?Number(b.lossRate):0);
      }, 0);
    } else {
      return null;
    }
  }

  const resetState = () => {
    dispatch(setValues({postProcess: {}}))
    setLossRate("");
    setGrowthEditIndex(null);
    setErrorMsg(false);
    setIsAdd(false);
    props.setEditMode(false);
  }

  const tableHead = () => {
    if(props.step === 2) {
      return _.filter(growthProcessTableHead, (obj) => {return obj.step <= 3 && obj.post !== false});
    } else if (props.step === 3) {
      return _.filter(growthProcessTableHead, (obj) => {return obj.step <= 4 && obj.post !== false});
    } else if (props.step === 4) {
      return _.filter(growthProcessTableHead, (obj) => {return obj.post !== false});
    } else {
      return _.filter(growthProcessTableHead, (obj) => {return obj.step <= 2 && obj.post !== false});
    }
  }

  const handleOnChange_select = (e) => {
    dispatch(updatePostProcess({[e.target.name]: e.target.value}))
  };

  const handleOnChange_text = (e) => {
    dispatch(updatePostProcess({[e.target.id]: e.target.value}))
  };

  const handleOnChange_textLoss = (e) => {
    setLossRate(e.target.value);
    let payload = _.cloneDeep(selectedPostHarvestProcess);
    payload[growthEditIndex].lossRate = e.target.value;
    setPostHarvestTotalLoss(calTotalLoss(payload));
  }

  const handleButtonClick_add = () => {
    let payload = _.cloneDeep(selectedPostHarvestProcess);
    payload.push({processSequence: payload.length+1});
    dispatch(setValues({productPostHarvestGrowthProcess: payload}));
    setGrowthEditIndex(payload.length-1);
    props.setEditMode(true);
    setIsAdd(true);
  }

  const handleButtonClick_edit = (index) => {
    dispatch(setValues({postProcess: selectedPostHarvestProcess[index]}));
    setLossRate(selectedPostHarvestProcess[index].lossRate);
    setGrowthEditIndex(index);
    props.setEditMode(true);
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      let payload = _.cloneDeep(selectedPostHarvestProcess);
      if (props.step === 1) {
        payload[growthEditIndex] = {...payload[growthEditIndex], ...postProcess};
      } else {
        payload[growthEditIndex].lossRate = lossRate;
      }
      dispatch(setValues({productPostHarvestGrowthProcess: payload}));
      resetState();
    }
  }

  const handleButtonClick_cancel = () => {
    if (isAdd) {
      let payload = _.cloneDeep(selectedPostHarvestProcess);
      payload.splice(payload.length - 1, 1);
      dispatch(setValues({productPostHarvestGrowthProcess: payload}));
    }
    resetState();
  }

  const handleModal = (index) => {
    setGrowthEditIndex(index);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    let payload = _.cloneDeep(selectedPostHarvestProcess);
    payload.splice(growthEditIndex,1);
    payload.forEach((item, index) => {
      item.processSequence = index+1
    })
    dispatch(setValues({productPostHarvestGrowthProcess: payload}));
    setGrowthEditIndex(null);
    setOpenModal(!openModal);
    if (props.step !== 1 && !payload.length) {
      props.setStep(1);
    }
  }

  const handleCreateTask = (index, item) => {
    dispatch(setValues({process: item}))
    props.setGrowthEditIndex(index);
    props.setCreateTask(true);
    props.setType("productPostHarvestGrowthProcess");
  }

  const handleCreateRawMaterial = (index, item) => {
    dispatch(setValues({process: item}))
    props.setGrowthEditIndex(index);
    props.setCreateRawMaterial(true);
    props.setType("productPostHarvestGrowthProcess");
  }

  const validateFields = () => {
    if (!postProcess.processName) {
      setErrorMsg({field: "processName", msg: "Please add in process name"});
      return false;
    }
    let payload = _.cloneDeep(selectedPostHarvestProcess);
    payload.splice(growthEditIndex,1);
    if (!isUnique([...payload, ...selectedPreHarvestProcess, postProcess], "processName")) {
      setErrorMsg({field: "processName", msg: "Selected process must be unique"});
      return false;
    }
    if (!isPositive(postProcess.processDurationDays)) {
      setErrorMsg({field: "processDurationDays", msg: "Please add in process duration"});
      return false;
    }
    if (selectedPostHarvestProcess && selectedPostHarvestProcess[growthEditIndex] && selectedPostHarvestProcess[growthEditIndex].lossRate && !isPositiveDecimal(selectedPostHarvestProcess[growthEditIndex].lossRate)) {
      setErrorMsg({field: "lossRate", msg: "Please enter a valid loss rate"});
      return false;
    }
    if (preHarvestTotalLoss + postHarvestTotalLoss > 100) {
      setErrorMsg({field: "totalLossRate", msg: "Total Loss Rate for both Pre Harvesting and Post Harvesting cannot be more than 100"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const totalPercentage = () => {
    if(props.step === 4) {
      return (
        <TableRow>
          <TableCell colSpan={6}></TableCell>
          <TableCell className={styles.editCell}>Total</TableCell>
          <TableCell colSpan={2} className={styles.lossRateCell}>
            <Typography>{postHarvestTotalLoss}</Typography>
            {errorMsg.field === "totalLossRate" && <Typography className={styles.lossRateError}>{errorMsg.msg}</Typography>}
          </TableCell>
        </TableRow>
      )
    }
  }

  React.useEffect(() => {
    setPostHarvestTotalLoss(calTotalLoss(selectedPostHarvestProcess));
  },[selectedPostHarvestProcess]);

  React.useEffect(() => {
    setPreHarvestTotalLoss(calTotalLoss(selectedPreHarvestProcess));
    setPostHarvestTotalLoss(calTotalLoss(selectedPostHarvestProcess));
    dispatch(GetAllPackagingType({page: 0, maxResultCount: 9999}));
  },[]);

  // const reducedList = selectedPreHarvestProcess.reduce((item,{processName}) => item.add(processName), new Set());
  // const processList = processMasterList.filter(({processName}) => !reducedList.has(processName));
  return (
    <React.Fragment>
      <Card
        variant="outlined"
        classes={{root:styles.growthProcessTable}}
        title = {
          <React.Fragment>
            Post Harvesting ({selectedProduct.productID})
            <IconButton 
              className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
              onClick={() => setCollapsed(!collapsed)}
            >
              <img className={styles.icon} src={arrow} alt="arrow" />
            </IconButton>
          </React.Fragment>
        }
      >
        { !collapsed && 
          <React.Fragment>
            <Table
              className={styles.table}
              header={tableHead().map((head) => {
                if (head.id === "packageType") {
                  head.label = <React.Fragment>
                    Packaging Type
                    {props.step === 1 &&
                      <IconButton 
                        onClick={() => props.setPackageSettings(true)}
                        disabled={props.editMode}
                      >
                        <img className={styles.icon} src={settings} alt="settings" />
                      </IconButton>
                    }
                  </React.Fragment>
                } 
                return head
              })}
            >
              {selectedPostHarvestProcess ? selectedPostHarvestProcess.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{('0'+(index+1)).slice(-2)}</TableCell>
                    <TableCell>
                      {(props.step === 1 && index === growthEditIndex && props.editMode)
                      ?
                      <Select
                        name="processName"
                        className={styles.alignTextfield}
                        onChange={(e)=>handleOnChange_select(e)}
                        value={postProcess.processName}
                        placeholder="Select a process"
                        errorMsg={errorMsg}
                      >
                        {processMasterList && processMasterList.map((item) => {
                          return <MenuItem key={item.id} value={item.processName}>{item.processName}</MenuItem>;
                        })} 
                      </Select>
                      :
                      item.processName
                      }
                    </TableCell>
                    <TableCell>
                      {(props.step === 1 && index === growthEditIndex && props.editMode)
                      ? <TextField
                          className={styles.alignTextfield}
                          id="processDurationDays"
                          variant="outlined"
                          type="Number"
                          onInput={(e)=>{ 
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                          }}
                          onChange={(e) => handleOnChange_text(e)}
                          value={postProcess.processDurationDays}
                          errorMsg={errorMsg}
                        />
                      : item.processDurationDays
                      }
                    </TableCell>
                    <TableCell>
                      {(props.step === 1 && index === growthEditIndex && props.editMode)
                      ? <TextField
                          className={styles.alignTextfield}
                          id="packageSize"
                          variant="outlined"
                          type="Number"
                          onInput={(e)=>{ 
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                          }}
                          onChange={(e) => handleOnChange_text(e)}
                          value={postProcess.packageSize}
                          errorMsg={errorMsg}
                          // disabled={postProcess.processName !== "Packaging"}
                        />
                      : item.packageSize
                      }
                    </TableCell>
                    <TableCell>
                      {(props.step === 1 && index === growthEditIndex && props.editMode)
                      ? <Select
                          name="packagingTypeSettingId"
                          className={styles.alignTextfield}
                          onChange={(e)=>handleOnChange_select(e)}
                          value={postProcess.packagingTypeSettingId}
                          placeholder="Select a packaging type"
                          errorMsg={errorMsg}
                          // disabled={postProcess.processName !== "Packaging"}
                        >
                          {packageTypeList && packageTypeList.map((item) => {
                            return <MenuItem key={item.id} value={item.id}>{item.packagingType}</MenuItem>;
                          })} 
                        </Select>
                      : (item.packagingTypeSettingId && packageTypeList.length && packageTypeList.find((pack) => pack.id === item.packagingTypeSettingId)) ? packageTypeList.find((pack) => pack.id === item.packagingTypeSettingId).packagingType : null
                      }
                    </TableCell>
                    {props.step >= 2 && <TableCell>{(props.step === 2)?<span className={styles.link} onClick={()=>handleCreateRawMaterial(index, item)}>Create</span>:"Create"}</TableCell>}
                    {props.step >= 3 && <TableCell>{(props.step === 3)?<span className={styles.link} onClick={()=>handleCreateTask(index, item)}>Create</span>:"Create"}</TableCell>}
                    {props.step >= 4 &&
                      <TableCell className={styles.lossRateCell}>
                        {(props.step === 4 && index === growthEditIndex && props.editMode)
                        ? <TextField
                            className={styles.alignTextfield}
                            id="lossRate"
                            variant="outlined" 
                            type="Number"
                            onInput={(e)=>{ 
                              e.target.value = e.target.value > 100 ? 100 : e.target.value
                            }}
                            onChange={(e) => handleOnChange_textLoss(e)}
                            value={lossRate}
                            errorMsg={errorMsg}
                          />
                        : item.lossRate
                        }
                      </TableCell>
                    }
                    <TableCell align="right">
                      {index === growthEditIndex
                      ? <React.Fragment>
                          <CustomIconButton 
                          type="confirm"
                            onClick={() => handleButtonClick_confirm()}
                          />
                          <CustomIconButton 
                            type="close"
                            onClick={() => handleButtonClick_cancel()}
                          />
                        </React.Fragment>
                      : <React.Fragment>
													{(props.step === 1 || props.step === 4) &&
                            <CustomIconButton
                              type="edit" 
															onClick={() => handleButtonClick_edit(index)}
															disabled={props.editMode}
														/>
													}
													<CustomIconButton 
                            type="delete"
														onClick={() => handleModal(index)}
														disabled={props.editMode}
													/>
												</React.Fragment>
                      }
                    </TableCell>
                  </TableRow>
                )
              })
              : null}
              {totalPercentage()}
            </Table>
            <div>
              {props.step === 1 &&
                <Button
                  className={clsx(styles.buttonSecondary, styles.addMoreProcess)}
                  onClick={()=>handleButtonClick_add()}
                  disabled={props.editMode}
                >
                  + Add more Process
                </Button>
              }
            </div>
          </React.Fragment>
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content={
          "Do you really want to delete this process? This process cannot be undone." + 
          ((props.step !== 1 && selectedPostHarvestProcess.length === 1) ? " You will be redirected to step 2 to add a new process for Post Harvest." : "")
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
			/>
    </React.Fragment>
	)
}
