import React from "react";
import { Link } from "react-router-dom";
// @mui/material
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";

import operations from "assets/icons/orange/operations.svg";
import finishedGoods from "assets/icons/orange/finished-goods.svg";
import planning from "assets/icons/orange/planning.svg";
import equipment from "assets/icons/orange/equipment.svg";
import rawMaterial from "assets/icons/orange/raw-material.svg";
import delivery from "assets/icons/orange/delivery.svg";
import climate from "assets/icons/orange/climate.svg";
import alarm from "assets/icons/orange/alarm.svg";

import operationsWhite from "assets/icons/white/operations.svg";
import finishedGoodsWhite from "assets/icons/white/finished-goods.svg";
import planningWhite from "assets/icons/white/planning.svg";
import equipmentWhite from "assets/icons/white/equipment.svg";
import rawMaterialWhite from "assets/icons/white/raw-material.svg";
import deliveryWhite from "assets/icons/white/delivery.svg";
import climateWhite from "assets/icons/white/climate.svg";
import alarmWhite from "assets/icons/white/alarm.svg";
import styles from "assets/jss/components/UserPortal/alarm.module.scss";

import { checkPermission } from "common/functions";

export default function Alarm() {
  const [hoverIndex, setHoverIndex] = React.useState(null);

  const items = [
    {title: "Operation Module", url: "/user/alarm/details/operations", icon: operations, iconWhite: operationsWhite, name: "Operations"},
    {title: "Finished Goods Module", url: "/user/alarm/details/finished-goods", icon: finishedGoods, iconWhite: finishedGoodsWhite, name: "Finished Goods"},
    {title: "Planning Module", url: "/user/alarm/details/planning", icon: planning, iconWhite: planningWhite, name: "Planning"},
    {title: "Equipment Module", url: "/user/alarm/details/equipment", icon: equipment, iconWhite: equipmentWhite, name: "Equipment"},
    {title: "Raw Material Module", url: "/user/alarm/details/raw-material", icon: rawMaterial, iconWhite: rawMaterialWhite, name: "Raw Materials"},
    {title: "Delivery Module", url: "/user/alarm/details/delivery", icon: delivery, iconWhite: deliveryWhite, name: "Delivery"},
    {title: "Climate & Environment Module", url: "/user/alarm/details/climate", icon: climate, iconWhite: climateWhite, name: "Climate/Environment"},
    {title: "Individual Alarm Settings", url: "/user/alarm/details/settings", icon: alarm, iconWhite: alarmWhite, name: null},
  ]

  const handleMouseOver = (key) => {
    setHoverIndex(key);
  };

  const handleMouseOut = () => {
    setHoverIndex(null);
  }

  return (
    <Card 
      title="Alarms & Notification Management"
    >
      <Grid container spacing={4}>
        {items.map((item, key) => {
          if (!item.name || checkPermission(item.name, "readEdit")) {
            return (
              <Grid item xs={12} sm={6} md={2} key={key}>
                <Link to={{pathname: item.url}}>
                  <Paper elevation={0} className={styles.paper} 
                    onMouseOver={()=>handleMouseOver(key)}
                    onMouseOut={()=>handleMouseOut()}
                  >
                    <div className={styles.paperItem}>
                      <img className={styles.logoImage} src={(hoverIndex===key)?item.iconWhite:item.icon} alt={item.title} />
                      <div><Typography className={styles.logoTitle}>{item.title}</Typography></div>
                    </div>
                  </Paper>
                </Link>
              </Grid>
            );
          }
        })}
      </Grid>
    </Card>
  );
}
