import React from "react";
import { useDispatch } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import Production from "./Production";
import Storage from "./Storage";
import { reset as resetNav } from "shared-components/Navbars";
import { setSearchText } from "store/general";
import { reset } from "../store/settings";

export default function Settings(props) {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Settings"
        subheader={
          <React.Fragment>
            <Tabs 
              tabs={["Production", "Storage"]}
              value={tab} 
              onChange={(e,value) => setTab(value)}
            />
          </React.Fragment>
        }
      >
        {tab === 0 ? <Production /> : <Storage />}
      </Card>
    </React.Fragment>
  );
}
