import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";

const TableCellCss = styled(TableCell)({
  borderBottom: "none",
  padding: "0.833vw",
  fontSize: "0.938vw"
});

const LeftStickyTableCell = styled(TableCell)({
  borderBottom: "none",
  padding: "0.833vw",
  fontSize: "0.938vw",
  backgroundColor: "inherit",
  zIndex: 99,
  left: 0,
  position: "sticky"
});

export default function CustomTableCell({children, sticky, ...props}) {
  if (sticky) {
    return (
      <LeftStickyTableCell {...props}>
        {children}
      </LeftStickyTableCell>
    );
  } else {
    return (
      <TableCellCss {...props}>
        {children}
      </TableCellCss>
    );
  }
}

CustomTableCell.propTypes = {
  children: PropTypes.node,
};