import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import { reset as resetNav } from "shared-components/Navbars";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";

import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { updateRequest, setValues } from "../store/overview";
import { GetDeviceList, ExecuteWaterFlowControl } from "services/UserPortal/FlowService";

import styles from "assets/jss/components/UserPortal/flowControl.module.scss";

export default function AddEditFlowControlDevice() {
  const dispatch = useDispatch(); // required to call api/store
  let history = useHistory();
  const deviceList = useSelector(store => store.user.flowControl.overview.deviceList);
  const flowControl = useSelector(store => store.user.flowControl.overview.flowControl);
  const [page, setPage] = React.useState(0); // for device list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for device list
  const [searchValue, setSearchValue] = React.useState(""); // for device list
  const [errorMsg, setErrorMsg] = React.useState(false); // for device list
  
  const controller = new AbortController();

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_autocomplete = (value) => {
    if (value) {
      dispatch(updateRequest({device: value, deviceId: value.id}));
    }
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {  // if validation is true === no errors
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(ExecuteWaterFlowControl())
        .then((response) => {
          if (response.error) {
            dispatch(setDirty(true));
          } else {
            history.push("/user/flow-control/overview");
          }
        });
      });
    }
  };

  const handleButtonClick_cancel = () => {
    history.push("/user/flow-control/overview");
  }
  
  const fetchMoreData = (search, pageNo) => {
    if ((page !== -1 || pageNo !== undefined)) {
      setIsLoadingMore(true);
      const param = {
        page: pageNo !== undefined ? pageNo : page,
        DeviceRefNo: search ? search : "",
      }
      dispatch(GetDeviceList({...param, signal: controller.signal}))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result&&response.payload.result.items.length) {
            setPage(pageNo !== undefined ? pageNo+1 : page+1);
            if (search || page === 0 || pageNo === 0) {
              dispatch(setValues({deviceList: response.payload.result.items}));
            } else {
              dispatch(setValues({deviceList: _.unionBy(deviceList, response.payload.result.items, "id")}));
            }
          } else {
            setPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const validateFields = () => {
    if (!flowControl.deviceId) {
      setErrorMsg({field: "deviceId", msg: "Select a device ID"});
      return false;
    }
    if (!flowControl.targetFlow) {
      setErrorMsg({field: "targetFlow", msg: "Enter a target Flow"});
      return false;
    }
    setErrorMsg({field: "", msg: ""});
    return true;
  }
  
  React.useEffect(() => {
    fetchMoreData(searchValue, 0);
    // cleanup controller
    return () => {
      controller.abort();
    };
  },[searchValue]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetDeviceList({page: 0}))
    .then(({payload}) => {
      if (payload.result && payload.result.items.length) {
        dispatch(setValues({deviceList: payload.result.items}));
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetNav());
    }
  },[]);

  return (
    <Card 
      title="Add New Flow Control"
    >
      <Paper className={styles.paper} elevation={0}>
        <Grid container spacing={4}>
          <Grid item xs={4} className={styles.label}>
            <Typography>Select Flow Control Device:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              id="deviceId"
              dataCount={page*10}
              options={deviceList}
              fetchData={()=>fetchMoreData()}
              renderOption={(option) => option.deviceName + " (" +option.deviceRefNo + ")"}
              isLoadingMore={isLoadingMore}
              placeholder="Select a device"
              onInputChange={(e, newInputValue) => {
                if (e && e._reactName == "onBlur") {
                  setPage(0);
                  setSearchValue(!_.isEmpty(flowControl.device)?flowControl.device.deviceRefNo:"");
                } 
                if (e && (e._reactName == "onClick" || e._reactName == "onChange")) {
                  setPage(0);
                  setSearchValue(newInputValue);
                }
                if (e && e._reactName == "onClick") {
                  setPage(0);
                  setSearchValue(newInputValue);
                }
                if (e && e._reactName == "onChange") {
                  setPage(0);
                  setSearchValue(newInputValue);
                }
              }}
              onChange={(_, newValue) => {
                handleOnChange_autocomplete(newValue);
              }}
              inputValue={searchValue}
              value={searchValue!==(flowControl.device&&flowControl.device.deviceRefNo) ? null : flowControl.device}
              renderValue={"deviceRefNo"}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4} className={styles.label}>
            <Typography>Target Volume:</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField 
              id="targetFlow"
              variant="outlined" 
              type="Number"
              onChange={(e) => handleOnChange_text(e)}
              placeholder="Enter volume"
              value={renderValue(flowControl.targetFlow)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={2} className={styles.label}>
            <Typography>Litre</Typography>
          </Grid>
          
        </Grid>
        <div className={styles.action}>
          <Button
            className={styles.buttonSecondary}
            onClick={()=>handleButtonClick_cancel()}
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            onClick={()=>handleButtonClick_save()}
          >
            Execute
          </Button>
        </div>
      </Paper>
    </Card>
  );
}
