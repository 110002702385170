import { combineReducers } from "redux";
import assignment from "./assignment";
import master from "./master";
import deliveryOrder from "./deliveryOrder";
import settings from "./settings";
import search from "./search";

const deliveryReducer = combineReducers({
  assignment,
  master,
  deliveryOrder,
  settings,
  search,
});

export default deliveryReducer;