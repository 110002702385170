import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// core components
import Card from "shared-components/Card/Card";
import Alert from "shared-components/Modal/Alert";
import Tabs from "shared-components/Tabs/Tabs";

import ThresholdForm from "./ThresholdForm";
// import MonitoringForm from "./MonitoringForm";
import { setDirty, setSearchText } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues } from "../store/sensorSettings";
import { GetSensorSetting } from "services/UserPortal/ClimateService";

export default function AddEditSensorSetting(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const [isEdit, setIsEdit] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [newTab, setNewTab] = React.useState(0);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);

  const prop = props.location && props.location.state;

  const handleOnChange_tab = (e, value) => {
    if (isDirty) {
      setOpenAlertModal(!openAlertModal);
      setNewTab(value);
    } else {
      setTab(value);
    }
  }

  const handleButtonClick_exit = () => {
    setOpenAlertModal(false);
    setTab(newTab);
    dispatch(setDirty(false));
  }

  React.useEffect(() => {
    if (!isEdit) {
      // dispatch(setValues({sensorSetting: {}}));
      switch (tab) {
        case 0:
          dispatch(setValues({thresholdType: "Environment", sensorSettingDetailList: []}))
          break;
        case 1:
          dispatch(setValues({thresholdType: "Monitoring", sensorSettingDetailList: []}))
          break;
      }
    }
  },[tab]);

  // componentDidMount
  React.useEffect(() => {
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "sensor-settings");
    if (path[index+1] !== "create") {
      dispatch(GetSensorSetting(path[index+1]))
      .then((response) => {
        if (response.error) {
          history.push("/user/climate/sensor-settings");
        } else {
          dispatch(setDirty(true));
          setIsEdit(true);
          setTab(response.payload.result.thresholdType == "Monitoring" ? 1 : 0);
        }
      });
    }
    if (prop && prop.deviceId && prop.deviceFunctionId) {
      setTab(prop.tab);
      dispatch(setValues({sensorSetting: {deviceId: prop.deviceId, deviceFunctionId: prop.deviceFunctionId}}));
    }
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      // dispatch(setValues({sensorSetting: {}}));
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = {isEdit ? "Edition of Sensor Settings" : "Creation of Sensor Settings"}
        subheader={
          <Tabs 
            tabs={["Environment Threshold", "Monitoring Threshold"]}
            value={tab} 
            onChange={handleOnChange_tab}
            disabled={isEdit}
          />
        }
      >
        <ThresholdForm isEdit={isEdit} type={tab === 0 ? "Environment" : "Monitoring"} />
      </Card>
      <Alert open={openAlertModal} onConfirm={()=>handleButtonClick_exit()} onCancel={()=>handleOnChange_tab()} />
    </React.Fragment>
  );
}
