// Create Edit Product
// Step 4: Create Task
import React, {useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
// core components
import Table from "shared-components/Table/Table";
import DragTableRow from "shared-components/Table/DragTableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";
import Select from "shared-components/Select/Select";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";
import Checkbox from "shared-components/Checkbox/Checkbox";

import { productTaskTableHead } from "enums/AdminPortal/TableHeaders";

import { GetTaskTypeList, GetUserList } from "services/AdminPortal/ProductService";
import { renderValue } from "common/functions";
import { setValues, updateSubTable } from "./store/product";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/product.module.scss";

export default function TaskTable(props) {
  const dispatch = useDispatch();
  const userList = useSelector(store => store.admin.product.lookup.userList);
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct);
  const selectedPreHarvest = useSelector(store => store.admin.product.product.productPreHarvestGrowthProcess);
  const selectedPostHarvest = useSelector(store => store.admin.product.product.productPostHarvestGrowthProcess);
  const process = useSelector(store => store.admin.product.product.process);
  const task = useSelector(store => store.admin.product.product.subTable);
  const taskTypeList = useSelector(store => store.admin.product.lookup.taskTypeList);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(null);
  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const [tasks, setTasks] = React.useState([]);
  
  const handleOnChange_select = (e) => {
    dispatch(updateSubTable({[e.target.name]: e.target.value}))
  };

  const handleOnChange_text = (e) => {
    dispatch(updateSubTable({[e.target.id]: e.target.value}))
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateSubTable({additionalMembers: value, additionalPersons : value.map(({id}) => id)}));
  };

  const handleButtonClick_add = () => {
    tasks.push({taskSequence: tasks.length+1, taskCategory: 'Manual Flow'});
    dispatch(setValues({subTable: {taskSequence: tasks.length, taskCategory: 'Manual Flow'}}));
    setEditIndex(tasks.length-1);
    props.setEditMode(true);
  }

  const handleButtonClick_edit = (item, index) => {
    dispatch(setValues({subTable: {...item, additionalMembers: userList.filter((user) => item.additionalPersons.includes(user.id))}}));
    setEditIndex(index);
    props.setEditMode(true);
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      tasks[editIndex] = task;
      // let payload = _.cloneDeep(process);
      // payload.productTask[editIndex] = task;

      // // update the parent array 
      // let processPayload = _.cloneDeep(selectedPostHarvest);
      // if (props.type === "productPreHarvestGrowthProcess") {
      //   processPayload = _.cloneDeep(selectedPreHarvest);
      // }
      // processPayload[props.growthEditIndex] = payload;
      // dispatch(setValues({[props.type]: processPayload, process: payload}));

      updateProcess();
      resetState();
    }
  }

  const handleButtonClick_cancel = () => {
    if (!task.id) {
      tasks.splice(editIndex, 1);
      // dispatch(setValues({process: payload}));
    }
    resetState();
  }

  const handleModal = (index) => {
    setDeleteIndex(index);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    setOpenModal(!openModal);
    let payload = _.cloneDeep(tasks);
    payload.splice(deleteIndex, 1);
    payload.forEach((item, index) => {
      item.taskSequence = index+1
    })
    setTasks(payload);
    // let payload = _.cloneDeep(process);
    // payload.productTask.splice(deleteIndex, 1);
    // payload.productTask.forEach((item, index) => {
    //   item.taskSequence = index+1
    // })
    // // update the parent array 
    // let processPayload = _.cloneDeep(selectedPostHarvest);
    // if (props.type === "productPreHarvestGrowthProcess") {
    //   processPayload = _.cloneDeep(selectedPreHarvest);
    // }
    // processPayload[props.growthEditIndex] = payload;
    // dispatch(setValues({[props.type]: processPayload, process: payload}));
  }

  const resetState = () => {
    setErrorMsg(false);
    setEditIndex(null);
    dispatch(setValues({subTable: {}}));
    props.setEditMode(false);
  }

  const validateFields = () => {
    if (_.isEmpty(task.taskName)) {
      setErrorMsg({field: "taskName", msg: "Add in task name"});
      return false;
    }
    if (task.taskType !== "Nutrient Conversion" && _.isEmpty(task.details)) {
      setErrorMsg({field: "details", msg: "Add in details"});
      return false;
    }
    if (task.taskType === "Nutrient Conversion" && _.isEmpty(task.nutrientSolutionARatioPerKg)) {
      setErrorMsg({field: "nutrientSolutionARatioPerKg", msg: "Add in ratio"});
      return false;
    }
    if (task.taskType === "Nutrient Conversion" && _.isEmpty(task.nutrientSolutionBRatioPerKg)) {
      setErrorMsg({field: "nutrientSolutionBRatioPerKg", msg: "Add in ratio"});
      return false;
    }
    if (!task.assignedKeyPerson) {
      setErrorMsg({field: "assignedKeyPerson", msg: "Add in key person"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const onMoveItem = useCallback((dragIndex, hoverIndex) => {
    // let processPayload = _.cloneDeep(selectedPostHarvest);
    // if (props.type === "productPreHarvestGrowthProcess") {
    //   processPayload = _.cloneDeep(selectedPreHarvest);
    // }
    // let payload = _.cloneDeep(process);
    let result = _.cloneDeep(tasks);
    const [removed] = result.splice(dragIndex, 1);
    result.splice(hoverIndex, 0, removed);
    result.map((item, index) => {
      item.taskSequence = index+1;
      return item;
    })
    setTasks(result);
    // processPayload[props.growthEditIndex].productTask = result;
    // dispatch(setValues({[props.type]: processPayload, process: payload}));
  }, [process]);

  const taskTable = () => {
    let component = [];
    tasks.map((item,index) => {
      component.push(
        <DragTableRow key={index} index={index} id={item} onMoveItem={onMoveItem} disabled={!(!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess")))}>
          <TableCell>{item.taskSequence}</TableCell>
          <TableCell>
            {(props.editMode && editIndex === index)
            ?
            <TextField 
              id="taskName"
              variant="outlined" 
              inputProps={{ maxLength: 100 }}
              onChange={(e) => handleOnChange_text(e)}
              value={task.taskName}
              errorMsg={errorMsg}
            />
            :
            item.taskName
            }
          </TableCell>
          <TableCell>
          {(props.editMode && editIndex === index)
            ? task.taskType === "Nutrient Conversion"
              ? <React.Fragment>
                  <Typography className="font-medium">1kg of Powder A & B converts to</Typography>
                  <Typography>Nutrient A Solution (l):</Typography>
                  <TextField 
                    // className={classes.alignTextfield}
                    id="nutrientSolutionARatioPerKg"
                    variant="outlined" 
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => handleOnChange_text(e)}
                    value={task.nutrientSolutionARatioPerKg}
                    errorMsg={errorMsg}
                  />
                  <Typography>Nutrient B Solution (l):</Typography>
                  <TextField 
                    // className={classes.alignTextfield}
                    id="nutrientSolutionBRatioPerKg"
                    variant="outlined" 
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => handleOnChange_text(e)}
                    value={task.nutrientSolutionBRatioPerKg}
                    errorMsg={errorMsg}
                  />
                </React.Fragment>
              : <TextField 
                  id="details"
                  variant="outlined" 
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => handleOnChange_text(e)}
                  value={task.details}
                  errorMsg={errorMsg}
                />
            : item.taskType === "Nutrient Conversion"
              ? <React.Fragment>
                  <Typography className="font-medium">1kg of Powder A & B converts to</Typography>
                  Nutrient A Solution (l): {item.nutrientSolutionARatioPerKg}<br/>
                  Nutrient B Solution (l): {item.nutrientSolutionBRatioPerKg}
                </React.Fragment>
              : item.details
            }
          </TableCell>
          <TableCell>
            {(props.editMode && editIndex === index)
            ?
            <Select
              name="taskType"
              onChange={(e)=> handleOnChange_select(e)}
              value={task.taskType}
              placeholder="Select a Task Type"
            >
              {taskTypeList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })} 
            </Select>
            : item.taskType
            }
          </TableCell>
          <TableCell>
            {(props.editMode && editIndex === index)
            ? <Select
                name="assignedKeyPerson"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(task.assignedKeyPerson)}
                placeholder="Select a person"
                errorMsg={errorMsg}
              >
                {userList && userList.map((item) => {
                  return <MenuItem key={item.id} value={item.id}>{item.userName}</MenuItem>;
                })} 
              </Select>
            : (item.assignedKeyPerson && userList.length && userList.find((user) => user.id === item.assignedKeyPerson)) ? userList.find((user) => user.id === item.assignedKeyPerson).userName : null
            }
          </TableCell>
          <TableCell>
            {(props.editMode && editIndex === index)
            ? <Autocomplete
                dataCount={userList.length}
                options={_.filter(userList, ({id}) => task.assignedKeyPerson !== id)}
                renderOption={(option) => (
                  <React.Fragment>
                    <Checkbox checked={_.findIndex(task.additionalMembers, ({id}) => id === option.id) > -1} />
                    {option.userName}
                  </React.Fragment>
                )}
                placeholder="Select team members"
                onChange={(_, newValue) => {
                  handleOnChange_autocomplete(newValue);
                }}
                value={task.additionalMembers ? task.additionalMembers : []}
                renderValue={"userName"}
                multiple
                disabled={!task.assignedKeyPerson}
              />
            : (item.additionalPersons && userList.length) &&
              userList.filter((user) => item.additionalPersons.includes(user.id)).map(({userName})=> userName).join(", ")
            }
          </TableCell>
          <TableCell>
            {!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess"))
            ? (editIndex === index)
              ? <React.Fragment>
                  <IconButton 
                    type="confirm"
                    onClick={() => handleButtonClick_confirm(index)}
                  />
                  <IconButton 
                    type="close"
                    onClick={() => handleButtonClick_cancel()}
                  />
                </React.Fragment>
              : <React.Fragment>
                  <IconButton
                    type="edit" 
                    onClick={() => handleButtonClick_edit(item, index)}
                    disabled={props.editMode}
                  />
                  <IconButton 
                    type="delete"
                    onClick={() => handleModal(index)}
                    disabled={props.editMode}
                  />
                </React.Fragment>
            : <TableCell />
            }
          </TableCell>
        </DragTableRow>
      )
    })
    return component;
  }

  const updateProcess = () => {
    let processClone = _.cloneDeep(process);
    let processPayload = _.cloneDeep(selectedPostHarvest);
    if (processClone && processPayload.length) {
      let payload = _.filter(processClone.productTask, (item) => item.taskCategory != 'Manual Flow');
      if (props.type === "productPreHarvestGrowthProcess") {
        processPayload = _.cloneDeep(selectedPreHarvest);
      }
      processClone.productTask = _.union(payload, tasks);
      processPayload[props.growthEditIndex].productTask = _.union(payload, tasks);
      dispatch(setValues({[props.type]: processPayload, process: processClone}));
    }
  }

  React.useEffect(() => {
    updateProcess();
  },[tasks]);

  //componentDidMount
  React.useEffect(() => {
    dispatch(GetTaskTypeList());
    dispatch(GetUserList());
    setTasks(_.filter(process.productTask, (item) => item.taskCategory === 'Manual Flow'));
  },[]);
  
  return (
    <React.Fragment>
      <Table
        className={styles.table}
        header={productTaskTableHead}
      >
        <DndProvider backend={HTML5Backend}>
          {taskTable()}
        </DndProvider>
      </Table>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this task? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      {!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess")) //check if user is in a non mix flow, or in a postharvest mix flow
      &&
        <Button
          className={clsx(styles.buttonSecondary, styles.addMoreProcess, styles.rawMaterialButton)}
          onClick={()=>handleButtonClick_add()}
          disabled={props.editMode}
        >
          + Add More Task
        </Button>
      }
    </React.Fragment>
  )
}
