import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import CostType from "./CostType";
import { setSearchText } from "store/general";
import { reset } from "../store/costOverview";
import { GetAllCostType, GetAllUnitOfMeasurement } from "services/UserPortal/CostProductivityService";

export default function Settings() {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const costTypes = useSelector(store => store.user.costProductivity.settings.costTypes);
  const [tab, setTab] = React.useState(0);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllCostType());
    dispatch(GetAllUnitOfMeasurement());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = "Cost & Efficiency"
        subheader={
          <React.Fragment>
            Add in the fixed cost required below:
            <Tabs 
              tabs={_.filter(costTypes, (item) => item !== "Variable Cost")}
              value={tab} 
              onChange={(e,value) => setTab(value)}
              disabled={isDirty}
            />
          </React.Fragment>
        }
      >
        <CostType costType={costTypes[tab]} />
      </Card>
    </React.Fragment>
  );
}
