import { createSlice } from "@reduxjs/toolkit";

import { GetAllASRSSoftzone } from "services/UserPortal/ASRSService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  result: [],
};

const softzone = createSlice({
  name: "softzone",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllASRSSoftzone.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.result = action.payload.result.items;
          state.totalCount = action.payload.result.totalCount;
        }
      })
  },
});

// export actions
export const { setValues, reset } = softzone.actions;

// export the reducer
export default softzone.reducer;