import { farmConfig } from "enums/Constants";
import { hideCages } from "config";

// About
export const patentTableHead = [
  { id: 'patentTitle', align: "left", label: 'Patent Title' },
  { id: 'country', align: "left", label: 'Country' },
  { id: 'grant', align: "left", label: 'Grant No. / Date of Grant' },
  // { id: 'date', align: "left", label: "INT'L APPL NO / [DATE]" },
]

// Company
export const companyTableHead = [
  { id: 'companyName', align: "left", label: 'Name' },
  { id: 'address', align: "left", label: 'Address' },
  { id: 'industry', align: "left", label: 'Company Size' },
  { id: 'companySize', align: "left", label: 'Industry' },
  { id: 'country', align: "left", label: 'Country' },
  { id: 'currency', align: "left", label: 'Currency' },
  { id: 'timeZone', align: "left", label: 'Time Zone' },
  { id: 'timeUnit', align: "left", label: 'Time Unit' },
  { id: 'action', align: "right", label: '' },
]

// Farm
export const farmTableHead = [
  { id: 'farmName', align: "left", label: 'Name' },
  { id: 'address', align: "left", label: 'Address' },
  { id: 'landSize', align: "left", label: 'Land Size' },
  { id: 'numberOfBanks', align: "left", label: 'No. of '+farmConfig.banks },
  { id: 'latitude', align: "left", label: 'Lat Long' },
  { id: 'action', align: "right", label: '' },
]

export const bankTableHead = [
  { id: 'bankName', align: "left", label: 'Name' },
  { id: 'numberOfBays', align: "left", label: 'No. of '+farmConfig.bays },
  { id: 'numberOfLevels', align: "left", label: 'No. of '+farmConfig.levels },
  { id: 'numberOfCages', align: "left", label: 'No. of '+farmConfig.cages, hidden: hideCages },
  { id: 'action', align: "right", label: '' },
]

// User
export const userTableHead = [
  { id: 'name', align: "left", label: 'Name' },
  { id: 'userName', align: "left", label: 'Username' },
  { id: 'phone', align: "left", label: 'Phone' },
  { id: 'emailAddress', align: "left", label: 'Email' },
  { id: 'employeeId', align: "left", label: 'Employee ID' },
  { id: 'roleNames', align: "left", label: 'Roles' },
  { id: 'gender', align: "left", label: 'Gender' },
  { id: 'workforceType', align: "left", label: 'Workforce Type' },
  { id: 'country', align: "left", label: 'Country / Region' },
  { id: 'action', align: "left", label: 'Untag / Edit / Delete' },
]

// Module
export const moduleTableHead = [
  { id: 'moduleName', align: "left", label: 'Module Name' },
  { id: 'description', align: "left", label: 'Module Description' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'availability', align: "left", label: 'Availability' },
  { id: 'action', align: "right", label: '' },
]

// Role
export const roleTableHead = [
  { id: 'name', align: "left", label: 'Role Name' },
  { id: 'description', align: "left", label: 'Role Description' },
  { id: 'read', align: "left", label: 'Modules (Read Only)' },
  { id: 'readEdit', align: "left", label: 'Modules (Read & Edit)' },
  { id: 'action', align: "right", label: '' },
]

export const roleModuleTableHead = [
  { id: 'modules', align: "left", label: 'Modules'},
  { id: 'read', align: "center", label: 'Read'},
  { id: 'readEdit', align: "center", label: 'Read and Edit'},
  { id: 'none', align: "center", label: 'None'},
]

// Device
export const deviceTableHead = [
  { id: 'deviceRefNo', align: "left", label: 'Device ID'},
  { id: 'deviceName', align: "left", label: 'Device Name'},
  { id: 'manufacturerID', align: "left", label: 'Hardware ID'},
  { id: 'manufacturerName', align: "left", label: 'Manufacturer Name'},
  { id: 'manufacturerPhone', align: "left", label: 'Manufacturer Contact'},
  { id: 'manufacturerEmail', align: "left", label: 'Manufacturer Email'},
  { id: 'testing', align: "left", label: 'Testing'},
  { id: 'mappingHierarchy', align: "left", label: 'Mapping Hierarchy'},
  { id: 'currentLocation', align: "left", label: 'Current Location'},
  { id: 'deviceDesc', align: "left", label: 'Device Description'},
  { id: 'action', align: "right", label: '' },
]

export const deviceBatchTableHead = [
  { id: 'deviceType', align: "left", label: 'Device Type', required: true},
  { id: 'deviceName', align: "left", label: 'Device Name'},
  { id: 'description', align: "left", label: 'Description'},
  { id: 'deviceFunctions', align: "left", label: 'Functions/Readings', required: true},
  { id: 'manufacturerID', align: "left", label: 'Hardware ID'},
  { id: 'manufacturerName', align: "left", label: 'Manufacturer Name'},
  { id: 'manufacturerContact', align: "left", label: 'Manufacturer Contact'},
  { id: 'parentDevice', align: "left", label: 'Parent Device'},
  { id: 'childDevice', align: "left", label: 'Child Device'},
  { id: 'action', align: "right", label: '' },
]

export const locationTableHead = [
  { id: 'deviceId', align: "left", label: 'Device ID'},
  { id: 'currentLocation', align: "left", label: 'Current Location'},
  { id: 'floor', align: "left", label: 'Floor'},
  { id: 'sector', align: "left", label: 'Sector'},
  { id: 'action', align: "right", label: '' },
]

export const deviceInfoTableHead = [
  { id: 'deviceRefNo', align: "left", label: 'Device ID'},
  { id: 'manufacturerID', align: "left", label: 'Hardware ID'},
]

export const deviceTestTableHead = [
  { id: 'timestamp', align: "left", label: 'Timestamp'},
  { id: 'readings', align: "left", label: 'Readings'},
]

export const readingUnitsTableHead = [
  { id: 'deviceFunctionName', align: "left", label: 'Readings'},
  { id: 'deviceFunctionUnit', align: "left", label: 'Units'},
  { id: 'shortDeviceFunctionName', align: "left", label: 'Shortform'},
  { id: 'action', align: "right", label: '' },
]

export const combineFunctionsTableHead = [
  { id: 'combineFunctionName', align: "left", label: 'Combined Functions Name'},
  { id: 'shortCombineFunctionName', align: "left", label: 'Shortform'},
  { id: 'action', align: "right", label: '' },
]

export const deviceTypesTableHead = [
  { id: 'deviceTypeName', align: "left", label: 'Device'},
  { id: 'deviceShortForm', align: "left", label: 'Shortform'},
  { id: 'functionLibrary.functionLibraryName', align: "left", label: 'Current Library'},
  { id: 'action', align: "right", label: '' },
]

export const functionsTableHead = [
  { id: 'functionsName', align: "left", label: 'Functions Name'},
  { id: 'shortcut', align: "left", label: 'Shortform'},
  { id: 'unit', align: "left", label: 'Unit'},
  { id: 'action', align: "right", label: '' },
]

export const searchDeviceTypeTableHead = [
  { id: 'deviceTypeName', align: "left", label: 'Device Type'},
  { id: 'functionLibrary.functionLibraryName', align: "left", label: 'Readings Measured / Functions'},
  { id: 'action', align: "right", label: '' },
]

export const searchDeviceFunctionTableHead = [
  { id: 'deviceFunctionName', align: "left", label: 'Functions Name'},
  { id: 'shortDeviceFunctionName', align: "left", label: 'Shortform'},
  { id: 'functionLibraryName', align: "left", label: 'Libraries'},
  { id: 'action', align: "right", label: '' },
]

// Device Tagging
export const floorPlanTableHead = [
  { id: 'floor', required: true, align: "left", label: 'Floor Number'},
  { id: 'noOfSector', required: true, align: "left", label: 'No. of Sector per Level '},
  { id: 'action', align: "right", label: '' },
]

// Cages
export const cagesTableHead = [
  { id: 's/n', align: "left", label: 'S/N'},
  { id: 'cageRefNo', align: "left", label: 'Cage ID'},
  { id: 'barcodeNumber', align: "left", label: 'Barcode ID'},
  { id: 'cageLocationId', align: "left", label: 'Cage Location ID'},
  { id: 'status', align: "left", label: 'Status'},
  { id: 'action', align: "right", label: '' },
]

export const cagesPreviewTableHead = [
  { id: 's/n', align: "left", label: 'S/N'},
  { id: 'cageRefNo', align: "left", label: 'Cage ID'},
  { id: 'barcodeNumber', align: "left", label: 'Barcode ID'},
]

// Product
export const productTableHead = [
  { id: 'productID', align: "left", label: 'Product ID' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productDescription', align: "left", label: 'Product Description' },
  { id: 'productProcess', align: "left", label: 'Product Process' },
  { id: 'productDetail', align: "left", label: 'Product Detail' },
  { id: 'action', align: "right", label: '' },
]

export const growthProcessTableHead = [
  { id: 'order', align: "left", label: 'Order', step: 2},
  { id: 'processName', align: "left", label: 'Process Name', step: 2},
  { id: 'processDuration', align: "left", label: 'Process Duration (Days)', step: 2},
  { id: 'packageSize', align: "left", label: 'Package Size (g)', step: 2, post: true},  // only appear in post
  { id: 'packageType', align: "left", label: 'Package Type', step: 2, post: true},
  { id: 'plantPerTray', align: "left", label: 'Plants Per Tray', step: 2, post: false}, // does not appear in post
  { id: 'trayPerCage', align: "left", label: 'Trays Per ' + farmConfig.cages, step: 2, post: false},
  { id: 'weightPerPlant', align: "left", label: 'Weight Per Plant (Kg)', step: 2, post: false},
  { id: 'rawMaterials', align: "left", label: 'Raw Materials/Equipment', step: 3},
  { id: 'Task', align: "left", label: 'Task', step: 4},
  { id: 'percentageLoss', align: "left", label: '% Loss', step: 5},
  { id: 'action', align: "right", label: '', step: 2},
]

export const configureProductCategoryTypeTableHead = [
  { id: 'productCategoryType', required: true, align: "left", label: 'Type'},
  { id: 'shortform', align: "left", label: 'Shortform' },
  { id: 'grouping', align: "left", label: 'Grouping' },
  { id: 'action', align: "right", label: '' },
]

export const configureProductCategoryTypeMixTableHead = [
  { id: 'productCategoryType', required: true, align: "left", label: 'Type'},
  { id: 'shortform', align: "left", label: 'Shortform' },
  { id: 'grouping', align: "left", label: 'Grouping' },
  { id: 'productMix', align: "left", label: 'Product Mix' },
  { id: 'action', align: "right", label: '' },
]

export const addViewGroupingsTableHead = [
  { id: 'groupingName', required: true, align: "left", label: 'Grouping Name'},
  { id: 'action', align: "right", label: '' },
]

export const configureProductCategoryTableHead = [
  { id: 'productCategory', required: true, align: "left", label: 'Product Category'},
  { id: 'shortform', required: true, align: "left", label: 'Shortform' },
  { id: 'combinationProduct', align: "center", label: 'Combination Product' },
  { id: 'action', align: "right", label: '' },
]

export const productRawMaterialConfigurationTableHead = [
  { id: 'UOMProduct', align: "left", label: 'UOM_Product'},
  { id: 'name', align: "left", label: 'Raw Material'},
  { id: 'id', align: "left", label: 'Raw Material ID' },
  { id: 'UOMRM', align: "left", label: 'UOM'},
  { id: 'quantityRM', align: "left", label: 'Quantity'},
  { id: 'action', align: "right", label: '' },
]

export const productDeviceConfigurationTableHead = [
  { id: 'UOMProduct', align: "left", label: 'UOM_Product'},
  { id: 'name', align: "left", label: 'Equipment'},
  { id: 'UOM', align: "left", label: 'UOM'},
  { id: 'quantity', align: "left", label: 'Quantity'},
  { id: 'action', align: "right", label: '' },
]

export const productTaskTableHead = [
  { id: 'taskSequence', align: "left", label: 'Task Seq.'},
  { id: 'taskName', align: "left", label: 'Task Name'},
  { id: 'details', align: "left", label: 'Details' },
  { id: 'taskType', align: "left", label: 'Task Type'},
  { id: 'assignedKeyPerson', align: "left", label: 'Key Person'},
  { id: 'additionalPersons', align: "left", label: 'Additional Person(s) (If Required)'},
  { id: 'action', align: "right", label: '' },
]

export const productEquipmentTaskTableHead = [
  { id: 'taskSequence', align: "left", label: 'Task Sequence'},
  { id: 'taskName', align: "left", label: 'Task Name'},
  { id: 'equipment', align: "left", label: 'Equipment' },
  { id: 'taskType', align: "left", label: 'Task Type'},
  { id: 'action', align: "right", label: '' },
]

// Raw Material
export const rawMaterialTableHead = [
  { id: 'RMID', align: "left", label: 'RM ID'},
  { id: 'RMName', align: "left", label: 'RM Name' },
  { id: 'RMDescription', align: "left", label: 'RM Description'},
  { id: 'UOM', align: "left", label: 'UOM'},
  { id: 'safetyLevel', align: "left", label: 'Safety Level'},
  { id: 'currentQty', align: "left", label: 'Current Qty'},
  { id: 'reqLeadTime', align: "left", label: 'Request Lead Time'},
  { id: 'action', align: "right", label: '' },
]

export const variantTypeTableHead = [
  { id: 'variantType', align: "left", label: ''},
  { id: 'action', align: "center", label: '' },
]

export const configureRawMaterialTypeTableHead = [
  { id: 'rawMaterialTypeName', align: "left", label: 'Raw Material Type'},
  { id: 'shortform', align: "left", label: 'Shortform'},
  { id: 'action', align: "right", label: '' },
]

export const configureVariantTypeTableHead = [
  { id: 'variantTypeName', align: "left", label: 'Variant Type'},
  { id: 'shortform', align: "left", label: 'Variant Shortform'},
  { id: 'action', align: "right", label: '' },
]

export const safetyLevelAndUOMTableHead = [
  { id: 'RMID', align: "left", label: 'RM ID'},
  { id: 'RM Name', align: "left", label: 'RM Name'},
  { id: 'UOM', align: "left", label: 'UOM'},
  { id: 'safetyLevels', align: "left", required: true, label: 'Safety Levels'},
  { id: 'leadTime', align: "left", required: true, label: 'Request Lead Time (Days)'},
  { id: 'action', align: "right", label: '' },
]

export const UOMSupportedTableHead = [
  { id: 'UOM', align: "left", label: 'Units of Measurement'},
  { id: 'shortform', align: "left", label: 'UOM Shortform'},
]

// Customer
export const customerTableHead = [
  { id: 'customerName', align: "left", label: 'Customer Name (Customer ID)'},
  { id: 'address', align: "left", label: 'Address'},
  { id: 'deliveryAddress', align: "left", label: 'Delivery Address'},
  { id: 'contact', align: "left", label: 'Contact'},
  { id: 'creditLimit', align: "left", label: 'Credit Limit'},
  { id: 'creditTerms', align: "left", label: 'Credit Terms'},
  { id: 'contactPerson', align: "left", label: 'Contact Person'},
  { id: 'priority', align: "left", label: 'Priority'},
  { id: 'action', align: "right", label: '' },
]

// Supplier
export const supplierTableHead = [
  { id: 'supplierCode', align: "left", label: 'Supplier ID'},
  { id: 'supplierName', align: "left", label: 'Supplier Name'},
  { id: 'address', align: "left", label: 'Address'},
  { id: 'emailAddress', align: "left", label: 'Email Address'},
  { id: 'contactPerson', align: "left", label: 'Contact Person'},
  { id: 'creditLimit', align: "left", label: 'Credit Limit'},
  { id: 'creditTerms', align: "left", label: 'Credit Terms'},
  { id: 'deviceSupplied', align: "left", label: 'RM/ Device Supplied'},
  { id: 'action', align: "right", label: '' },
]

// Vehicle
export const vehicleTableHead = [
  { id: 'vehicleRefNo', align: "left", label: 'Vehicle ID'},
  { id: 'vehicleName', align: "left", label: 'Vehicle Name'},
  { id: 'vehicleType', align: "left", label: 'Vehicle Type'},
  { id: 'capacity', align: "left", label: 'Capacity'},
  { id: 'carPlateNo', align: "left", label: 'Car Plate No'},
  { id: 'vehicleDescription', align: "left", label: 'Vehicle Description'},
  { id: 'driverName', align: "left", label: 'Driver Name'},
  { id: 'contact', align: "left", label: 'Contact'},
  { id: 'email', align: "left", label: 'Email'},
  { id: 'action', align: "right", label: '' },
]

export const vehicleTypeTableHead = [
  { id: 'vehicleTypes', align: "left", label: 'Vehicle Type'},
  { id: 'shortCuts', align: "left", label: 'Shortform'},
  { id: 'action', align: "right", label: '' },
]

// Manufacturer
export const manufacturerTableHead = [
  { id: 'manufacturerName', align: "left", label: 'Manufacturer Name'},
  { id: 'manufacturerType', align: "left", label: 'Manufacturer Type'},
  { id: 'integrationType', align: "left", label: 'Integration Type'},
  { id: 'noOfDevices', align: "left", label: 'No of Device IDs'},
  { id: 'action', align: "right", label: '' },
]

export const deviceIDTableHead = [
  { id: 'deviceId', align: "left", label: 'Device ID'},
  { id: 'hardwareId', align: "left", label: 'Hardware ID'},
  { id: 'referenceId', align: "left", label: 'Reference ID'},
  { id: 'otherIds', align: "left", label: 'Other IDs'},
  { id: 'action', align: "right", label: '' },
]

// Warehouse
export const warehouseTableHead = [
  { id: 'warehouseCode', align: "left", label: 'Warehouse ID'},
  { id: 'warehouseName', align: "left", label: 'Warehouse Name'},
  { id: 'capacity', align: "left", label: 'Capacity'},
  { id: 'address', align: "left", label: 'Address'},
  { id: 'inventoryList', align: "left", label: 'Inventory List'},
  { id: 'action', align: "right", label: '' },
]

// Capacity
export const farmCapacityTableHead = [
  { id: 'bankName', align: "left", label: farmConfig.bank+' Name'},
  { id: 'noOfBays', align: "center", label: 'No. of '+farmConfig.bays},
  { id: 'noOfLevels', align: "center", label: 'No. of '+farmConfig.levels},
  { id: 'noOfCages', align: "center", label: 'No. of '+farmConfig.cages},
]

export const capacityTrayConfigurationTableHead = [
  { id: 'productId', align: "left", label: 'Product ID'},
  { id: 'product.productName', align: "left", label: 'Product Name'},
  { id: 'process', align: "left", label: 'Process'},
  { id: 'plantPerTray', align: "left", label: 'Plants per Tray'},
  { id: 'trayPerCage', align: "left", label: 'Tray per ' + farmConfig.cages},
  { id: 'weightPerPlant', align: "left", label: 'Weight per Plant (kg)'},
  { id: 'perTrayProduction', align: "left", label: 'Per Tray Production (kg)'},
  { id: 'perCageProduction', align: "left", label: 'Per ' + farmConfig.cages + ' Production (kg)'},
]

export const capacityTableHead = [
  { id: 'productId', align: "left", label: 'Product ID'},
  { id: 'productName', align: "left", label: 'Product Name'},
  { id: 'process', align: "left", label: 'Process'},
  { id: 'vegetableType', align: "left", label: 'Grouping'},
  { id: 'totalPlants', align: "left", label: 'Plant (Whole Farm)'},
  { id: 'totalWeight', align: "left", label: 'Weight (kg) (Whole Farm)'},
  { id: 'possibleLossWeight', align: "left", label: 'Loss Calculation (kg)'},
]

export const searchFarmCapacityTableHead = [
  { id: 'productId', align: "left", label: 'Product ID'},
  { id: 'productName', align: "left", label: 'Product Name'},
  { id: 'capacity', align: "left", label: 'Current Farm Capacity'},
  { id: 'calculation', align: "left", label: 'Calculation of ' + farmConfig.cages},
  { id: 'cageCategories', align: "left", label: farmConfig.cage + ' Categories'},
  { id: 'action', align: "right", label: '' },
]

export const searchTrayConfigurationTableHead = [
  { id: 'productId', align: "left", label: 'Product ID'},
  { id: 'product.productName', align: "left", label: 'Product Name'},
  { id: 'process', align: "left", label: 'Process'},
  { id: 'plantPerTray', align: "left", label: 'Plants per Tray'},
  { id: 'perTrayProduction', align: "left", label: 'Per Tray Production (kg)'},
  { id: 'trayPerCage', align: "left", label: 'Tray per ' + farmConfig.cage},
  { id: 'weightPerPlant', align: "left", label: 'Weight per Plant (kg)'},
  { id: 'action', align: "right", label: '' },
]

// Equipment
export const equipmentTableHead = [
  { id: 'equipmentName', align: "left", label: 'Equipment'},
  { id: 'equipmentRefNo', align: "left", label: 'Equipment ID'},
  { id: 'shortForm', align: "left", label: 'Shortform'},
  { id: 'lowestDenominator', align: "left", label: 'Lowest Denominator'},
  { id: 'lowestUOM', align: "left", label: 'UOM'},
  { id: 'costPerUnit', align: "left", label: 'Cost Per Unit'},
  { id: 'availableUsagePerDay', align: "left", label: 'Available Usage per Day'},
  { id: 'accumulatedHoursForMaintenance', align: "left", label: 'Accumulated Ops Time for Maintenance'},
  { id: 'action', align: "left", label: 'Configure'},
]

export const configureEquipmentTableHead = [
  { id: 'functionName', align: "left", label: 'Function Name'},
  { id: 'shortform', align: "center", label: 'Shortform'},
  { id: 'requireConfiguration', align: "center", label: 'Require Configuration'},
  { id: 'uom', align: "center", label: 'UOM'},
]

export const configurePackageTableHead = [
  { id: 'productName', align: "left", label: 'Product Name'},
  { id: 'productCode', align: "left", label: 'Product Code'},
  { id: 'productWeight', align: "left", label: 'Product Weight (g)'},
  { id: 'weightPerPackage', align: "left", label: 'Weight Per Package (g)'},
  { id: 'packageType', align: "left", label: 'Packaging Type'},
  { id: 'action', align: "right", label: '' },
]

export const packageSettingsTableHead = [
  { id: 'packagingType', align: "left", label: 'Package Type'},
  { id: 'action', align: "right", label: '' },
]

// Human Resource
export const humanResourceTableHead = [
  { id: 'employeeId', align: "left", label: 'Employee ID'},
  { id: 'employeeName', align: "left", label: 'Employee Name'},
  { id: 'workforceType', align: "left", label: 'Workforce Type'},
  { id: 'hourRate', align: "left", label: 'Hourly Rate'},
  { id: 'otHourRate', align: "left", label: 'Overtime Hourly Rate'},
  { id: 'employeeStatus', align: "left", label: 'Employment Status'},
  { id: 'action', align: "right", label: '' },
]

export const taskSettingsTableHead = [
  { id: 'taskName', align: "left", label: 'Task Name'},
  { id: 'action', align: "right", label: '' },
]

// Recipe
export const stepConfigurationTableHead = [
  { id: 'UOMProduct', align: "left", label: 'UOM_Product'},
  { id: 'name', align: "left", label: 'Raw Material'},
  { id: 'id', align: "left", label: 'Raw Material ID' },
  { id: 'UOMRM', align: "left", label: 'UOM'},
  { id: 'quantityRM', align: "left", label: 'Quantity'},
]

export const processManagementTableHead = [
  { id: 'order', align: "left", label: 'Order'},
  { id: 'processName', align: "left", label: 'Process Name'},
  { id: 'processDuration', align: "left", label: 'Process Duration (Days)' },
]

// RFID
export const rfidTableHead = [
  { id: 'serialNo', align: "left", label: 'S/N'},
  { id: 'deviceId', align: "left", label: 'Device ID'},
  { id: 'rfidId', align: "left", label: 'RFID ID'},
  { id: 'rfidGroupName', align: "left", label: 'Groupings'},
  { id: 'action', align: "right", label: '' },
]

export const groupingsTableHead = [
  { id: 'serialNo', align: "left", label: 'S/N'},
  { id: 'groupName', align: "left", label: 'Groupings'},
  { id: 'action', align: "right", label: '' },
]

// Admin Log
export const adminLogTableHead = [
  { id: 'eventType', align: "left", label: 'Event Type'},
  { id: 'eventDetail', align: "left", label: 'Event Detail'},
  { id: 'portalType', align: "left", label: 'Portal Type'},
  { id: 'userName', align: "left", label: 'Administrator'},
  { id: 'date', align: "left", label: 'Date'},
  { id: 'clientIpAddress', align: "left", label: 'IP Address'},
]