import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// core components
import Radio from 'shared-components/Radio/Radio';
import TextField from "shared-components/TextField/TextField";

import { renderValue } from "common/functions";
import { updateRequest } from ".";

import styles from "assets/jss/components/AdminPortal/cage.module.scss";

export default function CreateForm(props) {
  const dispatch = useDispatch();
  const cage = useSelector(store => store.admin.cage.cage);
  const chamberList = useSelector(store => store.admin.cage.chamberList);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_radio = (e) => {
    dispatch(updateRequest({chamberId : Number(e.target.value)}));
  }

  return (
    <React.Fragment>
      <Grid container spacing={4} className={styles.gridContainer} >
        <Grid item xs={12}>
          <Typography className={styles.sectionHeader}>Please choose the correct prefix and key in the number of</Typography>
          {(props.errorMsg && props.errorMsg.field === 'chamberId') && <Typography className={styles.error}>{props.errorMsg.msg}</Typography>}
        </Grid>
        <Grid item xs={12}>
          { chamberList.map((item, index)=>{
            return (
              <div className={styles.radioList} key={index}>
                <Radio 
                  id="chamberId"
                  className={styles.radio}
                  value={item.id}
                  checked={item.id === cage.chamberId}
                  onChange={(e)=>handleOnChange_radio(e)}
                />
                <Typography className={styles.buttonLabel}>{item.chamberName}</Typography>
              </div>
            )
          })}
        </Grid>
        <Grid item xs={2}>
          <Typography>No of Cages to be added: </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField 
            id="noOfCages"
            type="number"
            variant="outlined" 
            onInput={(e)=>{ 
              e.target.value = Math.max(0, e.target.value).toString().slice(0,15)
            }}
            placeholder="Enter a value"
            onChange={(e) => handleOnChange_text(e)}
            value={renderValue(cage.noOfCages)}
            errorMsg={props.errorMsg}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
