import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Modal from "shared-components/Modal/Modal";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";

import { setValues } from "./store/report";
import { reportSettingTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllActiveGraph, GetAllDashboardSetting, AddOrRemoveGraphToDashboard, ExportQCReportGraphData } from "services/UserPortal/ReportService";

import DashboardChart from "./DashboardChart";
import styles from "assets/jss/components/UserPortal/report.module.scss";

const FileDownload = require('js-file-download');

export default function Dashboard(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.report.report.details.totalCount);
  const results = useSelector(store => store.user.report.report.details.items);
  const settings = useSelector(store => store.user.report.report.settings);
  const dashboardList = useSelector(store => store.user.report.report.dashboardList);
  const [openConfigModal, setOpenConfigModal] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const prop = props.location.state ?? {};
  const count = Math.ceil(totalCount / 2);

  const handleOnDownload = (id) => {
    dispatch(ExportQCReportGraphData(id))
    .then((response) => {
      FileDownload(response.payload.data, 'reporting.xlsx');
    });
  }

  const handleButtonClick_checkbox = (e, index) => {
    let payload = _.cloneDeep(dashboardList);
    payload[index].addToDashboard = !payload[index].addToDashboard;
    dispatch(setValues({dashboardList: payload}));
  };

  const handleConfigModal = () => {
    dispatch(setValues({dashboardList: settings.map((item) => {
      return {
        id: item.id,
        configurationName: item.configurationName,
        addToDashboard: item.isActive,
      }
    })}));
    setOpenConfigModal(!openConfigModal);
  }

  const handleOnSave = () => {
    dispatch(AddOrRemoveGraphToDashboard())
    .then(() => {
      dispatch(GetAllDashboardSetting());
      dispatch(GetAllActiveGraph({page: 0}));
      setPage(0);
      setOpenConfigModal(false);
    });
  }

  const handleOnReset = () => {
    let payload = _.cloneDeep(dashboardList);
    dispatch(setValues({dashboardList: payload.map((item) => {
      return {
        id: item.id,
        configurationName: item.configurationName,
        addToDashboard: false,
      }
    })}));
  }

  React.useEffect(() => {
    dispatch(GetAllActiveGraph({page}));
  },[page]);

  React.useEffect(() => {
    dispatch(GetAllDashboardSetting());
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = {"Report Dashboard - " + (prop.title)}
        action={
          <div className={styles.cardHeader}>
            <Button
              className={styles.buttonSecondary}
              onClick={handleConfigModal}
            >
              Dashboard Settings
            </Button>
            <Button 
              className={styles.buttonSecondary}
              onClick={() => history.push({pathname: location.pathname + '/configurations', state: {title: prop.title}})}
            >
              View Configurations
            </Button>
          </div>
        }
      >
        { results && results.map((item,index) => {
          return (
            <Card 
              key={index}
              classes={{
                root: clsx(styles.cardPaper, styles.cardListItem),
                header: styles.cardPaperHeader
              }}
              title = {item.configurationName}
              action={
                <Button
                  className={clsx(styles.buttonRight, styles.buttonSecondary)}
                  onClick={()=>handleOnDownload(item.id)}
                >
                  Download CSV
                </Button>
              }
            >
              <DashboardChart results={item} />
            </Card>
          )
        })}
        { totalCount > 2 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Card>
      <Modal
        className={styles.modal}
        open={openConfigModal}
        onClose={handleConfigModal}
        title="Available Configurations"
        content={
          <React.Fragment>
            <Typography>Select configurations to add to dashboard</Typography>
            <div className={styles.modalContainer}>
              <Table
                className={styles.modalTable}
                header={reportSettingTableHead}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { dashboardList.map((item,index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>{item.configurationName}</TableCell>
                        <TableCell align="center">
                          <Checkbox 
                            id="addToDashboard"
                            checked={item.addToDashboard}
                            onChange={(e) => handleButtonClick_checkbox(e, index)}
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                })}
              </Table>
            </div>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleOnReset()}>Reset</Button>
            <Button className={styles.button} onClick={() => handleOnSave()}>Save</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
