import React, {useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// core components
import Card from "shared-components/Card/Card";
import Modal from "shared-components/Modal/Modal";

import { minDate } from "config";
import { setSearchText } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues } from "../store/germination";
import { GetGerminationReportDetail, GetProductInfoById } from "services/UserPortal/QualityControlService";

import PrintFormat from "layouts/PrintFormat";
import styles from "assets/jss/components/UserPortal/qualityControl.module.scss";

export default function GerminationReport(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const qualityControl = useSelector(store => store.user.qualityControl.germination.qualityControl);
  const [zoomModal, setZoomModal] = useState(false);
  const [, setOnBeforeGetContentResolve] = useState(false);
  const [printing, setPrinting] = useState(false);
  
  const handleOnClick_zoom = (item) => {
    setZoomModal(item);
  }

  const handleButtonClick_print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Quality_Control_Report_"+qualityControl.workOrderNumber,
    removeAfterPrint: true,
    onAfterPrint: () => {
      setPrinting(false);
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setPrinting(true);
        setOnBeforeGetContentResolve(resolve);
      });
    },
  })

  const component = (isPrinting) => {
    return (
      <Grid container spacing={4}>
        {isPrinting && 
          <Grid item xs={12}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Quality Control Germination Report</Typography>
          </Grid>
        }
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Work Order:</Typography>
          <Typography className={isPrinting && styles.printFont}>{qualityControl.workOrderNumber}</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Vegetable Name:</Typography>
          <Typography className={isPrinting && styles.printFont}>{qualityControl.productName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>General Information</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Serial No:</Typography>
          <Typography className={isPrinting && styles.printFont}>{qualityControl.germinationSerialNo}</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Vegetable Variety:</Typography>
          <Typography className={isPrinting && styles.printFont}>{qualityControl.vegetableVariety}</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Seed Supplier:</Typography>
          <Typography className={isPrinting && styles.printFont}>{qualityControl.supplierName}</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Batch No:</Typography>
          <Typography className={isPrinting && styles.printFont}>{qualityControl.batchID}</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Tray Position:</Typography>
          <Typography className={isPrinting && styles.printFont}>{qualityControl.trayPosition}</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Batch Start Date:</Typography>
          <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.batchStartDate).isAfter(minDate) && moment(qualityControl.batchStartDate).format("DD/MM/YYYY")}</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Batch Open Date:</Typography>
          <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.batchOpenDate).isAfter(minDate) && moment(qualityControl.batchOpenDate).format("DD/MM/YYYY")}</Typography>
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={12}>
          <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Dates</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Germinate Date:</Typography>
          <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.germinateDate).isAfter(minDate) && moment(qualityControl.germinateDate).format("DD/MM/YYYY")}</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Transplant Date:</Typography>
          <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.transplantDate).isAfter(minDate) && moment(qualityControl.transplantDate).format("DD/MM/YYYY")}</Typography>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Nursery Duration:</Typography>
          <Typography className={isPrinting && styles.printFont}>{qualityControl.nurseryDuration}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Germination Rate</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Environmental Conditions</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Total Quantity:</Typography>
              <Typography className={isPrinting && styles.printFont}>{qualityControl.totalQty}</Typography>
            </Grid>
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Seedling Image:</Typography>
              {qualityControl.goodSeedlingPhoto
              ? <img src={qualityControl.goodSeedlingPhoto} className={clsx(styles.hcImageDiv, styles.hcImage, qualityControl.goodSeedlingPhoto && styles.reportImg)} style={{ backgroundColor: '#FFF' }} onClick={()=>handleOnClick_zoom(qualityControl.goodSeedlingPhoto)} />
              : <div className={clsx(styles.hcImageDiv, styles.hcImage, qualityControl.goodSeedlingPhoto && styles.reportImg)} style={{ backgroundColor: '#FFF' }} />
              }
            </Grid>
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel2, isPrinting && styles.printLabel)}>Water Level (cm):</Typography>
              <Grid container className={styles.gridMargin}>
                <Grid item xs={6}>
                  <Typography className={clsx(clsx(styles.formLabel3, isPrinting && styles.printLabel), isPrinting && styles.printLabel2)}>Initial</Typography>
                  <Typography className={isPrinting && styles.printFont}>{qualityControl.initialWaterLevel}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={clsx(clsx(styles.formLabel3, isPrinting && styles.printLabel), isPrinting && styles.printLabel2)}>Final</Typography>
                  <Typography className={isPrinting && styles.printFont}>{qualityControl.finalWaterLevel}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Empty Sponge:</Typography>
              <Typography className={isPrinting && styles.printFont}>{qualityControl.emptySponge}</Typography>
            </Grid>
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Good Seedling:</Typography>
              <Typography className={isPrinting && styles.printFont}>{qualityControl.goodSeeding}</Typography>
            </Grid>
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel2, isPrinting && styles.printLabel)}>EC (mS/cm):</Typography>
              <Grid container className={styles.gridMargin}>
                <Grid item xs={6}>
                  <Typography className={isPrinting && styles.printFont}>{qualityControl.initialEC}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={isPrinting && styles.printFont}>{qualityControl.finalEC}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Germination Rate:</Typography>
              <Typography className={isPrinting && styles.printFont}>{qualityControl.germinationRate}</Typography>
            </Grid>
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Weak Seedling:</Typography>
              <Typography className={isPrinting && styles.printFont}>{qualityControl.weakSeeding}</Typography>
            </Grid>
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel2, isPrinting && styles.printLabel)}>TDS (ppm):</Typography>
              <Grid container className={styles.gridMargin}>
                <Grid item xs={6}>
                  <Typography className={isPrinting && styles.printFont}>{qualityControl.initialTDS}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={isPrinting && styles.printFont}>{qualityControl.finalTDS}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} className={styles.grid} />
            <Grid item xs={4} className={styles.grid}>
              <Typography className={clsx(styles.formLabel2, isPrinting && styles.printLabel)}>pH Level:</Typography>
              <Grid container className={styles.gridMargin}>
                <Grid item xs={6}>
                  <Typography className={isPrinting && styles.printFont}>{qualityControl.initialPHLevel}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={isPrinting && styles.printFont}>{qualityControl.finalPHLevel}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.grid}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Remarks</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.spongeSpecs ?? "None"}</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} />
        <Grid item xs={12} className={styles.grid}>
            <Typography className={styles.printFooter}>- END OF REPORT -</Typography>
        </Grid>
      </Grid>
    );
  }
  
  React.useEffect(() => {
    if (qualityControl.productId) {
      dispatch(GetProductInfoById(qualityControl.productId));
    }
  },[qualityControl.productId]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    const prop = props.location.state;
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "report");
    dispatch(GetGerminationReportDetail({id: path[index+1], isPastRecord: prop && prop.isPastRecord}))
    .then(({error}) => {
      if (error) {
        history.goBack();
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(setValues({qualityControl: {}}));
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = "Quality Control Germination Report"
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.goBack()}
            >
              Go Back
            </Button>
          </React.Fragment>
        }
        action={!printing && 
          <Button
            className={styles.buttonSecondary}  
            onClick={handleButtonClick_print}
          >
            Export
          </Button>
        }
      >
        {component()}
        <Modal
          classes={{
            content: styles.zoomModalContent
          }}
          open={Boolean(zoomModal)}
          className={styles.zoomModal}
          onClose={() => handleOnClick_zoom(false)}
          content={
            <React.Fragment>
              <img src={zoomModal} className={styles.zoomImage} />
            </React.Fragment>
          }
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleOnClick_zoom(false)}>Close</Button>
            </React.Fragment>
          }
        />
      </Card>
      <div ref={componentRef}>
        <PrintFormat printing={printing}>
          {component(true)}
        </PrintFormat>
      </div>
    </React.Fragment>
  )
}
