/* eslint-disable no-unused-vars */
import React, {useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import ImageMarker from 'react-image-marker';
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Popper from "shared-components/Popper/Popper";
import Tag from 'shared-components/Chip/Tag';
// @mui/icons-material
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// core components
import Radio from 'shared-components/Radio/Radio';
import DateTimePicker from "shared-components/DatePicker/DateTimePicker";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";

import { minDateTime } from "config";
import { renderValue } from "common/functions";
import { setDirty, setSuccess, setError } from "store/general";
import { setValues, updateRequest, reset } from "../store/adhoc";
import { ExecuteGetAllRobots, ExecuteGetMapByName, CreateOrUpdateMission } from "services/UserPortal/AMRService";

import map from "./amr map.jpg";
import search from "assets/icons/grey/search.svg";
import batchUpdate from "assets/img/batchUpdate-img.svg";
import styles from "assets/jss/components/UserPortal/amr.module.scss";

export default function TaskCreation() {
  const canvasRef = useRef(null)
  const dispatch = useDispatch();
  const task = useSelector(store => store.user.amr.adhoc.task);
  const amrList = useSelector(store => store.user.amr.adhoc.amrList);
  const nodeList = useSelector(store => store.user.amr.adhoc.nodeList);
  const actionList = useSelector(store => store.user.amr.adhoc.actionList);
  const [markers, setMarkers] = React.useState([]);
  const [selectedRobot, setSelectedRobot] = React.useState(null);
  const [startDate, setStartDate] = React.useState();
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);

  const handleOnChange_radio = (e) => {
    setStartDate(e.target.value);
    if (e.target.value === "Now") {
      dispatch(updateRequest({delayDateTime : moment(minDateTime).format("DD/MM/YYYY HH:mm:ss")}));
    } else {
      dispatch(updateRequest({delayDateTime : moment().format("DD/MM/YYYY HH:mm:ss")}));
    }
  }

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      const newValue = moment(value).set({second: 0, millisecond: 0});
      let payload = {[field]: newValue.format()};
      dispatch(updateRequest(payload));
    } else {
      dispatch(updateRequest({delayDateTime : moment(value).format("DD/MM/YYYY HH:mm:ss")}));
    }
  };

  const handleOnChange_select = (e) => {
    if (e.target.name === "robot_id") {
      const robot = _.find(amrList, ({id}) => id === e.target.value);
      dispatch(updateRequest({[e.target.name] : e.target.value, req_robgroups: robot.req_robgroups}));
    } else {
      dispatch(updateRequest({[e.target.name] : e.target.value}));
    }
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  // add new action
  const handleButtonClick_location = (id, action) => {
    let payload = _.cloneDeep(task.steps) ?? [];
    payload = [...payload, {map_name: selectedRobot.map_name, target: id, action: action}];
    dispatch(updateRequest({steps : payload}));
    setOpenPopper(false);
  }

  // remove action
  const handleButtonClick_delete = (index) => {
    let payload = _.cloneDeep(task.steps) ?? [];
    payload.splice(index, 1);
    dispatch(updateRequest({steps : payload}));
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateMission({missionId: 0, ...task}))
        .then((response)=>{
          if (response.error) {
            dispatch(setError("An error has occurred. Please try again."));
          } else {
            if (task.startDate === "Now") {
              dispatch(setSuccess("Success! Your mission will start now."));
            } else {
              dispatch(setSuccess("Success! Your mission is saved."));
            }
            dispatch(reset());
          }
        })
      })
    }
  }

  const validateFields = () => {
    if (!task.robot_id) {
      setErrorMsg({field: "robot_id", msg: "Select a robot"});
      return false;
    }
    if (!startDate === "Now" && !task.delayDateTime) {
      setErrorMsg({field: "delayDateTime", msg: "Enter a start date"});
      return false;
    }
    if (!moment(task.delayDateTime).isValid()) {
      setErrorMsg({field: "delayDateTime", msg: "Invalid Date"});
      return false;
    }
    if (!task.steps.length) {
      setErrorMsg({field: "endLocation", msg: "Select a location"});
      return false;
    }
    // if (!task.isNoEndDateTime && !task.endDateTime) {
    //   setErrorMsg({field: "endDateTime", msg: "Please enter an end date"});
    //   return false;
    // }
    // if (!moment(task.endDateTime).isValid()) {
    //   setErrorMsg({field: "endDateTime", msg: "Invalid Date"});
    //   return false;
    // }
    // if (task.endDateTime && moment(task.endDateTime).isBefore(moment(task.delayDateTime))) {
    //   setErrorMsg({field: "endDateTime", msg: "End date cannot be before start date"});
    //   return false;
    // }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const getMarker = () => {
    dispatch(ExecuteGetMapByName({name: "greenphyto_level_4"}))
    .then(({payload}) => {
      if (payload && payload.result) {
        const result = payload.result.nodes;
        let location = [];
        for (let obj of result) {
          // need to know original image height and width px
          // top and left is in percentage
          location = _.unionBy(location , [{ ...obj, top: (obj.y/966)*100, left: (obj.x/2066)*100 }], "id");
        }
        setMarkers(location);
      }
    })
  }

  const CustomMarker = (obj) => {
    return (
      <Popper 
        noButton
        open={openPopper === obj.id}
        className={styles.popper}
        closePopper={() => setOpenPopper(false)}
        placement="right"
        title={"Point "+obj.id+" Actions"}
        content={
          <React.Fragment>
            {actionList.map((item, i) => {
              if (item !== "stopCharge") {
                return (
                  <Button key={i} className={styles.popperButton} onClick={()=>handleButtonClick_location(obj.id, item)}>{item}</Button>
                )
              }
            })}
          </React.Fragment>
        }
      >
        <div className={clsx(styles.markerDiv, styles.marker, styles.clickable)} onClick={()=>setOpenPopper(obj.id)}>
          <Typography className={styles.markerText}>{obj.id}</Typography>
        </div>
      </Popper>
    );
  };

  React.useEffect(() => {
    if (task.robot_id) {
      const robot = _.find(amrList, ({id}) => id === task.robot_id);
      setSelectedRobot(robot);
      dispatch(ExecuteGetMapByName({name: robot.map_name}));
    }
  },[task.robot_id]);

  React.useEffect(() => {
    dispatch(setValues({task: {robot_id: amrList[0].id, task_type: "AdHoc", delayDateTime: moment(minDateTime).format("DD/MM/YYYY HH:mm:ss")}}));
    setStartDate("Now");
  },[amrList]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(ExecuteGetAllRobots());
  },[]);

  // componentDidMount
  React.useEffect(() => {
    getMarker();
  },[]);
  
  return (
    <React.Fragment>
      <Grid container spacing={4} className={styles.gridContainer}>
        <Grid item xs={7} className={styles.formField}>
          <Typography>Start Date/Time:</Typography>
          <Radio 
            id="startDate"
            className={styles.radio}
            value={"Now"}
            checked={startDate === "Now"}
            onChange={(e)=>handleOnChange_radio(e)}
          />
          <Typography className={styles.radioLabel}>Now</Typography>
          <Radio 
            id="startDate"
            className={styles.radio}
            value={"Schedule"}
            checked={startDate === "Schedule"}
            onChange={(e)=>handleOnChange_radio(e)}
          />
          <Typography className={styles.radioLabel}>Schedule</Typography>
          <DateTimePicker
            type="datetime"
            name="delayDateTime"
            value={moment(task.delayDateTime, "DD/MM/YYYY HH:mm:ss").isAfter(minDateTime) && task.delayDateTime}
            minDateTime={moment().format("DD/MM/YYYY HH:mm:ss")}
            onChange={(e) => handleOnChange_date(e, "delayDateTime")}
            errorMsg={errorMsg}
            disabled={startDate === "Now"}
          />
        </Grid>
        <Grid item xs={5} />
        {/* <Grid item xs={5} className={styles.formField}>
          <Typography>End Date/Time:</Typography>
          <DateTimePicker
            type="datetime"
            name="endDate"
            value={task.endDate}
            minDateTime={task.startDate}
            onChange={(e) => handleOnChange_date(e, "endDate")}
            errorMsg={errorMsg}
          />
        </Grid> */}
        <Grid item xs={5} className={styles.formField}>
          <Typography>Task Name:</Typography>
          <TextField 
            id="nature"
            variant="outlined" 
            onChange={(e) => handleOnChange_text(e)}
            placeholder="Enter Value"
            value={renderValue(task.nature)}
            errorMsg={errorMsg}
          />
        </Grid>
        <Grid item xs={5} className={styles.formField}>
          <Typography>Task Type:</Typography>
          <Select
            name="task_type"
            onChange={(e)=>handleOnChange_select(e)}
            value={task.task_type}
            placeholder="Select Value"
            errorMsg={errorMsg}
          >
            { ["AdHoc", "Recurring"].map((item, index) => {
              return <MenuItem key={index} value={item}>{item}</MenuItem>;
            })} 
          </Select>
        </Grid>
        {task.task_type === "Recurring" &&
          <React.Fragment>
            <Grid item xs={5} className={styles.formField}>
              <Typography>Frequency:</Typography>
              <Select
                name="frequency"
                onChange={(e)=>handleOnChange_select(e)}
                value={task.frequency}
                placeholder="Select Value"
                errorMsg={errorMsg}
              >
                { ["Daily", "Weekly", "Monthly"].map((item, index) => {
                  return <MenuItem key={index} value={item}>{item}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={7} />
          </React.Fragment>
        }
        <Grid item xs={5} className={styles.formField}>
          <Typography>AMR:</Typography>
          <Select
            name="robot_id"
            onChange={(e)=>handleOnChange_select(e)}
            value={task.robot_id}
            placeholder="Select Value"
            errorMsg={errorMsg}
          >
            { amrList.map((item, index) => {
              return <MenuItem key={index} value={item.id}>{item.alias}</MenuItem>;
            })} 
          </Select>
        </Grid>
        <Grid item xs={5} className={styles.formField}>
          {selectedRobot && "Map: "+selectedRobot.map_name}
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={12} className={styles.formTagContainer}>
          <Typography className={styles.formTagLabel}>Path Sequence: </Typography>
          <div className={styles.formTag}>
            {_.map(task.steps, (item,i) => {
              return(
                <div className={styles.stepContainer}>
                  {i > 0 && <ArrowForwardIcon />}
                  {/* <span className={styles.step}>{item.target} ({item.action})</span> */}
                  <Tag
                    className={styles.step}
                    variant="outlined"
                    tabIndex={-1}
                    label={item.target +" (" +item.action+")"}
                    onDelete={() => handleButtonClick_delete(i)}
                  />
                </div>
              )
            })}
          </div>
        </Grid>
        <div className={styles.mapDiv}>
          <ImageMarker
            src={map}
            markers={markers}
            // onAddMarker={(marker) => handleOnChange_marker(marker)}
            markerComponent={CustomMarker}
          />
        </div>
        <Grid item xs={12} className={styles.buttonContainer}>
          <Button 
            className={styles.button}
            onClick={() => handleButtonClick_save()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
