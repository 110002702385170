import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import Typography from "@mui/material/Typography";

import { formatNumbers } from "common/functions";
import { reset } from "../store/search";
import { setSearchText } from "store/general";

import styles from "assets/jss/components/UserPortal/equipment.module.scss";
import SearchReserveResult from "./SearchReserveResult";
import SearchIssueResult from "./SearchIssueResult";
import SearchCostResult from "./SearchCostResult";
import SearchStatusResult from "./SearchStatusResult";
import SearchMaintenanceResult from "./SearchMaintenanceResult";

export default function SearchResult() {
  const dispatch = useDispatch();
  //count
  const reserveTotalCount = useSelector(store => store.user.equipment.search.reserveTotalCount);
  const issueTotalCount = useSelector(store => store.user.equipment.search.issueTotalCount);
  const costTotalCount = useSelector(store => store.user.equipment.search.costTotalCount);
  const statusTotalCount = useSelector(store => store.user.equipment.search.statusTotalCount);
  const maintenanceTotalCount = useSelector(store => store.user.equipment.search.maintenanceTotalCount);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={styles.result}>{formatNumbers(reserveTotalCount + issueTotalCount + costTotalCount + statusTotalCount + maintenanceTotalCount) + " Search Result(s) From 5 Tables:  ‘" + params.keyword + "’"}</Typography>
      <SearchReserveResult />
      <SearchIssueResult />
      <SearchCostResult />
      <SearchStatusResult />
      <SearchMaintenanceResult />
    </React.Fragment>
  );
}
