import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
// @mui/material
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { setSearchText } from "store/general";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, filterParam, filterUrl } from "common/functions";
import { setValues, reset } from "../store/management";
import { rawMaterialTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllRawMaterial } from "services/UserPortal/RawMaterialService";

import styles from "assets/jss/components/UserPortal/rawMaterial.module.scss";

export default function Management() {
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.rawMaterial.managementRM.filter);
  const totalCount = useSelector(store => store.user.rawMaterial.managementRM.totalCount);
  const orders = useSelector(store => store.user.rawMaterial.managementRM.orders);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('rawMaterialName');

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const getAllRawMaterial = (stopLoading) => {
    dispatch(GetAllRawMaterial({...filter, stopLoading}));
  }

  React.useEffect(() => {
    window.history.pushState({}, '', filterUrl(filter));
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllRawMaterial();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam({page:0})}}));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllRawMaterial(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="Raw Material Master List"
      >
        <div className={styles.table}>
          <Table
            className={styles.mainTable}
            header={rawMaterialTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { orders.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.rawMaterialName}</TableCell>
                  <TableCell>{item.rawMaterialRefNo}</TableCell>
                  <TableCell>{item.rawMaterialDescription}</TableCell>
                  <TableCell>{item.unitOfMeasurement}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.safetyLevel))}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.maximumLevel))}</TableCell>
                  <TableCell>{item.isInfinityQty ? "-" : formatNumbers(roundTo2Decimal(item.currentQty))}</TableCell>
                  <TableCell>{item.isInfinityQty ? "-" : formatNumbers(roundTo2Decimal(item.minimumOrderQuantity))}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.requestLeadTime))}</TableCell>
                  <TableCell>
                    {item.rawMaterialName !== "Water" &&
                      <Link className={styles.link} to={{pathname: "/user/raw-material/management/"+item.id, state: {rawMaterialName: item.rawMaterialName}}}>
                        Details
                      </Link>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
