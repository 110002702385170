
import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllRoles = createAsyncThunk(
  "role/getAllRoles", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/Role/GetAll", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllModules = createAsyncThunk(
  "role/getAllModule",
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Role/GetAllModule");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)

export const DeleteRole = createAsyncThunk(
  "role/deleteRole", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/Role/Delete", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRole = createAsyncThunk(
  "role/createOrUpdateRole", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const role = getState().admin.role.role;
    let body = {
      ...role,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/Role/CreateOrUpdate", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRoleData = createAsyncThunk(
  "role/getRole", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/Role/Get", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchRole = createAsyncThunk(
  "role/searchRole", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Role/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);