import { combineReducers } from "redux";
import managementRM from "./management";
import reserveRM from "./reserve";
import requestRM from "./request";
import receiptRM from "./receipt";
import issueRM from "./issue";
import costRM from "./cost";
import reconciliationRM from "./reconciliation";
import returnRM from "./return";
import search from "./search";

const rawMaterialReducer = combineReducers({
  managementRM,
  reserveRM,
  requestRM,
  receiptRM,
  issueRM,
  costRM,
  reconciliationRM,
  returnRM,
  search,
});

export default rawMaterialReducer;