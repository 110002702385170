import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui/material
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";

import { resetCage } from ".";
import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { GetChamberList, GetCagePreview, CreateCage } from "services/AdminPortal/CagesService";

import CreateForm from "./CreateForm";
import Preview from "./Preview";
import styles from "assets/jss/components/AdminPortal/cage.module.scss";

export default function AddCages() {
  let history = useHistory();
  const dispatch = useDispatch();
  const cage = useSelector(store => store.admin.cage.cage);
  const [step, setStep] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleButtonClick_next = () => {
    if (validateFields()) {
      dispatch(GetCagePreview());
      setStep(1);
    }
  }

  const handleButtonClick_save = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      dispatch(CreateCage())
      .then(({error}) => {
        if (!error) {
          history.push("/admin/cages");
        }
      });
    })
  }

  const validateFields = () => {
    if (!cage.chamberId) {
      setErrorMsg({field: "chamberId", msg: "Please select a chamber"});
      return false;
    }
    if (!cage.noOfCages) {
      setErrorMsg({field: "noOfCages", msg: "Please enter number of cages"});
      return false;
    }
    else {
      setErrorMsg(false);
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetChamberList());
    dispatch(setAddNewButton(true));
    dispatch(setDirty(true));
    // const path = location.pathname.split("/");
    // const index = path.findIndex((item) => item === "cages");
    // if (path[index+1] != "create") {
    //   dispatch(GetManufacturer(path[index+1]))
    //   .then(({error}) => {
    //     if (error) {
    //       history.push("/admin/cages");
    //     } else {
    //       dispatch(setDirty(true));
    //     }
    //   });
    // } else {
    //   dispatch(setDirty(true));
    // }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetCage());
      dispatch(resetNav());
    }
  },[]);

  const renderForm = () => {
    switch(step) {
      case 0:
        return <CreateForm errorMsg={errorMsg} />
      case 1:
        return <Preview />
    }
  }
  
  return (
    <Card 
      title="Cages - Onboarding"
      cardActions={
        step === 0
        ? <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.goBack()}
            >
              Back
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_next()}
            >
              Preview
            </Button>
          </React.Fragment>
        : <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>setStep(0)}
            >
              Back
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
      }
    >
      {renderForm()}
    </Card>
  )
}
