import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
// @mui/material
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";

import { graphColors as colors } from "assets/jss/palette";
import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";

export default function LineChart(props) {
  const [config, setConfig] = React.useState({chart: { zoomType: 'x' }});

  React.useEffect(() => {
    if (props.results) {
      props.setIsLoading(false);
      setConfig({
        chart: {
          zoomType: 'x',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          title: {
            text: props.title
          }
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: true,
          verticalAlign: 'bottom',
          align:'left',
          symbolRadius: 0,
        },
        plotOptions: {
          series: {
            animation: props.isLoading,
            label: {
              connectorAllowed: false
            },
            marker: {
              symbol: "circle"
            }
          }
        },
        series: props.results.map((device, i) => {
          if (device) {
            return {
              data: device.details.map((item) => [moment(item.timestamp).valueOf(), Number(item.costChart)]),
              name: device.deviceName,
              color: colors[i]
            }
          }
        })
      });
    }
  },[props.results]);
  
  if (props.isLoading) {
    return (
      <div className={styles.chartContainer}>
        <CircularProgress
          variant="indeterminate"
          className={styles.progress}
          thickness={6}
        />
      </div>
    ) 
  } else {
    return (
      <React.Fragment>
        {props.results && props.results.length
        ? <HighchartsReact highcharts={Highcharts} options={config} containerProps={{ className: styles.highChart }} />
        : <div className={styles.chartContainer}><Typography className={styles.graphText}>No Data</Typography></div>
        }
      </React.Fragment>
    );
  }
}
