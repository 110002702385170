import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllBanks, GetFunctionLibraryList, GetAllSensorControlDeviceFunction, 
  GetWorkOrderList, GetEquipmentList, GetProductList, GetProductListByWorkOrderId,
  GetProductGrowthProcessList, GetProductTaskList, GetUserList, 
  GetAllUserUOMs, GetNotYetStartedWorkOrderList, GetAllRawMaterialInfo, GetRawMaterialList, GetRawMaterialBatchList,
  GetAllSupplierInfo, GetAllWarehouseInfo, GetAllRawMaterialInfoForMapping, GetAllLotIdLookup } from "services/UserPortal/CommonLookupService";

// initial state
const initialState = {
  bankList: [],
  workOrderList: [],
  userList: [],
  uomList: [],
  allRawMaterialInfo: [],
  supplierList: [],
  warehouseList: [],
  equipmentList: [],
  lotIdList: [],
  // will change
  deviceList: [],
  libraryList: [],
  functionList: [],
  productList: [],
  processList: [],
  taskList: [],
  rawMaterialList: [],
  batchList: [],
  unstartedWorkOrderList: [],
  saleOrderList: [],
};

// create reducer and action creators
const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    resetValues: (state) => {
      state.deviceList = [];
      state.libraryList = [];
      state.functionList = [];
      state.productList = [];
      state.processList = [];
      state.taskList = [];
      state.unstartedWorkOrderList = [];
      state.saleOrderList = [];
      },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllBanks.fulfilled, (state, action) => {
        state.bankList = _.orderBy(action.payload.result, ["bankName"], ["asc"]);
      })
      .addCase(GetFunctionLibraryList.fulfilled, (state, action) => {
        state.libraryList = _.orderBy(action.payload.result, ["functionLibraryName"], ["asc"]);
      })
      .addCase(GetAllSensorControlDeviceFunction.fulfilled, (state, action) => {
        state.functionList = _.orderBy(action.payload.result, ["deviceFunctionName"], ["asc"]);
      })
      .addCase(GetWorkOrderList.fulfilled, (state, action) => {
        state.workOrderList = _.orderBy(action.payload.result, ["workOrderNo"], ["desc"]);
      })
      .addCase(GetEquipmentList.fulfilled, (state, action) => {
        state.equipmentList = _.orderBy(action.payload.result, ["equipmentRefNo", "equipmentName",], ["asc", "asc"]);
      })
      .addCase(GetAllLotIdLookup.fulfilled, (state, action) => {
        state.lotIdList = action.payload.result.sort();
      })
      .addCase(GetProductList.fulfilled, (state, action) => {
        state.productList = _.orderBy(action.payload.result, ["productName"], ["asc"]);
      })
      .addCase(GetProductListByWorkOrderId.fulfilled, (state, action) => {
        state.productList = _.orderBy(action.payload.result, ["productName"], ["asc"]);
      })
      .addCase(GetProductGrowthProcessList.fulfilled, (state, action) => {
        state.processList = action.payload.result;
      })
      .addCase(GetProductTaskList.fulfilled, (state, action) => {
        state.taskList = action.payload.result;
      })
      .addCase(GetUserList.fulfilled, (state, action) => {
        state.userList = _.orderBy(action.payload.result, ["userName"], ["asc"]);
      })
      .addCase(GetAllUserUOMs.fulfilled, (state, action) => {
        state.uomList = action.payload.result;
      })
      .addCase(GetNotYetStartedWorkOrderList.fulfilled, (state, action) => {
        state.unstartedWorkOrderList = _.orderBy(action.payload.result, ["workOrderNo"], ["asc"]);
      })
      .addCase(GetAllRawMaterialInfo.fulfilled, (state, action) => {
        state.allRawMaterialInfo = _.orderBy(action.payload.result, ["rawMaterialName"], ["asc"]);
      })
      .addCase(GetRawMaterialList.fulfilled, (state, action) => {
        state.rawMaterialList = _.orderBy(action.payload.result, ["rawMaterialName"], ["asc"]);
      })
      .addCase(GetRawMaterialBatchList.fulfilled, (state, action) => {
        state.batchList = _.orderBy(action.payload.result, ["batchRefNo"], ["asc"]);
      })
      .addCase(GetAllSupplierInfo.fulfilled, (state, action) => {
        state.supplierList = _.orderBy(action.payload.result, ["supplierName"], ["asc"]);
      })
      .addCase(GetAllWarehouseInfo.fulfilled, (state, action) => {
        state.warehouseList = _.orderBy(action.payload.result, ["warehouseName"], ["asc"]);
      })
      .addCase(GetAllRawMaterialInfoForMapping.fulfilled, (state, action) => {
        state.allRawMaterialInfoForMapping = _.orderBy(action.payload.result, ["rawMaterialName"], ["asc"]);
      })
  },
});

// export actions
export const { setValues, resetValues, reset } = common.actions;

// export the reducer
export default common.reducer;