import { createSlice } from "@reduxjs/toolkit";

import { GetAllEquipmentStatus, GetAllEquipmentMaintenanceHistory } from "services/UserPortal/EquipmentService";

// initial state
const initialState = {
  filter: {},
  totalStatusCount: 0,
  status: [],
  totalScheduleCount: 0,
  schedules: [],
  maintenance: {},
};

const maintenance = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.maintenance[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllEquipmentStatus.fulfilled, (state, action) => {
        state.totalStatusCount = action.payload.result.totalCount;
        state.currentDateTime = action.payload.result.currentDateTime;
        state.status = action.payload.result.items;
      })
      .addCase(GetAllEquipmentMaintenanceHistory.fulfilled, (state, action) => {
        state.totalScheduleCount = action.payload.result.totalCount;
        state.currentDateTime = action.payload.result.currentDateTime;
        state.schedules = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = maintenance.actions;

// export the reducer
export default maintenance.reducer;