import { createSlice } from "@reduxjs/toolkit";

import { ExecuteGetAllRobots, GetUpcomingMissions, ExecuteGetMapByName } from "services/UserPortal/AMRService";

// initial state
const initialState = {
  filter: {},
  result: [],
  upcoming: [],
  nodeList: [],
  pathList: [],
};

const amrOverview = createSlice({
  name: "amrOverview",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.batch[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(ExecuteGetAllRobots.fulfilled, (state, action) => {
        state.result = action.payload.result;
      })
      .addCase(GetUpcomingMissions.fulfilled, (state, action) => {
        state.upcoming = action.payload.result;
      })
      .addCase(ExecuteGetMapByName.fulfilled, (state, action) => {
        state.nodeList = action.payload.result.nodes;
        state.pathList = action.payload.result.edges;
      })
  },
});

// export actions
export const { setValues, updateRequest, updateExcessOrderRequest, reset } = amrOverview.actions;

// export the reducer
export default amrOverview.reducer;