import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @mui/material
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";

import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, filterActionTableHead } from 'common/functions';
import { setDirty } from "store/general";
import { setValues, updateRequest } from "../store/adjustment";
import { adjustmentTableHead } from "enums/UserPortal/TableHeaders";
import { GetSubSaleOrdersByWorkOrderIdForAdjustment, GetSimilarSubSaleOrdersForAdjustment } from "services/UserPortal/PlanningService";

import styles from "assets/jss/components/UserPortal/planning.module.scss";

import { useRouteCanWrite } from "hooks";

export default function Similar(props) {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => props.current ? store.user.planning.adjustment.adjustmentsCount : store.user.planning.adjustment.similarCount);
  const result = useSelector(store => props.current ? store.user.planning.adjustment.adjustments : store.user.planning.adjustment.similar);
  const adjust = useSelector(store => store.user.planning.adjustment.adjust);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('purchaseOrderNumber');
  const [page, setPage] = React.useState(0);

  const count = Math.ceil(totalCount / rowsPerTable);
  
  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleButtonClick_checkbox = (e, item) => {
    dispatch(setDirty(true));
    const key = props.current ? "oldOrder": "newOrder";
    const reset = {newSubSaleOrderNumber: null, newOrder: null}
    if (adjust[e.target.id] === e.target.value) {
      let payload = {[e.target.id]: null, [key]: null};
      if (props.current) payload = {...payload, ...reset}
      dispatch(updateRequest(payload));
    } else {
      let payload = {[e.target.id]: e.target.value, [key]: item};
      if (props.current) payload = {...payload, ...reset}
      dispatch(updateRequest(payload));
    }
  };

  const checkWeight = (item, compare) => {
    const w1 = Number(item.uom == "kg" ? item.customerOrderWeight*1000 : item.customerOrderWeight); // convert to gram
    const w2 = Number(compare.uom == "kg" ? compare.customerOrderWeight*1000 : compare.customerOrderWeight);
    // return (props.current ? w2 > w1 : w2 <= w1);
    return w2 <= w1;
  }

  const isDisabled = (item) => {
    // const compare = props.current ? adjust.newOrder : adjust.oldOrder;
    const compare = adjust.oldOrder;
    if (compare) {
      return (!moment(item.deliveryDate).isSame(moment(compare.deliveryDate)) 
      || item.product_Id !== compare.product_Id
      || checkWeight(item, compare));
    } return true;
  }

  const getTableDetails = (pageNo, stopLoading) => {
    if (props.filter.workOrderId) {
      if (props.current) {
        dispatch(GetSubSaleOrdersByWorkOrderIdForAdjustment({...props.filter, page: pageNo, stopLoading}));
      } else {
        if (adjust.oldOrder) {
          dispatch(GetSimilarSubSaleOrdersForAdjustment({...props.filter, product_Id: adjust.oldOrder.product_Id, page: pageNo}));
        }
      }
    } else {
      dispatch(setValues({adjustmentsCount: 0, adjustments: [], similarCount: 0, similar: []}))
    }
  }

  React.useEffect(() => {
    setPage(0);
  },[props.filter]);

  React.useEffect(() => {
    getTableDetails(page, true);
  },[page]);

  useInterval(() => {
    getTableDetails(page, true);
  });

  return (
    <React.Fragment>
      {props.current ? <Typography>Current Orders in Work Order:</Typography> : <Typography>Other Orders for Adjustment:</Typography>}
      <Paper className={styles.adjustPaper} elevation={0}>
        <Table 
          className={styles.table}
          header={filterActionTableHead(adjustmentTableHead)}
          // order={order}
          // orderBy={orderBy}
          // onRequestSort={handleRequestSort}
        >
          {!props.current && totalCount === 0 
          ? <TableRow><TableCell colSpan={7}>{adjust.oldOrder ? "There are no orders for consideration to be exchanged." : "Please select a order for adjustment"}</TableCell></TableRow>
          : result.map((item,index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.purchaseOrderNumber}</TableCell>
                <TableCell>{item.subSaleOrderNumber}</TableCell>
                <TableCell>{item.productName}</TableCell>
                <TableCell>{item.productId}</TableCell>
                <TableCell>{formatNumbers(roundTo2Decimal(item.customerOrderWeight))}{item.uom}</TableCell>
                <TableCell>{moment(item.deliveryDate).isAfter(minDate) ? moment(item.deliveryDate).format("DD/MM/yyyy") : ""}</TableCell>
                {canWrite && 
                  <TableCell>
                    <Checkbox 
                      id={props.current?"oldSubSaleOrderNumber":"newSubSaleOrderNumber"}
                      value={item.subSaleOrderNumber}
                      disabled={!props.current && isDisabled(item)}
                      checked={props.current?adjust.oldSubSaleOrderNumber===item.subSaleOrderNumber:adjust.newSubSaleOrderNumber===item.subSaleOrderNumber}
                      onChange={(e) => handleButtonClick_checkbox(e, item)}
                    />
                  </TableCell>
                }
              </TableRow>
            )
          })}
        </Table>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Paper>
    </React.Fragment>
  );
}
