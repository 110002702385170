// timeline
export const fixedProcessList = [
  {processName: "Seeding", code: "seeding"},
  {processName: "Germination", code: "germination"},
  {processName: "Harvesting", code: "harvesting"},
  {processName: "Packaging", code: "packaging"},
  {processName: "Cutting & Washing", code: "cutting"},
  {processName: "Transfer + Growth", code: "transferGrowth"},
  {processName: "Transfer", code: "transfer"},
  {processName: "Growth", code: "growth"},
  {processName: "Nursery", code: "nursery"},
]

// farm config
export const farmConfig = {
  bank: "Bank",
  banks: "Banks",
  bay: "Bay",
  bays: "Bays",
  level: "Level",
  levels: "Levels",
  cage: "Cage",
  cages: "Cages",
}

// operations
export const operationTaskStatus = {
  pending: "Pending",
  paused: "Pause",
  inProgress: "InProgress",
  completed: "Complete",
}

export const taskDetailStatus = {
  pending: "NotStarted",
  paused: "Pause",
  inProgress: "InProgress",
  completed: "Complete",
}

export const completeStatus = {
  inProgress: "InProgress",
  completed: "Complete",
}

export const assignmentStatus = {
  unassigned: "FullyUnassigned",
  partial: "Partially",
  completed: "FullyAssigned",
}

// delivery
export const deliveryStatus = {
  pending: "Pending",
  inProgress: "In Progress",
  completed: "Completed",
}

export const tripStatus = {
  started: "Started",
  paused: "Pause",
  delivered: "Delivered",
}

// AMR
export const amrStatus = {
  5: "Offline",
}

export const amrMissionStatus = {
  0: "Pending",
  1: "Running",
  5: "Finished",
  6: "Cancelled",
  7: "Error",
}
