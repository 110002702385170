import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  email: null,
  token: null,
  password: null,
  confirmPassword: null
};

// create reducer and action creators
const resetPassword = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
});

// export actions
export const { updateRequest, reset } = resetPassword.actions;

// export the reducer
export default resetPassword.reducer;