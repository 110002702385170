import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetDeliveryTripSetting, GetIntervalTime } from "services/UserPortal/DeliveryService";

// initial state
const initialState = {
  settings: {},
  intervals: [],
  initialMarker: {
    longitude: "103.78930650819217",
    latitude: "1.3757771917613002"
  },
};

const deliverySettings = createSlice({
  name: "deliverySettings",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.settings[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetDeliveryTripSetting.fulfilled, (state, action) => {
        state.settings = action.payload.result;
        state.initialMarker = _.pick(action.payload.result, ["longitude", "latitude"]);
      })
      .addCase(GetIntervalTime.fulfilled, (state, action) => {
        state.intervals = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = deliverySettings.actions;

// export the reducer
export default deliverySettings.reducer;