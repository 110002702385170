import React from "react";
import clsx from "clsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// core components
import styles from "assets/jss/shared-components/tab.module.scss";

export default function CustomTabs({tabs, disabled, ...props}) {

  return (
    <Tabs 
      {...props}
      className={clsx(props.className, styles.tab)}
      classes={{
        indicator: styles.indicator
      }}
    >
      {tabs.map((item, index) => {
        return <Tab key={index} className={styles["tabLabel"+(props.value===index?"Selected":"")]} label={item} disabled={disabled} />
      })}
    </Tabs>
  );
}

CustomTabs.propTypes = {
  tabs: PropTypes.array,
  disabled: PropTypes.bool,
};