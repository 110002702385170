
import { find,omit } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { rowsPerTable } from "config";
import { FomsMS } from "../index";

// Device 
export const GetAllDevice = createAsyncThunk(
  "device/getAllDevice", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      MaxResultCount: 9999
    }
    try {
      const response = await FomsMS.get("/Device/GetAllDevice", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDevice = createAsyncThunk(
  "device/getDevice", 
  async (id, {rejectWithValue}) => {
    const params = {id}
    try {
      const response = await FomsMS.get("/Device/GetDevice", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProposedDeviceRefNo = createAsyncThunk(
  "device/getProposedDeviceRefNo", 
  async (_, {getState, rejectWithValue}) => {
    const device = getState().admin.device.device.device;
    const body = {
      deviceTypeId: device.deviceTypeId,
      deviceFunctionList: device.deviceFunctions
    }
    try {
      const response = await FomsMS.post("/Device/GetProposedDeviceRefNo", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateDevice = createAsyncThunk(
  "device/createOrUpdateDevice", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = Object.assign({}, getState().admin.device.device.device);
    Object.assign(body, {farmId: find(userDetail.farms, "isActive").id});
    if (body.manufacturerName === "new") {
      Object.assign(body, {manufacturerName: body.newManufacturerName});
      body = omit(body, ["newManufacturerName"]);
    }
    try {
      const response = await FomsMS.post("/Device/CreateOrUpdateDevice", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateLightingDeviceSetting = createAsyncThunk(
  "device/updateLightingDeviceSetting", 
  async (param, {rejectWithValue}) => {
    const body = {...param};
    try {
      const response = await FomsMS.post("/Device/UpdateLightingDeviceSetting", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteDevice = createAsyncThunk(
  "device/deleteDevice", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/Device/DeleteDevice", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeviceHierarchy = createAsyncThunk(
  "device/getDeviceHierarchy", 
  async (id, {rejectWithValue}) => {
    const params = {deviceId: id}
    try {
      const response = await FomsMS.get("/Device/GetDeviceHierarchy", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search 
export const SearchDevice = createAsyncThunk(
  "device/searchDevice", 
  async (Keyword, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword,
      MaxResultCount: 9999
    }
    try {
      const response = await FomsMS.get("/Device/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Step 2

// Step 3
export const DeviceTesting = createAsyncThunk(
  "device/deviceTesting", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/Device/DeviceTesting");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Step 4
export const GetDeviceListForHierarchy = createAsyncThunk(
  "device/getDeviceListForHierarchy", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Device/GetDeviceListForHierarchy", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Device Type
export const GetAllDeviceType = createAsyncThunk(
  "device/getAllDeviceType", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: param ? param.page * rowsPerTable : 0,
      MaxResultCount: param ? rowsPerTable : 99
    }
    try {
      const response = await FomsMS.get("/Device/GetAllDeviceType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetDeviceType = createAsyncThunk(
  "device/getDeviceType", 
  async (Id, {rejectWithValue}) => {
    const params = {Id};
    try {
      const response = await FomsMS.get("/Device/GetDeviceType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateDeviceType = createAsyncThunk(
  "device/createOrUpdateDeviceType", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let deviceType = getState().admin.device.type.deviceType;
    const body = {
      ...deviceType,
      deviceTypeName: deviceType.deviceTypeName.trim(),
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/Device/CreateOrUpdateDeviceType", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteDeviceType = createAsyncThunk(
  "device/deleteDeviceType", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/Device/DeleteDeviceType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Function Library
export const GetAllFunctionLibrary = createAsyncThunk(
  "device/getAllFunctionLibrary", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      MaxResultCount: 99
    }
    try {
      const response = await FomsMS.get("/Device/GetAllFunctionLibrary", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetFunctionLibrary = createAsyncThunk(
  "device/getFunctionLibrary", 
  async (id, {rejectWithValue}) => {
    const params = {functionLibraryId : id}
    try {
      const response = await FomsMS.get("/Device/GetFunctionLibrary", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateFunctionLibrary = createAsyncThunk(
  "device/createOrUpdateFunctionLibrary", 
  async (lib, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...lib, 
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/Device/CreateOrUpdateFunctionLibrary", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Batch Update
export const DownloadBatchTemplate = createAsyncThunk(
  "device/downloadBatchTemplate", 
  async (functionLibraryId, {rejectWithValue}) => {
    const config = {
      responseType: 'blob',
      params: {
        functionLibraryId
      },
      stopLoading: true
    }
    try {
      const response = await FomsMS.get("/Device/DownloadBatchTemplate", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadBatchDevice = createAsyncThunk(
  "device/uploadBatchDevice", 
  async (file, {rejectWithValue}) => {
    try {
      let body = new FormData();
      body.append("File", file);
      const userDetail = JSON.parse(localStorage.getItem("userDetail"));
      let params = {
        FarmId: find(userDetail.farms, "isActive").id
      }
      const response = await FomsMS.post("/Device/UploadBatchDevice", body, {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateBatchDevice = createAsyncThunk(
  "device/createBatchDevice", 
  async (_, {getState, rejectWithValue}) => {
    try {
      const body = getState().admin.device.device.batchDevices;
      const response = await FomsMS.post("/Device/CreateBatchDevice", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);