import UserProfile from "components/UserSettings/UserProfile";
import FarmSettings from "components/UserSettings/FarmSettings";
import About from "components/UserSettings/About";

export const settingsRoutes = [
  {
    path: "/user-profile",
    name: "User Profile",
    header: "User Profile",
    component: UserProfile,
    layout: "/settings",
  },
  {
    path: "/farm-settings",
    name: "Farm Settings",
    header: "Farm Settings",
    component: FarmSettings,
    layout: "/settings",
  },
  {
    path: "/about",
    name: "About",
    header: "About",
    component: About,
    layout: "/settings",
  },
];