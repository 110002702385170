// File with all commonly used validations

export const isEmail = (value) => {
  const regex = /^\s*[\w\-+]+(\.[\w\-+]+)*@[\w\-+]+\.[\w\-+]+(\.[\w\-+]+)*\s*/;
  return regex.test(value);
}

export const isPhoneNumber = (value) => {
  const regex = /^[+\d]?(?:[\d-.\s()]*)$/;
  return (regex.test(value));
}

export const isValidPassword = (value) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[._@$!%*?&])[A-Za-z\d.,_@$!%*?&]{8,}$/;
  return (regex.test(value));
}

export const isPositive = (value) => {
  const regex = /^(0|([1-9]\d*))$/;
  return (regex.test(value));
}

export const isPositiveDecimal = (value) => {
  const regex = /^\d+(\.\d)?\d*$/;
  return (regex.test(value));
}

export const isValidPrice = (value) => {
  const regex = /^\d+(\.\d{1,2})?$/;
  return (regex.test(value));
}

export const isUnique = (array, key) => {
  let tmpArr = [];
  for(const index in array) {
    if(tmpArr.indexOf(array[index][key].toString()) < 0){ 
      tmpArr.push(array[index][key].toString());
    } else {
      return false;
    }
  }
  return true;
}

export const isValidDeviceID = (value) => {
  const regex = /^[A-Z]{1,3}-[A-Z]{1,5}-\d{5}$/;
  return (regex.test(value));
}

export const isShortform = (value) => {
  const regex = /^[A-Z]{1,3}$/;
  return (regex.test(value));
}