import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @mui/material
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import Modal from 'shared-components/Modal/Modal';
import Select from "shared-components/Select/Select";

import EquipmentOverview from "./EquipmentOverview";
import LocationView from "./LocationView";
import BankLocationView from "./BankLocationView";
import { farmConfig } from "enums/Constants";
import { setSearchText } from "store/general";
import { renderValue } from "common/functions";

import { GetEquipmentOverview } from "services/UserPortal/MonitoringService";

import filterIcon from "assets/icons/orange/filter.svg";
import styles from "assets/jss/components/UserPortal/monitoring.module.scss";

export default function Equipment(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const equipmentOverview = useSelector(store => store.user.monitoring.equipment.equipmentOverview.equipmentSummaryInfoDtos);
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);
  const [openFilterModal, setOpenFilterModal] = React.useState(false);
  const [newFilter, setNewFilter] = React.useState({equipmentName: null});

  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "equipment");

  const handleButtonClick_back = () => {
    history.push("/user/monitoring/equipment");
  }
  
  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleModal_filter = () => {
    setOpenFilterModal(!openFilterModal);
    setNewFilter({equipmentName: path[index+1].replace("%20", " ")});
  };

  const handleButtonClick_filter = () => {
    setOpenFilterModal(false);
    history.push("/user/monitoring/equipment/"+newFilter.equipmentName);
  };

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetEquipmentOverview());
    setNewFilter({equipmentName: path[index+1].replace("%20", " ")});
    // componentDidUnmount
    return () => {
    }
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <EquipmentOverview />
      case 1:
        return <LocationView />
      case 2:
        return <BankLocationView />
    } 
  }

  return (
    <React.Fragment>
      <Card 
        title={path[index+1].replace("%20", " ")+" Monitoring"}
        subheader={
          <React.Fragment>
            {tab !== 0 &&
            "Select the filters to view equipments locations"
            }
            <Tabs 
              tabs={["Overview of Equipment", "Floor/Sector Location View", farmConfig.bank+" Location View"]}
              value={tab} 
              onChange={(e,value) => setTab(value)}
            />
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <Button 
              className={styles.buttonSecondary}
              onClick={()=>handleModal_filter()}
              endIcon={<img className={styles.icon} src={filterIcon} alt="filter" />}
            >
              Filter
            </Button>
          </React.Fragment>
        }
        cardActions={
          <Button
            className={styles.buttonSecondary}
            onClick={()=>handleButtonClick_back()}
          >
            Back
          </Button>
        }
      >
        {renderComponent()}
      </Card>
      <Modal
        open={openFilterModal}
        onClose={() => handleModal_filter()}
        title="Search Filter"
        content={
          <React.Fragment>
            Choose one or all filters to monitor
            <Select
              className={styles.filterDropdown}
              name="equipmentName"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a equipment type"
              value={renderValue(newFilter.equipmentName)}
            >
              {equipmentOverview && equipmentOverview.map((item, index) => {
                return <MenuItem key={index} value={item.equipmentName}>{item.equipmentName}</MenuItem>;
              })} 
            </Select>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_filter()}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_filter()}>Apply Filter</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
