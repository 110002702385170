import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const SearchAdminLog = createAsyncThunk(
  "administrationLog/searchAdminLog", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      StartDate: param.startDate,
      EndDate: param.endDate,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/AdministrationLog/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExportAdminLog = createAsyncThunk(
  "administrationLog/exportAdminLog", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const config = {
      responseType: 'blob',
      params: {
        FarmId: find(userDetail.farms, "isActive").id,
        Keyword: param.keyword,
        StartDate: param.startDate,
        EndDate: param.endDate,
        MaxResultCount: 9999
      },
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/AdministrationLog/Export", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);