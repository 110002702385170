/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import Summary from "./Summary";
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import warehouse from "assets/icons/orange/warehouse.svg";
import enter from "assets/icons/orange/enter.svg";
import light from "assets/icons/black/light.svg";
import fan from "assets/icons/black/fan.svg";
import temperature from "assets/icons/black/temperature.svg";
import water from "assets/icons/black/water.svg";
import styles from "assets/jss/components/UserPortal/monitoring.module.scss";

export default function WarehouseOverview(props) {
  const detail = useSelector(store => store.user.monitoring.storage.detail);

  return (
    <React.Fragment>
      <div className={styles.cardContainer}>
        <Paper className={styles.envPaper}>
          <div className={styles.equipTitle}>
            <Typography className={styles.envValueBold}>{detail.warehouseName}</Typography>
            <img className={clsx(styles.icon_38, styles.equipIcon)} src={warehouse} alt="warehouse" />
          </div>
          <Typography>Inventory: {formatNumbers(roundTo2Decimal(detail.capacityUsed))}%</Typography>
          <Typography>Capacity: {detail.capacity} {detail.uom}</Typography>
          <Typography>Status: {detail.status}</Typography>
        </Paper>
        <Summary/>
      </div>
      <div className={styles.cardContainer}>
      <Paper className={styles.envPaper}>
          <img className={clsx(styles.icon_38, styles.iconMargin)} src={water} alt="water" />
          <Typography>Humidity</Typography>
          <Typography className={styles.envValue}>{detail.humidityValue ?? 0}% RH<img className={clsx(styles.icon_24, styles.enterButton)} src={enter} alt="enter" /></Typography>
        </Paper>
        <Paper className={styles.envPaper}>
          <img className={clsx(styles.icon_38, styles.iconMargin)} src={temperature} alt="temperature" />
          <Typography>Temperature</Typography>
          <Typography className={styles.envValue}>{detail.temperatureValue ?? 0}% °C<img className={clsx(styles.icon_24, styles.enterButton)} src={enter} alt="enter" /></Typography>
        </Paper>
        <Paper className={styles.envPaper}>
          <img className={clsx(styles.icon_38, styles.iconMargin)} src={light} alt="light" />
          <Typography>LED Lights</Typography>
          <Typography className={styles.envValue}>{detail.ledControllerValue ?? 0}% LUX<img className={clsx(styles.icon_24, styles.enterButton)} src={enter} alt="enter" /></Typography>
        </Paper>
        <Paper className={styles.envPaper}>
          <img className={clsx(styles.icon_38, styles.iconMargin)} src={fan} alt="fan" />
          <Typography>Ventilation Fans</Typography>
          <Typography className={styles.envValue}>{detail.ventilationFanValue ?? "Unknown"}<img className={clsx(styles.icon_24, styles.enterButton)} src={enter} alt="enter" /></Typography>
        </Paper>
      </div>
    </React.Fragment>
  );
}
