// Batch Upload RFID
// Edit Batch Upload
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import Select from "shared-components/Select/Select";
import IconButton from "shared-components/Button/IconButton";

import { rowsPerTable } from "config";
import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { GetAllDevices, GetAllRFIDGroup, CreateBatchRFID } from "services/AdminPortal/RFIDService";
import { rfidTableHead } from "enums/AdminPortal/TableHeaders";
import { setBatchValues, updateBatchRequest, updateBatchesRequest, deleteBatchRequest, resetBatch, reset } from ".";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/rfid.module.scss";

export default function UploadedData() {
  let history = useHistory();
  const dispatch = useDispatch();
  const batchRFIDs = useSelector(store => store.admin.rfid.batchRFIDs);
  const batchRFID = useSelector(store => store.admin.rfid.batchRFID);
  const devices = useSelector(store => store.admin.rfid.devices);
  const groupings = useSelector(store => store.admin.rfid.groupings);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('serialNo');
  const [page, setPage] = React.useState(0);
  const [editRfid, setEditRfid] = React.useState(false);
  const [rfidIndex, setRfidIndex] = React.useState(null);
  const [errorMode, setErrorMode] = React.useState(null);
  const [errorFields, setErrorFields] = React.useState([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(batchRFIDs.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_text = (e) => {
    dispatch(updateBatchRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateBatchRequest({[e.target.name] : e.target.value}));
  };

  const handleButtonClick_edit = (lineNo) => {
    setRfidIndex(lineNo);
    setEditRfid(true);
    dispatch(setBatchValues(_.find(batchRFIDs, (item) => item.lineNo === lineNo)));
  }

  const handleModal_delete = (lineNo) => {
    setRfidIndex(lineNo);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(deleteBatchRequest(rfidIndex));
    setOpenDeleteModal(!openDeleteModal);
    resetState();
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(updateBatchesRequest(rfidIndex));
      resetState();
    }
  }

  const handleButtonClick_save = () => {
    if (validateMissingFields().length > 0) {
      handleModal_warning();
    } else if (validateInvalidFields().length > 0) {
      handleModal_warning();
    } else {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateBatchRFID())
        .then((response)=>{
          if (response.error && response.payload.message !== "An internal error occurred during your request!") {
            dispatch(setDirty(true));
            handleModal_warning();
            setErrorFields([response.payload.message]);
            setErrorMode("Invalid");
          } else {
            history.push("/admin/rfid");
          }
        });
      })
    }
  }

  const validateMissingFields = () => {
    let errors = [];
    for (let i = 0; i < batchRFIDs.length; i++) {
      if (!batchRFIDs[i].deviceRefNo) {
        errors = _.union(errors, ["Device ID"]);
      }
      if (!batchRFIDs[i].rfidId) {
        errors = _.union(errors, ["RFID ID"]);
      }
      if (!batchRFIDs[i].rfidGroupName) {
        errors = _.union(errors, ["Grouping"]);
      }
    }
    setErrorFields(errors);
    setErrorMode("Missing");
    return errors;
  }

  const validateInvalidFields = () => {
    let errors = [];
    for (let i = 0; i < batchRFIDs.length; i++) {
      if (batchRFIDs[i].deviceRefNo && _.findIndex(devices, ["deviceRefNo", batchRFIDs[i].deviceRefNo]) === -1) {
        errors = _.union(errors, ["Device ID"]);
      }
      if (batchRFIDs[i].rfidGroupName && _.findIndex(groupings, ["groupName", batchRFIDs[i].rfidGroupName]) === -1) {
        errors = _.union(errors, ["Grouping"]);
      }
    }
    setErrorFields(errors);
    setErrorMode("Invalid");
    return errors;
  }

  const validateFields = () => {
    if (!batchRFID.deviceRefNo) {
      setErrorMsg({field: "deviceRefNo", msg: "Please select a device"});
      return false;
    }
    if (!batchRFID.rfidId) {
      setErrorMsg({field: "rfidId", msg: "Please enter a RFID ID"});
      return false;
    }
    if (!batchRFID.rfidGroupName) {
      setErrorMsg({field: "rfidGroupName", msg: "Please select a grouping"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setEditRfid(false);
    setRfidIndex(null);
    setPage(0);
    dispatch(resetBatch());
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetAllDevices());
    dispatch(GetAllRFIDGroup());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  }, []);

  return (
    <React.Fragment>
      <Card 
        className={styles.cardRadius}
        title = "Uploaded Data"
        cardActions={
          <React.Fragment>
            <Button disabled/>
            <Button
              className={styles.button}
              onClick={() => handleButtonClick_save()}
              disabled={editRfid}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Table
          className={styles.table}
          header={rfidTableHead.map((head, index) => {
            if (index === 3) {
              head.label = "Groupings";
            } 
            if (head.id !== "serialNo" && head.id !== "action") {
              return {...head, required: true};
            } else {
              return head;
            }
          })}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        >
          { _.orderBy(batchRFIDs, [orderBy], [order])
          .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
          .map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell className={styles.snCell}>{String((page*rowsPerTable)+1+index).padStart(2,'0')}</TableCell>
                <TableCell>
                  { editRfid && rfidIndex === item.lineNo
                  ? <Select
                      name="deviceRefNo"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={renderValue(batchRFID.deviceRefNo)}
                      placeholder="Select a device"
                      errorMsg={errorMsg}
                    >
                      {devices && devices.map((device) => {
                        return <MenuItem key={device.id} value={device.deviceRefNo}>{device.deviceRefNo}</MenuItem>;
                      })} 
                    </Select>
                  : item.deviceRefNo
                  }
                </TableCell>
                <TableCell>
                  { editRfid && rfidIndex === item.lineNo
                  ? <TextField 
                      id="rfidId"
                      variant="outlined" 
                      inputProps={{ maxLength: 255 }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={renderValue(batchRFID.rfidId)}
                      errorMsg={errorMsg}
                    />
                  : item.rfidId
                  }
                </TableCell>
                <TableCell>
                  { editRfid && rfidIndex === item.lineNo
                  ? <Select
                      name="rfidGroupName"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={renderValue(batchRFID.rfidGroupName)}
                      placeholder="Select a group name"
                      errorMsg={errorMsg}
                    >
                      {groupings && groupings.map((group) => {
                        return <MenuItem key={group.id} value={group.groupName}>{group.groupName}</MenuItem>;
                      })} 
                    </Select>
                  : item.rfidGroupName
                  }
                </TableCell>
                <TableCell align="right">
                  { editRfid && rfidIndex === item.lineNo
                  ? <React.Fragment>
                      <IconButton
                        type="confirm" 
                        onClick={() => handleButtonClick_confirm()}
                      />
                      <IconButton 
                        type="close"
                        onClick={() => resetState()}
                      />
                    </React.Fragment>
                  : <React.Fragment>
                      <IconButton
                        type="edit" 
                        onClick={() => handleButtonClick_edit(item.lineNo)}
                        disabled={(rfidIndex && rfidIndex !== item.lineNo)}
                      />
                      <IconButton
                        type="delete" 
                        onClick={() => handleModal_delete(item.lineNo)}
                        disabled={(rfidIndex && rfidIndex !== item.lineNo)}
                      />
                    </React.Fragment>
                  }
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this user? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning()}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title={errorMode + " Fields"}
        content={
          <React.Fragment>
            <Typography>Please check these fields again:</Typography>
            <Typography className={styles.errorList}>{errorFields.join(", ")}</Typography>
          </React.Fragment>
        }
        actions={
          <Button className={styles.button} onClick={() => handleModal_warning()}>OK</Button>
        }
      />
    </React.Fragment>
  );
}
