/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";

import { renderValue, filterParam } from "common/functions";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { setValues } from "../store/overview";
import { GetProductList } from "services/UserPortal/CommonLookupService";
import { GetOperationProcessProgress } from "services/UserPortal/OperationsService";

import ProgressDetails from "./ProgressDetails";
import CageDetails from "./CageDetails";
import TransplantDetails from "./TransplantDetails";
import HarvestDetails from "./HarvestDetails";
import styles from "assets/jss/components/UserPortal/operations.module.scss";

export default function Progress(props) {
  const dispatch = useDispatch();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const filter = useSelector(store => store.user.operations.overview.filter);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, productId: null});
  const [view, setView] = React.useState("details");
  const [operationTaskName, setOperationTaskName] = React.useState(null);

  const handleOnChange_select = (e) => {
    if (e.target.name == "workOrderId") {
      setNewFilter({...newFilter, [e.target.name]: e.target.value, productId: null});
    } else {
      setNewFilter({...newFilter, [e.target.name]: e.target.value});
    }
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };
  
  const renderComponent = () => {
    switch(view) {
      case "details":
        return <ProgressDetails setView={setView}  />
      case "cage":
        return <CageDetails setView={setView}  />
      case "transplant":
        return <TransplantDetails setView={setView}  />
      case "harvest":
        return <HarvestDetails setView={setView}  />
    }
  };

  const workOrder = workOrderList.find(({id}) => id == filter.workOrderId);
  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        title="Operations Management"
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "workOrderId") {
                const workOrder = workOrderList.find(({id}) => id == filter[key]);
                label = workOrder && workOrder.workOrderNo;
              }
              if (key === "productId") {
                const product = productList && productList.find(({id}) => id == filter[key]);
                label = product && product.productName+" ("+product.productRefNo+")";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={styles.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to display the operation timeline
                  <Select
                    className={styles.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                    disabled={!newFilter.workOrderId}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
