import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// @mui/icons-material
import CloseIcon from '@mui/icons-material/Close';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import { setValues, updateRequest } from "../store/germination";
import { setDirty, setSearchText } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { renderValue, roundTo2Decimal } from "common/functions";
import { GetGerminationReportDetail, CreateOrUpdateGerminationReport, GetProductListByWorkOrderId,
  GetBoardType, GetNutrientCategory, GetSeedSupplierNameByBatchID, GetProductInfoById, GetQCDetailsFromOperation, GetBatchIDList, GetBatchStartDateByBatchID, 
  CalculateNurseryDuration, CalculateGerminationRate, CalculateDatesByWorkOrderIdAndProductId, GetTrayPosition, UploadQCGerminationGoodSeedlingImage } from "services/UserPortal/QualityControlService";

import plus from "assets/icons/white/plus.svg"
import styles from "assets/jss/components/UserPortal/qualityControl.module.scss";

export default function AddEditQualityControlGermination() {
  const dispatch = useDispatch();
  let history = useHistory();
  const inputFile = React.createRef();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.user.qualityControl.germination.productList);
  const qualityControl = useSelector(store => store.user.qualityControl.germination.qualityControl);
  const trayPositionList = useSelector(store => store.user.qualityControl.germination.trayPositionList);
  const batchIDList = useSelector(store => store.user.qualityControl.germination.batchIDList);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "quality-control");

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
    if (e.target.name === "workOrderId") {
      dispatch(GetProductListByWorkOrderId({workOrderId: e.target.value, isGerminationReport: true}));
      dispatch(updateRequest({productId: null, vegetableVariety: null}));
    }
  };
  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };
  
  const handleOnChange_image = (e) => {
    setImageFile(e.target.files[0]);
    dispatch(updateRequest({goodSeedlingPhoto: URL.createObjectURL(e.target.files[0])}));
  }

  const handleButtonClick_deleteImage = () => {
    setImageFile(null);
    dispatch(updateRequest({goodSeedlingPhoto: null}));
  }

  const onButtonClick_image = () => {
    inputFile.current.click();
  }

  const handleOnChange_date = (date) => {
    if(date && date != "Invalid Date") {
      dispatch(updateRequest({["batchOpenDate"] : moment(date).format("YYYY-MM-DD")}));
    } else {
      dispatch(updateRequest({["batchOpenDate"] : date}));
    }
  };

  const handleOnClick_save= () => {
    if (validateFields()) {
      if (imageFile) {
        Promise.all([dispatch(setDirty(false))])
        .then(() => {
          dispatch(UploadQCGerminationGoodSeedlingImage(imageFile))
          .then((response) => {
            if (!response.error) {
              let payload = _.cloneDeep(qualityControl);
              payload.goodSeedlingPhoto = response.payload.result;
              dispatch(setValues({qualityControl: payload}));
              dispatch(CreateOrUpdateGerminationReport(payload))
              .then(({error}) => {
                if (!error) {
                  history.push("/user/quality-control/germination");
                }
              });
            }
          })
        })
      } else {
        Promise.all([dispatch(setDirty(false))])
        .then(() => {
          dispatch(CreateOrUpdateGerminationReport(qualityControl))
          .then(({error}) => {
            if (!error) {
              history.push("/user/quality-control/germination");
            }
          });
        })
      }
    }
  }

  const validateFields = () => {
    if (!qualityControl.workOrderId) {
      setErrorMsg({field: "workOrderId", msg: "Please select a Work Order"});
      return false;
    }
    if (!qualityControl.productId) {
      setErrorMsg({field: "productId", msg: "Please select a product"});
      return false;
    }
    if (!qualityControl.batchID) {
      setErrorMsg({field: "batchID", msg: "Please select a batch ID"});
      return false;
    }
    if (!qualityControl.batchOpenDate) {
      setErrorMsg({field: "batchOpenDate", msg: "Please enter batch open date"});
      return false;
    }
    if (!moment(qualityControl.batchOpenDate).isValid()) {
      setErrorMsg({field: "batchOpenDate", msg: "Invalid Date"});
      return false;
    }
    if (!qualityControl.totalQty) {
      setErrorMsg({field: "totalQty", msg: "Please fill in total quantity"});
      return false;
    }
    if (!qualityControl.goodSeeding) {
      setErrorMsg({field: "goodSeeding", msg: "Please fill in the number of good seeds"});
      return false;
    }
    if (Number(qualityControl.goodSeeding) > Number(qualityControl.totalQty)) {
      setErrorMsg({field: "goodSeeding", msg: "Number cannot exceed total quantity"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }
  
  React.useEffect(() => {
    if (qualityControl.trayPositionId) {
      if (path[index+2] === "create") {
        dispatch(GetQCDetailsFromOperation({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId, trayPositionId: qualityControl.trayPositionId, isGerminationReport: true}));
      }
    }
  },[qualityControl.trayPositionId]);
  
  React.useEffect(() => {
    if (qualityControl.productId) {
      dispatch(GetProductInfoById(qualityControl.productId));
      dispatch(GetBatchIDList({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}));
      dispatch(GetTrayPosition({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId, isGerminationReport: true}));
      if (path[index+2] === "create") {
        dispatch(CalculateDatesByWorkOrderIdAndProductId({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}))
        .then(({payload}) => {
          dispatch(CalculateNurseryDuration({transplantDate: moment(payload.result.transplantDate).format("DD MMMM YYYY"), germinationDate: moment(payload.result.germinateDate).format("DD MMMM YYYY")}));
        });
      }
    }
  },[qualityControl.productId]);

  React.useEffect(() => {
    if (qualityControl.batchID) {
      dispatch(GetBatchStartDateByBatchID({batchID: qualityControl.batchID}));
      dispatch(GetSeedSupplierNameByBatchID({batchID: qualityControl.batchID}));
    }
  },[qualityControl.batchID]);

  React.useEffect(() => {
    if (qualityControl.totalQty && qualityControl.goodSeeding) {
      dispatch(CalculateGerminationRate({totalQty: qualityControl.totalQty, goodSeeding: qualityControl.goodSeeding}));
    }
  },[qualityControl.totalQty, qualityControl.goodSeeding]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "quality-control");
    dispatch(GetBoardType());
    dispatch(GetNutrientCategory());
    if (path[index+2] !== "create") {
      dispatch(GetGerminationReportDetail({id: path[index+2]}))
      .then(({error}) => {
        if (error) {
          history.push("/user/quality-control/germination");
        } else {
          dispatch(setDirty(true))
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    // componentDidUnmount
    return () => {
      dispatch(setValues({qualityControl: {}}));
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = {(path[index+2] !== "create" ? "Edit" : "Add New") + " Production Details - Quality Control Entries"}
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.push("/user/quality-control/germination")}
            >
              Go Back
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleOnClick_save()}
              disabled={!qualityControl.workOrderId}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={styles.formTitle}>Select Work Order to Work On</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Work Order:</Typography>
            <Select
              name="workOrderId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a work order"
              value={renderValue(qualityControl.workOrderId)}
              errorMsg={errorMsg}
              disabled={path[index+2] !== "create"}
            >
              {workOrderList && workOrderList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Vegetable Name:</Typography>
            {path[index+2] === "create"
            ? <Select
                name="productId"
                onChange={(e)=>handleOnChange_select(e)}
                placeholder="Select a vegetable name"
                value={renderValue(qualityControl.productId)}
                errorMsg={errorMsg}
                disabled={!qualityControl.workOrderId}
              >
                {productList && productList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.productName}</MenuItem>;
                })}
              </Select>
            : <TextField 
                id="productId"
                variant="outlined" 
                value={renderValue(qualityControl.productName)}
                disabled
              />
            }
          </Grid>
          <Grid item xs={12}>
            <Typography className={styles.formTitle}>General Information</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Serial No:</Typography>
            <TextField 
              id="germinationSerialNo"
              variant="outlined"
              placeholder="Auto Generated after saving"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.germinationSerialNo)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Vegetable Variety:</Typography>
            <TextField 
              id="vegetableVariety"
              variant="outlined" 
              value={renderValue(qualityControl.vegetableVariety)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Seed Supplier:</Typography>
            <TextField
              id="supplierName"
              variant="outlined"
              value={renderValue(qualityControl.supplierName)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Batch No:</Typography>
            <Select
              name="batchID"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a batch ID"
              value={renderValue(qualityControl.batchID)}
              errorMsg={errorMsg}
              disabled={!qualityControl.productId}
            >
              {batchIDList && batchIDList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Tray Position:</Typography>
            <Select
              name="trayPositionId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a tray position"
              value={renderValue(qualityControl.trayPositionId)}
              disabled={!qualityControl.productId}
            >
              {trayPositionList && trayPositionList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.trayPosition}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Batch Start Date:</Typography>
            <TextField
              id="batchStartDate"
              variant="outlined" 
              value={qualityControl.batchStartDate ? moment(qualityControl.batchStartDate).format("DD/MM/YYYY") : ""}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Batch Open Date:</Typography>
            <DateTimePicker
              name="batchOpenDate"
              value={qualityControl.batchOpenDate}
              onChange={handleOnChange_date}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={12}>
            <Typography className={styles.formTitle}>Dates</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Germination Date:</Typography>
            <TextField
              id="germinateDate"
              variant="outlined" 
              value={qualityControl.germinateDate ? moment(qualityControl.germinateDate).format("DD/MM/YYYY") : ""}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Transplant Date:</Typography>
            <TextField
              id="transplantDate"
              variant="outlined" 
              value={qualityControl.transplantDate ? moment(qualityControl.transplantDate).format("DD/MM/YYYY") : ""}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Nursery Duration:</Typography>
            <TextField 
              id="nurseryDuration"
              variant="outlined"
              value={qualityControl.nurseryDuration}
              disabled
            />
          </Grid>
          <Grid item xs={8}>
            <Typography className={styles.formTitle}>Germination Rate</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={styles.formTitle}>Environment Conditions</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Total Quantity:</Typography>
            <TextField 
              id="totalQty"
              variant="outlined" 
              type = "number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
              }}
              inputProps={{ maxLength: 50 }}
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.totalQty)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Seedling Image:</Typography>
            <div className={styles.hcImageDiv}>
              <input 
                ref={inputFile} 
                style={{"display": "none"}} 
                type="file" 
                accept="image/*"
                onChange={(e) => handleOnChange_image(e, 0)}
                onClick={event => event.target.value = null}
              />
              <div className={styles.hcImageContainer}>
                {(qualityControl.goodSeedlingPhoto) && <Button className={styles.deleteImage} onClick={()=>handleButtonClick_deleteImage()}><CloseIcon /></Button>}
                <img src={(qualityControl.goodSeedlingPhoto) ? qualityControl.goodSeedlingPhoto : plus} className={styles.hcImage} onClick={()=>onButtonClick_image("Smallest")} />
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel2}>Water Level (cm):</Typography>
            <div>
              <Typography className={styles.formLabel3}>Initial</Typography>
              <TextField 
                className={clsx(styles.textFieldMargin, styles.textFieldSmall)}
                id="initialWaterLevel"
                variant="outlined" 
                type="Number"
                onInput={(e)=>{ 
                  // decimal
                  const reg = /^\d*(\.\d{0,2})?$/
                  if (!reg.test(e.target.value)) {
                    e.target.value = roundTo2Decimal(e.target.value);
                  }
                }}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(qualityControl.initialWaterLevel)}
              />
            </div>
            <div>
              <Typography className={styles.formLabel3}>Final</Typography>
              <TextField 
                className={clsx(styles.textFieldMargin, styles.textFieldSmall)}
                id="finalWaterLevel"
                variant="outlined" 
                type="Number"
                onInput={(e)=>{ 
                  // decimal
                  const reg = /^\d*(\.\d{0,2})?$/
                  if (!reg.test(e.target.value)) {
                    e.target.value = roundTo2Decimal(e.target.value);
                  }
                }}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(qualityControl.finalWaterLevel)}
              />
            </div>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Empty Sponge:</Typography>
            <TextField 
              id="emptySponge"
              variant="outlined" 
              type = "number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
              }}
              inputProps={{ maxLength: 50 }}
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.emptySponge)}
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Good Seedling:</Typography>
            <TextField 
              id="goodSeeding"
              variant="outlined" 
              type = "number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
              }}
              inputProps={{ maxLength: 50 }}
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.goodSeeding)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel2}>EC (mS/cm):</Typography>
            <TextField 
              className={clsx(styles.textFieldMargin, styles.textFieldSmall)}
              id="initialEC"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                // decimal
                const reg = /^\d*(\.\d{0,2})?$/
                if (!reg.test(e.target.value)) {
                  e.target.value = roundTo2Decimal(e.target.value);
                }
              }}
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.initialEC)}
            />
            <TextField 
              className={clsx(styles.textFieldMargin, styles.textFieldSmall)}
              id="finalEC"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                // decimal
                const reg = /^\d*(\.\d{0,2})?$/
                if (!reg.test(e.target.value)) {
                  e.target.value = roundTo2Decimal(e.target.value);
                }
              }}
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.finalEC)}
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Germination Rate:</Typography>
            <TextField 
              id="germinationRate"
              variant="outlined"
              value={qualityControl.germinationRate}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Weak Seedling:</Typography>
            <TextField 
              id="weakSeeding"
              variant="outlined" 
              type = "number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
              }}
              inputProps={{ maxLength: 50 }}
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.weakSeeding)}
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel2}>TDS (ppm):</Typography>
            <TextField 
              className={clsx(styles.textFieldMargin, styles.textFieldSmall)}
              id="initialTDS"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                // decimal
                const reg = /^\d*(\.\d{0,2})?$/
                if (!reg.test(e.target.value)) {
                  e.target.value = roundTo2Decimal(e.target.value);
                }
              }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.initialTDS)}
            />
            <TextField 
              className={clsx(styles.textFieldMargin, styles.textFieldSmall)}
              id="finalTDS"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                // decimal
                const reg = /^\d*(\.\d{0,2})?$/
                if (!reg.test(e.target.value)) {
                  e.target.value = roundTo2Decimal(e.target.value);
                }
              }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.finalTDS)}
            />
          </Grid>
          <Grid item xs={8} className={styles.grid} />
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel2}>pH Level:</Typography>
            <TextField 
              className={clsx(styles.textFieldMargin, styles.textFieldSmall)}
              id="initialPHLevel"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                // decimal
                const reg = /^\d*(\.\d{0,2})?$/
                if (!reg.test(e.target.value)) {
                  e.target.value = roundTo2Decimal(e.target.value);
                }
              }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.initialPHLevel)}
            />
            <TextField 
              className={clsx(styles.textFieldMargin, styles.textFieldSmall)}
              id="finalPHLevel"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                // decimal
                const reg = /^\d*(\.\d{0,2})?$/
                if (!reg.test(e.target.value)) {
                  e.target.value = roundTo2Decimal(e.target.value);
                }
              }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.finalPHLevel)}
            />
          </Grid>
          <Grid item xs={12} className={styles.grid}>
            <Typography className={styles.formTitle}>Remarks</Typography>
          </Grid>
          <Grid item xs={12} className={styles.grid}>
            <TextField 
              id="spongeSpecs"
              variant="outlined" 
              multiline
              minRows={12}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.spongeSpecs)}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
