import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllManufacturer = createAsyncThunk(
  "manufacturer/getAllManufacturer", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: param ? param.page * rowsPerTable : 0,
      MaxResultCount: param ? rowsPerTable : 99
    }
    try {
      const response = await FomsMS.get("/Manufacturer/GetAll", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetManufacturer = createAsyncThunk(
  "manufacturer/getManufacturer", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.get("/Manufacturer/Get", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteManufacturer = createAsyncThunk(
  "manufacturer/deleteCompany", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/Manufacturer/Delete", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateManufacturer = createAsyncThunk(
  "manufacturer/createOrUpdateManufacturer", 
  async (_, {getState, rejectWithValue}) => {
    const manufacturer = getState().admin.manufacturer.manufacturer;
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      ...manufacturer,
      farmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.post("/Manufacturer/CreateOrUpdate", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetIntegrationTypes = createAsyncThunk(
  "manufacturer/getIntegrationTypes", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Manufacturer/GetIntegrationTypes");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
