// Create Edit Device Tagging
import React from "react";
import { useDispatch } from "react-redux";

import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setSkipStep, reset } from "./store/deviceTagging";

import DetailForm from "./DetailForm";
import FloorPlanForm from "./FloorPlanForm";
import BankForm from "./BankForm";
import TagForm from "./TagForm";

export default function AddEditDeviceTagging(props) {
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(0);

  // componentDidMount
  React.useEffect(() => {
    const prop = props.location.state;
    dispatch(setAddNewButton(true));
    if (prop) {
      dispatch(setSkipStep(prop));
      setStep(prop.step);
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(reset());
      dispatch(resetNav());
    }
  },[]);

  const renderForm = () => {
    switch(step) {
      case 0:
        return <DetailForm step={step} setStep={setStep} />
      case 1:
        return <FloorPlanForm step={step} setStep={setStep} />
      case 2:
        return <BankForm step={step} setStep={setStep} />
      case 3:
        return <TagForm step={step} setStep={setStep} />
    }
  }
  return (
    <React.Fragment>
      {renderForm()}
    </React.Fragment>
  )
}
