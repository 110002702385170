import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllDeviceType, GetDeviceType } from "services/AdminPortal/DeviceService";

// initial state
const initialState = {
  deviceTypes: [],
  deviceType: {},
};

const type = createSlice({
  name: "type",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.deviceType = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.deviceType[key] = action.payload[key];
      })
    },
    resetDevice: (state) => {
      state.deviceType = {};
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllDeviceType.fulfilled, (state, action) => {
        if (action.payload.result) {
          state.deviceTypes = _.orderBy(action.payload.result.items, ["deviceTypeName"], ["asc"]);
        } else {
          state.deviceTypes = [];
        }
      })
      .addCase(GetDeviceType.fulfilled, (state, action) => {
        state.deviceType = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, resetDevice, reset } = type.actions;

// export the reducer
export default type.reducer;