import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
// @mui/material
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// @mui/icons
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
// core components

import Summary from "./Summary";

import styles from "assets/jss/components/UserPortal/monitoring.module.scss";

export default function EquipmentOverview() {
  const equipmentOverview = useSelector(store => store.user.monitoring.equipment.equipmentOverview.equipmentSummaryInfoDtos);
  const [equipment, setEquipment] = React.useState(null);
  
  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "equipment");
  
  React.useEffect(() => {
    setEquipment(equipmentOverview && equipmentOverview.find(({equipmentName}) => equipmentName === path[index+1].replace("%20", " ")));
  },[equipmentOverview, location.pathname]);

  return (
    <React.Fragment>
      <div className={styles.cardContainer}>
        <div className={styles.leftEquipOverview}>
          <Paper className={clsx(styles.envPaper, styles.equipPaper)}>
            <div className={styles.equipTitle}>
              <Typography className={styles.envValueBold}>{equipment&&equipment.equipmentName}</Typography>
              <PrecisionManufacturingOutlinedIcon className={clsx(styles.icon_38, styles.equipIcon)} />
            </div>
            <Typography>In Use: {equipment&&equipment.inUsed}</Typography>
            <Typography>Down: {equipment&&equipment.down}</Typography>
            <Typography>Maintenance: {equipment&&equipment.maintenance}</Typography>
          </Paper>
        </div>
        <Summary/>
      </div>
    </React.Fragment>
  );
}
