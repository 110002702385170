import React from "react";
import { useDispatch } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import Overview from "./Overview";
import Water from "./Water";
import Energy from "./Energy";
import Waste from "./Waste";
import Fuel from "./Fuel";
import Chiller from "./Chiller";
import { setSearchText } from "store/general";
import { reset } from "../store/costOverview";

export default function Efficiency() {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState(0);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // dispatch(GetAllWarehouse());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <Overview />
      case 1:
        return <Water />
      case 2:
        return <Energy />
      case 3:
        return <Waste />
      case 4:
        return <Fuel />
      case 5:
        return <Chiller />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title = "Cost & Efficiency"
        subheader={
          <React.Fragment>
            <Tabs 
              tabs={["Overview", "Water", "Energy", "Waste", "Fuel", "Chiller"]}
              value={tab} 
              onChange={(e,value) => setTab(value)}
            />
          </React.Fragment>
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
