import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
// core components
import Accordion from "shared-components/Accordion/CardAccordion";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Popper from "shared-components/Popper/Popper";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";
import Modal from "shared-components/Modal/Modal";

import { minDate, rowsPerTable } from "config";
import { isPositiveDecimal } from "common/validations";
import { renderValue, formatNumbers, roundTo2Decimal } from "common/functions";
import { setDirty, setLoading } from "store/general";
import { taskDetailTableHead } from "enums/UserPortal/TableHeaders";
import { taskDetailStatus } from "enums/Constants";
import { setValues, updateStorageRequest, updateQCRequest } from "../store/task";
import { GetWarehouseRackByWarehouseId, CreateOrUpdateOperationStorage, CreateOrUpdateQCDetails } from "services/UserPortal/OperationsService";

import styles from "assets/jss/components/UserPortal/operations.module.scss";

export default function TaskTable(props) {
  const dispatch = useDispatch();
  const userList = useSelector(store => store.common.userList);
  const selectedAction = useSelector(store => store.user.operations.task.selectedAction);
  const storage = useSelector(store => store.user.operations.task.storage);
  const qc = useSelector(store => store.user.operations.task.qc);
  const uomList = useSelector(store => store.user.operations.task.uomList);
  const warehouseList = useSelector(store => store.common.warehouseList);
  const allRackNumber = useSelector(store => store.user.operations.task.allRackNumber);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [autocompletePage, setAutocompletePage] = React.useState(0); // for rack list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for rack list
  const [searchValue, setSearchValue] = React.useState(""); // for rack list
  const [openStorageModal, setOpenStorageModal] = React.useState(false);
  const [openQCModal, setOpenQCModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  
  const handleButtonClick_checkbox = (e) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(selectedAction);
    const index = payload.findIndex(({id}) => id == e.target.id);
    if (index != -1) {
      payload[index] = {
        ...payload[index],
        isAction: !payload[index].isAction,
        remark: ""
      }
    }
    dispatch(setValues({selectedAction: payload}));
  };

  const handleOnChange_remark = (e, selected, target) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(selectedAction);
    const index = payload.findIndex(({id}) => id == e.target.id);
    if (index != -1) {
      let edit = {...selected, [target]: e.target.value};
      payload[index] = edit;
    }
    dispatch(setValues({selectedAction: payload}));
  };

  const renderRemarks = (item, selected) => {
    if (item.requireWarehouse) {
      if (item.operationStorageList.length) {
        return (
          <span className={styles.link} onClick={()=>handleModal_storage(item.id, item.operationStorageList[0])}>
            {item.operationStorageList[0].weight}kg; Stored at {item.operationStorageList[0].warehouseRefNo}; {item.operationStorageList[0].rackRefNoList.join(", ")}
          </span>
        )
      } else {
        if (item.isMyTask && !(props.taskStatus === taskDetailStatus.completed || props.taskStatus === taskDetailStatus.pending)) {
          return <span className={styles.link} onClick={()=>handleModal_storage(item.id)}>Choose Storage Area</span>
        } else {
          return <Typography class="storage">Choose Storage Area</Typography>
        }
      }
    }
    if (item.isInitialQCGermination || item.isFinalQCGermination || item.isInitialQCVegetable || item.isFinalQCVegetable) {
      if (item.waterLevel) {
        return (
          <span className={styles.link} onClick={()=>handleModal_qc(item)}>
            {item.waterLevel}cm; {item.ec}ms/cm; {item.tds}ppm; {item.phLevel}
          </span>
        )
      } else {
        if (item.isMyTask && !(props.taskStatus === taskDetailStatus.completed || props.taskStatus === taskDetailStatus.pending)) {
          return (
            <span className={styles.link} onClick={()=>handleModal_qc(item)}>
              Add in {item.isInitialQCGermination && "Initial Germination"}{item.isFinalQCGermination && "Final Germination"}{item.isInitialQCVegetable && "Initial Vegetable"}{item.isFinalQCVegetable && "Final Vegetable"} QC Details
            </span>
          )
        } else {
          return <Typography>Add in {item.isInitialQCGermination && "Initial Germination"}{item.isFinalQCGermination && "Final Germination"}{item.isInitialQCVegetable && "Initial Vegetable"}{item.isFinalQCVegetable && "Final Vegetable"} QC Details</Typography>
        }
      }
    }
    if (item.isMyTask && !item.requireWarehouse && props.taskStatus != taskDetailStatus.completed) {
      if (selected && selected.isAction) {
        return (
          <TextField 
            id={String(item.id)}
            variant="outlined" 
            inputProps={{ maxLength: 100 }}
            onChange={(e) => handleOnChange_remark(e, selected, "remark")}
            value={renderValue(selected && selected.remark)}
            disabled={!(selected && selected.isAction)}
            multiline={true}
          />
        )
      } else {
        <Typography>{selected && selected.remark && selected.remark.replace(",", "\n")}</Typography>
      }
    }
    return item.remark;
  }

  // storage modal
  const handleOnChange_storageText = (e) => {
    dispatch(updateStorageRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateStorageRequest({[e.target.name] : e.target.value}));
    if (e.target.name === "warehouseId") {
      dispatch(updateStorageRequest({"rackRefNoList" : []}));
      setAutocompletePage(0);
      const param = {
        warehouseId: e.target.value,
        page: 0
      }
      dispatch(GetWarehouseRackByWarehouseId(param))
      .then((response) => {
        if (response.payload.result) {
          setAutocompletePage(autocompletePage+1);
          dispatch(setValues({allRackNumber: response.payload.result.items}))
        }
      });
    }
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateStorageRequest({rackRefNoList : value}));
  };

  const fetchMoreData = (search, pageNo) => {
    if (autocompletePage !== -1 || pageNo !== undefined || storage.warehouseId) {
      setIsLoadingMore(true);
      const param = {
        warehouseId: storage.warehouseId,
        page: pageNo !== undefined ? pageNo : autocompletePage,
        rackID: search ? search : ""
      }
      dispatch(GetWarehouseRackByWarehouseId(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result) {
            setAutocompletePage(pageNo !== undefined ? pageNo+1 : autocompletePage+1);
            if (search || autocompletePage === 0 || pageNo === 0) {
              dispatch(setValues({allRackNumber: response.payload.result.items}));
            } else {
              dispatch(setValues({allRackNumber: _.unionBy(allRackNumber, response.payload.result.items, "rackID")}));
            }
          } else {
            setAutocompletePage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const handleModal_storage = (id, item) => {
    setOpenStorageModal(!openStorageModal);
    if (!id) {
      dispatch(setDirty(false));
      dispatch(setValues({storage: {}}));
    } else {
      dispatch(setDirty(true));
      if (item) {
        dispatch(setValues({storage: {...item, rackRefNoList: item.rackRefNoList.map((rack) => {return {rackID: rack}})}}));
      } else {
        dispatch(setValues({storage: {operationSubTaskId: id, uom: uomList[0].unitOfMeasurementShortForm}}));
      }
    }
  };

  const handleButtonClick_storageConfirm = () => {
    if (validateStorageFields()) {
      dispatch(setLoading(true));
      let body = _.cloneDeep(storage);
      if (body.rackRefNoList) {
        body.rackRefNoList = body.rackRefNoList.map((item) => item.rackID);
      }
      dispatch(CreateOrUpdateOperationStorage(body))
      .then(({error}) => {
        if (!error) {
          handleModal_storage(null);
          dispatch(setValues({storage : {}}));
          props.getOperationTask();
        }
      });
    }
  };
  
  const validateStorageFields = () => {
    if (!storage.weight) {
      setErrorMsg({field: "weight", msg: "Please enter vegetable weight"});
      return false;
    }
    if (!isPositiveDecimal(storage.weight)) {
      setErrorMsg({field: "weight", msg: "Please enter a valid vegetable weight"});
      return false;
    }
    if (!storage.warehouseId) {
      setErrorMsg({field: "warehouseId", msg: "Please select a warehouse"});
      return false;
    }
    if (!storage.rackRefNoList || (storage.rackRefNoList && !storage.rackRefNoList.length)) {
      setErrorMsg({field: "rackRefNoList", msg: "Please select a rack"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  // qc modal
  const handleOnChange_qcText = (e) => {
    dispatch(updateQCRequest({[e.target.id] : Number(e.target.value)}));
  };

  const handleModal_qc = (item) => {
    setOpenQCModal(!openQCModal);
    if (!(item && item.id)) {
      dispatch(setDirty(false));
      dispatch(setValues({qc: {}}));
    } else {
      dispatch(setDirty(true));
      if (item) {
        dispatch(setValues({qc: {..._.pick(item, ["waterLevel", "ec", "tds", "phLevel", "id"])}}));
      } else {
        dispatch(setValues({qc: {operationSubTaskId: item.id}}));
      }
    }
  };
  
  const handleButtonClick_qcConfirm = () => {
    if (validateQCFields()) {
      dispatch(setLoading(true));
      dispatch(CreateOrUpdateQCDetails(qc))
      .then(({error}) => {
        if (!error) {
          handleModal_qc({});
          dispatch(setValues({qc : {}}));
          props.getOperationTask();
        }
      });
    }
  };
  
  const validateQCFields = () => {
    if (!qc.waterLevel) {
      setErrorMsg({field: "waterLevel", msg: "Please enter water level"});
      return false;
    }
    if (!isPositiveDecimal(qc.waterLevel)) {
      setErrorMsg({field: "waterLevel", msg: "Please enter a valid water level"});
      return false;
    }
    if (!qc.ec) {
      setErrorMsg({field: "ec", msg: "Please enter ec"});
      return false;
    }
    if (!isPositiveDecimal(qc.ec)) {
      setErrorMsg({field: "ec", msg: "Please enter a valid ec"});
      return false;
    }
    if (!qc.tds) {
      setErrorMsg({field: "tds", msg: "Please enter tds"});
      return false;
    }
    if (!isPositiveDecimal(qc.tds)) {
      setErrorMsg({field: "tds", msg: "Please enter a valid tds"});
      return false;
    }
    if (!qc.phLevel) {
      setErrorMsg({field: "phLevel", msg: "Please enter pH level"});
      return false;
    }
    if (!isPositiveDecimal(qc.phLevel)) {
      setErrorMsg({field: "phLevel", msg: "Please enter a valid pH level"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  if (props.data.length) {
    return (
      <React.Fragment>
        <Accordion
          classes={{
            root: styles.accordion,
          }}
          header={props.title}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <div className={styles.table}>
            <Table
              // className={styles.assignmentTable}
              header={props.data[0].requireWarehouse ? taskDetailTableHead.map((item) => {
                if (item.id==="remark") {
                  item.label = "Detail";
                } 
                return item;
              }) : taskDetailTableHead}
            >
              { props.data.slice(props.page[props.pageKey] * rowsPerTable, props.page[props.pageKey] * rowsPerTable + rowsPerTable)
              .map((item,index) => {
                const selected = selectedAction.find(({id}) => id === item.id);
                const popperContent = item.isMyTask 
                ? (item.unableToAction
                  ? props.title==="Equipment" ? "Unable to issue equipment due to insufficient availability" : "Unable to issue raw material due to inadequate quantity"
                  : props.taskStatus == taskDetailStatus.pending ? "You need to start your timer to complete this task" : null)
                : "You do not have the right to complete this task"
                return (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Popper
                        noButton
                        open={openPopper === index}
                        className={styles.popper}
                        closePopper={() => setOpenPopper(false)}
                        placement="right"
                        content={popperContent}
                      >
                        <div 
                          onMouseOver={()=>popperContent && setOpenPopper(index)} 
                          onMouseOut={()=>setOpenPopper(false)}
                        >
                          <Checkbox 
                            id={String(item.id)}
                            checked={selected ? Boolean(selected.isAction) : item.isAction}
                            onChange={(e) => handleButtonClick_checkbox(e)}
                            disabled={Boolean(
                              item.unableToAction || !item.isMyTask || props.taskStatus === taskDetailStatus.completed || props.taskStatus === taskDetailStatus.pending ||
                              (item.requireWarehouse && item.operationStorageList.length === 0) ||
                              ((item.isInitialQCGermination || item.isFinalQCGermination || item.isInitialQCVegetable || item.isFinalQCVegetable) && !item.waterLevel))
                            }
                          />
                        </div>
                      </Popper>  
                    </TableCell>
                    <TableCell className={styles.smallCell}>{item.sequenceOrder}</TableCell>
                    <TableCell className={styles.bigCell}>{item.subTaskName}</TableCell>
                    <TableCell className={styles.bigCell}>
                      {props.refNoKey === 'deviceRefNo'
                      ? <TextField 
                          id={String(item.id)}
                          variant="outlined" 
                          inputProps={{ maxLength: 100 }}
                          onChange={(e) => handleOnChange_remark(e, selected, "deviceRefNo")}
                          value={renderValue(selected ? selected.deviceRefNo : item.deviceRefNo)}
                          disabled={!(selected && selected.isAction)}
                          errorMsg={props.errorMsg}
                        />
                      : item[props.refNoKey]
                      }
                    </TableCell>
                    <TableCell className={styles.smallCell}>{formatNumbers(roundTo2Decimal(item.qty))}</TableCell>
                    <TableCell className={styles.smallCell}>{item.uom}</TableCell>
                    <TableCell className={styles.bigCell}>{moment(item.startDate).isAfter(minDate) ? moment(item.startDate).format("DD/MM/yyyy") : ""}</TableCell>
                    <TableCell className={styles.bigCell}>{moment(item.startTime).format("HH:mm")+" - "+moment(item.endTime).format("HH:mm")}</TableCell>
                    <TableCell className={styles.smallCell}>{item.frequency}</TableCell>
                    <TableCell className={styles.bigCell}>{item.assignedUserName}</TableCell>
                    <TableCell className={styles.bigCell}>{item.additionalPersons && userList.filter((user) => item.additionalPersons.includes(user.id)).map(({userName})=> userName).join(", ")}</TableCell>
                    <TableCell className={styles.bigCell}>
                      {renderRemarks(item, selected)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </div>
          { props.count > 1 &&
            <Pagination 
              count={props.count} 
              page={props.page[props.pageKey]+1}
              onChange={(e, pageNo)=>props.setPage({...props.page, [props.pageKey]: pageNo-1})}
            />
          }
        </Accordion>
        <Modal
          open={openStorageModal}
          className={styles.modalPaper}
          classes={{
            content: styles.modalContent
          }}
          onClose={() => handleModal_storage(null)}
          title={<Typography className={styles.modalTitle}>Choose a storage area for the packaged vegetable</Typography>}
          content={
            <React.Fragment>
              <div className="flex">
                <div className={styles.weightField}>
                  <TextField
                    label="Vegetable Weight"
                    id="weight"
                    variant="outlined" 
                    type="Number"
                    onInput={(e)=>{ 
                      // decimal
                      const reg = /^\d*(\.\d{0,2})?$/
                      if (!reg.test(e.target.value)) {
                        e.target.value = roundTo2Decimal(e.target.value);
                      }
                    }}
                    onChange={(e) => handleOnChange_storageText(e)}
                    value={renderValue(storage.weight)}
                    placeholder="Vegetable Weight"
                    errorMsg={errorMsg}
                  />
                </div>
                <div className="w-full text-left">
                  <Select
                    label="UOM"
                    name="uom"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={renderValue(storage.uom)}
                    placeholder="Please select an option"
                    errorMsg={errorMsg}
                  >
                    {uomList && uomList.map((item) => {
                      return <MenuItem key={item.unitOfMeasurementShortForm} value={item.unitOfMeasurementShortForm}>{item.unitOfMeasurementShortForm}</MenuItem>;
                    })} 
                  </Select>
                </div>
              </div>
              <div className={styles.modalField}>
                <Select
                  label="Location"
                  name="warehouseId"
                  onChange={(e)=>handleOnChange_select(e)}
                  placeholder="Select a location"
                  value={renderValue(storage.warehouseId)}
                  errorMsg={errorMsg}
                >
                  {warehouseList && warehouseList.map((item) => {
                    return <MenuItem key={item.id} value={item.id}>{item.warehouseName}{item.warehouseRefNo}</MenuItem>;
                  })}
                </Select>
              </div>
              <div className={styles.modalField}>
                <Autocomplete
                  label="Rack Numbers"
                  id="rackRefNoList"
                  dataCount={autocompletePage*10}
                  options={allRackNumber}
                  fetchData={()=>fetchMoreData()}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Checkbox checked={_.findIndex(storage.rackRefNoList, ({rackID}) => rackID === option.rackID) > -1} />
                      {option.rackID}
                    </React.Fragment>
                  )}
                  isLoadingMore={isLoadingMore}
                  placeholder="Eg. R01-01"
                  onInputChange={(_, newInputValue) => {
                    setAutocompletePage(0);
                    setSearchValue(newInputValue);
                    fetchMoreData(newInputValue, 0);
                  }}
                  onChange={(_, newValue) => {
                    handleOnChange_autocomplete(newValue);
                  }}
                  inputValue={searchValue}
                  value={storage.rackRefNoList ? storage.rackRefNoList : []}
                  renderValue={"rackID"}
                  multiple
                  disabled={!storage.warehouseId}
                  errorMsg={errorMsg}
                />
              </div>
            </React.Fragment>
          }
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal_storage(null)}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_storageConfirm()}>Confirm</Button>
            </React.Fragment>
          }
        />
        <Modal
          open={openQCModal}
          className={styles.modalPaper}
          classes={{
            content: styles.modalContent
          }}
          onClose={() => handleModal_qc(null)}
          title={<Typography className={styles.modalTitle}>Key in the following details for quality control</Typography>}
          content={
            <React.Fragment>
              <div>
                <TextField
                  label="Water Level (cm)"
                  id="waterLevel"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  onChange={(e) => handleOnChange_qcText(e)}
                  value={renderValue(qc.waterLevel)}
                  placeholder="Water Level"
                  errorMsg={errorMsg}
                />
              </div>
              <div className={styles.modalField}>
                <TextField
                  label="EC (ms/cm)"
                  id="ec"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  onChange={(e) => handleOnChange_qcText(e)}
                  value={renderValue(qc.ec)}
                  placeholder="EC"
                  errorMsg={errorMsg}
                />
              </div>
              <div className={styles.modalField}>
                <TextField
                  label="TDS (ppm)"
                  id="tds"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  onChange={(e) => handleOnChange_qcText(e)}
                  value={renderValue(qc.tds)}
                  placeholder="TDS"
                  errorMsg={errorMsg}
                />
              </div>
              <div className={styles.modalField}>
                <TextField
                  label="pH Level"
                  id="phLevel"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  onChange={(e) => handleOnChange_qcText(e)}
                  value={renderValue(qc.phLevel)}
                  placeholder="pH Level"
                  errorMsg={errorMsg}
                />
              </div>
            </React.Fragment>
          }
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal_qc(null)}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_qcConfirm()}>Confirm</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    )
  } else {
    return null;
  }
}