import { createAsyncThunk } from "@reduxjs/toolkit";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetCompanyDashboard = createAsyncThunk(
  "company/getCompanyDashboard", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {id: userDetail.companyId};
    try {
      const response = await FomsMS.get("/Company/GetCompanyDashboard", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllCompany = createAsyncThunk(
  "company/getAllCompany", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Company/GetAll");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCompany = createAsyncThunk(
  "company/getCompany", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.get("/Company/Get", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteCompany = createAsyncThunk(
  "company/deleteCompany", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/Company/Delete", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateCompany = createAsyncThunk(
  "company/createOrUpdateCompany", 
  async (_, {getState, rejectWithValue}) => {
    const company = getState().admin.company.company;
    let body = company;
    try {
      const response = await FomsMS.post("/Company/CreateOrUpdate", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchCompany = createAsyncThunk(
  "company/searchCompany", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Company/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);