import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { renderValue, formatNumbers, roundTo2Decimal, filterParam } from 'common/functions';
import { warehouseTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/master";
import { GetAllWarehouse } from "services/UserPortal/WarehouseService";

import styles from "assets/jss/components/UserPortal/warehouse.module.scss";

export default function MasterList() {
  const dispatch = useDispatch();
  const warehouseList = useSelector(store => store.common.warehouseList);
  const filter = useSelector(store => store.user.warehouse.master.filter);
  const totalCount = useSelector(store => store.user.warehouse.master.totalCount);
  const result = useSelector(store => store.user.warehouse.master.warehouse);
  const [newFilter, setNewFilter] = React.useState({warehouseId: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('rawMaterialName');

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const getWarehouseList = (stopLoading) => {
    dispatch(GetAllWarehouse({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getWarehouseList(0);
    }
  },[filter]);

  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getWarehouseList(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="Master List"
        subheader={
          <React.Fragment>
            <Typography>Click on the details for each column to view the details:</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "page") {
                let label = filter[key];
                if (key === "warehouseId") {
                  const warehouse = warehouseList.find(({id}) => id == filter[key]);
                  label = warehouse && (warehouse.warehouseName+" ("+warehouse.warehouseId+")");
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={styles.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the warehouse list
                  <Select
                    className={styles.filterDropdown}
                    name="warehouseId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a warehouse"
                    value={renderValue(newFilter.warehouseId)}
                  >
                    {warehouseList && warehouseList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.warehouseName} ({item.warehouseId})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            header={warehouseTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.warehouseID}</TableCell>
                  <TableCell>{item.warehouseName}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.capacity))}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.capacityUsed*100))}%</TableCell>
                  <TableCell>{item.address}</TableCell>
                  <TableCell><Link className={styles.link} to={{pathname: "/user/warehouse/raw-material", search:"?warehouseId="+item.id}}>More Details</Link></TableCell>
                  <TableCell><Link className={styles.link} to={{pathname: "/user/warehouse/finished-goods", search:"?warehouseId="+item.id}}>More Details</Link></TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
