import { createSlice } from "@reduxjs/toolkit";

import { SearchCustomerOrder, SearchCustomerOrderCapacity } from "services/UserPortal/CustomerOrderService";

// initial state
const initialState = {
  saleOrderTotalCount: 0,
  capacityTotalCount: 0,
  saleOrderResults: [],
  capacityResults: [],
};

const search = createSlice({
  name: "search",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SearchCustomerOrder.fulfilled, (state, action) => {
        state.saleOrderTotalCount = action.payload.result.totalCount;
        state.saleOrderResults = action.payload.result.items;
      })
      .addCase(SearchCustomerOrderCapacity.fulfilled, (state, action) => {
        state.capacityTotalCount = action.payload.result.totalCount;
        state.capacityResults = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, reset } = search.actions;

// export the reducer
export default search.reducer;