import { find } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {FomsMS} from "../index";

// Lookup
export const GetAllProduct = createAsyncThunk(
  "capacity/getAllProduct", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Capacity/GetAllProduct", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllProductProcess = createAsyncThunk(
  "capacity/getAllProductProcess", 
  async (id, {rejectWithValue}) => {
    let params = new URLSearchParams();
    id.map((item) => params.append("productIds", item));
    try {
      const response = await FomsMS.get("/Capacity/GetAllProductProcess", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllSoftzone = createAsyncThunk(
  "capacity/getAllSoftzone", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
      MaxResultCount: 999
    }
    try {
      const response = await FomsMS.get("/Capacity/GetAllSoftzone", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllLocationBySoftzone = createAsyncThunk(
  "capacity/getAllLocationBySoftzone", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      ...param
    }
    try {
      const response = await FomsMS.get("/Capacity/GetAllLocationBySoftzone", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Farm Capacity
export const GetCurrentFarmCapacity = createAsyncThunk(
  "capacity/getCurrentFarmCapacity", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Capacity/GetCurrentFarmCapacity", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCapacityCategoryByFarmId = createAsyncThunk(
  "capacity/getCapacityCategoryByFarmId", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Capacity/GetCapacityCategoryByFarmId", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllBankAndCage = createAsyncThunk(
  "capacity/getAllBankAndCage", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Capacity/GetAllBankAndCage", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateCapacityCategoryList = createAsyncThunk(
  "capacity/createOrUpdateCapacityCategoryList", 
  async (_, {getState, rejectWithValue}) => {
    let categories = getState().admin.capacity.farmCapacity.categories;
    const body = categories.map((category) => {
      return {
        ...category, 
        productList: category.productList.map((product) => {return product.id})
      }
    });
    try {
      const response = await FomsMS.post("/Capacity/CreateOrUpdateCapacityCategoryList", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Tray Configuration
export const GetAllCapacityTrayConfig = createAsyncThunk(
  "capacity/getAllCapacityTrayConfig", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      productId: id
    }
    try {
      const response = await FomsMS.get("/Capacity/GetAllCapacityTrayConfig", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCapacityTrayConfig = createAsyncThunk(
  "capacity/getCapacityTrayConfig", 
  async (id, {rejectWithValue}) => {
    const params = {id}
    try {
      const response = await FomsMS.get("/Capacity/GetCapacityTrayConfig", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateCapacityTrayConfig = createAsyncThunk(
  "capacity/createOrUpdateCapacityTrayConfig", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = Object.assign({}, getState().admin.capacity.configuration.configuration);
    body = {...body, farmId: find(userDetail.farms, "isActive").id}
    try {
      const response = await FomsMS.post("/Capacity/CreateOrUpdateCapacityTrayConfig", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteCapacityTrayConfig = createAsyncThunk(
  "capacity/deleteCapacityTrayConfig", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/Capacity/DeleteCapacityTrayConfig", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Cage Configuration
export const GetCageConfiguration = createAsyncThunk(
  "capacity/getCageConfiguration", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.get("/Capacity/GetCageConfiguration", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SaveCageConfigurations = createAsyncThunk(
  "capacity/saveCageConfigurations", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const capacityCategories = Object.assign([], getState().admin.capacity.batchCapacity.capacityCategories);
    const body = {capacityCategories, farmId: find(userDetail.farms, "isActive").id}
    try {
      const response = await FomsMS.post("/Capacity/SaveCageConfigurations", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Capacity Table
export const GetCapacityTable = createAsyncThunk(
  "capacity/getCapacityTable", 
  async (include, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      IsIncludeLossCalculation: include
    }
    try {
      const response = await FomsMS.get("/Capacity/GetCapacityTable", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchCapacity = createAsyncThunk(
  "capacity/searchCapacity", 
  async (Keyword, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword
    }
    try {
      const response = await FomsMS.get("/Capacity/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);