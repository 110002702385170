import { combineReducers } from "redux";
import farmCapacity from "./farmCapacity";
import configuration from "./configuration";
import batchCapacity from "./batchCapacity";
import search from "./search";

const capacityReducer = combineReducers({
  farmCapacity,
  configuration,
  batchCapacity,
  search,
});

export default capacityReducer;