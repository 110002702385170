import { combineReducers } from "redux";
import costOverview from "./costOverview";
import harvest from "./harvest";
import report from "./report";
import settings from "./settings";
import search from "./search";

const costProductivityReducer = combineReducers({
  costOverview,
  harvest,
  report,
  settings,
  search,
});

export default costProductivityReducer;