import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Drawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";
// @mui/icons-material
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// core components
import Card from "shared-components/Card/Card";
import CustomTabs from "shared-components/Tabs/Tabs";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Switch from "shared-components/Switch/Switch";

import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from "common/functions";
import { climateMonitoringTableHead } from "enums/UserPortal/TableHeaders";
import { setSearchText } from "store/general";
import { setValues } from "store/common";
import { reset } from "../store/climateMonitoring";
import { GetClimateMonitoringHeatMap, GetDeviceUnits, DownloadClimateMonitoring } from "services/UserPortal/ClimateService";
import { GetDeviceList, GetAllSensorControlDeviceFunction } from "services/UserPortal/CommonLookupService";

import ParamList from "./ParamList";
import Graph from "./Graph";
import HeatMap from "./HeatMap";
import styles from "assets/jss/components/UserPortal/climate.module.scss";

const FileDownload = require('js-file-download');
export default function ClimateMonitoring(props) {
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const bankList = useSelector(store => store.common.bankList);
  const functionList = useSelector(store => store.common.functionList);
  const heatMapResult = useSelector(store => store.user.climate.climateMonitoring.heatMapResult);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('ruleNo');
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);
  const [isHeatMap, setIsHeatMap] = React.useState(false);
  const [date, setDate] = React.useState({startDate: 'Invalid Date', endDate: 'Invalid Date'});
  const [page, setPage] = React.useState(0); // for device list
  const [filter, setFilter] = React.useState({bank: 0, function: null, deviceId: [], unit: null}); // for filter
  const [open, setOpen] = React.useState(true);
  const [firstLoad, setFirstLoad] = React.useState(true);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_switch = () => {
    setIsHeatMap(!isHeatMap);
    setDate({startDate: moment().subtract(24,'hours').format(), endDate: moment().format()});
  };
  
  const handleButtonClick_download = () => {
    dispatch(DownloadClimateMonitoring({...date, bankId: 1, deviceIds: filter.deviceId, unit: filter.unit}))
    .then((response) => {
      FileDownload(response.payload.data, 'ClimateMonitoringReport.xlsx');
    });
  };

  const getTable = () => {
    if (bankList.length && filter.deviceId.length) {
      const param = {bankId: bankList[tab].id, deviceIds: filter.deviceId, unit: filter.unit};
      dispatch(GetClimateMonitoringHeatMap({...param, stopLoading: true}));
    }
  }

  React.useEffect(() => {
    const functions = _.filter(functionList, ({deviceFunctionName}) => !deviceFunctionName.includes('Controller'));
    if (bankList[tab] && functions.length && !isFirstUpdate.current) {
      const functionId = functions[0].id;
      dispatch(GetDeviceList({bankId: bankList[tab].id, DeviceFunctionId: functionId, page: 0}))
      .then(({payload}) => {
        if (payload.result && payload.result.items.length) {
          const device = payload.result.items[0];
          dispatch(setValues({deviceList: payload.result.items}));
          setPage(page+1);
          if (device) {
            dispatch(GetDeviceUnits(functionId))
            .then(({payload}) => {
              if (payload.result && payload.result.length) {
                setFilter({bank: tab, function: functionId, deviceId: [device.id], unit: payload.result[0]});
              }
            })
          }
        } else {
          dispatch(setValues({deviceCount: 0, deviceList: [], deviceUnits: []}));
          setFilter({bank: tab, function: functionId, deviceId: [], unit: null});
        }
      });
    }
  },[functionList, tab]);

  React.useEffect(() => {
    getTable();
    setFirstLoad(true);
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    const prop = props.location.state;
    dispatch(setSearchText(""));
    dispatch(GetAllSensorControlDeviceFunction({isSensor: true}));
    if (prop && prop.bankId && prop.deviceId && prop.unit && prop.date) {
      setFilter({deviceId: [prop.deviceId], unit: prop.unit});
      setDate({startDate: moment(prop.date).startOf('day').format(), endDate: moment(prop.date).endOf('day').format()})
    }
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getTable();
  });

  return (
    <React.Fragment>
      <Card 
        title="Climate Monitoring"
        subheader={
          <React.Fragment>
            <Typography>Click on &quot;Search&quot; and select the devices you want to view in the graph</Typography>
            <CustomTabs 
              tabs={bankList.map((item) => {return item.bankName})}
              value={tab} 
              onChange={(e, value) => setTab(value)}
            />
          </React.Fragment>
        }
        action={
          <div className={styles.switchAction}>
            <Typography className={styles.label}>Heat Map:</Typography>
            <Switch 
              checked={Boolean(isHeatMap)}
              onChange={() => handleOnChange_switch()}
            />
          </div>
        }
        cardActions={ !isHeatMap &&
          <React.Fragment>
            <Button disabled/>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>handleButtonClick_download()}
              disabled={!(filter.function && filter.deviceId.length && filter.unit)}
            >
              Download CSV File
            </Button>
          </React.Fragment>
        }
      >
        <div className={styles.graphContent}>
          <IconButton
            className={styles.formIconButton2}
            onClick={()=>setOpen(!open)}
          >
            {!open ? <KeyboardArrowRightIcon className={styles.icon_24} /> : <KeyboardArrowLeftIcon className={styles.icon_24} />}
          </IconButton>
          {isHeatMap
          ? <HeatMap 
              bank={bankList.length && bankList[tab]} 
              filter={filter} 
              setFilter={setFilter} 
              unit={filter.unit} 
            />
          : <Graph 
              bank={bankList.length && bankList[tab]} 
              filter={filter} 
              setFilter={setFilter} 
              unit={filter.unit}
              date={date}
              setDate={setDate} 
              firstLoad={firstLoad}
              setFirstLoad={setFirstLoad}
            />
          }
          <Drawer
            sx={{
              // width: drawerWidth,
              // flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: "30%",
                height: "35vw",
                position: 'absolute',
                padding: "1.563vw",
                paddingLeft: "3.125vw",
                marginLeft: "-1.979vw",
                border: "0.052vw solid #D9D9D6",
                borderLeft: 0,
                borderBottomRightRadius: "0.833vw",
                overflow: "visible"
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <Paper className={styles.paper} elevation={0}>
              <ParamList 
                bankId={bankList.length && bankList[tab].id}
                tab={tab} 
                setTab={setTab} 
                filter={filter} 
                setFilter={setFilter}
              />
            </Paper>
            <IconButton
              className={styles.formIconButton}
              onClick={()=>setOpen(!open)}
            >
              {!open ? <KeyboardArrowRightIcon className={styles.icon_24} /> : <KeyboardArrowLeftIcon className={styles.icon_24} />}
            </IconButton>
          </Drawer>
        </div>
        <Card 
          title="Latest Real Time Readings Table"
          classes={{
            root: clsx(styles.cardPaper, styles.cardMargin),
          }}
        >
          <Table
            className={styles.table}
            header={climateMonitoringTableHead.map((head) => {
              if (head.id === "value" && filter.unit) {
                head.label="Value ("+filter.unit+")"
              } 
              return head
            })}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            {heatMapResult && heatMapResult.map((item, index) => {
              if (item) {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.deviceFunctionName}</TableCell>
                    <TableCell>{item.deviceRefNo}</TableCell>
                    <TableCell>{(item.sensorValues&&item.sensorValues.length)&&formatNumbers(roundTo2Decimal(item.sensorValues[0].value))}</TableCell>
                    <TableCell>{moment(item.readingDate).format("DD/MM/yyyy HH:mm")}</TableCell>
                    <TableCell>{item.status}</TableCell>
                  </TableRow>
                )
              }
            })}
          </Table>
        </Card>
      </Card>
    </React.Fragment>
  );
}
