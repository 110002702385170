import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetWorkOrdersLookupForAdjustment, GetSubSaleOrdersByWorkOrderIdForAdjustment, GetSimilarSubSaleOrdersForAdjustment } from "services/UserPortal/PlanningService";

// initial state
const initialState = {
  filter: {},
  adjustmentsCount: 0,
  adjustments: [],
  similarCount: 0,
  similar: [],
  adjust: {},
  workOrderList: []
};

const adjustment = createSlice({
  name: "adjustment",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.adjust[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetWorkOrdersLookupForAdjustment.fulfilled, (state, action) => {
        state.workOrderList = _.orderBy(action.payload.result, ["workOrderNumber"], ["desc"]);
      })
      .addCase(GetSubSaleOrdersByWorkOrderIdForAdjustment.fulfilled, (state, action) => {
        state.adjustmentsCount = action.payload.result.totalCount;
        state.adjustments = action.payload.result.items;
      })
      .addCase(GetSimilarSubSaleOrdersForAdjustment.fulfilled, (state, action) => {
        state.similarCount = action.payload.result.totalCount;
        state.similar = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = adjustment.actions;

// export the reducer
export default adjustment.reducer;