import React from "react";
import { useHistory } from "react-router-dom";
// @mui/material components
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
// core components
import { bankFormat } from "common/functions";
import styles from "assets/jss/shared-components/bank.module.scss";

export default function AlarmBank(props) {
  let history = useHistory();

  const noOfBays = () => {
    let element = [];
    for (let i = 1; i <= parseInt(props.bays); i++) {
      element.push(
        <TableCell 
          key={i} 
          className={styles.cell} 
          onClick={()=>props.onCellClick({level: props.row, bay: i})} 
        >
          <div className="flex flex-wrap">
            {renderDevice({level: props.row, bay: i})}
          </div>
        </TableCell>
      );
    }
    return element;
  }

  const renderDevice = (value) => {
    const devices = props.data.filter((item) => {
      if (item.cageLocationRefNo) {
        const location = item.cageLocationRefNo.split("-");
        if (location[1] === bankFormat(value.bay) && location[2] === bankFormat(value.level)) {
          return item;
        }
      }
    })
    
    let element = [];
    if (devices) {
      for (let i = 0; i < devices.length; i++) {
        const refNo = devices[i].deviceInfo ? devices[i].deviceInfo.deviceRefNo : devices[i].deviceRefNo
        if (refNo) {
          element.push(
            <div key={i} className={styles.alarmContainer}>
              {devices[i].hasActiveAlarm &&
                <React.Fragment>
                  <div className={styles.alarm}>
                    <Typography className={styles.alarmTitle}>Alarm!</Typography>
                    <Typography className={styles.alarmDetail} onClick={()=>history.push("/user/alarm/details/equipment")}>Click to view details</Typography>
                  </div>
                  <span className={styles.arrow} />
                </React.Fragment>
              }
              <div className={styles.markerDiv}>
                <div className={styles.marker}>{refNo.substr(0, refNo.indexOf('-'))}</div>
                <Typography className={styles.markerText}>{refNo}</Typography>
              </div>
            </div>
          );
        }
      }
    }
    return element;
  }

  return (noOfBays());
}