import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";
import FilterButton from "shared-components/Button/FilterButton";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { checkPermission, formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { maximumOrdersTableHead } from "enums/UserPortal/TableHeaders";
import { setValues, updateRequest } from "../store/maximum";
import { GetProductMaximumOrderPerDay, CreateOrUpdateProductMaximumOrderPerDay, GetProductList } from "services/UserPortal/PlanningService";

import styles from "assets/jss/components/UserPortal/planning.module.scss";
import { useRouteCanWrite } from "hooks";
import { filterActionTableHead } from "common/functions";

export default function MaximumOrders() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const productList = useSelector(store => store.user.planning.maximum.productList);
  const filter = useSelector(store => store.user.planning.maximum.filter);
  const result = useSelector(store => store.user.planning.maximum.orders);
  const editOrder = useSelector(store => store.user.planning.maximum.order);
  const [newFilter, setNewFilter] = React.useState({productId: null});
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productName');
  const [page, setPage] = React.useState(0);
  const [editId, setEditId] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(result.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    if (!editId) {
      dispatch(setValues({filter: {...filter, [key]: null}}));
    }
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id]: Number(e.target.value)}));
  };

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateProductMaximumOrderPerDay())
      .then(() =>{
        setEditId(0);
        getMaximumOrderList();
      })
    }
  };

  const handleButtonClick_cancel = () => {
    setEditId(0);
    setErrorMsg(false)
  };

  const handleButtonClick_edit = (id) => {
    setEditId(id);
    dispatch(updateRequest(result.find((item)=> item.productId === id)));
  };

  const validateFields = () => {
    if (!String(editOrder.bufferPercent)) {
      setErrorMsg({field: "bufferPercent", msg: "Please enter a valid amount"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }
  
  const getMaximumOrderList = (stopLoading) => {
    dispatch(GetProductMaximumOrderPerDay({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getMaximumOrderList();
    }
  },[filter]);

  React.useEffect(() => {
    dispatch(GetProductList());
    getMaximumOrderList();
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    if (!editId) {
      getMaximumOrderList(true);
    }
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        title="Maximum Orders Per Day"
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "productId") {
                const product = productList.find(({id}) => id == filter[key]);
                label = product && product.productName+" ("+product.productId+")";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={styles.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              disabled={editId}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the order list
                  <Select
                    className={styles.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productId})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            header={filterActionTableHead(maximumOrdersTableHead, canWrite)}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(result, [orderBy], [order])
            .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
            .map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.product_Id}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.totalCapacity))}{item.uom}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.totalDaysOfProduction))}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.capacityPerDay))}{item.uom}</TableCell>
                  <TableCell>
                    {editId === item.productId 
                    ? <TextField 
                        id="bufferPercent"
                        variant="outlined" 
                        type="Number"
                        onInput={(e)=>{ 
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
                        }}
                        placeholder="Enter buffer"
                        onChange={(e) => handleOnChange_text(e)}
                        value={editOrder.bufferPercent}
                        errorMsg={errorMsg}
                      />
                    : formatNumbers(roundTo2Decimal(item.bufferPercent))
                    }
                  </TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.grossCapacityPerDay))}{item.uom}</TableCell>
                  <TableCell>
                    {canWrite && (editId === item.productId
                    ? <React.Fragment>
                        <IconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirm()}
                        />
                        <IconButton 
                          type="close"
                          onClick={() => handleButtonClick_cancel()}
                        />
                      </React.Fragment>
                    : <IconButton type="edit" onClick={()=>handleButtonClick_edit(item.productId)} />
                    )
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        {checkPermission("Admin.Capacity", "readEdit") &&
          <Typography className={styles.link}>
            <Link to={{pathname: "/admin/capacity/farm-capacity-per-batch"}}>Link to Total Farm Capacity Per Batch</Link>
          </Typography>
        }
        {checkPermission("Admin.Products", "readEdit") &&
          <Typography className={styles.link}>
            <Link to={{pathname: "/admin/products"}}>Link to Product Manage Growth Portal</Link>
          </Typography>
        }
      </Card>
    </React.Fragment>
  );
}
