import React from "react";
import clsx from "clsx";
// @mui/material components
import Popover from "@mui/material/Popover";
// core components
import styles from "assets/jss/shared-components/popper.module.scss";

export default function CustomPopover({children,  ...props}) {

  return (
    <Popover
      {...props}
      classes={{
        paper: clsx(props.classes.paper, styles.paper)
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </Popover>
  );
}