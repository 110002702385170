import React from "react";
// @mui/material components
import Typography from '@mui/material/Typography';
// @mui/lab
import Pagination from '@mui/material/Pagination';
// core components
import TextField from "shared-components/TextField/TextField";

import styles from "assets/jss/shared-components/table.module.scss";

export default function CustomPagination({...props}) {
  const [text, setText] = React.useState(props.page);
  const [pageNo, setPageNo] = React.useState(props.page);

  const onChangePage = (e, page) => {
    props.onChange(e, page);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    props.onChange(e, text);
  };

  React.useEffect(() => {
    setText(props.page);
    setPageNo(props.page);
  },[props.page]);

  return (
    <div className={styles.paginationDiv}>
      <Pagination 
        {...props}
        page={pageNo}
        className={styles.pagination}
        variant="outlined" 
        shape="rounded" 
        onChange={(e, page)=>onChangePage(e, page)}
        disabled={props.disabled}
      />
      <Typography className={styles.jumpPagination}>Jump To Page:</Typography>

      <form onSubmit={(e) => handleButtonClick(e)}>
        <TextField 
          className={styles.textPagination} 
          type="Number"
          variant="outlined" 
          onInput={(e)=>{ 
            e.target.value = e.target.value > props.count ? props.count : Math.max(0, parseInt(e.target.value)).toString()
          }}
          value={text}
          onChange={(e)=>setText(e.target.value)}
          onBlur={()=>setText(pageNo)}
          disabled={props.disabled}
        />
      </form>
    </div>
  );
}