import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";
import Popper from "shared-components/Popper/Popper";
import Checkbox from "shared-components/Checkbox/Checkbox";

import { formatNumbers, roundTo2Decimal, filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { setValues, reset } from "../store/search";
import { updateRequest } from "../store/issue";
import { setSearchText } from "store/general";
import { rmIssueTableHead } from "enums/UserPortal/TableHeaders";
import { SearchRawMaterialReservedOrIssued, BatchRawMaterialIssue } from "services/UserPortal/RawMaterialService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/rawMaterial.module.scss";

import { useRouteCanWrite } from "hooks";

export default function SearchIssuedResult() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.rawMaterial.search.issuedTotalCount);
  const results = useSelector(store => store.user.rawMaterial.search.issuedResults);
  const issuedId = useSelector(store => store.user.rawMaterial.issueRM.issuedId);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleButtonClick_checkbox = (e) => {
    let payload = _.cloneDeep(issuedId);
    if (payload.includes(e.target.id)) {
      payload.splice(payload.indexOf(e.target.id), 1);
    } else {
      payload.push(e.target.id);
    }
    dispatch(updateRequest(payload));
  };

  const handleButtonClick_save = () => {
    dispatch(BatchRawMaterialIssue())
    .then(({error}) => {
      if (!error) {
        setPage(0);
        dispatch(SearchRawMaterialReservedOrIssued({keyword: params.keyword, page}))
        .then((response) => {
          if (response.payload.result) {
            dispatch(setValues({issuedTotalCount: response.payload.result.totalCount, issuedResults: response.payload.result.items}));
          }
        });
        dispatch(updateRequest([]));
      }
    });
  };

  React.useEffect(() => {
    dispatch(SearchRawMaterialReservedOrIssued({keyword: params.keyword, page, stopLoading: Boolean(page)}))
    .then((response) => {
      dispatch(setValues({issuedTotalCount: response.payload.result.totalCount, issuedResults: response.payload.result.items}))
    });
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={styles.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: styles.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Issue List"}
                <IconButton 
                  className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
            cardActions={ !collapsed &&
              <React.Fragment>
                <Button disabled/>
                <div className={styles.issueAction}>
                  <Button
                    className={styles.buttonSecondary}
                    disabled
                  >
                    Scan RFID Tags
                  </Button>
                  {canWrite &&
                    <Button
                      className={styles.button}
                      onClick={()=>handleButtonClick_save()}
                      disabled={issuedId && issuedId.length === 0}
                    >
                      Save
                    </Button>
                  }
                </div>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={styles.table}>
                  <Table
                    header={filterActionTableHead(rmIssueTableHead, canWrite)}
                  >
                    { _.map(results, (item,index) => {
                      var regExp = /\(([^)]+)\)/;
                      var rackRefNo = regExp.exec(item.location);
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{highlightValue(item.workOrderNumber)}</TableCell>
                            <TableCell>{highlightValue(item.rawMaterialName)}</TableCell>
                            <TableCell>{highlightValue(item.rawMaterialRefNo)}</TableCell>
                            <TableCell>{highlightValue(moment(item.creationDate).format("DD/MM/yyyy HH:mm"))}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.qtyReserve)))}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.currentQty)))}</TableCell>
                            <TableCell>{highlightValue(item.uom)}</TableCell>
                            <TableCell>{highlightValue(moment(item.reserveDate).format("DD/MM/yyyy HH:mm"))}</TableCell>
                            <TableCell>{highlightValue(item.productName)}</TableCell>
                            <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                            <TableCell>{highlightValue(item.productRefNo)}</TableCell>
                            <TableCell>{highlightValue(item.unitCost.toFixed(4))}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.lossRatePercent)))}</TableCell>
                            <TableCell>{item.location && item.location.split('(')[0]}{rackRefNo && " ("}{rackRefNo && <ViewMoreModal list={rackRefNo[1]} title="Rack Reference Number" />}{rackRefNo && ")"}</TableCell>
                            <TableCell>{highlightValue(item.status)}</TableCell>
                            <TableCell>{highlightValue(item.issuedUserId ? moment(item.issuedTimeStamp).format("DD/MM/YYYY HH:mm") : "")}</TableCell>
                            <TableCell>{highlightValue(item.issuedUser)}</TableCell>
                            <TableCell align="center">
                              <Popper
                                noButton
                                open={openPopper === index}
                                className={styles.popper}
                                closePopper={() => setOpenPopper(false)}
                                placement="left"
                                content="Unable to issue Raw Materials due to inadequate quantity."
                              >
                                <div 
                                  onMouseOver={()=>item.unableToIssue && setOpenPopper(index)} 
                                  onMouseOut={()=>setOpenPopper(false)}
                                >
                                  <Checkbox 
                                    id={String(item.id)}
                                    checked={Boolean(item.issuedUser || issuedId.includes(String(item.id)))}
                                    onChange={(e) => handleButtonClick_checkbox(e)}
                                    disabled={Boolean(item.unableToIssue || item.issuedUser || !canWrite)}
                                  />
                                </div>
                              </Popper>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
