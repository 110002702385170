import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import { setValues } from "../store/planningOutcome";
import { unassignedOrdersTableHead } from "enums/UserPortal/TableHeaders";
import { GetWorkOrderPlanningOutcomeList } from "services/UserPortal/PlanningService";

import styles from "assets/jss/components/UserPortal/planning.module.scss";

export default function Unassigned() {
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.planning.planningOutcome.filter);
  const totalCount = useSelector(store => store.user.planning.planningOutcome.unassignedTotalCount);
  const unassigned = useSelector(store => store.user.planning.planningOutcome.unassigned);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('purchaseOrderNumber');
  const [page, setPage] = React.useState(0);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const getPlanningOutcomeList = (pageNo, stopLoading) => {
    if (filter.workOrderId) {
      dispatch(GetWorkOrderPlanningOutcomeList({...filter, page: pageNo, stopLoading}))
      .then((response) => {
        if (!response.error) {
          const result = response.payload.result.unassignedOrdersList;
          if (result) {
            dispatch(setValues({unassignedTotalCount: result.totalCount, unassigned: result.items}));
          } else {
            dispatch(setValues({unassignedTotalCount: 0, unassigned: []}));
          }
        }
      });
    } else {
      dispatch(setValues({unassignedTotalCount: 0, unassigned: []}));
    }
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      if (page === 0) {
        getPlanningOutcomeList(0);
      } else {
        setPage(0);
      }
    }
  },[filter]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getPlanningOutcomeList(page);
    }
  },[page]);

  useInterval(() => {
    getPlanningOutcomeList(page, true);
  });

  return (
    <React.Fragment>
      {totalCount
      ? <Card 
          classes={{root: styles.unassignedCard}}
          title="Unassigned Orders"
        >
          <div className={styles.table}>
            <Table
              header={unassignedOrdersTableHead.filter((head, index) => {
                if (index <= 7) {
                  if (index === 7) {
                    head.label="Reason";
                  }
                  return head;
                }
              })}
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
            >
              { unassigned.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.purchaseOrderNumber}</TableCell>
                    <TableCell>{item.subSaleOrderNumber}</TableCell>
                    <TableCell>{moment(item.deliveryDate).isAfter(minDate) ? moment(item.deliveryDate).format("DD/MM/yyyy") : ""}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.productId}</TableCell>
                    <TableCell>{item.productComponent}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.customerOrderWeight))}{item.uom}</TableCell>
                    <TableCell>{item.status}</TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </div>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
        </Card>
      : null
      }
    </React.Fragment>
  );
}
