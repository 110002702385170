import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import {FomsMS} from "../index";

// Lookup
export const GetFrequencyList = createAsyncThunk(
  "Operation/getFrequencyList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Operation/GetFrequencyList", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllUnitOfMeasurement = createAsyncThunk(
  "Operation/getAllUnitOfMeasurement", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Operation/GetAllUnitOfMeasurement", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWarehouseRackByWarehouseId = createAsyncThunk(
	"Operation/GetWarehouseRackByWarehouseId", 
	async (param, {rejectWithValue}) => {
    const params = {
      warehouseId: param.warehouseId,
      warehouseRackID: param.rackID,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    }
		try {
			const response = await FomsMS.get("/userPortal/Operation/GetWarehouseRackByWarehouseId", {params, stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const GetTimeToRunList = createAsyncThunk(
  "Operation/getTimeToRunList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Operation/GetTimeToRunList", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWorkOrderList = createAsyncThunk(
  "Operation/getWorkOrderList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetWorkOrderList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Process Overview
export const GetOperationTimeLineByWorkOrderId = createAsyncThunk(
  "Operation/getOperationTimeLineByWorkOrderId", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetOperationTimeLineByWorkOrderId", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProcessListForCheckOutAndRemoveCages = createAsyncThunk(
  "Operation/getProcessListForCheckOutAndRemoveCages", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetProcessListForCheckOutAndRemoveCages", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CalculateTimeDuration = createAsyncThunk(
  "Operation/calculateTimeDuration", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const params = {
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/CalculateTimeDuration", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Overall Tasks
export const GetOperationTaskDetail = createAsyncThunk(
  "Operation/getOperationTaskDetail", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetOperationTaskDetail", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetOperationSubTaskDetail = createAsyncThunk(
  "Operation/getOperationSubTaskDetail", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetOperationSubTaskDetail", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWorkOrderListByLoginUser = createAsyncThunk(
  "Operation/getWorkOrderListByLoginUser", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetWorkOrderListByLoginUser", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductListByWorkOrderId = createAsyncThunk(
  "Operation/getProductListByWorkOrderId", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetProductListByWorkOrderId", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductListByMixProductId = createAsyncThunk(
  "Operation/getProductListByMixProductId", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      ...param
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetProductListByMixProductId", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const IsValidDeviceRefNo = createAsyncThunk(
  "Operation/isValidDeviceRefNo", 
  async (param, {rejectWithValue}) => {
    const params = {...param};
    try {
      const response = await FomsMS.get("/userportal/Operation/IsValidDeviceRefNo", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateOperationBatchOpenDate = createAsyncThunk(
  "Operation/updateOperationBatchOpenDate", 
  async (params, {rejectWithValue}) => {
    try {
      const response = await FomsMS.put("/userportal/Operation/UpdateOperationBatchOpenDate", null, {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Your Tasks
export const UpdateTaskTime = createAsyncThunk(
  "Operation/updateTaskTime", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/Operation/UpdateTaskTime", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SetCompleteTask = createAsyncThunk(
  "Operation/setCompleteTask", 
  async (taskId, {rejectWithValue}) => {
    const params = {
      taskId
    };
    try {
      const response = await FomsMS.post("/userportal/Operation/SetCompleteTask", null, {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SetCheckoutProcess = createAsyncThunk(
  "Operation/setCheckoutProcess", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/Operation/SetCheckoutProcess", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SetRetrievalProcess = createAsyncThunk(
  "Operation/setRetrievalProcess", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/Operation/SetRetrievalProcess", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateOperationStorage = createAsyncThunk(
  "Operation/createOrUpdateOperationStorage", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/Operation/CreateOrUpdateOperationStorage", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateQCDetails = createAsyncThunk(
  "Operation/createOrUpdateQCDetails", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/Operation/CreateOrUpdateQCDetails", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateOperationSubTaskList = createAsyncThunk(
  "Operation/updateOperationSubTaskList", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/Operation/UpdateOperationSubTaskList", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteSubTask = createAsyncThunk(
  "Operation/deleteSubTask", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/userportal/Operation/DeleteSubTask", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Settings
export const GetOperationConfiguration = createAsyncThunk(
  "Operation/getOperationConfiguration", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetOperationConfiguration", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateOperationConfiguration = createAsyncThunk(
  "Operation/createOrUpdateOperationStorage", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.operations.settings.setting;
    try {
      const response = await FomsMS.post("/userportal/Operation/CreateOrUpdateOperationConfiguration", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//progress
export const GetOperationProcessProgress = createAsyncThunk(
  "Operation/GetOperationProcessProgress", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetOperationProcessProgress", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCageIDList = createAsyncThunk(
  "Operation/GetCageIDList", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetCageIDList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SubmitEndOrder = createAsyncThunk(
  "Operation/SubmitEndOrder", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/Operation/SubmitEndOrder", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetHarvestingDashboard = createAsyncThunk(
  "Operation/GetHarvestingDashboard", 
  async (id, {rejectWithValue}) => {
    const params = {
      id: id
    };
    try {
      const response = await FomsMS.get("/userportal/Operation/GetHarvestingDashboard", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);