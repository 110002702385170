/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateRequest, resetRequest } from "../store/request";
import { GetNotYetStartedWorkOrderList } from "services/UserPortal/CommonLookupService";
import { CreateOrUpdateRawMaterialRequest } from "services/UserPortal/RawMaterialService";

import styles from "assets/jss/components/UserPortal/rawMaterial.module.scss";

export default function AddEditRequest() {
  let history = useHistory();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const request = useSelector(store => store.user.rawMaterial.requestRM.request);
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfo);
  const workOrderList = useSelector(store => store.common.unstartedWorkOrderList);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.id] : Number(e.target.value)}));
  };

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleCreationDateChange = (date) => {
    if(date && date != "Invalid Date") {
      dispatch(setDirty(true));
      dispatch(updateRequest({["estDeliveryDate"] : moment(date).format("YYYY-MM-DD")}));
    } else {
      dispatch(updateRequest({["estDeliveryDate"] : date}));
    }
  };

  const handleButtonClick_cancel = () => {
    dispatch(resetRequest());
    history.goBack();
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([
        dispatch(setDirty(false))
      ])
      .then(() => {
        dispatch(CreateOrUpdateRawMaterialRequest(request))
        .then(({error})=>{
          if (error) {
            dispatch(setDirty(true));
          } else {
            dispatch(resetRequest());
            history.push("/user/raw-material/receipt");
          }
        });
      })
    }
  };

  const validateFields = () => {
    if (!request.rawMaterialId) {
      setErrorMsg({field: "rawMaterialId", msg: "Please select a Raw Material"});
      return false;
    }
    if (!moment(request.estDeliveryDate).isValid()) {
      setErrorMsg({field: "estDeliveryDate", msg: "Invalid date"});
      return false;
    }
    if (!request.qtyRequest) {
      setErrorMsg({field: "qtyRequest", msg: "Please enter quantity"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(GetNotYetStartedWorkOrderList());
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetNav());
    }
  },[]);

  const selectedRawMaterial = _.find(rawMaterialList, ({id}) => id === request.rawMaterialId);
  return (
    <React.Fragment>
      <Card 
        title="Request List for Raw Materials"
      >
        <Paper className={styles.paper} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={styles.label}>
              <Typography>RM Name <span className={styles.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="rawMaterialId"
                onChange={(e)=>handleOnChange_select(e)}
                placeholder="Please select a Raw Material"
                value={renderValue(request.rawMaterialId)}
                errorMsg={errorMsg}
              >
                {rawMaterialList && rawMaterialList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.rawMaterialName}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Delivered by <span className={styles.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <DateTimePicker
                className={styles.datepicker}
                name="estDeliveryDate"
                value={request.estDeliveryDate ? request.estDeliveryDate : moment().format("YYYY-MM-DD")}
                minDate={moment().format("YYYY-MM-DD")}
                onChange={handleCreationDateChange}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>Quantity <span className={styles.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField 
                id="qtyRequest"
                variant="outlined"
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Eg. 100"
                type="Number"
                value={renderValue(request.qtyRequest)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="uom"
                variant="outlined" 
                value={selectedRawMaterial ? selectedRawMaterial.uom : ""}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={styles.label}>
              <Typography>WO Number:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="workOrderId"
                onChange={(e)=>handleOnChange_select(e)}
                placeholder="Select a work order"
                value={renderValue(request.workOrderId)}
                errorMsg={errorMsg}
              >
                {workOrderList && workOrderList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                })} 
              </Select>
            </Grid>
          </Grid>
          <div className={styles.action}>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>handleButtonClick_cancel()}
            >
              Cancel
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
              disabled={!isDirty}
            >
              Save
            </Button>
          </div>
        </Paper>
      </Card>
    </React.Fragment>
  );
}
