import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import Typography from "@mui/material/Typography";

import { formatNumbers } from "common/functions";
import { reset } from "../store/search";
import { setSearchText } from "store/general";

import styles from "assets/jss/components/UserPortal/qualityControl.module.scss";
import SearchGerminationResult from "./SearchGerminationResult";
import SearchVegetableResult from "./SearchVegetableResult";

export default function SearchResult() {
  const dispatch = useDispatch();
  //count
  const germinationTotalCount = useSelector(store => store.user.qualityControl.search.germinationTotalCount);
  const vegetableTotalCount = useSelector(store => store.user.qualityControl.search.vegetableTotalCount);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={styles.result}>{formatNumbers(germinationTotalCount + vegetableTotalCount) + " Search Result(s) From 2 Tables:  ‘" + params.keyword + "’"}</Typography>
      <SearchGerminationResult />
      <SearchVegetableResult />
    </React.Fragment>
  );
}
