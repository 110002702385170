import { configureStore } from '@reduxjs/toolkit';
import generalReducer from './general';
import strapiReducer from './strapi';
import lookupReducer from './lookup';
import commonReducer from './common';
import settingsReducer from '../components/UserSettings';
import navbarReducer from 'shared-components/Navbars';
import credentialsReducer from 'components/Authentication/store';
import adminReducer from './admin';
import userReducer from './user';

const store = configureStore({
  reducer: {
    general: generalReducer,
    strapi: strapiReducer,
    common: commonReducer,
    lookup: lookupReducer,
    navbar: navbarReducer,
    settings: settingsReducer,
    credentials: credentialsReducer,
    admin: adminReducer,
    user: userReducer,
  }
})

export default store