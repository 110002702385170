import React from "react";
import { useSelector } from "react-redux";

import stop from "assets/img/stop.svg";
import stopComplete from "assets/img/stop-complete.svg";
import truck from "assets/img/truck.svg";
import styles from "assets/jss/components/UserPortal/delivery.module.scss";

const DetailMaps = (props) => {
  let google = window.google;
  const mapRef = React.useRef(null);
  const startPoint = useSelector(store => store.user.delivery.assignment.startPoint);
  const [map, setMap] = React.useState();
  const [preserveViewport, setPreserveViewport] = React.useState(false);
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    return () => {
      setMap();
      setPreserveViewport(false)
    }
  }, [])

  React.useEffect(() => {
    if (mapRef.current) {
      let lat = startPoint.latitude;
      let lng = startPoint.longitude;
      const defaultLatLng = new google.maps.LatLng(lat, lng);
      const mapOptions = {
          zoom: 11,
          center: defaultLatLng,
          zoomControl: true,
        };
      setMap(new window.google.maps.Map(mapRef.current, mapOptions));
    }
  }, [mapRef, startPoint])

  React.useEffect(() => {
    if (props.status === "In Progress") {
      let google = window.google;
      const infoWindow = new google.maps.InfoWindow();
      const trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(map);

      const locationMarker = new google.maps.Marker({
        map: map,
        title: location.value,
        icon: truck,
      });
      google.maps.event.addListener(locationMarker, 'mouseover', ((marker) => {
        return () => {
          infoWindow.setContent("<div class="+styles.infoWindow+">"+props.driverName+"<br />"+props.deliveryTripRefNo+"</div>");
          infoWindow.open(map, marker);
        }
      })(locationMarker));
      google.maps.event.addListener(locationMarker, 'mouseout', (() => {
        return () => {
          infoWindow.close()
        };
      })(locationMarker));
      setMarker(locationMarker);
    }
  }, [map, props.status]);

  React.useEffect(() => {
    if (map && props.stops.length) {
      const geocoder = new google.maps.Geocoder();
  
      const start = new google.maps.LatLng(startPoint.latitude, startPoint.longitude);
      let waypts = [];
      for (let i = 0; i < props.stops.length; i++) {
        geocoder.geocode({'address': props.stops[i].endPoint}, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const infoWindow = new google.maps.InfoWindow();
            let marker = new google.maps.Marker({
              position: results[0].geometry.location,
              map: map,
              icon: props.stops[i].deliveryOrder && props.stops[i].deliveryOrder.deliveryOrderStatus.status === "Delivered" ? stopComplete : stop,
            });
            infoWindow.setContent("<div class="+styles.infoWindow+">Stop "+(i+1)+"</div>");
            infoWindow.open(map, marker);
          }
        });
        waypts.push({
          location: props.stops[i].endPoint,
          stopover: true,
        });
      }
      //Starting point
      const infoWindow = new google.maps.InfoWindow();
      let marker = new google.maps.Marker({
        map: map,
        title: location.value,
        position: start,
        icon: stop,
      });
      google.maps.event.addListener(map, 'tilesloaded', ((marker) => {
        return () => {
          infoWindow.setContent("<div class="+styles.infoWindow+">Starting Point</div>");
          infoWindow.open(map, marker);
        }
      })(marker));
      setPreserveViewport(true);
    }
  }, [map, props.stops]);

  React.useEffect(() => {
    if (marker) {
      marker.setPosition(new google.maps.LatLng(props.location.latitude, props.location.longitude));
    }
  }, [props.location]);

  React.useEffect(() => {
    if (map && props.deliveryRoute) {
      const directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});
  
      directionsRenderer.setMap(map);
      directionsRenderer.setDirections(JSON.parse(props.deliveryRoute));
      directionsRenderer.setOptions({preserveViewport: preserveViewport});
    }
  }, [map, props.deliveryRoute]);

  return (
    <div className={styles.map} ref={mapRef}></div>
  );
};

export default DetailMaps;
