import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import OldDetailForm from "./OldDetailForm";
import UploadImage from "./UploadImage";
import { setDirty, setSearchText } from "store/general";
import { GetBoardType, GetNutrientCategory, GetRandDReportDetail } from "services/UserPortal/QualityControlService"
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues } from "./store/vegetable";
import DetailForm from "./DetailForm";

export default function AddEditQualityControl() {
  const dispatch = useDispatch();
  let history = useHistory();
  const qualityControl = useSelector(store => store.user.rnd.vegetable.qualityControl);
  const [step, setStep] = React.useState(0);
  const [hcImages, setHcImages] = React.useState([]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    dispatch(GetBoardType());
    dispatch(GetNutrientCategory());
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "r&d");
    dispatch(GetRandDReportDetail({id: path[index+1]}))
    .then(({error}) => {
      if (error) {
      history.push("/user/r&d");
      } else {
        dispatch(setDirty(true))
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(setValues({qualityControl: {healthConditions: []}, totalHorizontal: 0, totalVertical: 0}));
      dispatch(resetNav());
    }
  },[]);

  if(qualityControl.isOldRecord) {
    // old records
    switch (step) {
      case 0: 
        return <OldDetailForm setStep={setStep} hcImages={hcImages} setHcImages={setHcImages} />;
      case 1:
        return <UploadImage setStep={setStep} hcImages={hcImages} setHcImages={setHcImages} />;
    }
  } else {
    // new records
    switch (step) {
      case 0: 
        return <DetailForm setStep={setStep} hcImages={hcImages} setHcImages={setHcImages} />;
      case 1:
        return <UploadImage setStep={setStep} hcImages={hcImages} setHcImages={setHcImages} />;
    }
  }
}
