import { combineReducers } from "redux";
import homeReducer from '../components/UserPortal/Home';
import customerOrderReducer from '../components/UserPortal/CustomerOrder/store';
import planningReducer from '../components/UserPortal/Planning/store';
import capacityReducer from '../components/UserPortal/Capacity/store';
import rawMaterialReducer from '../components/UserPortal/RawMaterial/store';
import operationsReducer from '../components/UserPortal/Operations/store';
import deliveryReducer from '../components/UserPortal/Delivery/store';
import monitoringReducer from '../components/UserPortal/Monitoring/store';
import warehouseReducer from '../components/UserPortal/Warehouse/store';
import finishedGoodsReducer from '../components/UserPortal/FinishedGoods/store';
import climateReducer from '../components/UserPortal/Climate/store';
import hrReducer from '../components/UserPortal/HumanResource/store';
import equipmentReducer from '../components/UserPortal/Equipment/store';
import costProductivityReducer from '../components/UserPortal/CostProductivity/store';
import qualityControlReducer from '../components/UserPortal/QualityControl/store';
import rndReducer from '../components/UserPortal/R&D/store';
import reportReducer from '../components/UserPortal/Report/store';
import traceabilityReducer from '../components/UserPortal/Traceability';
import alarmReducer from '../components/UserPortal/Alarm';
import codeGeneratorReducer from '../components/UserPortal/CodeGenerator';
import predictionReducer from '../components/UserPortal/Prediction';
import smartMeteringReducer from "../components/UserPortal/SmartMetering/store";
import ASRSReducer from "../components/UserPortal/ASRS/store";
import AMRReducer from "../components/UserPortal/AMR/store";
import flowControlReducer from '../components/UserPortal/FlowControl/store';
import sustainabilityReducer from '../components/UserPortal/Sustainability/store';

const combinedReducer = combineReducers({
  home: homeReducer,
  customerOrder: customerOrderReducer,
  planning: planningReducer,
  capacity: capacityReducer,
  rawMaterial: rawMaterialReducer,
  operations: operationsReducer,
  warehouse: warehouseReducer,
  finishedGoods: finishedGoodsReducer,
  delivery: deliveryReducer,
  monitoring: monitoringReducer,
  climate: climateReducer,
  hr: hrReducer,
  equipment: equipmentReducer,
  costProductivity: costProductivityReducer,
  qualityControl: qualityControlReducer,
  rnd: rndReducer,
  report: reportReducer,
  traceability: traceabilityReducer,
  alarm: alarmReducer,
  codeGenerator: codeGeneratorReducer,
  prediction: predictionReducer,
  smartMetering: smartMeteringReducer,
  asrs: ASRSReducer,
  amr: AMRReducer,
  flowControl: flowControlReducer,
  sustainability: sustainabilityReducer,
})

const userReducer = (state, action) => {
  if (action.type === 'reset') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default userReducer;