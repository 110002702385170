import { createSlice } from "@reduxjs/toolkit";
import { GetAllRoles, GetAllModules, GetRoleData, SearchRole } from "services/AdminPortal/RoleService";
import _ from "lodash";

// initial state
const initialState = {
  totalCount: 0,
  roles: [],
  role: {},
  searchResultsCount: 0,
  searchResults: [],
};

// create reducer and action creators
const role = createSlice({
  name: "role",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.role = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.role[key] = action.payload[key];
      })
    },
    updateModuleRequest: (state, action) => {
      const index = _.findIndex(state.role.module, ['name', action.payload.name]);
      state.role.module[index] = {name: action.payload.name, value: action.payload.value}
    },
    resetRole: (state) => {
      state.role = {};
    },
    resetSearch: (state) => {
      state.searchResultsCount = 0;
      state.searchResults = [];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRoles.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.roles = action.payload.result.items;
      })
      .addCase(GetAllModules.fulfilled, (state, action) => {
        state.role.module = action.payload.result;
      })
      .addCase(GetRoleData.fulfilled, (state, action) => {
        state.role = _.pick(action.payload.result, ["id", "name", "description", "module"]);
      })
      .addCase(SearchRole.fulfilled, (state, action) => {
        state.searchResultsCount = action.payload.result.roleList.totalCount;
        state.searchResults = action.payload.result.roleList.items;
      })
  },
});

export const { updateRequest, updateModuleRequest, setRoleValues, resetRole, resetSearch, reset } = role.actions;

// export the reducer
export default role.reducer;