import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';

import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { renderValue, filterParam } from "common/functions";
import { setSearchText } from "store/general";
import { setValues } from "../store/maintenance";
import { equipmentStatusTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllEquipmentStatus, UpdateStatusEquipmentMaintenance } from "services/UserPortal/EquipmentService";

import calendar from "assets/icons/orange/calendar.svg";
import use from "assets/icons/others/in-use.svg";
import maintenance from "assets/icons/orange/in-maintenance.svg";
import down from "assets/icons/others/is-down.svg";
import use_selected from "assets/icons/white/in-use.svg";
import maintenance_selected from "assets/icons/white/in-maintenance.svg";
import down_selected from "assets/icons/white/is-down.svg";
import styles from "assets/jss/components/UserPortal/equipment.module.scss";
import { checkPermission } from "common/functions";

import { useRouteCanWrite } from "hooks";

export default function EquipmentStatus() {
  let history = useHistory();
  const canWrite = useRouteCanWrite();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.equipment.maintenance.filter);
  const totalCount = useSelector(store => store.user.equipment.maintenance.totalStatusCount);
  const currentDateTime = useSelector(store => store.user.equipment.maintenance.currentDateTime);
  const status = useSelector(store => store.user.equipment.maintenance.status);
  const equipmentList = useSelector(store => store.common.equipmentList);
  const [newFilter, setNewFilter] = React.useState({equipmentId: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('equipmentRefNo');
  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_select = (e) => {
    if (e.target.name === "equipmentId" && e.target.value === "all") {
      setNewFilter({...newFilter, [e.target.name]: null});
    } else {
      setNewFilter({...newFilter, [e.target.name]: e.target.value});
    }
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const handleButtonClick_icon = (param) => {
    dispatch(UpdateStatusEquipmentMaintenance(param))
    .then(() => {
      getAllEquipmentStatus();
    });
  };

  const getAllEquipmentStatus = (stopLoading) => {
    dispatch(GetAllEquipmentStatus({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllEquipmentStatus();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllEquipmentStatus(true);
  });

  const renderIcon = (currentStatus, equipmentId, id, equipment) => {
    const status = ["In Operation", "In Maintenance", "Is Down"];
    const boolean = ["isOperation", "isMaintenance", "isDown"];
    const icon = [use, maintenance, down];
    const icon_selected = [use_selected, maintenance_selected, down_selected];
    let component = [];
    for (let i = 0; i < status.length; i++) {
      component.push(
        <Button 
          key={i}
          className={currentStatus === status[i] ? clsx(styles.statusButton, styles.selectedStatusButton) : styles.statusButton}
          onClick={()=>{ i === 1
            ? history.push({pathname: "/user/equipment/maintenance-status/"+(id ? id : "create"), state: {equipment: equipment, isDisabled: true, id: id}})
            : handleButtonClick_icon({...Object.assign(...boolean.map(k=>({[k]:false}))), equipmentConfigId: equipmentId, [boolean[i]]: true, id})
          }}
          disabled={(currentStatus === status[i]) || !canWrite}
        >
          {currentStatus === status[i]
          ? <img className={styles.icon} src={icon_selected[i]} alt="status" />
          : <img className={styles.icon} src={icon[i]} alt="status" />
          }
        </Button>
      )
    }
    return component
  }

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        classes={{root: styles.cardPaper}}
        title={"Information Correct as at " + moment(currentDateTime).format("DD/MM/yyyy - HH:mm")}
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "equipmentId") {
                const equipment = equipmentList.find(({id}) => id == filter[key]);
                label = equipment && equipment.equipmentName;
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={styles.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the equipment list
                  <Select
                    name="equipmentId"
                    className={styles.filterDropdown}
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select an equipment"
                    value={renderValue(newFilter.equipmentId)}
                  >
                    { equipmentList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.equipmentName}</MenuItem>;
                    })}
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            header={equipmentStatusTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { status.map((item,index) => {
              const equipment = _.find(equipmentList, ({id}) => id === item.equipmentConfigId);
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell className={styles.cellWidth}>{item.equipmentRefNo}</TableCell>
                    <TableCell className={styles.cellWidth}>{item.equipmentName}</TableCell>
                    <TableCell>{item.operationHourFromLastMaintenance}</TableCell>
                    <TableCell>{moment(item.lastMaintenanceDate).isAfter(minDate) ? moment(item.lastMaintenanceDate).format("DD/MM/yyyy") : ""}</TableCell>
                    <TableCell>{item.hoursLimitToScheduleForMaintenance}</TableCell>
                    <TableCell>
                      {(moment(item.lastMaintenanceDate).isAfter(minDate) && moment(item.expectedDateToRequireMaintenance).isAfter(minDate)) 
                      ? moment(item.expectedDateToRequireMaintenance).format("DD/MM/yyyy") 
                      : ""
                      }
                    </TableCell>
                    <TableCell className={styles.statusCell}>{item.status}</TableCell>
                    <TableCell>
                      <div className={styles.actionCell}>
                        {renderIcon(item.status, item.equipmentConfigId, item.id, equipment)}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      {item.scheduleForMaintenance 
                      ? item.scheduleForMaintenance
                      : <Link to={!canWrite?"#":{pathname: "/user/equipment/maintenance-status/"+(item.id ? item.id : "create"), state: {equipment: equipment, id: item.id}}}>
                          <IconButton>
                            <img className={styles.icon} src={calendar} alt="calendar"/>
                          </IconButton>
                        </Link>
                      }
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
          </Table>
        </div>
        <div className={styles.legend}>
          <img className={clsx(styles.icon, styles.legendIcon)} src={use} alt="in-operation" /> In Operation
          <img className={clsx(styles.icon, styles.legendIcon)} src={maintenance} alt="in-maintenance" /> In Maintenance
          <img className={clsx(styles.icon, styles.legendIcon)} src={down} alt="is-down" /> Is Down
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
        {checkPermission("Admin.Equipment", "readEdit") &&
          <Typography className={styles.link}>
            <Link to={{pathname: "/admin/equipment"}}>Go to Equipment Module on the Admin Portal to edit the maintenance hours</Link>
          </Typography>
        }
      </Card>
    </React.Fragment>
  );
}
