import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllEquipmentReserve, GetEquipmentReserveById, GetEquipmentList } from "services/UserPortal/EquipmentService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  reservations: [],
  reservation: {},
  equipmentList: []
};

const reserveEquip = createSlice({
  name: "reserveEquip",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.reservation[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllEquipmentReserve.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.reservations = action.payload.result.items;
      })
      .addCase(GetEquipmentReserveById.fulfilled, (state, action) => {
        state.reservation = action.payload.result;
      })
      .addCase(GetEquipmentList.fulfilled, (state, action) => {
        state.equipmentList = _.orderBy(action.payload.result, ["equipmentRefNo", "equipmentName",], ["asc", "asc"]);
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = reserveEquip.actions;

// export the reducer
export default reserveEquip.reducer;