import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import CircularProgress from '@mui/material/CircularProgress';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import CustomIconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";

import { filterActionTableHead } from "common/functions";
import { daysOfWeek } from "enums/Time";
import { minDate, rowsPerTable } from "config";
import { reset } from "../store/search";
import { setValues } from "store/common";
import { setSearchText } from "store/general";
import { deviceControlTableHead, subDeviceControlTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllFloorPlan, SearchDeviceControl, DeleteDeviceControlRule } from "services/UserPortal/ClimateService";
import { GetDeviceList } from "services/UserPortal/CommonLookupService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/climate.module.scss";

import { useRouteCanWrite } from "hooks";

export default function SearchDeviceResult() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const deviceList = useSelector(store => store.common.deviceList);
  const bankList = useSelector(store => store.common.bankList);
  const floorSectorList = useSelector(store => store.user.climate.deviceControl.floorSectorList);
  const totalCount = useSelector(store => store.user.climate.search.deviceTotalCount);
  const results = useSelector(store => store.user.climate.search.deviceResults);
  const [page, setPage] = React.useState(0);
  const [subPage, setSubPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [devicePage, setDevicePage] = React.useState(0); // for device list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for device list
  const [settingId, setSettingId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openMoreModal, setOpenMoreModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const getTab = (item) => {
    if (item.bankId) {
      return bankList.findIndex(({id}) => id === item.bankId);
    } else {
      return floorSectorList.findIndex(({floor,sector}) => floor === item.floorId && sector === item.sectorId);
    }
  }
  
  const fetchMoreData = (search, pageNo) => {
    if (devicePage !== -1 || pageNo !== undefined) {
      setIsLoadingMore(true);
      const param = {
        page: pageNo !== undefined ? pageNo : devicePage,
        DeviceRefNo: search ? search : "",
        IsControlDevice: true
      }
      dispatch(GetDeviceList(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result.item) {
            setDevicePage(pageNo !== undefined ? pageNo+1 : devicePage+1);
            if (search || devicePage === 0 || pageNo === 0) {
              dispatch(setValues({deviceList: response.payload.result.items}));
            } else {
              dispatch(setValues({deviceList: _.unionBy(deviceList, response.payload.result.items, "id")}));
            }
          } else {
            setDevicePage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const handleModal_more = () => {
    setOpenMoreModal(!openMoreModal);
  }

  const handleModal_delete = (id) => {
    setSettingId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteDeviceControlRule(settingId))
    .then(() =>{
      dispatch(SearchDeviceControl({keyword: params.keyword, page: 0}));
      setPage(0);
    })
  }

  React.useEffect(() => {
    dispatch(SearchDeviceControl({keyword: params.keyword, page, stopLoading: Boolean(page)}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllFloorPlan());
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={styles.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: styles.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Device Control List"}
                <IconButton 
                  className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={styles.table}>
                  <Table
                    expandable
                    className={styles.mainTable}
                    header={filterActionTableHead(deviceControlTableHead, canWrite)}
                  >
                    { _.map(results, (item,index) => {
                      const subCount = Math.ceil(item.ruleList && item.ruleList.length / rowsPerTable);
                      return (
                        <React.Fragment key={index}>
                          <TableRow 
                            hover
                            sx={{ cursor: 'pointer' }}
                            onClick={() => open === index? setOpen(false) : setOpen(index)}
                          >
                            <TableCell>
                              <IconButton 
                                className={open === index ? styles.rotate : null}
                                onClick={() => open === index ? setOpen(false) : setOpen(index)}
                              >
                                <img className={styles.icon} src={arrow} alt="arrow" />
                              </IconButton>
                            </TableCell>
                            <TableCell>{item.deviceFunctionName}</TableCell>
                            <TableCell>
                              {item.isForAllDeviceFunctionDevices 
                              ? <React.Fragment>
                                  All Devices <span className={styles.link} onClick={handleModal_more}>(View)</span>
                                </React.Fragment>
                              : highlightValue(item.deviceRefNo)
                              }
                            </TableCell>
                            {canWrite &&
                              <TableCell align="right">
                                <Link to={{pathname: "/user/climate/device-control/"+item.id, state: {bankFloorTab: item.bankId ? 0 : 1, tab: getTab(item), deviceRefNo: item.deviceRefNo, prevPath: location.pathname+location.search}}}>
                                  <CustomIconButton type="edit" />
                                </Link>
                                <CustomIconButton 
                                  type="delete"
                                  onClick={() => handleModal_delete(item.id)}
                                />
                              </TableCell>
                            }
                          </TableRow>
                          <TableRow>
                            { open===index && 
                              <TableCell className="pt-0 pb-0" colSpan={7}>
                                <Collapse in={open === index}>
                                  <Table
                                    className={styles.subTable}
                                    header={subDeviceControlTableHead}
                                  >
                                    { item.ruleList && item.ruleList.slice(subPage * rowsPerTable, subPage * rowsPerTable + rowsPerTable)
                                    .map((subItem, subIndex) => {
                                      return (
                                        <React.Fragment key={subIndex}>
                                          <TableRow>
                                            <TableCell>{('0'+((subPage*rowsPerTable)+1+subIndex)).slice(-2)}</TableCell>
                                            <TableCell>
                                              {highlightValue(daysOfWeek.map((day) => {
                                                if (subItem["is"+day]) {
                                                  return day;
                                                }
                                              }).filter((day)=>day!=undefined).join(", "))}
                                            </TableCell>
                                            <TableCell>{highlightValue(moment(subItem.startDate).format("DD/MM/YYYY"))}</TableCell>
                                            <TableCell>{highlightValue(moment(subItem.endDate).isAfter(minDate) && moment(subItem.endDate).format("DD/MM/YYYY"))}</TableCell>
                                            <TableCell>{highlightValue(subItem.turnOnTime.replace("-", ""))}</TableCell>
                                            <TableCell>{highlightValue(subItem.turnOffTime.replace("-", ""))}</TableCell>
                                          </TableRow>
                                        </React.Fragment>
                                      )
                                    })}
                                  </Table>
                                  { subCount > 1 &&
                                    <Pagination 
                                      count={subCount} 
                                      page={subPage+1}
                                      onChange={(e, pageNo)=>setSubPage(pageNo-1)}
                                    />
                                  }
                                </Collapse>
                              </TableCell>
                            }
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
      <Modal
        open={openModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this device control? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openMoreModal}
        onClose={() => handleModal_more()}
        title="All Devices"
        content={
          deviceList &&
          <div className={styles.moreList} id="scrollableDiv">
            <InfiniteScroll
              dataLength={devicePage*10}
              next={()=>fetchMoreData()}
              hasMore={true}
              loader={ isLoadingMore && 
                <div className={"w-full text-center"}>
                  <CircularProgress className={styles.loading} size={"1.250vw"} />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              {deviceList.map((item, index) => (
                <ListItem key={index}>{item.deviceRefNo}</ListItem>
              ))}
            </InfiniteScroll>
          </div>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_more()}>Close</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
