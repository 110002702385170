
import { createAsyncThunk } from "@reduxjs/toolkit";

import * as Auth from "auth/Authenticate";
import {FomsMS} from "./index";

export const Authenticate = createAsyncThunk(
  "auth/authenticate", 
  async (_, {getState, rejectWithValue}) => {
    const credentials = getState().credentials.login;
    const body = {
      ...credentials,
      password: credentials.password && Buffer.from(credentials.password).toString('base64')
    }
    try {
      const response = await FomsMS.post("/TokenAuth/Authenticate", body);
      Auth.login(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GoogleLogin = createAsyncThunk(
  "auth/googleLogin", 
  async (param, {rejectWithValue}) => {
    const body = {
      ...param,
      provider: "Google"
    };
    try {
      const response = await FomsMS.post("/TokenAuth/ExternalAuthenticate", body);
      Auth.login(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const LogOut = createAsyncThunk(
  "auth/logout", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/TokenAuth/LogOut");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);