import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Drawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";
// @mui/icons-material
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// core components
import Card from "shared-components/Card/Card";
import Pagination from "shared-components/Table/Pagination";

import { useInterval } from 'common/utils';
import { setValues } from "../store/parameters";
import { GetAllEnergyMonitoring, GetAllEnergyUnit } from "services/UserPortal/SmartMeteringService";

import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";
import LineChart from "./LineChart2";
import ParamList from "./ParamList";
import EnergyConsumption from "./EnergyConsumption";
import GraphScale from "./GraphScale";

export default function Parameters() {
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.smartMetering.parameters.filter);
  const result = useSelector(store => store.user.smartMetering.parameters.result);
  const unitList = useSelector(store => store.user.smartMetering.parameters.unitList);
  const [aggregationScale, setAggregationScale] = React.useState({label: '10 Minutes', value: ''});
  const [selected, setSelected] = React.useState([]);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [dashboardResult, setDashboardResult] = React.useState({});
  const [dashboardResult2, setDashboardResult2] = React.useState({});
  const [open, setOpen] = React.useState(true);

  const rowsPerTable = 2;
  const count = Math.ceil(unitList.length / rowsPerTable);
  const controller = new AbortController();

  const onClickDetails = (item) => {
    setViewDetails(item);
  }

  const getEnergyMonitoring = (stopLoading) => {
    setIsLoading(!stopLoading);
    if ((filter.address && filter.address.length) || (filter.deviceName && filter.deviceName.length)) {
      dispatch(GetAllEnergyMonitoring({...filter, unit: viewDetails.unit, aggregationScale: moment(filter.endDate).diff(moment(filter.startDate), 'hours') <= 24 ? aggregationScale.value : '', stopLoading: true, signal: controller.signal}));
    } else {
      dispatch(setValues({result: []}));
    }
  }

  React.useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);
    setDashboardResult({});
    setDashboardResult2({});
    if (!viewDetails) {
      unitList.slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable).map((item, index) => {
        if ((filter.address && filter.address.length) || (filter.deviceName && filter.deviceName.length)) {
          dispatch(GetAllEnergyMonitoring({...filter, unit: item.unit, stopLoading: true, signal: controller.signal}))
          .then((response) => {
            if (!response.error) {
              setIsLoading(false);
              if (index % 2 === 0) {
                setDashboardResult(response.payload.result);
              } else {
                setDashboardResult2(response.payload.result);
              }
            }
          });
        }
      });
    }
    // cleanup controller
    return () => {
      controller.abort();
    };
  },[page, filter]);

  React.useEffect(() => {
    if (selected[0] && typeof selected[0] === "number") {
      dispatch(setValues({filter: {...filter, address: selected, deviceName: []}}));
    } else {
      dispatch(setValues({filter: {...filter, deviceName: selected, address: []}}));
    }
  },[selected]);

  React.useEffect(() => {
    if (viewDetails) {
      getEnergyMonitoring();
    }
    // cleanup controller
    return () => {
      controller.abort();
    };
  },[viewDetails, filter, aggregationScale]);
  
  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllEnergyUnit());
  },[]);
  
  useInterval(() => {
    if (viewDetails) {
      getEnergyMonitoring(true);
    }
  });

  return (    
    <React.Fragment>
      <div className={styles.graphContent}>
        <IconButton
          className={styles.formIconButton2}
          onClick={()=>setOpen(!open)}
        >
          {!open ? <KeyboardArrowRightIcon className={styles.icon_24} /> : <KeyboardArrowLeftIcon className={styles.icon_24} />}
        </IconButton>
        <Drawer
          sx={{
            // width: drawerWidth,
            // flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: "30%",
              height: "33vw",
              position: 'absolute',
              padding: "1.563vw",
              marginLeft: "-1.979vw",
              border: "0.052vw solid #D9D9D6",
              borderLeft: 0,
              borderBottomRightRadius: "0.833vw",
              overflow: "visible",
              marginTop: "5.125vw",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Paper className={styles.paper2} elevation={0}>
            <ParamList selected={selected} setSelected={setSelected} />
          </Paper>
          <IconButton
            className={styles.formIconButton}
            onClick={()=>setOpen(!open)}
          >
            {!open ? <KeyboardArrowRightIcon className={styles.icon_24} /> : <KeyboardArrowLeftIcon className={styles.icon_24} />}
          </IconButton>
        </Drawer>
        <div className={styles.highChart}>
          <div className={styles.topComponent}>
            <EnergyConsumption />
            {viewDetails && <GraphScale filter={filter} setValues={setValues} aggregationScale={aggregationScale} setAggregationScale={setAggregationScale} />}
          </div>
          {viewDetails
          ? <Grid container spacing={4}>
              <Grid item xs={4}>
                <Card 
                  classes={{
                    root: clsx(styles.cardPaper, styles.bottomSpace),
                    header: styles.cardHeader,
                    title: styles.cardTitle,
                    content: styles.cardContent,
                  }}
                  title="Today Average"
                >
                  <Typography className={styles.value}>{result.todayTotalEnergy && (result.todayTotalEnergy.averageVoltage ?? 0) + "V"}</Typography>
                  <Typography>Yesterday Average: {result.yesterdayTotalEnergy && (result.yesterdayTotalEnergy.averageVoltage ?? 0) + "V"}</Typography>
                </Card>
                <Card 
                  classes={{
                    root: clsx(styles.cardPaper, styles.bottomSpace),
                    header: styles.cardHeader,
                    title: styles.cardTitle,
                    content: styles.cardContent,
                  }}
                  title="Today Maximum"
                >
                  <Typography className={styles.value}>{result.todayTotalEnergy && (result.todayTotalEnergy.maxVoltage ?? 0) + "V"}</Typography>
                  <Typography>Yesterday Maximum: {result.yesterdayTotalEnergy && (result.yesterdayTotalEnergy.maxVoltage ?? 0) + "V"}</Typography>
                </Card>
                <Card 
                  classes={{
                    root: styles.cardPaper,
                    header: styles.cardHeader,
                    title: styles.cardTitle,
                    content: styles.cardContent,
                  }}
                  title="Selection"
                  subheader="Today Minimum"
                >
                  <Typography className={styles.value}>{result.todayTotalEnergy && (result.todayTotalEnergy.minVoltage ?? 0) + "V"}</Typography>
                  <Typography>Yesterday Minimum: {result.todayTotalEnergy && (result.todayTotalEnergy.minVoltage ?? 0) + "V"}</Typography>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <Card 
                  classes={{
                    root: styles.cardPaper,
                    header: styles.cardHeader,
                    title: styles.cardTitle,
                    content: styles.cardContent,
                  }}
                  title={viewDetails.name}
                  subheader={'From ' + (moment(filter.endDate).diff(moment(filter.startDate), 'hours') <= 24 ? aggregationScale.label : moment(filter.endDate).diff(moment(filter.startDate), 'years') >= 1 ? 'Monthly' : 'Daily') + ' Aggregation'}
                >
                  <LineChart results={result.todayTotalEnergy && result.todayTotalEnergy.aggregationRequest} viewDetails={viewDetails} isLoading={isLoading} setIsLoading={setIsLoading} />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={styles.buttonSecondary}
                  onClick={()=>setViewDetails(false)}
                >
                Back
                </Button>
              </Grid>
            </Grid>
          : <React.Fragment>
              <Grid container spacing={4}>
                {unitList.slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable).map((item, index) => {
                  return (
                    <Grid item xs={6} key={index}>
                      <Card 
                        classes={{
                          root: styles.cardPaper,
                          header: styles.cardHeader,
                          title: styles.cardTitle,
                          content: styles.cardContent,
                        }}
                        title={item.name}
                        subheader={"From " + aggregationScale.label + " Aggregation"}
                        action={ 
                          <Typography className={styles.link} onClick={() => onClickDetails(item)}>View Details</Typography>
                        }
                      >
                        <LineChart title={item} results={index % 2 === 0 ? dashboardResult && dashboardResult.todayTotalEnergy && dashboardResult.todayTotalEnergy.aggregationRequest : dashboardResult2 && dashboardResult2.todayTotalEnergy && dashboardResult2.todayTotalEnergy.aggregationRequest} isLoading={isLoading} setIsLoading={setIsLoading} />
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
              { count > 1 &&
                <Pagination 
                  count={count} 
                  page={page+1}
                  onChange={(e, pageNo)=>setPage(pageNo-1)}
                />
              }
            </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  );
}
