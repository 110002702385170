// Create Edit Devices
// Step 1: Device Details (Device Type + Device Function)
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Checkbox from "shared-components/Checkbox/Checkbox";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Autocomplete from "shared-components/Select/Autocomplete";
import Modal from "shared-components/Modal/Modal";

import DeviceStepper from "./DeviceStepper";
import ReadingUnits from "./ReadingUnits";
import DeviceTypes from "./DeviceTypes";
import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { updateRequest, deleteRequest, resetTesting, reset } from "./store/device";
import { GetAllDevice, GetAllDeviceType, GetProposedDeviceRefNo, GetFunctionLibrary } from "services/AdminPortal/DeviceService";

import settings from "assets/icons/orange/settings.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/device.module.scss";

export default function DetailForm(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const deviceNames = useSelector(store => store.admin.device.device.deviceNames);
  const device = useSelector(store => store.admin.device.device.device);
  const deviceTypes = useSelector(store => store.admin.device.type.deviceTypes);
  const functionLibrary = useSelector(store => store.admin.device.reading.functionLibrary);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [changeSelection, setChangeSelection] = React.useState(false);
  const [isNoLibrary, setIsNoLibrary] = React.useState(true);
  const [isDeviceType, setIsDeviceType] = React.useState(false);
  const [isReading, setIsReading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const libraries = functionLibrary.deviceFunctions;

  const handleOnChange_text = (e) => {
    props.setIsChanged(true);
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    // on change device type
    props.setIsChanged(true);
    dispatch(updateRequest({[e.target.name] : e.target.value, deviceFunctions: []}));
    getProposedId();
  };

  const handleOnChange_autocomplete = (value) => {
    props.setIsChanged(true);
    dispatch(updateRequest({deviceName: value}));
  };

  const handleModal_confirm = (action, e) => {
    if (device.testing) { // if device tested, confirm to reset test result
      setOpenConfirmModal(!openConfirmModal);
      setChangeSelection({action, e});
    } else {
      handleButtonClick_confirm({action, e});
    }
  }

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleButtonClick_confirm = (selection) => {
    if (selection.action === "select") {
      handleOnChange_select(selection.e);
    } else {
      handleButtonClick_checkbox(selection.e);
    }
    setOpenConfirmModal(false);
    dispatch(resetTesting());
  }

  const handleButtonClick_batch = () => {
    if (props.isChanged) {
      handleButtonClick_exit();
    } else {
      Promise.all([dispatch(setDirty(false))])  // if no change, no confirmation alert
      .then(() => {
        handleButtonClick_exit();
      });
    }
  };

  const handleButtonClick_checkbox = (e) => {
    if (e.target.id !== "deviceFunctions") {
      // for light additional functions (isTwoChannels, isFollowRecipe, isFollowNormalLight)
      dispatch(updateRequest({[e.target.id] : !device[e.target.id]}))
    } else {
      // can choose multiple device functions
      const value = parseInt(e.target.value);
      const index = device.deviceFunctions.indexOf(value);
      let payload = [];
      if (index === -1) { // not in array, add
        payload = [...device.deviceFunctions, value];
        dispatch(updateRequest({deviceFunctions: payload}));
      } 
      else {  // remove
        dispatch(deleteRequest({key: "deviceFunctions", index}));
      }
      getProposedId();
    }
  };

  const handleButtonClick_exit = () => {
    dispatch(reset());
    history.push("/admin/device/batch");
  }

  const handleButtonClick_next = () => {
    if (validateFields()) {
      props.setIsChanged(true);
      props.setStep(1);
    }
  }

  const getProposedId = () => {
    if(device.deviceTypeId && device.deviceFunctions) {
      dispatch(GetProposedDeviceRefNo());
    }
  }

  const validateFields = () => {
    if (!device.deviceTypeId) {
      setErrorMsg({field: "deviceTypeId", msg: "Please select a device type"});
      return false;
    }
    if (device.deviceFunctions.length === 0) {
      setErrorMsg({field: "functions", msg: "Please select at least 1 device function"});
      return false;
    }
    if (isNoLibrary) {
      setErrorMsg({field: "deviceTypeId", msg: "Please link a library to this device type"});
      return false;
    }
    else {
      setErrorMsg(false);
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllDevice());
    dispatch(GetAllDeviceType());
  },[]);

  React.useEffect(() => {
    if(deviceTypes.length && device.deviceTypeId) {
      const id = deviceTypes.find((deviceType) => deviceType.id === device.deviceTypeId)?.functionLibraryId;
      if (id) {
        // get device function list on selecting device type
        dispatch(GetFunctionLibrary(id));
        setIsNoLibrary(false);
        setOpenWarningModal(false);
      } else {
        setOpenWarningModal(true);
        setIsNoLibrary(true);
      }
    }
  },[deviceTypes, device.deviceTypeId, isReading]);

  if (isReading) {
    const deviceType = deviceTypes.find(({id}) => id === device.deviceTypeId);
    return <ReadingUnits id={deviceType.functionLibraryId} setIsReading={setIsReading} />
  } else if (isDeviceType){
    return <DeviceTypes setIsDeviceType={setIsDeviceType} />
  } else {
    return (
      <React.Fragment>
        <Card 
          title={
            <React.Fragment>
              Step 1: Please key in details for Device Creation
              <Button 
                className={styles.headerButton}
                startIcon={<AddIcon />}
                onClick={()=>handleButtonClick_batch()}
              >
                Choose Batch Update
              </Button>
            </React.Fragment>
          }
          subheader={<DeviceStepper activeStep={props.step} />}
          cardActions={
            <React.Fragment>
              <Button disabled/>
              <Button
                className={styles.button}
                onClick={()=>handleButtonClick_next()}
              >
                Next Step
              </Button>
            </React.Fragment>
          }
        >
          <div className={styles.content}>
            <div className={styles.leftContent}>
              <Paper className={styles.paper} elevation={0}>
                <Autocomplete
                  label="Device Name"
                  id="deviceName"
                  freeSolo
                  inputProps={{ maxLength: 50 }}
                  onInputChange={(e, newInputValue) => {
                    handleOnChange_autocomplete(newInputValue);
                  }}
                  inputValue={renderValue(device.deviceName)}
                  options={deviceNames}
                  placeholder="Please select or enter a device name" 
                />
                <div className="flex w-full">
                  <div className={clsx(styles.leftField, styles.marginTop)}>
                    <Select
                      label={<React.Fragment>Device Type <span className={styles.orange}>*</span></React.Fragment>}
                      name="deviceTypeId"
                      onChange={(e)=>handleModal_confirm("select", e)}
                      value={renderValue(device.deviceTypeId)}
                      placeholder="Please select a device type"
                      errorMsg={errorMsg}
                      disabled={props.isEdit}
                    >
                      {deviceTypes && _.orderBy(deviceTypes, ["deviceTypeName"], ["asc"]).map((item,index) => {
                        return <MenuItem key={index} value={item.id}>{item.deviceTypeName}</MenuItem>;
                      })}  
                    </Select>
                  </div>
                  <IconButton 
                    className={clsx(styles.iconButton, styles.rightField)}
                    onClick={() => setIsDeviceType(true)}
                  >
                    <img className={styles.icon} src={settings} alt="settings" />
                  </IconButton>
                </div>
                <TextField 
                  label="Proposed Device ID"
                  disabled
                  id="deviceRefNo"
                  variant="outlined" 
                  value={renderValue(device.deviceRefNo)}
                />
              </Paper>
              <Paper className={styles.paper} elevation={0}>
                <TextField 
                  label="Device Description"
                  id="deviceDesc"
                  variant="outlined" 
                  inputProps={{ maxLength: 255 }}
                  minRows={4}
                  multiline
                  placeholder="Please type a description here"
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(device.deviceDesc)}
                  errorMsg={errorMsg}
                />
              </Paper>
            </div>
            <div className={styles.rightContent}>
              <Paper className={clsx(styles.paper, styles.function)} elevation={0}>
                { !isNoLibrary
                ? <React.Fragment>
                    <Typography className={(styles.paperTitle)}>
                      Device Functions
                      <IconButton 
                        className={styles.iconButton_end}
                        onClick={() => setIsReading(true)}
                      >
                        <img className={styles.icon} src={settings} alt="settings" />
                      </IconButton>
                    </Typography>
                    { errorMsg.field === "functions" && <Typography className={styles.error}>{errorMsg.msg}</Typography> }
                    <div className={styles.functionContainer}>
                      <div className={styles.functionScroll}>
                        <Table className={styles.functionTable}>
                          { libraries && libraries.map((library, index)=> {
                            if (!library.isCombine) {
                              return (
                                <TableRow key={index} className={styles.tableRow}>
                                  <TableCell>
                                    <span className={styles.tableCell}>
                                      {library.deviceFunctionName} ({library.shortDeviceFunctionName})
                                      <Checkbox 
                                        id="deviceFunctions"
                                        checked={device.deviceFunctions.includes(library.id)}
                                        value={library.id} 
                                        onChange={(e) => handleModal_confirm("checkbox", e)}
                                        disabled={props.isEdit}
                                      />
                                    </span>
                                    { library.deviceFunctionName.toLowerCase().includes("light") && device.deviceFunctions.includes(library.id) && 
                                      <React.Fragment>
                                        <div className={styles.checkboxPadding} >
                                          <Checkbox
                                            id="isTwoChannel"
                                            checked={device.isTwoChannel}
                                            onChange={(e) => handleButtonClick_checkbox(e)} />
                                          Two Channel Light
                                        </div>
                                        <div className={styles.checkboxPadding} >
                                          <Checkbox
                                            id="isFollowReceipt"
                                            checked={device.isFollowReceipt}
                                            onChange={(e) => handleButtonClick_checkbox(e)} />
                                          Follow Recipe
                                        </div>
                                        <div className={styles.checkboxPadding} >
                                          <Checkbox
                                            id="isFollowNormalLight"
                                            checked={device.isFollowNormalLight}
                                            onChange={(e) => handleButtonClick_checkbox(e)} />
                                          Follow Normal Light
                                        </div>
                                      </React.Fragment>
                                    }
                                  </TableCell>
                                </TableRow>
                              )
                            }
                          })}
                        </Table>
                      </div>
                    </div>
                  </React.Fragment>
                : <Typography className={styles.empty}>No Device Type Selected</Typography> 
                }
              </Paper>
            </div>
          </div>
        </Card>
        <Modal
          open={openConfirmModal}
          onClose={() => handleModal_confirm()}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="Are you sure"
          content="Do you really want to change your selection? It will reset your test result."
          actions={
            <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_confirm()}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_confirm(changeSelection)}>Confirm</Button>
            </React.Fragment>
          }
        />
        <Modal
          open={openWarningModal}
          onClose={() => handleModal_warning()}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="No Library Found"
          content="Device does not belong to any library, please link a library to it."
          actions={
            <React.Fragment>
              <Button className={styles.button} onClick={() => handleModal_warning()}>Okay</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    )
  }
}
