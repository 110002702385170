import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import {FomsMS} from "../index";

export const GetDeviceList = createAsyncThunk(
  "commonLookup/getDeviceList", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * 10,
      MaxResultCount: param.maxResultCount ? param.maxResultCount : 10
    }
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetDeviceList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllBanks = createAsyncThunk(
  "commonLookup/getAllBanks", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetAllBanks", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetFunctionLibraryList = createAsyncThunk(
  "commonLookup/getFunctionLibraryList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetFunctionLibraryList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllSensorControlDeviceFunction = createAsyncThunk(
  "commonLookup/getAllSensorControlDeviceFunction", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetAllSensorControlDeviceFuntion", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWorkOrderList = createAsyncThunk(
  "commonLookup/getWorkOrderList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetWorkOrderList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipmentList  = createAsyncThunk(
  "commonLookup/getEquipmentList ", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetEquipmentList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductList  = createAsyncThunk(
  "commonLookup/getProductList ", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      workOrderId: id
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetProductList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductListByWorkOrderId  = createAsyncThunk(
  "commonLookup/getProductListByWorkOrderId ", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      workOrderId: id
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetProductListByWorkOrderId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductGrowthProcessList  = createAsyncThunk(
  "commonLookup/getProductGrowthProcessList ", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      versionId: id,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetProductGrowthProcessList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductTaskList  = createAsyncThunk(
  "commonLookup/getProductTaskList ", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      productGrowthProcessId: id,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetProductTaskList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetUserList = createAsyncThunk(
  "commonLookup/getUserList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetUserList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllUserUOMs = createAsyncThunk(
  "commonLookup/getAllUserUOMs", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetAllUOMs", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetNotYetStartedWorkOrderList = createAsyncThunk(
  "commonLookup/getNotYetStartedtWorkOrderList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetNotYetStartedWorkOrderList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllRawMaterialInfo = createAsyncThunk(
  "commonLookup/getAllRawMaterialInfo", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetAllRawMaterialInfo", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRawMaterialBatchList  = createAsyncThunk(
  "commonLookup/getRawMaterialBatchList ", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      rawMaterialId: id,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetRawMaterialBatchList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRawMaterialList  = createAsyncThunk(
  "commonLookup/getRawMaterialList ", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      productGrowthProcessId: id,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetRawMaterialList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllSupplierInfo = createAsyncThunk(
  "commonLookup/getAllSupplierInfo", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetAllSupplierInfo", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllWarehouseInfo = createAsyncThunk(
  "commonLookup/getAllWarehouseInfo", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetAllWareHouseInfo", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllRawMaterialInfoForMapping = createAsyncThunk(
  "commonLookup/getAllRawMaterialInfoForMapping", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetAllRawMaterialInfoForMapping", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllSaleOrder = createAsyncThunk(
  "commonLookup/getAllSaleOrder", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetAllSaleOrder", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllLotIdLookup = createAsyncThunk(
  "commonLookup/getAllLotIdLookup", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetAllLotIdLookup", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);