/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Carousel from "shared-components/Carousel/Carousel";

import { useInterval } from 'common/utils';
import noCamera from "assets/vids/no-camera.mp4";

import styles from "assets/jss/components/UserPortal/monitoring.module.scss";

export default function CameraOverview(props) {
  const dispatch = useDispatch();
  const detailView = useSelector(store => store.user.monitoring.production.detailView);
  const [date, setDate] = React.useState(moment());

  useInterval(() => {
    setDate(moment());
  }, 1000);

  return (
    <React.Fragment>
      <Card 
        classes={{
          header: styles.cameraHeaderDiv,
          content: styles.cameraContent
        }}
        title={detailView.bankName}
        action={
          <React.Fragment>
            <Typography className={styles.cameraAction}>{moment(date).format("YYYY-MM-DD HH:mm:ss")}</Typography>
          </React.Fragment>
        }
      >
        {(detailView.cameraInfos && detailView.cameraInfos.length > 0)
        ?
          <Carousel 
            className={styles.cameraView}
            classes={{
              img: styles.cameraView
            }}
            data={
              detailView.cameraInfos.map((item) => {
                return (
                  item.streamUrl
                )
              })
            }
          />
        :
          <video 
            className={styles.cameraView}
            src={noCamera}
            autoPlay
            loop
          />
        }
      </Card>
    </React.Fragment>
  );
}
