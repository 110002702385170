import { createAsyncThunk } from "@reduxjs/toolkit";
import { find, omit } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

// Lookup
export const GetEquipmentList  = createAsyncThunk(
  "equipmentMgt/getEquipmentList ", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetEquipmentList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Reserve
export const GetAllEquipmentReserve = createAsyncThunk(
  "equipmentMgt/getAllEquipmentReserve", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/GetAllEquipmentReserve", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipmentReserveById = createAsyncThunk(
  "equipmentMgt/getEquipmentReserveById", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/GetEquipmentReserveById", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateEquipmentReserve = createAsyncThunk(
  "equipmentMgt/createOrUpdateEquipmentReserve", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const reservation = getState().user.equipment.reserve.reservation;
    let body = {
      ...reservation,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/EquipmentMgt/CreateOrUpdateEquipmentReserve", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteEquipmentReserve = createAsyncThunk(
  "equipmentMgt/DeleteEquipmentReserve", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/userportal/EquipmentMgt/DeleteEquipmentReserve", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Issue
export const SaveIssuedEquipment = createAsyncThunk(
  "equipmentMgt/saveIssuedEquipment", 
  async (param, {getState, rejectWithValue}) => {
    let body = omit(param, ["equipment"]);
    if (!param) {
      body = {equipmentReserveIdList: getState().user.equipment.issue.issuedId};
    }
    try {
      const response = await FomsMS.post("/userportal/EquipmentMgt/SaveIssuedEquipment", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Overall Availability
export const GetOverallAvailability = createAsyncThunk(
  "equipmentMgt/getOverallAvailability", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/GetOverallAvailability", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Equipment Cost
export const GetAllEquipmentCost = createAsyncThunk(
  "equipmentMgt/getAllEquipmentCost", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable,
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/GetAllEquipmentCost", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Maintenance Status
export const GetAllEquipmentStatus = createAsyncThunk(
  "equipmentMgt/getAllEquipmentStatus", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/GetAllEquipmentStatus", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllEquipmentMaintenanceHistory = createAsyncThunk(
  "equipmentMgt/getAllEquipmentMaintenanceHistory", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/GetAllEquipmentMaintenanceHistory", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipmentMaintenance = createAsyncThunk(
  "equipmentMgt/getEquipmentMaintenance", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/GetEquipmentMaintenance", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteEquipmentMaintenance = createAsyncThunk(
  "equipmentMgt/deleteEquipmentMaintenance", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/userportal/EquipmentMgt/DeleteEquipmentMaintenance", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateStatusEquipmentMaintenance = createAsyncThunk(
  "equipmentMgt/createOrUpdateEquipmentMaintenance", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.post("/userportal/EquipmentMgt/CreateOrUpdateEquipmentMaintenance", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateEquipmentMaintenance = createAsyncThunk(
  "equipmentMgt/createOrUpdateEquipmentMaintenance", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...getState().user.equipment.maintenance.maintenance,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/userportal/EquipmentMgt/CreateOrUpdateEquipmentMaintenance", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Search
export const SearchEquipmentReserveOrIssue = createAsyncThunk(
  "equipmentMgt/searchEquipmentReserveOrIssue", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/SearchEquipmentReserveOrIssue", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchEquipmentCost = createAsyncThunk(
  "equipmentMgt/searchEquipmentCost", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/SearchEquipmentCost", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchEquipmentStatus = createAsyncThunk(
  "equipmentMgt/searchEquipmentStatus", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/SearchEquipmentStatus", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchEquipmentMaintenanceHistory = createAsyncThunk(
  "equipmentMgt/searchEquipmentMaintenanceHistory", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/EquipmentMgt/SearchEquipmentMaintenanceHistory", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);