import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import {FomsMS} from "../index";

// Lookup
export const GetWorkOrderList = createAsyncThunk(
  "capacity/getWorkOrderList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      isActiveWorkOrder: true
    };
    try {
      const response = await FomsMS.get("/userportal/CommonLookup/GetWorkOrderList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Report
export const TracingCapacityModuleForStatisticsReport = createAsyncThunk(
  "capacity/tracingCapcityModuleForStatisticsReport", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/CapacityModuleStatistics/TracingCapcityModuleForStatisticsReport", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);