import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import addHeatmapModule from 'highcharts/modules/heatmap';
// import addTilemapModule from 'highcharts/modules/tilemap';
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Select from "shared-components/Select/Select";

import { renderValue } from "common/functions";
import { useInterval } from 'common/utils';
import { farmConfig } from "enums/Constants";
import { GetClimateMonitoringHeatMap } from "services/UserPortal/ClimateService";

import styles from "assets/jss/components/UserPortal/climate.module.scss";

addHeatmapModule(Highcharts)
// addTilemapModule(Highcharts)
export default function HeatMap(props) {
  const dispatch = useDispatch();
  const result = useSelector(store => store.user.climate.climateMonitoring.heatMapResult);
  const deviceUnits = useSelector(store => store.user.climate.climateMonitoring.deviceUnits);
  
  const units = [{name: "temperature", unit: "°C"}, {name: "humidity", unit: "%RH"}, {name: "CO2", unit: "ppm"}]
  const legend = {
    temperature: {
      valueFrom: [0, 21, 22, 23, 24, 25, 26, 27, 28, 29],
      valueTo: [21, 22, 23, 24, 25, 26, 27, 28, 100],
      color: ["#FFF8E2", "#FFECB3", "#FEE082", "#FFCA28", "#FFB400", "#FFA001", "#FF9000", "#FF7001", "#E65100"],
    },
    humidity: {
      valueFrom: [0, 65, 66, 67, 68, 69, 70, 71, 72, 73],
      valueTo: [65, 66, 67, 68, 69, 70, 71, 72, 100],
      color: ["#D5F2F6", "#ABE5ED", "#81D8E4", "#57CADB", "#2DBDD2", "#2497A8", "#1B727E", "#124C54", "#09262A"],
    },
    CO2: {
      valueFrom: [0, 640, 650, 660, 670, 680, 690, 700, 710, 720],
      valueTo: [640, 650, 660, 670, 680, 690, 700, 710, 1000],
      color: ["#F8D3F2", "#F1A7E5", "#EA7BD8", "#E250CB", "#DB24BE", "#AF1D98", "#841572", "#580E4C", "#2C0726"],
    }
  }

  const getHeatMap = () => {
    if (props.bank && props.filter.deviceId.length) {
      const param = {bankId: props.bank.id, deviceIds: props.filter.deviceId, unit: props.filter.unit};
      dispatch(GetClimateMonitoringHeatMap({...param, stopLoading: true}));
    }
  }

  const handleOnChange_select = (e) => {
    props.setFilter({...props.filter, [e.target.name]: e.target.value});
  };
  
  React.useEffect(() => {
    getHeatMap();
  },[props.filter]);

  const config = () => {
    const value = unit && legend[unit.name];
    return ({
      chart: {
        // type: 'tilemap',
        type: 'heatmap',
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        min: 1,
        max: props.bank.numberOfBays,
        gridLineColor: '#D3DDD3',
        gridLineWidth: "0.052vw",
        title: {
          text: farmConfig.bay
        },
        labels: {
          align: "right"
        }
      },
      yAxis: {
        min: 1,
        max: props.bank.numberOfLevels,
        gridLineColor: '#D3DDD3',
        gridLineWidth: "0.052vw",
        title: {
          text: farmConfig.level
        },
      },
      colorAxis: {
        dataClasses: value&&value.color.map((item, index) => {
          return {
            from: value.valueFrom[index],
            to: value.valueTo[index],
            color: item,
          }
        }),
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.value}<br/>{point.name}'
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          tileShape: 'circle',
          dataLabels: {
            enabled: true,
            format: '{point.value}',
            color: 'contrast',
            style: {
              textOutline: false
            }
          },
          colsize: _.floor(Math.max(props.bank.numberOfLevels, props.bank.numberOfBays)/12),
          rowsize: _.floor(Math.max(props.bank.numberOfLevels, props.bank.numberOfBays)/12),
        },
      },
      series: [{
        data: result.map((device) => {
          if (device) {
            const location = device.location.cageRefNo.split("-");
            return {
              x: Number(location[1]),
              y: Number(location[1])%2 === 0 ? Number(location[2]) : Number(location[2])+0.5,
              value: (device.sensorValues&&device.sensorValues.length) ? device.sensorValues[0].value : 0,
              name: device.deviceRefNo
            }
          }
        })
      }]
    })
  };
  
  useInterval(() => {
    getHeatMap(true);
  }, 1000*60*60);

  if (props.filter.deviceId.length === 0) {
    return (<Typography className={styles.graphText}>Select device to view graph</Typography>);
  }

  const unit = _.find(units, ({unit}) => unit===props.unit);
  return (
    <div className={styles.graphContainer}>
      <div className={styles.unitSelectContainer}>
        <Typography className={styles.label}>Unit: </Typography>
        <div className={styles.unitSelect}>
          <Select 
            name="unit"
            onChange={(e)=>handleOnChange_select(e)}
            value={renderValue(props.filter.unit)}
            placeholder={deviceUnits.length ? "Select a Unit" : "No Unit"}
          >
            {deviceUnits.map((item, index) => {
              return <MenuItem key={index} value={item}>{item}</MenuItem>;
            })} 
          </Select>
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: styles.highChart }} />
      <div className={styles.legend}>
        {unit && legend[unit.name].color.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div 
                className={styles.heatMapLegend} 
                style={{backgroundColor: item, color: index<4?"black":"white"}}
              >
                {legend[unit.name].valueFrom[index+1]}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  );
}
