/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
// core components
import Tag from 'shared-components/Chip/Tag';

import Summary from "./Summary";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import { reset } from "../store/production";

import warehouse from "assets/icons/orange/warehouse.svg";
import enter from "assets/icons/orange/enter.svg";
import styles from "assets/jss/components/UserPortal/monitoring.module.scss";


export default function AllOverview(props) {
  const dispatch = useDispatch();
  const warehouseList = useSelector(store => store.user.monitoring.storage.warehouseList);
  const [date, setDate] = React.useState(moment());

  const getLogSheet = () => {

  };

  React.useEffect(() => {
    getLogSheet();
  },[props.equipmentId]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    setDate(moment());
  });

  useInterval(() => {
    setDate(moment());
  }, 1000);

  return (
    <Grid container spacing={4}>
      {warehouseList && warehouseList.map((item, index) => {
        return (
          <Grid key={index} item xs={3}>
            <Paper className={styles.envPaper}>
              <div className={styles.equipTitle}>
                <Typography className={styles.envValueBold}>{item.warehouseName}</Typography>
                <img className={clsx(styles.icon_38, styles.equipIcon)} src={warehouse} alt="warehouse" />
              </div>
              <Typography>Inventory: {formatNumbers(roundTo2Decimal(item.capacityUsed))}%</Typography>
              <Typography>Capacity: {item.capacity} {item.uom}</Typography>
              <Typography>Status: {item.status}</Typography>
              <IconButton 
                className={styles.iconButton}
                onClick={() => props.setId(item.id)}
              >
                <img className={clsx(styles.icon_24, styles.enterButton)} src={enter} alt="enter" />
              </IconButton>
            </Paper>
          </Grid>
        )
      })}
      {/* <div className={styles.cardContainer}>
        <Summary equipmentId={props.equipmentId} />
      </div> */}
    </Grid>
  );
}
