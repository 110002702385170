import React from "react";
import { useDispatch, useSelector } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import Aggregation from "./Aggregation";
// import FloorPlan from "./FloorPlan";
import Parameters from "./Parameters";
import CostCharts from "./CostCharts";
import Dashboard from "./Dashboard";
import { setSearchText } from "store/general";

export default function Overview() {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const [tab, setTab] = React.useState(0);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <Aggregation />
      // case 1:
      //   return <FloorPlan />
      case 1:
        return <Parameters />
      case 2:
        return <CostCharts />
      case 3:
        return <Dashboard />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title = "Energy Management - Overview"
        subheader={
          <React.Fragment>
            <Tabs 
              tabs={["Aggregation Scale", "Main Parameters", "Cost Charts", "Dashboard"]}
              value={tab} 
              onChange={(e,value) => setTab(value)}
              disabled={isDirty}
            />
          </React.Fragment>
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
