import { find } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllEquipment = createAsyncThunk(
  "equipment/getAllEquipment", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Equipment/GetAllEquipment", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipment = createAsyncThunk(
  "equipment/getEquipment", 
  async (Id, {rejectWithValue}) => {
    const params = {Id};
    try {
      const response = await FomsMS.get("/Equipment/GetEquipmentById", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ConfigureDeviceFunction = createAsyncThunk(
  "equipment/configureDeviceFunction", 
  async (value, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...value,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/Equipment/ConfigureDeviceFunctions", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateEquipmentConfig = createAsyncThunk(
  "equipment/updateEquipmentConfig", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().admin.equipment.equipment;
    try {
      const response = await FomsMS.post("/Equipment/UpdateEquipmentConfig", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetLibraryList = createAsyncThunk(
  "equipment/getLibraryList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Equipment/GetLibraryList");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetFunctionById = createAsyncThunk(
  "equipment/getFunctionById", 
  async (id, {rejectWithValue}) => {
    let params = new URLSearchParams();
    id.map((item) => params.append("LibraryIdList", item));
    try {
      const response = await FomsMS.get("/Equipment/GetFunctionNameByLibraryIdList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductNameList = createAsyncThunk(
  "equipment/getProductNameList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.get("/Equipment/GetProductNameList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllUOM = createAsyncThunk(
  "equipment/getAllUOM", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Equipment/GetAllUnitOfMeasurement");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchEquipment = createAsyncThunk(
  "equipment/searchEquipment", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Equipment/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);