import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import {FomsMS} from "../index";

export const GetProductList = createAsyncThunk(
  "recipe/getProductList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Recipe/GetProductList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRecipeByProductId = createAsyncThunk(
  "recipe/getRecipeByProductId", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      productId: id,
    };
    try {
      const response = await FomsMS.get("/Recipe/GetRecipeByProductId", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRecipe = createAsyncThunk(
  "recipe/createOrUpdateRecipe", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().admin.recipe.control;
    try {
      const response = await FomsMS.post("/Recipe/CreateOrUpdateRecipe", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);