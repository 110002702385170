/* eslint-disable no-unused-vars */ // TO REMOVE
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { amrListingTableHead, amrDetailsTableHead } from "enums/UserPortal/TableHeaders";
import { filterParam, filterUrl } from 'common/functions';
import { setValues } from "../store/overview";
import { GetAllCurrentListingList, GetAllCages } from "services/UserPortal/ASRSService";

import styles from "assets/jss/components/UserPortal/amr.module.scss";

export default function AMRListing() {
  let history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.amr.overview.filter);
  const totalCount = useSelector(store => store.user.amr.overview.totalCount);
  const result = useSelector(store => store.user.amr.overview.result);
  const [newFilter, setNewFilter] = React.useState({cageId: null, softzoneId: null, page: 0});
  const [open, setOpen] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);
  
  const getAllAMR = (stopLoading) => {
    dispatch(GetAllCurrentListingList({...filter, stopLoading}));
  }

  React.useEffect(() => {
    window.history.pushState({}, '', filterUrl(filter));  // no filter button
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllAMR();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllCages());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllAMR(true);
  });

  return (
    <React.Fragment>
      <div className={styles.table}>
        <Table
          expandable
          className={styles.mainTable}
          header={amrListingTableHead}
        >
          { result.map((item,index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{item.amrID}</TableCell>
                  <TableCell>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <LinearProgress 
                        classes={{
                          root: styles.progressBar, 
                          barColorPrimary: styles["progress"+(item.batteryPercentage ? parseInt(item.batteryPercentage/10) : "1")]
                        }} 
                        variant="determinate" 
                        value={item.batteryPercentage ?? 0} /> 
                      {item.batteryPercentage ?? 0}%
                    </div>
                  </TableCell>
                  <TableCell>{item.hoursLeft}</TableCell>
                  <TableCell>{item.currentTask}</TableCell>
                  <TableCell>{item.taskStatus}</TableCell>
                  <TableCell>{item.upcomingTask}</TableCell>
                  <TableCell>{moment(item.date).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{moment(item.time).format("HHmm")}</TableCell>
                  <TableCell><Typography className={styles.link} onClick={() => open === index? setOpen(false) : setOpen(index)}>View Details</Typography></TableCell>
                </TableRow>
                <TableRow>
                  { open===index && 
                    <TableCell className="pt-0 pb-0" colSpan={9}>
                      <Collapse in={open === index}>
                        <Table
                          className={styles.subTable}
                          header={amrDetailsTableHead}
                        >
                          { item.ruleList?.map((subItem, subIndex) => {
                            return (
                              <React.Fragment key={subIndex}>
                                <TableRow>
                                  <TableCell>{moment(subItem.startDate).format("DD/MM/YYYY")}</TableCell>
                                  <TableCell>{moment(subItem.endDate).format("DD/MM/YYYY")}</TableCell>
                                  <TableCell>{subItem.turnOnTime.replace("-", "")}</TableCell>
                                  <TableCell>{subItem.turnOffTime.replace("-", "")}</TableCell>
                                </TableRow>
                              </React.Fragment>
                            )
                          })}
                        </Table>
                      </Collapse>
                    </TableCell>
                  }
                </TableRow>
              </React.Fragment>
            )
          })}
        </Table>
      </div>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={filter.page+1}
          onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
        />
      }
    </React.Fragment>
  );
}
