/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// core components
import Card from 'shared-components/Card/Card';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import { checkPermission } from "common/functions";
import styles from "assets/jss/components/UserPortal/monitoring.module.scss";

export default function WorkOrder(props) {
  const workOrderList = useSelector(store => store.user.monitoring.production.bankOverview.workOrders);
  const detailView = useSelector(store => store.user.monitoring.production.detailView);

  return (
    <React.Fragment>
      <Card
        classes={{
          root: clsx(styles.cardPaper, styles.cardWO),
          title: styles.cardMargin,
          content: styles.cardContent
        }}
        title="Work Order(s) in Operation"
      >
        <Table
          className={styles.table}
        >
          {props.bankId
          ?
            detailView.workOrders && detailView.workOrders.map((item,index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{item.workOrderNo}</TableCell>
                    <TableCell>{!checkPermission("Operations", "none")?<Link className={styles.link} to={{pathname: "/user/operations/process-overview", search: "?workOrderId="+item.id}}>Details</Link>:""}</TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })
          :
            workOrderList && workOrderList.map((item,index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{item.workOrderNo}</TableCell>
                    <TableCell>{!checkPermission("Operations", "none")?<Link className={styles.link} to={{pathname: "/user/operations/process-overview", search: "?workOrderId="+item.id}}>Details</Link>:""}</TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })
          }
          
        </Table>
      </Card>
    </React.Fragment>
  );
}
