import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import CustomIconButton from "shared-components/Button/IconButton";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import DateTimePicker from "shared-components/DatePicker/DateTimePicker";
import Autocomplete from "shared-components/Select/Autocomplete";

import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { reset as resetNav } from "shared-components/Navbars";
import { asrsAddMovementTableHead } from "enums/UserPortal/TableHeaders";
import { setValues, updateRequest, updateMovementRequest, reset } from "../store/movement";
import { CreateOrUpdateASRSMovementMaster, GetASRSMovementMaster, GetLocationIDListByCageId } from "services/UserPortal/ASRSService";

import styles from "assets/jss/components/UserPortal/asrs.module.scss";

export default function AddEditMovement() {
  const dispatch = useDispatch();
  let history = useHistory();
  const movement = useSelector(store => store.user.asrs.movement.movement);
  const movementDetailList = useSelector(store => store.user.asrs.movement.movement.asrsMovementDetailList);
  const movementEdit = useSelector(store => store.user.asrs.movement.movementEdit);
  const locationList = useSelector(store => store.user.asrs.movement.locationList);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const [editMode, setEditMode] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(null);
  const [isAdd, setIsAdd] = React.useState(false);

  const resetState = () => {
    dispatch(setValues({movementEdit: {}}))
    setEditIndex(null);
    setErrorMsg(false);
    setEditMode(false);
    setIsAdd(false);
  }

  const handleModal = () => {
    if (!movement.nameOfJob) {
      setErrorMsg({field: "nameOfJob", msg: "Please add in job name"});
    } else {
      setErrorMsg({field: "", msg: ""});
      setOpenModal(!openModal);
    }
  };

  const handleOnChange_jobText = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_autocomplete = (target, newValue) => {
    dispatch(updateMovementRequest({[target] : newValue}));
  };

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      const newValue = moment(value).set({second: 0, millisecond: 0});
      let payload = {[field]: newValue.format()};
      dispatch(updateRequest(payload));
    } else {
      dispatch(updateRequest({[field] : value}));
    }
  };

  const handleButtonClick_add = () => {
    dispatch(setDirty(true));
    const payload = _.cloneDeep(movement);
    if (payload.asrsMovementDetailList) {
      payload.asrsMovementDetailList = [...movement.asrsMovementDetailList, {locationId: ""}];
      dispatch(setValues({movement: payload}));
      dispatch(updateMovementRequest({locationID: ""}));
      setEditIndex(payload.asrsMovementDetailList.length-1);
    } else {
      payload.asrsMovementDetailList = [{locationId: ""}];
      dispatch(setValues({movement: payload}));
      dispatch(updateMovementRequest({locationID: ""}));
      setEditIndex(0);
    }
    setIsAdd(true);
    setEditMode(true);
  }

  const handleButtonClick_cancel = () => {
    if (isAdd) {
      let payload = _.cloneDeep(movementDetailList);
      payload.splice(payload.length - 1, 1);
      dispatch(updateRequest({asrsMovementDetailList: payload}));
    }
    resetState();
  }

  const handleButtonClick_delete = (index) => {
    const payload = _.cloneDeep(movementDetailList);
    payload.splice(index, 1);
    dispatch(updateRequest({asrsMovementDetailList: payload}));
    setEditIndex(null);
  }

  const validateTime = () => {
    if (!movement.startDateTime) {
      setErrorMsg({field: "startDateTime", msg: "Please enter a start date"});
      return false;
    }
    if (!moment(movement.startDateTime).isValid()) {
      setErrorMsg({field: "startDateTime", msg: "Invalid Date"});
      return false;
    }
    if (!movement.isNoEndDateTime && !movement.endDateTime) {
      setErrorMsg({field: "endDateTime", msg: "Please enter an end date"});
      return false;
    }
    if (!moment(movement.endDateTime).isValid()) {
      setErrorMsg({field: "endDateTime", msg: "Invalid Date"});
      return false;
    }
    if (movement.endDateTime && moment(movement.endDateTime).isBefore(moment(movement.startDateTime))) {
      setErrorMsg({field: "endDateTime", msg: "End date cannot be before start date"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const validateFields = () => {
    if (!movementEdit.locationID) {
      setErrorMsg({field: "locationID", msg: "Please add in location ID"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const handleButtonClick_edit = (index) => {
    dispatch(setValues({movementEdit: movementDetailList[index]}));
    setEditIndex(index);
    setEditMode(true);
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      let payload = _.cloneDeep(movementDetailList);
      payload[editIndex] = {...payload[editIndex], ...movementEdit};
      dispatch(updateRequest({asrsMovementDetailList: payload}));
      resetState();
    }
  }

  const handleButtonClick_save = () => {
    if (validateTime()) {
      dispatch(setDirty(false));
      dispatch(CreateOrUpdateASRSMovementMaster(movement))
      .then((response) => {
        if (!response.error) {
          history.push("/user/vifs/movement");
        }
      });
    }
  }

  const handleBack = () => {
    dispatch(setDirty(false));
    history.goBack()
  }

  // componentDidMount
  React.useEffect(() => {
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "movement");
    const newValue = moment().set({second: 0, millisecond: 0});
    if (path[index+1] && (path[index+1] !== "create")) {
      dispatch(GetASRSMovementMaster(path[index+1]))
      .then(({payload}) => {
        if (payload && payload.result === null) {
          history.push("/user/vifs/maintenance");
        } else {
          dispatch(setDirty(true));
        }
      });
    } else {
      dispatch(updateRequest({maintenanceStartDate: newValue.format(), maintenanceEndDate: newValue.format()}));
      dispatch(setDirty(true));
    }
    dispatch(setDirty(true));
    dispatch(GetLocationIDListByCageId());
    // componentDidUnmount
    return () => {
      dispatch(resetNav());
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="VIFS - New Movement"
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>handleBack()}
            >
              Go Back
            </Button>
            <Button
              className={styles.button}
              disabled={editMode}
              onClick={()=>handleModal()}
            >
              Next
            </Button>
          </React.Fragment>
        }
      >
        <div className={styles.jobName}>
          <Typography className={styles.jobNameText}>Name of Job</Typography>
          <TextField 
            id="nameOfJob"
            className={styles.jobNameTextfield}
            variant="outlined" 
            inputProps={{ maxLength: 255 }}
            onChange={(e) => handleOnChange_jobText(e)}
            value={renderValue(movement.nameOfJob)}
            errorMsg={errorMsg}
          />
        </div>
        
        <Typography className={styles.editDescription}>Choose the Cage ID or the Location ID for the cages required for Movement Work</Typography>
        <div className={styles.table}>
          <Table
            header={asrsAddMovementTableHead}
          >
            { movement && movement.asrsMovementDetailList && movement.asrsMovementDetailList.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell className={styles.snCell}>{String(index+1).padStart(2,'0')}</TableCell>
                  <TableCell>
                    {( index === editIndex && editMode)
                    ? <Autocomplete
                        id="locationID"
                        inputProps={{ maxLength: 50 }}
                        value={movementEdit.locationID}
                        onChange={(e, newValue) => {
                          handleOnChange_autocomplete('locationID', newValue);
                        }}
                        options={locationList}
                        placeholder="Select a location"
                        errorMsg={errorMsg}
                      />
                    : item.locationID
                    }
                  </TableCell>
                  <TableCell align="right">
                    {editMode
                    ? <React.Fragment>
                        <CustomIconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirm()}
                        />
                        <CustomIconButton 
                          type="close"
                          onClick={() => handleButtonClick_cancel()}
                        />
                      </React.Fragment>
                    : <React.Fragment>
                        <CustomIconButton 
                          type="edit"
                          onClick={() => handleButtonClick_edit(index)}
                          disabled={editMode}
                        />
                        {movement && movement.asrsMovementDetailList && movement.asrsMovementDetailList > 1 &&
                          <CustomIconButton 
                            type="delete"
                            onClick={() => handleButtonClick_delete(index)}
                            disabled={editMode}
                          />
                        }
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        <Button
          className={clsx(styles.buttonSecondary, styles.addButton)}
          startIcon={<AddIcon />}
          disabled={editMode}
          onClick={() => handleButtonClick_add()}
        >
          Add More
        </Button>
      </Card>
      <Modal
        className={styles.batchModal}
        open={openModal}
        onClose={() => handleModal()}
        title="Choose Date & Time for Movement"
        content={
          <React.Fragment>
            <div className="flex">
              <Typography className={styles.timeField}>Start Date/Time:</Typography>
              <DateTimePicker
                type="datetime"
                name="startDateTime"
                value={movement.startDateTime}
                onChange={(e) => handleOnChange_date(e, "startDateTime")}
                errorMsg={errorMsg}
              />
            </div>
            <div className={styles.timeField}>
              <Typography className={styles.formLabel}>End Date/Time:</Typography>
              <DateTimePicker
                type="datetime"
                name="endDateTime"
                value={movement.endDateTime}
                minDateTime={movement.startDateTime}
                onChange={(e) => handleOnChange_date(e, "endDateTime")}
                errorMsg={errorMsg}
              />
            </div>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal()}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_save()}>Save</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
