import { createSlice } from "@reduxjs/toolkit";
import { ConvertkWhToCO2, GetAllSellingPrice } from "services/UserPortal/SustainabilityService";

// initial state
const initialState = {
  conversion: {},
  price: []
};

const sustainabilityChart = createSlice({
  name: "sustainabilityChart",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.config[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(ConvertkWhToCO2.fulfilled, (state, action) => {
        state.conversion = action.payload.result;
      })
      .addCase(GetAllSellingPrice.fulfilled, (state, action) => {
        state.price = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = sustainabilityChart.actions;

// export the reducer
export default sustainabilityChart.reducer;