import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";

import { setSearchText, setDirty } from "store/general";
import { renderValue } from 'common/functions';
import { setValues, updateRequest, reset } from "../store/settings";
import { GetIntervalTime, GetDeliveryTripSetting, CreateOrUpdateTripSetting } from "services/UserPortal/DeliveryService";

import Maps from "./Maps"
import confirm from "assets/icons/orange/confirm.svg";
import styles from "assets/jss/components/UserPortal/delivery.module.scss";

export default function Settings() {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const intervals = useSelector(store => store.user.delivery.settings.intervals);
  const settings = useSelector(store => store.user.delivery.settings.settings);
  const [address, setAddress] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.name]: e.target.value}));
  };

  const handleOnChange_text = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.id]: e.target.value}));
  };

  const handleButtonClick_save = () => {
    dispatch(CreateOrUpdateTripSetting())
    .then(({error})=> {
      if (!error) {
        setOpenModal(true);
        dispatch(setDirty(false));
        dispatch(GetDeliveryTripSetting());
        dispatch(setValues({settings: {}}));
      }
    })
  }

  const handleButtonClick_cancel = () => {
    dispatch(setDirty(false));
    dispatch(GetDeliveryTripSetting());
  }

  const handleModal = () => {
    setOpenModal(!openModal);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetIntervalTime());
    dispatch(GetDeliveryTripSetting());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        classes={{
          content: styles.settingsCard
        }}
        title = "Settings of Trip Calculation"
        subheader="Please set the correct configurations"
        cardActions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleButtonClick_cancel()} disabled={!isDirty}>Cancel</Button>
            <Button 
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
              disabled={!isDirty}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={6} className={styles.leftContent}>
            <Grid container spacing={4}>
              <Grid item xs={4} className={styles.settingsLabel}>
                Interval between stops:
              </Grid>
              <Grid item xs={8}>
                <Select
                  name="inBetweenStopProcessingTime"
                  onChange={(e)=>handleOnChange_select(e)}
                  placeholder="Select an interval"
                  value={renderValue(settings.inBetweenStopProcessingTime)}
                >
                  {intervals && intervals.map((item, index) => {
                    return <MenuItem key={index} value={item.value}>{item.displayText}</MenuItem>;
                  })} 
                </Select>
              </Grid>
              <Grid item xs={4} className={styles.settingsLabel}>
                3rd Party Logistics to integrate:
              </Grid>
              <Grid item xs={8}>
                <Select
                  name="thirdPartyLogicstics"
                  onChange={(e)=>handleOnChange_select(e)}
                  placeholder="Select a logistic"
                  value={renderValue(settings.thirdPartyLogicstics)}
                  disabled
                >
                  <MenuItem value={"Google Api"}>Google Api</MenuItem> 
                </Select>
              </Grid>
              <Grid item xs={4} className={styles.settingsLabel}>
                Default start location of farm:
              </Grid>
              <Grid item xs={8}>
                <TextField 
                  id="address"
                  variant="outlined" 
                  inputProps={{ maxLength: 255 }}
                  placeholder="Enter Address"
                  onChange={(e) => handleOnChange_text(e)}
                  onKeyUp={(e) => setAddress(e.target.value)}
                  value={renderValue(settings.address)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Maps address={address} />
          </Grid>
        </Grid>
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        icon={<img className={styles.icon_64} src={confirm} alt="confirm" />}
        title="Success"
        content="Your selection has been saved."
        actions={
          <React.Fragment>
            <Button className={styles.button} onClick={() => handleModal()}>Okay</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
