import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Switch from "shared-components/Switch/Switch";

import DeviceDetail from "./DeviceDetail";
import { setDirty, setSuccess } from "store/general";
import { renderValue } from "common/functions";
import { updateRequest } from "../store/sensorSettings";
import { CreateOrUpdateSensorSetting } from "services/UserPortal/ClimateService";

import styles from "assets/jss/components/UserPortal/climate.module.scss";

export default function ThresholdForm(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const sensorSetting = useSelector(store => store.user.climate.sensorSettings.sensorSetting);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e,index) => {
    const key = e.target.id.replace(/\d/g, '');
    let payload = _.cloneDeep(sensorSetting).sensorSettingDetailList;
    payload[index][key] = e.target.value;
    dispatch(updateRequest({sensorSettingDetailList : payload}));
  };

  const handleOnChange_switch = () => {
    dispatch(updateRequest({isActive: !sensorSetting.isActive}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateSensorSetting())
        .then(({error}) => {
          if (error) {
            dispatch(setDirty(true));
          } else {
            dispatch(setSuccess(props.isEdit ? "Sensor setting has been modified" : "Sensor setting has been created"));
            history.push({pathname: "/user/climate/sensor-settings"});
          }
        });
      })
    }
  };

  const validateFields = () => {
    if (!sensorSetting.deviceFunctionId) {
      setErrorMsg({field: "deviceFunctionId", msg: "Please select a device function"});
      return false;
    }
    if (!sensorSetting.isForAllDeviceFunctionDevices && !sensorSetting.deviceId) {
      setErrorMsg({field: "deviceId", msg: "Please select a device"});
      return false;
    }
    if (!sensorSetting.ruleName) {
      setErrorMsg({field: "ruleName", msg: "Please enter a rule name"});
      return false;
    }
    for (let i = 0; i < sensorSetting.sensorSettingDetailList.length; i ++) {
      if (!sensorSetting.sensorSettingDetailList[i].minValue) {
        setErrorMsg({field: "minValue"+i, msg: "Please enter a min value"});
        return false;
      }
      if (!sensorSetting.sensorSettingDetailList[i].maxValue) {
        setErrorMsg({field: "maxValue"+i, msg: "Please enter a max value"});
        return false;
      }
      if (sensorSetting.sensorSettingDetailList[i].minValue > sensorSetting.sensorSettingDetailList[i].maxValue) {
        setErrorMsg({field: "maxValue"+i, msg: "Max value cannot be smaller than min value"});
        return false;
      }
    }
    setErrorMsg(false);
    return true;
  };

  const resetState = () => {
    dispatch(setDirty(false));
  };

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      resetState();
    }
  },[]);

  return (
    <React.Fragment>
      <Card
        classes={{
          root: styles.cardPaper,
        }}
        title="Based on Device Function / ID"
        action={
          <div className={styles.switchAction}>
            <Typography className={styles.label}>Active:</Typography>
            <Switch 
              checked={Boolean(sensorSetting.isActive)}
              onChange={() => handleOnChange_switch()}
            />
          </div>
        }
        cardActions={ 
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.push({pathname: "/user/climate/sensor-settings"})}
            >
              Go Back
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <DeviceDetail errorMsg={errorMsg} isEdit={props.isEdit} type={props.type} />
        { sensorSetting.sensorSettingDetailList && sensorSetting.sensorSettingDetailList.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Typography className={styles.formTitle}>{item.sensorSettingDetailName} ({item.deviceFunctionUnit ? item.deviceFunctionUnit : "-"})</Typography>
              <Grid container spacing={4}>
                <Grid item xs={4} className={styles.grid}>
                  <Typography className={styles.formLabel}>{props.type==="Environment" ? "Minimum" : "On"}:</Typography>
                  <TextField 
                    id={"minValue"+index}
                    type="Number"
                    variant="outlined" 
                    onInput={(e)=>{ 
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                    }}
                    onChange={(e) => handleOnChange_text(e, index)}
                    placeholder={props.type==="Environment" ? "Enter minimum value" : "Enter on value"}
                    value={renderValue(item.minValue)}
                    errorMsg={errorMsg}
                  />
                </Grid>
                <Grid item xs={4} className={styles.grid}>
                  <Typography className={styles.formLabel}>{props.type==="Environment" ? "Maximum" : "Off"}:</Typography>
                  <TextField 
                    id={"maxValue"+index}
                    type="Number"
                    variant="outlined" 
                    onInput={(e)=>{ 
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                    }}
                    onChange={(e) => handleOnChange_text(e, index)}
                    placeholder={props.type==="Environment" ? "Enter maximum value" : "Enter off value"}
                    value={renderValue(item.maxValue)}
                    errorMsg={errorMsg}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })}
      </Card>
    </React.Fragment>
  );
}
