/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Modal from "shared-components/Modal/Modal";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import CustomIconButton from "shared-components/Button/IconButton";

import { useInterval } from 'common/utils';
import { useRouteCanWrite } from "hooks";
import { rowsPerTable } from "config";
import { reportConfigTableHead } from "enums/UserPortal/TableHeaders";
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import { GetAllQCReportConfiguration, DeleteQCReportConfiguration } from "services/UserPortal/ReportService";

import report from "assets/icons/orange/view-report.svg";
import qualityControl from "assets/icons/black/quality-control.svg";
import enter from "assets/icons/orange/enter.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/report.module.scss";

export default function Configurations(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.report.report.totalCount);
  const results = useSelector(store => store.user.report.report.configurations);
  const [openModal, setOpenModal] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [page, setPage] = React.useState(0);

  const count = Math.ceil(totalCount / rowsPerTable);
  
  const prop = props.location.state ?? {};

  const handleOnChange_select = (e) => {

  }

  const handleModal = (id) => {
    setId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteQCReportConfiguration(id))
    .then(() =>{
      resetState();
      getAllConfiguration(0);
    })
  }

  const resetState = () => {
    setId(null);
    setPage(0);
    setOpenModal(!openModal);
  }
  
  const getAllConfiguration = (pageNo, stopLoading) => {
    dispatch(GetAllQCReportConfiguration({page: pageNo, stopLoading}));
  }

  React.useEffect(() => {
    getAllConfiguration(page);
  },[page]);

  useInterval(() => {
    getAllConfiguration(page, true);
  });

  return (
    <React.Fragment>
      <Card 
        title = {"Report Dashboard - " + (prop.title)}
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.push({pathname: "/user/report/dashboard/quality-control", state: {title: prop.title}})}
            >
              Go Back
            </Button>
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table 
            className={styles.mainTable}
            header={reportConfigTableHead}
          >
            { results.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.configurationName}</TableCell> 
                  <TableCell>{item.vegetableType}</TableCell> 
                  <TableCell>{item.xaxis}</TableCell> 
                  <TableCell>{item.yaxis}</TableCell> 
                  <TableCell>{moment(item.startDate).format('DD/MM/YYYY')} - {moment(item.endDate).format('DD/MM/YYYY')}</TableCell> 
                  <TableCell>{item.visualisationType}</TableCell> 
                  <TableCell align="right">
                    <IconButton 
                      onClick={() => history.push({pathname: "/user/report/dashboard/quality-control/configurations/report/" + item.id, state: {title: prop.title}})}
                    >
                      <img className={styles.icon} src={report} alt="report" />
                    </IconButton>
                    {/* {canWrite && */}
                      <React.Fragment>
                        <Link to={{pathname: "/user/report/dashboard/quality-control/configurations/" + item.id, state: {id: item.id}}}>
                          <CustomIconButton type="edit" />
                        </Link>
                        <CustomIconButton
                          type="delete" 
                          onClick={() => handleModal(item.id)}
                        />
                      </React.Fragment>
                    {/* } */}
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this item? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
