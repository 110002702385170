// Batch Upload Devices
// Edit Batch Upload
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// @mui/material
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import Autocomplete from "shared-components/Select/Autocomplete";
import IconButton from "shared-components/Button/IconButton";

import { rowsPerTable } from "config";
import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { deviceBatchTableHead } from "enums/AdminPortal/TableHeaders";
import { setBatchValues, updateBatchRequest, updateBatchesRequest, deleteBatchRequest, resetBatch, reset } from "./store/device";
import { GetAllDevice, CreateBatchDevice } from "services/AdminPortal/DeviceService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/device.module.scss";

export default function UploadedData() {
  let history = useHistory();
  const dispatch = useDispatch();
  const deviceNames = useSelector(store => store.admin.device.device.deviceNames);
  const devices = useSelector(store => store.admin.device.device.batchDevices);
  const device = useSelector(store => store.admin.device.device.batchDevice);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [deviceIndex, setDeviceIndex] = React.useState(null);
  const [errorMode, setErrorMode] = React.useState(null);
  const [errorFields, setErrorFields] = React.useState([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);

  const count = Math.ceil(devices.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_text = (e) => {
    dispatch(updateBatchRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateBatchRequest({deviceName: value}));
  };

  const handleButtonClick_edit = (lineNo) => {
    setDeviceIndex(lineNo);
    setIsUpdate(true);
    dispatch(setBatchValues(devices.find((item) => item.lineNo === lineNo)));
  }

  const handleModal_delete = (lineNo) => {
    setDeviceIndex(lineNo);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(deleteBatchRequest(deviceIndex));
    setOpenDeleteModal(!openDeleteModal);
    resetState();
  }

  const handleButtonClick_confirm = () => {
    let value = Object.assign({}, device);
    if ((!value.manufacturerID || !value.manufacturerName) || (!value.deviceParent && !value.deviceChilds)) {
      value.isDraft = true;
    } else {
      value.isDraft = false;
    }
    dispatch(updateBatchesRequest({deviceIndex, value}));
    resetState();
  }

  const handleButtonClick_save = () => {
    if (validateMissingFields().length > 0) {
      handleModal_warning();
    } else {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateBatchDevice())
        .then((response) => {
          if (response.error && response.payload.message !== "An internal error occurred during your request!") {
            dispatch(setDirty(true));
            handleModal_warning();
            setErrorFields([response.payload.message]);
            setErrorMode("Invalid");
          } else {
            history.push("/admin/device");
          }
        });
      })
    }
  }

  const validateMissingFields = () => {
    let errors = [];
    for (let obj of devices) {
      if (_.isEmpty(obj.deviceType)) {
        errors = _.union(errors, ["Device Type"]);
      }
      if (_.isEmpty(obj.deviceFunction)) {
        errors = _.union(errors, ["Device Functions"]);
      }
    }
    setErrorFields(errors);
    setErrorMode("Missing");
    return errors;
  }

  const resetState = () => {
    setIsUpdate(false);
    setDeviceIndex(null);
    setPage(0);
    dispatch(resetBatch());
  }

  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetAllDevice());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  }, []);

  return (
    <React.Fragment>
      <Card 
        title = "Uploaded Data"
        cardActions={
          <React.Fragment>
            <Button disabled />
            <Button
              className={styles.button}
              onClick={() => handleButtonClick_save()}
              disabled={isUpdate}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            header={deviceBatchTableHead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(devices, [orderBy], [order])
            .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
            .map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    { isUpdate && deviceIndex === item.lineNo
                    ? <TextField 
                        id="deviceType"
                        variant="outlined" 
                        placeholder=""
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(device.deviceType)}
                      />
                    : item.deviceType
                    }
                  </TableCell>
                  <TableCell>
                    { isUpdate && deviceIndex === item.lineNo
                    ? <Autocomplete
                        id="deviceName"
                        freeSolo
                        inputProps={{ maxLength: 50 }}
                        onInputChange={(e, newInputValue) => {
                          handleOnChange_autocomplete(newInputValue);
                        }}
                        value={renderValue(device.deviceName)}
                        options={deviceNames}
                        placeholder="Please select or enter a device name" 
                      />
                    : item.deviceName
                    }
                  </TableCell>
                  <TableCell>
                    { isUpdate && deviceIndex === item.lineNo
                    ? <TextField 
                        id="deviceDesc"
                        variant="outlined" 
                        placeholder=""
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(device.deviceDesc)}
                      />
                    : item.deviceDesc
                    }
                  </TableCell>
                  <TableCell>
                    { isUpdate && deviceIndex === item.lineNo
                    ? <TextField 
                        id="deviceFunction"
                        variant="outlined" 
                        placeholder=""
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(device.deviceFunction)}
                      />
                    : item.deviceFunction
                    }
                  </TableCell>
                  <TableCell>
                    { isUpdate && deviceIndex === item.lineNo
                    ? <TextField 
                        id="manufacturerID"
                        variant="outlined" 
                        placeholder=""
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(device.manufacturerID)}
                      />
                    : item.manufacturerID
                    }
                  </TableCell>
                  <TableCell>
                    { isUpdate && deviceIndex === item.lineNo
                    ? <TextField 
                        id="manufacturerName"
                        variant="outlined" 
                        placeholder=""
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(device.manufacturerName)}
                      />
                    : item.manufacturerName
                    }
                  </TableCell>
                  <TableCell>
                    { isUpdate && deviceIndex === item.lineNo
                    ? <TextField 
                        id="manufacturerContact"
                        variant="outlined" 
                        placeholder=""
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(device.manufacturerContact)}
                      />
                    : item.manufacturerContact
                    }
                  </TableCell>
                  <TableCell>
                    { isUpdate && deviceIndex === item.lineNo
                    ? <TextField 
                        id="deviceParent"
                        variant="outlined" 
                        placeholder=""
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(device.deviceParent)}
                      />
                    : item.deviceParent
                    }
                  </TableCell>
                  <TableCell>
                    { isUpdate && deviceIndex === item.lineNo
                    ? <TextField 
                        id="deviceChilds"
                        variant="outlined" 
                        placeholder=""
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(device.deviceChilds)}
                      />
                    : item.deviceChilds
                    }
                  </TableCell>
                  <TableCell align="right">
                    { isUpdate && deviceIndex === item.lineNo
                    ? <React.Fragment>
                        <IconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirm()}
                        />
                        <IconButton 
                          type="close"
                          onClick={() => resetState()}
                        />
                      </React.Fragment>
                    : <React.Fragment>
                        <IconButton 
                          type="edit"
                          onClick={() => handleButtonClick_edit(item.lineNo)}
                          disabled={(deviceIndex && deviceIndex !== item.lineNo)}
                        />
                        <IconButton
                          type="delete" 
                          onClick={() => handleModal_delete(item.lineNo)}
                          disabled={(deviceIndex && deviceIndex !== item.lineNo)}
                        />
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this device? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning()}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title={errorMode + " Fields"}
        content={
          <React.Fragment>
            <Typography>Please check these fields again:</Typography>
            <Typography className={styles.errorList}>{errorFields.join(", ")}</Typography>
          </React.Fragment>
        }
        actions={
          <Button className={styles.button} onClick={() => handleModal_warning()}>OK</Button>
        }
      />
    </React.Fragment>
  );
}
