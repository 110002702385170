import { combineReducers } from "redux";
import homeReducer from '../components/AdminPortal/Home';
import companyReducer from '../components/AdminPortal/Company';
import farmReducer from '../components/AdminPortal/Farm';
import userReducer from '../components/AdminPortal/User';
import roleReducer from '../components/AdminPortal/Role';
import moduleReducer from '../components/AdminPortal/Module';
import productReducer from '../components/AdminPortal/Product/store';
import rawMaterialReducer from '../components/AdminPortal/RawMaterial';
import customerReducer from '../components/AdminPortal/Customer';
import supplierReducer from '../components/AdminPortal/Supplier';
import vehicleReducer from '../components/AdminPortal/Vehicle';
import deviceReducer from '../components/AdminPortal/Device/store';
import deviceTaggingReducer from '../components/AdminPortal/DeviceTagging/store';
import manufacturerReducer from '../components/AdminPortal/DeviceSettings';
import cageReducer from '../components/AdminPortal/Cages';
import warehouseReducer from '../components/AdminPortal/Warehouse';
import capacityReducer from '../components/AdminPortal/Capacity/store';
import equipmentReducer from '../components/AdminPortal/Equipment';
import humanResourceReducer from '../components/AdminPortal/HumanResource/store';
import recipeReducer from '../components/AdminPortal/Recipe';
import rfidReducer from '../components/AdminPortal/RFID';
import adminLogReducer from '../components/AdminPortal/AdminLog';
import reportReducer from '../components/AdminPortal/Report'

const adminReducer = combineReducers({
  role: roleReducer,
  home: homeReducer,
  company: companyReducer,
  farm: farmReducer,
  user: userReducer,
  modules: moduleReducer,
  product: productReducer,
  rawMaterial: rawMaterialReducer,
  customer: customerReducer,
  supplier: supplierReducer,
  vehicle: vehicleReducer,
  device: deviceReducer,
  deviceTagging: deviceTaggingReducer,
  manufacturer: manufacturerReducer,
  cage: cageReducer,
  warehouse: warehouseReducer,
  capacity: capacityReducer,
  equipment: equipmentReducer,
  humanResource: humanResourceReducer,
  recipe: recipeReducer,
  rfid: rfidReducer,
  adminLog: adminLogReducer,
  report: reportReducer,
})

export default adminReducer