import { combineReducers } from "redux";
import product from "./product";
import packageSettings from "./packageSettings";
import search from "./search";
import lookup from "./lookup";

const productReducer = combineReducers({
  product,
  packageSettings,
  search,
  lookup,
});

export default productReducer;