// Create Edit Product
// Step 4: Create Task
import React from "react";
import { useSelector } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import TaskTable from "./TaskTable"
import EquipmentTaskTable from "./EquipmentTaskTable"
import styles from "assets/jss/components/AdminPortal/product.module.scss";

export default function CreateTask(props) {
  const process = useSelector(store => store.admin.product.product.process);
  const [tab, setTab] = React.useState(0);

  return (
    <Card 
      classes={{
        root: styles.cardPaper
      }}
      title = {"Steps For Configuration: " + process.processName + (props.productID ? " (" + props.productID + ")" : "")}
      subheader={
        <React.Fragment>
          Click and drag on table row to reorder task sequence
          <Tabs 
            tabs={["Manual Flow", "Equipment Flow"]}
            value={tab} 
            onChange={(e,value) => setTab(value)}
            disabled={props.editMode}
          />
        </React.Fragment>
      }
    >
      {tab === 0
      ? <TaskTable {...props} />
      : <EquipmentTaskTable {...props} />
      }
    </Card>
  )
}
