import { combineReducers } from "redux";
import master from "./master";
import rm from "./rm";
import finishedGoods from "./finishedGoods";
import rackUsage from "./rackUsage";
import search from "./search";

const warehouseReducer = combineReducers({
  master,
  rm,
  finishedGoods,
  rackUsage,
  search
});

export default warehouseReducer;