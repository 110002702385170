import React from "react";
import { useDispatch } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import NewEntry from "./NewEntry";
import PastRecords from "./PastRecords";
import { setSearchText } from "store/general";

export default function LogSheet(props) {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);

  React.useEffect(() => {
    setTab(props.location.state ? props.location.state.tab : 0);
  },[props.location.state]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Log Sheet"
        subheader={
          <Tabs 
            tabs={["New Entry", "Past Records"]}
            value={tab} 
            onChange={(e,value) => setTab(value)}
          />
        }
      >
        {tab === 0
        ? <NewEntry />
        : <PastRecords />
        }
      </Card>
    </React.Fragment>
  );
}
