// Farm List
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// @mui/material
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import Empty from "error-page/Empty";
import { rowsPerTable } from "config";
import { GetUserProfile, GetAllFarm, DeleteFarm } from "services/AdminPortal/FarmService";
import { farmTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/farm.module.scss";

import { filterActionTableHead } from "common/functions";
import { useRouteCanWrite } from "hooks";

export default function FarmList(props) {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.admin.farm.totalCount);
  const farms = useSelector(store => store.admin.farm.farms);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('farmName');
  const [page, setPage] = React.useState(0);
  const [farmId, setFarmId] = React.useState(null);   // to know which farm is selected for edit
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleButtonClick_detail = (id) => {
    props.setFarmId(id);
    props.setBankDetails(true);
  }

  const handleModal_delete = (id) => {
    setFarmId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteFarm(farmId))
    .then(() => {
      dispatch(GetAllFarm({page: 0}));
      dispatch(GetUserProfile());
      resetState();
    })
  }
  
  const resetState = () => {
    setFarmId(null);
    setPage(0);
    setOpenDeleteModal(!openDeleteModal);
  }

  React.useEffect(() => {
    dispatch(GetAllFarm({page}));
  },[page]);

  return (
    <React.Fragment>
      { totalCount > 0 
      ? <React.Fragment>
          <Card 
            title = {"Farm List under " + userDetail.companyName}
          >
            <div className={styles.table}>
              <Table
                header={filterActionTableHead(farmTableHead, canWrite)}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                {farms.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell className={styles.tableName}>{item.farmName}</TableCell>
                      <TableCell className={styles.tableAddress}>{item.address}</TableCell>
                      <TableCell>{item.landSize + " Hectares"}</TableCell>
                      <TableCell>{item.numberOfBanks}<span className={styles.detail} onClick={()=>handleButtonClick_detail(item.id)}>Details</span></TableCell>
                      <TableCell>{item.latitude}°N, {item.longitude}°E</TableCell>
                      <TableCell align="right">
                        {userDetail.farms.find((userFarm) => userFarm.id === item.id) && canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/farm/"+item.id}}>
                              <IconButton type="edit" />
                            </Link>
                            <IconButton 
                              type="delete"
                              onClick={() => handleModal_delete(item.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={page+1}
                onChange={(e, pageNo)=>setPage(pageNo-1)}
              />
            }
          </Card>
          <Modal
            open={openDeleteModal}
            onClose={() => handleModal_delete(null)}
            icon={<img className={styles.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete one of your farm? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
                <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
        </React.Fragment>
      : <Empty description={"Your page is empty. " + (canWrite ? "Add a farm to get started!" : "")}/>
      }
    </React.Fragment>
  );
}
