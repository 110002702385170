/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Select from "shared-components/Select/Select";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { amrMissionStatus } from "enums/Constants"; 
import { amrTaskTableHead } from "enums/UserPortal/TableHeaders";
import { filterParam, filterUrl } from 'common/functions';
import { setValues } from "../store/adhoc";
import { GetInventoryCount } from "services/UserPortal/AMRService";

import styles from "assets/jss/components/UserPortal/amr.module.scss";

export default function LevelDetails(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const result = useSelector(store => store.user.amr.inventoryCount.result);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {result && result.length && result[0].zone.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <Grid item xs={12}>
                <Typography className={styles.zoneTitle}>{item.zoneList}</Typography>
              </Grid>
              {item.inventoryList.map((item, j) => {
                if (item.count) {
                  return (
                    <Grid key={j} item xs={2}>
                      <div className={styles.palletContainer}>
                        <Typography className={styles.palletCount}>{item.count}</Typography>
                        <Typography>{item.palletType}</Typography>
                      </div>
                    </Grid>
                  )
                }
              })}
            </React.Fragment>
          )
        })}
      </Grid>
    </React.Fragment>
  );
}
