// Admin Log Search
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { reset } from ".";
import { setSearchText } from "store/general";
import { formatNumbers } from "common/functions";
import { adminLogTableHead } from "enums/AdminPortal/TableHeaders";
import { SearchAdminLog, ExportAdminLog } from "services/AdminPortal/AdminLogService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/AdminPortal/adminLog.module.scss";

const FileDownload = require('js-file-download');
export default function SearchAdminLogResult() {
  const dispatch = useDispatch();
  const searchResultsCount = useSelector(store => store.admin.adminLog.totalCount);
  const searchResults = useSelector(store => store.admin.adminLog.logs);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('eventType');
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);

  const count = Math.ceil(searchResultsCount / rowsPerTable);
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleButtonClick_export = () => {
    dispatch(ExportAdminLog({keyword: params.keyword}))
    .then((response) => {
      FileDownload(response.payload.data, 'admin_log.xlsx');
    });
  };

  React.useEffect(() => {
    dispatch(reset());
    dispatch(SearchAdminLog({keyword: params.keyword, page}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value) {
      return (
        <Highlighter
          highlightClassName={styles.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      <Typography className={styles.result}>{formatNumbers(searchResultsCount) + " Search Result(s) From 1 Table:  ‘" + params.keyword + "’"}</Typography>
      { searchResultsCount > 0 &&
        <React.Fragment>
          <Card 
            title = {
              <React.Fragment>
                {searchResultsCount + " Result(s) From Administration Log"}
                <IconButton 
                  className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
            action={
              <Button 
                className={styles.buttonSecondary}
                onClick={() => handleButtonClick_export()}
              >
                Export
              </Button>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={styles.table}>
                  <Table
                    header={adminLogTableHead}
                    // order={order}
                    // orderBy={orderBy}
                    // onRequestSort={handleRequestSort}
                  >
                    { searchResults.map((item,index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{highlightValue(item.eventType)}</TableCell>
                          <TableCell>{highlightValue(item.eventDetail)}</TableCell>
                          <TableCell>{item.portalType}</TableCell>
                          <TableCell>{highlightValue(item.userName)}</TableCell>
                          <TableCell>{highlightValue(moment(item.date).format("DD/MM/yyyy H:mm:ss"))}</TableCell>
                          <TableCell>{highlightValue(item.clientIpAddress)}</TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
