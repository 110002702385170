// Create Edit Farm
// Step 1: Farm Details
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";

import FarmStepper from "./FarmStepper";
import Maps from "./Maps";
import { isPositiveDecimal } from "common/validations";
import { renderValue } from "common/functions";
import { updateFarmRequest } from "./";

import styles from "assets/jss/components/AdminPortal/farm.module.scss";

export default function FarmForm(props) {
  const dispatch = useDispatch();
  const farm = useSelector(store => store.admin.farm.farm);
  const [address, setAddress] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateFarmRequest({[e.target.id] : e.target.value}));
  };

  const handleButtonClick_next = () => {
    if (validateFields()) {
      props.setStep(1);
    }
  };

  const validateFields = () => {
    if (_.isEmpty(farm.farmName)) {
      setErrorMsg({field: "farmName", msg: "Please add in your farm name"});
      return false;
    }
    if (_.isEmpty(farm.address)) {
      setErrorMsg({field: "address", msg: "Please add in your farm address"});
      return false;
    }
    if (!farm.landSize) {
      setErrorMsg({field: "landSize", msg: "Please enter a farm size"});
      return false;
    }
    if (farm.landSize && !isPositiveDecimal(farm.landSize)) {
      setErrorMsg({field: "landSize", msg: "Please enter a valid farm size"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }
  
  return (
    <Card 
      title="Step 1: Create a Farm" 
      subheader={
        <React.Fragment>
          Please make sure you fill in all the fields before proceeding to the next step.
          <FarmStepper activeStep={props.step} />
        </React.Fragment>
      }
      cardActions={
        <React.Fragment>
          <Button disabled />
          <Button
            className={styles.button}
            onClick={()=>handleButtonClick_next()}
          >
            Next
          </Button>
        </React.Fragment>
      }
    >
      <div className={styles.content}>
        <div className={styles.subContent}>
          <TextField 
            label={<React.Fragment>Farm Name <span className={styles.orange}>*</span></React.Fragment>}
            id="farmName"
            variant="outlined" 
            inputProps={{ maxLength: 35 }}
            placeholder="Enter Farm Name"
            onChange={(e) => handleOnChange_text(e)}
            value={renderValue(farm.farmName)}
            errorMsg={errorMsg}
          />
          <TextField 
            label={<React.Fragment>Farm Address <span className={styles.orange}>*</span></React.Fragment>}
            id="address"
            variant="outlined" 
            inputProps={{ maxLength: 255 }}
            placeholder="Enter Farm Address"
            onChange={(e) => handleOnChange_text(e)}
            onKeyUp={(e) => setAddress(e.target.value)}
            value={renderValue(farm.address)}
            errorMsg={errorMsg}
          />
          <TextField 
            label={<React.Fragment>Land Size (Hectares) <span className={styles.orange}>*</span></React.Fragment>}
            id="landSize"
            className={styles.textField}
            variant="outlined" 
            type="Number"
            placeholder="Enter size in hectares"
            onChange={(e) => handleOnChange_text(e)}
            value={renderValue(farm.landSize)}
            errorMsg={errorMsg}
          />
        </div>
        <div className={styles.subContent}>
          <Typography className={styles.label}>Location Map</Typography>
          <Paper className={styles.rightPaper} elevation={0}>
            <Maps address={address} />
          </Paper>
        </div>
      </div>
    </Card>
  )
}
