import React, {useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Table from "shared-components/Table/Table";
import DragTableRow from "shared-components/Table/DragTableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";
import Select from "shared-components/Select/Select";

import { productEquipmentTaskTableHead } from "enums/AdminPortal/TableHeaders";

import { GetEquipmentList, GetEquipmentTaskTypeList } from "services/AdminPortal/ProductService";
import { setValues, updateSubTable } from "./store/product";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/product.module.scss";

export default function EquipmentTaskTable(props) {
  const dispatch = useDispatch();
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct);
  const selectedPreHarvest = useSelector(store => store.admin.product.product.productPreHarvestGrowthProcess);
  const selectedPostHarvest = useSelector(store => store.admin.product.product.productPostHarvestGrowthProcess);
  const process = useSelector(store => store.admin.product.product.process);
  const task = useSelector(store => store.admin.product.product.subTable);
  const equipmentList = useSelector(store => store.admin.product.lookup.taskEquipmentList);
  const taskTypeList = useSelector(store => store.admin.product.lookup.equipmentTaskTypeList);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(null);
  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const [tasks, setTasks] = React.useState([]);
  
  const handleOnChange_select = (e) => {
    dispatch(updateSubTable({[e.target.name]: e.target.value}))
  };

  const handleOnChange_text = (e) => {
    dispatch(updateSubTable({[e.target.id]: e.target.value}))
  };

  const handleButtonClick_add = () => {
    tasks.push({taskSequence: tasks.length+1, taskCategory: 'Equipment Flow'});
    dispatch(setValues({subTable: {taskSequence: tasks.length, taskCategory: 'Equipment Flow'}}));
    setEditIndex(tasks.length-1);
    props.setEditMode(true);
  }

  const handleButtonClick_edit = (item, index) => {
    dispatch(setValues({subTable: item}));
    setEditIndex(index);
    props.setEditMode(true);
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      tasks[editIndex] = task;
      updateProcess();
      resetState();
    }
  }

  const handleButtonClick_cancel = () => {
    if (!task.id) {
      tasks.splice(editIndex, 1);
      // dispatch(setValues({process: payload}));
    }
    resetState();
  }

  const handleModal = (index) => {
    setDeleteIndex(index);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    setOpenModal(!openModal);
    let payload = _.cloneDeep(tasks);
    payload.splice(deleteIndex, 1);
    payload.forEach((item, index) => {
      item.taskSequence = index+1
    })
    setTasks(payload);
  }

  const resetState = () => {
    setErrorMsg(false);
    setEditIndex(null);
    dispatch(setValues({subTable: {}}));
    props.setEditMode(false);
  }

  const validateFields = () => {
    if (_.isEmpty(task.taskName)) {
      setErrorMsg({field: "taskName", msg: "Please add in Task Name"});
      return false;
    }
    if (_.isEmpty(task.equipment)) {
      setErrorMsg({field: "equipment", msg: "Please select equipment"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const onMoveItem = useCallback((dragIndex, hoverIndex) => {
    let result = _.cloneDeep(tasks);
    const [removed] = result.splice(dragIndex, 1);
    result.splice(hoverIndex, 0, removed);
    result.map((item, index) => {
      item.taskSequence = index+1;
      return item;
    })
    setTasks(result);
  }, [process]);

  const taskTable = () => {
    let component = [];
    tasks.map((item,index) => {
      component.push(
        <DragTableRow key={index} index={index} id={item} onMoveItem={onMoveItem} disabled={!(!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess")))}>
          <TableCell>{item.taskSequence}</TableCell>
          <TableCell>
            {(props.editMode && editIndex === index)
            ?
            <TextField 
              className={styles.alignTextfield}
              id="taskName"
              variant="outlined" 
              inputProps={{ maxLength: 100 }}
              onChange={(e) => handleOnChange_text(e)}
              value={task.taskName}
              errorMsg={errorMsg}
            />
            :
            item.taskName
            }
          </TableCell>
          <TableCell>
            {(props.editMode && editIndex === index)
            ?
            <Select
              name="equipment"
              onChange={(e)=> handleOnChange_select(e)}
              value={task.equipment}
              placeholder="Select a Equipment"
            >
              {equipmentList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })} 
            </Select>
            :
            item.equipment
            }
          </TableCell>
          <TableCell>
            {(props.editMode && editIndex === index)
            ?
            <Select
                name="taskType"
                onChange={(e)=> handleOnChange_select(e)}
                value={task.taskType}
                placeholder="Select a Task Type"
                disabled={!task.equipment}
              >
                {taskTypeList.map((item, index) => {
                  return <MenuItem key={index} value={item}>{item}</MenuItem>;
                })} 
            </Select>
            : item.taskType
            }
          </TableCell>
          <TableCell>
            {!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess"))
            ? (editIndex === index)
              ? <React.Fragment>
                  <IconButton 
                    type="confirm"
                    onClick={() => handleButtonClick_confirm(index)}
                  />
                  <IconButton 
                    type="close"
                    onClick={() => handleButtonClick_cancel()}
                  />
                </React.Fragment>
              : <React.Fragment>
                  <IconButton
                    type="edit" 
                    onClick={() => handleButtonClick_edit(item, index)}
                    disabled={props.editMode}
                  />
                  <IconButton 
                    type="delete"
                    onClick={() => handleModal(index)}
                    disabled={props.editMode}
                  />
                </React.Fragment>
            : <TableCell />
            }
          </TableCell>
        </DragTableRow>
      )
    })
    return component;
  }

  const updateProcess = () => {
    let processClone = _.cloneDeep(process);
    let processPayload = _.cloneDeep(selectedPostHarvest);
    if (processClone && processPayload.length) {
      let payload = _.filter(processClone.productTask, (item) => item.taskCategory != 'Equipment Flow');
      if (props.type === "productPreHarvestGrowthProcess") {
        processPayload = _.cloneDeep(selectedPreHarvest);
      }
      processClone.productTask = _.union(payload, tasks);
      processPayload[props.growthEditIndex].productTask = _.union(payload, tasks);
      dispatch(setValues({[props.type]: processPayload, process: processClone}));
    }
  }

  React.useEffect(() => {
    updateProcess();
  },[tasks]);

  React.useEffect(() => {
    dispatch(GetEquipmentTaskTypeList(task.equipment));
  },[task.equipment]);

  //componentDidMount
  React.useEffect(() => {
    dispatch(GetEquipmentList());
    setTasks(_.filter(process.productTask, (item) => item.taskCategory === 'Equipment Flow'));
  },[]);
  
  return (
    <React.Fragment>
      <Table
        className={styles.table}
        header={productEquipmentTaskTableHead}
      >
        <DndProvider backend={HTML5Backend}>
          {taskTable()}
        </DndProvider>
      </Table>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this task? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      {!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess")) //check if user is in a non mix flow, or in a postharvest mix flow
      &&
        <Button
          className={clsx(styles.buttonSecondary, styles.addMoreProcess, styles.rawMaterialButton)}
          onClick={()=>handleButtonClick_add()}
          disabled={props.editMode}
        >
          + Add More Task
        </Button>
      }
    </React.Fragment>
  )
}
