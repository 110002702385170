// Groupings
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { rowsPerTable } from "config";
import { groupingsTableHead } from "enums/AdminPortal/TableHeaders";
import { updateRequest, updateGroupRequest } from ".";
import { GetAllRFIDGroup, DeleteRFIDGroup, CreateOrUpdateRFIDGroup } from "services/AdminPortal/RFIDService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/rfid.module.scss";

export default function Groupings(props) {
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.admin.rfid.totalGroupCount);
  const groupings = useSelector(store => store.admin.rfid.groupings);
  const grouping = useSelector(store => store.admin.rfid.grouping);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('serialNo');
  const [page, setPage] = React.useState(0);
  const [isAddEdit, setIsAddEdit] = React.useState(false);
  const [groupingId, setGroupingId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_text = (e) => {
    dispatch(updateGroupRequest({[e.target.id] : e.target.value}));
  };

  const handleButtonClick_edit = (id) => {
    dispatch(setDirty(true));
    setIsAddEdit(true);
    setGroupingId(id);
    dispatch(updateGroupRequest(_.find(groupings, (item) => item.id === id)));
  }

  const handleButtonClick_add = () => {
    dispatch(setDirty(true));
    const payload = [...groupings, {id: 0}];
    setIsAddEdit(true);
    setGroupingId(0);
    setPage(Math.ceil(payload.length / rowsPerTable)-1);
    dispatch(updateRequest({groupings: payload}));
  }

  const handleModal_delete = (id) => {
    setGroupingId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteRFIDGroup(groupingId))
    .then(() =>{
      dispatch(GetAllRFIDGroup());
      setPage(0);
      resetState();
    })
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateRFIDGroup())
      .then((response) => {
        if (response.error) {
          if (response.payload.message.includes("Name")) {
            setErrorMsg({field: "groupName", msg: "Group name already exist"});
          }
        } else {
          dispatch(GetAllRFIDGroup());
          resetState();
        }
      })
    }
  }

  const handleButtonClick_cancel = () => {
    if (groupingId === 0) {
      dispatch(updateRequest({groupings: _.filter(groupings, (item) => item.id !== groupingId)}));
      setPage(0);
    }
    resetState();
  }

  const validateFields = () => {
    if (!grouping.groupName) {
      setErrorMsg({field: "groupName", msg: "Please enter group name"});
      return false;
    } else {
      setErrorMsg(false);
      return true;
    }
  }

  const resetState = () => {
    setIsAddEdit(false);
    setGroupingId(null);
    setErrorMsg(false);
    setOpenModal(false);
    dispatch(setDirty(false));
    dispatch(updateRequest({grouping: {}}));
  }

  React.useEffect(() => {
    dispatch(GetAllRFIDGroup());
  },[]);

  return (
    <React.Fragment>
      <Card 
        classes={{
          content: styles.cardContent
        }}
        title="Manage Groupings"
        subheader="Create and Edit Grouping of RFID Below:"
        cardActions={
          <Button
            className={styles.buttonSecondary}
            onClick={()=>props.setManageGroupings(false)}
            disabled={isAddEdit}
          >
            Go Back
          </Button>
        }
      >
        <Paper elevation={0} className={styles.groupPaper}>
          <Table
            className={styles.table}
            header={groupingsTableHead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(groupings, [orderBy], [order])
            .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
            .map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell className={styles.snCell}>
                    {String((page*rowsPerTable)+1+index).padStart(2,'0')}
                  </TableCell>
                  <TableCell>
                    { isAddEdit && groupingId === item.id
                    ? <TextField 
                        id="groupName"
                        variant="outlined" 
                        inputProps={{ maxLength: 255 }}
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(grouping.groupName)}
                        errorMsg={errorMsg}
                      />
                    : item.groupName
                    }
                  </TableCell>
                  <TableCell align="right">
                    { isAddEdit && groupingId === item.id
                    ? <React.Fragment>
                        <IconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirm()}
                        />
                        <IconButton 
                          type="close"
                          onClick={() => handleButtonClick_cancel()}
                        />
                      </React.Fragment>
                    : <React.Fragment>
                        <IconButton 
                          type="edit"
                          onClick={() => handleButtonClick_edit(item.id)}
                          disabled={Boolean(groupingId && groupingId !== item.id)}
                        />
                        <IconButton
                          type="delete" 
                          onClick={() => handleModal_delete(item.id)}
                          disabled={Boolean(groupingId && groupingId !== item.id)}
                        />
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
              disabled={Boolean(isAddEdit)}
            />
          }
          <Button
            className={clsx(styles.buttonSecondary, styles.paperButton)}
            startIcon={<AddIcon />}
            onClick={() => handleButtonClick_add()}
            disabled={isAddEdit}
          >
            Add More Groupings
          </Button>
        </Paper>
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this grouping? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
