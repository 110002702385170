/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// core components
import Card from "shared-components/Card/Card";
import DateTabs from "shared-components/Tabs/DateTabs";

import { setEndDate } from "common/functions";
import { reset } from "../store/costOverview";

import styles from "assets/jss/components/UserPortal/costProductivity.module.scss";
// import { ExportReport, GetActivationRatePercentReport, GetActiveRatePercentReport, GetActiveUsersReport } from "services/AdminPortal/ReportService";

const legendTable = [
  {temp: "15", eff: "0.59"},
  {temp: "14", eff: "0.60"},
  {temp: "13", eff: "0.61"},
  {temp: "12", eff: "0.62"},
  {temp: "11", eff: "0.63"},
  {temp: "10", eff: "0.64"},
  {temp: "9", eff: "0.65"},
  {temp: "8", eff: "0.66"},
  {temp: "7", eff: "0.67"},
  {temp: "6", eff: "0.68"},
  {temp: "5", eff: "0.69"},
  {temp: "4", eff: "0.70"},
  {temp: "3", eff: "0.71"}
]
const FileDownload = require('js-file-download');

export default function Chiller() {
  const dispatch = useDispatch();
  const activeUsers = useSelector(store => store.admin.report.activeUsers);
  const activeRatePercent = useSelector(store => store.admin.report.activeRatePercent);
  const activationRatePercent = useSelector(store => store.admin.report.activationRatePercent);
  const [date, setDate] = React.useState({startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});

  const handleButtonClick_export = () => {
    // dispatch(ExportReport({startDate: date.startDate, endDate: date.endDate}))
    // .then((response) => {
    //   FileDownload(response.payload, 'Overall_Statistics.xlsx');
    // });
  };

  const config = (title) => {
    let array = []
    if (title === "Water Consumption Efficiency") {
      array = activeUsers.map((item) => {
        return Number(item.value)
      })
    }
    return ({
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: "Duration"
        },
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: "Count"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          data: array,
          label: {
            connectorAllowed: false
          },
          pointStart: new Date(date.startDate).getTime(),
          pointInterval: 24 * 3600 * 1000, // one day
          color: "#225422"
        }
      },
      series: [{
        data: array,
        name: title
      }],
    })
  };

  React.useEffect(() => {
    // dispatch(GetActiveUsersReport({startDate: date.startDate, endDate: date.endDate}));
    // dispatch(GetActivationRatePercentReport({startDate: date.startDate, endDate: date.endDate}));
    // dispatch(GetActiveRatePercentReport({startDate: date.startDate, endDate: date.endDate}));
  },[date]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <Card 
      classes={{
        root: styles.cardPaper
      }}
      title = {
        <React.Fragment>
          Chilled Water Temperature = 15°C<br/>
          Energy Efficiency Level = 0.60 kW/RT
        </React.Fragment>
      }
      action={
        <React.Fragment>
          <DateTabs setDate={setDate} date={date} />
        </React.Fragment>
      }
    >
      <HighchartsReact highcharts={Highcharts} options={config("Water Consumption Efficiency")} />
      <div className={styles.legend}>
        <Typography className={styles.legendLabel}>Legend:</Typography>
        <Table className={styles.legendTable}>
          <TableRow>
            <TableCell className={styles.tableCell}>Chilled water temperature (°C)</TableCell>
            {legendTable.map((item, index) => {
              return <TableCell key={index} className={styles.tableCell} align="center">{item.temp}</TableCell>
            })}
          </TableRow>
          <TableRow>
            <TableCell className={styles.tableCell}>Minimum energy efficiency level (kW/RT)</TableCell>
            {legendTable.map((item, index) => {
              return <TableCell key={index} className={styles.tableCell} align="center">{item.eff}</TableCell>
            })}
          </TableRow>
        </Table>
        <Typography>
          <span className={styles.legendLabel2}>Note: </span>
          kW/RT = Total energy consumption of the chilled water system (kWh) during the assessment period + total cooling load produced by the system (Rth) during the assessment period.
        </Typography>
      </div>
    </Card>
  );
}
