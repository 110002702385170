import React from "react";
import PropTypes from "prop-types";
import clsx from 'clsx'
// @mui/material components
import Checkbox from "@mui/material/Checkbox";
// core components
import styles from "assets/jss/shared-components/checkbox.module.scss";


export default function CustomCheckbox({type, ...props}) {

  switch (type) {
    case "outlined":
      return (
        <Checkbox
          className={clsx(props.className, styles.root)}
          disableRipple
          color="default"
          checkedIcon={<span className={clsx(styles.outlinedIcon, styles.checkedOutlinedIcon)} />}
          indeterminateIcon={<span className={clsx(styles.outlinedIcon, styles.indeterminateOutlinedIcon)} />}
          icon={<span className={styles.outlinedIcon} />}
          inputProps={{ 'aria-label': 'decorative checkbox' }}
          {...props}
        />
      );
    default: 
      return (
        <Checkbox
          className={clsx(props.className, styles.root)}
          disableRipple
          color="default"
          checkedIcon={<span className={clsx(styles.icon, styles.checkedIcon)} />}
          indeterminateIcon={<span className={clsx(styles.icon, styles.indeterminateIcon)} />}
          icon={<span className={styles.icon} />}
          inputProps={{ 'aria-label': 'decorative checkbox' }}
          {...props}
        />
      );
  }
}

CustomCheckbox.propTypes = {
  type: PropTypes.string,
};