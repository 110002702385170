/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// core components
import Card from 'shared-components/Card/Card';

import WorkOrder from "./WorkOrder";
import { farmConfig } from "enums/Constants";
import styles from "assets/jss/components/UserPortal/monitoring.module.scss";

export default function AllOverview(props) {
  const bankOverview = useSelector(store => store.user.monitoring.production.bankOverview.bankSummary);

  const bankSummary = bankOverview && _.sortBy(bankOverview, ['bankName']);
  return (
    <div className={styles.cardContainer}>
      <Card
        classes={{
          root: clsx(styles.cardPaper, styles.cardLeft),
          title: styles.cardMargin,
          content: styles.cardMargin
        }}
        title={farmConfig.bank+" Summary"}
      >
        <Table className={styles.dashboard}>
          <TableRow>
            <TableCell className={clsx(styles.noBorder, styles.tableCage)} />
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(styles.noBorder, styles.tableTitle, styles.tableBank)} align="center">{item.bankName}</TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell className={clsx(styles.border, styles.tableTitle)}>Assigned {farmConfig.cages}</TableCell>
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(styles.border)} align="center">{item.assignedCages}</TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell className={clsx(styles.border, styles.tableTitle)}>Unassigned {farmConfig.cages}</TableCell>
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(styles.border)} align="center">{item.unAssignedCages}</TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell className={clsx(styles.border, styles.tableTitle)}>{farmConfig.cages} Reserved</TableCell>
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(styles.border)} align="center">{item.reservedCages}</TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell className={clsx(styles.border, styles.tableTitle)}>Total {farmConfig.cages}</TableCell>
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(styles.border, styles.tableTotal)} align="center">{item.totalCages}</TableCell>
              )
            })}
          </TableRow>
        </Table>
      </Card>
      <WorkOrder bankId={props.bankId} />
    </div>
  );
}
