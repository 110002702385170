import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";
import * as Auth from "auth/Authenticate";

export const GetAllRoles = createAsyncThunk(
  "user/getAllRoles", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/User/GetAllRole", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetUserProfile = createAsyncThunk(
  "user/getUserProfile", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {id: userDetail.id};
    try {
      const response = await FomsMS.get("/User/Get", {params});
      Auth.updateProfile(response.data);
      window.dispatchEvent(new Event("storage"));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateProfile = createAsyncThunk(
  "user/updateProfile", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().settings.profile;
    try {
      const response = await FomsMS.post("/User/UpdateProfile", body);
      localStorage.setItem("userDetail", JSON.stringify(response.data.result));
      window.dispatchEvent(new Event("storage"));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ChangePassword = createAsyncThunk(
  "user/changePassword", 
  async (profile, {rejectWithValue}) => {
    let body = {
      currentPassword: profile.oldPassword,
      newPassword: profile.newPassword
    }
    try {
      const response = await FomsMS.post("/User/ChangePassword", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllUser = createAsyncThunk(
  "user/getAllUser", 
  async (param, {rejectWithValue}) => {
		const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let params = {
      FarmId: find(userDetail.farms, "isActive").id,
      CompanyId: userDetail.companyId,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    }
    try {
      const response = await FomsMS.get("/User/GetAll", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetUser = createAsyncThunk(
  "user/getUser", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/User/Get", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetUserByFarmId = createAsyncThunk(
  "user/getUserByFarmId", 
  async (id, {rejectWithValue}) => {
		const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      userId: id,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/User/GetUserByFarmId", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteUser = createAsyncThunk(
  "user/deleteUser", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/User/Delete", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateUser = createAsyncThunk(
  "user/createUser", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const user = getState().admin.user.user;
    let body = {
      ...user,
      farmId: find(userDetail.farms, "isActive").id,
      companyId: userDetail.companyId,
    }
    try {
      const response = await FomsMS.post("/User/Create", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateUser = createAsyncThunk(
  "user/updateUser", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...getState().admin.user.user,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/User/Update", body, {isNoSnackbar: true});
      if (body.id === userDetail.id) { // if update yourself, update nav
        localStorage.setItem("userDetail", JSON.stringify(response.data.result));
        window.dispatchEvent(new Event("storage"));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const TaggedUsersToFarm = createAsyncThunk(
  "user/taggedUsersToFarm", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      farmId: find(userDetail.farms, "isActive").id,
      emailAddresses: getState().admin.user.existingUsers,
    }
    try {
      const response = await FomsMS.post("/User/TaggedUsersToFarm", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UntaggedUsersFromFarm = createAsyncThunk(
  "user/untaggedUsersFromFarm", 
  async (userId, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      farmId: find(userDetail.farms, "isActive").id,
      userId
    }
    try {
      const response = await FomsMS.post("/User/UntaggedUsersFromFarm", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadBatchTemplate = createAsyncThunk(
  "user/downloadBatchTemplate", 
  async (_, {rejectWithValue}) => {
    const config = {
      responseType: 'blob'
    }
    try {
      const response = await FomsMS.get("/User/DownloadBatchTemplate", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadBatchUser = createAsyncThunk(
  "device/uploadbatchUser", 
  async (file, {rejectWithValue}) => {
    let body = new FormData();
    body.append("File", file);
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let params = {
      FarmId: find(userDetail.farms, "isActive").id,
      CompanyId: userDetail.companyId,
    }
    try {
      const response = await FomsMS.post("/User/UploadBatchUser", body, {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateBatchUser = createAsyncThunk(
  "user/createBatchUser", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().admin.user.batchUsers;
    try {
      const response = await FomsMS.post("/User/CreateBatchUser", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetUserFarmList = createAsyncThunk(
  "user/getUserFarmList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/User/GetUserFarmList");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetActiveFarm = createAsyncThunk(
  "user/getActiveFarm", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/User/GetActiveFarm");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SetActiveFarm = createAsyncThunk(
  "user/setActiveFarm", 
  async (_, {getState, rejectWithValue}) => {
    const newFarm = getState().settings.farm.newFarm;
    const body = {
      farmId: newFarm
    }
    try {
      const response = await FomsMS.post("/User/SetActiveFarm", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchUser = createAsyncThunk(
  "user/searchUser", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/User/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);