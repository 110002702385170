/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch } from "react-redux";
// @mui/material
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import CostReport from "./CostReport";
import RevenueReport from "./RevenueReport";
import ProfitLoss from "./ProfitLoss";
import { setSearchText } from "store/general";
import { reset } from "../store/costOverview";
import { GetSubSaleOrderLookup, GetCustomerLookUp } from "services/UserPortal/CostProductivityService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import styles from "assets/jss/components/UserPortal/costProductivity.module.scss";

export default function Report() {
  const dispatch = useDispatch();
  // const [selectedParam, setSelectedParam] = React.useState(null);
  const [tab, setTab] = React.useState(0);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetSubSaleOrderLookup());
    dispatch(GetCustomerLookUp());
    dispatch(GetProductList());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <CostReport />
      case 1:
        return <RevenueReport />
      case 2:
        return <ProfitLoss />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title = "Cost & Efficiency"
        subheader={//selectedParam &&
          <React.Fragment>
            <Tabs 
              tabs={["Cost Report", "Revenue Report", "Profit and Loss"]}
              value={tab} 
              onChange={(e,value) => setTab(value)}
            />
          </React.Fragment>
        }
      >
        {renderComponent()}
        {/* {selectedParam 
        ? renderComponent()
        : <React.Fragment>
            <Typography className={styles.title}>Please choose a parameter to compare the cost and revenue metrics:</Typography>
            <div className={styles.paramButton}>
              <Button
                className={styles.buttonSecondary}
                onClick={()=>setSelectedParam("subSaleOrderList")}
              >
                Sub Sales Orders
              </Button>
              <Button
                className={styles.buttonSecondary}
                onClick={()=>setSelectedParam("customerList")}
              >
                Customers
              </Button>
              <Button
                className={styles.buttonSecondary}
                onClick={()=>setSelectedParam("productList")}
              >
                Products
              </Button>
            </div>
          </React.Fragment>
        } */}
      </Card>
    </React.Fragment>
  );
}
