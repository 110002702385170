import { createSlice } from "@reduxjs/toolkit";

import { GetAllActiveGraph, GetAllDashboardSetting, GetAllQCReportConfiguration, GetQCReportGraphById, GetQCReportConfiguration, GenerateGraph, 
  GetDataSourceList, GetVegetableType, GetXaxisVariableName, GetYaxisVariableName, GetVisualisationType } from "services/UserPortal/ReportService";

// initial state
const initialState = {
  details: [],
  settings: [],
  dashboardList: [],
  // configurations
  totalCount: 0,
  configurations: [],
  config: {},
  graph: {},
  //lookup
  dataSourceList: [],
  vegetableTypeList: [],
  xaxisList: [],
  yaxisList: [],
  visualisationTypeList: [],
};

const reporting = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.config[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllActiveGraph.fulfilled, (state, action) => {
        state.details = action.payload.result;
      })
      .addCase(GetAllDashboardSetting.fulfilled, (state, action) => {
        state.settings = action.payload.result;
      })
      .addCase(GetAllQCReportConfiguration.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.configurations = action.payload.result.items;
      })
      .addCase(GetQCReportConfiguration.fulfilled, (state, action) => {
        state.config = action.payload.result;
      })
      .addCase(GetQCReportGraphById.fulfilled, (state, action) => {
        state.graph = action.payload.result;
      })
      .addCase(GenerateGraph.fulfilled, (state, action) => {
        state.graph = action.payload.result;
      })
      //lookup
      .addCase(GetDataSourceList.fulfilled, (state, action) => {
        state.dataSourceList = action.payload.result;
      })
      .addCase(GetVegetableType.fulfilled, (state, action) => {
        state.vegetableTypeList = action.payload.result;
      })
      .addCase(GetXaxisVariableName.fulfilled, (state, action) => {
        state.xaxisList = action.payload.result;
      })
      .addCase(GetYaxisVariableName.fulfilled, (state, action) => {
        state.yaxisList = action.payload.result;
      })
      .addCase(GetVisualisationType.fulfilled, (state, action) => {
        state.visualisationTypeList = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = reporting.actions;

// export the reducer
export default reporting.reducer;