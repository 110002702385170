import { createSlice } from "@reduxjs/toolkit";

import { SearchEmployee } from "services/AdminPortal/HumanResourceService";

// initial state
const initialState = {
  searchResultsCount: {},
  searchResults: {},
};

const searchCapacity = createSlice({
  name: "searchCapacity",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SearchEmployee.fulfilled, (state, action) => {
        state.searchResultsCount = action.payload.result.employeeRateScheduleList.totalCount;
        state.searchResults = action.payload.result.employeeRateScheduleList.items;
      })
  },
});

// export actions
export const { reset } = searchCapacity.actions;

// export the reducer
export default searchCapacity.reducer;