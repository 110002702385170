/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import Typography from '@mui/material/Typography';
// core components
import Card from "shared-components/Card/Card";
import DateTabs from "shared-components/Tabs/DateTabs";

import { reset } from "../store/costOverview";

import styles from "assets/jss/components/UserPortal/costProductivity.module.scss";
// import { ExportReport, GetActivationRatePercentReport, GetActiveRatePercentReport, GetActiveUsersReport } from "services/AdminPortal/ReportService";

const FileDownload = require('js-file-download');

export default function Water() {
  const dispatch = useDispatch();
  const activeUsers = useSelector(store => store.admin.report.activeUsers);
  const activeRatePercent = useSelector(store => store.admin.report.activeRatePercent);
  const activationRatePercent = useSelector(store => store.admin.report.activationRatePercent);
  const [date, setDate] = React.useState({startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});

  const handleButtonClick_export = () => {
    // dispatch(ExportReport({startDate: date.startDate, endDate: date.endDate}))
    // .then((response) => {
    //   FileDownload(response.payload, 'Overall_Statistics.xlsx');
    // });
  };

  const config = (title) => {
    let array = []
    if (title === "Water Consumption Efficiency") {
      array = activeUsers.map((item) => {
        return Number(item.value)
      })
    }
    return ({
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: "Duration"
        },
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: "Count"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          data: array,
          label: {
            connectorAllowed: false
          },
          pointStart: new Date(date.startDate).getTime(),
          pointInterval: 24 * 3600 * 1000, // one day
          color: "#225422"
        }
      },
      series: [{
        data: array,
        name: title
      }],
    })
  };

  React.useEffect(() => {
    // dispatch(GetActiveUsersReport({startDate: date.startDate, endDate: date.endDate}));
    // dispatch(GetActivationRatePercentReport({startDate: date.startDate, endDate: date.endDate}));
    // dispatch(GetActiveRatePercentReport({startDate: date.startDate, endDate: date.endDate}));
  },[date]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <Card 
      classes={{
        root: styles.cardPaper
      }}
      title = "Water Consumption Efficiency - 34.0 (l/kg)"
      action={
        <React.Fragment>
          <DateTabs setDate={setDate} date={date} />
        </React.Fragment>
      }
    >
      <HighchartsReact highcharts={Highcharts} options={config("Water Consumption Efficiency")} />
      <div className={styles.legend}>
        <Typography className={styles.formulaLegend}><span className={styles.legendLabel}>Legend:&nbsp;&nbsp;</span>WC (L/kg)&nbsp;=&nbsp;<div className={styles.formula}><span className={styles.fraction}>V&nbsp;<span className={styles.small}>Total</span>&nbsp;(L)</span><span>&nbsp;W&nbsp;<span className={styles.small}>Marketable</span>&nbsp;(kg)&nbsp;</span></div></Typography>
        <Typography>
          <span className={styles.legendLabel2}>Where:&nbsp;</span>
          V <span className={styles.small}>Total</span> is the volume of water supplied (including water from alternative sources and eater supplied by the national water agency) for rop production activities (excluding postharvest activities) in a period determined by the farm. Water used in cooling towers shall be included where applicable.<br/>
          W <span className={styles.small}>Marketable</span> is the weight of marketable production in the same period.
        </Typography>
      </div>
    </Card>
  );
}
