import { combineReducers } from "redux";
import production from "./production";
import equipment from "./equipment";
import workOrder from "./workOrder";
import storage from "./storage";
import settings from "./settings";

const monitoringReducer = combineReducers({
  production,
  equipment,
  workOrder,
  storage,
  settings
});

export default monitoringReducer;