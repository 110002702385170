import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

// initial state
const initialState = {
  bankList: [],
  banks: [],
  deletedBanks: [],
};

const bank = createSlice({
  name: "bank",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.bankList = action.payload;
      for (let obj of action.payload) {
        if (obj.isInSector) {
          state.banks = _.unionBy(state.banks, [{
            bankId: obj.id, 
            bankName: obj.bankName,
            isInSector: obj.isInSector,
            floorPlanX: obj.floorPlanX,
            floorPlanY: obj.floorPlanY
          }], "bankId");
        }
      }
    },
    updateRequest: (state, action) => {
      state.banks = action.payload;
    },
    updateDeletedRequest: (state, action) => {
      state.deletedBanks = action.payload;
    },
    updateIndexRequest: (state, action) => {
      state.banks[action.payload.index] = Object.assign(state.banks[action.payload.index], action.payload.payload);
    },
    resetBanks: (state) => {
      state.banks = [];
      state.deletedBanks = [];
    },
    reset: () => initialState,
  },
});

// export actions
export const { setValues, updateRequest, updateDeletedRequest, updateIndexRequest, resetBanks,
  reset } = bank.actions;

// export the reducer
export default bank.reducer;