import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from 'lodash';
// @mui/material
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Tag from 'shared-components/Chip/Tag';
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import FilterButton from "shared-components/Button/FilterButton";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { rowsPerTable, minDate } from "config";
import { setSearchText } from "store/general";
import { useInterval } from 'common/utils';
import { renderValue, filterParam } from 'common/functions';
import { setValues } from "../store/deliveryOrder";
import { deliveryOrderTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllDeliveryOrder, GetDeliveryTripLookUp, GetAllDriver, GetDeliveryOrderLookUp } from "services/UserPortal/DeliveryService";

import styles from "assets/jss/components/UserPortal/delivery.module.scss";

export default function DeliveryOrder() {
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.delivery.deliveryOrder.filter);
  const tripList = useSelector(store => store.user.delivery.deliveryOrder.tripList);
  const doList = useSelector(store => store.user.delivery.deliveryOrder.doList);
  const driverList = useSelector(store => store.user.delivery.deliveryOrder.driverList);
  const totalCount = useSelector(store => store.user.delivery.deliveryOrder.totalCount);
  const results = useSelector(store => store.user.delivery.deliveryOrder.results);
  const [newFilter, setNewFilter] = React.useState({deliveryOrderId: null, deliveryTripRefNo: null, driverId: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('deliveryTripRefNo');

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, deliveryStartDate: moment(value.startDate).format("YYYY-MM-DD"), deliveryEndDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const getAllDeliveryOrder = (stopLoading) => {
    dispatch(GetAllDeliveryOrder({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllDeliveryOrder();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetDeliveryTripLookUp());
    dispatch(GetAllDriver());
    dispatch(GetDeliveryOrderLookUp())
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllDeliveryOrder(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="Details of the Trip"
        subheader={
          <React.Fragment>
            <Typography>Please choose a delivery order to view details</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "page" && !key.includes("Date")) {
                let label = filter[key];
                if (key === "driverId") {
                  const driver = driverList.find(({id}) => id == filter[key]);
                  label = driver && driver.name;
                }
                if (key === "deliveryOrderId") {
                  const deliveryOrder = doList.find(({deliveryOrderId}) => deliveryOrderId == filter[key]);
                  label = deliveryOrder && deliveryOrder.deliveryOrderRefNo;
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={styles.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the delivery order list
                  <Select
                    className={styles.filterDropdown}
                    name="deliveryTripRefNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select trip number"
                    value={renderValue(newFilter.deliveryTripRefNo)}
                  >
                    {tripList && tripList.map((item, index) => {
                      return <MenuItem key={index} value={item.deliveryTripRefNo}>{item.deliveryTripRefNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="deliveryOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select DO number"
                    value={renderValue(newFilter.deliveryOrderId)}
                  >
                    {doList && doList.map((item, index) => {
                      return <MenuItem key={index} value={item.deliveryOrderId}>{item.deliveryOrderRefNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="driverId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select driver's name"
                    value={renderValue(newFilter.driverId)}
                  >
                    {driverList && driverList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Delivery date"
              className={styles.cardActionDate}
              value={{startDate: filter.deliveryStartDate, endDate: filter.deliveryEndDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, deliveryStartDate: null, deliveryEndDate: null, page: 0}}))}
            />
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            header={deliveryOrderTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { results.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.deliveryTripRefNo}</TableCell>
                  <TableCell>{item.deliveryOrderRefNo}</TableCell>
                  <TableCell>{item.driverName}</TableCell>
                  <TableCell>{item.deliveryOrderStatus.status}</TableCell>
                  <TableCell>{moment(item.signedDateTime).isAfter(minDate) ? moment(item.signedDateTime).format("DD/MM/YYYY HH:mm") : ""}</TableCell>
                  <TableCell>
                    <Link className={styles.link} to={{pathname: "/user/delivery/delivery-order/details/"+item.id}}>View More</Link>
                  </TableCell>
                  {/* <TableCell>
                    {item.isCompleted && <Link className={styles.link} to={{pathname: "/user/delivery/delivery-order/invoice/"+item.id}}>View More</Link>}
                  </TableCell> */}
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
