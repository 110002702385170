import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material components
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from "@mui/material/InputAdornment";
// core components
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";

import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { GetCostPrice, CreateOrUpdateCostPrice } from "services/UserPortal/SmartMeteringFlagshipService";

import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";

export default function EditCost() {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const cost = useSelector(store => store.user.smartMetering.costChart.cost);
  const [edit, setEdit] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOnChange_text = (e) => {
    setEdit(e.target.value);
  };

  const handleOnCancel = () => {
    dispatch(setDirty(false));
    setEdit(null);
  }

  const handleOnSave = () => {
    setIsLoading(true);
    dispatch(CreateOrUpdateCostPrice(edit))
    .then(() => {
      dispatch(setDirty(false));
      dispatch(GetCostPrice(true));
      setEdit(null);
      setIsLoading(false);
    });
  }

  const handleOnEdit = () => {
    dispatch(setDirty(true));
    setEdit(cost);
  }
  // componentDidMount
  React.useEffect(() => {
    dispatch(GetCostPrice());
  },[]);
  
  return (
    <div>
      <Typography className={styles.label}>Cost:</Typography>
      <div className={styles.flexContainer}>
        {isLoading
        ? <CircularProgress
            variant="indeterminate"
            className={styles.progress}
            size={24}
            thickness={4}
          />
        : <React.Fragment>
            <TextField 
              id="cost"
              variant="outlined" 
              type="number"
              inputProps={{ maxLength: 20 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={(e) => handleOnChange_text(e)}
              placeholder="Enter cost"
              value={edit ? edit : renderValue(cost)}
              //errorMsg={errorMsg}
              disabled={!isDirty}
            />
            { edit
            ? <React.Fragment>
                <IconButton 
                  type="confirm"
                  onClick={handleOnSave}
                />
                <IconButton 
                  type="close"
                  onClick={handleOnCancel}
                />
              </React.Fragment>
            : <IconButton 
                type="edit"
                onClick={handleOnEdit}
                disabled={isDirty}
              />
            }
          </React.Fragment>
        }
      </div>
    </div>
  );
}