// Create Edit Device
// Stepper
import React from "react";
// core components
import Stepper from "shared-components/Stepper/Stepper";

export default function DeviceStepper(props) {

  const deviceSteps = ['Please Key in Details for Device Creation', 'Mapping of Device ID to Manufacturer ID', 'Testing of Device Information', 'Mapping of Device Hierarchy'];

  return (
    <Stepper 
      steps={deviceSteps}
      activeStep={props.activeStep}
    />
  )
}
