import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// @mui/material
import Button from "@mui/material/Button";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import IconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";

import { useRouteCanWrite } from "hooks";
import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { monitoringSettingsStorageTableHead } from "enums/UserPortal/TableHeaders";
import { reset } from "../store/settings";
import { GetAllMonitoringStorageSetting, DeleteMonitoringStorageSetting } from "services/UserPortal/MonitoringService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/monitoring.module.scss";

export default function Production() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.monitoring.settings.totalCount);
  const results = useSelector(store => store.user.monitoring.settings.storage);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('warehouseName');
  const [page, setPage] = React.useState(0);
  const [deleteId, setDeleteId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleModal = (id) => {
    setDeleteId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteMonitoringStorageSetting(deleteId))
    .then(() =>{
      getProductionSetting(0);
      setPage(0);
      handleModal(null);
      setDeleteId(null);
    })
  }

  const getProductionSetting = (pageNo, stopLoading) => {
    dispatch(GetAllMonitoringStorageSetting({page: pageNo, stopLoading}));
  };

  React.useEffect(() => {
    getProductionSetting(page, true);
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getProductionSetting(page, true);
  });

  return (
    <React.Fragment>
      <div className={styles.table}>
        <Table
          header={monitoringSettingsStorageTableHead}
          // order={order}
          // orderBy={orderBy}
          // onRequestSort={handleRequestSort}
        >
          { results.map((item,index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.warehouseName}</TableCell>
                <TableCell>{item.humiditySensorRefNo}</TableCell>
                <TableCell>{item.temperatureSensorRefNo}</TableCell>
                <TableCell>{item.ledControlRefNo}</TableCell>
                <TableCell>{item.ventilationFanRefNo}</TableCell>
                <TableCell align="right">
                  {canWrite &&
                    <React.Fragment>
                      <Link to={{pathname: "/user/monitoring/settings/"+item.id, state: {tab: 1}}}>
                        <IconButton type="edit" />
                      </Link>
                      <IconButton 
                        type="delete"
                        onClick={() => handleModal(item.id)}
                      />
                    </React.Fragment>
                  }
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
      </div>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
        />
      }
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this storage setting? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
