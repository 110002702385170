import React, { useRef } from "react";
import Hls from "hls.js";

export default function Video({data, slideIndex, ...props}) {
  const player = useRef();

  React.useEffect(() => {
    if (Hls.isSupported() && player.current) {
      const video = player.current;
      const hls = new Hls();
      hls.loadSource(
        data[slideIndex]
      );
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function() {
        video.play();
      });
    }
  },[slideIndex]);

  return (
    <React.Fragment>
      <video 
        {...props}
        ref={player}
        autoPlay={true}
      />
    </React.Fragment>
  );
}