import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { STRAPI_URL, STRAPI_TOKEN } from "config";

export const StrapiMS = axios.create({
  baseURL: STRAPI_URL,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
    'Authorization': `Bearer ${STRAPI_TOKEN}` 
  }
});

export const GetSidebar = createAsyncThunk(
  "strapi/getSidebar", 
  async (_, {getState, rejectWithValue}) => {
    const language = getState().general.language;
    const params = {
      locale: language.code,
      'pagination[pageSize]': 100
    }
    try {
      const response = await StrapiMS.get("/sidebars", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetNavigation = createAsyncThunk(
  "strapi/getNavigation", 
  async (_, {getState, rejectWithValue}) => {
    const language = getState().general.language;
    const params = {
      locale: language.code,
      'pagination[pageSize]': 100
    }
    try {
      const response = await StrapiMS.get("/navigations", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetContent = createAsyncThunk(
  "strapi/getContent", 
  async (param, {getState, rejectWithValue}) => {
    const language = getState().general.language;
    const params = {
      locale: language.code,
      content_type: param,
    }
    try {
      const response = await StrapiMS.get("", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);