import React, {useState, useRef} from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// core components
import Card from "shared-components/Card/Card";
import Modal from "shared-components/Modal/Modal";

import { minDate } from "config";

import PrintFormat from "layouts/PrintFormat";
import styles from "assets/jss/components/UserPortal/qualityControl.module.scss";

export default function VegetableReport() {
  let history = useHistory();
  const componentRef = useRef();
  const qualityControl = useSelector(store => store.user.rnd.vegetable.qualityControl);
  const [listIndex, setListIndex] = useState(false);
  const [zoomModal, setZoomModal] = useState(false);
  const [, setOnBeforeGetContentResolve] = useState(false);
  const [printing, setPrinting] = useState(false);
  
  const handleOnClick_zoom = (item, index) => {
    setListIndex(index);
    setZoomModal(item);
  }

  const onClickArrow = (index) => {
    if (qualityControl.qualityControlImages) {
      if (listIndex + index >= 0  && listIndex + index <= (qualityControl.qualityControlImages.length - 1)) {
        setListIndex(listIndex + index);
        if (qualityControl.qualityControlImages[listIndex + index]) {
          setZoomModal(qualityControl.qualityControlImages[listIndex + index].imageUrl);
        }
      }
    }
  }

  const handleButtonClick_print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "R&D_Experiment_Report_"+qualityControl.workOrderNumber,
    removeAfterPrint: true,
    onAfterPrint: () => {
      setPrinting(false);
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setPrinting(true);
        setOnBeforeGetContentResolve(resolve);
      });
    },
  })

  const component = (isPrinting) => {
    return (
      <React.Fragment>
        <Grid container spacing={4}>
          {isPrinting && 
            <Grid item xs={12}>
              <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Experiment Report</Typography>
            </Grid>
          }
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Work Order:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.workOrderNumber}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Vegetable Name:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.productName}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Germination Serial No.:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.germinationSerialNo}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>General Information</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Serial No:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.vegetableSerialNo}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Vegetable Variety:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.vegetableVariety}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>No. of Plants Transplanted:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.noOfPlantsTransplanted}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Batch No:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.batchNo}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Tray Position:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.trayPosition}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>No. of Plants Harvested:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.noOfPlantsHarvested}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Order No.:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.orderNo}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Seed Supplier:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.supplierName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Dates</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Germinate Date:</Typography>
            <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.germinateDate).isAfter(minDate) && moment(qualityControl.germinateDate).format("DD/MM/YYYY")}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Harvest Date:</Typography>
            <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.harvestDate).isAfter(minDate) && moment(qualityControl.harvestDate).format("DD/MM/YYYY")}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Nursery Duration:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.nurseryDuration}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Transplant Date:</Typography>
            <Typography className={isPrinting && styles.printFont}>{moment(qualityControl.transplantDate).isAfter(minDate) && moment(qualityControl.transplantDate).format("DD/MM/YYYY")}</Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Growth Duration:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.growthDuration}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Weights</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Total Net Weight (kg):</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.totalNetWeight}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Avr Net Weight/Plant (g):</Typography>
            <Typography className={isPrinting && styles.printFont}>{parseFloat(qualityControl.avrNetWeightPerPlant).toFixed(0)}</Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Plant Board</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Lighting</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Board Type:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.boardType}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>LED Type:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.ledType}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Light Intensity (µmol/m2/s):</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.lightIntensity}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Growing Media Type:</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.growingMediaType}</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Lighting Duration (hr):</Typography>
            <Typography className={isPrinting && styles.printFont}>{qualityControl.lightingDuration}</Typography>
          </Grid>
        </Grid>
        <div className="pagebreak">
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Environment Conditions (Final)</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Plant Parameters</Typography>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={4}>
                <Grid item xs={12} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Water Level (cm):</Typography>
                  <Grid container className={styles.gridMargin}>
                    <Grid item xs={3}>
                      <Typography className={clsx(clsx(styles.formLabel3, isPrinting && styles.printLabel), isPrinting && styles.printLabel2)}>Initial</Typography>
                      <Typography className={isPrinting && styles.printFont}>{qualityControl.initialWaterLevel}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={clsx(clsx(styles.formLabel3, isPrinting && styles.printLabel), isPrinting && styles.printLabel2)}>Final</Typography>
                      <Typography className={isPrinting && styles.printFont}>{qualityControl.finalWaterLevel}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>EC (mS/cm):</Typography>
                  <Grid container className={styles.gridMargin}>
                    <Grid item xs={3}>
                      <Typography className={isPrinting && styles.printFont}>{qualityControl.initialEC}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={isPrinting && styles.printFont}>{qualityControl.finalEC}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>TDS (ppm):</Typography>
                  <Grid container className={styles.gridMargin}>
                    <Grid item xs={3}>
                      <Typography className={isPrinting && styles.printFont}>{qualityControl.initialTDS}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={isPrinting && styles.printFont}>{qualityControl.finalTDS}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>pH Level:</Typography>
                  <Grid container className={styles.gridMargin}>
                    <Grid item xs={3}>
                      <Typography className={isPrinting && styles.printFont}>{qualityControl.initialPHLevel}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={isPrinting && styles.printFont}>{qualityControl.finalPHLevel}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={styles.grid}>
                <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Production Remarks</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={isPrinting && styles.printFont}>{qualityControl.productionRemark}</Typography>
              </Grid>
              <Grid item xs={12} className={styles.grid}>
                <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>R&D Remarks</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={isPrinting && styles.printFont}>{qualityControl.remark}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={5} className={styles.grid}>
              <Grid container spacing={2}>
                <Grid item xs={4} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Photos:</Typography>
                </Grid>
                <Grid item xs={8} className={styles.grid}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} className={styles.grid}>
                      <div className={styles.hcImageDiv}>
                        <Typography className={clsx(styles.formLabel3, isPrinting && styles.printLabel, isPrinting && styles.printLabel3)}>Smallest</Typography>
                        <img src={smallestHC && smallestHC.imageUrl} className={clsx(styles.hcImage, smallestHC && smallestHC.imageUrl && styles.reportImg)} style={{backgroundColor: "#FFF"}} onClick={()=>handleOnClick_zoom(smallestHC && smallestHC.imageUrl)} />
                      </div>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <div className={styles.hcImageDiv}>
                        <Typography className={clsx(styles.formLabel3, isPrinting && styles.printLabel, isPrinting && styles.printLabel3)}>Average</Typography>
                        <img src={averageHC && averageHC.imageUrl} className={clsx(styles.hcImage, averageHC && averageHC.imageUrl && styles.reportImg)} style={{backgroundColor: "#FFF"}} onClick={()=>handleOnClick_zoom(averageHC && averageHC.imageUrl)} />
                      </div>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <div className={styles.hcImageDiv}>
                        <Typography className={clsx(styles.formLabel3, isPrinting && styles.printLabel, isPrinting && styles.printLabel3)}>Biggest</Typography>
                        <img src={biggestHC && biggestHC.imageUrl} className={clsx(styles.hcImage, biggestHC && biggestHC.imageUrl && styles.reportImg)} style={{backgroundColor: "#FFF"}} onClick={()=>handleOnClick_zoom(biggestHC && biggestHC.imageUrl)} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Gross Weight Less Growing Media & Roots (g):</Typography>
                </Grid>
                <Grid item xs={8} className={styles.grid}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{smallestHC && smallestHC.grossWeight}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{averageHC && averageHC.grossWeight}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{biggestHC && biggestHC.grossWeight}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Discarded Leaves Weight (g):</Typography>
                </Grid>
                <Grid item xs={8} className={styles.grid}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{smallestHC && smallestHC.discardedLeavesWeight}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{averageHC && averageHC.discardedLeavesWeight}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{biggestHC && biggestHC.discardedLeavesWeight}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Net Weight:</Typography>
                </Grid>
                <Grid item xs={8} className={styles.grid}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{smallestHC && smallestHC.netWeight}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{averageHC && averageHC.netWeight}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{biggestHC && biggestHC.netWeight}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Height of Plant (cm):</Typography>
                </Grid>
                <Grid item xs={8} className={styles.grid}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{smallestHC && smallestHC.heightOfPlant}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{averageHC && averageHC.heightOfPlant}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{biggestHC && biggestHC.heightOfPlant}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.grid}>
                  <Typography className={clsx(styles.formLabel, isPrinting && styles.printLabel)}>Width of Plant (cm):</Typography>
                </Grid>
                <Grid item xs={8} className={styles.grid}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{smallestHC && smallestHC.widthOfPlant}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{averageHC && averageHC.widthOfPlant}</Typography>
                    </Grid>
                    <Grid item xs={4} className={styles.grid}>
                      <Typography className={isPrinting && styles.printFont}>{biggestHC && biggestHC.widthOfPlant}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="pagebreak">
          <Grid container spacing={4}>
            <Grid item xs={12} className={styles.grid}>
              <Typography className={clsx(styles.formTitle, isPrinting && styles.printTitle)}>Photos Attached</Typography>
            </Grid>
            <Grid item xs={7} className={styles.grid}>
              <Grid container className={styles.gridMargin}>
                {qualityControl.qualityControlImages && qualityControl.qualityControlImages.map((item, index) => {
                  if (!item.isDeleted) {
                    return (
                      <Grid className={styles.image} item xs={4} key={index}>
                        <img src={item.imageUrl} className={clsx(styles.printImage, styles.reportImg)} onClick={()=>handleOnClick_zoom(item.imageUrl, index)} />
                      </Grid>
                    )
                  }
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} className={styles.grid}>
              <Typography className={styles.printFooter}>- END OF REPORT -</Typography>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  const smallestHC = qualityControl.healthConditions && qualityControl.healthConditions.find(({healthConditionName}) => healthConditionName === "Smallest");
  const averageHC = qualityControl.healthConditions && qualityControl.healthConditions.find(({healthConditionName}) => healthConditionName === "Average");
  const biggestHC = qualityControl.healthConditions && qualityControl.healthConditions.find(({healthConditionName}) => healthConditionName === "Biggest");
  return (
    <React.Fragment>
      <Card 
        title = "Experiment Report"
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.goBack()}
            >
              Go Back
            </Button>
          </React.Fragment>
        }
        action={!printing && 
          <Button
            className={styles.buttonSecondary}  
            onClick={handleButtonClick_print}
          >
            Export
          </Button>
        }
      >
        {component()}
      </Card>
      <Modal
        classes={{
          content: styles.zoomModalContent
        }}
        open={Boolean(zoomModal)}
        className={styles.zoomModal}
        onClose={() => handleOnClick_zoom(false)}
        content={
          <React.Fragment>
            <img src={zoomModal} className={styles.zoomImage} />
            {listIndex !== undefined &&
              <React.Fragment>
                <a className={styles.carouselButton} onClick={()=>onClickArrow(-1)}>&#10094;</a>
                <a className={clsx(styles.carouselButton, styles.next)} onClick={()=>onClickArrow(1)}>&#10095;</a>
              </React.Fragment>
            }
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleOnClick_zoom(false)}>Close</Button>
          </React.Fragment>
        }
      />
      <div ref={componentRef}>
        <PrintFormat printing={printing}>
          {component(true)}
        </PrintFormat>
      </div>
    </React.Fragment>
  )
}
