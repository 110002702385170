import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetClimateMonitoring, GetClimateMonitoringHeatMap, GetDeviceUnits } from "services/UserPortal/ClimateService";

// initial state
const initialState = {
  result: [],
  heatMapResult: [],
  selectedDevice: [],
  deviceUnits: [],
};

const climateMonitoring = createSlice({
  name: "climateMonitoring",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetClimateMonitoring.fulfilled, (state, action) => {
        state.result = action.payload.result.devices;
      })
      .addCase(GetClimateMonitoringHeatMap.fulfilled, (state, action) => {
        state.heatMapResult = action.payload.result.devices;
      })
      .addCase(GetDeviceUnits.fulfilled, (state, action) => {
        state.deviceUnits = _.compact(action.payload.result);
      })
  },
});

// export actions
export const { updateRequest, reset } = climateMonitoring.actions;

// export the reducer
export default climateMonitoring.reducer;