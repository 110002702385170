
import { createAsyncThunk } from "@reduxjs/toolkit";

import {FomsMS} from "../index";

export const UploadCompanyLogo = createAsyncThunk(
  "upload/uploadCompanyLogo", 
  async (file, {rejectWithValue}) => {
    let body = new FormData();
    body.append("companyLogo", file)
    try {
      const response = await FomsMS.post("/Upload/UploadCompanyLogo", body, {isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadUserProfile = createAsyncThunk(
  "upload/uploadUserProfile", 
  async (file, {rejectWithValue}) => {
    let body = new FormData();
    body.append("userProfile", file)
    try {
      const response = await FomsMS.post("/Upload/UploadUserProfile", body, {isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadFloorPlanDiagram = createAsyncThunk(
  "upload/uploadFloorPlanDiagram", 
  async (file, {rejectWithValue}) => {
    let body = new FormData();
    body.append("floorPlan", file)
    try {
      const response = await FomsMS.post("/Upload/UploadFloorPlanDiagram", body, {isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadProductImage = createAsyncThunk(
  "upload/uploadProductImage", 
  async (file, {rejectWithValue}) => {
    let body = new FormData();
    body.append("productImage", file)
    try {
      const response = await FomsMS.post("/Upload/UploadProductImage", body, {isLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);