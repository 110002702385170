import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Accordion from "shared-components/Accordion/CardAccordion";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import IconButton from 'shared-components/Button/IconButton';
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";
import Modal from "shared-components/Modal/Modal";

import { useRouteCanWrite } from "hooks";
import { minDate, minDateTime, rowsPerTable } from "config";
import { getCurrentTime, renderValue, formatNumbers, roundTo2Decimal, filterActionTableHead } from "common/functions";
import { setDirty } from "store/general";
import { taskAssignmentTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/task";
import { DeleteSubTask, UpdateOperationSubTaskList } from "services/UserPortal/OperationsService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/operations.module.scss";

export default function AssignmentTable(props) {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const userList = useSelector(store => store.common.userList);
  const task = useSelector(store => store.user.operations.task.task);
  const frequencyList = useSelector(store => store.user.operations.task.frequencyList);
  const selectedAction = useSelector(store => store.user.operations.task.selectedAction);
  const config = useSelector(store => store.user.operations.task.taskConfig);
  const [isEdit, setIsEdit] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(null);
  const [expanded, setExpanded] = React.useState(true);
  const [deleteId, setDeleteId] = React.useState(null);

  const handleButtonClick_checkbox = (e, data) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(selectedAction);
    const index = payload.findIndex(({id}) => id == e.target.id);
    const selected = _.find(data, ({id}) => id == e.target.id);
    if (index != -1) {
      payload.splice(index, 1);
    } else {
      payload.push(_.pick(selected, ["id", "startDate", "endDate", "frequency", "assignedUserId", "additionalPersons"]));
    }
    dispatch(setValues({selectedAction: payload}));
  };
  
  const handleButtonClick_checkAll = (e, data) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(selectedAction);
    if (payload.filter(o1 => data.some(o2 => o1.id === o2.id)).length === _.reject(data, "isAction").length) {
      payload = _.reject(payload, o1 => data.some(o2 => o1.id === o2.id));
    } else {
      payload = _.unionBy(payload, data.filter((item)=>{if (!item.isAction) return _.pick(item, ["id", "startDate", "startTime", "endTime", "frequency", "assignedUserId", "additionalPersons", "isAction", "remark"])}), "id")
    }
    dispatch(setValues({selectedAction: payload}));
  };
  
  const handleButtonClick_edit = (item) => {
    dispatch(setDirty(true));
    setIsEdit(item.id);
    const startTime = moment(item.startTime).isAfter(minDateTime) ? moment(item.startTime).format() : getCurrentTime(task.expectedStartDate).format();
    const endTime = moment(item.endTime).isAfter(minDateTime) ? moment(item.endTime).format() : getCurrentTime(task.expectedStartDate).add(1, "hour").format();
    dispatch(setValues({taskConfig: {
      ..._.pick(item, ["id", "frequency", "assignedUserId", "additionalPersons"]), 
      startDate: moment(item.startDate).isAfter(minDate) ? item.startDate : task.expectedStartDate && task.expectedStartDate.format(),
      startTime,
      endTime,
      startTimeISO: moment(startTime),
      endTimeISO: moment(endTime),
      additionalMembers: userList.filter((user) => item.additionalPersons && item.additionalPersons.includes(user.id)),
    }}));
  };

  const handleModal_delete = (value) => {
    setDeleteId(value);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteSubTask(deleteId))
    .then(() =>{
      props.setPage({rm: 0, equip: 0, other: 0});
      props.getOperationTask();
      setDeleteId(null);
      setOpenModal(!openModal);
    })
  }
  
  const handleButtonClick_confirm = () => {
    if (props.validateFields()) {
      setIsEdit(null);
      dispatch(UpdateOperationSubTaskList([config]))
      .then(() => {
        dispatch(setDirty(false));
        dispatch(setValues({taskConfig: {}}));
        props.setPage({rm: 0, equip: 0, other: 0});
        props.getOperationTask();
      });
    }
  };
  
  const handleButtonClick_cancel = () => {
    dispatch(setDirty(false));
    setIsEdit(null);
    dispatch(setValues({taskConfig: {}}));
    props.setErrorMsg(false);
  };

  if (props.data.length) {
    return (
      <Accordion
        classes={{
          root: styles.accordion,
        }}
        header={props.title}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <div className={styles.table}>
          <Table
            className={styles.assignmentTable}
            header={filterActionTableHead(_.map(taskAssignmentTableHead, (item, index) => {
              if (index === 0) {
                item.label = <Checkbox 
                    checked={Boolean(selectedAction.filter(o1 => props.data.some(o2 => o1.id === o2.id)).length===_.reject(props.data, "isAction").length && _.reject(props.data, "isAction").length)} 
                    indeterminate={Boolean(selectedAction.length && selectedAction.filter(o1 => props.data.some(o2 => o1.id === o2.id)).length!==_.reject(props.data, "isAction").length)} 
                    onClick={(e)=>handleButtonClick_checkAll(e, props.data)} 
                    disabled={Boolean(isEdit || !_.reject(props.data, "isAction").length)}
                  />;
              }
              return item;
            }), canWrite)}
          >
            { props.data
            .slice(props.page[props.pageKey] * rowsPerTable, props.page[props.pageKey] * rowsPerTable + rowsPerTable)
            .map((item,index) => {
              const selected = selectedAction.find(({id}) => id === item.id);
              return (
                <TableRow key={index}>
                  {canWrite && 
                    <TableCell align="center" className={styles.smallCell}>
                      <Checkbox 
                        id={String(item.id)}
                        checked={Boolean(selected)}
                        onChange={(e) => handleButtonClick_checkbox(e, props.data)}
                        disabled={Boolean(isEdit || item.isAction)}
                      />
                    </TableCell>
                  }
                  <TableCell className={styles.smallCell}>{item.sequenceOrder}</TableCell>
                  <TableCell className={styles.bigCell}>{item.subTaskName}</TableCell>
                  <TableCell className={styles.contentCell}>{item[props.refNoKey]}</TableCell>
                  <TableCell className={styles.smallCell}>{formatNumbers(roundTo2Decimal(item.qty))}</TableCell>
                  <TableCell className={styles.smallCell}>{item.uom}</TableCell>
                  <TableCell className={isEdit === item.id ? styles.textFieldCell : styles.bigCell}>
                    {isEdit === item.id
                    ? <DateTimePicker
                        name="startDate"
                        minDate={moment(task.expectedStartDate)}
                        value={config.startDate}
                        onChange={(e) => props.handleOnChange_date(e, "startDate")}
                        errorMsg={props.errorMsg}
                      />
                    : moment(item.startDate).isAfter(minDate) ? moment(item.startDate).format("DD/MM/YYYY") : ""
                    } 
                  </TableCell>
                  <TableCell className={isEdit === item.id ? styles.textFieldCell : styles.bigCell}>
                    {isEdit === item.id
                    ? <React.Fragment>
                        <div className={styles.durationCell}>
                          <DateTimePicker
                            className={styles.assignmentTime} 
                            type="time"
                            name="startTime"
                            value={config.startTimeISO}
                            onChange={(e) => props.handleOnChange_time(e, "startTime")}
                            errorMsg={props.errorMsg}
                          />&nbsp;-&nbsp;
                          <DateTimePicker
                            className={styles.assignmentTime} 
                            type="time"
                            name="endTime"
                            value={config.endTimeISO}
                            onChange={(e) => props.handleOnChange_time(e, "endTime")}
                            errorMsg={props.errorMsg}
                          />
                        </div>
                      </React.Fragment>
                    : moment(item.startTime).isSame(minDateTime) && moment(item.endTime).isSame(minDateTime) 
                      ? null
                      : moment(item.startTime).format("HH:mm")+" - "+moment(item.endTime).format("HH:mm")
                    } 
                  </TableCell>
                  <TableCell>
                    {isEdit === item.id
                    ? <Select
                        name="frequency"
                        onChange={(e)=>props.handleOnChange_select(e)}
                        value={renderValue(config.frequency)}
                        disabled={!task.ableToChangeFrequency}
                      >
                        {frequencyList && frequencyList.map((item) => {
                          return <MenuItem key={item} value={item}>{item}</MenuItem>;
                        })} 
                      </Select> 
                    : item.frequency
                    } 
                  </TableCell>
                  <TableCell>
                    {isEdit === item.id
                    ? <TextField 
                        id="numberOfRepeat"
                        variant="outlined"
                        type="Number"
                        onInput={(e)=>{ 
                          e.target.value = Math.max(1, parseInt(e.target.value)).toString().slice(0,4)
                        }}
                        value={renderValue(config.numberOfRepeat)}
                        onChange={(e) => props.handleOnChange_text(e)}
                        disabled={!task.ableToChangeFrequency && config.frequency == "Once"}
                        errorMsg={props.errorMsg}
                      />
                    : item.numberOfRepeat
                    } 
                  </TableCell>
                  <TableCell className={styles.bigCell}>
                    {/* {isEdit === item.id
                    ? <Select
                        name="assignedUserId"
                        onChange={(e)=>props.handleOnChange_select(e)}
                        value={renderValue(config.assignedUserId)}
                        placeholder="Select a person"
                        errorMsg={props.errorMsg}
                      >
                        {userList && userList.map((item) => {
                          return <MenuItem key={item.id} value={item.id}>{item.userName}</MenuItem>;
                        })} 
                      </Select>
                    : item.assignedUserName
                    } */}
                    {item.assignedUserName}
                  </TableCell>
                  <TableCell className={styles.bigCell}>
                    {isEdit === item.id
                    ? <Autocomplete
                        dataCount={userList.length}
                        options={_.filter(userList, ({id}) => config.assignedUserId !== id)}
                        renderOption={(option) => (
                          <React.Fragment>
                            <Checkbox checked={_.findIndex(config.additionalMembers, ({id}) => id === option.id) > -1} />
                            {option.userName}
                          </React.Fragment>
                        )}
                        placeholder="Select team members"
                        onChange={(_, newValue) => {
                          props.handleOnChange_autocomplete(newValue);
                        }}
                        value={config.additionalMembers ? config.additionalMembers : []}
                        renderValue={"userName"}
                        multiple
                        disabled={!config.assignedUserId}
                      />
                    : item.additionalPersons &&
                      userList.filter((user) => item.additionalPersons.includes(user.id)).map(({userName})=> userName).join(", ")
                    }
                  </TableCell>
                  <TableCell className={isEdit === item.id ? clsx(styles.actionCell, styles.editCell) : null}>
                    {canWrite && (isEdit === item.id && !item.isAction
                    ?  <React.Fragment>
                        <IconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirm()}
                        />
                        <IconButton 
                          type="close"
                          onClick={() => handleButtonClick_cancel()}
                        />
                      </React.Fragment>
                    : (!item.isAction 
                      ? <React.Fragment>
                          <IconButton
                            type="edit" 
                            onClick={() => handleButtonClick_edit(item)}
                            disabled={Boolean((isEdit && isEdit!=item.id) || selectedAction.length)}
                          />
                          { item.isDeletable &&
                            <IconButton 
                              type="delete"
                              onClick={() => handleModal_delete(item.id)}
                              disabled={Boolean((isEdit && isEdit!=item.id) || selectedAction.length)}
                            />
                          }
                        </React.Fragment>
                      : "Task Completed"
                      )
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { props.count > 1 &&
          <Pagination 
            count={props.count} 
            page={props.page[props.pageKey]+1}
            onChange={(e, pageNo)=>props.setPage({...props.page, [props.pageKey]: pageNo-1})}
          />
        }
        <Modal
          open={openModal}
          onClose={() => handleModal_delete(null)}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this assignment? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </Accordion>
    )
  } else {
    return null;
  }
}