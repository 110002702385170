import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Drawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";
// @mui/icons-material
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { setValues } from "../store/overview";
import { useInterval } from 'common/utils';
import { GetAllEnergyMonitoring } from "services/UserPortal/SmartMeteringService";

import EnergyConsumption from "./EnergyConsumption";
import GraphScale from "./GraphScale";
import ParamList from "./ParamList";
import ColumnChart from "./ColumnChart";
import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";

export default function Aggregation() {
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.smartMetering.overview.filter);
  const result = useSelector(store => store.user.smartMetering.overview.result);
  const [aggregationScale, setAggregationScale] = React.useState({label: '10 Minutes', value: ''});
  const [selected, setSelected] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(true);

  const controller = new AbortController();
  
  const getEnergyMonitoring = (stopLoading) => {
    setIsLoading(!stopLoading);
    if ((filter.address && filter.address.length) || (filter.deviceName && filter.deviceName.length)) {
      dispatch(GetAllEnergyMonitoring({...filter, aggregationScale: moment(filter.endDate).diff(moment(filter.startDate), 'hours') <= 24 ? aggregationScale.value : '', stopLoading: true, signal: controller.signal}));
    } else {
      dispatch(setValues({result: []}));
    }
  }

  React.useEffect(() => {
    if (selected[0] && typeof selected[0] === "number") {
      dispatch(setValues({filter: {...filter, address: selected, deviceName: []}}));
    } else {
      dispatch(setValues({filter: {...filter, deviceName: selected, address: []}}));
    }
  },[selected]);

  React.useEffect(() => {
    getEnergyMonitoring();
    // cleanup controller
    return () => {
      controller.abort();
    };
  },[filter, aggregationScale]);
  
  useInterval(() => {
    getEnergyMonitoring(true);
  });

  return (
    <React.Fragment>
      <div className={styles.graphContent}>
        <IconButton
          className={styles.formIconButton2}
          onClick={()=>setOpen(!open)}
        >
          {!open ? <KeyboardArrowRightIcon className={styles.icon_24} /> : <KeyboardArrowLeftIcon className={styles.icon_24} />}
        </IconButton>
        <Drawer
          sx={{
            // width: drawerWidth,
            // flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: "30%",
              height: "33vw",
              position: 'absolute',
              padding: "1.563vw",
              marginLeft: "-1.979vw",
              border: "0.052vw solid #D9D9D6",
              borderLeft: 0,
              borderBottomRightRadius: "0.833vw",
              overflow: "visible",
              marginTop: "5.125vw",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Paper className={styles.paper2} elevation={0}>
            <ParamList selected={selected} setSelected={setSelected} />
          </Paper>
          <IconButton
            className={styles.formIconButton}
            onClick={()=>setOpen(!open)}
          >
            {!open ? <KeyboardArrowRightIcon className={styles.icon_24} /> : <KeyboardArrowLeftIcon className={styles.icon_24} />}
          </IconButton>
        </Drawer>
        <div className={styles.highChart}>
          <div className={styles.topComponent}>
            <EnergyConsumption />
            <GraphScale filter={filter} setValues={setValues} aggregationScale={aggregationScale} setAggregationScale={setAggregationScale} />
          </div>
          <Paper className={styles.chartPaper} elevation={0}>
            <Typography className={styles.title}>From {moment(filter.endDate).diff(moment(filter.startDate), 'hours') <= 24 ? aggregationScale.label : moment(filter.endDate).diff(moment(filter.startDate), 'years') >= 1 ? 'Monthly' : 'Daily'} Aggregation</Typography>
            <ColumnChart results={result.todayTotalEnergy && result.todayTotalEnergy.aggregationRequest} isLoading={isLoading} setIsLoading={setIsLoading} />
            {/* <div className={styles.legend}>
              {result.todayTotalEnergy && result.todayTotalEnergy.aggregationRequest.map((item, index) => {
                if (item) {
                  return (
                    <React.Fragment key={index}>
                      <div className={clsx(styles.colorBox, styles.legendColor)} />{item.address}
                    </React.Fragment>
                  )
                }
              })}
            </div> */}
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
}
