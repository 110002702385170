import React from "react";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
// core components
import Alert from "./Alert";

import { reset as resetGeneral } from "store/general";
import { reset as resetNav } from "shared-components/Navbars";

export default function CustomPrompt(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  const showModal = (location) => {
    setOpen(true);
    setLastLocation(location);
  }

  const closeModal = () => {
    setOpen(false);
  }

  const blockTransition = (nextLocation) => {
    if (!confirmedNavigation && props.shouldBlockNavigation()){
      showModal(nextLocation);
      return false;
    }
    return true;
  }

  const allowTransition = () => {
    Promise.all([
      dispatch(resetNav()),
      dispatch(resetGeneral())
    ]).then(() => {
      closeModal();
      if (lastLocation) {
        setConfirmedNavigation(true);
      }
    })
  }

  React.useEffect(() => {
    if (confirmedNavigation) {
      props.navigate(lastLocation);
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);
  

  return (
    <React.Fragment>
      <Prompt
        when={props.when}
        message={(e)=>blockTransition(e)}
      />
      <Alert open={open} onConfirm={()=>allowTransition()} onCancel={()=>closeModal()} />
    </React.Fragment>
  );
}