import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";

import Pagination from "./Pagination";
import styles from "assets/jss/components/UserPortal/home.module.scss";

export default function Delivery() {
  let history = useHistory();
  const results = useSelector(store => store.user.home.deliveryWidget);
  const [page, setPage] = React.useState(0);

  return (
    <React.Fragment>
      {results.length
      ? <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={0} className={styles.paper}>
              <Typography className={styles.tableTitle}>Trip ID</Typography>
              <Typography className={styles.paperContent}>{results[page] && results[page].deliveryTripRefNo}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={7}>
            <Paper elevation={0} className={styles.paper}>
              <Typography className={styles.tableTitle}>Delivery Order Number</Typography>
              <Typography className={styles.paperContent}>{results[page] && results[page].deliveryOrderNumber}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper elevation={0} className={styles.paper}>
              <Typography className={styles.tableTitle}>Delivery Date</Typography>
              <Typography className={styles.paperContent}>{moment(results[page] && results[page].deliveryDate).format("DD/MM/YYYY")}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.progressTitle}>
              <Typography className={styles.tableTitle}>Delivery Status</Typography>
              <Typography className={styles.paperContent}>{results[page] && results[page].status}</Typography>
            </div>
            <LinearProgress 
              classes={{
                root: styles.progressBar, 
                barColorPrimary: styles.progress
              }} 
              variant="determinate" 
              value={results[page] && results[page].status === "In Progress" ? 50 : (results[page] && results[page].status === "Completed" ? 100 : 0)} 
            />
          </Grid>
        </Grid>
      : <Typography className={styles.tableTitle}>Nothing to show</Typography>
      }
      <div className={styles.widgetAction}>
        { results.length > 1 &&
          <Pagination 
            count={results.length} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        <Button className={clsx(styles.buttonSecondary, styles.viewAllButton)} onClick={()=>history.push("/user/delivery/master-list")}>View All</Button>
      </div>
    </React.Fragment>
  );
}
