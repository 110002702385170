import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "shared-components/Checkbox/Checkbox";
// core components
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import ProductList from "./ProductList";
import { daysOfWeek } from "enums/Time";
import { setDirty } from "store/general";
import { renderValue, addToEndDate } from "common/functions";
import { updateRequest, resetOrder } from "../store/management";
import { GetCustomerList, GetPONumberFromERP } from "services/UserPortal/CustomerOrderService";

import styles from "assets/jss/components/UserPortal/salesOrder.module.scss";

export default function ForecastOrder(props) {
  const dispatch = useDispatch();
  const order = useSelector(store => store.user.customerOrder.management.customerOrder);
  const customerList = useSelector(store => store.user.customerOrder.management.customerList);
  // const poNumberList = useSelector(store => store.user.customerOrder.management.poNumberList);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleOnChange_text = (e) => {
    if (e.target.id === "purchaseOrderNumber" && e.target.value && order.customerId) {
      dispatch(updateRequest({
        orderType: "Recurring",
        [e.target.id] : e.target.value
      }));
    } else {
      dispatch(updateRequest({[e.target.id] : e.target.value}));
    }
  };

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      const newValue = moment(value);
      let payload = {[field]: newValue.format("YYYY-MM-DD")};
      if (field === "startDeliveryDate") {
        const endDate = addToEndDate(newValue, order.endDeliveryDate, "days");
        payload = {...payload, endDeliveryDate: endDate};
      }
      dispatch(updateRequest(payload));
    } else {
      dispatch(updateRequest({[field] : value}));
    }
  };

  const handleButtonClick_checkbox = (e) => {
    dispatch(updateRequest({[e.target.id] : !order[e.target.id]}));
  };

  const validateFields = React.useCallback(() => {
    if (!(order.isMonday || order.isTuesday || order.isWednesday || order.isThursday || order.isFriday || order.isSaturday || order.isSunday)) {
      setErrorMsg({field: "deliveryDays", msg: "Please select a day"});
      return false;
    }
    if (!order.startDeliveryDate) {
      setErrorMsg({field: "startDeliveryDate", msg: "Please enter a start date"});
      return false;
    }
    if (!moment(order.startDeliveryDate).isValid()) {
      setErrorMsg({field: "startDeliveryDate", msg: "Invalid Date"});
      return false;
    }
    if (!order.endDeliveryDate) {
      setErrorMsg({field: "endDeliveryDate", msg: "Please enter an end date"});
      return false;
    }
    if (!moment(order.endDeliveryDate).isValid()) {
      setErrorMsg({field: "endDeliveryDate", msg: "Invalid Date"});
      return false;
    }
    if (moment(order.endDeliveryDate).isSameOrBefore(moment(order.startDeliveryDate))) {
      setErrorMsg({field: "endDeliveryDate", msg: "End date cannot be before start date"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }, [order]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetCustomerList());
    dispatch(GetPONumberFromERP());
    // componentDidUnmount
    return () => {
      dispatch(resetOrder());
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={styles.formTitle}>Customer Information</Typography>
      <Grid container spacing={4}>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={styles.formLabel}>Customer Name:</Typography>
          <Select
            name="customerId"
            onChange={(e)=>handleOnChange_select(e)}
            placeholder="Select customer"
            value={renderValue(order.customerId)}
            errorMsg={errorMsg}
          >
            {customerList && customerList.map((item, index) => {
              return <MenuItem key={index} value={item.id}>{item.customerName} ({item.customerRefNo})</MenuItem>;
            })} 
          </Select>
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={styles.formLabel}>Customer PO:</Typography>
          <TextField
            id="purchaseOrderNumber"
            variant="outlined" 
            inputProps={{ maxLength: 20 }}
            placeholder="Enter purchase order"
            onChange={(e) => handleOnChange_text(e)}
            value={renderValue(order.purchaseOrderNumber)}
            errorMsg={errorMsg}
            disabled={!props.isEdit}
          />
        </Grid>
        <Grid item xs={12} className={styles.grid}>
          <Typography className={styles.formLabel}>Delivery Days:</Typography>
          {daysOfWeek.map((day) => {
            return (
              <React.Fragment key={day}>
                <Checkbox  
                  className={styles.checkbox}
                  id={"is"+day} 
                  value={"is"+day} 
                  checked={Boolean(order["is"+day])}
                  onChange={(e) => handleButtonClick_checkbox(e)}
                />
                <Typography className={styles.checkboxLabel}>{day}</Typography>
              </React.Fragment>
            )
          })}
        </Grid>
        {errorMsg.field === "deliveryDays" &&
          <Grid item xs={12}>
            <Typography className={styles.error}>{errorMsg.msg}</Typography> 
          </Grid>
        }
        <Grid item xs={4} className={styles.grid}>
          <Typography className={styles.formLabel}>Delivery Start Date:</Typography>
          <DateTimePicker
            name="startDeliveryDate"
            value={order.startDeliveryDate}
            onChange={(e) => handleOnChange_date(e, "startDeliveryDate")}
            errorMsg={errorMsg}
          />
        </Grid>
        <Grid item xs={4} className={styles.grid}>
          <Typography className={styles.formLabel}>Delivery End Date:</Typography>
          <DateTimePicker
            name="endDeliveryDate"
            value={order.endDeliveryDate}
            minDate={order.startDeliveryDate ? moment(order.startDeliveryDate).add(1,'d').format("YYYY-MM-DD") : moment().add(1,'d').format("YYYY-MM-DD")}
            onChange={(e) => handleOnChange_date(e, "endDeliveryDate")}
            errorMsg={errorMsg}
          />
        </Grid>
      </Grid>
      <ProductList validateFields={validateFields} setIsEdit={props.setIsEdit} />
    </React.Fragment>
  );
}
