import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllRawMaterial, GetAllRawMaterialBatch, GetAllRawMaterialBatchInfo } from "services/UserPortal/RawMaterialService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  orders: [],
  //batch
  selectedRMRefNo: "",
  selectedRMID: "",
  batchCount: 0,
  batchList: [],
  batch: {},
  allRackNumber: [],
  //filter
  batchRefList: [],
};

const managementRM = createSlice({
  name: "managementRM",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.batch[key] = action.payload[key];
      })
    },
    setSelectedRMRefNo: (state, action) => {
      state.selectedRMRefNo = action.payload
    },
    setSelectedRMID: (state, action) => {
      state.selectedRMID = action.payload
    },
    setSelectedBatch: (state, action) => {
      state.batch = _.omit(action.payload, ["rackNumbers"])
      state.batch.rackNumbers = _.reduce(action.payload.rackNumbers, (result, value) => ([ ...result, {rackID: value}]), []);
    },
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateBatchForm: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.batch[key] = action.payload[key];
      })
    },
    resetBatch: (state) => {
      state.batch = {};
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRawMaterial.fulfilled, (state, action) => {
        state.totalCount= action.payload.result.rawMaterialFinishList.totalCount;
        state.orders = action.payload.result.rawMaterialFinishList.items;
      })
      .addCase(GetAllRawMaterialBatch.fulfilled, (state, action) => {
        state.batchCount = action.payload.result.totalCount;
        state.batchList = action.payload.result.items;
      })
      .addCase(GetAllRawMaterialBatchInfo.fulfilled, (state, action) => {
        state.batchRefList = action.payload.result;
      })
  },
});

// export actions
export const { updateRequest, setSelectedRMRefNo, setSelectedRMID, setSelectedBatchList, setSelectedBatch, setValues, updateBatchForm, resetBatch, reset } = managementRM.actions;

// export the reducer
export default managementRM.reducer;