import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import styles from "assets/jss/shared-components/modal.module.scss";

export default function Alert({onCancel, onConfirm, ...props}) {

  return (
    <Modal {...props} className={styles.alert}>
      <Paper className={styles.alertPaper}>
        <Typography className={styles.alertTitle}>Leave without changes?</Typography>
        <Divider />
        <Typography className={styles.alertContent}>You have not saved your current changes. Are you sure you want to leave?</Typography>
        <div className={styles.alertActions}>
          <Button
            className={styles.buttonSecondary}
            onClick={onCancel}
          >
            No
          </Button>
          <Button
            className={styles.button}
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

Alert.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};