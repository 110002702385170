import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { rowsPerTable } from "config";
import { renderValue, filterParam } from "common/functions";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { assignmentStatus } from "enums/Constants"
import { setValues } from "../store/task";
import { assignmentWorkOrderTableHead, assignmentProductTableHead, assignmentProductComponentTableHead } from "enums/UserPortal/TableHeaders";
import { GetWorkOrderList, GetProductListByWorkOrderId, GetProductListByMixProductId } from "services/UserPortal/OperationsService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/operations.module.scss";

export default function ProductList() {
  const dispatch = useDispatch();
  const lotIdList = useSelector(store => store.common.lotIdList);
  const filter = useSelector(store => store.user.operations.task.filter);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const results = useSelector(store => store.user.operations.task.workOrderList);
  const products = useSelector(store => store.user.operations.task.products);
  const mixProducts = useSelector(store => store.user.operations.task.mixProducts);
  const [mixProduct, setMixProduct] = React.useState(null);
  const [selectedWorkOrder, setSelectedWorkOrder] = React.useState(null);
  const [newFilter, setNewFilter] = React.useState({workOrderNo: null, startDate: null, endDate: null, page: 0});
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('purchaseOrderNumber');
  const [subOrder, setSubOrder] = React.useState('asc');
  const [subOrderBy, setSubOrderBy] = React.useState('subSaleOrderNumber');
  const [subPage, setSubPage] = React.useState(0);
  const [mixPage, setMixPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [mixOpen, setMixOpen] = React.useState(false);

  const count = Math.ceil(results.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSubRequestSort = (e, property) => {
    const isAsc = subOrderBy === property && subOrder === 'asc';
    setSubOrder(isAsc ? 'desc' : 'asc');
    setSubOrderBy(property);
  };

  const handleOnChange_page = (pageNo) => {
    dispatch(setValues({filter: {...filter, page: pageNo-1}, products: []}));
    setOpen(false); 
    setSubPage(0);
  };

  const handleOnChange_subpage = (pageNo) => {
    setSubPage(pageNo-1); 
    setMixOpen(false); 
    setMixPage(0);
    dispatch(setValues({mixProducts: []}));
  }

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter:{...filter, [key]: null, page: 0}}));
  };

  const onButtonClick_collapse = (index, wo) => {
    if (open === index) {
      setOpen(false);
      setMixOpen(false);
      setSelectedWorkOrder(null);
      dispatch(setValues({products: [], mixProducts: []}));
    } else {
      setOpen(index);
      setMixOpen(false);
      const workOrder = workOrderList.find(({workOrderNo}) => workOrderNo == wo);
      setSelectedWorkOrder(workOrder);
      dispatch(GetProductListByWorkOrderId({workOrderId: workOrder.id, lotId: filter.lotId, startDate: filter.startDate, endDate: filter.endDate, stopLoading: true}));
    }
  }

  const onButtonClick_mixCollapse = (index, item) => {
    if (mixOpen === index) {
      setMixOpen(false);
      dispatch(setValues({mixProducts: []}));
    } else {
      setMixOpen(index);
      setMixProduct(item);
      dispatch(GetProductListByMixProductId({workOrderId: selectedWorkOrder.id, productId: item.id, startDate: filter.startDate, endDate: filter.endDate, stopLoading: true}));
    }
  }
  
  const getWorkOrderProducts = (stopLoading) => {
    dispatch(GetWorkOrderList({...filter, stopLoading}));
    if (selectedWorkOrder) {
      dispatch(GetProductListByWorkOrderId({workOrderId: selectedWorkOrder.id, lotId: filter.lotId, startDate: filter.startDate, endDate: filter.endDate, stopLoading: true}));
    }
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      setOpen(false);
      setMixOpen(false);
      setSubPage(0);
      getWorkOrderProducts();
    }
  },[filter]);

  React.useEffect(() => {
    setSubPage(0);
  },[open]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getWorkOrderProducts(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && !key.includes("Date"));
  return (
    <React.Fragment>
      <Card 
        title="Summary"
        subheader={filteredKeys.length > 0
        ? <React.Fragment>
            {filteredKeys.map((key) => (
              <Tag
                key={key}
                variant="outlined"
                tabIndex={-1}
                label={filter[key]}
                className={styles.tag}
                onDelete={() => handleButtonClick_delete(key)}
              />
            ))}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through products list
                  <Select
                    className={styles.filterDropdown}
                    name="workOrderNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderNo)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.workOrderNo}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Task date"
              className={styles.cardActionDate}
              value={{startDate: filter.startDate, endDate: filter.endDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter:{...filter, startDate: null, endDate: null, page: 0}}))}
            />
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            expandable
            className={styles.mainTable}
            header={assignmentWorkOrderTableHead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(results, [orderBy], [order])
            .slice(filter.page * rowsPerTable, filter.page * rowsPerTable + rowsPerTable)
            .map((item,index) => {
              const subCount = Math.ceil(products.length / rowsPerTable);
              return (
                <React.Fragment key={index}>
                  <TableRow
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onButtonClick_collapse(index, item.workOrderNo)}
                  >
                    <TableCell>
                      <IconButton 
                        className={open === index ? styles.rotate : null}
                        onClick={() => onButtonClick_collapse(index, item.workOrderNo)}
                      >
                        <img className={styles.icon} src={arrow} alt="arrow" />
                      </IconButton>
                    </TableCell>
                    <TableCell>{item.workOrderNo}</TableCell>
                    <TableCell>
                      <Checkbox 
                        checked={item.status === assignmentStatus.completed}
                        indeterminate={item.status === assignmentStatus.partial}
                        disabled
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    { open===index && 
                      <TableCell className={styles.paddingBottom} colSpan={7}>
                        <Collapse in={open === index}>
                          <Table
                            className={styles.subTable}
                            header={assignmentProductTableHead}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleSubRequestSort}
                          >
                            { _.orderBy(products, [subOrderBy], [subOrder])
                            .slice(subPage * rowsPerTable, subPage * rowsPerTable + rowsPerTable)
                            .map((subItem, subIndex) => {
                              const mixCount = Math.ceil(mixProducts.length / rowsPerTable);
                              return (
                                <React.Fragment key={subIndex}>
                                  <TableRow>
                                    <TableCell>
                                      {subItem.isMixProduct &&
                                        <IconButton 
                                          className={mixOpen === subIndex ? styles.rotate : null}
                                          onClick={() => onButtonClick_mixCollapse(subIndex, subItem)}
                                        >
                                          <img className={styles.icon} src={arrow} alt="arrow" />
                                        </IconButton>
                                      }
                                    </TableCell>
                                    <TableCell>
                                      <Link to={{pathname: location.pathname+"/"+subItem.id, state: {productId: subItem.id, workOrderId: selectedWorkOrder.id, operationId: subItem.operationId, isMixProduct: subItem.isMixProduct}}} className={clsx(styles.productCell, styles.link)}>
                                        <img className={styles.productImage} src={subItem.productImage} alt={subItem.productName} />{subItem.productName} ({subItem.productRefNo}) {subItem.lotId && "("+subItem.lotId+")"}
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      <Checkbox 
                                        checked={subItem.unAssignedTaskStatus === assignmentStatus.completed}
                                        indeterminate={subItem.unAssignedTaskStatus === assignmentStatus.partial}
                                        disabled
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    { mixOpen===subIndex && 
                                      <TableCell className={styles.paddingBottom} colSpan={7}>
                                        <Collapse in={mixOpen===subIndex}>
                                          <Table
                                            className={styles.mixTable}
                                            header={assignmentProductComponentTableHead}
                                          >
                                            { mixProducts.slice(mixPage * rowsPerTable, mixPage * rowsPerTable + rowsPerTable)
                                            .map((mixItem, mixIndex) => {
                                              return (
                                                <React.Fragment key={mixIndex}>
                                                  <TableRow>
                                                    <TableCell>
                                                      <Link to={{pathname: location.pathname+"/"+mixItem.id, state: {productId: mixItem.id, workOrderId: selectedWorkOrder.id, operationId: mixItem.operationId, isMixProduct: Boolean(mixProduct)}}} className={clsx(styles.productCell, styles.link)}>
                                                        <img className={styles.productImage} src={mixItem.productImage} alt={mixItem.productName} />{mixItem.productName} ({mixItem.productRefNo})
                                                      </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                      <Checkbox 
                                                        checked={mixItem.unAssignedTaskStatus === assignmentStatus.completed}
                                                        indeterminate={mixItem.unAssignedTaskStatus === assignmentStatus.partial}
                                                        disabled
                                                      />
                                                    </TableCell>
                                                  </TableRow>
                                                </React.Fragment>
                                              )
                                            })}
                                          </Table>
                                          { mixCount > 1 &&
                                            <Pagination 
                                              count={mixCount} 
                                              page={mixPage+1}
                                              onChange={(e, pageNo)=>setMixPage(pageNo-1)}
                                            />
                                          }
                                        </Collapse>
                                      </TableCell>
                                    }
                                  </TableRow>
                                </React.Fragment>
                              )
                            })}
                          </Table>
                          { subCount > 1 &&
                            <Pagination 
                              count={subCount} 
                              page={subPage+1}
                              onChange={(e, pageNo)=>handleOnChange_subpage(pageNo)}
                            />
                          }
                        </Collapse>
                      </TableCell>
                    }
                  </TableRow>
                </React.Fragment>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>handleOnChange_page(pageNo)}
          />
        }
      </Card>
    </React.Fragment>
  );
}
