import { createSlice } from "@reduxjs/toolkit";

import { GetAllASRSMovementMasterList, GetASRSMovementMaster, GetASRSMaintenanceStatus, GetLocationIDListByCageId } from "services/UserPortal/ASRSService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  movementList: [],
  movement: {},
  movementEdit: {}, //editing fields
  //lookup
  statusList: [],
  cageList: [],
  locationList: [],
};

const movementASRS = createSlice({
  name: "movementASRS",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.movement[key] = action.payload[key];
      })
    },
    updateMovementRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.movementEdit[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllASRSMovementMasterList.fulfilled, (state, action) => {
        state.movementList = action.payload.result.items;
        state.totalCount = action.payload.result.totalCount;
      })
      .addCase(GetASRSMovementMaster.fulfilled, (state, action) => {
        state.movement = action.payload.result ?? {};
      })
      // lookup
      .addCase(GetASRSMaintenanceStatus.fulfilled, (state, action) => {
        state.statusList = action.payload.result;
      })
      .addCase(GetLocationIDListByCageId.fulfilled, (state, action) => {
        state.locationList = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, updateMovementRequest, reset } = movementASRS.actions;

// export the reducer
export default movementASRS.reducer;