import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';

import { graphColors as colors } from "assets/jss/palette";
import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";

export default function ColumnChart(props) {
  const [config, setConfig] = React.useState({chart: { zoomType: 'x' }});

  React.useEffect(() => {
    if (props.results) {
      props.setIsLoading(false);
      setConfig({
        chart: {
          type: 'column',
          zoomType: 'x',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          gridLineWidth: 1
        },
        yAxis: {
          title: {
            text: props.yAxis
          }
        },
        // plotOptions: {
        //   series: {
        //     pointWidth: 2
        //   }
        // },
        time: {
          useUTC: false
        },
        legend: {
          enabled: true,
          verticalAlign: 'bottom',
          align:'left',
          symbolRadius: 0,
        },
        plotOptions: {
          column: {
            minPointLength: 5
          },
          series: {
            animation: props.isLoading,
          }
        },
        series: props.results.map((device, i) => {
          if (device) {
            return {
              data: device.values?.map((item) => [moment(item.dateTime).valueOf(), Number(item.realValue)]),
              name: device.address + " (" + device.use + ") " + " [" + device.dbName + "]",
              color: colors[i]
            }
          }
        }),
        // series: [{
        //   data: props.results.map(({timestamp, realValue}) => [moment(timestamp).valueOf(), realValue]),
        //   //name: item.productName,
        //   color: colors[0]
        // }],
      })
    }
  },[props.results]);

  return(
    <React.Fragment>
      {props.isLoading 
      ? <div className={styles.chartContainer}>
          <CircularProgress
            variant="indeterminate"
            className={styles.progress}
            thickness={6}
          />
        </div>
      : (config.series && config.series.every(({data}) => data?.length)) 
        ? <HighchartsReact highcharts={Highcharts} options={config} containerProps={{ className: styles.highChart }} />
        : <div className={styles.chartContainer}><Typography className={styles.graphText}>No Data</Typography></div>
      }
    </React.Fragment>
  )
}
