// Create Edit Product
// Step 2-5: Pre Harvest Table
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import CustomIconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";

import { growthProcessTableHead } from "enums/AdminPortal/TableHeaders";
import { isUnique, isPositive, isPositiveDecimal } from "common/validations";
import { setValues, updatePreProcess } from "./store/product";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/product.module.scss";
import arrow from "assets/icons/orange/droplist-arrow.svg";

export default function PreHarvest(props) {
  const dispatch = useDispatch();
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct);
  const finishProduct = useSelector(store => store.admin.product.product.productFinish);
  const preHarvestMixProcess = useSelector(store => store.admin.product.product.preHarvestMixProcess);
  const selectedPreHarvestProcess = useSelector(store => store.admin.product.product.productPreHarvestGrowthProcess);
  const selectedPostHarvestProcess = useSelector(store => store.admin.product.product.productPostHarvestGrowthProcess);
  const processMasterList = useSelector(store => store.admin.product.lookup.processMasterList);
  const preProcess = useSelector(store => store.admin.product.product.preProcess);
  const [lossRate, setLossRate] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [growthEditIndex, setGrowthEditIndex] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState([false]);
  const [preHarvestTotalLoss, setPreHarvestTotalLoss] = React.useState([false]);
  const [postHarvestTotalLoss, setPostHarvestTotalLoss] = React.useState([false]);
  const [isAdd, setIsAdd] = React.useState(false);

  const calTotalLoss = (array) => {
    if (array) {
      return array.reduce((a, b) => {
        return a + (b.lossRate?Number(b.lossRate):0);
      }, 0);
    } else {
      return null;
    }
  }

  const resetState = () => {
    dispatch(setValues({preProcess: {}}))
    setLossRate("");
    setGrowthEditIndex(null);
    setErrorMsg(false);
    setIsAdd(false);
    props.setEditMode(false);
  }

  const tableHead = () => {
    if(props.step === 2) {
      return _.filter(growthProcessTableHead, (obj) => {return obj.step <= 3 && obj.post !== true});
    } else if (props.step === 3) {
      return _.filter(growthProcessTableHead, (obj) => {return obj.step <= 4 && obj.post !== true});
    } else if (props.step === 4) {
      return _.filter(growthProcessTableHead, (obj) => {return obj.post !== true});
    } else {
      return _.filter(growthProcessTableHead, (obj) => {return obj.step <= 2 && obj.post !== true});
    }
  }

  const handleOnChange_select = (e) => {
    dispatch(updatePreProcess({[e.target.name]: e.target.value}))
  };

  const handleOnChange_text = (e) => {
    dispatch(updatePreProcess({[e.target.id]: e.target.value}))
  };

  const handleOnChange_textLoss = (e) => {
    setLossRate(e.target.value);
    let payload = _.cloneDeep(selectedPreHarvestProcess);
    payload[growthEditIndex].lossRate = e.target.value;
    setPreHarvestTotalLoss(calTotalLoss(payload));
  }

  const handleButtonClick_add = () => {
    let payload = _.cloneDeep(selectedPreHarvestProcess);
    payload.push({processSequence: payload.length+1});
    dispatch(setValues({productPreHarvestGrowthProcess: payload}));
    setGrowthEditIndex(payload.length-1);
    props.setEditMode(true);
    setIsAdd(true);
  }

  const handleModal = (index) => {
    setGrowthEditIndex(index);
    setOpenModal(!openModal);
  }

  const handleButtonClick_edit = (index) => {
    dispatch(setValues({preProcess: selectedPreHarvestProcess[index]}));
    setLossRate(selectedPreHarvestProcess[index].lossRate);
    setGrowthEditIndex(index);
    props.setEditMode(true);
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      let payload = _.cloneDeep(selectedPreHarvestProcess);
      if (props.step === 1) {
        payload[growthEditIndex] = {...payload[growthEditIndex], ...preProcess};
      } else {
        payload[growthEditIndex].lossRate = lossRate;
      }
      dispatch(setValues({productPreHarvestGrowthProcess: payload}));
      resetState();
    }
  }
  
  const handleButtonClick_cancel = () => {
    if (isAdd) {
      let payload = _.cloneDeep(selectedPreHarvestProcess);
      payload.splice(payload.length - 1, 1);
      dispatch(setValues({productPreHarvestGrowthProcess: payload}));
    }
    resetState();
  }

  const handleButtonClick_delete = () => {
    let payload = _.cloneDeep(selectedPreHarvestProcess);
    payload.splice(growthEditIndex,1);
    payload.forEach((item, index) => {
      item.processSequence = index+1
    })
    dispatch(setValues({productPreHarvestGrowthProcess: payload}));
    setGrowthEditIndex(null);
    setOpenModal(!openModal);
    if (props.step !== 1 && !payload.length) {
      props.setStep(1);
    }
  }

  const handleCreateTask = (index, item, productID) => {
    dispatch(setValues({process: item}))
    props.setGrowthEditIndex(index);
    props.setCreateTask(true);
    props.setType("productPreHarvestGrowthProcess");
    props.setProductID(productID);
  }

  const handleCreateRawMaterial = (index, item) => {
    dispatch(setValues({process: item}))
    props.setGrowthEditIndex(index);
    props.setCreateRawMaterial(true);
    props.setType("productPreHarvestGrowthProcess");
  }

  const validateFields = () => {
    if (!preProcess.processName) {
      setErrorMsg({field: "processName", msg: "Please add in process name"});
      return false;
    }
    let payload = _.cloneDeep(selectedPreHarvestProcess);
    payload.splice(growthEditIndex,1);
    if (!isUnique([...payload, ...selectedPostHarvestProcess, preProcess], "processName")) {
      setErrorMsg({field: "processName", msg: "Selected process must be unique"});
      return false;
    }
    if (!isPositive(preProcess.processDurationDays)) {
      setErrorMsg({field: "processDurationDays", msg: "Please add in process duration"});
      return false;
    }
    if (!isPositive(preProcess.plantPerTray)) {
      setErrorMsg({field: "plantPerTray", msg: "Please enter a value"});
      return false;
    }
    if (!isPositive(preProcess.traysPerCage)) {
      setErrorMsg({field: "traysPerCage", msg: "Please enter a value"});
      return false;
    }
    if (selectedPreHarvestProcess && selectedPreHarvestProcess[growthEditIndex] && selectedPreHarvestProcess[growthEditIndex].lossRate && !isPositiveDecimal(selectedPreHarvestProcess[growthEditIndex].lossRate)) {
      setErrorMsg({field: "lossRate", msg: "Please enter a valid loss rate"});
      return false;
    }
    if (preHarvestTotalLoss + postHarvestTotalLoss > 100) {
      setErrorMsg({field: "totalLossRate", msg: "Total Loss Rate for both Pre Harvesting and Post Harvesting cannot be more than 100"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const totalPercentage = () => {
    if(props.step === 4) {
      return (
        <TableRow>
          <TableCell colSpan={7}></TableCell>
          <TableCell className={styles.editCell}>Total</TableCell>
          <TableCell colSpan={2} className={styles.lossRateCell}>
            <Typography>{preHarvestTotalLoss}</Typography>
            {errorMsg.field === "totalLossRate" && <Typography className={styles.lossRateError}>{errorMsg.msg}</Typography>}
          </TableCell>
        </TableRow>
      )
    }
  }

  const displayTable = (process, productID, processLossRate) => {
    // const reducedPostList = selectedPostHarvestProcess && selectedPostHarvestProcess.reduce((item,{processName}) => item.add(processName), new Set());
    // const reducedPreList = selectedPreHarvestProcess && selectedPreHarvestProcess.reduce((item,{processName}) => item.add(processName), new Set());
    // const processList = processMasterList.filter(({processName}) => !reducedPostList.has(processName) && !reducedPreList.has(processName));
    return (
      <React.Fragment>
        <Table
          className={styles.table}
          header={tableHead()}
        >
          { process && process.map((item, index) => {
            const isEdit = props.editMode && index === growthEditIndex;
            return (
              <TableRow key={index}>
                <TableCell>{('0'+(index+1)).slice(-2)}</TableCell>
                <TableCell>
                  {(props.step === 1 && index === growthEditIndex && props.editMode)
                    ?
                    <Select
                      name="processName"
                      className={styles.alignTextfield}
                      onChange={(e)=>handleOnChange_select(e)}
                      value={preProcess.processName}
                      placeholder="Select a process"
                      errorMsg={errorMsg}
                    >
                      {processMasterList && processMasterList.map((item) => {
                        return <MenuItem key={item.id} value={item.processName}>{item.processName}</MenuItem>;
                      })} 
                    </Select>
                    :
                    item.processName
                  }
                </TableCell>
                <TableCell>
                  {(props.step === 1 && index === growthEditIndex && props.editMode)
                  ? <TextField
                      className={styles.alignTextfield}
                      id="processDurationDays"
                      variant="outlined"
                      type="Number"
                      onInput={(e)=>{ 
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                      }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={preProcess.processDurationDays}
                      errorMsg={errorMsg}
                    />
                  : item.processDurationDays
                  }
                </TableCell>
                <TableCell>
                  {(props.step === 1 && index === growthEditIndex && props.editMode)
                  ? <TextField
                      className={styles.alignTextfield}
                      id="plantPerTray"
                      variant="outlined"
                      type="Number"
                      onInput={(e)=>{ 
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                      }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={preProcess.plantPerTray}
                      errorMsg={errorMsg}
                    />
                  : item.plantPerTray
                  }
                </TableCell>
                <TableCell>
                  {(props.step === 1 && index === growthEditIndex && props.editMode)
                  ? <TextField
                      className={styles.alignTextfield}
                      id="traysPerCage"
                      variant="outlined"
                      type="Number"
                      onInput={(e)=>{ 
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                      }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={preProcess.traysPerCage}
                      errorMsg={errorMsg}
                    />
                  : item.traysPerCage
                  }
                </TableCell>
                <TableCell>
                  {(props.step === 1 && index === growthEditIndex && props.editMode)
                  ? <TextField
                      className={styles.alignTextfield}
                      id="weightPerPlant"
                      variant="outlined"
                      type="Number"
                      // onInput={(e)=>{ 
                      //   e.target.value = e.target.value.toString().slice(0,8)
                      // }}
                      onChange={(e) => handleOnChange_text(e)}
                      value={preProcess.weightPerPlant}
                      //errorMsg={errorMsg}
                    />
                  : item.weightPerPlant
                  }
                </TableCell>
                {props.step >= 2 && 
                  <TableCell>
                    <span className={props.step === 2 && styles.link} onClick={()=>props.step === 2 && handleCreateRawMaterial(index, item)}>Create</span>
                  </TableCell>
                }
                {props.step >= 3 && 
                  <TableCell>
                    <span className={props.step === 3 && styles.link} onClick={()=>props.step === 3 && handleCreateTask(index, item, selectedProduct.combinationProduct && productID)}>Create</span>
                  </TableCell>
                }
                {props.step >= 4 &&
                  <TableCell className={styles.lossRateCell}>
                    {(props.step === 4 && isEdit)
                    ? <TextField
                        className={styles.alignTextfield}
                        id="lossRate"
                        variant="outlined" 
                        type="Number"
                        onInput={(e)=>{ 
                          e.target.value = e.target.value > 100 ? 100 : e.target.value
                        }}
                        onChange={(e) => handleOnChange_textLoss(e)}
                        value={lossRate}
                        errorMsg={errorMsg}
                      />
                    : item.lossRate
                  }
                  </TableCell>
                }
                {!selectedProduct.combinationProduct ?
                  <TableCell align="right">
                    {isEdit
                    ? <React.Fragment>
                        <CustomIconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirm()}
                        />
                        <CustomIconButton 
                          type="close"
                          onClick={() => handleButtonClick_cancel()}
                        />
                      </React.Fragment>
                    : <React.Fragment>
                        {(props.step === 4 || props.step === 1) &&
                          <CustomIconButton 
                            type="edit"
                            onClick={() => handleButtonClick_edit(index)}
                            disabled={props.editMode}
                          />
                        }
                        <CustomIconButton 
                          type="delete"
                          onClick={() => handleModal(index)}
                          disabled={props.editMode}
                        />
                      </React.Fragment>
                    }
                  </TableCell>
                : <TableCell />
                }
              </TableRow>
            )
          })}
          {!selectedProduct.combinationProduct
          ? <React.Fragment>
              {totalPercentage()}
            </React.Fragment>
          : props.step === 4 &&
            <TableRow>
              <TableCell colSpan={7}></TableCell>
              <TableCell>Total</TableCell>
              <TableCell>
                <Typography>{processLossRate}</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          }
        </Table>
        <div>
          {(props.step === 1 && !selectedProduct.combinationProduct) &&
            <Button
              className={clsx(styles.buttonSecondary, styles.addMoreProcess)}
              onClick={()=>handleButtonClick_add()}
              disabled={props.editMode}
            >
              + Add more Process
            </Button>
          }
        </div>
      </React.Fragment>
    )
  }

  React.useEffect(() => {
    if (Object.keys(preHarvestMixProcess).length) {
      setCollapsed(new Array(Object.keys(preHarvestMixProcess).length).fill(false));
    }
  },[preHarvestMixProcess]);

  React.useEffect(() => {
    setPreHarvestTotalLoss(calTotalLoss(selectedPreHarvestProcess));
  },[selectedPreHarvestProcess]);

  React.useEffect(() => {
    setPreHarvestTotalLoss(calTotalLoss(selectedPreHarvestProcess));
    setPostHarvestTotalLoss(calTotalLoss(selectedPostHarvestProcess));
  },[]);
  
  return (
    <React.Fragment>
      { selectedProduct.combinationProduct 
      ? Object.keys(preHarvestMixProcess).map((key, index) => {
          const productID = _.find(finishProduct, {"id": Number(key)}) && _.find(finishProduct, {"id": Number(key)}).productID
          const processLossRate = preHarvestMixProcess[key].reduce((total, current) => {
            return total + (current.lossRate ? current.lossRate : 0);
          }, 0);
          if (preHarvestTotalLoss < processLossRate) {
            setPreHarvestTotalLoss(processLossRate);
          }
          return (
            <Card
              key={index}
              variant="outlined"
              classes={{root:styles.growthProcessTable}}
              title = {
                <React.Fragment>
                  Pre Harvesting ({productID})
                  <IconButton
                    className={collapsed[index] ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                    onClick={() => setCollapsed({...collapsed, [index]: !collapsed[index]})}
                  >
                    <img className={styles.icon} src={arrow} alt="arrow" />
                  </IconButton>
                </React.Fragment>
              }
            >
              { !collapsed[index] && displayTable(preHarvestMixProcess[key], productID, processLossRate) }
            </Card>
          )
        })
      : <Card
          variant="outlined"
          classes={{root:styles.growthProcessTable}}
          title = {
            <React.Fragment>
              Pre Harvesting ({selectedProduct.productID})
              <IconButton 
                className={collapsed[0] ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                onClick={() => setCollapsed({...collapsed, [0]: !collapsed[0]})}
              >
                <img className={styles.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed[0] && displayTable(selectedPreHarvestProcess) }
        </Card>
      }
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content={
          "Do you really want to delete this process? This process cannot be undone." + 
          ((props.step !== 1 && selectedPreHarvestProcess.length === 1) ? " You will be redirected to step 2 to add a new process for Pre Harvest." : "")
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
			/>
    </React.Fragment>
  )
}
