import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import {FomsMS} from "../index";
import { rowsPerTable } from "config";

export const GetAllSupplier = createAsyncThunk(
  "Supplier/getAll", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    };
    try {
      const response = await FomsMS.get("/Supplier/GetAll", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSupplier = createAsyncThunk(
  "Supplier/getSupplier", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/Supplier/Get", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteSupplier = createAsyncThunk(
  "Supplier/deleteSupplier", 
  async (id, {rejectWithValue}) => {
    const params = {id}
    try {
      const response = await FomsMS.delete("/Supplier/Delete", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProposedSupplierRefNo = createAsyncThunk(
  "Supplier/getProposedSupplierRefNo", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      CountryCode: param,
    };
    try {
      const response = await FomsMS.get("/Supplier/GetProposedSupplierRefNo", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchSupplier = createAsyncThunk(
  "Supplier/searchSupplier", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword: param.keyword,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Supplier/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllRawMaterialAndDevice = createAsyncThunk(
  "Supplier/getAllRawMaterialAndDevice", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Supplier/GetAllRawMaterialAndDevice", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateSupplier = createAsyncThunk(
  "Supplier/createOrUpdateSupplier", 
  async (data, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...data,
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.post("/Supplier/CreateOrUpdateSupplier", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)