import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import Typography from "@mui/material/Typography";

import { formatNumbers } from "common/functions";
import { reset } from "../store/search";
import { setSearchText } from "store/general";

import styles from "assets/jss/components/UserPortal/warehouse.module.scss";

import SearchWarehouseResult from "./SearchWarehouseResult";
import SearchRawMaterialResult from "./SearchRawMaterialResult";
import SearchFinishedGoodsResult from "./SearchFinishedGoodsResult";

export default function SearchResult() {
  const dispatch = useDispatch();
  //count
  const masterTotalCount = useSelector(store => store.user.warehouse.search.masterTotalCount);
  const rawMaterialTotalCount = useSelector(store => store.user.warehouse.search.rawMaterialTotalCount);
  const finishedGoodsTotalCount = useSelector(store => store.user.warehouse.search.finishedGoodsTotalCount);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={styles.result}>{formatNumbers(masterTotalCount + rawMaterialTotalCount + finishedGoodsTotalCount) + " Search Result(s) From 3 Tables:  ‘" + params.keyword + "’"}</Typography>
      <SearchWarehouseResult />
      <SearchRawMaterialResult />
      <SearchFinishedGoodsResult />
    </React.Fragment>
  );
}
