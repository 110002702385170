// Create Edit Devices
// Step 2: Device Mapping
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Autocomplete from "shared-components/Select/Autocomplete";
import Modal from "shared-components/Modal/Modal";

import DeviceStepper from "./DeviceStepper";
import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { updateRequest, resetTesting } from "./store/device";
import { GetAllManufacturer } from "services/AdminPortal/ManufacturerService";
import { CreateOrUpdateDevice } from "services/AdminPortal/DeviceService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/device.module.scss";

export default function MappingForm(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const device = useSelector(store => store.admin.device.device.device);
  const manufacturer = useSelector(store => store.admin.device.device.manufacturer);
  const [changeText, setChangeText] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (value) => {
    const selectedManufacturer = manufacturers.find((item) => item.manufacturerName === value);
    if (selectedManufacturer) {
      dispatch(updateRequest({manufacturerName: value, manufacturerEmail: selectedManufacturer.manufacturerEmail, manufacturerPhone: selectedManufacturer.manufacturerPhone}));
    } else {
      dispatch(updateRequest({manufacturerName: value}));
    }
  };

  const handleModal_confirm = (e) => {
    if (device.testing) {
      setOpenConfirmModal(!openConfirmModal);
      setChangeText(e);
    } else {
      handleButtonClick_confirm(e);
    }
  }

  const handleModal_warning = () => {
    if (validateFields()) {
      setOpenWarningModal(!openWarningModal);
    }
  }

  const handleButtonClick_confirm = (e) => {
    handleOnChange_text(e)
    setOpenConfirmModal(false);
    dispatch(resetTesting());
  }

  const handleButtonClick_skip = () => {
    Promise.all([
      dispatch(updateRequest({isDraft: true})),
      dispatch(setDirty(false))
    ]).then(() => {
      dispatch(CreateOrUpdateDevice())
      .then((response) => {
        if (response.error) {
          dispatch(setDirty(true));
        } else {
          history.push("/admin/device");
        }
      });
    })
  }

  const handleButtonClick_next = () => {
    if (validateFields()) {
      props.setStep(2);
    }
  }

  const validateFields = () => {
    if (device.manufacturerPhone && !validate.isPhoneNumber(device.manufacturerPhone)) {
      setErrorMsg({field: "manufacturerPhone", msg: "Invalid phone number"});
      return false;
    }
    if (device.manufacturerEmail && !validate.isEmail(device.manufacturerEmail)) {
      setErrorMsg({field: "manufacturerEmail", msg: "Invalid email address"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const isCompleted = () => {
    if (_.isEmpty(device.manufacturerID)) {
      return false;
    }
    if (_.isEmpty(device.manufacturerName)) {
      return false;
    }
    return true
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllManufacturer());
  },[]);

  const manufacturers = [...manufacturer, {manufacturerName: "No Manufacturer"}];
  return (
    <React.Fragment>
      <Card 
        title="Step 2: Mapping of Device ID to Manufacturer ID" 
        subheader={<DeviceStepper activeStep={props.step} />}
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>props.setStep(0)}
            >
              Go Back
            </Button>
            <div>
              <Button
                className={clsx(styles.buttonSecondary, styles.buttonMargin)}
                onClick={()=>handleModal_warning()}
              >
                Save Draft
              </Button>
              <Button
                className={styles.button}
                onClick={()=>handleButtonClick_next()}
                disabled={!isCompleted()}
              >
                Next Step
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <Grid container spacing={8}>
          <Grid item xs={3}>
            <TextField 
              label="Device ID"
              id="deviceRefNo"
              variant="outlined" 
              value={renderValue(device.deviceRefNo)}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <TextField 
              label={<React.Fragment>Hardware ID <span className={styles.orange}>*</span></React.Fragment>}
              id="manufacturerID"
              variant="outlined" 
              inputProps={{ maxLength: 50 }}
              placeholder="Enter your manufacturer id here"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(device.manufacturerID)}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              label={<React.Fragment>Manufacturer Name <span className={styles.orange}>*</span></React.Fragment>}
              id="manufacturerName"
              freeSolo
              inputProps={{ maxLength: 50 }}
              onInputChange={(e, newInputValue) => {
                handleOnChange_select(newInputValue);
              }}
              value={renderValue(device.manufacturerName)}
              options={manufacturers.map((item) => item.manufacturerName)}
              placeholder="Please select or enter a manufacturer name" 
            />
          </Grid>
          <Grid item xs={3}>
            <TextField 
              label="Manufacturer Phone Number"
              id="manufacturerPhone"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
              }}
              placeholder="Enter your manufacturer phone number here"
              value={renderValue(device.manufacturerPhone)}
              onChange={(e) => handleOnChange_text(e)}
              errorMsg={errorMsg}
            />
            <TextField 
              label="Manufacturer Email"
              id="manufacturerEmail"
              variant="outlined" 
              inputProps={{ maxLength: 50 }}
              placeholder="Enter your manufacturer email here"
              value={renderValue(device.manufacturerEmail)}
              onChange={(e) => handleOnChange_text(e)}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Card>
      <Modal
        open={openConfirmModal}
        onClose={() => handleModal_confirm()}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure"
        content="Do you really want to change your selection? It will reset your test result."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_confirm()}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_confirm(changeText)}>Confirm</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning()}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you want to save the details first? You will be directed to the unfinished device list."
        actions={
          <React.Fragment>
          <Button className={styles.buttonSecondary} onClick={() => handleModal_warning()}>Cancel</Button>
          <Button className={styles.button} onClick={() => handleButtonClick_skip()}>Confirm</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
