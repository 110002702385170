import { createSlice } from "@reduxjs/toolkit";

import { GetAllCompany, GetCompany, SearchCompany } from "services/AdminPortal/CompanyService";
import { UploadCompanyLogo } from "services/AdminPortal/UploadService";

// initial state
const initialState = {
  companies: [],
  company: {},
  searchResultsCount: 0,
  searchResults: [],
};

// create reducer and action creators
const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.company = action.payload;
    },
    updateImg: (state, action) => {
      state.company.imageUrl = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.company[key] = action.payload[key];
      })
    },
    resetCompany: (state) => {
      state.company = {};
    },
    resetSearch: (state) => {
      state.searchResultsCount = {};
      state.searchResults = {};
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllCompany.fulfilled, (state, action) => {
        state.companies = action.payload.result.items;
      })
      .addCase(GetCompany.fulfilled, (state, action) => {
        state.company = action.payload.result;
      })
      .addCase(UploadCompanyLogo.fulfilled, (state, action) => {
        state.company.imageUrl = action.payload.result;
      })
      .addCase(SearchCompany.fulfilled, (state, action) => {
        state.searchResultsCount = action.payload.result.companyList ? action.payload.result.companyList.totalCount : 0;
        state.searchResults = action.payload.result.companyList ? action.payload.result.companyList.items : 0;
      })
  },
});

// export actions
export const { setValues, updateImg, updateRequest, resetCompany, resetSearch, reset } = company.actions;

// export the reducer
export default company.reducer;