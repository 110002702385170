import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Modal from "shared-components/Modal/Modal";
import IconButton from 'shared-components/Button/IconButton';
import Checkbox from "shared-components/Checkbox/Checkbox";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import { setSearchText, setDirty } from "store/general";
import { setValues, updateExclusionRequest, reset } from "../store/settings";
import { GetAllExclusionDays, CreateOrUpdateExclusionDays } from "services/UserPortal/PlanningService";

import confirm from "assets/icons/orange/confirm.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/planning.module.scss";

import { useRouteCanWrite } from "hooks";

export default function Exclusion() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const isDirty = useSelector(store => store.general.isDirty);
  const exclusion = useSelector(store => store.user.planning.settings.exclusion);
  const [openModal, setOpenModal] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleButtonClick_checkbox = (e) => {
    dispatch(setDirty(true));
    dispatch(updateExclusionRequest({[e.target.id]: !exclusion[e.target.id]}));
  };

  const handleOnChange_date = (value, index) => {
    dispatch(setDirty(true));
    const payload = _.cloneDeep(exclusion);
    if(value && value.isValid() && value != "Invalid Date") {
      payload.exclusionDates[index] = moment(value).format("YYYY-MM-DD");
      dispatch(setValues({exclusion: payload}));
    } else {
      payload.exclusionDates[index] = value;
      dispatch(setValues({exclusion: payload}));
    }
  };

  const handleButtonClick_add = () => {
    dispatch(setDirty(true));
    const payload = _.cloneDeep(exclusion);
    payload.exclusionDates = [...exclusion.exclusionDates, null];
    dispatch(setValues({exclusion: payload}));
  }

  const handleButtonClick_delete = (index) => {
    dispatch(setDirty(true));
    const payload = _.cloneDeep(exclusion);
    payload.exclusionDates.splice(index, 1);
    dispatch(setValues({exclusion: payload}));
  }

  const handleButtonClick_cancel = () => {
    dispatch(setDirty(false));
    dispatch(GetAllExclusionDays());
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateExclusionDays())
      .then(({error})=> {
        if (!error) {
          setSuccess(true);
          dispatch(setDirty(false));
        }
        handleModal();
      })
    }
  }

  const validateFields = () => {
    for (let i = 0; i < exclusion.exclusionDates.length; i++) {
      if (!moment(exclusion.exclusionDates[i]).isValid()) {
        setErrorMsg({field: "date"+i, msg: "Invalid Date"});
        return false;
      }
      if (!exclusion.exclusionDates[i]) {
        setErrorMsg({field: "date"+i, msg: "Please enter a date"});
        return false;
      }
    }
    setErrorMsg(false);
    return true;
  }

  const handleModal = () => {
    setOpenModal(!openModal);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllExclusionDays());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={styles.settingsTitle}>Choose the days which you do not want to start a work order? (Optional)</Typography>
      <div>
        <div className={styles.checkboxPadding}>
          <Checkbox 
            id="isSaturday"
            checked={Boolean(exclusion && exclusion.isSaturday)}
            onChange={(e) => handleButtonClick_checkbox(e)}
            disabled={!canWrite}
          />Saturdays<br/>
          <Checkbox 
            id="isSunday"
            checked={Boolean(exclusion && exclusion.isSunday)}
            onChange={(e) => handleButtonClick_checkbox(e)}
            disabled={!canWrite}
          />Sundays<br/>
          <Checkbox 
            id="isHoliday"
            checked={Boolean(exclusion && exclusion.isHoliday)}
            onChange={(e) => handleButtonClick_checkbox(e)}
            disabled={!canWrite}
          />Public Holidays<br/>
        </div>
      </div>
      <Typography className={styles.settingsTitle}>Any other additional dates? (Optional)</Typography>
      <div className={styles.dateContent}>
        {exclusion.exclusionDates.map((item, index)=>{
          return (
            <div key={index} className={styles.dateField}>
              <DateTimePicker
                closeOnSelect
                name={"date"+index}
                placeholder="Select a date"
                value={item}
                onChange={(e) => handleOnChange_date(e, index)}
                errorMsg={errorMsg}
                disabled={!canWrite}
              />
              {canWrite && 
                <IconButton
                  type="delete" 
                  onClick={() => handleButtonClick_delete(index)}
                />
              }
              {index < exclusion.exclusionDates.length-1 && <br/>}
            </div>
          )
        })}
        {canWrite && 
          <Button 
            className={clsx(styles.buttonSecondary, styles.checkboxPadding)} 
            startIcon={<AddIcon />}
            onClick={() => handleButtonClick_add()}
          >
            Add Another
          </Button>
        }
      </div>
      <div className={styles.settingsButton}>
        <Button className={styles.buttonSecondary} onClick={() => handleButtonClick_cancel()} disabled={!isDirty}>Cancel</Button>
        <Button className={styles.button} onClick={() => handleButtonClick_save()} disabled={!isDirty}>Save</Button>
      </div>
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        icon={success ? <img className={styles.icon_64} src={confirm} alt="confirm" /> : <img className={styles.icon_64} src={alert} alt="alert" />}
        title={success ? "Success" : "Error"}
        content={ success
          ? "Your selection has been saved"
          : "There is an error when saving your selection. Please try again."
        }
        actions={
          <React.Fragment>
            <Button className={styles.button} onClick={() => handleModal()}>Okay</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
