import React from "react";
import { useSelector } from "react-redux";
// @mui/material
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
// core components
import Accordion from "shared-components/Accordion/Accordion";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { formatNumbers, roundTo2Decimal } from "common/functions";
import { variableCostTableHead, variableCostLabourTableHead } from "enums/UserPortal/TableHeaders";

import styles from "assets/jss/components/UserPortal/costProductivity.module.scss";

export default function VariableCost() {
  const totalCount = useSelector(store => store.user.costProductivity.costOverview.totalCount);
  const workOrderCost = useSelector(store => store.user.costProductivity.costOverview.workOrderCost);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('ruleNo');
  const [page, setPage] = React.useState(0);
  const [expanded, setExpanded] = React.useState(null);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleButtonClick_accordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };

  return (
    <React.Fragment>
      <div>
        <Typography className={styles.costTitle}>Overall Cost: ${workOrderCost.totalAmount ? formatNumbers(roundTo2Decimal(workOrderCost.totalAmount)) : 0}</Typography>
        <Typography className={styles.costTitle}>Variable Cost: ${workOrderCost.variableCostType && workOrderCost.variableCostType.subTotal ? formatNumbers(roundTo2Decimal(workOrderCost.variableCostType.subTotal)) : 0}</Typography>
        <Typography className={styles.costTitle}>Fixed Cost: ${workOrderCost.fixedCostType && workOrderCost.fixedCostType.subTotal ? formatNumbers(roundTo2Decimal(workOrderCost.fixedCostType.subTotal)) : 0}</Typography>
      </div>
      {workOrderCost && workOrderCost.variableCostType && workOrderCost.variableCostType.variableCostCategories.map((item, index) => {
        return ( 
          <Accordion
            key={index}
            classes={{
              root: styles.accordion,
            }}
            expanded={expanded == index}
            onChange={handleButtonClick_accordion(index)}
            header={
              <div className={styles.overviewHeader}>
                <span>{item.name}</span>
                <div><span className={styles.totalFont}>Subtotal:</span> ${item.subTotal ? formatNumbers(roundTo2Decimal(item.subTotal)) : 0}</div>
              </div>
            }
          >
            <Paper className={styles.content} elevation={0}>
              <Table
                className={styles.tableMargin}
                header={item.name === "Labour" ? variableCostLabourTableHead : variableCostTableHead}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { item.variableCostDetails && item.variableCostDetails.map((subItem,subIndex) => {
                  return (
                    <TableRow key={subIndex}>
                      <TableCell>{subItem.name}</TableCell>
                      <TableCell>{subItem.referenceNo}</TableCell>
                      <TableCell>{subItem.processName}</TableCell>
                      {item.name === "Labour" && <TableCell>{subItem.taskId}</TableCell>}
                      <TableCell>{subItem.quantity}</TableCell>
                      <TableCell>{subItem.uom}</TableCell>
                      <TableCell>{formatNumbers(roundTo2Decimal(subItem.unitPrice))}</TableCell>
                      <TableCell>{formatNumbers(roundTo2Decimal(subItem.subTotal))}</TableCell>
                    </TableRow>
                  )
                })}
              </Table>
              { count > 1 &&
                <Pagination 
                  count={count} 
                  page={page+1}
                  onChange={(e, pageNo)=>setPage(pageNo-1)}
                />
              }
            </Paper>
          </Accordion>
        )
      })}
    </React.Fragment>
  );
}
