import _ from "lodash";
import store from "store";

import { setValues } from "../store/task";
import { GetOperationSubTaskDetail } from "services/UserPortal/OperationsService";

export const getOperationTaskDetail = (param, history, prop, isTask) => {
  const id = location.pathname.split("/").pop();
  store.dispatch(GetOperationSubTaskDetail({operationTaskId: id, ...param}))
  .then((response) => {
    if (!response.error) {
      const result = response.payload.result;
      store.dispatch(setValues({
        selectedAction: isTask ? _.filter(result, "isMyTask").map((item)=>_.pick(item, ["id", "deviceRefNo", "subTaskName", "startDate", "endDate", "frequency", "assignedUserId", "isAction", "remark", "isEquipment", "isRawMaterial"])) : [],
        rawMaterial: _.filter(result, "isRawMaterial").sort((a,b) => a.sequenceOrder.localeCompare(b.sequenceOrder, 'en', { numeric: true, })),
        equipment: _.filter(result, "isEquipment").sort((a,b) => a.sequenceOrder.localeCompare(b.sequenceOrder, 'en', { numeric: true, })),
        other: _.filter(result, {"isEquipment": false, "isRawMaterial": false}).sort((a,b) => a.sequenceOrder.localeCompare(b.sequenceOrder, 'en', { numeric: true, })),
      }));
    } else {
      goBackFromSubTask(history, prop);
    }
  });
}

export const goBackFromSubTask = (history, prop) => {
  const pathname = location.pathname.split("/");
  pathname.pop();
  history.push({
    pathname: pathname.join("/"), 
    state: prop,
    search: location.search
  });
};