import { combineReducers } from "redux";
import device from "./device";
import type from "./type";
import reading from "./reading";
import functions from "./functions";
import search from "./search";

const deviceReducer = combineReducers({
  device,
  reading,
  type,
  functions,
  search,
});

export default deviceReducer;