/* eslint-disable no-unused-vars */ // TO REMOVE
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Select from "shared-components/Select/Select";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import IconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { amrFutureTaskTableHead } from "enums/UserPortal/TableHeaders";
import { filterParam, filterUrl } from 'common/functions';
import { setValues } from "../store/overview";
import { GetUpcomingMissions, ExecuteGetAllRobots, DeleteUpcomingMissions } from "services/UserPortal/AMRService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/amr.module.scss";

export default function FutureTask() {
  let history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.amr.adhoc.filter);
  const result = useSelector(store => store.user.amr.adhoc.upcoming);
  const amrList = useSelector(store => store.user.amr.adhoc.amrList);
  const [newFilter, setNewFilter] = React.useState({robotId: [], task_type: "All", startDate: moment().format(), endDate: moment().add(7, 'days').endOf('day').format(), page: 0});
  const [id, setId] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(result.length / rowsPerTable);

  const handleOnChange_date = (value) => {
    if (value) {
      setNewFilter({...newFilter, startDate: moment(value.startDate).format(), endDate: moment(value.endDate).endOf('day').format()});
    }
  };

  const handleModal = (id) => {
    setId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteUpcomingMissions(id))
    .then(() =>{
      setId(null);
      getAllMission();
      setOpenModal(!openModal);
    })
  }

  const handleOnChange_select = (e) => {
    _.remove(e.target.value, (n) => n === "Select Value");
    setNewFilter({...newFilter, [e.target.name] : e.target.value});
  };
  
  const getAllMission = (stopLoading) => {
    dispatch(GetUpcomingMissions({...newFilter, stopLoading}));
  }

  React.useEffect(() => {
    getAllMission();
  },[newFilter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(ExecuteGetAllRobots({stopLoading: true}));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllMission(true);
  });

  return (
    <React.Fragment>
      <div className={styles.filterContainer}>
        <div className={styles.filterFields}>
          <div className={styles.filterField}>
            <Typography>AMR:</Typography>
            <Select
              name="robotId"
              multiple
              value={newFilter.robotId}
              renderValue={(value) => newFilter.robotId&&newFilter.robotId.length === 0 ? value :_.join(_.map(_.filter(amrList, (item) => _.includes(value, item.id)), (item) => item.alias), ', ')}
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select Value"
            >
              { amrList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    <Checkbox checked={_.findIndex(newFilter.robotId, (selected) => selected === item.id) > -1} />
                    {item.alias}
                  </MenuItem>
                );
              })} 
            </Select>
          </div>
          <div className={styles.filterField}>
            <Typography>Task Type:</Typography>
            <Select
              className={styles.filterSelect}
              name="task_type"
              onChange={(e)=>handleOnChange_select(e)}
              value={newFilter.task_type}
              placeholder="Select Value"
            >
              { ["All", "AdHoc", "Recurring"].map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })} 
            </Select>
          </div>
        </div>
        <div>
          <DateRangePicker
            clearable
            placeholder="Mission date"
            className={styles.cardActionDate}
            minDate={new Date()}
            value={{startDate: newFilter.startDate, endDate: newFilter.endDate}}
            onChange={(e) => handleOnChange_date(e)}
            onClear={()=>setNewFilter({...newFilter, startDate: null, endDate: null})}
          />
        </div>
      </div>
      <div className={styles.table}>
        <Table
          header={amrFutureTaskTableHead}
        >
          { result.slice(filter.page * rowsPerTable, filter.page * rowsPerTable + rowsPerTable).map((item,index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{item.robotId}</TableCell>
                  <TableCell>{item.missions}</TableCell>
                  <TableCell>{moment(item.delayDateTime).format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell>{moment(item.creationTime).format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell>{item.createdBy}</TableCell>
                  <TableCell>
                    <IconButton 
                      type="edit" 
                      // onClick={() => handleOnEdit(item)}
                    />
                    <IconButton 
                      type="delete"
                      onClick={() => handleModal(item.id)}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          })}
        </Table>
      </div>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={filter.page+1}
          onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
        />
      }
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this mission? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
