/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import DateTabs from "shared-components/Tabs/DateTabs";

import { reset } from "../store/costOverview";

import styles from "assets/jss/components/UserPortal/costProductivity.module.scss";
// import { ExportReport, GetActivationRatePercentReport, GetActiveRatePercentReport, GetActiveUsersReport } from "services/AdminPortal/ReportService";

const FileDownload = require('js-file-download');

export default function Overview() {
  const dispatch = useDispatch();
  const activeUsers = useSelector(store => store.admin.report.activeUsers);
  const activeRatePercent = useSelector(store => store.admin.report.activeRatePercent);
  const activationRatePercent = useSelector(store => store.admin.report.activationRatePercent);
  const [date, setDate] = React.useState({startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});

  const handleButtonClick_export = () => {
    // dispatch(ExportReport({startDate: date.startDate, endDate: date.endDate}))
    // .then((response) => {
    //   FileDownload(response.payload, 'Overall_Statistics.xlsx');
    // });
  };

  const config = (title) => {
    let array = []
    if (title === "Water Consumption Efficiency") {
      array = activeUsers.map((item) => {
        return Number(item.value)
      })
    } else if (title === "Energy Consumption Efficiency") {
      array = activeRatePercent.map((item) => {
        return Number(item.value)
      })
    } else if (title === "Waste Diversion Factor Efficiency") {
      array = activationRatePercent.map((item) => {
        return Number(item.value)
      })
    } else if (title === "Transport Fuel Factor Efficiency") {
      array = activationRatePercent.map((item) => {
        return Number(item.value)
      })
    } else if (title === "Chiller Efficiency") {
      array = activationRatePercent.map((item) => {
        return Number(item.value)
      })
    }
    return ({
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: "Duration"
        },
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: "Count"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          data: array,
          label: {
            connectorAllowed: false
          },
          pointStart: new Date(date.startDate).getTime(),
          pointInterval: 24 * 3600 * 1000, // one day
          color: "#225422"
        }
      },
      series: [{
        data: array,
        name: title
      }],
    })
  };

  React.useEffect(() => {
    // dispatch(GetActiveUsersReport({startDate: date.startDate, endDate: date.endDate}));
    // dispatch(GetActivationRatePercentReport({startDate: date.startDate, endDate: date.endDate}));
    // dispatch(GetActiveRatePercentReport({startDate: date.startDate, endDate: date.endDate}));
  },[date]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <div className={styles.action}>
        <DateTabs setDate={setDate} date={date} />
      </div>
      <div className={styles.graphContent}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              variant="outlined"
              classes={{root:styles.cardBorder}}
              title = "Water Consumption Efficiency"
            >
              <HighchartsReact highcharts={Highcharts} options={config("Water Consumption Efficiency")} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              variant="outlined"
              classes={{root:styles.cardBorder}}
              title = "Energy Consumption Efficiency"
            >
              <HighchartsReact highcharts={Highcharts} options={config("Energy Consumption Efficiency")} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              variant="outlined"
              classes={{root:styles.cardBorder}}
              title = "Waste Diversion Factor Efficiency"
            >
              <HighchartsReact highcharts={Highcharts} options={config("Waste Diversion Factor Efficiency")} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              variant="outlined"
              classes={{root:styles.cardBorder}}
              title = "Transport Fuel Factor Efficiency"
            >
              <HighchartsReact highcharts={Highcharts} options={config("Transport Fuel Factor Efficiency")} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              variant="outlined"
              classes={{root:styles.cardBorder}}
              title = "Chiller Efficiency"
            >
              <HighchartsReact highcharts={Highcharts} options={config("Chiller Efficiency")} />
            </Card>
          </Grid>
        </Grid>
      </div> 
    </React.Fragment>
  );
}
