import { combineReducers } from "redux";
import management from "./management";
import capacity from "./capacity";
import search from "./search";

const customerOrderReducer = combineReducers({
  management,
  capacity,
  search,
});

export default customerOrderReducer;