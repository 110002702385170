import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import Alert from "shared-components/Modal/Alert";

import OneOffOrder from "./OneOffOrder";
import RecurringOrder from "./RecurringOrder";
import ForecastOrder from "./ForecastOrder";
import { setDirty, setSearchText } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateRequest, reset } from "../store/management";
import { GetCustomerOrderBySaleOrderID } from "services/UserPortal/CustomerOrderService";

export default function AddEditOrder(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const [tab, setTab] = React.useState(props.tab && props.tab !== -1 ? props.tab : 0);
  const [newTab, setNewTab] = React.useState(0);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);

  const handleOnChange_tab = (e, value) => {
    if (isDirty) {
      setOpenAlertModal(!openAlertModal);
      setNewTab(value);
    } else {
      setTab(value);
    }
  }

  const handleButtonClick_exit = () => {
    setOpenAlertModal(false);
    setTab(newTab);
    dispatch(setDirty(false));
  }

  React.useEffect(() => {
    if (!props.isEdit) {
      switch (tab) {
        case 0:
          dispatch(updateRequest({
            orderType: "One-off", 
            deliveryDate : moment().format("YYYY-MM-DD")
          }));
          break;
        case 1: 
          dispatch(updateRequest({
            orderType: "Recurring",
            startDeliveryDate : moment().format("YYYY-MM-DD"), 
            endDeliveryDate: moment().add(1,'d').format("YYYY-MM-DD")
          }));
          break;
        case 2: 
          dispatch(updateRequest({
            orderType: "Forecast",
            startDeliveryDate : moment().format("YYYY-MM-DD"), 
            endDeliveryDate: moment().add(1,'d').format("YYYY-MM-DD")
          }));
          break;
      }
    }
  },[tab]);

  // componentDidMount
  React.useEffect(() => {
    if (props.isEdit) {
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "management");
      dispatch(setDirty(true));
      dispatch(GetCustomerOrderBySaleOrderID(path[index+1]))
      .then((response) => {
        if (response.error) {
          history.push("/user/sales-order/management");
        }
      });
    }
    dispatch(setAddNewButton(true));
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(resetNav());
    }
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <OneOffOrder isEdit={props.isEdit} setIsEdit={props.setIsEdit} />
      case 1:
        return <RecurringOrder isEdit={props.isEdit} setIsEdit={props.setIsEdit} />
      case 2:
        return <ForecastOrder isEdit={props.isEdit} setIsEdit={props.setIsEdit} />;
    }
  }

  return (
    <React.Fragment>
      <Card 
        title = {props.isEdit ? "Edit Sales Order" : "Create Sales Order"}
        subheader={
          <Tabs 
            tabs={["One Off Order", "Recurring Order", "Forecast Order"]}
            value={tab} 
            onChange={handleOnChange_tab}
            disabled={props.isEdit}
          />
        }
      >
        {renderComponent()}
      </Card>
      <Alert open={openAlertModal} onConfirm={()=>handleButtonClick_exit()} onCancel={()=>handleOnChange_tab()} />
    </React.Fragment>
  );
}
