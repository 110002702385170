import { createSlice } from "@reduxjs/toolkit";

import { SearchDevice } from "services/AdminPortal/DeviceService";

// initial state
const initialState = {
  searchResultsCount: {},
  searchResults: {}
};

const searchDevice = createSlice({
  name: "searchDevice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SearchDevice.fulfilled, (state, action) => {
        let deviceList = [];
        let deviceListCount = 0;
        Object.keys(action.payload.result).forEach((key) => {
          if (key === "deviceFinishList" || key === "deviceUnfinishList") {
            deviceListCount += action.payload.result[key] ? action.payload.result[key].totalCount : 0;
            deviceList = action.payload.result[key] ? [...deviceList, ...action.payload.result[key].items] : deviceList;
            if (deviceListCount) {
              state.searchResultsCount.deviceList = deviceListCount;
              state.searchResults.deviceList = deviceList;
            }
          } else {
            if (action.payload.result[key]) {
              state.searchResultsCount[key] = action.payload.result[key].totalCount;
              state.searchResults[key] = action.payload.result[key].items;
            }
          }
        })
      })
  },
});

// export actions
export const { reset } = searchDevice.actions;

// export the reducer
export default searchDevice.reducer;