/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
// @mui/material components
import { MenuItem, MenuList, Grow, Paper, ClickAwayListener, Popper, Divider, Button, Grid, Avatar } from "@mui/material";
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// @mui/icons-material
import Notifications from "@mui/icons-material/Notifications";
import ExpandMore from "@mui/icons-material/ExpandMore";
// core components
import Alert from "shared-components/Modal/Alert";
import Switch from "shared-components/Switch/Switch";

import Profile from "assets/icons/grey/user-profile.svg";
import ProfileWhite from "assets/icons/white/user-profile.svg";
import Farm from "assets/icons/grey/id-management.svg";
import FarmWhite from "assets/icons/white/id-management.svg";
import About from "assets/icons/grey/helper.svg";
import AboutWhite from "assets/icons/white/helper.svg";
import Administration from "assets/icons/grey/role.svg";
import AdministrationWhite from "assets/icons/white/role.svg";
import Logout from "assets/icons/grey/logout.svg";
import LogoutWhite from "assets/icons/white/logout.svg";

import * as Auth from "auth/Authenticate";
import { locale } from 'config';
import { useInterval } from 'common/utils';
import { setValues } from ".";
import { setLanguage, setDirty, setAdminPortal } from "store/general";
import { GetSidebar, GetNavigation } from "services/StrapiService";
import { GetAllAlarmNotification, GetAlarmNotificationCount, SetAlarmNotificationReadByUser } from "services/UserPortal/AlarmService";
import { LogOut } from "services/TokenAuthService";

import profileImg from "assets/img/user-img.svg"
import arrow from "assets/icons/black/droplist-arrow.svg";
import styles from "assets/jss/shared-components/headerLinks.module.scss";

export default function AdminNavbarLinks() {
  let history = useHistory();
  const dispatch = useDispatch();
  const strapiNavigation = useSelector(store => store.strapi.navigation);
  const selectedLang = useSelector(store => store.general.language);
  const isDirty = useSelector(store => store.general.isDirty);
  const isAdminPortal = useSelector(store => store.general.isAdminPortal);
  const unread = useSelector(store => store.navbar.unread);
  const alarms = useSelector(store => store.navbar.alarms);
  const notifications = useSelector(store => store.navbar.notifications);
  const [tab, setTab] = React.useState(0);
  const [isUnread, setIsUnread] = React.useState(false);
  const [openLanguage, setOpenLanguage] = React.useState(null);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [hover, setHover] = React.useState(null);
  const [userDetail, setUserDetail] = React.useState(null);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);

  const hasAdmin = JSON.parse(localStorage.getItem("hasAdmin"));

  const menu = [
    {name: "Profile Settings", url: "/settings/user-profile", icon: Profile, iconWhite: ProfileWhite},
    {name: "Farm Settings", url: "/settings/farm-settings", icon: Farm, iconWhite: FarmWhite},
    hasAdmin ? {name: isAdminPortal ? "User Portal" : "Admin Portal", url: isAdminPortal ? "/user/home" : "/admin/home", icon: Administration, iconWhite: AdministrationWhite} : null,
    {name: "About", url: "/settings/about", icon: About, iconWhite: AboutWhite},
  ]

  const items = [
    {name: "UserPortal.Operation", url: "/user/alarm/details/operations"},
    {name: "UserPortal.FinishedGoods", url: "/user/alarm/details/finished-goods"},
    {name: "UserPortal.Planning", url: "/user/alarm/details/planning"},
    {name: "UserPortal.Equipment", url: "/user/alarm/details/equipment"},
    {name: "UserPortal.RawMaterial", url: "/user/alarm/details/raw-material"},
    {name: "UserPortal.Delivery", url: "/user/alarm/details/delivery"},
    {name: "UserPortal.ClimateEnvironment", url: "/user/alarm/details/climate"},
  ]

  const handleClickLanguage = (event) => {
    if (openLanguage && openLanguage.contains(event.target)) {
      setOpenLanguage(null);
    } else {
      setOpenLanguage(event.currentTarget);
    }
  };

  const handleCloseLanguage = (item) => {
    setOpenLanguage(null);
    if (item) {
      dispatch(setLanguage(item));
    }
  };

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = (moduleName, item) => {
    setOpenNotification(null);
    const module = items.find(({name}) => name === moduleName);
    if (module) {
      dispatch(SetAlarmNotificationReadByUser(item.id));
      dispatch(GetAlarmNotificationCount());
      if (module.name === "UserPortal.Operation" && item.operation.productId) {
        if (tab === 0) {
          history.push({pathname: "/user/operations/overall-tasks/"+item.operation.productId, state: _.pick(item.operation, ["workOrderId", "productId", item.operation.isMixProduct && "operationId"])});
        } else {
          history.push({pathname: "/user/operations/task-assignment/"+item.operation.productId, state: _.pick(item.operation, ["workOrderId", "productId", item.operation.isMixProduct && "operationId"])});
        }
      } else {
        history.push({pathname: module.url, state: {tab: tab===0 ? 0 : 2}});
      }
    }
  };

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setHover(null);
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = (link) => {
    setOpenProfile(null);
    if (link) {
      history.push(link);
    }
  };

  const handleLogout = () => {
    if (isDirty) {
      setOpenAlertModal(true);
    } else {
      handleButtonClick_logout();
    }
  }

  const handleButtonClick_logout = () => {
    Promise.all([
      dispatch(setDirty(false)),
      dispatch(LogOut())
    ])
    .then(() => {
      Auth.logout(); 
      history.push("/login");
    })
  }

  const handleMouseOver = (value) => {
    setHover(value);
  };

  const handleMouseOut = () => {
    setHover(null);
  }

  const fetchNotif = () => {
    if (openNotification) {
      Promise.all([
        dispatch(GetAllAlarmNotification({IsNotification: false, IsResolved: false, IsShowOnlyForUnread: isUnread, maxResultCount: 10, page: 0, stopLoading: true})),
        dispatch(GetAllAlarmNotification({IsNotification: true, IsShowOnlyForUnread: isUnread, maxResultCount: 10, page: 0, stopLoading: true})),
      ]).then((response) => {
        let payload = {}; 
        for (let i = 0; i < response.length; i++) {
          if (response[i].payload && response[i].payload.result) {
            payload = {
              ...payload,
              [i===0?"alarms":"notifications"]: response[i].payload.result.items
            };
          }
        }
        dispatch(setValues(payload));
      })
    }
  };

  const getMenu = (name) => {
    const attributes = _.find(strapiNavigation, ({attributes}) => attributes.title==name);
    return attributes ? attributes.attributes.label : name;
  }

  useInterval(() => {
    dispatch(GetAlarmNotificationCount());
    fetchNotif();
  }, 1000*10);

  // TODO: Strapi
  React.useEffect(() => {
    // dispatch(GetSidebar());
    // dispatch(GetNavigation());
  }, [selectedLang]);

  React.useEffect(() => {
    fetchNotif();
  }, [isUnread, openNotification]);

  React.useEffect(() => {
    dispatch(GetAlarmNotificationCount());
    const checkUserData = () => {
      setUserDetail(JSON.parse(localStorage.getItem("userDetail")));
    }
    checkUserData();
    window.addEventListener("storage", checkUserData);
    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, []);
  
  const activeFarm = userDetail && _.find(userDetail.farms, "isActive");
  return (
    <Grid container className={styles.manager}>
      {/* <Button
        onClick={handleClickLanguage}
        className={styles.languageButton}
        startIcon={<img className={styles.flagIcon} src={selectedLang.flag} alt="flag" />}
        endIcon={<img className={styles.arrowIcon} src={arrow} alt="arrow" />}
      > 
        {selectedLang.name}
      </Button> */}
      <Popper 
        className={!openLanguage && styles.hidePopper}
        open={Boolean(openLanguage)} 
        anchorEl={openLanguage} 
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={styles.languageContainer}>
              <ClickAwayListener onClickAway={()=>handleCloseLanguage()}>
                <MenuList role="menu">
                  {locale.map((item, index) => {
                    return (
                      <MenuItem 
                        key={index}
                        className={item == selectedLang ? clsx(styles.languageItem, styles.selectedLanguage) : styles.languageItem}
                        onClick={() => handleCloseLanguage(item)}
                      >
                        <img className={styles.flagIcon} src={item.flag} alt="flag" />{item.name}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Divider orientation="vertical" flexItem className={styles.divider} />
      <Button
        onClick={handleClickNotification}
        className={clsx(styles.notificationButton, styles[unread.state])}
        startIcon={<Notifications className={styles.notificationIcon} />}
      > 
        {unread ? unread.notificationCount + unread.alarmCount : 0}
      </Button>
      <Popper 
        className={!openNotification && styles.hidePopper}
        open={Boolean(openNotification)} 
        anchorEl={openNotification} 
        placement="bottom-end"
        transition
        disablePortal 
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={styles.notificationContainer}>
              <ClickAwayListener onClickAway={()=>handleCloseNotification()}>
                <div>
                  <div className={styles.notificationAction}>
                    <Typography>Show Unread</Typography>
                    <Switch 
                      size="sm"
                      checked={isUnread}
                      onChange={() => setIsUnread(!isUnread)}
                    />
                  </div>
                  <Tabs 
                    classes={{
                      indicator: styles.indicator
                    }}
                    value={tab} 
                    onChange={(e, value) => setTab(value)}
                  >
                    <Tab 
                      className={tab === 0 ? clsx(styles.tabLabel, styles.orange) : styles.tabLabel} 
                      label={<React.Fragment>Alarms<span className={tab === 0 ? clsx(styles.notificationChip, styles.orangeBackground) : styles.notificationChip}>{unread&&unread.alarmCount}</span></React.Fragment>}
                    />
                    <Tab 
                      className={tab === 1 ? clsx(styles.tabLabel, styles.orange) : styles.tabLabel}
                      label={<React.Fragment>Notifications<span className={tab === 1 ? clsx(styles.notificationChip, styles.orangeBackground) : styles.notificationChip}>{unread&&unread.notificationCount}</span></React.Fragment>} 
                    />
                  </Tabs>
                  <MenuList role="menu" className={styles.notificationMenu}>
                    {(tab===0?alarms:notifications).map((item, index) => {
                      return (
                        <div key={index}>
                          <MenuItem 
                            className={item.isReadByUser ? styles.notificationItem : clsx(styles.notificationItem, styles.unread)}
                            onClick={() => handleCloseNotification(item.moduleName, item)}
                          >
                            <Typography className={item.isReadByUser ? styles.notificationMsg : clsx(styles.notificationMsg, styles.unreadMsg)}>{item.message}</Typography>
                            <Typography className={item.isReadByUser ? styles.notificationTime : clsx(styles.notificationTime, styles.unreadTime)}>{moment(item.creationTime).fromNow()}</Typography>
                          </MenuItem>
                          <Divider />
                        </div>
                      )
                    })}
                  </MenuList>
                  <Link to={{pathname: "/user/alarm/all", state: {tab: tab}}} onClick={handleCloseNotification}>
                    <Typography className={styles.link}>View All {tab===0 ? " Alarms" : " Notifications"}</Typography>
                  </Link>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Divider orientation="vertical" flexItem className={styles.divider} />
      <div className={styles.userDetailWrapper}>
        <Grid container className={styles.userDetailHover} onClick={handleClickProfile}>
          <Avatar className={styles.avatar} src={userDetail && userDetail.profileUrl ? userDetail.profileUrl : profileImg} />
          <div>
            <div className={styles.userName}>{userDetail && userDetail.name}</div>
            <div className={styles.userCompanyFarm}>{userDetail && userDetail.companyName}</div>
            {activeFarm && <div className={styles.userCompanyFarm}>{activeFarm.farmName}</div>}
          </div>
          <div className={styles.expand}>
          <ExpandMore className={styles.expandMoreIcon}/></div>
        </Grid>
      </div>
      <Popper
        className={!openProfile && styles.hidePopper}
        open={Boolean(openProfile)}
        anchorEl={openProfile}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={styles.dropdownContainer}>
              <ClickAwayListener onClickAway={() => handleCloseProfile()}>
                <div>
                  <div className={styles.dropdownTopPadding}>
                    <Avatar className={styles.dropdownAvatar} src={userDetail && userDetail.profileUrl ? userDetail.profileUrl : profileImg} />
                    <div className={styles.dropdownUsername}>
                      {userDetail && userDetail.name}
                    </div>
                    {activeFarm && <div className={styles.dropdownUserDetail}>{activeFarm.farmName}</div>}
                  </div>
                  <MenuList role="menu">
                    { menu.map((item, index) => {
                      if (item) {
                        return (
                          <div key={index}>
                            <MenuItem
                              onClick={() => handleCloseProfile(item.url)}
                              className={styles.dropdownItem}
                              onMouseOver={()=>handleMouseOver(index+1)}
                              onMouseOut={()=>handleMouseOut()}
                            >
                              <img src={hover===index+1?item.iconWhite:item.icon} alt="profile" className={styles.dropdownIcon}/>
                              {getMenu(item.name)}
                            </MenuItem>
                            <Divider />
                          </div>
                        )
                      }
                      return null;
                    })}
                    <MenuItem
                      onClick={() => handleLogout()}
                      className={styles.dropdownItem}
                      onMouseOver={()=>handleMouseOver(5)}
                      onMouseOut={()=>handleMouseOut()}
                    >
                      <img src={(hover===5)?LogoutWhite:Logout} alt="logout" className={styles.dropdownIcon}/>
                      {getMenu("Logout")}
                    </MenuItem>
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Alert open={openAlertModal} onConfirm={()=>handleButtonClick_logout()} onCancel={()=>setOpenAlertModal(false)} />
    </Grid>
  );
}
