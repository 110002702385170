// Create Edit Device Type
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateRequest, resetDevice } from "./store/type";
import { GetDeviceType, CreateOrUpdateDeviceType, GetAllFunctionLibrary } from "services/AdminPortal/DeviceService";

import styles from "assets/jss/components/AdminPortal/device.module.scss";

export default function AddEditDeviceTypes(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const deviceType = useSelector(store => store.admin.device.type.deviceType);
  const functionLibraryNames = useSelector(store => store.admin.device.functions.functionLibraryNames);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    let value = e.target.value;
    if (e.target.id === "deviceShortForm") {
      value = value.toUpperCase();
    }
    dispatch(updateRequest({[e.target.id] : value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateDeviceType())
      .then((response) => {
        if (response.error) {
          if (response.payload.message.includes("Name")) {
            setErrorMsg({field: "deviceTypeName", msg: "Device type name already exist"});
          } else {
            setErrorMsg({field: "deviceShortForm", msg: "Device type short form already exist"});
          }
        } else {
          resetState();
        }
      })
    }
  }

  const validateFields = () => {
    if (_.isEmpty(deviceType.deviceTypeName)) {
      setErrorMsg({field: "deviceTypeName", msg: "You must provide a device type name"});
      return false;
    }
    if (_.isEmpty(deviceType.deviceShortForm)) {
      setErrorMsg({field: "deviceShortForm", msg: "You must provide a device short form"});
      return false;
    }
    if (!validate.isShortform(deviceType.deviceShortForm)) {
      setErrorMsg({field: "deviceShortForm", msg: "Shortform should contain at most 3 upper case characters"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      if (props.setNewOrUpdate) {
        props.setNewOrUpdate(false);
        props.setTypeId(null);
      } else {
        const prop = props.location && props.location.state;  // from search
        history.push((prop && prop.prevPath) || "/admin/device");
      }
      dispatch(resetDevice());
    })
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setDirty(true));
    const prop = props.location && props.location.state;  // from search
    if (prop) {
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "type");
      dispatch(GetDeviceType(path[index+1]))
      .then(({error}) => {
        if (error) {
          Promise.all([dispatch(setDirty(false))])
          .then(() => {
            history.push((prop.prevPath) || "/admin/device");
          })
        }
      });
    }
    if(props.id) {  // from add/edit flow
      dispatch(GetDeviceType(props.id));
    }
    dispatch(GetAllFunctionLibrary());
    // componentDidUnmount
    return () => {
      dispatch(resetDevice());
      dispatch(setDirty(false));
      if (prop) {
        dispatch(resetNav());
      }
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = {props.id || (props.location && props.location.state) ? "Edit Device Type" : "Add New Device Type"}
        cardActions={
          <React.Fragment>
            <Button disabled/>
            <div>
              <Button
                className={clsx(styles.buttonSecondary, styles.buttonMargin)}
                onClick={()=>resetState()}
              >
                Cancel
              </Button>
              <Button
                className={styles.button}
                onClick={()=>handleButtonClick_save()}
              >
                Save
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <Grid container spacing={8}>
          <Grid item xs={4}>
            <TextField 
              label={<React.Fragment>{!props.id && "New "}Device Type <span className={styles.orange}>*</span></React.Fragment>}
              id="deviceTypeName"
              variant="outlined" 
              inputProps={{ maxLength: 120 }}
              placeholder="Enter Device Type Name Here"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(deviceType.deviceTypeName)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField 
              label={<React.Fragment>{!props.id && "New "}Shortform <span className={styles.orange}>*</span></React.Fragment>}
              id="deviceShortForm"
              variant="outlined" 
              inputProps={{ maxLength: 3 }}
              placeholder="Enter Device Type Shortform Here"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(deviceType.deviceShortForm)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              label="Library"
              name="functionLibraryId"
              onChange={(e)=>handleOnChange_select(e)}
              value={renderValue(deviceType.functionLibraryId)}
              placeholder="Please select a library"
            >
              {functionLibraryNames && functionLibraryNames.map((item) => {
                return <MenuItem key={item.id} value={item.id}>{item.functionLibraryName}</MenuItem>;
              })} 
            </Select>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
