import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
// core components
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import { graphColors } from "assets/jss/palette";
import styles from "assets/jss/components/UserPortal/sustainability.module.scss";

export default function EmissionChart(props) {
  const results = useSelector(store => store.user.sustainability.chart.conversion);

  const handleOnChange_date = (date) => {
    if(date && date != "Invalid Date") {
      props.setDate({startDate: moment(date).format("YYYY-MM-DD"), endDate: moment(date).format("YYYY-MM-DD")});
    } else {
      props.setDate({startDate: date, endDate: date});
    }
  };

  const start = moment(props.date.endDate);
  const result = results.kgco2 && results.kgco2.find((val) => val && val.month === start.month()+1 && val.year === start.year());
  const config = () => {
    return ({
      colors: graphColors,
      chart: {
        type: 'column',
        zoomType: 'x',
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        valueSuffix: ' tonCO2'
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'tonCO2',
        }
      },
      series: [
        {
          name: "Emission Breakdown",
          groupPadding: 0,
          data: [
            {
              name: 'Solar PV',
              y: result && -result.pV_Value,
              color: graphColors[0]
            },
            {
              name: 'Purchase Electricity',
              y: result && result.grid_Value,
              color: graphColors[1]
            },
          ]
        }
      ],
    })
  };

  return (
    <React.Fragment>
      <div className={styles.action}>
        <DateTimePicker
          clearable
          className={styles.datepicker}
          placeholder="Date"
          value={props.date.endDate}
          onChange={(e) => handleOnChange_date(e)}
          onClear={()=>props.setDate({startDate: null, endDate: null})}
        />
      </div>
      <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: styles.highChart }} />
    </React.Fragment>
  );
}
