import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { GetTodayYesterdayData } from "services/UserPortal/SmartMeteringFlagshipService";

import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";

export default function EnergyConsumption() {
  const dispatch = useDispatch();
  const result = useSelector(store => store.user.smartMetering.flagship.consumption);

  const getData = () => {
    dispatch(GetTodayYesterdayData());
  }

  React.useEffect(() => {
    getData();
  },[]);

  return (
    <div>
      <Typography className={styles.label}>Energy Consumption</Typography>
      <Paper className={styles.paper} elevation={0}>
        <Typography>Today: <span className={styles.orange}>{result.todayTotal}kWh</span></Typography>
        <Typography>Yesterday: <span className={styles.orange}>{result.yesterdayTotal}kWh</span></Typography>
      </Paper>
      {/* <Typography className={styles.sublabel}>Last Updated: {result.todayTotalEnergy && moment(result.todayTotalEnergy.dateTime).format('DD/MM/YYYY HH:mm')}</Typography> */}
    </div>
  );
}
