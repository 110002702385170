import { createSlice } from "@reduxjs/toolkit";

import { GetAllOverallLogStatus, GetAllLogSheet, GetProductProcessName, GetTaskNumberList, GetLotIdByWorkOrderIdAndProductId } from "services/UserPortal/HumanResourceService";

// initial state
const initialState = {
  filter: {},
  overallTotalCount: 0,
  overallLog: [],
  totalTime: 0,
  totalCount: 0,
  totalWorkingHours: 0,
  logSheet: [],
  log: {},
  lotId: null,
  // Lookup
  employeeList: [],
  processList: [],
  taskList: []
};

const adminLogSheet = createSlice({
  name: "adminLogSheet",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.log[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllOverallLogStatus.fulfilled, (state, action) => {
        state.overallTotalCount = action.payload.result.totalCount;
        state.overallLog = action.payload.result.items;
      })
      .addCase(GetAllLogSheet.fulfilled, (state, action) => {
        state.totalTime = action.payload.result.totalTimeSpent;
        state.totalCount = action.payload.result.totalCount;
        state.totalWorkingHours = action.payload.result.totalWorkingHours;
        state.logSheet = action.payload.result.items;
        state.log = {workingDayType: "FullWorkingDay", logSheetIdList: action.payload.result.items.map(({id}) => id)};
      })
      .addCase(GetProductProcessName.fulfilled, (state, action) => {
        state.processList = action.payload.result;
      })
      .addCase(GetTaskNumberList.fulfilled, (state, action) => {
        state.taskList = action.payload.result;
      })
      .addCase(GetLotIdByWorkOrderIdAndProductId.fulfilled, (state, action) => {
        state.lotId = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = adminLogSheet.actions;

// export the reducer
export default adminLogSheet.reducer;