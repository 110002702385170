import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { GetDashboard } from "services/UserPortal/SmartMeteringService";

// initial state
const initialState = {
  filter: {startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")},
  result: [],
  cost: null,
};

const smCostChart = createSlice({
  name: "smCostChart",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetDashboard.fulfilled, (state, action) => {
        state.result = action.payload.result ?? [];
      })
  },
});

// export actions
export const { setValues, updateRequest, updateExcessOrderRequest, reset } = smCostChart.actions;

// export the reducer
export default smCostChart.reducer;