import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// @mui/material
import { Paper, Grid } from "@mui/material";
// core components
import Card from "shared-components/Card/Card";

import farm from "assets/icons/orange/farm.svg";
import customer from "assets/icons/orange/customer.svg";
import device from "assets/icons/orange/device.svg";
import modules from "assets/icons/orange/module.svg";
import product from "assets/icons/orange/products.svg";
import rawMaterial from "assets/icons/orange/raw-material.svg";
import role from "assets/icons/orange/role.svg";
import user from "assets/icons/orange/user.svg";

import farmWhite from "assets/icons/white/farm.svg";
import customerWhite from "assets/icons/white/customer.svg";
import deviceWhite from "assets/icons/white/device.svg";
import modulesWhite from "assets/icons/white/module.svg";
import productWhite from "assets/icons/white/products.svg";
import rawMaterialWhite from "assets/icons/white/raw-material.svg";
import roleWhite from "assets/icons/white/role.svg";
import userWhite from "assets/icons/white/user.svg";

import styles from "assets/jss/components/AdminPortal/home.module.scss";

import { reset } from "./";
import { GetCompanyDashboard } from "services/AdminPortal/CompanyService";
import { checkPermission } from "common/functions";

export default function Home() {
  const dispatch = useDispatch();
  const companyId = useSelector(store => store.admin.home.companyId);
  const totalEmployee = useSelector(store => store.admin.home.totalEmployee);
  const totalFarm = useSelector(store => store.admin.home.totalFarm);
  const [hoverIndex, setHoverIndex] = React.useState(null);

  const items = [
    {title: "Farm", url: "/admin/farm", icon: farm, iconWhite: farmWhite, name: "Admin.Farm"},
    {title: "User", url: "/admin/user", icon: user, iconWhite: userWhite, name: "Admin.User"},
    {title: "Role", url: "/admin/role", icon: role, iconWhite: roleWhite, name: "Admin.Role"},
    {title: "Module", url: "/admin/module", icon: modules, iconWhite: modulesWhite, name: "Admin.Module"},
    {title: "Product", url: "/admin/products", icon: product, iconWhite: productWhite, name: "Admin.Products"},
    {title: "RM", url: "/admin/raw-material", icon: rawMaterial, iconWhite: rawMaterialWhite, name: "Admin.Raw Material"},
    {title: "Customer", url: "/admin/customer", icon: customer, iconWhite: customerWhite, name: "Admin.Customer"},
    {title: "Device", url: "/admin/device", icon: device, iconWhite: deviceWhite, name: "Admin.Device"},
  ]

  //get data from localStorage
  const userDetail = JSON.parse(localStorage.getItem("userDetail"));

  const handleMouseOver = (key) => {
    setHoverIndex(key);
  };

  const handleMouseOut = () => {
    setHoverIndex(null);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetCompanyDashboard());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    //this one is the new user homescreen
    //<NewUserHome userName={userDetail.userName}/>
    <div>
      <Grid container spacing={4} className={styles.topContainer}>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            classes={{
              content: styles.cardContent,
            }}
          >
            <p className={styles.cardTitle}>{userDetail.companyName} Company</p>
            <p className={styles.cardDescription}>Company ID:</p>
            <p className={styles.cardData}>{companyId}</p>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            classes={{
              content: styles.cardContent,
            }}
          >
            <p className={styles.cardTitle}>User Information</p>
            <p className={styles.cardDescription}>No. of Users:</p>
            {(totalEmployee === 1)
              ? <p className={styles.cardData}>{totalEmployee} User</p>
              : <p className={styles.cardData}>{totalEmployee} Users</p>
            }
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            classes={{
              content: styles.cardContent,
            }}
          >
            <p className={styles.cardTitle}>Farm Information</p>
            <p className={styles.cardDescription}>Total Farms:</p>
            {(totalFarm === 1)
              ? <p className={styles.cardData}>{totalFarm} Farm</p>
              : <p className={styles.cardData}>{totalFarm} Farms</p>
            }
          </Card>
        </Grid>
      </Grid>
      <Card 
        classes={{
          content: styles.cardContent,
        }}
      >
        <Grid container spacing={4}>
          {items.map((item, key) => {
            if (!checkPermission(item.name, "none")) {
              return (
                <Grid item xs={12} sm={6} md={2} key={key}>
                  <Link to={{pathname: item.url}}>
                    <Paper elevation={0} className={styles.paper} 
                      onMouseOver={()=>handleMouseOver(key)}
                      onMouseOut={()=>handleMouseOut()}
                    >
                      <img className={styles.logoImage} src={(hoverIndex===key)?item.iconWhite:item.icon} alt={item.title} />
                      <div className={styles.logoTitle}>
                      <p >
                        {item.title} <br/> Management
                      </p>
                      </div>
                    </Paper>
                  </Link>
                </Grid>
              ); 
            } else {
              return null
            }
          })}
        </Grid>
      </Card>
    </div>
  );
}
