import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import { rowsPerTable, minDate } from "config";
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { setValues } from "../store/harvest";
import { harvestTableHead } from "enums/UserPortal/TableHeaders";
import { GetWorkOrderRevenue } from "services/UserPortal/CostProductivityService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import styles from "assets/jss/components/UserPortal/costProductivity.module.scss";

export default function Harevest() {
  const dispatch = useDispatch();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const filter = useSelector(store => store.user.costProductivity.harvest.filter);
  const subTotal = useSelector(store => store.user.costProductivity.harvest.subTotal);
  const totalCount = useSelector(store => store.user.costProductivity.harvest.totalCount);
  const results = useSelector(store => store.user.costProductivity.harvest.harvests);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, productId: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('productName');

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    if (key === "workOrderId") {
      dispatch(setValues({filter: {...filter, [key]: null, productId: null, page: 0}}));
    } else {
      dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
    }
  };

  const getWorkOrderRevenue = (stopLoading) => {
    if (filter.workOrderId) {
      dispatch(GetWorkOrderRevenue({...filter, stopLoading}));
    }
  }
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search, page: 0}}));
    } else {
      if (workOrderList.length) {
        dispatch(setValues({filter: {workOrderId: workOrderList[0].id, productId: null, page: 0}}));
      } else {
        dispatch(setValues({filter: {workOrderId: null, productId: null, page: 0}}));
      }
    }
  },[workOrderList]);

  React.useEffect(() => {
    if (newFilter.workOrderId) {
      dispatch(GetProductList());
    }
  },[newFilter.workOrderId]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getWorkOrderRevenue();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);
  
  useInterval(() => {
    getWorkOrderRevenue(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="Cost & Efficiency"
        subheader={
          <React.Fragment>
            <Typography>Choose the Work Order to know the harvested amount:</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key!=="page") {
                let label = filter[key];
                if (key === "workOrderId") {
                  const workOrder = workOrderList.find(({id}) => id == filter[key]);
                  label = workOrder && workOrder.workOrderNo;
                }
                if (key === "productId") {
                  const product = productList.find(({id}) => id == filter[key]);
                  label = product && (product.productName + "(" + product.productRefNo + ")");
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={styles.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <div className={styles.cardHeader}>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the harvest list
                  <Select
                    className={styles.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                    disabled={!newFilter.workOrderId}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </div>
        }
      >
        <div className={styles.table}>
          <Table
            header={harvestTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { results.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.product_Id}</TableCell>
                  <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.weightHarvested))}</TableCell>
                  <TableCell>{moment(item.harvestedDate).isAfter(minDate) && moment(item.harvestedDate).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>${formatNumbers(roundTo2Decimal(item.unitPrice))}</TableCell>
                  <TableCell>${formatNumbers(roundTo2Decimal(item.projectedRevenue))}</TableCell>
                </TableRow>
              )
            })}
            <TableRow className={styles.subtotal}>
              <TableCell colSpan={6} align="right">Subtotal</TableCell>
              <TableCell>${subTotal && formatNumbers(roundTo2Decimal(subTotal))}</TableCell>
            </TableRow>
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
