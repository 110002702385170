import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import {FomsMS} from "../index";

export const GetPurchaseOrderLookup = createAsyncThunk(
  "traceability/GetPurchaseOrderLookup", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Traceability/GetPurchaseOrderLookup", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWorkOrderLookup = createAsyncThunk(
  "traceability/GetWorkOrderLookup", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Traceability/GetWorkOrderLookup", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetSaleOrderLookup = createAsyncThunk(
  "traceability/GetSaleOrderLookup", 
  async (id, {rejectWithValue}) => {
    const params = {
      POId: id
    };
    try {
      const response = await FomsMS.get("/userportal/Traceability/GetSaleOrderLookup", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTraceabilityBySO = createAsyncThunk(
  "traceability/GetTraceabilityBySO", 
  async (id, {rejectWithValue}) => {
    const params = {
      SOId: id
    };
    try {
      const response = await FomsMS.get("/userportal/Traceability/GetTraceabilityBySO", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTraceabilityByWO = createAsyncThunk(
  "traceability/GetTraceabilityByWO", 
  async (id, {rejectWithValue}) => {
    const params = {
      WOId: id
    };
    try {
      const response = await FomsMS.get("/userportal/Traceability/GetTraceabilityByWO", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadTraceabilityBySO = createAsyncThunk(
  "traceability/DownloadTraceabilityBySO", 
  async (id, {rejectWithValue}) => {
    var params = new URLSearchParams();
    params.append("SOId", id);
    const config = {
      responseType: 'blob',
      params,
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/Traceability/DownloadTraceabilityBySO", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadTraceabilityByWO = createAsyncThunk(
  "traceability/DownloadTraceabilityByWO", 
  async (id, {rejectWithValue}) => {
    var params = new URLSearchParams();
    params.append("WOId", id);
    const config = {
      responseType: 'blob',
      params,
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/Traceability/DownloadTraceabilityByWO", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);