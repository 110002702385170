import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  searchResultsCount: {},
  searchResults: {},
};

const search = createSlice({
  name: "search",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  // extraReducers: {
    
  // },
});

// export actions
export const { reset } = search.actions;

// export the reducer
export default search.reducer;