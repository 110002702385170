import { createSlice } from "@reduxjs/toolkit";

import { SearchAdminLog } from "services/AdminPortal/AdminLogService";

// initial state
const initialState = {
  totalCount: 0,
  logs: [],
};

const adminLog = createSlice({
  name: "adminLog",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SearchAdminLog.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.logs = action.payload.result.items;
      })
  },
});

// export actions
export const { reset } = adminLog.actions;

// export the reducer
export default adminLog.reducer;