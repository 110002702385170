import { combineReducers } from "redux";
import overview from "./overview";
import task from "./task";
import settings from "./settings";

const operationsReducer = combineReducers({
  overview,
  task,
  settings
});

export default operationsReducer;