// Create Edit Raw Material
// Stepper
import React from "react";
// core components
import Stepper from "shared-components/Stepper/Stepper";

export default function ProductStepper(props) {

  const productSteps = ['Input for Product Creation', 'Input for Growth Process', 'Input Raw Materials & Equipment', 'Input Tasks', '% loss for each process'];

  return (
    <Stepper 
      steps={productSteps}
      activeStep={props.activeStep}
    />
  )
}
