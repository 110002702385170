/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
// @mui/material components
import { AppBar, Toolbar, Tooltip, Button } from "@mui/material";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import { setAddNewButton } from "./";

//hooks
import { useRouteName, useRouteCanCreate } from "hooks";

import chatbot from "assets/img/chatbot.svg";
import styles from "assets/jss/shared-components/header.module.scss";

export default function Header(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const strapiNavigation = useSelector(store => store.strapi.navigation);
  const routeName = useRouteName();
  const canCreate = useRouteCanCreate();

  const handleButtonClick_addNew = () => {
    const pathName = location.pathname.replace("/search", "");
    if (pathName.includes("device-tagging")) {
      history.push(pathName+"/edit");
    } else if (pathName.includes("admin/equipment")) {
      history.push(pathName+"/configure");
    } else {
      history.push(pathName+"/create");
    }
    dispatch(setAddNewButton(true));
  }

  const openChatbot = () => {
    props.setChatbotOpen(!props.chatbotOpen);
    localStorage.setItem('chatbotOpen', true);
  }

  const attributes = _.find(strapiNavigation, ({attributes}) => attributes.title=="Add New");
  return (
    <AppBar className={styles.appBar}>
      <Toolbar className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.title}>
            {routeName}
          </div>
          {canCreate &&
            <Button
              onClick={ () => handleButtonClick_addNew() }
              className={styles.addNewButton}
              startIcon={<AddIcon />}
              disabled={isDisabled}
            > 
              {attributes ? attributes.attributes.label : "Add New" + (location.pathname.includes("raw-material/receipt") ? " Request" : "")}
            </Button>
          }
        </div>
        {!props.chatbotOpen &&
          <Tooltip title="AI Chatbot" placement="top">
            <Button onClick={openChatbot}>
              <img className={styles.chatbotImg} src={chatbot} alt="chatbot" />
            </Button>
          </Tooltip>
        }
          <AdminNavbarLinks />
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
