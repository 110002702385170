import { createAsyncThunk } from "@reduxjs/toolkit";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";
import * as Auth from "auth/Authenticate";

export const GetUserProfile = createAsyncThunk(
  "farm/getUserProfile", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {id: userDetail.id};
    try {
      const response = await FomsMS.get("/Farm/GetUser", {params});
      Auth.updateProfile(response.data);
      window.dispatchEvent(new Event("storage"));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllFarm = createAsyncThunk(
  "farm/getAllFarm", 
  async (param, {rejectWithValue}) => {
    const params = {
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    };
    try {
      const response = await FomsMS.get("/Farm/GetAll", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetFarm = createAsyncThunk(
  "farm/getFarm", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.get("/Farm/Get", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteFarm = createAsyncThunk(
  "farm/deleteFarm", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/Farm/Delete", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateFarm = createAsyncThunk(
  "farm/createOrUpdateFarm", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const farm = getState().admin.farm.farm;
    const banks = getState().admin.farm.banks;
    let body = {
      companyID: userDetail.companyId,
      ...farm,
      bankList: banks
    };
    try {
      const response = await FomsMS.post("/Farm/CreateOrUpdate", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllBank = createAsyncThunk(
  "farm/getAllBank", 
  async (param, {rejectWithValue}) => {
    const params = {
      FarmId: param.id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: rowsPerTable
    };
    try {
      const response = await FomsMS.get("/Farm/GetAllBank", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteBank = createAsyncThunk(
  "farm/deleteBank", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        Id:id
      };
      const response = await FomsMS.delete("/Farm/DeleteBank", {params, isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateBank = createAsyncThunk(
  "farm/createOrUpdateBank", 
  async (id, {getState, rejectWithValue}) => {
    const bank = getState().admin.farm.bank;
    const body = { 
      ...bank,
      farmId: id
    };
    try {
      const response = await FomsMS.post("/Farm/CreateOrUpdateBank", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchFarm = createAsyncThunk(
  "farm/searchFarm", 
  async (Keyword, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword
    }
    try {
      const response = await FomsMS.get("/Farm/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);