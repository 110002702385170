// Device
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";

import Hierarchy from "./Hierarchy";
import Location from "./Location";
import Empty from "error-page/Empty";
import { rowsPerTable } from "config";
import { setSearchText } from "store/general";
import { resetDevices } from "./store/device";
import { deviceTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllDevice, DeleteDevice } from "services/AdminPortal/DeviceService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/device.module.scss";

import { filterActionTableHead } from "common/functions";
import { useRouteCanWrite } from "hooks";

export default function Device() {
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const finishedDevices = useSelector(store => store.admin.device.device.finishedDevices);
  const unfinishedDevices = useSelector(store => store.admin.device.device.unfinishedDevices);
  const [order, setOrder] = React.useState({finished: 'asc', unfinished: 'asc'});
  const [orderBy, setOrderBy] = React.useState({finished: 'deviceRefNo', unfinished: 'deviceRefNo'});
  const [page, setPage] = React.useState({finished: 0, unfinished: 0});
  const [deviceId, setDeviceId] = React.useState(null);
  const [locationDetail, setLocationDetail] = React.useState(false);
  const [hierarchyDetail, setHierarchyDetail] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);

  const finishedCount = Math.ceil(finishedDevices.length / rowsPerTable);
  const unfinishedCount = Math.ceil(unfinishedDevices.length / rowsPerTable);

  const handleRequestSortFinished = (e, property) => {
    const isAsc = orderBy.finished === property && order.finished === 'asc';
    setOrder({...order, finished: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, finished: property});
  };
  
  const handleRequestSortUnfinished = (e, property) => {
    const isAsc = orderBy.unfinished === property && order.unfinished === 'asc';
    setOrder({...order, unfinished: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, unfinished: property});
  };

  const handleButtonClick_hierarchy = (id) => {
    setDeviceId(id);
    setHierarchyDetail(true);
  }

  const handleButtonClick_location = (id, currentLocation) => {
    setDeviceId(id);
    setLocationDetail(currentLocation);
  }

  const handleModal = (id) => {
    setDeviceId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteDevice(deviceId))
    .then(() =>{
      dispatch(GetAllDevice());
      resetState();
    })
  }

  const resetState = () => {
    setDeviceId(null);
    setPage({finished: 0, unfinished: 0});
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllDevice());
    // componentDidUnmount
    return () => {
      dispatch(resetDevices());
    }
  },[]);

  if (locationDetail) {
    return <Location deviceId={deviceId} locationDetail={locationDetail} setLocationDetail={setLocationDetail} canWrite={canWrite}/>
  } else if (hierarchyDetail) {
    return <Hierarchy deviceId={deviceId} hierarchyDetail={hierarchyDetail} setHierarchyDetail={setHierarchyDetail} devices={[...finishedDevices, ...unfinishedDevices]} />
  } else {
    return (
      <React.Fragment>
        { finishedDevices.length > 0 || unfinishedDevices.length > 0 
        ? <React.Fragment>
            { finishedDevices.length > 0 &&
              <Card 
                title = "Device List"
              >
                {/* Finished Devices Table */}
                <div className={styles.table}>
                  <Table
                    header={filterActionTableHead(deviceTableHead, canWrite)}
                    order={order.finished}
                    orderBy={orderBy.finished}
                    onRequestSort={handleRequestSortFinished}
                  >
                    { _.orderBy(finishedDevices, [orderBy.finished], [order.finished])
                    .slice(page.finished * rowsPerTable, page.finished * rowsPerTable + rowsPerTable)
                    .map((device,index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={styles.deviceTableCell}>{device.deviceRefNo}</TableCell>
                          <TableCell className={styles.deviceTableCell}>{device.deviceName}</TableCell>
                          <TableCell className={styles.deviceTableCell}>{device.manufacturerID}</TableCell>
                          <TableCell>{device.manufacturerName}</TableCell>
                          <TableCell>{device.manufacturerPhone}</TableCell>
                          <TableCell>{device.manufacturerEmail}</TableCell>
                          <TableCell>{device.testing}</TableCell>
                          <TableCell>
                            <span className={styles.link} onClick={() => handleButtonClick_hierarchy(device.id)}>
                              {device.mappingHierarchy}
                            </span>
                          </TableCell>
                          <TableCell>
                            {device.deviceLocation
                            ? <span className={styles.link} onClick={() => handleButtonClick_location(device.id, device.deviceLocation)}>
                                Details
                              </span>
                            : canWrite 
                              ? <Link className={styles.link} to={{pathname: "/admin/device-tagging/edit", state: {step: 3, deviceRefNo: device.deviceRefNo, deviceId: device.id}}}>
                                  Add Info
                                </Link>
                              : null
                            }
                          </TableCell>
                          <TableCell><Typography className={styles.ellipsis}>{device.deviceDesc}</Typography></TableCell>
                          <TableCell align="right">
                            {canWrite &&
                              <React.Fragment>
                                <Link to={{pathname: "/admin/device/"+device.id}}>
                                  <CustomIconButton type="edit" />
                                </Link>
                                <CustomIconButton
                                  type="delete" 
                                  onClick={() => handleModal(device.id)}
                                />
                              </React.Fragment>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </div>
                { finishedCount > 1 &&
                  <Pagination 
                    count={finishedCount} 
                    page={page.finished+1}
                    onChange={(e, pageNo)=>setPage({...page, finished: pageNo-1})}
                  />
                }
              </Card>
            }
            { unfinishedDevices.length > 0 &&
              // Unfinished Devices Table
              <Card 
                classes={{
                  root: styles.bottomCard
                }}
                title = {
                  <React.Fragment>
                    Unfinished Device List
                    <IconButton 
                      className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      <img className={styles.icon} src={arrow} alt="arrow" />
                    </IconButton>
                  </React.Fragment>
                }
              >
                { !collapsed && 
                <React.Fragment>
                  <div className={styles.table}>
                    <Table
                      header={filterActionTableHead(deviceTableHead, canWrite)}
                      order={order.unfinished}
                      orderBy={orderBy.unfinished}
                      onRequestSort={handleRequestSortUnfinished}
                    >
                      { _.orderBy(unfinishedDevices, [orderBy.unfinished], [order.unfinished])
                      .slice(page.unfinished * rowsPerTable, page.unfinished * rowsPerTable + rowsPerTable)
                      .map((device,index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className={styles.deviceTableCell}>{device.deviceRefNo}</TableCell>
                            <TableCell className={styles.deviceTableCell}>{device.deviceName}</TableCell>
                            <TableCell className={styles.deviceTableCell}>{device.manufacturerID}</TableCell>
                            <TableCell>{device.manufacturerName}</TableCell>
                            <TableCell>{device.manufacturerPhone}</TableCell>
                            <TableCell>{device.manufacturerEmail}</TableCell>
                            <TableCell>{device.testing}</TableCell>
                            <TableCell>
                              <span className={styles.link} onClick={() => handleButtonClick_hierarchy(device.id)}>
                                {device.mappingHierarchy}
                              </span>
                            </TableCell>
                            <TableCell>
                                {device.deviceLocation
                                ? <span 
                                    className={styles.link} 
                                    onClick={() => handleButtonClick_location(device.deviceRefNo, device.deviceLocation)}
                                  >
                                    Details
                                  </span>
                                : canWrite 
                                  ? <Link className={styles.link} to={{pathname: "/admin/device-tagging/edit", state: {step: 3, deviceRefNo: device.deviceRefNo, deviceId: device.id}}}>
                                      Add Info
                                    </Link>
                                  : null
                                }
                            </TableCell>
                            <TableCell><Typography className={styles.ellipsis}>{device.deviceDesc}</Typography></TableCell>
                            <TableCell align="right">
                              {canWrite &&
                                <React.Fragment>
                                  <Link to={{pathname: "/admin/device/"+device.id}}>
                                    <CustomIconButton type="edit" />
                                  </Link>
                                  <CustomIconButton
                                    type="delete" 
                                    onClick={() => handleModal(device.id)}
                                  />
                                </React.Fragment>
                              }
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </Table>
                  </div>
                  { unfinishedCount > 1 &&
                    <Pagination 
                      count={unfinishedCount} 
                      page={page.unfinished+1}
                      onChange={(e, pageNo)=>setPage({...page, unfinished: pageNo-1})}
                    />
                  }
                </React.Fragment>
                }
              </Card>
            }
            <Modal
              open={openModal}
              onClose={() => handleModal(null)}
              icon={<img className={styles.icon_64} src={alert} alt="alert" />}
              title="Are you sure?"
              content="Do you really want to delete this device? This process cannot be undone."
              actions={
                <React.Fragment>
                  <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
                  <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
                </React.Fragment>
              }
            />
          </React.Fragment>
        : <Empty 
            description={"Your page is empty. " + (canWrite ? "Add a device to get started!" : "")}
          />
        }
      </React.Fragment>
    );
  }
}
