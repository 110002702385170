import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
// @mui/material
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Modal from "shared-components/Modal/Modal";

import { setDirty, setSuccess } from "store/general";
import { UpdateLightingDeviceSetting } from "services/AdminPortal/DeviceService";

import DeviceDetail from "./DeviceDetail";
import styles from "assets/jss/components/UserPortal/climate.module.scss";

export default function DetailModal(props) {
  const dispatch = useDispatch();
  const [deviceIndex, setDeviceIndex] = React.useState(0);
  const [selectedDevice, setSelectedDevice] = React.useState({});

  const handleButtonClick_save = () => {
    dispatch(UpdateLightingDeviceSetting({id: selectedDevice.id, isFollowNormalLight: selectedDevice.isFollowNormalLight, isFollowReceipt: selectedDevice.isFollowReceipt}))
    .then(({error}) => {
      if (!error) {
        dispatch(setDirty(false));
        dispatch(setSuccess("Success! Changes will be reflected in a few minutes."));
      }
    });
  }

  const onClickArrow = (index) => {
    if (deviceIndex + index >= 0  && deviceIndex + index <= (props.selectedDevices.length - 1)) {
      setDeviceIndex(deviceIndex + index);
    }
  }

  React.useEffect(() => {
    setSelectedDevice(props.selectedDevices[deviceIndex]);
  },[props.selectedDevices, deviceIndex]);

  return (
    <React.Fragment>
      <Modal
        classes={{
          content: styles.detailModalContent
        }}
        className={styles.detailModal}
        open={props.openDetailModal}
        onClose={() => props.handleButtonClick_view()}
        title="Details"
        content={
          <React.Fragment>
            <Typography className={styles.modalTab}>Toggle left and right to view the selected devices details.</Typography>
            <DeviceDetail selectedFunction={props.selectedFunction} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} />
            {deviceIndex !== undefined &&
              <React.Fragment>
                <a className={styles.carouselButton} onClick={()=>onClickArrow(-1)}>&#10094;</a>
                <a className={clsx(styles.carouselButton, styles.next)} onClick={()=>onClickArrow(1)}>&#10095;</a>
              </React.Fragment>
            }
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => props.handleButtonClick_view()}>Close</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_save()}>Save</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
