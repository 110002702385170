import React from "react";
import { useDispatch } from "react-redux";
// @mui/material components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// @material-ui/icon
import AndroidIcon from '@mui/icons-material/Android';
// import AppleIcon from '@mui/icons-material/Apple';
// core components
import Card from "shared-components/Card/Card";

import apk from "apk/Greenphyto FOMS.apk";
import styles from "assets/jss/components/userSettings.module.scss";

import { reset } from ".";

export default function APK(props) {
  const dispatch = useDispatch();

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <Card 
      className={styles.container}
      classes={{
        root: styles.container,
        content: styles.content,
        cardActions: styles.actions
      }}
      title="APK"
      cardActions={
        <Button className={styles.buttonSecondary} onClick={()=>props.setPage(null)}>Back</Button>
      }
    >
      <div className={styles.download}>
        <a className={styles.downloadContainer} href={apk} download>
          <AndroidIcon className={styles.downloadIcon} />
          <div className={styles.textContainer}>
            <Typography className={styles.downloadText}>Download Android apk</Typography>
            <Typography className={styles.downloadText2}>Version: 171023</Typography>
          </div>
        </a>
        {/* <a className={classes.downloadContainer} href={apk} download>
          <AppleIcon className={classes.appleDownloadIcon} />
          <div className={classes.textContainer}>
            <Typography className={classes.downloadText}>Download iOS apk</Typography>
            <Typography className={classes.downloadText2}>Version: 210223</Typography>
          </div>
        </a> */}
      </div>
      <Typography className={styles.instruction}>Note: Uninstall previous version before installing new apk</Typography>
    </Card>
  );
}
