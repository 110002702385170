import { createSlice } from "@reduxjs/toolkit";

import { SearchSensorSetting, SearchDeviceControl } from "services/UserPortal/ClimateService";

// initial state
const initialState = {
  sensorTotalCount: 0,
  deviceTotalCount: 0,
  sensorResults: [],
  deviceResults: [],
};

const climateSearch = createSlice({
  name: "climateSearch",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SearchSensorSetting.fulfilled, (state, action) => {
        state.sensorTotalCount = action.payload.result.totalCount;
        state.sensorResults = action.payload.result.items;
      })
      .addCase(SearchDeviceControl.fulfilled, (state, action) => {
        state.deviceTotalCount = action.payload.result.totalCount;
        state.deviceResults = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, reset } = climateSearch.actions;

// export the reducer
export default climateSearch.reducer;