import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
// core components
import TextField from "shared-components/TextField/TextField";

import { renderValue } from "common/functions";
import { updateRequest } from "../store/phenotype";

import PlantMatrixImage from "./PlantMatrixImage";
import styles from "assets/jss/components/UserPortal/qualityControl.module.scss";

export default function PlantMatrix(props) {
  const dispatch = useDispatch();
  const totalHorizontal = useSelector(store => store.user.qualityControl.phenotype.totalHorizontal);
  const qualityControl = useSelector(store => store.user.qualityControl.phenotype.qualityControl);

  const handleOnChange_matrix = (e, key, x) => {
    let tempMatrix = qualityControl.plantMatrices ? _.cloneDeep(qualityControl.plantMatrices) : [];
    const foundIndex = tempMatrix.findIndex((item) => item.horizontal == x && item.vertical == 1);
    if (foundIndex != -1) {
      tempMatrix[foundIndex][key] = e.target.value;
    } else {
      tempMatrix.push({
        [key]: e.target.value,
        horizontal: x,
        vertical: 1,
        qualityControlEntryId: qualityControl.id
      })
    }
    dispatch(updateRequest({plantMatrices: tempMatrix}));
  };

  const renderColumn = () => {
    let component = [];
    for (let i = 1; i <= totalHorizontal; i++) {
      const matrixValue = qualityControl.plantMatrices && qualityControl.plantMatrices.find((item) => item.horizontal == i);
      component.push(
        <TextField 
          key={i}
          className={clsx(i !== totalHorizontal && styles.textFieldMargin, styles.textFieldSmall4, (matrixValue && matrixValue.isDisabled) && styles.coloredTextField)}
          id="plantMatrices"
          variant="outlined"
          disabled
        />
      )
    }
    return component;
  }

  const renderColumnImage = () => {
    let component = [];
    for (let i = 1; i <= totalHorizontal; i++) {
      const matrixValue = qualityControl.plantMatrices && qualityControl.plantMatrices.find((item) => item.horizontal == i );
      component.push(
        <PlantMatrixImage key={i} matrixValue={matrixValue} x={i} matrixImages={props.matrixImages} setMatrixImages={props.setMatrixImages} />
      )
    }
    return component;
  }

  const renderColumnFields = (key) => {
    let component = [];
    for (let i = 1; i <= totalHorizontal; i++) {
      const matrixValue = qualityControl.plantMatrices && qualityControl.plantMatrices.find((item) => item.horizontal == i );
      component.push(
        <TextField 
          key={i}
          className={clsx(i !== totalHorizontal && styles.textFieldMargin, styles.textFieldSmall4, (matrixValue && matrixValue.isDisabled) && styles.coloredTextField)}
          id="plantMatrices"
          variant="outlined" 
          type="Number"
          onChange={(e) => handleOnChange_matrix(e, key, i)}
          placeholder=""
          value={matrixValue && renderValue(matrixValue[key])}
        />
      )
    }
    return component;
  }
  
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {qualityControl.boardType
        ? <>
            <Grid item xs={2} className={styles.grid} />
            <Grid item xs={10} className={styles.grid}>
              <Paper className={styles.paper2} elevation={0}>
                {renderColumn()}
              </Paper>
            </Grid>
          </>
        : <Grid item xs={12} className={styles.grid}>
            <Typography className={styles.formLabel4}>Select a Board Type</Typography>
          </Grid>
        }
        <Grid item xs={2} className={styles.grid}>
          <Typography className={styles.formLabel}>Photos:</Typography>
        </Grid>
        <Grid item xs={10} className={styles.grid}>
          <div className={styles.padding}>
            {renderColumnImage()}
          </div>
        </Grid>
        <Grid item xs={2} className={styles.grid}>
          <Typography className={styles.formLabel}>Plant Weight (g):</Typography>
        </Grid>
        <Grid item xs={10} className={styles.grid}>
          <div className={styles.padding}>
            {renderColumnFields("plantWeight")}
          </div>
        </Grid>
        <Grid item xs={2} className={styles.grid}>
          <Typography className={styles.formLabel}>Plant Height (cm):</Typography>
        </Grid>
        <Grid item xs={10} className={styles.grid}>
          <div className={styles.padding}>
            {renderColumnFields("plantHeight")}
          </div>
        </Grid>
        <Grid item xs={2} className={styles.grid}>
          <Typography className={styles.formLabel}>Plant Width (cm):</Typography>
        </Grid>
        <Grid item xs={10} className={styles.grid}>
          <div className={styles.padding}>
            {renderColumnFields("plantWidth")}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
