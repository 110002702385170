import { createSlice } from "@reduxjs/toolkit";

import { GetAllLabourCost } from "services/UserPortal/HumanResourceService";

// initial state
const initialState = {
  filter: {},
  totalCost: 0,
  totalCount: 0,
  costs: [],
};

const labourCost = createSlice({
  name: "labourCost",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.newEntry[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllLabourCost.fulfilled, (state, action) => {
        state.totalCost = action.payload.result.totalCost;
        state.totalCount = action.payload.result.totalCount;
        state.costs = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = labourCost.actions;

// export the reducer
export default labourCost.reducer;