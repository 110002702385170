import { combineReducers } from "redux";
import login from "./login";
import signup from "./signup";
import resetPassword from "./resetPassword";

const credentialsReducer = combineReducers({
  login,
  signup,
  resetPassword
});

export default credentialsReducer;