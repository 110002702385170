import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllBank, GetDevicesTaggingBank, GetDevicesTaggingFloorPlan } from "services/AdminPortal/DeviceTaggingService";

// initial state
const initialState = {
  deviceList: [],
  devices: [],
  currentDevices: [],
  deletedDevices: [],
  banks: []
};

const devices = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.deviceList = action.payload;
    },
    updateRequest: (state, action) => {
      state.devices = action.payload;
    },
    updateCurrentRequest: (state, action) => {
      state.currentDevices = action.payload;
    },
    updateDeletedRequest: (state, action) => {
      state.deletedDevices = action.payload;
    },
    updateIndexRequest: (state, action) => {
      state.devices[action.payload.index] = Object.assign(state.devices[action.payload.index], action.payload.payload);
    },
    resetDevices: (state) => {
      state.devices = [];
      state.deletedDevices = [];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllBank.fulfilled, (state, action) => {
        state.banks = _.orderBy(action.payload.result, ["bankName"], ["asc"]);
      })
      .addCase(GetDevicesTaggingBank.fulfilled, (state, action) => {
        const result = action.payload.result.deviceList.map((device) => {
          return {
            ..._.omit(device, ["deviceInfo"]), 
            deviceRefNo: device.deviceInfo.deviceRefNo, 
            isTagged: device.deviceInfo.isTagged
          }
        });
        state.devices = _.unionBy(state.devices, result, "deviceId");
      })
      .addCase(GetDevicesTaggingFloorPlan.fulfilled, (state, action) => {
        const result = action.payload.result.deviceList.map((device) => {
          return {
            ..._.omit(device, ["deviceInfo"]), 
            deviceRefNo: device.deviceInfo.deviceRefNo, 
            isTagged: device.deviceInfo.isTagged
          }
        });
        state.devices = _.unionBy(state.devices, result, "deviceId");
      })
  },
});

// export actions
export const { setValues, updateRequest, updateCurrentRequest, updateDeletedRequest, updateIndexRequest, 
  resetDevices, reset } = devices.actions;

// export the reducer
export default devices.reducer;