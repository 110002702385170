import { combineReducers } from "redux";
import deviceControl from "./deviceControl";
import climateMonitoring from "./climateMonitoring";
import sensorControl from "./sensorControl";
import sensorSettings from "./sensorSettings";
import lookup from "./lookup";
import search from "./search";

const climateReducer = combineReducers({
  deviceControl,
  climateMonitoring,
  sensorControl,
  sensorSettings,
  lookup,
  search,
});

export default climateReducer;