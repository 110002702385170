import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";
import { rowsPerTable } from "config";

import {FomsMS} from "../index";

export const GetAllRawMaterial = createAsyncThunk(
  "rawMaterialUP/GetAllRawMaterial", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetAllRawMaterial", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllRawMaterialBatch = createAsyncThunk(
  "rawMaterialUP/GetAllRawMaterialBatch", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const params = {
      ...param,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetAllRawMaterialBatch", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRawMaterialBatch = createAsyncThunk(
  "RawMaterialUP/CreateOrUpdateRawMaterialBatch", 
  async (data, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...data,
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.post("/userportal/RawMaterialUP/CreateOrUpdateRawMaterialBatch", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)

export const GetAllRawMaterialReserveOrIssue = createAsyncThunk(
  "rawMaterialUP/GetAllRawMaterialReserveOrIssue", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetAllRawMaterialReserveOrIssue", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRawMaterialReserve = createAsyncThunk(
  "rawMaterialUP/CreateOrUpdateRawMaterialReserve", 
  async (value, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      ...value,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/RawMaterialUP/CreateOrUpdateRawMaterialReserve", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteRawMaterialReserve = createAsyncThunk(
  "RawMaterialUP/DeleteRawMaterialReserve", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/userportal/RawMaterialUP/DeleteRawMaterialReserve", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const BatchRawMaterialIssue = createAsyncThunk(
  "RawMaterialUP/BatchRawMaterialIssue", 
  async (_, {getState, rejectWithValue}) => {
    let body = {rawMaterialReserveIds: getState().user.rawMaterial.issueRM.issuedId};
    try {
      const response = await FomsMS.post("/userportal/RawMaterialUP/BatchRawMaterialIssue", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Request Receipt 
export const GetAllRawMaterialRequestOrReceived = createAsyncThunk(
  "rawMaterialUP/GetAllRawMaterialRequestOrReceived", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetAllRawMaterialRequestOrReceived", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const RawMaterialReceiptReceive = createAsyncThunk(
  "rawMaterialUP/RawMaterialReceiptReceive", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/RawMaterialUP/RawMaterialReceiptReceive", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateRawMaterialRequestReject = createAsyncThunk(
  "rawMaterialUP/UpdateRawMaterialRequestReject", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      rawMaterialRequestId: id,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/RawMaterialUP/UpdateRawMaterialRequestReject", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Cost
export const GetAllRawMaterialCost = createAsyncThunk(
  "rawMaterialUP/GetAllRawMaterialCost", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetAllRawMaterialCost", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRawMaterialRequest = createAsyncThunk(
  "rawMaterialUP/CreateOrUpdateRawMaterialRequest", 
  async (value, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      ...value,
     requestByUserId: userDetail.id,
     FarmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/RawMaterialUP/CreateOrUpdateRawMaterialRequest", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//return
export const GetAllRawMaterialReturn = createAsyncThunk(
  "rawMaterialUP/GetAllRawMaterialReturn", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetAllRawMaterialReturn", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateRawMaterialReturn = createAsyncThunk(
  "rawMaterialUP/UpdateRawMaterialReturn", 
  async (value, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      ...value,
      farmId: find(userDetail.farms, "isActive").id
    };
    try {
      const response = await FomsMS.post("/userportal/RawMaterialUP/UpdateRawMaterialReturn", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Reconciliation
export const GetAllRawMaterialReconciliation = createAsyncThunk(
  "rawMaterialUP/GetAllRawMaterialReconciliation", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetAllRawMaterialReconcilliation", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateRawMaterialReconciliation = createAsyncThunk(
  "rawMaterialUP/CreateOrUpdateRawMaterialReconciliation", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/RawMaterialUP/CreateOrUpdateRawMaterialReconcilliation", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//filter
export const GetAllRawMaterialBatchInfo = createAsyncThunk(
  "rawMaterialUP/GetAllRawMaterialBatchInfo", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetAllRawMaterialBatchInfo", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetRawMaterialStatusList = createAsyncThunk(
  "rawMaterialUP/GetRawMaterialStatusList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetRawMaterialStatusList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetReceiptDetail = createAsyncThunk(
  "rawMaterialUP/GetReceiptDetail", 
  async (value, {rejectWithValue}) => {
    const params = {
      rawMaterialRequestId: value,
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/GetReceiptDetail", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWarehouseRackByWarehouseId = createAsyncThunk(
	"rawMaterialUP/GetWarehouseRackByWarehouseId", 
	async (param, {rejectWithValue}) => {
    const params = {
      warehouseId: param.warehouseId,
      rackID: param.rackID,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    }
		try {
			const response = await FomsMS.get("/userPortal/RawMaterialUP/GetWarehouseRackByWarehouseId", {params, stopLoading: true});
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

// Search
export const SearchRawMaterial = createAsyncThunk(
  "rawMaterialUP/searchRawMaterial", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/SearchRawMaterial", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchRawMaterialBatch = createAsyncThunk(
  "rawMaterialUP/searchRawMaterialBatch", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/SearchRawMaterialBatch", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchRawMaterialReservedOrIssued = createAsyncThunk(
  "rawMaterialUP/searchRawMaterialReservedOrIssued", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/SearchRawMaterialReservedOrIssued", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchRawMaterialRequestOrReceived = createAsyncThunk(
  "rawMaterialUP/searchRawMaterialRequestOrReceived", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/SearchRawMaterialRequestOrReceived", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchRawMaterialCost = createAsyncThunk(
  "rawMaterialUP/searchRawMaterialCost", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/SearchRawMaterialCost", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchRawMaterialReturn = createAsyncThunk(
  "rawMaterialUP/searchRawMaterialReturn", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/SearchRawMaterialReturn", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SearchRawMaterialReconciliation = createAsyncThunk(
  "rawMaterialUP/searchRawMaterialReconciliation", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/RawMaterialUP/SearchRawMaterialReconciliation", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);