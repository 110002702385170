import React from "react";
import clsx from "clsx";
// @mui/material
import Typography from "@mui/material/Typography";
// @mui/icons-material
import errorImg from "assets/img/error-img.svg";

import styles from "assets/jss/error-page/error.module.scss";

export default function Error() {

  return (
    <div className={clsx("h-screen", styles.container)}>
      <div>
        <img className={styles.errorImg} src={errorImg} alt="greenphyto-error" />
        <Typography className={styles.description}>Sorry! The page you’re looking for doesn’t exist.</Typography>
      </div>
    </div>
  );
}
