// Device Search
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";

import Hierarchy from "./Hierarchy";
import Location from "./Location";
import { formatNumbers, filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { reset } from "./store/search";
import { setSearchText } from "store/general";
import { deviceTableHead, searchDeviceTypeTableHead, searchDeviceFunctionTableHead } from "enums/AdminPortal/TableHeaders";
import { SearchDevice, DeleteDevice, DeleteDeviceType, GetFunctionLibrary, CreateOrUpdateFunctionLibrary } from "services/AdminPortal/DeviceService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/device.module.scss";

import { useRouteCanWrite } from "hooks";

export default function SearchDeviceResult() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const searchResultsCount = useSelector(store => store.admin.device.search.searchResultsCount);
  const searchResults = useSelector(store => store.admin.device.search.searchResults);
  const [order, setOrder] = React.useState({device: 'asc', type: 'asc', function: 'asc'});
  const [orderBy, setOrderBy] = React.useState({device: 'deviceRefNo', type: 'deviceTypeName', function: 'deviceFunctionName'});
  const [page, setPage] = React.useState({device: 0, type: 0, function: 0});
  const [id, setId] = React.useState(null);
  const [table, setTable] = React.useState(null);
  const [locationDetail, setLocationDetail] = React.useState(false);
  const [hierarchyDetail, setHierarchyDetail] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState({device: false, type: false, function: false});

  const deviceCount = Math.ceil(searchResultsCount.deviceList / rowsPerTable);
  const typeCount = Math.ceil(searchResultsCount.deviceTypeList / rowsPerTable);
  const functionCount = Math.ceil(searchResultsCount.deviceFunctionList / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleRequestSortDevice = (e, property) => {
    const isAsc = orderBy.device === property && order.device === 'asc';
    setOrder({...order, device: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, device: property});
  };
  
  const handleRequestSortType = (e, property) => {
    const isAsc = orderBy.type === property && order.type === 'asc';
    setOrder({...order, type: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, type: property});
  };
  
  const handleRequestSortFunction = (e, property) => {
    const isAsc = orderBy.function === property && order.function === 'asc';
    setOrder({...order, function: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, function: property});
  };

  const handleButtonClick_hierarchy = (val) => {
    setId(val);
    setHierarchyDetail(true);
  }

  const handleButtonClick_location = (val, currentLocation) => {
    setId(val);
    setLocationDetail(currentLocation);
  }

  const handleModal = (tableVal, val) => {
    setId(val);
    setTable(tableVal);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    if (table === "device") {
      dispatch(DeleteDevice(id))
      .then(() =>{
        dispatch(SearchDevice(params.keyword));
        resetState();
      })
    } else if (table === "type") {
      dispatch(DeleteDeviceType(id))
      .then(() =>{
        dispatch(SearchDevice(params.keyword));
        resetState();
      })
    } else {
      dispatch(GetFunctionLibrary(table))
      .then((response) => {
        let body = _.cloneDeep(response.payload.result);
        body.deviceFunctions = _.map(body.deviceFunctions, (func) => {
          if (func.id === id) {
            return {...func, isDeleted: true}
          } else {
            return func
          }
        });
        dispatch(CreateOrUpdateFunctionLibrary(body))
        .then(() => {
          dispatch(SearchDevice(params.keyword));
          resetState();
        });
      })
    }
  }

  const resetState = () => {
    setId(null);
    setPage({device: 0, type: 0, function: 0});
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    dispatch(reset());
    dispatch(SearchDevice(params.keyword));
  },[params.keyword]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value) {
      return (
        <Highlighter
          highlightClassName={styles.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  if (locationDetail) {
    return <Location deviceId={id} locationDetail={locationDetail} setLocationDetail={setLocationDetail} />
  } else if (hierarchyDetail) {
    return <Hierarchy deviceId={id} hierarchyDetail={hierarchyDetail} setHierarchyDetail={setHierarchyDetail} devices={searchResults.deviceList} />
  } else {
    const totalCount = _.isEmpty(searchResultsCount) ? 0 : Object.values(searchResultsCount).reduce((a, b) => a + b);
    const totalTableCount = Object.keys(searchResults).length;
    return (
      <React.Fragment>
        <Typography className={styles.result}>{formatNumbers(totalCount) + " Search Result(s) From " + totalTableCount + " Table:  ‘" + params.keyword + "’"}</Typography>
        { searchResultsCount.deviceList > 0 &&
          <Card 
            classes={{
              root: styles.result,
            }}
            title = {
              <React.Fragment>
                {searchResultsCount.deviceList + " Result(s) From Device List"}
                <IconButton 
                  className={collapsed.device ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed({...collapsed, device: !collapsed.device})}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed.device && 
              <React.Fragment>
                <div className={styles.table}>
                  <Table
                    header={filterActionTableHead(deviceTableHead, canWrite)}
                    order={order.device}
                    orderBy={orderBy.device}
                    onRequestSort={handleRequestSortDevice}
                  >
                    { _.orderBy(searchResults.deviceList, [orderBy.device], [order.device])
                    .slice(page.device * rowsPerTable, page.device * rowsPerTable + rowsPerTable)
                    .map((device,index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{highlightValue(device.deviceRefNo)}</TableCell>
                          <TableCell>{highlightValue(device.deviceName)}</TableCell>
                          <TableCell>{highlightValue(device.manufacturerID)}</TableCell>
                          <TableCell>{highlightValue(device.manufacturerName)}</TableCell>
                          <TableCell>{highlightValue(device.manufacturerPhone)}</TableCell>
                          <TableCell>{highlightValue(device.manufacturerEmail)}</TableCell>
                          <TableCell>{highlightValue(device.testing)}</TableCell>
                          <TableCell>
                            <span className={styles.link} onClick={() => handleButtonClick_hierarchy(device.id)}>
                              {device.mappingHierarchy}
                            </span>
                          </TableCell>
                          <TableCell>
                            {device.deviceLocation
                            ? <span className={styles.link} onClick={() => handleButtonClick_location(device.id, device.deviceLocation)}>
                                Details
                              </span>
                            : canWrite 
                              ? <Link className={styles.link} to={{pathname: "/admin/device-tagging/edit", state: {step: 3, deviceRefNo: device.deviceRefNo, deviceId: device.id}}}>
                                  Add Info
                                </Link>
                              : null
                            }
                          </TableCell>
                          <TableCell><Typography className={styles.ellipsis}>{highlightValue(device.deviceDesc)}</Typography></TableCell>
                          <TableCell align="right">
                            {canWrite &&
                              <React.Fragment>
                                <Link to={{pathname: "/admin/device/"+device.id, state: {prevPath: location.pathname+location.search}}}>
                                  <CustomIconButton type="edit" />
                                </Link>
                                <CustomIconButton 
                                  type="delete"
                                  onClick={() => handleModal("device", device.id)}
                                />
                              </React.Fragment>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </div>
                { deviceCount > 1 &&
                  <Pagination 
                    count={deviceCount} 
                    page={page.device+1}
                    onChange={(e, pageNo)=>setPage({...page, device: pageNo-1})}
                  />
                }
              </React.Fragment>
            }
          </Card>
        }
        { searchResultsCount.deviceTypeList > 0 &&
          <Card 
            classes={{
              root: styles.result,
            }}
            title = {
              <React.Fragment>
                {searchResultsCount.deviceTypeList + " Result(s) From Device Type"}
                <IconButton 
                  className={collapsed.type ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed({...collapsed, type: !collapsed.type})}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed.type && 
            <React.Fragment>
              <div className={styles.table}>
                <Table
                  header={filterActionTableHead(searchDeviceTypeTableHead, canWrite)}
                  order={order.type}
                  orderBy={orderBy.type}
                  onRequestSort={handleRequestSortType}
                >
                  { _.orderBy(searchResults.deviceTypeList, [orderBy.type], [order.type])
                  .slice(page.type * rowsPerTable, page.type * rowsPerTable + rowsPerTable)
                  .map((type,index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{highlightValue(type.deviceTypeName+" ("+type.deviceShortForm+")")}</TableCell>
                        <TableCell>
                          {highlightValue(type.functionLibrary && type.functionLibrary.deviceFunctions.map((func) => {
                            return func.deviceFunctionName + " (" + func.shortDeviceFunctionName + ")"
                          }).join(", "))}
                        </TableCell>
                        <TableCell align="right">
                          {canWrite &&
                            <React.Fragment>
                              <Link to={{pathname: "/admin/device/type/"+type.id, state: {prevPath: location.pathname+location.search}}}>
                                <CustomIconButton type="edit" />
                              </Link>
                              <CustomIconButton 
                                type="delete"
                                onClick={() => handleModal("type", type.id)}
                              />
                            </React.Fragment>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
              </div>
              { typeCount > 1 &&
                <Pagination 
                  count={typeCount} 
                  page={page.type+1}
                  onChange={(e, pageNo)=>setPage({...page, type: pageNo-1})}
                />
              }
            </React.Fragment>
            }
          </Card>
        }
        { searchResultsCount.deviceFunctionList > 0 &&
          <Card 
            title = {
              <React.Fragment>
                {searchResultsCount.deviceFunctionList + " Result(s) From Device Functions"}
                <IconButton 
                  className={collapsed.function ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed({...collapsed, function: !collapsed.function})}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed.function && 
            <React.Fragment>
              <div className={styles.table}>
                <Table
                  header={filterActionTableHead(searchDeviceFunctionTableHead, canWrite)}
                  order={order.function}
                  orderBy={orderBy.function}
                  onRequestSort={handleRequestSortFunction}
                >
                  { _.orderBy(searchResults.deviceFunctionList, [orderBy.function], [order.function])
                  .slice(page.function * rowsPerTable, page.function * rowsPerTable + rowsPerTable)
                  .map((func,index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{highlightValue(func.deviceFunctionName)}</TableCell>
                        <TableCell>{highlightValue(func.shortDeviceFunctionName)}</TableCell>
                        <TableCell>{highlightValue(func.functionLibraryName)}</TableCell>
                        <TableCell align="right">
                          {canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/device/function/"+func.id, state: {libraryId: func.functionLibraryId, prevPath: location.pathname+location.search}}}>
                              <CustomIconButton type="edit" />
                            </Link>
                            {!func.isCombine && 
                              <CustomIconButton 
                                type="delete"
                                onClick={() => handleModal(func.functionLibraryId, func.id)}
                              />
                            }
                          </React.Fragment>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
              </div>
              { functionCount > 1 &&
                <Pagination 
                  count={functionCount} 
                  page={page.function+1}
                  onChange={(e, pageNo)=>setPage({...page, function: pageNo-1})}
                />
              }
            </React.Fragment>
            }
          </Card>
        }
        <Modal
          open={openModal}
          onClose={() => handleModal(null)}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this item? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
