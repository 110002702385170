// Create Edit Devices
// Step 1: Device Hierarchy
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Popper from "shared-components/Popper/Popper";
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import CustomIconButton from "shared-components/Button/IconButton";

import DeviceStepper from "./DeviceStepper";
import { renderValue } from "common/functions"
import { setDirty } from "store/general";
import { updateRequest, deleteRequest } from "./store/device";
import { GetDeviceListForHierarchy, CreateOrUpdateDevice } from "services/AdminPortal/DeviceService";

import helper from "assets/icons/black/helper.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/device.module.scss";

export default function HierarchyForm(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const device = useSelector(store => store.admin.device.device.device);
  const hierarchy = useSelector(store => store.admin.device.device.hierarchy);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [deviceIndex, setDeviceIndex] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_select = (e, index) => {
    let payload = device.deviceChildIds.slice();
    payload[index] = e.target.value;
    const key = e.target.name.replace(/\d/g, '');
    dispatch(updateRequest({[key] : payload}));
  };

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleModal_delete = (index) => {
    setDeviceIndex(index);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(deleteRequest({key: "deviceChildIds", index: deviceIndex}));
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_add = () => {
    let payload = [...device.deviceChildIds, null];
    dispatch(updateRequest({deviceChildIds: payload}));
  }

  const handleButtonClick_skip = (notParent) => {
    if (notParent){
      dispatch(updateRequest({deviceParentId: 0, deviceChildIds: [], isDraft: false}));
    } else {
      dispatch(updateRequest({deviceChildIds: [], isDraft: true}));
    }
    setOpenWarningModal(false);
    handleButtonClick_next();
  }

  const handleButtonClick_next = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateDevice())
        .then((response) => {
          if (response.error) {
            dispatch(setDirty(true));
          } else {
            history.push("/admin/device");
          }
        });
      })
    }
  };

  const validateFields = () => {
    for (let i = 0; i < device.deviceChildIds.length; i++) {
      if (!device.deviceChildIds[i]) {
        setErrorMsg({field: "deviceChildIds"+i, msg: "Please select a child device"});
        return false;
      }
    }
    setErrorMsg(false);
    return true;
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetDeviceListForHierarchy());
  },[]);

  let childDeviceList = hierarchy;
  if (device.childDevices) {
    childDeviceList = [...childDeviceList, ...device.childDevices];
  }
  const updatedDeviceList = _.orderBy(childDeviceList.filter((item) => (!device.deviceChildIds.includes(item.id) && item.id!==device.deviceParentId && item.id!==device.id)), "deviceRefNo", "asc");
  return (
    <React.Fragment>
      <Card 
        title="Step 4: Mapping of Device Hierarchy" 
        subheader={<DeviceStepper activeStep={props.step} />}
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>props.setStep(2)}
            >
              Go Back
            </Button>
            <div>
              <Button
                className={clsx(styles.buttonSecondary, styles.buttonMargin)}
                onClick={()=>handleModal_warning()}
              >
                Save Draft
              </Button>
              { device.deviceChildIds.length
              ? <Button
                  className={styles.button}
                  onClick={()=>handleButtonClick_next()}
                >
                  Complete Step
                </Button>
              : <Button
                  className={styles.button}
                  onClick={()=>handleButtonClick_skip(true)}
                  disabled={device.deviceChildIds.length ? true : false}
                >
                  Skip if not Parent Device
                </Button>
              }
            </div>
          </React.Fragment>
        }
      >
        <div className={styles.content}>
          <div className={styles.subContent}>
            <Card 
              classes={{
                root: styles.cardPaper,
                title: styles.cardTitle
              }}
              title= {
                <React.Fragment>
                  Parent Device
                  <Popper 
                    open={openPopper}
                    className={styles.popper}
                    closePopper={() => setOpenPopper(false)}
                    buttonClick={() => setOpenPopper(false)}
                    placement="right"
                    title="Parent and Child Device"
                    content="A parent device has 1 or many devices (child devices) linked to it while a child device is linked to another device (parent device)"
                  >
                    <IconButton 
                      className={styles.iconButton_helper}
                      onClick={() => setOpenPopper(true)}
                    >
                      <img className={styles.helperIcon} src={helper} alt="helper" />
                    </IconButton>
                  </Popper>
                </React.Fragment>
              }
            >
              <TextField 
                label="Parent Device ID"
                id="deviceParentId"
                variant="outlined" 
                value={!device.parentDevice || device.deviceParentId === device.id ? device.deviceRefNo : device.parentDevice.deviceRefNo}
                disabled
              />
            </Card>
          </div>
          <div className={styles.subContent}>
            <Card 
              classes={{
                root: styles.cardPaper,
                title: styles.cardTitle
              }}
              title="Child Device"
            >
              { device.deviceChildIds && device.deviceChildIds.map((deviceChildId, index) => {
                const selected = childDeviceList.find((childDevice) => childDevice.id === deviceChildId);
                return (
                  <React.Fragment key={index}>
                    <div className={styles.hierarchyField}>
                      <div className={styles.leftField}>
                        <Select
                          label={"Child Device " + (index+1) + " ID"}
                          name={"deviceChildIds" + index}
                          onChange={(e)=>handleOnChange_select(e, index)}
                          value={renderValue(deviceChildId)}
                          placeholder="Please select a child device"
                          errorMsg={errorMsg}
                        >
                          {selected && <MenuItem value={selected.id}>{selected.deviceRefNo}</MenuItem>}
                          {updatedDeviceList.map((item) => {
                            return <MenuItem key={item.id} value={item.id}>{item.deviceRefNo}</MenuItem>;
                          })} 
                        </Select>
                      </div>
                      <CustomIconButton
                        type="delete" 
                        className={clsx(styles.iconButton, styles.deleteButton)}
                        onClick={() => handleModal_delete(index)}
                      />
                    </div>
                  </React.Fragment>
                )
              })}
              <Button
                className={clsx(styles.buttonSecondary, styles.addButton)}
                startIcon={<AddIcon />}
                onClick={() => handleButtonClick_add()}
                disabled={!updatedDeviceList.length}
              >
                Add More Devices
              </Button>
            </Card>
          </div>
        </div>
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this device? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning()}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you want to save the details first? You will be directed to the unfinished device list."
        actions={
          <React.Fragment>
          <Button className={styles.buttonSecondary} onClick={() => handleModal_warning()}>Cancel</Button>
          <Button className={styles.button} onClick={() => handleButtonClick_skip()}>Confirm</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
