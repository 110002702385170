import React, { useEffect, createRef }  from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Popper from "shared-components/Popper/Popper";
// core components
import Card from "shared-components/Card/Card";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { GetAllHRAvailability } from "services/UserPortal/HumanResourceService";

import styles from "assets/jss/components/UserPortal/hr.module.scss";

const LeftStickyTableCell = withStyles({
  root: {
    borderBottom: "none",
    backgroundColor: "white",
    zIndex: 2
  },
  body: {
    left: 0,
    position: "sticky"
  }
})(TableCell);

export default function OverallAvailability() {
  const dispatch = useDispatch();
  const availability = useSelector(store => store.user.hr.availability.availability);
  const [sortBy] = React.useState("Accumulative Hours");
  const [date, setDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [elRefs, setElRefs] = React.useState([]);
  const [height, setHeight] = React.useState([]);
  const [openPopper, setOpenPopper] = React.useState(false);

  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date" && moment(value).isAfter('2000-01-01', 'year')) {
      setDate(moment(value).format("YYYY-MM-DD"));
    } else {
      setDate(value);
    }
  };

  const getOverallAvailability = (stopLoading) => {
    dispatch(GetAllHRAvailability({sortBy, date, stopLoading}));
  }

  React.useEffect(() => {
    getOverallAvailability();
  },[sortBy, date]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  useInterval(() => {
    getOverallAvailability(true);
  });

  const employeeWO = (logSheetList, index) => {
    let component = [];
    let color=0;
    let prevWO = "";
    let prevProd = "wo.productRefNo";
    for (let i = 0; i < logSheetList.length; i++) {
      const wo = logSheetList[i];
      if (!(prevWO === wo.workOrderNumber && prevProd === wo.productRefNo)) {
        prevWO = wo.workOrderNumber;
        prevProd = wo.productRefNo;
        if (color > 4) {
          color = 1;
        } else {
          color += 1;
        }
      }
      component.push(
        <TableCell 
          className={clsx(styles.tableCell)}
          style={{height: height[index] && height[index]+30}}
        >
          <div 
            className={clsx(styles.chip, styles.woChip, wo.timeSpent < 0.5 && styles.emptyDesc, styles["task"+color])} 
            // style={{width: ((wo.timeSpent*2)*(5.729))+"vw"}}
            onMouseOver={()=> (wo.timeSpent >= 0.5 && wo.workOrderNumber && wo.productRefNo) || wo.timeSpent < 0.5 ? setOpenPopper(wo.id) : null} 
            onMouseOut={()=>setOpenPopper(false)}
          >
            <Popper
              key={i} 
              noButton
              open={openPopper === wo.id}
              className={styles.popper}
              closePopper={() => setOpenPopper(false)}
              placement="top-start"
              content={
                <span className={styles.popperContent}>
                  {wo.workOrderNumber && <span>{wo.workOrderNumber},<br/></span>}
                  {wo.productRefNo && <span>{wo.productRefNo}</span>}
                  {/* {wo.timeSpent < 0.5 &&
                  <React.Fragment>
                    {wo.productProcessName && <span>{wo.productProcessName},<br/></span>}
                    {wo.taskNumber != 0 && <span>Task {wo.taskNumber},<br/></span>}
                    {wo.timeSpent && <span>{wo.timeSpent} hours</span>}
                  </React.Fragment>
                  } */}
                </span>
              }
            >
            <div>
                {wo.productProcessName && <span>{wo.productProcessName},<br/></span>}
                {wo.taskNumber != 0 && <span>Task {wo.taskNumber}{wo.timeSpent ? "," : null}<br/></span>}
                {wo.timeSpent ? <span>{wo.timeSpent} hours</span> : null}
              </div>
            </Popper> 
          </div> 
        </TableCell>
      )
    }
    return component;
  }

  useEffect(() => {
    setHeight(elRefs.map(({current}) => {
      if (current) {
        return current.clientHeight;
      }
    }))
  }, [elRefs])

  useEffect(() => {
    setElRefs(Array.from({ length: availability.length }).map(() => createRef()));
  }, [availability]);

  return (
    <React.Fragment>
      <Card 
        classes={{
          content: styles.cardContent
        }}
        title = "HR Availability"
        action={
          <React.Fragment>
            <Typography className={styles.label}>Date:</Typography>
            <DateTimePicker
              closeOnSelect
              className={styles.cardActionDropdown}
              value={date}
              onChange={(e) => handleOnChange_date(e)}
            />
          </React.Fragment>
        }
      >
        <Typography className={styles.overallTitle}>Tasks</Typography>
        <div className={styles.timeline}>
          <Table className={styles.timelineTable}>
            <TableBody>
              { availability.map((item, index) => {
                if (item.logSheetList.length) {
                  return (
                    <TableRow key={index} className={styles.colWidth}>
                      <LeftStickyTableCell className={styles.tableLabel}>
                        <div className={styles.stickyBackground} />
                        {item.employeeName}<br/>{item.employeeRefNo && "("+item.employeeRefNo+")"}
                      </LeftStickyTableCell>
                      {employeeWO(item.logSheetList, index)}
                      {/* <TableCell 
                        className={clsx(styles.tableCell)}
                        style={{height: height[index] && height[index]+30}}
                      >
                        <div className={styles.woContainer} ref={elRefs[index]}>{employeeWO(item.logSheetList)}</div>
                      </TableCell> */}
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </div>
      </Card>
    </React.Fragment>
  );
}
