import { createSlice } from "@reduxjs/toolkit";

import { GetASRSCageBatchSetting } from "services/UserPortal/ASRSService";

// initial state
const initialState = {
  setting: {},
};

const settingsASRS = createSlice({
  name: "settingsASRS",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.setting[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetASRSCageBatchSetting.fulfilled, (state, action) => {
        state.setting = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = settingsASRS.actions;

// export the reducer
export default settingsASRS.reducer;