import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { GetDashboard } from "services/UserPortal/SmartMeteringService";

import cost from "assets/icons/black/energy-cost.svg";
import watt from "assets/icons/black/energy-watt.svg";
import efficiency from "assets/icons/black/energy-efficiency.svg";
import styles from "assets/jss/components/UserPortal/smartMetering.module.scss";

export default function Dashboard() {
  const dispatch = useDispatch();
  const result = useSelector(store => store.user.smartMetering.dashboard.result);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetDashboard());
  },[]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={4} className={styles.dashboardContainer}>
          <Paper elevation={0} className={styles.iconPaper}>
            <img src={cost} className={styles.dashboardIcon} />
          </Paper>
          <div>
            <Typography className={styles.label}>COST</Typography>
            <Paper className={styles.paper} elevation={0}>
              <Typography>Today: <span className={styles.orange}>${result.todayCost}</span></Typography>
              <Typography>Yesterday: <span className={styles.orange}>${result.yesterdayCost}</span></Typography>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={4} className={styles.dashboardContainer}>
          <Paper elevation={0} className={styles.iconPaper}>
            <img src={watt} className={styles.dashboardIcon} />
          </Paper>
          <div>
            <Typography className={styles.label}>WATT PER HOUR</Typography>
            <Paper className={styles.paper} elevation={0}>
              <Typography>Today: <span className={styles.orange}>{result.todayWattPerHour}kWh</span></Typography>
              <Typography>Yesterday: <span className={styles.orange}>{result.yesterdayWattPerHour}kWh</span></Typography>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} className={styles.dashboardContainer}>
          <Paper elevation={0} className={styles.iconPaper}>
            <img src={efficiency} className={styles.dashboardIcon} />
          </Paper>
          <div>
            <Typography className={styles.label}>SYSTEM EFFICIENCY</Typography>
            <Paper className={styles.paper} elevation={0}>
              <Typography className={styles.orange}>0.8kW/RT</Typography>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
