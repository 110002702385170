import { createSlice } from "@reduxjs/toolkit";

import { GetAllCustomer, GetCustomer, GetProposedCustomerRefNo, SearchCustomer } from "services/AdminPortal/CustomerService";

// initial state
const initialState = {
  totalCount: 0,
  customers: [],
  customer: {},
  creditTerms: [],
  searchResultsCount: 0,
  searchResults: [],
};

const customer = createSlice({
  name: "customer",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.customer[key] = action.payload[key];
      })
    },
    resetCustomer: (state) => {
      state.customer = {};
    },
    resetSearch: (state) => {
      state.searchResultsCount = 0;
      state.searchResults = [];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllCustomer.fulfilled, (state, action) => {
        state.totalCount = action.payload.result.totalCount;
        state.customers = action.payload.result.items;
      })
      .addCase(GetCustomer.fulfilled, (state, action) => {
        state.customer = action.payload.result;
      })
      .addCase(GetProposedCustomerRefNo.fulfilled, (state, action) => {
        state.customer.customerRefNo = action.payload.result;
      })
      .addCase(SearchCustomer.fulfilled, (state, action) => {
        state.searchResultsCount = action.payload.result.totalCount;
        state.searchResults = action.payload.result.items;
      })
  },
});

// export actions
export const { updateRequest, resetCustomer, resetSearch, reset } = customer.actions;

// export the reducer
export default customer.reducer;