import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import FilterButton from "shared-components/Button/FilterButton";

import { minDate, rowsPerTable } from "config";
import { setSearchText } from "store/general";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { rmReceiptTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/receipt";
import { GetAllRawMaterialRequestOrReceived, GetRawMaterialStatusList } from "services/UserPortal/RawMaterialService";

import styles from "assets/jss/components/UserPortal/rawMaterial.module.scss";

export default function Receipt() {
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.rawMaterial.receiptRM.filter);
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfo);
  const statusList = useSelector(store => store.user.rawMaterial.receiptRM.statusList.requestStatuses);
  const totalCount = useSelector(store => store.user.rawMaterial.receiptRM.totalCount);
  const receipts = useSelector(store => store.user.rawMaterial.receiptRM.receiptList);
  const [newFilter, setNewFilter] = React.useState({rawMaterialName: null, status: null, estDeliveryStartDate: null, estDeliveryEndDate: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('rawMaterialName');

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, estDeliveryStartDate: moment(value.startDate).format("YYYY-MM-DD"), estDeliveryEndDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_deleteTag = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const getReceivedList = (stopLoading) => {
    dispatch(GetAllRawMaterialRequestOrReceived({...filter,  stopLoading}));
  }
  
  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getReceivedList(0);
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetRawMaterialStatusList());
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getReceivedList(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && !key.includes("Date"));
  return (
    <React.Fragment>
      <Card 
        title="Request/Receipt List"
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "rawMaterialName") {
                const rm = rawMaterialList.find(({rawMaterialName}) => rawMaterialName == filter[key]);
                label = rm ? rm.rawMaterialName+" ("+rm.rawMaterialRefNo+")" : "";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={styles.tag}
                  onDelete={() => handleButtonClick_deleteTag(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the receipt list
                  <Select
                    className={styles.filterDropdown}
                    name="rawMaterialName"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a raw material"
                    value={renderValue(newFilter.rawMaterialName)}
                  >
                    {rawMaterialList && rawMaterialList.map((item, index) => {
                      return <MenuItem key={index} value={item.rawMaterialName}>{item.rawMaterialName} ({item.rawMaterialRefNo})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={styles.filterDropdown}
                    name="status"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a raw material status"
                    value={renderValue(newFilter.status)}
                  >
                    {statusList && statusList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Delivery date"
              className={styles.cardActionDate}
              value={{startDate: filter.estDeliveryStartDate, endDate: filter.estDeliveryEndDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, estDeliveryStartDate: null, estDeliveryEndDate: null, page: 0}}))}
            />
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            className={styles.mainTable}
            header={rmReceiptTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { receipts.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.rawMaterialName}</TableCell>
                  <TableCell>{item.rawMaterialRefNo}</TableCell>
                  <TableCell>{item.workOrderNumber}</TableCell>
                  <TableCell>{item.po}</TableCell>
                  <TableCell>{moment(item.requestDate).isAfter(minDate) ? moment(item.requestDate).format("DD/MM/yyyy HH:mm") : ""}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.qtyRequest))}</TableCell>
                  <TableCell>{moment(item.estDeliveryDate).isAfter(minDate) ? moment(item.estDeliveryDate).format("DD/MM/yyyy") : ""}</TableCell>
                  <TableCell>{item.uom}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.requestByUserName}</TableCell>
                  <TableCell>
                    <Link className={styles.link} to={{pathname: "/user/raw-material/receipt/"+item.id, state: {selectedReceipt: item}}}>
                      {item.status === "Requested" ? "To Receive" : "Receipt Details"}
                    </Link>
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
