import { find, unionBy, map, omit} from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {FomsMS} from "../index";

// Device Tagging
export const GetAllDevicesTagging = createAsyncThunk(
  "deviceTagging/getAllDevicesTagging", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/DeviceTagging/GetAllDevicesTagging", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Step 1
export const GetAllFloorPlan = createAsyncThunk(
  "deviceTagging/getAllFloorPlan", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/DeviceTagging/GetAllFloorPlan", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const CreateFloorPlans = createAsyncThunk(
  "deviceTagging/createFloorPlans", 
  async (_, {getState, rejectWithValue}) => {
    const allFloors = getState().admin.deviceTagging.floorPlan.floors;
    const floors = allFloors.filter((item) => item.id === undefined);
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      farmId: find(userDetail.farms, "isActive").id,
      floors
    }
    try {
      const response = await FomsMS.post("/DeviceTagging/CreateFloorPlans", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const UpdateFloorPlans = createAsyncThunk(
  "deviceTagging/updateFloorPlans", 
  async (_, {getState, rejectWithValue}) => {
    const allFloors = getState().admin.deviceTagging.floorPlan.floors;
    const floors = allFloors.filter((item) => item.id !== undefined);
    const body = floors;
    try {
      const response = await FomsMS.post("/DeviceTagging/UpdateFloorPlans", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const DeleteFloorPlan = createAsyncThunk(
  "deviceTagging/deleteFloorPlan", 
  async (id, {rejectWithValue}) => {
    const params = {Id: id};
    try {
      const response = await FomsMS.delete("/DeviceTagging/DeleteFloorPlan", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Step 2
export const UpdateFloorPlanDiagram = createAsyncThunk(
  "deviceTagging/updateFloorPlanDiagram", 
  async (_, {getState, rejectWithValue}) => {
    const floorPlan = getState().admin.deviceTagging.floorPlan.floorPlan;
    const body = {
      id: floorPlan.id,
      floorPlanDiagramUrl: floorPlan.floorPlanDiagramUrl,
      noFloorPlanRequire: floorPlan.noFloorPlanRequire
    }
    try {
      const response = await FomsMS.post("/DeviceTagging/UpdateFloorPlanDiagram", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Step 3
export const GetAllBank = createAsyncThunk(
  "deviceTagging/getAllBank", 
  async (value, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      sectorId: value.sectorId,
      IsAssignFloorPlan: value.IsAssignFloorPlan,
      isGetAllBank: value.isGetAllBank
    }
    try {
      const response = await FomsMS.get("/DeviceTagging/GetAllBank", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const UpdateFloorPlanBank = createAsyncThunk(
  "deviceTagging/updateFloorPlanBank", 
  async (sectorId, {getState, rejectWithValue}) => {
    const banks = unionBy(getState().admin.deviceTagging.bank.banks, getState().admin.deviceTagging.bank.deletedBanks);
    const body = {
      sectorId,
      banks
    }
    try {
      const response = await FomsMS.post("/DeviceTagging/UpdateFloorPlanBank", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

// Step 4
export const GetAllDeviceSearchList = createAsyncThunk(
  "deviceTagging/getAllDeviceSearchList", 
  async (value, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      FloorId: value.FloorId,
      SectorId: value.SectorId,
      BankId: value.BankId,
      DeviceRefNo: value.DeviceRefNo,
      FiltertaggedDevice: value.FiltertaggedDevice,
    }
    try {
      const response = await FomsMS.get("/DeviceTagging/GetAllDeviceSearchList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const CreateDevicesTagging = createAsyncThunk(
  "deviceTagging/createDevicesTagging", 
  async (value, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const currentDevices = getState().admin.deviceTagging.devices.currentDevices;
    const deletedDevices = getState().admin.deviceTagging.devices.deletedDevices;
    let deviceList = [...currentDevices, ...deletedDevices];
    const body = {
      farmId: find(userDetail.farms, "isActive").id,
      floorId: value.floorId,
      sectorId: value.sectorId,
      bankId: value.bankId,
      deviceList: map(deviceList, (item) => omit(item, ["deviceRefNo"])),
    }
    try {
      const response = await FomsMS.post("/DeviceTagging/CreateDevicesTagging", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const GetDevicesTaggingFloorPlan = createAsyncThunk(
  "deviceTagging/getDevicesTaggingFloorPlan", 
  async (value, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      floorId: value.floorId,
      sectorId: value.sectorId,
    }
    try {
      const response = await FomsMS.get("/DeviceTagging/GetDevicesTaggingFloorPlan", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const GetDevicesTaggingBank = createAsyncThunk(
  "deviceTagging/getDevicesTaggingBank", 
  async (value, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      bankId: value.bankId
    }
    try {
      const response = await FomsMS.get("/DeviceTagging/GetDevicesTaggingBank", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const GetDeviceLocation = createAsyncThunk(
  "deviceTagging/getDeviceLocation", 
  async (id, {rejectWithValue}) => {
    const params = {
      deviceLocationId: id
    }
    try {
      const response = await FomsMS.get("/DeviceTagging/GetDeviceLocation", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const DeleteDeviceLocation = createAsyncThunk(
  "deviceTagging/deleteDeviceLocation", 
  async (id, {rejectWithValue}) => {
    const params = {
      deviceLocationId: id
    }
    try {
      const response = await FomsMS.delete("/DeviceTagging/DeleteDeviceLocation", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const UpdateDeviceLocation = createAsyncThunk(
  "deviceTagging/updateDeviceLocation", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.post("/DeviceTagging/UpdateDeviceLocation", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);