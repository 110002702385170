import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Modal from "shared-components/Modal/Modal";
import Tag from 'shared-components/Chip/Tag';
import CustomIconButton from "shared-components/Button/IconButton";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { rowsPerTable } from "config";
//import { useRouteCanWrite } from "hooks";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { renderValue, filterParam } from 'common/functions';
import { asrsMovementTableHead } from "enums/UserPortal/TableHeaders";
import { reset, setValues } from "../store/movement";
import { GetAllASRSMovementMasterList, DeleteASRSMovement, GetASRSMaintenanceStatus } from "services/UserPortal/ASRSService";

import styles from "assets/jss/components/UserPortal/asrs.module.scss";

export default function Movement() {
  //const canWrite = useRouteCanWrite();
  const dispatch = useDispatch();
  let history = useHistory();
  const filter = useSelector(store => store.user.asrs.movement.filter);
  const totalCount = useSelector(store => store.user.asrs.movement.totalCount);
  const movementList = useSelector(store => store.user.asrs.movement.movementList);
  const statusList = useSelector(store => store.user.asrs.movement.statusList);
  const [newFilter, setNewFilter] = React.useState({page: 0});
  const [deleteId, setDeleteId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {page: 0, startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD"), ...filterParam(newFilter)}}));
    }
  };

  const handleModal = (id) => {
    setDeleteId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteASRSMovement(deleteId))
    .then(() =>{
      //dispatch(setValues({filter: {...filter, page: 0}}));
      handleModal(null);
      setDeleteId(null);
    })
  }

  const handleButtonClick_deleteTag = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const getAllMovement = (stopLoading) => {
    dispatch(GetAllASRSMovementMasterList({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllMovement();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetASRSMaintenanceStatus());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllMovement(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="VIFS - Movement Master List"
        subheader={
          <React.Fragment>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "page") {
                let label = filter[key];
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={styles.tag}
                    onDelete={() => handleButtonClick_deleteTag(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <div className={styles.cardHeader}>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the movement list
                  <Select
                    className={styles.filterDropdown}
                    name="status"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select status"
                    value={renderValue(newFilter.status)}
                  >
                    {statusList && statusList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Date"
              className={styles.cardActionDate}
              value={{startDate: filter.startDate, endDate: filter.endDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {page: 0, startDate: null, endDate: null, ...filterParam(newFilter)}}))}
            />
          </div>
        }
      >
        <div className={styles.table}>
          <Table
            header={asrsMovementTableHead}
          >
            { movementList.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{moment(item.startDateTime).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{moment(item.startDateTime).format('HH:mm')}</TableCell>
                  <TableCell>{item.nameOfJob}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell><Link to={{pathname: "/user/vifs/movement/"+item.id+"/details"}} className={styles.link}>Link</Link></TableCell>
                  <TableCell align="right">
                    <React.Fragment>
                      {item.status=="Scheduled" &&
                        <React.Fragment>
                          <CustomIconButton 
                            type="edit"
                            onClick={() => history.push("/user/vifs/movement/"+item.id)}
                          />
                          <CustomIconButton 
                            type="delete"
                            onClick={() => handleButtonClick_delete(index)}
                          />
                        </React.Fragment>
                      }
                    </React.Fragment>
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            //onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
