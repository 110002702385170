import { createSlice } from "@reduxjs/toolkit";

import { GetAlarmNotificationCount } from "services/UserPortal/AlarmService";

// initial state
const initialState = {
  isDisabled: false,
  unread: {notificationCount: 0, alarmCount: 0, state: null},
  alarms: [],
  notifications: []
};

// create reducer and action creators
const navbar = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    setAddNewButton: (state, action) => {
      state.isDisabled = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAlarmNotificationCount.fulfilled, (state, action) => {
        state.unread={
          notificationCount: action.payload.result.notificationCount,
          alarmCount: action.payload.result.alarmCount,
          state: action.payload.result.alarmNotificationState.replace(" ", "")
        }
      })
  }
});

// export actions
export const { setValues, setAddNewButton, reset } = navbar.actions;

// export the reducer
export default navbar.reducer;