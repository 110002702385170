import React from "react";
import { useDispatch } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import EquipmentStatus from "./EquipmentStatus";
import MaintenanceSchedule from "./MaintenanceSchedule";
import { setSearchText } from "store/general";
import { reset } from "../store/maintenance";

export default function MaintenanceStatus() {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState(0);

  const handleOnChange_tab = (e, value) => {
    setTab(value);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <EquipmentStatus />
      case 1:
        return <MaintenanceSchedule />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title="Maintenance Status"
        subheader={
          <Tabs 
            tabs={["Equipment Status", "Maintenance Schedule"]}
            value={tab} 
            onChange={handleOnChange_tab} 
          />
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
