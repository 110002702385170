import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Buffer } from 'buffer';
// @mui/material
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
// core components
import Portal from "layouts/Portal.js";
import Authentication from "layouts/Authentication.js";
import Form from "layouts/Form.js";

// import swDev from "./swDev";
import { interceptor } from "services";
import { theme, scrollbarStyle } from "../src/theme";
import 'react-date-range/dist/styles.css'; // react date range
import 'react-date-range/dist/theme/default.css'; // react date range
import "assets/css/tailwind.css";
import "assets/css/fullcalendar-vars.css";
import "assets/css/index.css";
import "assets/css/maps.css";

import store from "store";

interceptor(store);

window.Buffer = Buffer;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={scrollbarStyle} />
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/account" render={() => !localStorage.getItem("accessToken") ? <Form /> : <Redirect to="/user/home" />} />
            <Route path="/login" render={() => !localStorage.getItem("accessToken") ? <Authentication /> : <Redirect to="/user/home" />} />
            <Route path="/signup" render={() => !localStorage.getItem("accessToken") ? <Authentication /> : <Redirect to="/user/home" />} />
            <Route path="/admin" render={() => localStorage.getItem("accessToken") ? <Portal /> : <Redirect to="/login" />} />
            <Route path="/user" render={() => localStorage.getItem("accessToken") ? <Portal /> : <Redirect to="/login" />} />
            <Route path="/settings" render={() => localStorage.getItem("accessToken") ? <Portal /> : <Redirect to="/login" />} />
            {localStorage.getItem("accessToken") 
            ? JSON.parse(localStorage.getItem("userDetail")).companyId ? <Redirect to="/user/home" /> : <Redirect to="/admin/home" /> 
            : <Redirect to="/login" />}
          </Switch>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);
// swDev();