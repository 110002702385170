/* eslint-disable no-unused-vars */ // TO REMOVE
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { GetInventoryCount } from "services/UserPortal/AMRService";

import LevelDetails from "./LevelDetails";

export default function InventoryCount() {
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const [tab, setTab] = React.useState(0);

  const tabs = [{value: 1, title: "Level 1"}, {value: 3, title: "Level 3"}, {value: 4, title: "Level 4"}]

  const handleOnChange_tab = (e, value) => {
    setTab(value);
  }
  
  const getAllInventoryCount = (stopLoading) => {
    dispatch(GetInventoryCount({level: [tabs[tab].value], stopLoading}));
  }

  // componentDidMount
  React.useEffect(() => {
    getAllInventoryCount();
  },[tab]);

  useInterval(() => {
    getAllInventoryCount(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="AMR Management - Inventory Count"
        subheader={
          <Tabs 
            tabs={tabs.map(({title}) => title)}
            value={tab} 
            onChange={handleOnChange_tab}
          />
        }
      >
        <LevelDetails level={tabs[tab]} />
      </Card>
    </React.Fragment>
  );
}
