import { createSlice } from "@reduxjs/toolkit";

import { SearchUnassignedOrderList } from "services/UserPortal/PlanningService";

// initial state
const initialState = {
  outcomeTotalCount: 0,
  outcomeUnassignedTotalCount: 0,
  excessTotalCount: 0,
  unassignedTotalCount: 0,
  outcomeResults: [],
  planningUnassignedResults: [],
  excessResults: [],
  unassignedResults: [],
};

const planningSearch = createSlice({
  name: "planningSearch",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SearchUnassignedOrderList.fulfilled, (state, action) => {
        state.unassignedTotalCount = action.payload.result.totalCount;
        state.unassignedResults = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, reset } = planningSearch.actions;

// export the reducer
export default planningSearch.reducer;