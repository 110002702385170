import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Autocomplete from "shared-components/Select/Autocomplete";

import { setDirty } from "store/general";
import { updateRequest } from "../store/phenotype";
import { renderValue } from "common/functions";
import { GetProductInfoById, GetGerminationDetailByWorkOrderIdAndProductId, GetBatchIDList, GetTrayPosition, GetQCDetailsFromOperation, CalculateDatesByWorkOrderIdAndProductId,
  CalculateNurseryDuration, CalculateGrowthDuration, GeneratePlantPhenotypeMatrixFormat, UploadQualityControlImageList, CreateOrUpdatePhenotypeQualityControl } from "services/UserPortal/QualityControlService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import PlantMatrix from "./PlantMatrix";
import styles from "assets/jss/components/UserPortal/qualityControl.module.scss";

export default function DetailForm(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const batchIDList = useSelector(store => store.user.qualityControl.phenotype.batchIDList);
  const boardTypeList = useSelector(store => store.user.qualityControl.phenotype.boardTypeList);
  const trayPositionList = useSelector(store => store.user.qualityControl.phenotype.trayPositionList)
  const qualityControl = useSelector(store => store.user.qualityControl.phenotype.qualityControl);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "quality-control");

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
    if (e.target.name === "workOrderId") {
      setErrorMsg(false);
      dispatch(updateRequest({productId: null, vegetableVariety: null}));
    }
    if (e.target.name === "boardType") {
      dispatch(GeneratePlantPhenotypeMatrixFormat({boardType: e.target.value}));
      props.setMatrixImages([]);
    }
    if (e.target.name === "trayPositionId") {
      dispatch(GetQCDetailsFromOperation({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId, trayPositionId: qualityControl.trayPosition, isGerminationReport: false}));
    }
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateRequest({trayPosition: value}));
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnClick_save = () => {
    if (validateFields()) {
      Promise.all([
        (props.matrixImages && props.matrixImages.length) && dispatch(UploadQualityControlImageList(props.matrixImages))
      ])
      .then((response) => {
        if (!response[0].error) {
          let qc = _.cloneDeep(qualityControl);
          let qcMatrix = _.cloneDeep(qualityControl.plantMatrices);
          if (qcMatrix && response[0]) {
            qcMatrix = qcMatrix.map((item, i) => {
              const imageIndex = response[0].payload.result.findIndex(({imageLocation}) => imageLocation === String(i+1));
              if (imageIndex !== -1) {
                item.imageUrl = response[0].payload.result[imageIndex].imageUrl;
              }
              return item;
            })
          }
          qc.plantMatrices = qcMatrix;
          dispatch(CreateOrUpdatePhenotypeQualityControl(qc))
          .then(({error}) => {
            if (!error) {
              Promise.all([dispatch(setDirty(false))])
              .then(() => {
                history.push("/user/quality-control/phenotype");
              })
            } else {
              dispatch(updateRequest({plantMatrices: qcMatrix}));
            }
          });
        }
      })
    }
  }

  const validateFields = () => {
    if (!qualityControl.workOrderId) {
      setErrorMsg({field: "workOrderId", msg: "Please select a work order"});
      return false;
    }
    if (!qualityControl.productId) {
      setErrorMsg({field: "productId", msg: "Please select a product"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }
  
  React.useEffect(() => {
    if (qualityControl.workOrderId) {
      dispatch(GetProductList(qualityControl.workOrderId));
    }
  },[qualityControl.workOrderId]);
  
  React.useEffect(() => {
    if (qualityControl.productId) {
      dispatch(GetProductInfoById(qualityControl.productId));
      dispatch(GetBatchIDList({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}));
      dispatch(GetTrayPosition({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId, isGerminationReport: false}));
      if (path[index+2] === "create") {
        dispatch(GetGerminationDetailByWorkOrderIdAndProductId({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}))
        .then((response) => {
          if (response.payload && response.payload.result) {
            if (!response.payload.result.germinationSerialNo) {
              setErrorMsg({field: "productId", msg: "Germination report not created"});
            } else {
              setErrorMsg(false);
            }
          }
        });
        dispatch(CalculateDatesByWorkOrderIdAndProductId({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}))
        .then(({payload}) => {
          if (payload.result) {
            dispatch(CalculateNurseryDuration({transplantDate: moment(payload.result.transplantDate).format("DD MMMM YYYY"), germinationDate: moment(payload.result.germinateDate).format("DD MMMM YYYY")}));
            dispatch(CalculateGrowthDuration({transplantDate: moment(payload.result.transplantDate).format("YYYY-MM-DD"), harvestDate: moment(payload.result.harvestDate).format("YYYY-MM-DD")}));
          }
        });
      }
    }
  },[qualityControl.productId]);
  
  return (
    <React.Fragment>
      <Card 
        title = {(path[index+1] !== "create" ? "Edit" : "Add New") + " Plant Phenotype Report - Quality Control Entries"}
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.push("/user/quality-control/phenotype")}
            >
              Go Back
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleOnClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={styles.formTitle}>Select Work Order to Work On</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Work Order:</Typography>
            <Select
              name="workOrderId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a work order"
              value={renderValue(qualityControl.workOrderId)}
              errorMsg={errorMsg}
              disabled={path[index+2] !== "create"}
            >
              {workOrderList && workOrderList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Vegetable Name:</Typography>
            {path[index+2] === "create"
            ? <Select
                name="productId"
                onChange={(e)=>handleOnChange_select(e)}
                placeholder="Select a vegetable name"
                value={renderValue(qualityControl.productId)}
                errorMsg={errorMsg}
                disabled={!qualityControl.workOrderId}
              >
                {productList && productList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.productName}</MenuItem>;
                })}
              </Select>
            : <TextField 
                id="productId"
                variant="outlined" 
                value={renderValue(qualityControl.productName)}
                disabled
              />
            }
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Germination Serial No.:</Typography>
            <TextField 
              id="germinationSerialNo"
              variant="outlined" 
              placeholder=""
              value={renderValue(qualityControl.germinationSerialNo)}
              errorMsg={errorMsg}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={styles.formTitle}>General Information</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Serial No:</Typography>
            <TextField 
              id="vegetableSerialNo"
              variant="outlined" 
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.vegetableSerialNo )}
              disabled = { !qualityControl.workOrderId }
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Vegetable Variety:</Typography>
            <TextField 
              id="vegetableVariety"
              variant="outlined" 
              placeholder=""
              value={renderValue(qualityControl.vegetableVariety)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Batch No:</Typography>
            <Select
              name="batchNo"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a batch ID"
              value={renderValue(qualityControl.batchNo)}
              errorMsg={errorMsg}
              disabled={!qualityControl.productId}
            >
              {batchIDList && batchIDList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Tray Position:</Typography>
            <Autocomplete
              id="trayPosition"
              freeSolo
              inputProps={{ maxLength: 50 }}
              onInputChange={(e, newInputValue) => {
                handleOnChange_autocomplete(newInputValue);
              }}
              inputValue={renderValue(qualityControl.trayPosition)}
              options={_.compact(trayPositionList.map((item) => item.trayPosition))}
              placeholder="Select a tray position" 
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={styles.formTitle}>Dates</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Germinate Date:</Typography>
            <TextField 
              name="germinateDate"
              variant="outlined" 
              value={renderValue(qualityControl.germinateDate && moment(qualityControl.germinateDate).format("DD/MM/YYYY"))}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Harvest Date:</Typography>
            <TextField 
              name="harvestDate"
              variant="outlined" 
              value={renderValue(qualityControl.harvestDate && moment(qualityControl.harvestDate).format("DD/MM/YYYY"))}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Nursery Duration:</Typography>
            <TextField 
              id="nurseryDuration"
              variant="outlined" 
              value={renderValue(qualityControl.nurseryDuration)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Transplant Date:</Typography>
            <TextField 
              name="transplantDate"
              variant="outlined" 
              value={renderValue(qualityControl.transplantDate && moment(qualityControl.transplantDate).format("DD/MM/YYYY"))}
              disabled
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Growth Duration:</Typography>
            <TextField 
              id="growthDuration"
              variant="outlined" 
              value={renderValue(qualityControl.growthDuration)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={styles.formTitle}>Plant Board</Typography>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Board Type:</Typography>
            <Select
              name="boardType"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a board type"
              value={renderValue(qualityControl.boardType)}
            >
              {boardTypeList && boardTypeList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={styles.grid}>
            <Typography className={styles.formLabel}>Growing Media Type:</Typography>
            <TextField 
              id="growingMediaType"
              variant="outlined" 
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.growingMediaType)}
            />
          </Grid>
          <Grid item xs={12} className={styles.grid}>
            <Typography className={styles.formTitle}>Remarks</Typography>
          </Grid>
          <Grid item xs={7} className={styles.grid}>
            <TextField 
              id="remark"
              variant="outlined" 
              multiline
              minRows={12}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.remark)}
            />
          </Grid>
          <Grid item xs={12} className={styles.grid}>
            <Typography className={styles.formTitle}>Plant Matrix</Typography>
          </Grid>
          <Grid item xs={12} className={styles.grid}>
            <PlantMatrix matrixImages={props.matrixImages} setMatrixImages={props.setMatrixImages} />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
