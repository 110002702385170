import { createSlice } from "@reduxjs/toolkit";

import { GetTimeToRunList, GetPlanningConfiguration, GetAllExclusionDays, GetPlanningParametersByFarmId } from "services/UserPortal/PlanningService";

// initial state
const initialState = {
  timeToRun: [],
  setting: {},
  exclusion: {exclusionDates:[]},
  parameters: {},
};

const planningSettings = createSlice({
  name: "planningSettings",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.setting[key] = action.payload[key];
      })
    },
    updateExclusionRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.exclusion[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetTimeToRunList.fulfilled, (state, action) => {
        state.timeToRun = action.payload.result;
      })
      .addCase(GetPlanningConfiguration.fulfilled, (state, action) => {
        state.setting = action.payload.result ? action.payload.result : {};
      })
      .addCase(GetAllExclusionDays.fulfilled, (state, action) => {
        state.exclusion = action.payload.result;
      })
      .addCase(GetPlanningParametersByFarmId.fulfilled, (state, action) => {
        state.parameters = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, updateExclusionRequest, reset } = planningSettings.actions;

// export the reducer
export default planningSettings.reducer;