import React from "react";
// @mui/material components
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
// core components
import Popover from "shared-components/Popper/Popover";
import IconButton from "shared-components/Button/IconButton";
// core components
import { bankFormat } from "common/functions";
import styles from "assets/jss/shared-components/bank.module.scss";

export default function TagBank(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDetails, setOpenDetails] = React.useState(false);

  const noOfBays = () => {
    let element = [];
    for (let i = 1; i <= parseInt(props.bays); i++) {
      const devices = props.data.filter((item) => {
        if (item.cageLocationRefNo) {
          const location = item.cageLocationRefNo.split("-");
          if (location[1] === bankFormat(i) && location[2] === bankFormat(props.row)) {
            return item;
          }
        }
      });
      element.push(
        <React.Fragment key={i}>
          <TableCell 
            className={styles.tagCell} 
            onClick={()=>props.onCellClick({level: props.row, bay: i})} 
          >
            <div className="flex flex-wrap">
              {renderContent(devices, i)}
            </div>
          </TableCell>
          <Popover
            sx={{ pointerEvents: 'none' }}  // for interaction + mouse leave action
            classes={{paper: styles.tagPaper}}
            open={openDetails.level === props.row && openDetails.bay === i}
            onClose={()=>{setAnchorEl(null); setOpenDetails(false);}}
            anchorEl={anchorEl}
          >
            <Typography className={styles.titleText}>Level: {props.row}, Bay: {i}</Typography>
            {renderDetails(devices)}
          </Popover>
        </React.Fragment>
      );
    }
    return element;
  }

  // const renderDevice = (value) => {
  //   const devices = props.data.filter((item) => {
  //     if (item.cageLocationRefNo) {
  //       const location = item.cageLocationRefNo.split("-");
  //       if (location[1] === format(value.bay) && location[2] === format(value.level)) {
  //         return item;
  //       }
  //     }
  //   })
    
  //   let element = [];
  //   if (devices) {
  //     for (let i = 0; i < devices.length; i++) {
  //       const refNo = devices[i].deviceInfo ? devices[i].deviceInfo.deviceRefNo : devices[i].deviceRefNo
  //       if (refNo) {
  //         element.push(
  //           <div key={i} className={styles.markerDiv}>
  //             <div className={styles.marker}>{refNo.substr(0, refNo.indexOf('-'))}</div>
  //             <Typography className={styles.markerText}>{refNo}</Typography>
  //           </div>
  //         );
  //       }
  //     }
  //   }
  //   return element;
  // }

  const renderContent = (devices, i) => {
    if (devices.length) {
      return <div className={styles.marker} onMouseEnter={(e)=>showDetails(e, {level: props.row, bay: i}, devices)} onMouseLeave={()=>{setAnchorEl(null); setOpenDetails(false);}}>{devices.length}</div>;
      // return <Typography className={styles.link}>{devices.length} device(s) tagged</Typography>
    }
  }

  const showDetails = (e, value, devices) => {
    if (devices.length) {
      e.preventDefault();
      setAnchorEl(e.currentTarget);
      setOpenDetails(value);
    }
  }

  const renderDetails = (devices) => {
    let element = [];
    if (devices) {
      for (let i = 0; i < devices.length; i++) {
        const refNo = devices[i].deviceInfo ? devices[i].deviceInfo.deviceRefNo : devices[i].deviceRefNo;
        const id = devices[i].deviceInfo ? devices[i].deviceInfo.deviceId : devices[i].deviceId;
        if (refNo) {
          element.push(
            <div className={styles.detailItem}>
              <Typography key={i} className={styles.listText}>{i+1}. {refNo}</Typography>
              <IconButton
                type="delete" 
                onClick={() => props.onDelete(id)}
              />
            </div>
          );
        }
      }
    }
    return element;
  }

  return (noOfBays());
}