import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import Collapse from "@mui/material/Collapse";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import CustomIconButton from "shared-components/Button/IconButton";
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";
import DateTimePicker from "shared-components/DatePicker/DateTimePicker";

import { minDate, rowsPerTable } from "config";
import { renderValue } from 'common/functions';
import { reset } from "../store/search";
import { setValues, updateRequest } from "../store/master";
import { setSearchText } from "store/general";
import { deliveryTableHead, deliveryDetailsTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllDriver, PartialUpdateDeliveryTrip, DeleteTrip, SearchDeliveryMasterList } from "services/UserPortal/DeliveryService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/delivery.module.scss";

import { useRouteCanWrite } from "hooks";

export default function SearchMasterResult() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.delivery.search.masterTotalCount);
  const results = useSelector(store => store.user.delivery.search.masterResults);
  const driverList = useSelector(store => store.user.delivery.master.driverList);
  const trip = useSelector(store => store.user.delivery.master.trip);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const [subPage, setSubPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [editId, setEditId] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleOnChange_time = (value) => {
    let payload = _.cloneDeep(trip);
    if(value && value.isValid() && value != "Invalid Date") {
      payload.time = value;
    } else {
      payload.time = value;
    }
    payload.deliveryTime = moment(value).format("HH:mm");
    dispatch(setValues({trip: payload}));
  };
  
  const handleButtonClick_edit = (id) => {
    setEditId(id);
    const value = _.pick(_.find(results, (item) => item.id === id), ["farmId", "id", "driverId", "deliveryTime"]);
    const time = value.deliveryTime.split(":");
    value.time = moment().set({'hour': time[0], 'minute': time[1]});
    dispatch(setValues({trip: value}));
  }

  const handleModal_delete = (id) => {
    setOpenDeleteModal(id);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteTrip(openDeleteModal))
    .then(() =>{
      setPage(0);
      dispatch(SearchDeliveryMasterList({keyword: params.keyword, page: 0}));
      setOpenDeleteModal(false);
    })
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(PartialUpdateDeliveryTrip())
      .then(({error}) =>{
        if (!error) {
          dispatch(SearchDeliveryMasterList({keyword: params.keyword, page}));
          dispatch(setValues({trip: {}}));
          setEditId(null);
        }
      })
    }
  }

  const handleButtonClick_cancel = () => {
    dispatch(setValues({trip: {}}));
    setEditId(null);
  }
  
  const validateFields = () => {
    if (!trip.deliveryTime) {
      setErrorMsg({field: "deliveryTime", msg: "Please select a delivery time"});
      return false;
    }
    if (!moment(trip.time).isValid()) {
      setErrorMsg({field: "deliveryTime", msg: "Invalid time"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  React.useEffect(() => {
    dispatch(SearchDeliveryMasterList({keyword: params.keyword, page, stopLoading: Boolean(page)}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllDriver());
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={styles.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: styles.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Master List"}
                <IconButton 
                  className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={styles.table}>
                  <Table
                    expandable
                    header={deliveryTableHead}
                  >
                    { _.map(results, (item,index) => {
                      const subCount = Math.ceil(item.deliveryTripDetails.length / rowsPerTable);
                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            hover
                            sx={{ cursor: 'pointer' }}
                            onClick={() => open === index ? setOpen(false) : setOpen(index)}
                          >
                            <TableCell>
                              <IconButton 
                                className={open === index ? styles.rotate : null}
                                onClick={() => open === index? setOpen(false) : setOpen(index)}
                              >
                                <img className={styles.icon} src={arrow} alt="arrow" />
                              </IconButton>
                            </TableCell>
                            <TableCell>{highlightValue(item.deliveryTripRefNo)}</TableCell>
                            <TableCell>{highlightValue(moment(item.deliveryDate).format("DD/MM/YYYY"))}</TableCell>
                            <TableCell>{highlightValue(item.status)}</TableCell>
                            <TableCell>
                              { canWrite && editId === item.id
                              ? <Select
                                  name="driverId"
                                  onChange={(e)=>handleOnChange_select(e)}
                                  value={renderValue(trip.driverId)}
                                  placeholder="Select a driver"
                                >
                                  {driverList && driverList.map((item, index) => {
                                    return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                                  })} 
                                </Select>
                              : item.driverName
                              }
                            </TableCell>
                            <TableCell>
                              { canWrite && editId === item.id
                              ? <DateTimePicker
                                  type="time"
                                  name="time"
                                  value={trip.time}
                                  onChange={(e) => handleOnChange_time(e)}
                                  errorMsg={errorMsg}
                                />
                              : item.deliveryTime
                              }
                            </TableCell>
                            <TableCell>{highlightValue(moment(item.tripStartTime).isAfter(minDate) && moment(item.tripStartTime).format("HH:mm"))}</TableCell>
                            <TableCell>{highlightValue(item.estimatedDuration ? item.estimatedDuration + " mins" : "")}</TableCell>
                            <TableCell>{highlightValue(item.actualDuration ? item.actualDuration + " mins" : "")}</TableCell>
                            <TableCell>
                              {editId
                              ?"Link"
                              :<Link to={{pathname: location.pathname+"/"+item.id}} className={styles.link}>Link</Link>
                              }
                            </TableCell>
                            <TableCell>
                              {item.status === "Pending"
                              ? canWrite && editId === item.id
                                ? <React.Fragment>
                                    <CustomIconButton 
                                      type="confirm"
                                      onClick={() => handleButtonClick_confirm()}
                                    />
                                    <CustomIconButton 
                                      type="close"
                                      onClick={() => handleButtonClick_cancel()}
                                    />
                                  </React.Fragment>
                                : <React.Fragment>
                                    <CustomIconButton 
                                      type="edit" 
                                      disabled={Boolean(editId && editId !== item.id)} 
                                      onClick={()=>handleButtonClick_edit(item.id)} 
                                    />
                                    <CustomIconButton 
                                      type="delete"
                                      onClick={() => handleModal_delete(item.id)}
                                      disabled={Boolean(editId && editId !== item.id)}
                                    />
                                  </React.Fragment>
                              : null
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            { open===index && 
                              <TableCell className="pt-0 pb-0" colSpan={11}>
                                <Collapse in={open === index}>
                                  <Table
                                    className={styles.subTable}
                                    header={deliveryDetailsTableHead}
                                  >
                                    { item.deliveryTripDetails.slice(subPage * rowsPerTable, subPage * rowsPerTable + rowsPerTable)
                                    .map((subItem, subIndex) => {
                                      if (subItem.deliveryOrder) {
                                        const detail = subItem.deliveryOrder;
                                        return (
                                          <React.Fragment key={subIndex}>
                                            <TableRow>
                                              <TableCell>{highlightValue(detail.deliveryOrderRefNo)}</TableCell>
                                              <TableCell>{highlightValue(detail.workOrderNumber)}</TableCell>
                                              <TableCell>{highlightValue(detail.saleOrderNumber)}</TableCell>
                                              <TableCell>{highlightValue(detail.customerName)}</TableCell>
                                              <TableCell>{highlightValue(detail.deliveryAddress)}</TableCell>
                                              <TableCell>{highlightValue(detail.deliveryOrderStatus ? detail.deliveryOrderStatus.status : "Pending")}</TableCell>
                                              <TableCell>{highlightValue(moment(detail.signedDateTime).isAfter(minDate) && moment(detail.signedDateTime).format("DD/MM/YYYY"))}</TableCell>
                                              <TableCell>{highlightValue(detail.remarks)}</TableCell>
                                            </TableRow>
                                          </React.Fragment>
                                        )
                                      }
                                    })}
                                  </Table>
                                  { subCount > 1 &&
                                    <Pagination 
                                      count={subCount} 
                                      page={subPage+1}
                                      onChange={(e, pageNo)=>setSubPage(pageNo-1)}
                                    />
                                  }
                                </Collapse>
                              </TableCell>
                            }
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
      <Modal
        open={Boolean(openDeleteModal)}
        onClose={() => handleModal_delete(false)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this trip? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(false)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
