import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {FomsMS} from "../index";
import { ML_URL } from "config";

export const MLMS = axios.create({
  baseURL: ML_URL,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  }
});

export const GetLightPrediction = createAsyncThunk(
  "prediction/getLightPrediction", 
  async (param, {rejectWithValue}) => {
    const body = {
      ...param,
      gapRange: 50,
    }
    try {
      const response = await MLMS.post("prediction/light_predict", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetLightingType = createAsyncThunk(
  "prediction/getLightingType", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await MLMS.get("prediction/lighting_type_list");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetFarmLocation = createAsyncThunk(
  "prediction/getFarmLocation", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await MLMS.get("prediction/farm_location_list");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Report
export const TracingWattCalculatorModuleForStatisticsReport = createAsyncThunk(
  "wattCalculatorModuleStatistics/tracingWattCalculatorModuleForStatisticsReport", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/WattCalculatorModuleStatistics/TracingWattCalculatorModuleForStatisticsReport", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const LightingPowerAndPercentagePrediction = createAsyncThunk(
  "prediction/lightingPowerAndPercentagePrediction", 
  async (_, {getState, rejectWithValue}) => {
    const body = getState().user.prediction.lighting;
    try {
      const response = await MLMS.post("/prediction/lighting_power_and_percentage_prediction", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);