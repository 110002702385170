import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
// @mui/material
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Select from "shared-components/Select/Select";
import BankOverview from "./BankOverview";

import { renderValue } from "common/functions";
import { farmConfig } from "enums/Constants";
import { GetBayLookUp, GetLevelLookUp, GetCageDetailView } from "services/UserPortal/MonitoringService";
import styles from "assets/jss/components/UserPortal/monitoring.module.scss";
import { GetCageLookUp } from "services/UserPortal/MonitoringService";

export default function CageView(props) {
  const dispatch = useDispatch();
  const bayList = useSelector(store => store.user.monitoring.production.bayList);
  const levelList = useSelector(store => store.user.monitoring.production.levelList);
  const cageList = useSelector(store => store.user.monitoring.production.cageList);
  const detailView = useSelector(store => store.user.monitoring.production.detailView);
  const [filter, setFilter] = React.useState({selectedBay: "", selectedLevel: "", selectedCage: ""});
  const [view, setView] = React.useState(false);

  const handleOnChange_select = (e) => {
    setFilter({...filter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_view = () => {
    const param = {
      bankId: props.bankId,
      cageId: filter.selectedCage
    }
    dispatch(GetCageDetailView(param));
    setView(true);
  };

  React.useEffect(() => {
    dispatch(GetBayLookUp(props.bankId));
  },[]);

  React.useEffect(() => {
    if (filter.selectedBay) {
      setFilter({...filter, selectedLevel: "", selectedCage: ""});
      const param = {
        bankId: props.bankId,
        bayId: filter.selectedBay
      }
      dispatch(GetLevelLookUp(param));
    }
  },[filter.selectedBay]);

  React.useEffect(() => {
    if (filter.selectedBay && filter.selectedLevel) {
      setFilter({...filter, selectedCage: ""});
      const param = {
        bankId: props.bankId,
        bayId: filter.selectedBay,
        levelId: filter.selectedLevel
      }
      dispatch(GetCageLookUp(param));
    }
  },[filter.selectedLevel]);

  return (
    <React.Fragment>
      <Typography className={styles.title}>{detailView.bankName}</Typography>
      <div className={styles.cageView}>
        <Typography className={styles.label}>{farmConfig.bay}: </Typography>
        <Select
          className={styles.productDropdown}
          name="selectedBay"
          onChange={(e)=>handleOnChange_select(e)}
          value={renderValue(filter.selectedBay)}
          placeholder={"Select a "+farmConfig.bay}
        >
          {bayList && bayList.map((item, index) => {
            return <MenuItem key={index} value={item}>{item}</MenuItem>;
          })} 
        </Select>
        <Typography className={styles.label}>{farmConfig.level}: </Typography>
        <Select
          className={styles.productDropdown}
          name="selectedLevel"
          onChange={(e)=>handleOnChange_select(e)}
          value={renderValue(filter.selectedLevel)}
          placeholder={"Select a "+farmConfig.level}
        >
          {levelList && levelList.map((item, index) => {
            return <MenuItem key={index} value={item}>{item}</MenuItem>;
          })} 
        </Select>
        <Typography className={styles.label}>{farmConfig.cage}: </Typography>
        <Select
          className={styles.productDropdown}
          name="selectedCage"
          onChange={(e)=>handleOnChange_select(e)}
          value={renderValue(filter.selectedCage)}
          placeholder={"Select a "+farmConfig.cage}
        >
          {cageList && cageList.map((item, index) => {
            return <MenuItem key={index} value={item.cageId}>{item.cageRefNo}</MenuItem>;
          })} 
        </Select>
        <Button 
          className={clsx(styles.button, styles.viewButton)}
          onClick={()=>handleButtonClick_view()}
        >
          View
        </Button>
      </div>
      {view && <BankOverview noSummary={true} />}
    </React.Fragment>
  );
}
