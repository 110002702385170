// Create Edit Product
// Step 2-5: Adding new table column each step
//                   _ooOoo_
//                  o8888888o
//                  88" . "88
//                  (| -_- |)
//                  O\  =  /O
//               ____/`---'\____
//             .'  \\|     |//  `.
//            /  \\|||  :  |||//  \
//           /  _||||| -:- |||||-  \
//           |   | \\\  -  /// |   |
//           | \_|  ''\---/''  |   |
//           \  .-\__  `-`  ___/-. /
//         ___`. .'  /--.--\  `. . __
//      ."" '<  `.___\_<|>_/___.'  >'"".
//     | | :  `- \`.;`\ _ /`;.`/ - ` : | |
//     \  \ `-.   \_ __\ /__ _/   .-` /  /
//======`-.____`-.___\_____/___.-`____.-'======
//                   `=---='
//
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//          佛祖保佑           永无BUG
//         God Bless        Never Crash
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { setDirty } from "store/general";
// @mui/material
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";

import { updateRequest } from "./store/product";
import { UploadProductImage } from "services/AdminPortal/UploadService";
import { GetProcessMasterList, CreateOrUpdateProduct, GetAllProducts } from "services/AdminPortal/ProductService";

import ProductStepper from "./ProductStepper";
import CreateRMEquip from "./CreateRMEquip";
import CreateTask from "./CreateTask";
import ManagePackage from "./ManagePackage";
import PreHarvest from "./PreHarvest";
import PostHarvest from "./PostHarvest";
import styles from "assets/jss/components/AdminPortal/product.module.scss";

export default function GrowthProcess(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct);
  const selectedPostHarvestProcess = useSelector(store => store.admin.product.product.productPostHarvestGrowthProcess);
  const [editMode, setEditMode] = React.useState(false);
  const [createRawMaterial, setCreateRawMaterial] = React.useState(false);
  const [createTask, setCreateTask] = React.useState(false);
  const [packageSettings, setPackageSettings] = React.useState(false);
  const [growthEditIndex, setGrowthEditIndex] = React.useState(null); //pre/postharvest edit index
  const [type, setType] = React.useState("productPostHarvestGrowthProcess");
  const [productID, setProductID] = React.useState(""); //used for displaying productID in task header
  const [disableSave, setDisableSave] = React.useState(false);

  const title = [
    {
      title: "Step 2: Key in details for Growth Process",
      subtitle: "Please key in the details. You are currently configuring for Product "+selectedProduct.productID,
    },
    {
      title: "Step 3: Key in Raw Materials & Equipment for each Process",
      subtitle: "Please key in the necessary Raw Materials & Equipment for each Process"
    },
    {
      title: "Step 4: Key in Tasks for each Process",
      subtitle: "Please key in the necessary tasks for each process. You are currently configuring for Product "+selectedProduct.productID
    },
    {
      title: "Step 5: Key in % Loss for the Product at each stage",
      subtitle: "Please key in the necessary details for percentage loss. You are currently configuring for Product "+selectedProduct.productID
    },
  ]

  const handleGoBack = () => {
    if (createRawMaterial) {
      setGrowthEditIndex(null);
      setCreateRawMaterial(false);
    } else if (createTask) {
      setGrowthEditIndex(null);
      setCreateTask(false);
    } else if (packageSettings) {
      setPackageSettings(false);
    } else {
      props.setStep(props.step - 1)
    }
  }

  const saveProduct = () => {
    dispatch(CreateOrUpdateProduct())
    .then(({error}) => {
      if (error) {
        setDisableSave(false);
      } else {
        history.push("/admin/product");
      }
    });
  }

  const handleSave = () => {
    Promise.all([
      dispatch(setDirty(false))
    ])
    .then(() => {
      setDisableSave(true);
      if (props.imgFile) {
        dispatch(UploadProductImage(props.imgFile))
        .then(() => {
          saveProduct();
        })
      } else {
        saveProduct();
      }
    })
  }

  //componentDidMount
  React.useEffect(() => {
    dispatch(GetProcessMasterList());
    dispatch(GetAllProducts());
  },[]);

  React.useEffect(() => {
    if (props.step === 4) {
      dispatch(updateRequest({["isDraft"] : false}))
    }
  },[props.step]);

  // check if pre n post harvest obj is empty or not, if it is fly the user back to step 2 to add more process
  React.useEffect(() => {
    if (selectedProduct.productPreHarvestGrowthProcess && !selectedProduct.combinationProduct) {
      if (selectedProduct.productPreHarvestGrowthProcess.length === 0) {
        props.setStep(1);
      }
    }
    if (selectedProduct.productPostHarvestGrowthProcess) {
      if (selectedProduct.productPostHarvestGrowthProcess.length === 0) {
        props.setStep(1);
      }
    }
  },[selectedProduct.productPreHarvestGrowthProcess, selectedProduct.productPostHarvestGrowthProcess]);

  const displayTable = () => {
    if (createRawMaterial) {
      return (
        <CreateRMEquip type={type} editMode={editMode} setEditMode={setEditMode} growthEditIndex={growthEditIndex} />
      )
    } else if (createTask) {
      return (
        <CreateTask type={type} editMode={editMode} setEditMode={setEditMode} growthEditIndex={growthEditIndex} productID={productID}/>
      )
    } else if (packageSettings) {
      return (
        <ManagePackage setPackageSettings={setPackageSettings} />
      )
    } else {
      return (
        <React.Fragment>
          <PreHarvest step={props.step} setType={setType} editMode={editMode} setEditMode={setEditMode} setCreateRawMaterial={setCreateRawMaterial} setGrowthEditIndex={setGrowthEditIndex} setCreateTask={setCreateTask} setProductID={setProductID}/>
          <PostHarvest step={props.step} setStep={props.setStep} setType={setType} editMode={editMode} setEditMode={setEditMode} setCreateRawMaterial={setCreateRawMaterial} setGrowthEditIndex={setGrowthEditIndex} setCreateTask={setCreateTask} setPackageSettings={setPackageSettings}/>
        </React.Fragment>
      )
    }
  }

  const saveButton = () => {
    if(createRawMaterial || createTask || packageSettings) {
      if (selectedProduct.combinationProduct && type === "preHarvest") {
        return null;
      } else {
        return (
          <Button
            className={clsx(styles.button, styles.buttonSpacing)}
            onClick={()=>handleGoBack()}
            disabled={editMode || selectedPostHarvestProcess.length === 0}
          >
            Save
          </Button>
        )
      }
    } else {
      return (
        <Button
          className={clsx(styles.button, styles.buttonSpacing)}
          onClick={()=>props.setStep(props.step + 1)}
          disabled={editMode || selectedPostHarvestProcess.length === 0}
        >
          Next Step
        </Button>
      )
    }
  }

  return (
    <React.Fragment>
      <Card 
        title={title[props.step-1].title}
        subheader={
          <React.Fragment>
            {title[props.step-1].subtitle}
            <ProductStepper activeStep={props.step} />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>handleGoBack()}
              disabled={editMode}
            >
              Go Back
            </Button>
            <div>
              {(createRawMaterial || createTask || packageSettings)
                ? 
                <Button disabled/>
                :
                <Button
                  className={styles.buttonSecondary}
                  onClick={()=>handleSave()}
                  disabled={editMode || disableSave}
                >
                  Do it later
                </Button>
              }
              {(props.step === 4)
                ? 
                <Button
                  className={clsx(styles.button, styles.buttonSpacing)}
                  onClick={()=>handleSave()}
                  disabled={editMode || selectedPostHarvestProcess.length === 0 || disableSave}
                >
                  Save
                </Button>
                :
                saveButton()
              }
            </div>
          </React.Fragment>
        }
      >
        {displayTable()}
      </Card>
    </React.Fragment>
  );
}
