import React from "react";
import { useDispatch, useSelector } from "react-redux";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import Alert from "shared-components/Modal/Alert";

import { setDirty, setSearchText } from "store/general";
import { reset } from "../store/settings";

import Release from "./Release";

export default function Settings() {
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const [tab, setTab] = React.useState(0);
  const [newTab, setNewTab] = React.useState(0);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);

  const handleOnChange_tab = (e, value) => {
    if (isDirty) {
      setOpenAlertModal(!openAlertModal);
      setNewTab(value);
    } else {
      setTab(value);
    }
  }

  const handleButtonClick_exit = () => {
    setOpenAlertModal(false);
    setTab(newTab);
    dispatch(setDirty(false));
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0: 
        return <Release />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title = "Plan Settings Configuration"
        subheader={
          <React.Fragment>
            Please take note that the timing which you have configured must be more than 2 hours after the daily planning run time.
            <Tabs 
              tabs={["Time to Release New Ops Plan"]}
              value={tab} 
              onChange={handleOnChange_tab}
            />
          </React.Fragment>
        }
      >
        {renderComponent()}
      </Card>
      <Alert open={openAlertModal} onConfirm={()=>handleButtonClick_exit()} onCancel={()=>handleOnChange_tab()} />
    </React.Fragment>
  );
}
