import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import Button from "@mui/material/Button";
// core components
import Modal from 'shared-components/Modal/Modal';

import { filterUrl } from 'common/functions';

import filterIcon from "assets/icons/orange/filter.svg";
import disabledFilterIcon from "assets/icons/default/filter.svg";
import styles from "assets/jss/shared-components/button.module.scss";
export default function FilterButton({filter, setFilter, setNewFilter, ...props}) {
  const [openFilterModal, setOpenFilterModal] = React.useState(false);

  const handleModal_filter = () => {
    setOpenFilterModal(!openFilterModal);
    setNewFilter(filter);
  };

  const handleButtonClick_filter = () => {
    setFilter();
    setOpenFilterModal(false);
  };

  React.useEffect(() => {
    window.history.pushState({}, '', filterUrl(filter));
  },[filter]);

  return (
    <React.Fragment>
      <Button 
        className={styles.buttonSecondary}
        onClick={()=>handleModal_filter()}
        endIcon={<img className={styles.icon} src={props.disabled ? disabledFilterIcon : filterIcon} alt="filter" />}
        disabled={props.disabled}
      >
        Filter
      </Button>
      <Modal
        className={props.classes && props.classes.modal}
        open={openFilterModal}
        onClose={() => handleModal_filter()}
        title="Search Filter"
        content={props.content}
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_filter()}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_filter()}>Apply Filter</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}

FilterButton.propTypes = {
  filter: PropTypes.object,
  newFilter: PropTypes.object,
  setNewFilter: PropTypes.func,
};