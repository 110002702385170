import React from "react";
// @mui/material
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";

import styles from "assets/jss/components/AdminPortal/util.module.scss";
import bank from "assets/img/bank-img.svg";

export default function BankExplanation(props) {

  return (
    <Card 
      classes={{
        cardActions: styles.paper
      }}
      title="Explanation of the terms"
      cardActions={
        <Button
          className={styles.buttonSecondary}
          onClick={() => props.setShowExplanation(false)}
        >
          Go Back
        </Button>
      }
    >
      <Paper className={styles.paper} elevation={0}>
        <img className={styles.bankImage} src={bank} alt="bank-img" />
        <Typography className={styles.bankDetails}>
          Above example shows a Bank setup. In this setup, it is made of 4 x Bays and 4 x Levels for the farm.
          <br/><br/>
          Each box will be indicated as a cage. For the orange colored cage, the label should be call 01-02-03 (Bank# - Bay# - Level#).
          <br/><br/>
          Admin can also configure the number of trays in each cage.
        </Typography>
      </Paper>
    </Card>
  )
}
