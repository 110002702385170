import { createSlice } from "@reduxjs/toolkit";

import { GetProductMaximumOrderPerDay, GetProductList } from "services/UserPortal/PlanningService";

// initial state
const initialState = {
  filter: {},
  orders: [],
  order: {},
  productList: []
};

const maximum = createSlice({
  name: "maximum",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.order[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetProductMaximumOrderPerDay.fulfilled, (state, action) => {
        state.orders = action.payload.result;
      })
      .addCase(GetProductList.fulfilled, (state, action) => {
        state.productList = action.payload.result;
      })
  },
});

// export actions
export const { setValues, updateRequest, reset } = maximum.actions;

// export the reducer
export default maximum.reducer;