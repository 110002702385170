import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { GetPlanningReport, GetAllOtherDevicesByWorkOrderId, GetAllRawMaterialReserved, GetAllEquipmentReserved, GetAllOtherDevicesReserved } from "services/UserPortal/PlanningService";

// initial state
const initialState = {
  filter: {},
  report: [],
  planningCount: 0,
  planning: [],
  excessCount: 0,
  excess: [],
  missingCount: 0,
  missing: [],
  rmCount: 0,
  rm: [],
  equipmentCount: 0,
  equipment: [],
  deviceCount: 0,
  device: [],
  rawMaterial: [],
  // lookup
  deviceList: []
};

const report = createSlice({
  name: "report",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetPlanningReport.fulfilled, (state, action) => {
        state.report = action.payload.result.map((item)=>{
          item.date = moment(item.date).format("YYYY-MM-DD");
          return item;
        });
      })
      .addCase(GetAllOtherDevicesByWorkOrderId.fulfilled, (state, action) => {
        state.deviceList = action.payload.result;
      })
      .addCase(GetAllRawMaterialReserved.fulfilled, (state, action) => {
        state.rmCount = action.payload.result.totalCount;
        state.rm = action.payload.result.items;
      })
      .addCase(GetAllEquipmentReserved.fulfilled, (state, action) => {
        state.equipmentCount = action.payload.result.totalCount;
        state.equipment = action.payload.result.items;
      })
      .addCase(GetAllOtherDevicesReserved.fulfilled, (state, action) => {
        state.deviceCount = action.payload.result.totalCount;
        state.device = action.payload.result.items;
      })
  },
});

// export actions
export const { setValues, reset } = report.actions;

// export the reducer
export default report.reducer;