import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import IconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';

import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { renderValue, filterParam, filterActionTableHead } from "common/functions";
import { setSearchText } from "store/general";
import { setValues } from "../store/maintenance";
import { maintenanceScheduleTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllEquipmentMaintenanceHistory, DeleteEquipmentMaintenance } from "services/UserPortal/EquipmentService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/equipment.module.scss";

import { useRouteCanWrite } from "hooks";

export default function MaintenanceSchedule() {
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const filter = useSelector(store => store.user.equipment.maintenance.filter);
  const totalCount = useSelector(store => store.user.equipment.maintenance.totalScheduleCount);
  const currentDateTime = useSelector(store => store.user.equipment.maintenance.currentDateTime);
  const schedules = useSelector(store => store.user.equipment.maintenance.schedules);
  const equipmentList = useSelector(store => store.common.equipmentList);
  const [newFilter, setNewFilter] = React.useState({equipmentId: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('equipmentRefNo');
  const [maintenanceId, setMaintenanceId] = React.useState(0);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_deleteTag = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const handleModal_delete = (id) => {
    setMaintenanceId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteEquipmentMaintenance(maintenanceId))
    .then(() => {
      getAllEquipmentMaintenanceHistory();
      resetState();
    });
  }

  const resetState = () => {
    dispatch(setValues({filter: {...filter, page: 0}}));
    setMaintenanceId(null);
    setOpenDeleteModal(false);
  }

  const getAllEquipmentMaintenanceHistory = (stopLoading) => {
    dispatch(GetAllEquipmentMaintenanceHistory({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllEquipmentMaintenanceHistory();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllEquipmentMaintenanceHistory(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        classes={{root: styles.cardPaper}}
        title = {"Information Correct as at " + moment(currentDateTime).format("DD/MM/yyyy - HH:mm")}
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "equipmentId") {
                const equipment = equipmentList.find(({id}) => id == filter[key]);
                label = equipment && equipment.equipmentName;
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={styles.tag}
                  onDelete={() => handleButtonClick_deleteTag(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the equipment list
                  <Select
                    name="equipmentId"
                    className={styles.filterDropdown}
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select an equipment"
                    value={renderValue(newFilter.equipmentId)}
                  >
                    { equipmentList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.equipmentName}</MenuItem>;
                    })}
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={styles.table}>
          <Table
            header={filterActionTableHead(maintenanceScheduleTableHead, canWrite)}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { schedules.map((item,index) => {
              const equipment = _.find(equipmentList, ({id}) => id === item.equipmentConfigId);
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{item.equipmentRefNo}</TableCell>
                    <TableCell>{item.equipmentName}</TableCell>
                    <TableCell>{moment(item.startDate).isAfter(minDate) ? moment(item.startDate).format("DD/MM/yyyy") : ""}</TableCell>
                    <TableCell>
                      {(moment(item.startDate).isAfter(minDate) && moment(item.endDate).isAfter(minDate)) 
                      ? moment(item.endDate).format("DD/MM/yyyy") 
                      : ""
                      }
                    </TableCell>
                    <TableCell>{item.remark}</TableCell>
                    {canWrite &&
                      <TableCell align="right">
                        <Link to={{pathname: "/user/equipment/maintenance-status/"+(item.id ? item.id : "create"), state: {equipment: equipment, id: item.id, isEdit: true}}}>
                          <IconButton type="edit" />
                        </Link>
                        <IconButton 
                          type="delete" 
                          onClick={() => handleModal_delete(item.id)}
                        />
                      </TableCell>
                    }
                  </TableRow>
                </React.Fragment>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
        <Modal
          open={openDeleteModal}
          onClose={() => handleModal_delete(null)}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this maintenance? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </Card>
    </React.Fragment>
  );
}
