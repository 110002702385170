import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
// core components
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { graphColors } from "assets/jss/palette";
import styles from "assets/jss/components/UserPortal/sustainability.module.scss";

export default function RECChart(props) {
  const results = useSelector(store => store.user.sustainability.chart.price);

  // const start = moment();
  // const end = moment().add(1, "year");
  // const months = [];

  // while (end.isSameOrAfter(start, 'month')) {
  //   months.push(start.format('MMM YYYY'));
  //   start.add(1, 'month');
  // }y.chart.conversion);

  const start = moment(props.date.startDate);
  const end = moment(props.date.endDate);
  const years = [];

  while (end.isSameOrAfter(start, 'year')) {
    years.push(start.year());
    start.add(1, 'year');
  }

  const handleOnChange_date = (value) => {
    if (value) {
      props.setDate({startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD")});
    }
  };

  const config = () => {
    return ({
      colors: graphColors,
      chart: {
        zoomType: 'x',
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: [{
        categories: years,
        crosshair: true
      }],
      yAxis: {
        title: {
          text: '/MWh',
        }
      },
      series: [{
        name: 'REC Utilisation',
        type: 'column',
        data: years.map((item, i) => {
          const value = results && results.find((val) => val && val.year === item);
          return {
            x: i,
            y: value && Number(value.rec ?? 0) * Number(value.value ?? 0),
            color: graphColors[0]
          };
        }),
        tooltip: {
          valueSuffix: '/MWh'
        }
        // results.rec && results.rec.map((item, i) => {
        //   return {
        //     x: i,
        //     y: item.value,
        //     color: graphColors[0]
        //   };
        // }),
      }, 
      // {
      //   name: 'Target REC Usage',
      //   type: 'line',
      //   data: [0]
      //   results.rec && results.rec.map((item, i) => {
      //     return {
      //       x: i,
      //       // y: item.pV_value,
      //       color: graphColors[1]
      //     };
      //   }),
      // }
      ],
    })
  };

  return (
    <React.Fragment>
      <div className={styles.action}>
        <DateRangePicker
          clearable
          className={styles.datepicker}
          placeholder="Date"
          value={{startDate: props.date.startDate, endDate: props.date.endDate}}
          onChange={(e) => handleOnChange_date(e)}
          onClear={()=>props.setDate({startDate: null, endDate: null})}
        />
      </div>
      <Typography className={styles.subtitle}>Please choose the year range, day and month are disregarded</Typography>
      <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: styles.highChart }} />
    </React.Fragment>
  );
}
