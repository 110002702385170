import axios from "axios";

import { setError, setLoading, setNoSnackbar } from "store/general";
import { FOMS_URL, AIBOT_URL, Energy_URL, AMR_URL } from "config";

/**
 * parse error response
 */
const parseError = (response) => {
  // console.log(response)
  // error
  if (response.error) {
    if (response.error.message) {
      return Promise.reject({ message: response.error.message });
      // return Promise.reject({ error: {message: response.error.message} })
    }
  } else {
    return Promise.reject({ error: {message: 'An error has occurred!'} });
  }
}

/**
 * parse response
 */
const parseBody = (response, store) => {
  // console.log(response);
  if (response.status === 200) {
    if (!response.config.isLoading && !response.config.stopLoading) {
      store.dispatch(setLoading(false));
    } 
    return response;
  } else {
    return this.parseError(response.data.messages);
  }
}

/**
 * axios instance
 */
export const FomsMS = axios.create({
  baseURL: FOMS_URL + "/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export const AIMS = axios.create({
  baseURL: AIBOT_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
});

export const EnergyMS = axios.create({
  baseURL: Energy_URL + "/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
});

export const AMRMS = axios.create({
  baseURL: AMR_URL + "/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
});

export const interceptor = (store) => {
  // request header
  FomsMS.interceptors.request.use((config) => {
    if (!config.stopLoading) {
      store.dispatch(setLoading(true));
    }
    const accessToken = localStorage.getItem('accessToken');
    config.headers = { 'Authorization': 'Bearer ' + accessToken };
    return config
  }, error => {
    return Promise.reject(error);
  }, null, { synchronous: true }) // synchronous fix loading call?

  // response parse
  FomsMS.interceptors.response.use((response) => {
    return parseBody(response, store);
  }, error => {
    console.warn('Error status', error.response.status);
    store.dispatch(setLoading(false));
    // return Promise.reject(error)
    if (error.response) { 
      if (error.response.status === 401) {
        store.dispatch(setError(error.response.status));
      } else if (error.response.data.error.message !== "An internal error occurred during your request!" && !error.response.config.url.includes("TokenAuth")) { 
        store.dispatch(setNoSnackbar(Boolean(error.response.config.isNoSnackbar)));
        store.dispatch(setError(error.response.data.error.message));
      }
      return parseError(error.response.data);
    } else {
      return Promise.reject(error);
    }
  })
  
  // request header
  AIMS.interceptors.request.use((config) => {
    if (!config.stopLoading) {
      store.dispatch(setLoading(true));
    }
    return config
  }, error => {
    return Promise.reject(error);
  })

  // response parse
  AIMS.interceptors.response.use((response) => {
    return parseBody(response, store);
  }, error => {
    store.dispatch(setLoading(false));
    // return Promise.reject(error)
    if (error.response) { 
      console.warn('Error status', error.response.status);
      if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch(setError(error.response.status));
      } else if (error.response.data.error.message !== "An internal error occurred during your request!" && !error.response.config.url.includes("TokenAuth")) { 
        store.dispatch(setNoSnackbar(Boolean(error.response.config.isNoSnackbar)));
        store.dispatch(setError(error.response.data.error.message));
      }
      return parseError(error.response.data);
    } else {
      return Promise.reject(error);
    }
  })

  // request header
  EnergyMS.interceptors.request.use((config) => {
    if (!config.stopLoading) {
      store.dispatch(setLoading(true));
    }
    const accessToken = localStorage.getItem('accessToken');
    config.headers = { 'Authorization': 'Bearer ' + accessToken };
    return config
  }, error => {
    return Promise.reject(error);
  }, null, { synchronous: true }) // synchronous fix loading call?

  // response parse
  EnergyMS.interceptors.response.use((response) => {
    return parseBody(response, store);
  }, error => {
    console.warn('Error status', error.response.status);
    store.dispatch(setLoading(false));
    // return Promise.reject(error)
    if (error.response) { 
      if (error.response.status === 401) {
        store.dispatch(setError(error.response.status));
      } else if (error.response.data.error.message !== "An internal error occurred during your request!" && !error.response.config.url.includes("TokenAuth")) { 
        store.dispatch(setNoSnackbar(Boolean(error.response.config.isNoSnackbar)));
        store.dispatch(setError(error.response.data.error.message));
      }
      return parseError(error.response.data);
    } else {
      return Promise.reject(error);
    }
  })

  // request header
  AMRMS.interceptors.request.use((config) => {
    if (!config.stopLoading) {
      store.dispatch(setLoading(true));
    }
    const accessToken = localStorage.getItem('accessToken');
    config.headers = { 'Authorization': 'Bearer ' + accessToken };
    return config
  }, error => {
    return Promise.reject(error);
  }, null, { synchronous: true }) // synchronous fix loading call?

  // response parse
  AMRMS.interceptors.response.use((response) => {
    return parseBody(response, store);
  }, error => {
    console.warn('Error status', error.response.status);
    store.dispatch(setLoading(false));
    // return Promise.reject(error)
    if (error.response) { 
      if (error.response.status === 401) {
        store.dispatch(setError(error.response.status));
      } else if (error.response.data.error.message !== "An internal error occurred during your request!" && !error.response.config.url.includes("TokenAuth")) { 
        store.dispatch(setNoSnackbar(Boolean(error.response.config.isNoSnackbar)));
        store.dispatch(setError(error.response.data.error.message));
      }
      return parseError(error.response.data);
    } else {
      return Promise.reject(error);
    }
  })
}