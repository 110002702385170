// Add Equipment
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "shared-components/Checkbox/Checkbox";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Radio from 'shared-components/Radio/Radio';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { configureEquipmentTableHead } from "enums/AdminPortal/TableHeaders";
import { setValues, updateConfigRequest } from ".";
import { GetLibraryList, GetFunctionById, ConfigureDeviceFunction, GetAllUOM } from "services/AdminPortal/EquipmentService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/equipment.module.scss";

export default function ConfigureEquipment() {
  let history = useHistory();
  const dispatch = useDispatch();
  const config = useSelector(store => store.admin.equipment.config);
  const libraries = useSelector(store => store.admin.equipment.libraries);
  const functions = useSelector(store => store.admin.equipment.functions);
  const uom = useSelector(store => store.admin.equipment.uom);
  const [libraryId, setLibraryId] = React.useState(null);
  const [selectedLibraryId, setSelectedLibraryId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleOnChange_select = (e, id) => {
    let value = e.target.value;
    if (e.target.name === "uom") {
      let payload = _.cloneDeep(config);
      let library = _.find(payload, (lib) => lib.libraryId === selectedLibraryId) || {configuredFunctionList: []};
      let index = _.findIndex(library.configuredFunctionList, (func) => func.functionId === id);
      library.configuredFunctionList[index] = {...library.configuredFunctionList[index], [e.target.name]: value};
      if (!library.libraryId) {
        library.libraryId = selectedLibraryId;
        payload = [...payload, library];
      } else {
        payload = payload.map((item) => {
          if (item.libraryId === selectedLibraryId) {
            return library
          }
          return item;
        })
      }
      dispatch(updateConfigRequest(payload));
    } else {
      setSelectedLibraryId(Number(value));
      dispatch(GetFunctionById([value]))
      .then(({payload}) => {
        if (payload.result) {
          dispatch(setValues({functions: payload.result}));
        }
      });
    }
  };

  const handleOnChange_checkbox = (e) => {
    const {id, value} = e.target;
    let payload = _.cloneDeep(config);
    let library = _.find(payload, (libObj) => libObj.libraryId === selectedLibraryId) || {configuredFunctionList: []};
    let index = _.findIndex(library.configuredFunctionList, (func) => func.functionId === Number(value));
    if (index === -1) {  // function id does not exists
      library.configuredFunctionList = [...library.configuredFunctionList, {functionId: Number(value)}];
      index = library.configuredFunctionList.length-1;
    }
    library.configuredFunctionList[index] = {...library.configuredFunctionList[index], [id]: library.configuredFunctionList[index] ? !library.configuredFunctionList[index][id] : true};
    if (id === "isRequireConfig" && !library.configuredFunctionList[index][id]) {  // uncheck involvePackaging
      library.configuredFunctionList.splice(index, 1);
    }
    if (!library.libraryId) {
      library.libraryId = selectedLibraryId;
      payload = [...payload, library];
    } else {
      payload = payload.map((item) => {
        if (item.libraryId === selectedLibraryId) {
          return library
        }
        return item;
      })
    }
    dispatch(updateConfigRequest(payload));
    // if one is checked, add isConfigured: true
    // if all is unchecked, add isConfigured: false
    let lib = _.cloneDeep(libraries);
    lib = lib.map((libObj) => {
      if (libObj.id === selectedLibraryId) {
        return {...libObj, isConfigured: true}
      }
      return libObj
    });
    dispatch(setValues({libraries: lib}));
  };

  const handleModal = (id) => {
    setLibraryId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    let payload = _.cloneDeep(config);
    payload = payload.filter((item) => item.libraryId !== libraryId);
    let lib = _.cloneDeep(libraries);
    lib = lib.map((library) => {
      if (library.id === libraryId) {
        return {...library, isConfigured: false}
      }
      return library
    });
    dispatch(setValues({config: payload, libraries: lib}));
    setOpenModal(!openModal);
  }

  const handleButtonClick_save = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      let payload = {libraryIdList: [], configuredFunctionList: []};
      config.map((item) => {
        payload.libraryIdList = payload.libraryIdList ? _.union(payload.libraryIdList, [item.libraryId]) : [item.libraryId];
        payload.configuredFunctionList = payload.configuredFunctionList ? _.union(payload.configuredFunctionList, item.configuredFunctionList) : item.configuredFunctionList
      })
      dispatch(ConfigureDeviceFunction(payload))
      .then(({error}) => {
        if (error) {
          dispatch(setDirty(true));
        } else {
          history.push("/admin/equipment");
        }
      });
    })
  };
  
  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setDirty(true));
    dispatch(GetAllUOM())
    dispatch(GetLibraryList())
    .then((response) => {
      if (response.payload.result) {
        const configuredList = response.payload.result.filter((item)=>item.isConfigured).map((item) => item.id);
        if (configuredList.length) {
          dispatch(GetFunctionById(configuredList))
          .then(({payload}) => {
            if (payload.result) {
              let configuredPayload = [];
              payload.result.map((func) => {
                if (func) {
                  let value = {libraryId: func.functionLibraryId, configuredFunctionList: []};
                  func.functionNameList.map((item) => {
                    if (item.requiredConfig) {
                      value.configuredFunctionList = _.union(value.configuredFunctionList, [{functionId: item.id, isRequireConfig: item.requiredConfig, isInvolvePackage: item.involvePackage, uom: item.uom}]);
                    }
                  })
                  configuredPayload = [...configuredPayload, value];
                }
              })
              dispatch(setValues({config: configuredPayload}));
            }
          })
        }
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Configure Equipment of Farm" 
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>history.push("/admin/equipment")}
            >
              Go Back
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <div className={styles.content}>
          <div className={clsx(styles.subContent, styles.leftContent)}>
            <Paper elevation={0} className={styles.newPaper} >
              <Select
                label="Device Library"
                className={styles.select}
                name="deviceLibrary"
                onChange={(e)=>handleOnChange_select(e)}
                value={selectedLibraryId && _.find(libraries, (lib) => lib.id === selectedLibraryId && !lib.isConfigured) ? selectedLibraryId : ""}
                placeholder="Select a Device Library"
              >
                { libraries.map((item) => {
                  if (!item.isConfigured) {
                    return (
                      <MenuItem key={item.id} value={item.id}>{item.functionLibraryName}</MenuItem>
                    )
                  }
                })} 
              </Select>
              { libraries.map((item) => {
                if (item.isConfigured) {
                  return (
                    <div key={item.id}>
                      <Radio 
                        checked={Number(selectedLibraryId) === item.id}
                        onChange={(e)=>handleOnChange_select(e)}
                        value={item.id}
                        name="deviceLibrary"
                      />
                      {item.functionLibraryName}
                      <IconButton
                        type="delete"
                        className="float-right" 
                        onClick={() => handleModal(item.id)}
                      />
                    </div>
                  )
                }
              })}
            </Paper>
          </div>
          <div className={styles.subContent}>
            <Card
              classes={{
                root: styles.cardPaper,
                title: styles.cardTitle,
                content: styles.cardContent
              }}
              title="Functions in Library"
            >
              <Table
                stickyHeader
                className={clsx(styles.table, styles.funcTable)}
                header={configureEquipmentTableHead}
              >
                { functions.map((funct) => {
                  return funct.functionNameList.map((item, index) => {
                    const library = _.find(config, (lib) => lib.libraryId === selectedLibraryId) || {configuredFunctionList: []};
                    const configuration = _.find(library.configuredFunctionList, (func) => func.functionId === Number(item.id));
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.functionName}</TableCell>
                        <TableCell align="center">{item.shortForm}</TableCell>
                        <TableCell align="center">
                          <Checkbox 
                            id="isRequireConfig"
                            value={item.id} 
                            checked={Boolean(configuration && configuration.isRequireConfig)}
                            onChange={(e) => handleOnChange_checkbox(e)}
                          />
                        </TableCell>
                        <TableCell align="center" className={styles.dropdown}>
                          <Select
                            className={"text-left"}
                            name="uom"
                            onChange={(e)=>handleOnChange_select(e, item.id)}
                            value={configuration && configuration.uom ? configuration.uom : ""}
                            placeholder="Select unit of measurement"
                            disabled={!(configuration && configuration.isRequireConfig)}
                          >
                            {uom.map((obj, i) => {
                              return <MenuItem key={i} value={obj}>{obj}</MenuItem>;
                            })} 
                          </Select>
                        </TableCell>
                      </TableRow>
                    )
                  })
                })}
              </Table>
            </Card>
          </div>
        </div>
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this library configuration? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
