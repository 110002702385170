import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";
import { rowsPerTable } from "config";

import {FomsMS} from "../index";

export const GetDeviceList = createAsyncThunk(
  "flow/getDeviceList", 
  async ({signal, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * 10,
      MaxResultCount: param.maxResultCount ? param.maxResultCount : 10,
      IsControlDevice: true,
    }
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetDeviceList", {params, signal, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllWaterFlowControl = createAsyncThunk(
  "flow/getAllWaterFlowControl", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      FarmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/ClimateEnvironment/GetAllWaterflowcontrol", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExecuteWaterFlowControl = createAsyncThunk(
  "flow/ExecuteWaterFlowControl", 
  async (_, {getState, rejectWithValue}) => {
    let body = getState().user.flowControl.overview.flowControl;
    try {
      const response = await FomsMS.post("/userportal/ClimateEnvironment/ExecuteWaterFlowControl", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CancelWaterFlowControl = createAsyncThunk(
  "flow/cancelWaterFlowControl", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/userportal/ClimateEnvironment/CancelWaterFlowControl", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);