import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";
import { rowsPerTable } from "config";

import {FomsMS} from "../index";

// lookup
export const GetAllASRSLocation = createAsyncThunk(
  "integration/GetAllASRSLocation", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * 10,
      maxResultCount: param.maxResultCount || 10
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetAllASRSLocation", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllCages = createAsyncThunk(
  "integration/GetAllCages", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetCageList", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetLocationID = createAsyncThunk(
  "integration/GetLocationID", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetLocationID", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//onboarding
export const GetAllASRSOnboardingList = createAsyncThunk(
  "integration/GetAllASRSOnboardingList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const params = {
      ...param,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetAllASRSOnboardingList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllCurrentListingList = createAsyncThunk(
  "integration/GetAllCurrentListingList", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const params = {
      ...param,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetAllASRSCurrentList", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteASRSOnboardingRecord = createAsyncThunk(
  "integration/DeleteASRSOnboardingRecord", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        id: id,
      };
      const response = await FomsMS.delete("/integration/DaifukuIntegration/DeleteASRSOnboardingRecord", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateASRSOnboardingRecord = createAsyncThunk(
  "integration/CreateOrUpdateASRSOnboardingRecord", 
  async (param, {rejectWithValue}) => {
    const body = {
      ...param,
    };
    try {
      const response = await FomsMS.post("/integration/DaifukuIntegration/CreateOrUpdateASRSOnboardingRecord", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DownloadASRSOnboardingTemplate = createAsyncThunk(
  "integration/DownloadASRSOnboardingTemplate", 
  async (_, {rejectWithValue}) => {
    const config = {
      responseType: 'blob',
      stopLoading: true 
    }
    try {
      const response = await FomsMS.post("/integration/DaifukuIntegration/DownloadASRSOnboardingTemplate", null, config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UploadASRSOnboarding = createAsyncThunk(
  "integration/UploadASRSOnboarding", 
  async (file, {rejectWithValue}) => {
    let body = new FormData();
    body.append("Data", file);
    try {
      const response = await FomsMS.post("/integration/DaifukuIntegration/UploadASRSOnboarding", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const SaveASRSOnboardingList = createAsyncThunk(
  "integration/SaveASRSOnboardingList", 
  async (_, {getState, rejectWithValue}) => {
    const body = {
      asrsOnboardingList: getState().user.asrs.currentListings.batchResult,
    };
    try {
      const response = await FomsMS.post("/integration/DaifukuIntegration/SaveASRSOnboardingList", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExportVIFSCurrentListings = createAsyncThunk(
  "integration/ExportVIFSCurrentListings", 
  async (_, {rejectWithValue}) => {
    const config = {
      responseType: 'blob',
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/ExportVIFSCurrentListings", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Maintenance
export const GetAllASRSMaintenanceMasterList = createAsyncThunk(
  "integration/GetAllASRSMaintenanceMasterList", 
  async (param, {rejectWithValue}) => {
    const params = {
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable,
      ...param
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetAllASRSMaintenanceMasterList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetASRSMaintenanceMaster = createAsyncThunk(
  "integration/GetASRSMaintenanceMaster", 
  async (param, {rejectWithValue}) => {
    const params = {
      id: param
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetASRSMaintenanceMaster", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteASRSMaintenanceMaster = createAsyncThunk(
  "integration/DeleteASRSMaintenanceMaster", 
  async (id, {rejectWithValue}) => {
    const params = {
      id: id,
    };
    try {
      const response = await FomsMS.delete("/integration/DaifukuIntegration/DeleteASRSMaintenanceMaster", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateASRSMaintenanceStatus = createAsyncThunk(
  "integration/UpdateASRSMaintenanceStatus", 
  async (id, {rejectWithValue}) => {
    const params = {
      id: id
    };
    try {
      const response = await FomsMS.patch("/integration/DaifukuIntegration/UpdateASRSMaintenanceStatus", null, {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateASRSMaintenance = createAsyncThunk(
  "Operation/CreateOrUpdateASRSMaintenance", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/integration/DaifukuIntegration/CreateOrUpdateASRSMaintenance", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Movement
export const GetAllASRSMovementMasterList = createAsyncThunk(
  "integration/GetAllASRSMovementMasterList", 
  async (param, {rejectWithValue}) => {
    const params = {
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount || rowsPerTable,
      ...param
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetAllASRSMovementMasterList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetASRSMovementMaster = createAsyncThunk(
  "integration/GetASRSMovementMaster", 
  async (param, {rejectWithValue}) => {
    const params = {
      id: param
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetASRSMovementMaster", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteASRSMovement = createAsyncThunk(
  "integration/DeleteASRSMovement", 
  async (id, {rejectWithValue}) => {
    const params = {
      id: id,
    };
    try {
      const response = await FomsMS.delete("/integration/DaifukuIntegration/DeleteASRSMovement", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdateASRSMovementStatus = createAsyncThunk(
  "integration/UpdateASRSMovementStatus", 
  async (id, {rejectWithValue}) => {
    const params = {
      id: id
    };
    try {
      const response = await FomsMS.patch("/integration/DaifukuIntegration/UpdateASRSMovementStatus", null, {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateASRSMovementMaster = createAsyncThunk(
  "integration/CreateOrUpdateASRSMovement", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/integration/DaifukuIntegration/CreateOrUpdateASRSMovementMaster", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetLocationIDListByCageId = createAsyncThunk(
  "integration/GetLocationIDListByCageId", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetLocationIDListByCageId", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCageByLocationId = createAsyncThunk(
  "integration/GetCageByLocationId", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param
    }
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetCageList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//softzone
export const GetAllASRSSoftzone = createAsyncThunk(
  "integration/GetAllASRSSoftzone", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetAllASRSSoftzone", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//station
export const GetAllASRSStationStatusList = createAsyncThunk(
  "integration/GetAllASRSStationStatusList", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * 10,
      MaxResultCount: 10
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetAllASRSStationStatusList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteASRSStationStatus = createAsyncThunk(
  "integration/DeleteASRSStationStatus", 
  async (id, {rejectWithValue}) => {
    const params = {
      id
    };
    try {
      const response = await FomsMS.delete("/integration/DaifukuIntegration/DeleteASRSStationStatus", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const StartASRSStation = createAsyncThunk(
  "integration/StartASRSStation", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/StartASRSStation", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//filter
export const GetASRSMaintenanceStatus = createAsyncThunk(
  "integration/GetASRSMaintenanceStatus", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetASRSMaintenanceStatus", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//settings
export const GetASRSCageBatchSetting = createAsyncThunk(
  "integration/GetASRSCageBatchSetting", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/integration/DaifukuIntegration/GetASRSCageBatchSetting", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateASRSCageBatchSetting = createAsyncThunk(
  "integration/CreateOrUpdateASRSCageBatchSetting", 
  async (_, {getState, rejectWithValue}) => {
    const body = {
      ...getState().user.asrs.settings.setting,
    };
    try {
      const response = await FomsMS.post("/integration/DaifukuIntegration/CreateOrUpdateASRSCageBatchSetting", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);