import { combineReducers } from "redux";
import planningOutcome from "./planningOutcome";
import conversion from "./conversion";
import adjustment from "./adjustment";
import unassigned from "./unassigned";
import maximum from "./maximum";
import report from "./report";
import settings from "./settings";
import search from "./search";

const planningReducer = combineReducers({
  planningOutcome,
  conversion,
  adjustment,
  unassigned,
  maximum,
  report,
  settings,
  search,
});

export default planningReducer;