import { createSlice } from "@reduxjs/toolkit";

import { GetCompanyDashboard } from "services/AdminPortal/CompanyService";

// initial state
const initialState = {
  companyId: "",
  totalEmployee: 0,
  totalFarm: 0,
};

// create reducer and action creators
const home = createSlice({
  name: "home",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCompanyDashboard.fulfilled, (state, action) => {
        const result = action.payload.result;
        state.companyId = result.companyId;
        state.totalEmployee = result.totalEmployee;
        state.totalFarm = result.totalFarm;
      })
  }
});

// export actions
export const { setCompanyDashboard, reset } = home.actions;

// export the reducer
export default home.reducer;