// Raw Materials
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
import { Link } from "react-router-dom";
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import Pagination from "shared-components/Table/Pagination";
import CustomIconButton from "shared-components/Button/IconButton";

import Empty from "error-page/Empty";
import { GetAllRawMaterial, DeleteRawMaterial } from "services/AdminPortal/RawMaterialService";
import { reset } from "./";
import { setSearchText } from "store/general";

import { formatNumbers, filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { rawMaterialTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/rawMaterial.module.scss";
import arrow from "assets/icons/orange/droplist-arrow.svg";

import { useRouteCanWrite } from "hooks";

export default function RawMaterial() {
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const finishRawMaterial = useSelector(store => store.admin.rawMaterial.rawMaterialFinish);
  const unfinishRawMaterial = useSelector(store => store.admin.rawMaterial.rawMaterialUnfinish);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const [order, setOrder] = React.useState({finished: 'asc', unfinished: 'asc'});
  const [orderBy, setOrderBy] = React.useState({finished: 'rawMaterialRefNo', unfinished: 'rawMaterialRefNo'});
  const [page, setPage] = React.useState({finished: 0, unfinished: 0});
  const [selectedId, setSelectedId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);

  const finishCount = Math.ceil(finishRawMaterial.length / rowsPerTable);
  const unfinishCount = Math.ceil(unfinishRawMaterial.length / rowsPerTable);

  const handleRequestSortFinished = (e, property) => {
    const isAsc = orderBy.finished === property && order.finished === 'asc';
    setOrder({...order, finished: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, finished: property});
  };

  const handleRequestSortUnfinished = (e, property) => {
    const isAsc = orderBy.unfinished === property && order.unfinished === 'asc';
    setOrder({...order, unfinished: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, unfinished: property});
  };

  const handleModal = (id) => {
    setSelectedId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteRawMaterial(selectedId))
    .then(() => {
      dispatch(GetAllRawMaterial());
      setPage({finished: 0, unfinished: 0});
      setOpenModal(!openModal);
    })
  }

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllRawMaterial());
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      { finishRawMaterial.length > 0 || unfinishRawMaterial.length > 0 
      ?<React.Fragment>
        <Card 
          classes={{
            root: styles.topCard
          }}
          title = "Raw Material List"
        >
          {/* Finished Raw Materials */}
          <Table
            className={styles.table}
            header={filterActionTableHead(rawMaterialTableHead, canWrite)}
            order={order.finished}
            orderBy={orderBy.finished}
            onRequestSort={handleRequestSortFinished}
          >
            { _.orderBy(finishRawMaterial, [orderBy.finished], [order.finished])
            .slice(page.finished * rowsPerTable, page.finished * rowsPerTable + rowsPerTable)
            .map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.rawMaterialRefNo ? item.rawMaterialRefNo : ""}</TableCell>
                  <TableCell>{item.rawMaterialName ? item.rawMaterialName : ""}</TableCell>
                  <TableCell>{item.rawMaterialDescription ? item.rawMaterialDescription : ""}</TableCell>
                  <TableCell>{item.unitOfMeasurement ? item.unitOfMeasurement : ""}</TableCell>
                  <TableCell>{item.safetyLevel ? formatNumbers(item.safetyLevel) : ""}</TableCell>
                  <TableCell>{item.currentQty ? formatNumbers(item.currentQty) : ""}</TableCell>
                  <TableCell>{item.requestLeadTime ? formatNumbers(item.requestLeadTime) : ""}</TableCell>
                  <TableCell align="right">
                    {canWrite && (item.rawMaterialRefNo !== "RM-IN-WW") &&
                      <React.Fragment>
                        <Link to={{pathname: "/admin/raw-material/"+item.id}}>
                          <CustomIconButton type="edit" />
                        </Link>
                        <CustomIconButton
                          type="delete" 
                          onClick={() => handleModal(item.id)}
                        />
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
                )
            })}
          </Table>
          { finishCount > 1 &&
            <Pagination 
              count={finishCount} 
              page={page.finished+1}
              onChange={(e, pageNo)=>setPage({...page, finished: pageNo-1})}
            />
          }
        </Card>
        {/* Unfinished Raw Materials */}
        { unfinishRawMaterial.length > 0 &&
          <Card
            title = {
              <React.Fragment>
                  Unfinished Raw Material List
                  <IconButton 
                    className={collapsed ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    <img className={styles.icon} src={arrow} alt="arrow" />
                  </IconButton>
                </React.Fragment>
            }
          >
            { !collapsed && 
              <React.Fragment>
                <Table
                  className={styles.table}
                  header={filterActionTableHead(rawMaterialTableHead, canWrite)}
                  order={order.unfinished}
                  orderBy={orderBy.unfinished}
                  onRequestSort={handleRequestSortUnfinished}
                >
                  { _.orderBy(unfinishRawMaterial, [orderBy.unfinished], [order.unfinished])
                  .slice(page.unfinished * rowsPerTable, page.unfinished * rowsPerTable + rowsPerTable)
                  .map((item) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>{item.rawMaterialRefNo ? item.rawMaterialRefNo : ""}</TableCell>
                        <TableCell>{item.rawMaterialName ? item.rawMaterialName : ""}</TableCell>
                        <TableCell>{item.rawMaterialDescription ? item.rawMaterialDescription : ""}</TableCell>
                        <TableCell>{item.unitOfMeasurement ? item.unitOfMeasurement : ""}</TableCell>
                        <TableCell>{item.safetyLevel ? formatNumbers(item.safetyLevel) : ""}</TableCell>
                        <TableCell>{item.currentQty ? formatNumbers(item.currentQty) : ""}</TableCell>
                        <TableCell>{item.requestLeadTime ? formatNumbers(item.requestLeadTime) : ""}</TableCell>
                        <TableCell align="right">
                          {canWrite &&
                            <React.Fragment>
                              <Link to={{pathname: "/admin/raw-material/"+item.id}}>
                                <CustomIconButton type="edit" />
                              </Link>
                              <CustomIconButton
                                type="delete" 
                                onClick={() => handleModal(item.id)}
                              />
                            </React.Fragment>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
                { unfinishCount > 1 &&
                  <Pagination 
                    count={unfinishCount} 
                    page={page.unfinished+1}
                    onChange={(e, pageNo)=>setPage({...page, unfinished: pageNo-1})}
                  />
                }
              </React.Fragment>
            }
          </Card>
        }
        <Modal
          open={openModal}
          onClose={() => handleModal(null)}
          icon={<img className={styles.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this raw material? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={styles.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
              <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
      :<React.Fragment>
        <Empty 
          description={"Your page is empty. " + (canWrite ? "Add a raw material to get started!" : "")}
        />
      </React.Fragment>
      }
    </React.Fragment>
  );
}
