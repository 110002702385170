import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

export const GetAllProducts = createAsyncThunk(
  "product/getAllProducts", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      MaxResultCount: 9999
    }
    try {
      const response = await FomsMS.get("/Product/GetAllProducts", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteProduct = createAsyncThunk(
  "Product/deleteProduct", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/Product/DeleteProduct", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductById = createAsyncThunk(
  "Product/getProductById", 
  async (params, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Product/GetProductById", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductProcessByProductId = createAsyncThunk( // get latest process
  "Product/getProductProcessByProductId", 
  async (id, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      productID: id
    }
    try {
      const response = await FomsMS.get("/Product/GetProductProcessByProductId", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllProductCategory = createAsyncThunk(
  "product/getAllProductCategory", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Product/GetAllProductCategory");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateProductCategory = createAsyncThunk(
  "product/createOrUpdateProductCategory", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/Product/CreateOrUpdateProductCategory", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteProductCategory = createAsyncThunk(
  "product/deleteProductCategory", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id};
      const response = await FomsMS.delete("/Product/DeleteProductCategory", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductCategoryByID = createAsyncThunk(
  "Product/getProductCategoryByID", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        ID: id
      }
      const response = await FomsMS.get("/Product/GetProductCategoryByID", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GenerateProductId = createAsyncThunk(
  "Product/generateProductId", 
  async (data, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/Product/GenerateProductId", data, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)

export const GetProductGroupByCategoryID = createAsyncThunk(
  "product/GetProductGroupByCategoryID", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        productCategoryID: id
      }
      const response = await FomsMS.get("/Product/GetProductGroupByCategoryID", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateProductGroup = createAsyncThunk(
  "product/createOrUpdateProductGroup", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/Product/CreateOrUpdateProductGroup", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteProductGroup = createAsyncThunk(
  "product/deleteProductGroup", 
  async (params, {rejectWithValue}) => {
    try {
      const response = await FomsMS.delete("/Product/DeleteProductGroup", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductGroup = createAsyncThunk(
  "product/getProductGroup", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        productGroupId: id
      }
      const response = await FomsMS.get("/Product/GetProductGroup", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductCategoryTypeByID = createAsyncThunk(
  "product/getProductCategoryTypeByID", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {id}
      const response = await FomsMS.get("/Product/GetProductCategoryTypeByID", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateProductCategoryType = createAsyncThunk(
  "product/createOrUpdateProductCategoryType", 
  async (body, {rejectWithValue}) => {
    try {
      const response = await FomsMS.post("/Product/CreateOrUpdateProductCategoryType", body, {isNoSnackbar: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteProductCategoryType = createAsyncThunk(
  "product/deleteProductCategoryType", 
  async (params, {rejectWithValue}) => {
    try {
      const response = await FomsMS.delete("/Product/DeleteProductCategoryType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllPackagingType = createAsyncThunk(
  "product/getAllPackagingType", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
      SkipCount: (param.page) * rowsPerTable,
      MaxResultCount: param.maxResultCount ? param.maxResultCount : rowsPerTable
    }
    try {
      const response = await FomsMS.get("/Product/GetAllPackagingType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdatePackagingType = createAsyncThunk(
  "product/createOrUpdatePackagingType", 
  async (_, {getState, rejectWithValue}) => {
    const data = getState().admin.product.packageSettings.package;
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const body = {
      ...data,
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.post("/Product/CreateOrUpdatePackagingType", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)

export const DeletePackagingType = createAsyncThunk(
  "product/deletePackagingType", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {packagingTypeId: id};
      const response = await FomsMS.delete("/Product/DeletePackagingType", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProcessMasterList = createAsyncThunk(
  "product/getProcessMasterList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Product/GetProcessMasterList");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductRawMaterial = createAsyncThunk(
  "product/GetProductRawMaterial", 
  async (type, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      isPreHarvest: type == "productPreHarvestGrowthProcess" ? true : false
    }
    try {
      const response = await FomsMS.get("/Product/GetProductRawMaterial", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductEquipment = createAsyncThunk(
  "product/GetProductEquipment", 
  async (type, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      isPreHarvest: type == "productPreHarvestGrowthProcess" ? true : false
    }
    try {
      const response = await FomsMS.get("/Product/GetProductEquipment", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetUserList = createAsyncThunk(
  "product/getUserList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Product/GetUserList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipmentList = createAsyncThunk(
  "product/getEquipmentList", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Product/GetEquipmentList", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetEquipmentTaskTypeList = createAsyncThunk(
  "product/getEquipmentTaskTypeList", 
  async (equipmentName, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      equipmentName,
      FarmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.get("/Product/GetEquipmentTaskTypeList", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateProduct = createAsyncThunk(
  "Product/createOrUpdateProduct", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const data = getState().admin.product.product.selectedProduct;
    const preHarvest = getState().admin.product.product.productPreHarvestGrowthProcess;
    const postHarvest = getState().admin.product.product.productPostHarvestGrowthProcess;
    const body = {
      ...data,
      productPreHarvestGrowthProcess: preHarvest,
      productPostHarvestGrowthProcess: postHarvest,
      farmId: find(userDetail.farms, "isActive").id
    }
    try {
      const response = await FomsMS.post("/Product/CreateOrUpdateProduct", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
)

export const SearchProduct = createAsyncThunk(
  "product/searchProduct", 
  async (Keyword, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
      Keyword,
      MaxResultCount: 9999
    }
    try {
      const response = await FomsMS.get("/Product/Search", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllProductGroup = createAsyncThunk(
  "product/getAllProductGroup", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Product/GetAllProductGroup");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const IsProductNameExist = createAsyncThunk(
  "Product/isProductNameExist", 
  async (data, {rejectWithValue}) => {
    try {
      const params = {
        productName : data.productName,
        id: data.id
      };
      const response = await FomsMS.get("/Product/IsProductNameExist", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetFinishedProductForMix = createAsyncThunk(
  "product/getFinishedProductForMix", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      FarmId: find(userDetail.farms, "isActive").id,
    }
    try {
      const response = await FomsMS.get("/Product/GetFinishedProductForMix", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductPreHarvestMixProcess = createAsyncThunk(
  "product/getProductPreHarvestMixProcess", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        productCategoryTypeID: id
      }
      const response = await FomsMS.get("/Product/GetProductPreHarvestMixProcess", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetProductVersionsByProductId = createAsyncThunk(
  "product/getProductVersionsByProductId", 
  async (id, {rejectWithValue}) => {
    try {
      const params = {
        productID: id
      }
      const response = await FomsMS.get("/Product/GetProductVersionsByProductId", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTaskTypeList = createAsyncThunk(
  "product/getTaskTypeList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/Product/GetTaskTypeList");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);