import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from 'shared-components/TextField/TextField';
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";
import Radio from 'shared-components/Radio/Radio';

import { daysOfWeek } from "enums/Time";
import { renderValue, roundTo2Decimal } from "common/functions";
import { isValidPrice } from "common/validations";
import { setDirty, setSuccess } from "store/general";
import { updateProductRequest, updatePackageRequest } from "../store/management";
import { GetProductList, GetPackageList, GetAllUnitOfMeasurement, GetTotalWeight, CreateOrUpdateCustomerOrder } from "services/UserPortal/CustomerOrderService";

import alert from "assets/icons/orange/alert-line.svg";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/salesOrder.module.scss";

export default function ProductList(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const order = useSelector(store => store.user.customerOrder.management.customerOrder);
  const subSaleOrders = useSelector(store => store.user.customerOrder.management.subSaleOrders);
  const productList = useSelector(store => store.user.customerOrder.management.productList);
  const packageList = useSelector(store => store.user.customerOrder.management.packageList);
  const uomList = useSelector(store => store.user.customerOrder.management.uomList);
  const [collapsed, setCollapsed] = React.useState(false);
  const [productIndex, setProductIndex] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "management");
  
  const handleOnChange_text = (e, index) => {
    const key = e.target.id.replace(/\d/g, '');
    let payload = _.cloneDeep(subSaleOrders);
    payload[index][key] = e.target.value;
    dispatch(updateProductRequest(payload));
    if (key==="quantity") {
      getTotalWeight(payload[index], index);
    }
  };

  const handleOnChange_select = (e, index) => {
    const key = e.target.name.replace(/\d/g, '');
    let payload = _.cloneDeep(subSaleOrders);
    if (key === "productId") {
      dispatch(GetPackageList(e.target.value))
      .then((response) => {
        let packagePayload = _.cloneDeep(packageList);
        packagePayload[index] = response.payload.result;
        dispatch(updatePackageRequest(packagePayload));
      });
      payload[index].packageId = null;
    }
    payload[index][key] = e.target.value;
    dispatch(updateProductRequest(payload));
    if (key==="packageId" || key==="uom") {
      getTotalWeight(payload[index], index);
    }
  };

  const handleButtonClick_radio = (e, index) => {
    const key = e.target.id.replace(/\d/g, '');
    let payload = _.cloneDeep(subSaleOrders);
    payload[index][key] = !payload[index][key];
    dispatch(updateProductRequest(payload));
  };

  const handleModal_delete = (index) => {
    setProductIndex(index);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    setOpenDeleteModal(!openDeleteModal);
    let payload = _.cloneDeep(subSaleOrders);
    payload.splice(productIndex, 1);
    dispatch(updateProductRequest(payload));
    // update package dropdown
    let packagePayload = _.cloneDeep(packageList);
    packagePayload.splice(productIndex, 1);
    dispatch(updatePackageRequest(packagePayload));
  };

  const handleButtonClick_add = () => {
    dispatch(updateProductRequest([...subSaleOrders, {isWeightOrder: false}]));
    dispatch(updatePackageRequest([...packageList, []])); // update package dropdown
  };

  const handleButtonClick_save = () => {
    if (props.validateFields() && validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        let id = path[index+1];
        if (id === "create") {
          id = 0;
        }
        dispatch(CreateOrUpdateCustomerOrder(id))
        .then((response) => {
          if (response.error) {
            dispatch(setDirty(true));
          } else {
            let successMsg = "Your order has been created";
            if (order.orderType !== "One-off" && order.startDeliveryDate) {
              let noOfOrders = 0;
              const days = daysOfWeek.map((day, index) => {
                if (order["is"+day]) {
                  if (index === 6) {
                    return 0; // sunday: 0
                  }
                  return index+1; // monday - saturday: 1-6
                }
              }).filter((value) => value)
              _.forEach(days, (value) => {
                let current = moment(order.startDeliveryDate);
                while (current.isSameOrBefore(moment(order.endDeliveryDate))) {
                  current.day(7 + value);
                  noOfOrders += 1;
                }
              });
              successMsg = noOfOrders + " orders have been created"
            }
            dispatch(setSuccess(props.isEdit ? "Your order has been modified" : successMsg));
            handleButtonClick_exit(response.payload.result.saleOrder.id);
          }
        });
      })
    }
  };

  const handleButtonClick_exit = (id) => {
    if (id) {
      history.push({pathname: "/user/sales-order/management/"+id});
    } else {
      history.push({pathname: "/user/sales-order/management"});
    }
  }

  const getTotalWeight = (value, index) => {
    if (value.packageId && value.quantity && value.uom) {
      dispatch(GetTotalWeight({packageId: value.packageId, qty: value.quantity, uom: value.uom}))
      .then((response) => {
        let payload = _.cloneDeep(subSaleOrders);
        payload[index] = {...value, totalNetWeight:response.payload.result};
        dispatch(updateProductRequest(payload));
      });
    }
  }

  const validateFields = () => {
    for (let i = 0; i < subSaleOrders.length; i ++) {
      if (!subSaleOrders[i].productId) {
        setErrorMsg({field: "productId"+i, msg: "Please select a product"});
        return false;
      }
      if (!subSaleOrders[i].packageId) {
        setErrorMsg({field: "packageId"+i, msg: "Please select a package"});
        return false;
      }
      if (!subSaleOrders[i].quantity) {
        setErrorMsg({field: "quantity"+i, msg: "Please enter a quantity"});
        return false;
      }
      if (!subSaleOrders[i].uom) {
        setErrorMsg({field: "uom"+i, msg: "Please select an uom"});
        return false;
      }
      if (subSaleOrders[i].isRootInclude===undefined || !(subSaleOrders[i].isRootInclude.toString())) {
        setErrorMsg({field: "isRootInclude"+i, msg: "Please select a root"});
        return false;
      }
      if (!subSaleOrders[i].unitPrice) {
        setErrorMsg({field: "unitPrice"+i, msg: "Please enter a unit price"});
        return false;
      }
      if (!isValidPrice(subSaleOrders[i].unitPrice)) {
        setErrorMsg({field: "unitPrice"+i, msg: "Please enter a valid unit price"});
        return false;
      }
    }
    setErrorMsg(false);
    return true;
  };

  React.useEffect(() => {
    Promise.all(subSaleOrders.map((item) => {
      if (item.productId) {
        return dispatch(GetPackageList(item.productId))
        .then((response) => {
          return response.payload.result;
        });
      }
    })).then((response) => {
      dispatch(updatePackageRequest(response));
    })
  },[subSaleOrders]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetProductList());
    dispatch(GetAllUnitOfMeasurement());
  },[]);

  return (
    <React.Fragment>
      {subSaleOrders.map((item, index) => {
        // remove from list if selected
        const reducedList = subSaleOrders.reduce((list,{productId, packageId},i) => {
          if (productId===item.productId && packageId && index!==i) {
            list.add(packageId)
          } 
          return list
        }, new Set());
        const packageLookup = packageList[index] && packageList[index].filter(({id}) => reducedList && !reducedList.has(id));
        return (
          <Card 
            key={index}
            classes={{
              root: clsx(styles.cardPaper, styles.cardMargin),
            }}
            title={
              <React.Fragment>
                Product {index+1} Detail
                <IconButton 
                  className={collapsed[index] ? styles.collapsedIcon : clsx(styles.collapsedIcon, styles.rotate)}
                  onClick={() => setCollapsed({...collapsed, [index]: !collapsed[index]})}
                >
                  <img className={styles.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
            action = { subSaleOrders.length > 1 &&
              <CustomIconButton 
                type="delete"
                onClick={() => handleModal_delete(index)}
              />
            }
          >
            { !collapsed[index] &&
              <React.Fragment>
                <Typography className={styles.formTitle}>Product {index+1} Information</Typography>
                <Grid container spacing={4}>
                  <Grid item xs={4} className={styles.grid}>
                    <Typography className={styles.formLabel}>Product Name:</Typography>
                    <Select
                      name={"productId"+index}
                      onChange={(e)=>handleOnChange_select(e, index)}
                      placeholder="Select product"
                      value={renderValue(item.productId)}
                      errorMsg={errorMsg}
                    >
                      {productList && productList.map((item, index) => {
                        return <MenuItem key={index} value={item.id}>{item.productName} ({item.productId})</MenuItem>;
                      })} 
                    </Select>
                  </Grid>
                  <Grid item xs={4} className={styles.grid}>
                    <Typography className={styles.formLabel2}>Package:</Typography>
                    <Select
                      name={"packageId"+index}
                      onChange={(e)=>handleOnChange_select(e, index)}
                      placeholder="Select package UOM"
                      value={renderValue(item.packageId)}
                      errorMsg={errorMsg}
                    >
                      {packageLookup && packageLookup.map((item, index) => {
                        return <MenuItem key={index} value={item.id}>{item.packageName}</MenuItem>;
                      })} 
                    </Select>
                  </Grid>
                  <Grid item xs={4} className={styles.grid}>
                    <Typography className={styles.formLabel2}>Quantity:</Typography>
                    <TextField
                      id={"quantity"+index}
                      type="Number"
                      variant="outlined" 
                      onInput={(e)=>{ 
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
                      }}
                      placeholder="Enter quantity"
                      onChange={(e) => handleOnChange_text(e, index)}
                      value={renderValue(item.quantity)}
                      errorMsg={errorMsg}
                    />
                  </Grid>
                  <Grid item xs={4} className={styles.grid}>
                    <Typography className={styles.formLabel}>Total Weight:</Typography>
                    <TextField
                      id="totalNetWeight"
                      type="Number"
                      variant="outlined"
                      placeholder="Quantity x Package size"
                      value={renderValue(item.totalNetWeight)}
                      disabled
                    />
                    <Select
                      className={styles.unitSelect}
                      name={"uom"+index}
                      onChange={(e)=>handleOnChange_select(e, index)}
                      placeholder="Select a unit"
                      value={renderValue(item.uom)}
                      errorMsg={errorMsg}
                    >
                      {uomList && uomList.map((item, index) => {
                        return <MenuItem key={index} value={item.unitOfMeasurementShortForm}>{item.unitOfMeasurementShortForm}</MenuItem>;
                      })} 
                    </Select>
                  </Grid>
                  <Grid item xs={4} className={styles.grid}>
                    <Typography className={styles.formLabel2}>Root:</Typography>
                    <Select
                      name={"isRootInclude"+index}
                      onChange={(e)=>handleOnChange_select(e, index)}
                      placeholder="Select root inclusion"
                      value={item.isRootInclude!== undefined ? item.isRootInclude.toString() : ""}
                      errorMsg={errorMsg}
                    >
                      <MenuItem value={"true"}>Yes</MenuItem>
                      <MenuItem value={"false"}>No</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={4} className={styles.grid}>
                    <Typography className={styles.formLabel2}>UOM:</Typography>
                    <Radio 
                      id="isWeightOrder"
                      checked={!item.isWeightOrder}
                      onChange={(e)=>handleButtonClick_radio(e, index)}
                    />
                    <Typography className={styles.checkboxLabel}>Package</Typography>
                    <Radio 
                      id="isWeightOrder"
                      checked={item.isWeightOrder}
                      onChange={(e)=>handleButtonClick_radio(e, index)}
                    />
                    <Typography className={styles.checkboxLabel}>Weight</Typography>
                  </Grid>
                </Grid>
                <Typography className={styles.formTitle}>Product {index+1} Price</Typography>
                <Grid container spacing={4}>
                  <Grid item xs={4} className={styles.grid}>
                    <Typography className={styles.formLabel}>Unit Price:</Typography>
                    <TextField
                      id={"unitPrice"+index}
                      type="Number"
                      variant="outlined" 
                      onInput={(e)=>{ 
                      // decimal
                        const reg = /^\d*(\.\d{0,2})?$/
                        if (!reg.test(e.target.value)) {
                          e.target.value = roundTo2Decimal(e.target.value);
                        }
                      }}
                      placeholder="Enter unit price"
                      onChange={(e) => handleOnChange_text(e, index)}
                      value={renderValue(item.unitPrice)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      errorMsg={errorMsg}
                    />
                  </Grid>
                  <Grid item xs={4} className={styles.grid}>
                    <Typography className={styles.formLabel2}>Total Price:</Typography>
                    <TextField
                      id="totalPrice"
                      type="Number"
                      variant="outlined" 
                      placeholder="Quantity x Unit Price"
                      value={(item.unitPrice && item.quantity) ? roundTo2Decimal(item.unitPrice*item.quantity) : ""}
                      InputProps={{
                        startAdornment: 
                          <InputAdornment position="start">$</InputAdornment>,
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </Card>
        )
      })}
      <Button
        className={clsx(styles.addButton, styles.buttonSecondary)}
        startIcon={<AddIcon />}
        onClick={()=>handleButtonClick_add()}
      >
        Add Another Product to the Same PO
      </Button>
      <div className={styles.cardActions}>
        <Button
          className={styles.buttonSecondary}
          onClick={()=>location.reload()}
        >
          Go Back
        </Button>
        <Button
          className={styles.button}
          onClick={()=>handleButtonClick_save()}
        >
          Complete Order
        </Button>
      </div>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={styles.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this product? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={styles.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={styles.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
