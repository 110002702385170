import React from "react";
import Cropper from "react-easy-crop";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import Modal from "@mui/material/Modal";
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
// core components
import styles from "assets/jss/shared-components/modal.module.scss";

export default function CustomModal({imageFile, onConfirm, ...props}) {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [blob, setBlob] = React.useState(1);

  const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  })

  const getCroppedImg = async (imageSrc, crop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    /* setting canvas width & height allows us to 
    resize from the original image resolution */
    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob)
      }, 'image/jpeg')
    })
}

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(imageFile, croppedAreaPixels);
    setBlob(croppedImage)
}

  return (
    <Modal {...props} className={styles.modal}>
      <Paper className={clsx(styles.paper, styles.cropperPaper)}>
        <div className={styles.cropperContainer}>
          <Cropper
            classes={{
              containerClassName: styles.cropper
            }}
            image={imageFile}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className={styles.actions}>
          <Button className={styles.buttonSecondary} onClick={props.onClose}>Cancel</Button>
          <Button className={styles.button} onClick={() => onConfirm(blob)}>Upload</Button>
        </div>
      </Paper>
    </Modal>
  );
}

CustomModal.propTypes = {
  imageFile: PropTypes.string,
  onConfirm: PropTypes.func,
};