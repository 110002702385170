import { combineReducers } from "redux";
import logSheet from "./logSheet";
import availability from "./availability";
import labourCost from "./labourCost";
import adminLogSheet from "./adminLogSheet";
import search from "./search";

const hrReducer = combineReducers({
  logSheet,
  availability,
  labourCost,
  adminLogSheet,
  search
});

export default hrReducer;