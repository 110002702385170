import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import clsx from "clsx";
// @mui/material
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";

import close from "assets/icons/orange/close.svg";
import styles from "assets/jss/components/UserPortal/home.module.scss";

export default function DragCard({ id, item, index, onMoveItem, children, ...props }) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "div",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      onMoveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [, drag] = useDrag({
    type: "div",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <Grid key={index} item xs={4} ref={ref} data-handler-id={handlerId}>
      <Card 
        classes={{
          root: clsx(styles.cardPaper, styles.widgetPaper, styles.widgetDrag),
          content: styles.widgetContent
        }}
        title={item.title+" Module:"}
        action={
          <IconButton onClick={()=>props.handleModal_warning(item)}>
            <img className={styles.icon_24} src={close} alt="close" />
          </IconButton>
        }
      >
        <React.Fragment>
          {children}
        </React.Fragment>
      </Card>
    </Grid>
  );
}
