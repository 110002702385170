import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { graphColors as colors } from "assets/jss/palette";
import styles from "assets/jss/components/UserPortal/costProductivity.module.scss";

export default function LineChart({date, title, tab}) {
  const productList = useSelector(store => store.common.productList);
  const customerList = useSelector(store => store.user.costProductivity.report.customerList);
  const results = useSelector(store => store.user.costProductivity.report.reports);

  const config = () => {
    return ({
      chart: {
        zoomType: moment(date.startDate).isSame(date.endDate) && 'x',
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: title
        }
      },
      time: {
        useUTC: false
      },
      legend: {
        enabled: false
      },
      series: results.map((item, i) => {
        if (item) {
          const name = tab === 1 ? productList.find(({id}) => id == item.productId) : customerList.find(({id}) => id == item.customerId);
          const sortedReport = title === "Cost" ? (item.costReports && _.sortBy(item.costReports, ["date"])) : (item.revenueReports && _.sortBy(item.revenueReports, ["date"]));
          return {
            data: moment(date.startDate).isBefore(date.endDate)
            ? sortedReport && sortedReport.map((data) => 
                [moment(data.date).valueOf(), data.value]
              )
            : [],
            name: name && (tab === 1 ? name.productName : name.customerName),
            color: colors[i]
          }
        }
      })
    })
  };
  
  return (
    <React.Fragment>
      <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: styles.highChart }} />
      <div className={styles.graphLegend}>
        {results && results.map((item, index) => {
          if (item) {
            const name = tab === 1 ? productList.find(({id}) => id == item.productId) : customerList.find(({id}) => id == item.customerId);
            return (
              <div key={index} className={styles.legendColor}>
                <div className={styles.colorBox} />{name && (tab === 1 ? name.productName : name.customerName)}
              </div>
            )
          }
        })}
      </div>
    </React.Fragment>
  );
}
