import { createAsyncThunk } from "@reduxjs/toolkit";

import {EnergyMS} from "../index";

// lookup
export const GetDBNames  = createAsyncThunk(
  "smartMeteringFlagship/getDBNames", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetDbNames", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllWattageAddresses  = createAsyncThunk(
  "smartMeteringFlagship/getAllWattageAddresses", 
  async (param, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "dbName") {
        param[key].map((item) => params.append("dbName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetAllWattageAddresses", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCurrentAddresses  = createAsyncThunk(
  "smartMeteringFlagship/getCurrentAddresses", 
  async (param, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "dbName") {
        param[key].map((item) => params.append("dbName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetCurrentAddresses", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetWattageHourAddresses  = createAsyncThunk(
  "smartMeteringFlagship/getWattageHourAddresses", 
  async (param, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "dbName") {
        param[key].map((item) => params.append("dbName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetWattageHourAddresses", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetVoltageAddresses  = createAsyncThunk(
  "smartMeteringFlagship/getVoltageAddresses", 
  async (param, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "dbName") {
        param[key].map((item) => params.append("dbName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetVoltageAddresses", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTodayYesterdayData  = createAsyncThunk(
  "smartMeteringFlagship/GetTodayYesterdayData", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetTodayYesterdayData", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Overview
export const GetData  = createAsyncThunk(
  "smartMeteringFlagship/getData", 
  async ({signal, stopLoading, ...param}, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "dbName") {
        param[key].map((item) => params.append("dbName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetData", {params, signal, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetTotalData  = createAsyncThunk(
  "smartMeteringFlagship/getTotalData", 
  async ({signal, stopLoading, ...param}, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "dbName") {
        param[key].map((item) => params.append("dbName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetTotalData", {params, signal, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Wattage
export const GetWattageData  = createAsyncThunk(
  "smartMeteringFlagship/getWattageData", 
  async ({signal, stopLoading, ...param}, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "dbName") {
        param[key].map((item) => params.append("dbName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetWattageData", {params, signal, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Cost
export const GetAllCostChartRequest  = createAsyncThunk(
  "smartMeteringFlagship/GetAllCostChartRequest", 
  async ({signal, stopLoading, ...param}, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "name") {
        param[key].map((item) => params.append("dbName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetCost", {params, signal, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetCostPrice  = createAsyncThunk(
  "smartMeteringFlagship/getCostPrice", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await EnergyMS.get("/SmartMeteringFlagship/GetCostPrice", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateCostPrice  = createAsyncThunk(
  "smartMeteringFlagship/createOrUpdateCostPrice", 
  async (value, {rejectWithValue}) => {
    const params = {
      price: value
    };
    try {
      const response = await EnergyMS.post("/SmartMeteringFlagship/CreateOrUpdateCostPrice", null, {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);