import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// @mui/material
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
import Card from "shared-components/Card/Card";

import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { DownloadASRSOnboardingTemplate, UploadASRSOnboarding } from "services/UserPortal/ASRSService";

import UploadedData from "./UploadedData";
import batchUpdate from "assets/img/batchUpdate-img.svg";
import styles from "assets/jss/components/UserPortal/asrs.module.scss";

const FileDownload = require('js-file-download');

export default function Onboarding() {
  let history = useHistory();
  const dispatch = useDispatch();
  const inputFile = React.createRef();
  const [isFileUploaded, setIsFileUploaded] = React.useState(false);

  const handleButtonClick_download = () => {
    dispatch(DownloadASRSOnboardingTemplate())
    .then((response) => {
      FileDownload(response.payload.data, 'vifs_onboarding_template.xlsx');
    })
  }

  const onButtonClick_upload = () => {
    inputFile.current.click();
  }

  const handleOnUpload = (e) => {
    setIsFileUploaded(true);
    dispatch(UploadASRSOnboarding(e.target.files[0]));
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetNav());
    }
  },[]);

  if (isFileUploaded) {
    return <UploadedData />
  } else {
    return (
      <React.Fragment>
        <Card 
          title="VIFS - Onboarding"
          cardActions={
            <React.Fragment>
              <Button
                className={styles.buttonSecondary}
                onClick={() => history.push("/user/vifs/current-listings")}
              >
                Back
              </Button>
            </React.Fragment>
          }
        >
          <div className={styles.batchPaper}>
            <img className={styles.img} src={batchUpdate} alt="batch-update" />
            <input 
              ref={inputFile} 
              style={{"display": "none"}} 
              type="file" 
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => handleOnUpload(e)}
            />
            <Typography className={styles.batchTitle}>VIFS Upload</Typography>
            <Button 
              className={styles.batchButton} 
              startIcon={<AddIcon />} 
              onClick={() => handleButtonClick_download()}
            >
              Download Template
            </Button>
            <Button 
              className={styles.batchButton} 
              startIcon={<AddIcon />} 
              onClick={() => onButtonClick_upload()}
            >
              Upload Template
            </Button>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}
