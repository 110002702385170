import { createAsyncThunk } from "@reduxjs/toolkit";

import {EnergyMS} from "../index";

// lookup
export const GetEnergyFloor  = createAsyncThunk(
  "smartMetering/getEnergyFloor", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await EnergyMS.get("/userportal/SmartMetering/GetEnergyFloor", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllEnergyDevices  = createAsyncThunk(
  "smartMetering/GetAllEnergyDevices", 
  async (value, {rejectWithValue}) => {
    const params = {
      deviceName: value
    };
    try {
      const response = await EnergyMS.get("/SmartMetering/GetAllEnergyDevices", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllEnergyUnit  = createAsyncThunk(
  "smartMetering/GetAllEnergyUnit", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await EnergyMS.get("/SmartMetering/GetAllEnergyUnit", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Overview
export const GetAllEnergyMonitoring  = createAsyncThunk(
  "smartMetering/getAllEnergyMonitoring", 
  async ({signal, stopLoading, ...param}, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "deviceName") {
        param[key].map((item) => params.append("deviceName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMetering/GetAllEnergyMonitoring", {params, signal, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Cost
export const GetAllCostChartRequest  = createAsyncThunk(
  "smartMetering/GetAllCostChartRequest", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    let params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key == "address") {
        param[key].map((item) => params.append("address", item));
      } else if (key == "deviceName") {
        param[key].map((item) => params.append("deviceName", item));
      } else {
        params.append(key, param[key]);
      }
    });
    try {
      const response = await EnergyMS.get("/SmartMetering/GetAllCostChartRequest", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetPricekWh  = createAsyncThunk(
  "smartMetering/GetPricekWh", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await EnergyMS.get("/SmartMetering/GetPricekWh", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const UpdatePricekWh  = createAsyncThunk(
  "smartMetering/UpdatePricekWh", 
  async (value, {rejectWithValue}) => {
    const params = {
      price: value
    };
    try {
      const response = await EnergyMS.put("/SmartMetering/UpdatePricekWh", null, {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//Dashboard
export const GetDashboard  = createAsyncThunk(
  "smartMetering/getDashboard", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await EnergyMS.get("/SmartMetering/Dashboards");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);