import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import palette from "assets/jss/general.module.scss"

const TableRowCss = styled(TableRow)({
  color: palette.primary,
  '& td': {
    fontSize: "0.938vw",
  },
  // '&:nth-of-type(odd)': {
  //   backgroundColor,
  // },
  // '&:nth-of-type(even)': {
  //   backgroundColor: "white",
  // },
  '& td:first-child': {
    paddingLeft: "2.292vw"
  },
  '& td:last-child': {
    paddingRight: "2.292vw"
  },
});

export default function CustomTableRow({children, ...props}) {

  return (
    <TableRowCss {...props}>
      {children}
    </TableRowCss>
  );
}

CustomTableRow.propTypes = {
  children: PropTypes.node,
};