import * as adminRoutes from "routes/adminRoutes";
import * as userRoutes from "routes/userRoutes";
import _ from "lodash";
import { checkPermission } from "common/functions";

export const useRouteCanWrite = () => {
  let routesObj = _.pick(adminRoutes, ["dashboardRoutes", "capacitySubRoutes", "humanResourceSubRoutes"]);
  if (!location.pathname.includes("/admin")) {
    routesObj = _.pick(userRoutes, ["dashboardRoutes", "customerOrderSubRoutes", "climateSubRoutes", "hrSubRoutes", "equipmentSubRoutes", "operationsSubRoutes"]);
  }
  let routes = [];
  Object.keys(routesObj).forEach(key => routes = _.concat(routes, routesObj[key]));
  let value = false;
  routes.forEach((route) => {
    const permission = route.permissionName && checkPermission(route.permissionName, "readEdit");
    if (window.location.href.indexOf(route.layout + route.path) !== -1 && permission) {
      value = true;
    }
  });
  return value;
};