import { createAsyncThunk } from "@reduxjs/toolkit";
import { find } from "lodash";

import { rowsPerTable } from "config";
import {FomsMS} from "../index";

// Lookup
export const GetDataSourceList = createAsyncThunk(
  "reporting/getDataSourceList", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetDataSourceList", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetVegetableType = createAsyncThunk(
  "reporting/getVegetableType", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetVegetableType", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetXaxisVariableName = createAsyncThunk(
  "reporting/getXaxisVariableName", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetXaxisVariableName", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetYaxisVariableName = createAsyncThunk(
  "reporting/getYaxisVariableName", 
  async (param, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetYaxisVariableName", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetVisualisationType = createAsyncThunk(
  "reporting/getVisualisationType", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetVisualisationType", {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Main
export const GetAllActiveGraph = createAsyncThunk(
  "reporting/getAllActiveGraph", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * 2,
      maxResultCount: param.maxResultCount || 2
    };
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetAllActiveGraph", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetAllDashboardSetting = createAsyncThunk(
  "reporting/getAllDashboardSetting", 
  async (_, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetAllDashboardSetting", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const AddOrRemoveGraphToDashboard = createAsyncThunk(
  "reporting/addOrRemoveGraphToDashboard", 
  async (_, {getState, rejectWithValue}) => {
    let body = {
      qcDashboardRequestList: getState().user.report.report.dashboardList
    };
    try {
      const response = await FomsMS.post("/userportal/Reporting/AddOrRemoveGraphToDashboard", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Configurations
export const GetAllQCReportConfiguration = createAsyncThunk(
  "reporting/getAllQCReportConfiguration", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    const params = {
      ...param,
      farmId: find(userDetail.farms, "isActive").id,
      skipCount: (param.page) * rowsPerTable,
      maxResultCount: param.maxResultCount || rowsPerTable
    };
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetAllQCReportConfiguration", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetQCReportGraphById = createAsyncThunk(
  "reporting/getQCReportGraphById", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetQCReportGraphById", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetQCReportConfiguration = createAsyncThunk(
  "reporting/getQCReportConfiguration", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.get("/userportal/Reporting/GetQCReportConfiguration", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteQCReportConfiguration = createAsyncThunk(
  "reporting/deleteQCReportConfiguration", 
  async (id, {rejectWithValue}) => {
    const params = {id};
    try {
      const response = await FomsMS.delete("/userportal/Reporting/DeleteQCReportConfiguration", {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GenerateGraph = createAsyncThunk(
  "reporting/generateGraph", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      ...getState().user.report.report.config,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/userportal/Reporting/GenerateGraph", body, {stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateQCReportConfiguratiion = createAsyncThunk(
  "reporting/createOrUpdateQCReportConfiguratiion", 
  async (_, {getState, rejectWithValue}) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let body = {
      ...getState().user.report.report.config,
      farmId: find(userDetail.farms, "isActive").id,
    };
    try {
      const response = await FomsMS.post("/userportal/Reporting/CreateOrUpdateQCReportConfiguratiion", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExportQCReportGraphData = createAsyncThunk(
  "reporting/exportQCReportGraphData", 
  async (id, {rejectWithValue}) => {
    const config = {
      responseType: 'blob',
      params: {id},
      stopLoading: true 
    }
    try {
      const response = await FomsMS.get("/userportal/Reporting/ExportQCReportGraphData", config);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);