import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllDevices, GetAllRFIDs, GetAllRFIDGroup, UploadBatchRFID, SearchRFID } from "services/AdminPortal/RFIDService";

// initial state
const initialState = {
  devices: [],
  totalRFIDCount: 0,
  RFIDs: [],
  RFID: {},
  totalGroupCount: 0,
  groupings: [],
  grouping: {},
  batchRFIDs: [],
  batchRFID: {},
};

const rfid = createSlice({
  name: "rfid",
  initialState,
  reducers: {
    setBatchValues: (state, action) => {
      state.batchRFID = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRFIDRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.RFID[key] = action.payload[key];
      })
    },
    updateGroupRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.grouping[key] = action.payload[key];
      })
    },
    updateBatchRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.batchRFID[key] = action.payload[key];
      })
    },
    deleteBatchRequest: (state, action) => {
      state.batchRFIDs = _.filter(state.batchRFIDs, (item) => item.lineNo !== action.payload)
    },
    updateBatchesRequest: (state, action) => {
      state.batchRFIDs = state.batchRFIDs.map((item) => {
        if (item.lineNo === action.payload) {
          return state.batchRFID;
        } else {
          return item;
        }
      });
    },
    resetRFID: (state) => {
      state.RFID = {};
    },
    resetBatch: (state) => {
      state.batchRFID = {};
    },
    resetSearch: (state) => {
      state.searchResultsCount = 0;
      state.searchResults = [];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllDevices.fulfilled, (state, action) => {
        state.devices = _.orderBy([...action.payload.result.deviceFinishList.items, ...action.payload.result.deviceUnfinishList.items], ["deviceRefNo"], ["asc"]);
      })
      .addCase(GetAllRFIDs.fulfilled, (state, action) => {
        state.totalRFIDCount = action.payload.result.totalCount;
        state.RFIDs = action.payload.result.items;
      })
      .addCase(GetAllRFIDGroup.fulfilled, (state, action) => {
        state.totalGroupCount = action.payload.result.totalCount;
        state.groupings = action.payload.result.items;
      })
      .addCase(UploadBatchRFID.fulfilled, (state, action) => {
        state.batchRFIDs = action.payload.result.results;
      })
      .addCase(SearchRFID.fulfilled, (state, action) => {
        state.searchResultsCount = action.payload.result.totalCount;
        state.searchResults = action.payload.result.items;
      })
  },
});

// export actions
export const { updateRequest, updateRFIDRequest, updateGroupRequest, 
  setBatchValues, updateBatchRequest, deleteBatchRequest, updateBatchesRequest,
  resetRFID, resetBatch, resetSearch, reset } = rfid.actions;

// export the reducer
export default rfid.reducer;