// Create Edit Raw Material
// Step 2: Safety UOM
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import { setDirty } from "store/general";
// @mui/icons-material
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// @mui/material
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { safetyLevelAndUOMTableHead, UOMSupportedTableHead } from "enums/AdminPortal/TableHeaders";
import RawMaterialStepper from "./RawMaterialStepper";
import { isPositiveDecimal } from "common/validations";
import { renderValue } from "common/functions";

import styles from "assets/jss/components/AdminPortal/rawMaterial.module.scss";
import helper from "assets/icons/black/helper.svg";
import { GetAllUnitOfMeasurement, CreateOrUpdateRawMaterial } from "services/AdminPortal/RawMaterialService";
import { GetAllRawMaterialInfoForMapping } from "services/UserPortal/CommonLookupService";
import { updateRequest, clearRawMaterialMapping } from "./";

export default function SafetyLevelUOMForm(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfoForMapping);
  const selectedRawMaterial = useSelector(store => store.admin.rawMaterial.selectedRawMaterial);
  const allUnitOfMeasurement = useSelector(store => store.admin.rawMaterial.allUnitOfMeasurement);
  const [openUOMHelper, setOpenUOMHelper] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  
  safetyLevelAndUOMTableHead[2].label = <Typography className={styles.UOMHeader}> UOM <span className={styles.orange}>*</span> 
    <IconButton 
      className={styles.iconButton_helper}
      onClick={() => setOpenUOMHelper(true)}
    >
      <img className={styles.helperIcon} src={helper} alt="helper" />
    </IconButton>
  </Typography>

  const handleOnChange_text = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      value = Number(e.target.value);
    }
    dispatch(updateRequest({[e.target.id] : value}));
  };

  const handleOnChange_select = (e) => {
    if (e.target.name === "childRawMaterialId") {
      if (e.target.value === 0) {
        dispatch(updateRequest({[e.target.name] : e.target.value, parentValue: 0, childValue: 0, childRawMaterialName: null}));
      } else {
        dispatch(updateRequest({[e.target.name] : e.target.value, childRawMaterialName: _.find(rawMaterialList, ({id}) => id == e.target.value).rawMaterialName}));
      }
    } else {
      dispatch(updateRequest({[e.target.name] : e.target.value}));
    }
  };

  const handleButtonClick_next = () => {
    if (validateFields()) {
      dispatch(updateRequest({["isDraft"] : false}))
      props.setStep(2)
    }
  }

  const handleButtonClick_checkbox = (e) => {
    if (e.target.id == "isSeed") {
      dispatch(updateRequest({isSeed : !selectedRawMaterial.isSeed, isNutrient: false}))
    } else {
      dispatch(updateRequest({isNutrient : !selectedRawMaterial.isNutrient, isSeed: false}))
    }
  };

  const saveRawMaterial = (body) => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      dispatch(CreateOrUpdateRawMaterial(body))
      .then(({error}) => {
        if (error) {
          dispatch(setDirty(true));
        } else {
          history.push("/admin/raw-material");
        }
      });
    });
  }

  const handleButtonClick_saveDraft = () => {
    if (_.isEmpty(selectedRawMaterial.unitOfMeasurement)) {
      setErrorMsg({field: "unitOfMeasurement", msg: "Please select a Unit of Measurement"});
      return false;
    } else {
      const result = _.omit(selectedRawMaterial, ['rawMaterialType', 'rawMaterialVariantType']);
      saveRawMaterial(result);
    }
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      const result = _.omit(selectedRawMaterial, ['rawMaterialType', 'rawMaterialVariantType']);
      saveRawMaterial(result);
    }
  }

  const validateFields = () => {
    if (_.isEmpty(selectedRawMaterial.unitOfMeasurement)) {
      setErrorMsg({field: "unitOfMeasurement", msg: "Please select a unit of measurement"});
      return false;
    }
    if (selectedRawMaterial.safetyLevel == undefined) {
      setErrorMsg({field: "safetyLevel", msg: "Please add in a safety level"});
      return false;
    }
    if (selectedRawMaterial.safetyLevel && !isPositiveDecimal(selectedRawMaterial.safetyLevel)) {
      setErrorMsg({field: "safetyLevel", msg: "Please enter a valid safety level"});
      return false;
    }
    if (selectedRawMaterial.maximumLevel && !isPositiveDecimal(selectedRawMaterial.maximumLevel)) {
      setErrorMsg({field: "maximumLevel", msg: "Please enter a valid maximum level"});
      return false;
    }
    if (selectedRawMaterial.maximumLevel && Number(selectedRawMaterial.safetyLevel) > Number(selectedRawMaterial.maximumLevel)) {
      setErrorMsg({field: "safetyLevel", msg: "Safety Level cannot be more than maximum level"});
      return false;
    }
    if (selectedRawMaterial.requestLeadTime === 0 || selectedRawMaterial.requestLeadTime == undefined) {
      setErrorMsg({field: "requestLeadTime", msg: "Please add in a lead time"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const displayNutrientInfo = () => {
    const filteredRMList = rawMaterialList && rawMaterialList.filter(({id}) => id !== selectedRawMaterial.id);
    if (selectedRawMaterial.isNutrient) {
      return(
        <React.Fragment>
          <Grid item xs={4} className={styles.newLabel}>
            <Typography>Map to :</Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              name="childRawMaterialId"
              placeholder="Select raw material"
              onChange={(e)=>handleOnChange_select(e)}
              value={selectedRawMaterial.rawMaterialMapping && renderValue(selectedRawMaterial.rawMaterialMapping.childRawMaterialId)}
              disabled={selectedRawMaterial.rawMaterialMapping && selectedRawMaterial.rawMaterialName === selectedRawMaterial.rawMaterialMapping.childRawMaterialName}
            >
              {filteredRMList && filteredRMList.length &&
                <MenuItem value={0}>None</MenuItem>
              } 
              {filteredRMList && filteredRMList.map((item,index) => {
                return <MenuItem key={index} value={item.id}>{item.rawMaterialName}</MenuItem>;
              })}  
            </Select>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={3}>
            Parent
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            Child
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={3} className={styles.newLabel}>
            <TextField
              variant="outlined"
              multiline
              disabled
              value={selectedRawMaterial.rawMaterialMapping && selectedRawMaterial.rawMaterialMapping.parentRawMaterialName ? selectedRawMaterial.rawMaterialMapping.parentRawMaterialName : selectedRawMaterial.rawMaterialName}
            />
          </Grid>
          <Grid item xs={1} className={styles.newLabel}>
            <ArrowRightAltIcon />
          </Grid>
          <Grid item xs={3} className={styles.newLabel}>
            <TextField
              variant="outlined"
              multiline
              disabled
              value={selectedRawMaterial.rawMaterialMapping && renderValue(selectedRawMaterial.rawMaterialMapping.childRawMaterialName)}
            />
          </Grid>
          <Grid item xs={4} className={styles.newLabel}>
            <Typography>Ratio :</Typography>
          </Grid>
          <Grid item xs={3} className={styles.newLabel}>
            <TextField
              id="parentValue"
              type="Number"
              variant="outlined" 
              placeholder="Eg. 1"
              onChange={(e) => handleOnChange_text(e)}
              value={selectedRawMaterial.rawMaterialMapping && selectedRawMaterial.rawMaterialMapping.parentValue}
              disabled={selectedRawMaterial.rawMaterialMapping && selectedRawMaterial.rawMaterialName === selectedRawMaterial.rawMaterialMapping.childRawMaterialName}
            />
          </Grid>
          <Grid item xs={1} className={styles.newLabel}>
            <Typography className={styles.semicolonAlign}>:</Typography>
          </Grid>
          <Grid item xs={3} className={styles.newLabel}>
            <TextField
              id="childValue"
              type="Number"
              variant="outlined" 
              placeholder="Eg. 10"
              onChange={(e) => handleOnChange_text(e)}
              value={selectedRawMaterial.rawMaterialMapping && selectedRawMaterial.rawMaterialMapping.childValue}
              disabled={selectedRawMaterial.rawMaterialMapping && selectedRawMaterial.rawMaterialName === selectedRawMaterial.rawMaterialMapping.childRawMaterialName}
            />
          </Grid>
        </React.Fragment>
      )
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllUnitOfMeasurement());
    dispatch(GetAllRawMaterialInfoForMapping());
    if (props.isEdit) {
      dispatch(updateRequest({["isDraft"] : false}))
    }
  },[]);

  React.useEffect(() => {
    if (!selectedRawMaterial.isNutrient) {
      dispatch(clearRawMaterialMapping());
    }
  },[selectedRawMaterial.isNutrient]);
  
  if (openUOMHelper) {
    return(
      <Card 
        title={
          <React.Fragment>
            Units of Measurement Supported
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={() => setOpenUOMHelper(false)}
            >
              Go Back
            </Button>
            
          </React.Fragment>
        }
      >
        <Table
          className={styles.table}
          header={UOMSupportedTableHead}
        >
        {allUnitOfMeasurement && allUnitOfMeasurement.map((item,index) => {
          return (
            <TableRow key={index}>
              <TableCell>{item.unitOfMeasurement}</TableCell>
              <TableCell>{item.unitOfMeasurementShortForm}</TableCell>
            </TableRow>
          );
        })}  
        </Table>
      </Card>
    )
  } else {
    return (
      <Card 
        title={
          <React.Fragment>
            Step 2: Key in Quality Safety Level and UOM
          </React.Fragment>
        }
        subheader={<RawMaterialStepper activeStep={props.step} />}
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={()=>props.setStep(0)}
            >
              Go Back
            </Button>
            <div>
            {props.isEdit ? 
              <Button
                className={clsx(styles.button, styles.buttonSpacing)}
                onClick={()=> handleButtonClick_save()}
              >
                Save
              </Button>
            :
              <React.Fragment>
                <Button
                  className={styles.buttonSecondary}
                  onClick={()=>handleButtonClick_saveDraft()}
                >
                  Save Draft
                </Button>
                <Button
                  className={clsx(styles.button, styles.buttonSpacing)}
                  onClick={()=>handleButtonClick_next()}
                >
                  Next
                </Button>
              </React.Fragment>
            }
              
            </div>
            
          </React.Fragment>
        }
      >
        <Paper className={styles.newPaper} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={styles.newLabel}>
              <Typography>RM ID :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                variant="outlined"
                value={selectedRawMaterial.rawMaterialRefNo}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={styles.newLabel}>
              <Typography>RM Name :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                variant="outlined"
                value={selectedRawMaterial.rawMaterialName}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={styles.newLabel}>
              <Typography>UOM <span className={styles.orange}>*</span> : 
                <IconButton 
                  className={styles.iconButton_helper}
                  onClick={() => setOpenUOMHelper(true)}
                >
                  <img className={styles.helperIcon} src={helper} alt="helper" />
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="unitOfMeasurement"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(selectedRawMaterial.unitOfMeasurement)}
                placeholder="Eg. g"
                disabled={props.isEdit}
                errorMsg={errorMsg}
              >
                {allUnitOfMeasurement && allUnitOfMeasurement.map((item,index) => {
                  return <MenuItem key={index} value={item.unitOfMeasurementShortForm}>{item.unitOfMeasurementShortForm}</MenuItem>;
                })}  
              </Select>
            </Grid>
            <Grid item xs={4} className={styles.newLabel}>
              <Typography>Safety Levels <span className={styles.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="safetyLevel"
                placeholder="Eg. 100"
                type="Number"
                variant="outlined" 
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(selectedRawMaterial.safetyLevel)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={styles.newLabel}>
              <Typography>Maximum Levels :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="maximumLevel"
                placeholder="Eg. 100"
                type="Number"
                variant="outlined" 
                onInput={(e)=>{ 
                  e.target.value = Number(e.target.value)
                }}
                onChange={(e) => handleOnChange_text(e)}
                value={selectedRawMaterial.maximumLevel}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={styles.newLabel}>
              <Typography>Request Lead Time (Business Days) <span className={styles.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="requestLeadTime"
                placeholder="Eg. 3"
                type="Number"
                onInput={(e)=>{ 
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                }}
                variant="outlined" 
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(selectedRawMaterial.requestLeadTime)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={styles.newLabel}>
              <Typography>Minimum Order Qty :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="minimumOrderQuantity"
                placeholder="Eg. 100"
                type="Number"
                onInput={(e)=>{ 
                  e.target.value = Math.max(0, parseInt(Number(e.target.value))).toString().slice(0,4)
                }}
                variant="outlined" 
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(selectedRawMaterial.minimumOrderQuantity)}
              />
            </Grid>
            <Grid item xs={4} className={styles.newLabel}>
              <Typography>Seed :</Typography>
            </Grid>
            <Grid item xs={8}>
            <Checkbox 
              id="isSeed"
              className={styles.checkboxPadding} 
              checked={selectedRawMaterial.isSeed}
              onChange={(e) => handleButtonClick_checkbox(e)}
            />
            </Grid>
            <Grid item xs={4} className={styles.newLabel}>
              <Typography>Nutrient :</Typography>
            </Grid>
            <Grid item xs={8}>
            <Checkbox 
              id="isNutrient"
              className={styles.checkboxPadding} 
              checked={selectedRawMaterial.isNutrient}
              onChange={(e) => handleButtonClick_checkbox(e)}
            />
            </Grid>
            {displayNutrientInfo()}
          </Grid>
        </Paper>
      </Card>
    )
  }
}
