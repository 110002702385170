// Farm Capacity per Batch
// Capacity Table tab
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import Popper from "shared-components/Popper/Popper";
import Switch from "shared-components/Switch/Switch";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { formatNumbers, roundTo2Decimal } from "common/functions";
import { setSearchText } from "store/general";
import { rowsPerTable } from "config";
import { capacityTableHead } from "enums/AdminPortal/TableHeaders";
import { GetCapacityTable } from "services/AdminPortal/CapacityService";

import helper from "assets/icons/black/helper.svg";
import styles from "assets/jss/components/AdminPortal/capacity.module.scss";


export default function CapacityTable() {
  const dispatch = useDispatch();
  const capacityTable = useSelector(store => store.admin.capacity.batchCapacity.capacityTable);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productId');
  const [page, setPage] = React.useState(0);
  const [includeLoss, setIncludeLoss] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);

  const count = Math.ceil(capacityTable.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetCapacityTable(includeLoss));
  },[includeLoss]);

  return (
    <React.Fragment>
      <Card 
        classes={{
          root: styles.cardPaper,
          title: styles.cardTitle
        }}
        title="Capacity Table"
        action={
          <div className={styles.tableAction}>
            <Typography>Add in Loss Calculation</Typography>
            <Popper 
              open={openPopper}
              className={styles.popper}
              closePopper={() => setOpenPopper(false)}
              buttonClick={() => setOpenPopper(false)}
              placement="bottom"
              title="Loss Calculation"
              content="To take into the account the loss rate which is configured within the Product Module for the particular product. Edit in Product Module if you want to change the loss rate. Calculated by (Loss rate configured in Product Module) x (Weight of whole farm) "
            >
              <IconButton 
                className={styles.iconButton_helper}
                onClick={() => setOpenPopper(true)}
              >
                <img className={styles.icon} src={helper} alt="helper" />
              </IconButton>
            </Popper>
            <Switch 
              checked={includeLoss}
              onChange={() => setIncludeLoss(!includeLoss)}
            />
          </div>
        }
      >
        <div className={styles.table}>
          <Table
            header={includeLoss ? capacityTableHead : _.filter(capacityTableHead, (item)=> item.id !== 'possibleLossWeight')}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(capacityTable, [orderBy], [order])
            .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
            .map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.productId}</TableCell>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.process}</TableCell>
                  <TableCell>{item.vegetableType}</TableCell>
                  <TableCell>{item.totalPlants && formatNumbers(roundTo2Decimal(item.totalPlants))}</TableCell>
                  <TableCell>{item.totalWeight && formatNumbers(roundTo2Decimal(item.totalWeight))}</TableCell>
                  {includeLoss && <TableCell>{item.possibleLossWeight && formatNumbers(roundTo2Decimal(item.possibleLossWeight))}</TableCell>}
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Card>
    </React.Fragment>
  );
}
