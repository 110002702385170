export const statusLookup = [
  {
    key: "active",
    displayName: "Active"
  },
  {
    key: "inactive",
    displayName: "Inactive"
  },
]

export const genderLookup = [
  {
    key: "male",
    displayName: "Male"
  },
  {
    key: "female",
    displayName: "Female"
  },
]

export const timeUnitLookup = [
  {
    key: "Hour",
    displayName: "Hour",
  },
  {
    key: "Day",
    displayName: "Day",
  },
]

export const controlModeLookup = [
  {
    key: "Auto",
    displayName: "Auto",
  },
  {
    key: "Manual",
    displayName: "Manual",
  },
]