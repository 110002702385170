// Create Edit Product
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setDirty } from "store/general";

import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";

import ProductCreationForm from "./ProductCreationForm";
import GrowthProcess from "./GrowthProcess";
import { GetProductById, GetProductProcessByProductId } from "services/AdminPortal/ProductService";

export default function AddEditProduct(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(0);
  const [imgFile, setImgFile] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);

  // componentDidMount
  React.useEffect(() => {
    const prop = props.location.state;
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "products");
    if (path[index+1] != "create") {
      setIsEdit(true);
      dispatch(GetProductById({id: path[index+1]}))
      .then(({error}) => {
        if (error) {
          history.push((prop && prop.prevPath) || "/admin/products");
        } else {
          dispatch(setDirty(true));
          dispatch(GetProductProcessByProductId(path[index+1]));
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    return () => {
      dispatch(setDirty(false));
      dispatch(resetNav());
    }
  },[]);

  const renderForm = () => {
    switch(step) {
      case 0:
        return <ProductCreationForm step={step} setStep={setStep} setNewOrUpdate={props.setNewOrUpdate} imgFile={imgFile} setImgFile={setImgFile} isEdit={isEdit}/>
      case 1:
        return <GrowthProcess step={step} setStep={setStep} imgFile={imgFile}/>
      case 2:
        return <GrowthProcess step={step} setStep={setStep} imgFile={imgFile}/>
      case 3:
        return <GrowthProcess step={step} setStep={setStep} imgFile={imgFile}/>
      case 4:
        return <GrowthProcess step={step} setStep={setStep} imgFile={imgFile}/>
    }
  }
  
  return (
    <React.Fragment>
      {renderForm()}
    </React.Fragment>
  )
}
