/* eslint-disable no-unused-vars */ // TO REMOVE
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import DateTimePicker from "shared-components/DatePicker/DateTimePicker";
import Select from "shared-components/Select/Select";

import { rowsPerTable } from "config";
import { setDirty, setSuccess } from "store/general";
import { amrPastTaskTableHead } from "enums/UserPortal/TableHeaders";
import { filterParam, renderValue } from 'common/functions';
import { setValues, updateRequest } from "../store/overview";
import { GetAllCurrentListingList, GetAllCages } from "services/UserPortal/ASRSService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/amr.module.scss";

export default function EditFutureTask() {
  let history = useHistory();
  const dispatch = useDispatch();
  const task = useSelector(store => store.user.amr.adhoc.task);
  const [deleteId, setDeleteId] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      const newValue = moment(value).set({second: 0, millisecond: 0});
      let payload = {[field]: newValue.format()};
      dispatch(updateRequest(payload));
    } else {
      dispatch(updateRequest({[field] : value}));
    }
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name]: e.target.value}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        // dispatch(CreateOrUpdateFunctionLibrary(body))
        // .then((response)=>{
        //   if (response.error) {
        //     if (response.payload.message.includes("short")) {
        //       setErrorMsg({field: "shortDeviceFunctionName", msg: "Shortform already exists"});
        //     } else {
        //       setErrorMsg({field: "deviceFunctionName", msg: "Reading name already exists"});
        //     }
        //   } else {
        //     dispatch(setSuccess("Success! Changes will be reflected in a few minutes."));
        //     history.push("/user/amr/ad-hoc-tasks")
        //   }
        // })
      })
    }
  }

  const validateFields = () => {
    if (!task.startDateTime) {
      setErrorMsg({field: "startDateTime", msg: "Please enter a start date"});
      return false;
    }
    if (!moment(task.startDateTime).isValid()) {
      setErrorMsg({field: "startDateTime", msg: "Invalid Date"});
      return false;
    }
    if (!task.isNoEndDateTime && !task.endDateTime) {
      setErrorMsg({field: "endDateTime", msg: "Please enter an end date"});
      return false;
    }
    if (!moment(task.endDateTime).isValid()) {
      setErrorMsg({field: "endDateTime", msg: "Invalid Date"});
      return false;
    }
    if (task.endDateTime && moment(task.endDateTime).isBefore(moment(task.startDateTime))) {
      setErrorMsg({field: "endDateTime", msg: "End date cannot be before start date"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllCages());
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Edit Future Task"
        cardActions={
          <React.Fragment>
            <Button
              className={styles.buttonSecondary}
              onClick={() => history.push("/user/amr/ad-hoc-tasks")}
            >
              Back
            </Button>
            <Button
              className={styles.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={2} className={styles.gridContainer}>
          <Grid item xs={2}>
            Start Date/Time:
          </Grid>
          <Grid item xs={3}>
            <DateTimePicker
              type="datetime"
              name="startDateTime"
              value={task.startDateTime}
              onChange={(e) => handleOnChange_date(e, "startDateTime")}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={7} />
          <Grid item xs={2}>
            End Date/Time:
          </Grid>
          <Grid item xs={3}>
            <DateTimePicker
              type="datetime"
              name="endDateTime"
              value={task.endDateTime}
              minDateTime={task.startDateTime}
              onChange={(e) => handleOnChange_date(e, "endDateTime")}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={7} />
          <Grid item xs={2}>
            AMR ID:
          </Grid>
          <Grid item xs={3}>
            <Select
              className={styles.filterDropdown}
              name="status"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Choose status"
              value={renderValue(task.status)}
            >
              {/* {statusList && statusList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}  */}
            </Select>
          </Grid>
          <Grid item xs={7} />
          <Grid item xs={2}>
            Nature of Task:
          </Grid>
          <Grid item xs={3}>
            <Select
              className={styles.filterDropdown}
              name="status"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Choose status"
              value={renderValue(task.status)}
            >
              {/* {statusList && statusList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}  */}
            </Select>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1}>
            Task Type:
          </Grid>
          <Grid item xs={3}>
            <Select
              className={styles.filterDropdown}
              name="status"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Choose status"
              value={renderValue(task.status)}
            >
              {/* {statusList && statusList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}  */}
            </Select>
          </Grid>
          <Grid item xs={2}/>
          <Grid item xs={2}>
            Start Location:
          </Grid>
          {[...Array(10)].map((e, i) => {
            return (
              <Grid key={i} item xs={1}>
                <Paper elevation={0} className={styles.arrayButton}>
                  {i+1}
                </Paper>
              </Grid>
            )
          })}
          <Grid item xs={2}>
            End Location:
          </Grid>
          {[...Array(10)].map((e, i) => {
            return (
              <Grid key={i} item xs={1}>
                <Paper elevation={0} className={styles.arrayButton}>
                  {i+1}
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      </Card>
    </React.Fragment>
  );
}
