import _ from "lodash";

export const login = (data) => {
  console.log("LOGGING IN...");
  localStorage.setItem('accessToken', data.result.accessToken);
  localStorage.setItem("userDetail", JSON.stringify(data.result.userDetail));
  localStorage.setItem('expiresAt', Date.now() + (data.result.expireInSeconds*1000));
  localStorage.setItem("permission", JSON.stringify(data.result.module));
  localStorage.setItem('chatbotOpen', true);

  const hasAdmin = data && _.some(data.result.module, permission => {
    return Boolean(permission.name.includes("Admin.") && permission.value != "none")
  })
  localStorage.setItem("hasAdmin", hasAdmin);
}

export const logout = () => {
  console.log("LOGGING OUT...");
  localStorage.removeItem('accessToken');
  localStorage.removeItem("userDetail");
  localStorage.removeItem('expiresAt');
  localStorage.removeItem("permission");
  localStorage.removeItem("chatbotOpen");
}

export const updateProfile = (data) => {
  localStorage.setItem("userDetail", JSON.stringify(_.omit(data.result, ["grantedPermissions", "module"])));
  localStorage.setItem("permission", JSON.stringify(data.result.module));

  const hasAdmin = data && _.some(data.result.module, permission => {
    return Boolean(permission.name.includes("Admin.") && permission.value != "none");
  })
  localStorage.setItem("hasAdmin", hasAdmin);
}

export const isAuthenticated = () => {
  const expiresAt = JSON.parse(localStorage.getItem('expiresAt'));
  return Date.now() < expiresAt;
}